import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EnviarDialogComponent } from '../../dialogs/enviar-dialog/enviar-dialog.component';
import { Encargo } from '../../models/encargo';
import { Expediente } from '../../models/expediente';
import { FormatoConsulta } from '../../models/formato_consulta/formato-consulta.model';
import { Medico } from '../../models/medico';
import { Tipo } from '../../models/tipo';
import { Tramitador } from '../../models/tramitador';
import { FuncionesPrincipalService } from '../../services/funciones-principal.service';
import { NotificacionService } from '../../services/notificacion.service';
import { SessionService } from '../../services/session.service';
import { TramitadorPortalService } from '../../services/tramitador-portal.service';

declare var JitsiMeetExternalAPI: any;

@Component({
  selector: 'app-tramitador-principal',
  templateUrl: './tramitador-principal.component.html',
  styleUrls: ['./tramitador-principal.component.scss']
})
export class TramitadorPrincipalComponent implements OnInit {

  private idEncargo;
  tramitador: Tramitador;
  encargo: Encargo;
  numExpediente: string = "";
  msgErrorInputExpediente: string = "";
  expediente: Expediente;
  medicos: Medico[];
  tipos: Tipo[];
  tiposSlice : Tipo[];
  idMedico: number;
  idTipo: number;
  mostrarProgressSpinner: boolean = false;
  mostrarCancel: boolean = false;
  isUrgente: boolean;
  comentario: string;
  idEncargoCreado;
  fechaLimite: string = null;
  fechaConsultaVideo: string = null;
  horaConsultaVideo: string = null;
  patoAutomatica: string = null;
  formatoConsulta : FormatoConsulta[];
  idFormatoConsulta: number;

  constructor(private tramitadorService: TramitadorPortalService, 
    private notificacion: NotificacionService, 
    private route: ActivatedRoute, 
    private router: Router, 
    private funcionesService: FuncionesPrincipalService, 
    private sessionService: SessionService,
    private dialog: MatDialog) {

      this.encargo = new Encargo();
      this.expediente = new Expediente();
      this.tramitador = this.sessionService.getCurrentSession().tramitador;

      this.route.params.subscribe(params =>{
        this.idEncargo = params['idEncargo'];
        this.idTipo    = params['idTipo'];
        this.ngOnInit();
      });
  }

  ngOnInit(): void {
    this.mostrarProgressSpinner = true;
    this.getFormatoConsulta();

    //Si es un encargo pendiente de enviar se habilita el boton de cancelar encargo
    if(this.idEncargo){
      this.mostrarCancel = true;
    }

    this.tramitadorService.getDatosNuevaTarea().subscribe(
      data =>{
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          this.medicos = data['medicos'];
          this.tipos = data['tipos'];
        }
        this.tipos.splice(0,10);
        // Obtener la longitud actual del arreglo
        var longitudArreglo = this.tipos.length;

        // Utilizamos filter para crear un nuevo array sin el valor a eliminar
        this.tipos = this.tipos.filter(item => item.id !== 19);
        // Eliminar el último elemento del arreglo
        //this.tipos.splice(longitudArreglo - 1, 1);
        
      }, error=>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );

    //Si se ha navegado desde el encargo, los campos se autocompletan con el encargo
    if(this.idEncargo){
      this.funcionesService.getEncargoById(this.idEncargo, this.tramitador.trm_id_user).subscribe(
        data =>{
          this.mostrarProgressSpinner = false;
          if(data['response'] == "ok"){
            this.encargo = data['encargo'];
            this.rellenarCampos(this.encargo);
          }else{
            this.notificacion.notificationFail(data['mensaje']);
          }
        }, error =>{
          this.mostrarProgressSpinner = false;
          this.notificacion.notificationFail("Error en el servidor");
        }
      );
    }

  }

  getFormatoConsulta() {
    this.tramitadorService.getFormatoConsulta().subscribe({
      next: (data: any) => {
        console.log(data);
        this.formatoConsulta = data['data'];
      },
      error: (err: any) => {
        console.log(err);
      },
      
    });
  }

  rellenarCampos(encargo: Encargo){
    this.expediente.cia = encargo.enup_cia;
    this.expediente.condicion = encargo.enup_condicion_lesionado;
    this.expediente.edadLesionado = encargo.enup_edad_lesionado;
    this.expediente.telefono = encargo.enup_telefono;
    this.expediente.fechaSiniestro = encargo.enup_fecha_siniestro;
    this.expediente.formaProduccion = encargo.enup_forma_produccion;
    this.expediente.lesionado = encargo.enup_lesionado;
    this.expediente.localidad = encargo.enup_localidad;
    this.expediente.numExpediente = encargo.enup_expediente;
    this.expediente.idExpediente = encargo.enup_id_expediente;
    this.numExpediente = encargo.enup_expediente;
    this.expediente.provincia = encargo.enup_provincias;
    this.expediente.siniestro = encargo.enup_referencia_siniestro;
    this.idMedico = encargo.enup_id_medico;
    this.idTipo = encargo.enup_id_tipo_encargo;
    this.isUrgente = encargo.enup_prioridad == 1 ? true : false;
    this.idFormatoConsulta = encargo.formato_consulta;
  }

  getExpediente(){
    console.log(this.numExpediente);
    this.mostrarProgressSpinner = true;
    //Si es vacio mostrar mensaje de error
    if(this.numExpediente.trim()==""){
      this.mostrarProgressSpinner = false;
      this.msgErrorInputExpediente = "El campo está vacio";
    }else{
      this.msgErrorInputExpediente = "";

      this.tramitadorService.getExpediente(this.numExpediente).subscribe(
        data =>{
          this.mostrarProgressSpinner = false;
          if(data['response'] == "ok"){
            //console.log(data['expediente']);
            this.expediente = data['expediente'];
          }else if(data['response'] == "noEncontrado"){
            console.log(data['mensaje']);
          }else{

          }
        }, error =>{
          this.mostrarProgressSpinner = false;
        }
      );
    }
  }

  //Guardar encargo estado pendiente de enviar
  guardarTarea(estado: string){
    this.mostrarProgressSpinner = true;
    var idEstado: number;

    if(this.idTipo == -1){
      this.idTipo = null;
    }

    if(this.idMedico == -1){
      this.idMedico = null;
    }

    //Dependiendo del boton se le pasa un estado o otro
    if(estado == "tramitacion"){
      idEstado = 1;
    }else if(estado == "enviado"){
      idEstado = 2;
    }

    //Campos requeridos
    if((this.idTipo != null && this.idMedico != null && this.numExpediente != null && idEstado == 2) || (idEstado == 1)){

      //console.log(this.expediente);
      var datos = {
        'numExpediente': this.numExpediente,
        'idExpediente': this.expediente.idExpediente,
        'idMedico': this.idMedico,
        'idTramitador': this.tramitador.trm_id_user,
        'prioridad': this.isUrgente == true ? 1 : null,
        'lesionado': this.expediente.lesionado,
        'edadLesionado': this.expediente.edadLesionado,
        'telefono': this.expediente.telefono,
        'fechaSiniestro': this.expediente.fechaSiniestro,
        'refSiniestro': this.expediente.siniestro,
        'idEstadoEncargo': idEstado,
        'idTipoEncargo': this.idTipo,
        'condicion': this.expediente.condicion,
        'idCia': this.expediente.idCia,
        'provincia': this.expediente.provincia,
        'localidad': this.expediente.localidad,
        'formaProduccion': this.expediente.formaProduccion,
        'fechaLimite': this.fechaLimite,
        'fechaConsultaVideo': this.fechaConsultaVideo,
        'horaConsultaVideo': this.horaConsultaVideo,
        'patoAutomatica': this.patoAutomatica,
        'formato_consulta' : this.idFormatoConsulta,
        'encargoAnterior' : false
      };

      if(idEstado == 2){
        //Mostrar dialogo de confirmacion
        const dialogEnviar = this.dialog.open(EnviarDialogComponent,{
          data: {comentario: this.comentario}
        });
        
        dialogEnviar.afterClosed().subscribe(
          result =>{
            //Si pulsa el boton "Si" del dialog
            if(result){
              
              this.tramitadorService.crearTareaEncargo(datos).subscribe(
                data =>{
                  this.mostrarProgressSpinner = false;
                  if(data['response'] == "ok"){
                    this.idEncargoCreado = data['idEncargo'];
                    let datosMensaje = {
                      'idEncargo': data['idEncargo'],
                      'idUsuario': this.tramitador.trm_id_user,
                      'textoMensaje': result
                    };

                    //Si es un encargo urgente se le envia un email al medico
                    if(this.isUrgente){
                      let datosEmail = {
                        'idEncargo': this.idEncargoCreado
                      };
                      this.mostrarProgressSpinner = true;
                      //Enviar email al medico
                      this.enviarEmailEncargoUrgente(datosEmail);
                    }

                    this.mostrarProgressSpinner = true;
                    //crear Autorizacion y enviar mensaje
                    if(this.idTipo != 19) {
                      this.crearAutorizacion(datosMensaje);
                    }else{
                      this.mostrarProgressSpinner = false;
                      this.notificacion.notificationOk(data['mensaje']);
                    }
                    
                  }else{
                    this.notificacion.notificationFail(data['mensaje']);
                  }
                }, error =>{
                  this.mostrarProgressSpinner = false;
                  this.notificacion.notificationFail("Error en el servidor");
                }
              );
              
            }else{
              this.mostrarProgressSpinner = false;
            }
          }
        );
      }else if(idEstado == 1){
        this.tramitadorService.crearTareaEncargo(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              this.notificacion.notificationOk("Tarea guardada en estado: En tramitación. Se te redireccionará en 3 segundos");
              setTimeout(() => {
                if(this.idTipo == 0) {
                  this.router.navigate(['tramitador-listado/asesorias']);
                }
              }, 3000);
            }else{
              this.notificacion.notificationFail(data['mensaje']);
            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            this.notificacion.notificationFail("Error en el servidor");
          }
        );
        
      }

    }else{
      this.mostrarProgressSpinner = false;
      this.notificacion.notificationFail("El campo 'Medicos' o 'Tipo' no estan rellenos");
    }
  }

  //Enviar email encargo urgente
  enviarEmailEncargoUrgente(datosEmail){
    this.tramitadorService.enviarEmailEncargoUrgente(datosEmail).subscribe(
      data =>{
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){

        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }
  
  //Crear autorizacion y enviar mensaje
  crearAutorizacion(datosMensaje){
    this.mostrarProgressSpinner = true;
    let datos = {
      'idMedico': this.idMedico,
      'idEncargo': this.idEncargoCreado,
      'idExpedienteCrm': this.expediente.idExpediente,
      'idTipo' : this.idTipo,
      'idCia': this.expediente.cia
    };
    this.funcionesService.crearAut(datos).subscribe(
      data =>{
        for(let dato in data) {
          let valor = data[dato];
          console.log(`Clave: ${dato}, Valor: ${valor}`);
        }
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          this.funcionesService.addMensajeEncargo(datosMensaje).subscribe(
            data => {
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.notificacion.notificationOk("Tarea guardada en estado: Enviado. Se te redireccionará en 3 segundos");
                setTimeout(() => {
                  if(this.idTipo == 0) {
                    this.router.navigate(['tramitador-listado/asesorias']);
                  }
                  
                }, 3000);
              }else{
                console.log(data['mensaje']);
                this.notificacion.notificationFail(data['mensaje']);
              }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor. Autorización no creada");
        
      }
    );
  }

  cancelar(){
    //Eliminar encargo
    this.funcionesService.borrarEncargo(this.idEncargo).subscribe(
      data =>{
        this.mostrarProgressSpinner = false;
          if(data['response'] == "ok"){
            this.mostrarProgressSpinner = true;
            this.notificacion.notificationOk(data['mensaje'] + " Se te redireccionará en 3 segundos.");
            setTimeout(() => {
              this.router.navigate(['tramitador-principal'])
              .then(() => {
                window.location.reload();
              });
            }, 3000);
            
          }else{
            this.notificacion.notificationFail(data['mensaje']);
          }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }
    
}
