import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComentarioIncidencia } from 'src/app/facturacion/models/comentario-incidencia';
import { Incidencia } from 'src/app/facturacion/models/incidencia';
import { IncidenciaNormal } from 'src/app/facturacion/models/incidencia-normal';
import { Motivo } from 'src/app/facturacion/models/motivo';
import { Proveedor } from 'src/app/facturacion/models/proveedor';
import { ComentariosIncidencia, FacturacionServiceService, Motivos, Users } from 'src/app/facturacion/services/facturacion-service.service';
import { User } from 'src/app/models/user';
import { SessionService } from 'src/app/services/session.service';
import { DialogDataAction } from '../dialog-utils/dialog-data-action';

@Component({
  selector: 'app-incidencia-proveedor-dialog',
  templateUrl: './incidencia-proveedor-dialog.component.html',
  styleUrls: ['./incidencia-proveedor-dialog.component.scss']
})
export class IncidenciaProveedorDialogComponent implements OnInit {

  fechaCreacion: Date = new Date();
  motivos: Motivo[] = [];
  usersAsignar: User[] = [];

  tipoIncidenciaSelected: string = '';
  estadoSelected: string = '';
  usuarioAsignadoSelected: string = '';
  motivoSelected: string = '';
  alarma: boolean = false;
  descripcion: string = '';

  comentarios: ComentarioIncidencia[] = [];

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(
    public facturacionService: FacturacionServiceService, 
    private sessionService: SessionService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataIncidenciaNormal,
    public dialogRef: MatDialogRef<IncidenciaProveedorDialogComponent>,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    if(this.data.accion == DialogDataAction.modificar){
      this.tipoIncidenciaSelected = this.data.incidencia.inc_tipo;
      this.estadoSelected = this.data.incidencia.inc_estado;
      this.usuarioAsignadoSelected = this.data.incidencia.inc_usuario_asignado;
      this.motivoSelected = this.data.incidenciaNormal.incn_motivo;
      this.descripcion = this.data.incidencia.inc_descripcion;
      this.alarma = this.data.incidenciaNormal.incn_alarma;

      this.obtenerComentariosIncidenciaNormal()
    }
    this.obtenerMotivos();
    this.obtenerUsuariosFacturacion();
  }

  obtenerMotivos(){
    this.facturacionService.obtenerMotivos().subscribe({
      next: (data: Motivos) => {
        this.motivos = data.data;
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }

  obtenerComentariosIncidenciaNormal(){
    //console.log(this.data.incidenciaNormal);
    this.facturacionService.obtenerComentariosIncidenciaNormal(this.data.incidenciaNormal).subscribe({
      next: (data: ComentariosIncidencia) => {
        this.comentarios = data.data;
        console.log(this.comentarios);
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }

  obtenerUsuariosFacturacion(){
    this.facturacionService.obtenerUsuariosFacturacion().subscribe({
      next: (data: Users) => {
        this.usersAsignar = data.data.filter(user => user.id != this.sessionService.getCurrentSession().user.id);
        //console.log(this.usersAsignar);
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }

  checkIfIsUser(comentario: ComentarioIncidencia){
    return comentario.cinc_id_user == this.sessionService.getCurrentSession().user.id.toString();
  }
  

  crearComentario(message: string){
    if(message.trim().length !== 0){

      const comentario: ComentarioIncidencia = {
        cinc_id_comentarios_incidencia: '',
        cinc_fecha_creacion: new Date(),
        cinc_fecha_modificacion: undefined,
        cinc_id_incidencia_normal: null,
        cinc_id_incidencia_contabilidad: null,
        cinc_id_incidencia_pago: null,
        cinc_id_user: this.sessionService.getCurrentSession().user.id.toString(),
        cinc_nombre: this.sessionService.getCurrentSession().user.name,
        cinc_text: message
      }

      this.comentarios.push(comentario);

      if(this.data.accion == DialogDataAction.modificar){
        this.facturacionService.crearComentarioIncidenciaNormal(comentario, this.data.incidenciaNormal).subscribe({
          next: (data: any) => {
            this.snackBar.open('Subido nuevo comentario', undefined, {
              duration: 3 * 1000,
            });
          },
          error: (err: any) => {
            console.log(err);
            this.snackBar.open(err, undefined, {
              duration: 3 * 1000,
            });
          }
        });
      }

    }
  }

  crearIncidenciaNormal(){

    const incidencia: Incidencia = {
      inc_id_incidencias: '',
      inc_fecha_creacion: undefined,
      inc_fecha_modificacion: undefined,
      inc_usuario_creacion: this.sessionService.getCurrentSession().user.id.toString(),
      inc_estado: this.estadoSelected,
      inc_tipo: this.tipoIncidenciaSelected,
      inc_usuario_asignado: this.usuarioAsignadoSelected,
      inc_descripcion: this.descripcion,
      inc_fecha_finalizacion: undefined,
    }

    const incidenciaNormal: IncidenciaNormal = {
      incn_id_incidencias_normales: '',
      incn_fecha_creacion: undefined,
      incn_fecha_modificacion: undefined,
      incn_id_incidencia: '',
      incn_proveedor: this.data.proveedor.prov_id_crm,
      incn_motivo: this.motivoSelected,
      incn_alarma: this.alarma,
    }

    if(
      this.tipoIncidenciaSelected == '' ||
      this.estadoSelected == '' ||
      this.usuarioAsignadoSelected == '' ||
      this.motivoSelected == '' ||
      this.descripcion == ''
    ){
      this.snackBar.open('Rellena los campos obligatorios *', undefined, {
        duration: 3 * 1000,
      });
    }
    else{
      this.facturacionService.crearIncidenciaNormal(incidencia, incidenciaNormal, this.comentarios).subscribe({
        next: (data: any) => {
          console.log(data);
          this.snackBar.open('Incidencia creada', undefined, {
            duration: 3 * 1000,
          });
          this.dialogRef.close();
        },
        error: (err: any) => {
          console.log(err);
          this.snackBar.open(err, undefined, {
            duration: 3 * 1000,
          });
        }
      })
    }

    
  }

  actualizarIncidenciaNormal(){

    const incidencia: Incidencia = {
      inc_id_incidencias: this.data.incidencia.inc_id_incidencias,
      inc_fecha_creacion: this.data.incidencia.inc_fecha_creacion,
      inc_fecha_modificacion: this.data.incidencia.inc_fecha_modificacion,
      inc_usuario_creacion: this.data.incidencia.inc_usuario_creacion,
      inc_estado: this.estadoSelected,
      inc_tipo: this.tipoIncidenciaSelected,
      inc_usuario_asignado: this.usuarioAsignadoSelected,
      inc_descripcion: this.descripcion,
      inc_fecha_finalizacion: this.data.incidencia.inc_fecha_finalizacion,
    }

    const incidenciaNormal: IncidenciaNormal = {
      incn_id_incidencias_normales: this.data.incidenciaNormal.incn_id_incidencias_normales,
      incn_fecha_creacion: this.data.incidenciaNormal.incn_fecha_creacion,
      incn_fecha_modificacion: this.data.incidenciaNormal.incn_fecha_modificacion,
      incn_id_incidencia: this.data.incidenciaNormal.incn_id_incidencia,
      incn_proveedor: this.data.incidenciaNormal.incn_proveedor,
      incn_motivo: this.motivoSelected,
      incn_alarma: this.alarma,
    }

    this.facturacionService.actualizarIncidenciaNormal(
      incidencia,
      incidenciaNormal
    ).subscribe({
      next: (data: any) => {
        console.log(data);
        this.snackBar.open('Incidencia actualizada', undefined, {
          duration: 3 * 1000,
        });
        this.dialogRef.close();
      },
      error: (err: any) => {
        console.log(err);
        this.snackBar.open(err, undefined, {
          duration: 3 * 1000,
        });
      }
    })

  }

}

interface DialogDataIncidenciaNormal {
  proveedor: Proveedor;
  incidencia: Incidencia;
  incidenciaNormal: IncidenciaNormal;
  accion: DialogDataAction;
}