<h1 mat-dialog-title>Crear incidencia de factura emitida</h1>
<div mat-dialog-content>

    <p>Motivo:</p>

    <mat-radio-group aria-label="Selecciona una opción" class="d-flex flex-column" [(ngModel)]="motivo">
        <mat-radio-button value="Cambio de importes" class="mb-2">Cambio de importes</mat-radio-button>
        <mat-radio-button value="Anular factura">Anular factura</mat-radio-button>
    </mat-radio-group>

    <mat-form-field appearance="outline" class="mt-4 w-100">
        <mat-label>Observaciones</mat-label>
        <textarea matInput [(ngModel)]="observaciones"></textarea>
    </mat-form-field>

</div>
<div mat-dialog-actions>

    <button mat-flat-button color="primary" (click)="crearIncidenciaContabilidad()">Crear</button>

</div>