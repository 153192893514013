import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { DialogDataAction } from 'src/app/dialogs/dialog-utils/dialog-data-action';
import { IncidenciaProveedorDialogComponent } from 'src/app/dialogs/incidencia-proveedor-dialog/incidencia-proveedor-dialog.component';
import { Medico } from 'src/app/models/medico';
import { User } from 'src/app/models/user';
import { Centro } from '../../models/centro';
import { FacturasRecibidas } from '../../models/facturas-recibidas';
import { IncidenciaNormal } from '../../models/incidencia-normal';
import { Motivo } from '../../models/motivo';
import { Pago } from '../../models/pago';
import { Proveedor } from '../../models/proveedor';
import { Centros, FacturacionServiceService, FacturasRecibidasList, incidenciasNormales, Medicos, Motivos, Pagos, Users } from '../../services/facturacion-service.service';

@Component({
  selector: 'app-proveedor',
  templateUrl: './proveedor.component.html',
  styleUrls: ['./proveedor.component.scss']
})
export class ProveedorComponent implements OnInit {

  proveedor: Proveedor = undefined;

  CIFNIF: string = null;
  nombre: string = null;
  domicilio: string = null;
  poblacion: string = null;
  provincia: string = null;
  codigoPostal: string = null;
  telefono: string = null;
  movil: string = null;
  fax: string = null;
  eMail: string = null;

  nCuenta: string = null;
  retencion: string = null;
  codigoIAE: string = null;
  contrato: string = null;
  cero3637: string = null;
  periodoPago: string = null;
  inicioActividad: string = null;
  checkedPago: boolean = false;

  centrosAsignados: Centro[] = [];
  medicosAsignados: Medico[] = [];
  facturasRecibidas: FacturasRecibidas[] = [];
  pagos: Pago[] = [];
  incidenciasPago: Medico[] = [];
  incidenciasNormales: IncidenciaNormal[] = [];

  motivos: Motivo[] = [];
  users: User[] = [];

  //Tablas
  //---Tabla Centros Asignados---
  @ViewChild('tableCentros')
  centrosTable: MatTable<Centro>;
  displayedColumnsCentros: string[] = [
    'cen_id_centros',
    'cen_fecha_creacion',
    'cen_fecha_modificacion',
    'cen_estado',
    'cen_nombre'
  ];
  dataSourceCentros: MatTableDataSource<Centro> = new MatTableDataSource(this.centrosAsignados);

  //---Tabla Médicos Asignados---
  @ViewChild('tableMedicos')
  medicosTable: MatTable<Medico>;
  displayedColumnsMedicos: string[] = [
    'med_id_medicos',
    'med_fecha_creacion',
    'med_fecha_modificacion',
    'med_estado',
    'med_nombre'
  ];
  dataSourceMedicos: MatTableDataSource<Medico> = new MatTableDataSource(this.medicosAsignados);

  //---Tabla Facturas Recibidas---
  @ViewChild('tableFacturasRecibidas')
  facturasRecibidasTable: MatTable<FacturasRecibidas>;
  displayedColumnsFacturasRecibidas: string[] = [
    'factr_id_facturas_recibidas',
    'factr_fecha_creacion',
    'factr_fecha_modificacion',
    'factr_estado',
    'factr_numero_factura',
    'factr_fecha_pago',
    'factr_base_imponible',
    'factr_total_iva',
    'factr_total_retencion',
    'factr_total_factura',
    'factr_estado_factura',
    'factr_pago',
    'factr_contabilidad',
    'factr_fecha_prevision_de_pago',
  ];
  dataSourceFacturasRecibidas: MatTableDataSource<FacturasRecibidas> = new MatTableDataSource(this.facturasRecibidas);

  //---Tabla Pagos---
  @ViewChild('tablePagos')
  pagosTable: MatTable<Pago>;
  displayedColumnsPagos: string[] = [
    'pag_id_pagos', 
    'pag_fecha_creacion', 
    'pag_fecha_modificacion', 
    'pag_estado', 
    'pag_remesa', 
    'pag_concepto', 
    'pag_iban_cuenta', 
    'pag_total_pagado'
  ];
  dataSourcePagos: MatTableDataSource<Pago> = new MatTableDataSource(this.pagos);

  //---Tabla Incidencias normales---
  @ViewChild('tableIncidenciasNormales')
  incidenciasNormalesTable: MatTable<IncidenciaNormal>;
  displayedColumnsIncidenciasNormales: string[] = [
    'inc_id_incidencias', 
    'inc_fecha_creacion', 
    'inc_usuario_creacion', 
    'inc_estado', 
    'inc_tipo', 
    'inc_usuario_asignado', 
    'incn_motivo', 
    'incn_alarma'
  ];
  dataSourceIncidenciasNormales: MatTableDataSource<IncidenciaNormal> = new MatTableDataSource(this.incidenciasNormales);

  //Paginators
  //---Paginator Centros---
  @ViewChild('centrosPaginator')
  centrosPaginator: MatPaginator;
  centrosLength = 0;

  //---Paginator Medicos---
  @ViewChild('medicosPaginator')
  medicosPaginator: MatPaginator;
  medicosLength = 0;

  //---Paginator Facturas Recibidas---
  @ViewChild('facturasRecibidasPaginator')
  facturasRecibidasPaginator: MatPaginator;
  facturasRecibidasLength = 0;

  //---Paginator Pagos---
  @ViewChild('pagosPaginator')
  pagosPaginator: MatPaginator;
  pagosLength = 0;

  //---Paginator Incidencias Normales---
  @ViewChild('incidenciasNormalesPaginator')
  incidenciasNormalesPaginator: MatPaginator;
  incidenciasNormalesLength = 0;

  constructor(
    private facturacionService : FacturacionServiceService,
    private routerActive: ActivatedRoute,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {

    this.routerActive.queryParams.subscribe(params => this.obtenerProveedor(params["proveedor"]));
  }

  ngAfterViewInit(): void {
    this.centrosPaginator._intl.itemsPerPageLabel = 'Mostrando:';
    this.medicosPaginator._intl.itemsPerPageLabel = 'Mostrando:';
  }

  obtenerProveedor(prov_id_crm: string){
    this.facturacionService.obtenerProveedor(prov_id_crm).subscribe({
      next: (data: any) => {

        this.proveedor = data['data'];

        this.CIFNIF = this.proveedor.prov_cif;
        this.nombre = this.proveedor.prov_nombre;
        this.domicilio = this.proveedor.prov_domicilio;
        this.poblacion = this.proveedor.prov_poblacion;
        this.provincia = this.proveedor.prov_provincia.prv_nombre_crm;
        this.codigoPostal = this.proveedor.prov_codigo_postal;
        this.telefono = this.proveedor.prov_telefono;
        this.movil = this.proveedor.prov_movil;
        this.fax = this.proveedor.prov_fax;
        this.eMail = this.proveedor.prov_email;
      
        this.nCuenta = this.proveedor.prov_cuenta;
        this.retencion = this.proveedor.prov_retencion.toString();
        this.codigoIAE = this.proveedor.prov_codigoiae;
        this.contrato = this.proveedor.prov_contrato;
        this.cero3637 = this.proveedor.prov_cero3637;
        this.periodoPago = this.proveedor.prov_periodo_pago;
        this.inicioActividad = this.proveedor.prov_inicio_actividad;
        
        if(this.proveedor.prov_pago_por_centro == 1){
          this.checkedPago = true;
        }

        this.obtenerCentrosAsignados();
        this.obtenerMedicosAsignados();
        this.obtenerFacturasRecibidasAsignadas();
        this.obtenerPagos();
        this.obtenerIncidenciasNormales();
      },

      error: (err: any) => {
        console.log(err);
        this.snackBar.open(err, undefined, {
          duration: 3 * 1000,
        });
      }
    });
  }

  obtenerCentrosAsignados(){
    this.facturacionService.obtenerCentrosAsignados(this.proveedor.prov_id_crm).subscribe({
      next: (data: Centros) => {
        this.centrosAsignados = data.data;

        this.dataSourceCentros = new MatTableDataSource(this.centrosAsignados);
        this.dataSourceCentros.paginator = this.centrosPaginator;
        this.centrosLength = this.dataSourceCentros.filteredData.length;
      },

      error: (err: any) => {
        console.log(err);
        this.snackBar.open(err, undefined, {
          duration: 3 * 1000,
        });
      }
    });
  }

  obtenerMedicosAsignados(){
    this.facturacionService.obtenerMedicosAsignados(this.proveedor.prov_id_crm).subscribe({
      next: (data: Medicos) => {
        this.medicosAsignados = data.data;
        //console.log(this.medicosAsignados);

        this.dataSourceMedicos = new MatTableDataSource(this.medicosAsignados);
        this.dataSourceMedicos.paginator = this.medicosPaginator;
        this.medicosLength = this.dataSourceMedicos.filteredData.length;
      },

      error: (err: any) => {
        console.log(err);
        this.snackBar.open(err, undefined, {
          duration: 3 * 1000,
        });
      }
    });
  }

  obtenerFacturasRecibidasAsignadas(){
    this.facturacionService.obtenerFacturasRecibidasAsignadas(this.proveedor.prov_id_crm).subscribe({
      next: (data: FacturasRecibidasList) => {
        this.facturasRecibidas = data.data;

        this.dataSourceFacturasRecibidas = new MatTableDataSource(this.facturasRecibidas);
        this.dataSourceFacturasRecibidas.paginator = this.facturasRecibidasPaginator;
        this.facturasRecibidasLength = this.dataSourceFacturasRecibidas.filteredData.length;
      },

      error: (err: any) => {
        console.log(err);
        this.snackBar.open(err, undefined, {
          duration: 3 * 1000,
        });
      }
    });
  }

  obtenerPagos(){
    this.facturacionService.obtenerPagos(this.proveedor.prov_id_crm).subscribe({
      next: (data: Pagos) => {
        this.pagos = data.data;
        //console.log(this.pagos);

        this.dataSourcePagos = new MatTableDataSource(this.pagos);
        this.dataSourcePagos.paginator = this.pagosPaginator;
        this.pagosLength = this.dataSourcePagos.filteredData.length;
      },

      error: (err: any) => {
        console.log(err);
        this.snackBar.open(err, undefined, {
          duration: 3 * 1000,
        });
      }
    });
  }

  obtenerIncidenciasNormales(){
    this.facturacionService.obtenerMotivos().subscribe({
      next: (data: Motivos) => {
        this.motivos = data.data;

        this.facturacionService.obtenerUsuariosFacturacion().subscribe({
          next: (data: Users) => {
            this.users = data.data;

            this.facturacionService.obtenerIncidenciasNormales(this.proveedor.prov_id_crm).subscribe({
              next: (data: incidenciasNormales) => {
                this.incidenciasNormales = data.data[0];
                data.data[1].forEach((incidencia, key) => {
                  this.incidenciasNormales[key]['incidencia'] = incidencia;
                });
        
                this.dataSourceIncidenciasNormales = new MatTableDataSource(this.incidenciasNormales);
                this.dataSourceIncidenciasNormales.paginator = this.incidenciasNormalesPaginator;
                this.incidenciasNormalesLength = this.dataSourceIncidenciasNormales.filteredData.length;
              },
        
              error: (err: any) => {
                console.log(err);
                this.snackBar.open(err, undefined, {
                  duration: 3 * 1000,
                });
              }
            });

          },
          error: (err: any) => {
            console.log(err);
            this.snackBar.open(err, undefined, {
              duration: 3 * 1000,
            });
          }
        })

        
      },

      error: (err: any) => {
        console.log(err);
        this.snackBar.open(err, undefined, {
          duration: 3 * 1000,
        });
      }
    });
  }

  obtenerNombreMotivo(incidenciaNormal: IncidenciaNormal): string{
    return this.motivos.filter(motivo => motivo.mot_id_motivos == incidenciaNormal.incn_motivo)[0].mot_descripcion;
  }

  obtenerNombreUsuario(userId: string): string{
    return this.users.filter(user => user.id.toString() == userId)[0].name;
  }

  modificarIncidencia(incidenciaNormal: IncidenciaNormal){

    //console.log(incidenciaNormal);

    let incidenciaNormalLocal: IncidenciaNormal = {
      incn_id_incidencias_normales: incidenciaNormal.incn_id_incidencias_normales,
      incn_fecha_creacion: incidenciaNormal.incn_fecha_creacion,
      incn_fecha_modificacion: incidenciaNormal.incn_fecha_modificacion,
      incn_id_incidencia: incidenciaNormal.incn_id_incidencia,
      incn_proveedor: incidenciaNormal.incn_proveedor,
      incn_motivo: incidenciaNormal.incn_motivo,
      incn_alarma: incidenciaNormal.incn_alarma
    };
    delete incidenciaNormalLocal['incidencia'];

    const dialogRef = this.dialog.open(IncidenciaProveedorDialogComponent, {
      maxWidth: 1000,
      data: {
        proveedor: this.proveedor,
        incidencia: incidenciaNormal['incidencia'],
        incidenciaNormal: incidenciaNormalLocal,
        accion: DialogDataAction.modificar,
      },
    });

    dialogRef.afterClosed().subscribe( () => {
      this.obtenerIncidenciasNormales();
    });

  }


}
