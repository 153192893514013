<app-header-facturacion></app-header-facturacion>

<div class="container align">
    <mat-form-field>
      <mat-label>Buscar</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Buscar">
    </mat-form-field>
  
    <div class="container mat-elevation-z8">
        <div class="loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
            <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
            <div class="rate-limit-reached" *ngIf="isRateLimitReached">
                Ha ocurrido un error inesperado. Se restablecerá enseguida.
            </div>
        </div>
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        
                <!-- Position Column -->
                
                <ng-container matColumnDef="numExp">
                    <th mat-header-cell *matHeaderCellDef> Número expediente </th>
                    <td mat-cell *matCellDef="let element"> {{element.exp_id_expedientes_up}} </td>
                </ng-container>

                <ng-container matColumnDef="refSin">
                    <th mat-header-cell *matHeaderCellDef  class="celda"> Ref. Siniestro </th>
                    <td mat-cell *matCellDef="let element" class="celda"> {{element.exp_ref_siniestro}} </td>
                </ng-container>

                <ng-container matColumnDef="fechaEncargo">
                    <th mat-header-cell *matHeaderCellDef> Fecha encargo </th>
                    <td mat-cell *matCellDef="let element"> {{element.exp_fecha_encargo}} </td>
                </ng-container>

                <ng-container matColumnDef="lesionado">
                    <th mat-header-cell *matHeaderCellDef  class="celda"> Lesionado </th>
                    <td mat-cell *matCellDef="let element"  class="celda"> {{element.exp_lesionado}} </td>
                </ng-container>

                <ng-container matColumnDef="compania">
                    <th mat-header-cell *matHeaderCellDef class="celda"> Compañia </th>
                    <td mat-cell *matCellDef="let element" class="celda" > {{element.exp_cia.cia_nombre}} </td>                    
                </ng-container>

                <ng-container matColumnDef="tipoEncargo">
                    <th mat-header-cell *matHeaderCellDef class="celda"> Tipo de encargo </th>
                    <td mat-cell *matCellDef="let element" class="celda"> {{element.exp_tipo_encargo}} </td>
                </ng-container>

                <ng-container matColumnDef="estado">
                    <th mat-header-cell *matHeaderCellDef> Estado </th>
                    <td mat-cell *matCellDef="let element"> {{element.exp_estado}} </td>
                </ng-container>

                <ng-container matColumnDef="provincia">
                    <th mat-header-cell *matHeaderCellDef> Provincia </th>
                    <td mat-cell *matCellDef="let element"> {{element.exp_provincia.prv_descripcion}} </td>
                </ng-container>

                <ng-container matColumnDef="selected">
                    <th mat-header-cell *matHeaderCellDef>Facturar expediente</th>
                    <td mat-cell *matCellDef="let element"> <a class="btn boton" [routerLink]="['/facturar-expediente']" [queryParams]="{expediente: element.exp_id_expedientes_up}" target="_blank"> Facturar</a> </td>
                </ng-container>
                
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <mat-paginator [length]="resultsLength"  [pageSizeOptions]="[10]"></mat-paginator>
    </div>
</div>
<div class="button-container refresh">
    <button mat-fab color="secondary" title="Actualizar Expediente" aria-label="Actualizar expediente" (click)="getExpedientes()">
        <mat-icon>refresh</mat-icon>
    </button>
</div>

<div class="button-container facturas" >        
    <button mat-fab color="secondary" title="Facturas emitidas" aria-label="Facturas emitidas" (click)="navigateToFacturasEmitidas()">
        <mat-icon>search</mat-icon>
    </button>
</div>
<!-- <div class="button-container facturasParalelas" >        
    <button mat-fab color="secondary" title="Facturas paralelas" aria-label="Facturas paralelas">
        <mat-icon>arrow_right_alt</mat-icon>
    </button>
</div> -->
<div class="button-container export" >        
    <button mat-fab color="secondary" title="Exportar expediente" aria-label="Exportar expediente" (click)="exportarExpediente()">
        <img width="30px" src="assets/excel.png" />
    </button>
</div>

