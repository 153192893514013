import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Encargo } from '../../models/encargo';
import { SessionService } from '../../services/session.service';
import { TramitadorPortalService } from '../../services/tramitador-portal.service';
import { EncargoGenerali } from '../../models/encargoGenerali';

@Component({
  selector: 'app-header-encargo',
  templateUrl: './header-encargo.component.html',
  styleUrls: ['./header-encargo.component.scss']
})
export class HeaderEncargoComponent implements OnInit {

  nombreTramitador: string;
  countMensajes: number;
  idEncargo: string;
  idTipo : number;
  encargo : Encargo;
  encargoGenerali: EncargoGenerali;

  constructor(private sessionService: SessionService, private tramitadorService: TramitadorPortalService, private title : Title,private route: ActivatedRoute, ) {
    this.nombreTramitador = this.sessionService.getCurrentSession().tramitador.trm_nombre;
    this.route.params.subscribe(params =>{
      this.idEncargo = params['idEncargo'];
      this.idTipo    = params['idTipo'];
      //this.ngOnInit();
    });
   }

  ngOnInit(): void {
 
    console.log(this.idTipo);
    if(this.idTipo == 19) {
      this.tramitadorService.getExpedienteByIdGenerali(this.idEncargo).subscribe(data => {
        if(data){
          this.encargoGenerali = data['data'][0];
          console.log(this.encargoGenerali.upgen_lesionado) 
          this.title.setTitle(this.encargoGenerali.upgen_lesionado);
        }else{
          this.title.setTitle("CELSUS");
        }
      });
    }else{
      this.tramitadorService.getExpedienteById(this.idEncargo).subscribe(data => {
        if(data){
          this.encargo = data['data'][0];
          console.log(this.encargo.enup_lesionado) 
          this.title.setTitle(this.encargo.enup_lesionado);
        }else{
          this.title.setTitle("CELSUS");
        }
      });
    }

     

    this.tramitadorService.getCountMensajesSinLeerTramitador().subscribe(
      data => {
        if(data['response'] == "ok"){
          this.countMensajes = data['totalMensajes'];
        }else{

        }
      }
    );
  }

  logout(){
    const confirmed = confirm("Está a punto de cerrar sesión, y eso conlleva a cerrar sesión en TODAS las pestañas de CELSUS.");
    
    if(confirmed) {
      this.sessionService.logout();
    }
  }

}
