<app-header-facturacion></app-header-facturacion>

<div class="loading w-100 h-100 d-flex justify-content-center align-items-center position-absolute" *ngIf="loadingPageFlag">
    <mat-spinner></mat-spinner>
</div>

<div class="min-vh-100" *ngIf="!loadingPageFlag">

    <div class="row">

        <div class="col-sm-12 col-md-6 min-vh-100">
            <div class="p-3 element1">
                <mat-card class="h-100 d-flex flex-column mat-elevation-z8">
                    <div class="flex-shrink-1">
                        <mat-card-header>
                            <mat-card-title>Información general</mat-card-title>
                        </mat-card-header>
                        <mat-divider inset></mat-divider>
                    </div>
                    
                    <mat-card-content class="card-body d-flex flex-column justify-content-between">
                        <p class="m-0">Número expediente: <strong>{{numeroExp}}</strong></p>
                        <p class="m-0">Ref. Siniestro: {{refSiniestroExp}}</p>
                        <p class="m-0" *ngIf="isEditingFlag" >Factura: {{idFactura}}</p>
                        <p class="m-0">Provincia: {{provinciaExp}}</p>
                        <p class="m-0">Compañía: {{companiaExp}}</p>
                    </mat-card-content>
                </mat-card>
            </div>
            
            <div class="p-3 element2">
                <mat-card class="h-100 d-flex flex-column mat-elevation-z8">
                    <div class="flex-shrink-1">
                        <mat-card-header>
                            <mat-card-title>Listado de registros facturables</mat-card-title>
                        </mat-card-header>
                        <mat-divider inset></mat-divider>
                    </div>
                    

                    <div class="h-100 overflow-auto" [style.visibility]="loadingPageFlag ? 'hidden' : 'visible'">

                        <mat-card-content>

                            <table mat-table #tableRegistrosFacturables
                                #listRegistrosFacturables="cdkDropList"
                                [dataSource]="dataSourceRegistrosFacturables"
                                cdkDropList
                                [cdkDropListData]="dataSourceRegistrosFacturables.data"
                                [cdkDropListConnectedTo]="[listElementosFacturados]"
                                (cdkDropListDropped)="drop($event)"
                                [cdkDropListEnterPredicate]="noReturnPredicate"
                                class="table-registros">
                            
                                <ng-container matColumnDef="tipo">
                                    <th mat-header-cell *matHeaderCellDef> Tipo </th>
                                    <td mat-cell *matCellDef="let registro" [ngClass]="{'registro-a': getObjectType(registro) == 'Autorización', 'registro-c': getObjectType(registro) == 'Cita', 'registro-pp': getObjectType(registro) == 'Plantilla de productos', 'registro-lls': getObjectType(registro) == 'Llamada de seguimiento'}">{{getObjectType(registro)}}</td>
                                </ng-container>

                                <ng-container matColumnDef="id">
                                    <th mat-header-cell *matHeaderCellDef> Id </th>
                                    <td mat-cell *matCellDef="let registro" [ngClass]="{'registro-a': getObjectType(registro) == 'Autorización', 'registro-c': getObjectType(registro) == 'Cita', 'registro-pp': getObjectType(registro) == 'Plantilla de productos', 'registro-lls': getObjectType(registro) == 'Llamada de seguimiento'}">{{getId(registro)}}</td>
                                </ng-container>
                            
                                <ng-container matColumnDef="fecha">
                                    <th mat-header-cell *matHeaderCellDef> Fecha </th>
                                    <td mat-cell *matCellDef="let registro" [ngClass]="{'registro-a': getObjectType(registro) == 'Autorización', 'registro-c': getObjectType(registro) == 'Cita', 'registro-pp': getObjectType(registro) == 'Plantilla de productos', 'registro-lls': getObjectType(registro) == 'Llamada de seguimiento'}">{{getDate(registro) | date:'shortDate'}}</td>
                                </ng-container>
                            
                                <ng-container matColumnDef="precio">
                                    <th mat-header-cell *matHeaderCellDef> Precio </th>
                                    <td mat-cell *matCellDef="let registro" [ngClass]="{'registro-a': getObjectType(registro) == 'Autorización', 'registro-c': getObjectType(registro) == 'Cita', 'registro-pp': getObjectType(registro) == 'Plantilla de productos', 'registro-lls': getObjectType(registro) == 'Llamada de seguimiento'}">{{getPrice(registro) | number:'1.2-2'}}€</td>
                                </ng-container>

                                <ng-container matColumnDef="iva">
                                    <th mat-header-cell *matHeaderCellDef> Iva </th>
                                    <td mat-cell *matCellDef="let registro" [ngClass]="{'registro-a': getObjectType(registro) == 'Autorización', 'registro-c': getObjectType(registro) == 'Cita', 'registro-pp': getObjectType(registro) == 'Plantilla de productos', 'registro-lls': getObjectType(registro) == 'Llamada de seguimiento'}">{{getIva(registro)}}%</td>
                                </ng-container>

                                <ng-container matColumnDef="accion">
                                    <th mat-header-cell *matHeaderCellDef> Añadir </th>
                                    <td mat-cell *matCellDef="let registro" [ngClass]="{'registro-a': getObjectType(registro) == 'Autorización', 'registro-c': getObjectType(registro) == 'Cita', 'registro-pp': getObjectType(registro) == 'Plantilla de productos', 'registro-lls': getObjectType(registro) == 'Llamada de seguimiento'}">
                                        <button mat-mini-fab color="primary" aria-label="Añadir" class="boton" (click)="moveRegistro(registro)">
                                            <mat-icon>keyboard_arrow_right</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>
                            
                                <tr mat-header-row *matHeaderRowDef="displayedColumnsRegistrosFacturables"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsRegistrosFacturables;" cdkDrag></tr>
                            </table>

                            <!-- <mat-tab-group mat-align-tabs="start" class="h-100">
                                <mat-tab label="Registros">
    
                                    <table mat-table #tableRegistrosFacturables
                                        #listRegistrosFacturables="cdkDropList"
                                        [dataSource]="dataSourceRegistrosFacturables"
                                        cdkDropList
                                        [cdkDropListData]="dataSourceRegistrosFacturables.data"
                                        [cdkDropListConnectedTo]="[listElementosFacturados]"
                                        (cdkDropListDropped)="drop($event)"
                                        [cdkDropListEnterPredicate]="noReturnPredicate"
                                        class="table-registros">
                                    
                                        <ng-container matColumnDef="tipo">
                                            <th mat-header-cell *matHeaderCellDef> Tipo </th>
                                            <td mat-cell *matCellDef="let registro" [ngClass]="{'registro-a': getObjectType(registro) == 'Autorización', 'registro-c': getObjectType(registro) == 'Cita', 'registro-pp': getObjectType(registro) == 'Plantilla de productos', 'registro-lls': getObjectType(registro) == 'Llamada de seguimiento'}">{{getObjectType(registro)}}</td>
                                        </ng-container>
    
                                        <ng-container matColumnDef="id">
                                            <th mat-header-cell *matHeaderCellDef> Id </th>
                                            <td mat-cell *matCellDef="let registro" [ngClass]="{'registro-a': getObjectType(registro) == 'Autorización', 'registro-c': getObjectType(registro) == 'Cita', 'registro-pp': getObjectType(registro) == 'Plantilla de productos', 'registro-lls': getObjectType(registro) == 'Llamada de seguimiento'}">{{getId(registro)}}</td>
                                        </ng-container>
                                    
                                        <ng-container matColumnDef="fecha">
                                            <th mat-header-cell *matHeaderCellDef> Fecha </th>
                                            <td mat-cell *matCellDef="let registro" [ngClass]="{'registro-a': getObjectType(registro) == 'Autorización', 'registro-c': getObjectType(registro) == 'Cita', 'registro-pp': getObjectType(registro) == 'Plantilla de productos', 'registro-lls': getObjectType(registro) == 'Llamada de seguimiento'}">{{getDate(registro) | date:'shortDate'}}</td>
                                        </ng-container>
                                    
                                        <ng-container matColumnDef="precio">
                                            <th mat-header-cell *matHeaderCellDef> Precio </th>
                                            <td mat-cell *matCellDef="let registro" [ngClass]="{'registro-a': getObjectType(registro) == 'Autorización', 'registro-c': getObjectType(registro) == 'Cita', 'registro-pp': getObjectType(registro) == 'Plantilla de productos', 'registro-lls': getObjectType(registro) == 'Llamada de seguimiento'}">{{getPrice(registro) | number:'1.2-2'}}€</td>
                                        </ng-container>
    
                                        <ng-container matColumnDef="iva">
                                            <th mat-header-cell *matHeaderCellDef> Iva </th>
                                            <td mat-cell *matCellDef="let registro" [ngClass]="{'registro-a': getObjectType(registro) == 'Autorización', 'registro-c': getObjectType(registro) == 'Cita', 'registro-pp': getObjectType(registro) == 'Plantilla de productos', 'registro-lls': getObjectType(registro) == 'Llamada de seguimiento'}">{{getIva(registro)}}%</td>
                                        </ng-container>

                                        <ng-container matColumnDef="accion">
                                            <th mat-header-cell *matHeaderCellDef> Añadir </th>
                                            <td mat-cell *matCellDef="let registro" [ngClass]="{'registro-a': getObjectType(registro) == 'Autorización', 'registro-c': getObjectType(registro) == 'Cita', 'registro-pp': getObjectType(registro) == 'Plantilla de productos', 'registro-lls': getObjectType(registro) == 'Llamada de seguimiento'}">
                                                <button mat-mini-fab color="primary" aria-label="Añadir" class="boton" (click)="moveRegistro(registro)">
                                                    <mat-icon>keyboard_arrow_right</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>
                                    
                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsRegistrosFacturables"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsRegistrosFacturables;" cdkDrag></tr>
                                    </table>
    
    
                                </mat-tab>
                                <mat-tab label="Autorizaciones">
    
                                    <table mat-table #tableAutorizaciones
                                        #listAutorizaciones="cdkDropList"
                                        [dataSource]="dataSourceAutorizaciones"
                                        cdkDropList
                                        [cdkDropListData]="dataSourceAutorizaciones.data"
                                        [cdkDropListConnectedTo]="[listElementosFacturados]"
                                        (cdkDropListDropped)="drop($event)"
                                        [cdkDropListEnterPredicate]="noReturnPredicate"
                                        class="table-autorizaciones">
                                    
                                        <ng-container matColumnDef="id">
                                            <th mat-header-cell *matHeaderCellDef> ID </th>
                                            <td mat-cell *matCellDef="let autorizacion">{{autorizacion.aut_id_autorizaciones}}</td>
                                        </ng-container>
                                    
                                        <ng-container matColumnDef="fecha">
                                            <th mat-header-cell *matHeaderCellDef> Fecha </th>
                                            <td mat-cell *matCellDef="let autorizacion"> {{autorizacion.aut_fecha_creacion | date:'shortDate'}} </td>
                                        </ng-container>
    
                                        <ng-container matColumnDef="coste">
                                            <th mat-header-cell *matHeaderCellDef> Coste </th>
                                            <td mat-cell *matCellDef="let autorizacion"> {{autorizacion.aut_coste_real | number:'1.2-2'}}€</td>
                                        </ng-container>
    
                                        <ng-container matColumnDef="venta">
                                            <th mat-header-cell *matHeaderCellDef> Venta </th>
                                            <td mat-cell *matCellDef="let autorizacion"> {{autorizacion.producto.prd_coste_estandar | number:'1.2-2'}}€</td>
                                        </ng-container>
    
                                        <ng-container matColumnDef="IVA">
                                            <th mat-header-cell *matHeaderCellDef> IVA </th>
                                            <td mat-cell *matCellDef="let autorizacion"> {{autorizacion.producto.prd_iva}}%</td>
                                        </ng-container>

                                        <ng-container matColumnDef="anadir">
                                            <th mat-header-cell *matHeaderCellDef> Añadir </th>
                                            <td mat-cell *matCellDef="let autorizacion">
                                                <button mat-mini-fab color="primary" aria-label="Añadir" class="boton" (click)="moveAutorizacion(autorizacion)">
                                                    <mat-icon>keyboard_arrow_right</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>
                                    
                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsAutorizaciones"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsAutorizaciones;" cdkDrag></tr>
                                    </table>
    
    
                                </mat-tab>
                                <mat-tab label="Citas">
    
                                    <table mat-table #tableCitas
                                        #listCitas="cdkDropList"
                                        [dataSource]="dataSourceCitas"
                                        cdkDropList
                                        [cdkDropListData]="dataSourceCitas.data"
                                        [cdkDropListConnectedTo]="[listElementosFacturados]"
                                        (cdkDropListDropped)="drop($event)"
                                        [cdkDropListEnterPredicate]="noReturnPredicate"
                                        class="table-citas">
                                    
                                        <ng-container matColumnDef="id">
                                            <th mat-header-cell *matHeaderCellDef> ID </th>
                                            <td mat-cell *matCellDef="let cita">{{cita.cup_id_citas}}</td>
                                        </ng-container>
                                    
                                        <ng-container matColumnDef="fecha">
                                            <th mat-header-cell *matHeaderCellDef> Fecha Consulta </th>
                                            <td mat-cell *matCellDef="let cita"> {{cita.cup_fecha_creacion | date:'shortDate'}} </td>
                                        </ng-container>
    
                                        <ng-container matColumnDef="coste">
                                            <th mat-header-cell *matHeaderCellDef> Coste </th>
                                            <td mat-cell *matCellDef="let cita"> {{cita.cup_coste_real | number:'1.2-2'}}€</td>
                                        </ng-container>
    
                                        <ng-container matColumnDef="venta">
                                            <th mat-header-cell *matHeaderCellDef> Venta </th>
                                            <td mat-cell *matCellDef="let cita"> {{cita.producto.prd_coste_estandar | number:'1.2-2'}}€</td>
                                        </ng-container>
    
                                        <ng-container matColumnDef="IVA">
                                            <th mat-header-cell *matHeaderCellDef> IVA </th>
                                            <td mat-cell *matCellDef="let cita"> {{cita.producto.prd_iva}}%</td>
                                        </ng-container>

                                        <ng-container matColumnDef="anadir">
                                            <th mat-header-cell *matHeaderCellDef> Añadir </th>
                                            <td mat-cell *matCellDef="let cita">
                                                <button mat-mini-fab color="primary" aria-label="Añadir" class="boton" (click)="moveCita(cita)">
                                                    <mat-icon>keyboard_arrow_right</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>
                                    
                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsCitas"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsCitas;" cdkDrag></tr>
                                    </table>
    
    
                                </mat-tab>
                                <mat-tab label="Plantillas de productos">
    
                                    <table mat-table #tablePlantillasProd
                                        #listPlantillasProd="cdkDropList"
                                        [dataSource]="dataSourcePlantillasProd"
                                        cdkDropList
                                        [cdkDropListData]="dataSourcePlantillasProd.data"
                                        [cdkDropListConnectedTo]="[listElementosFacturados]"
                                        (cdkDropListDropped)="drop($event)"
                                        [cdkDropListEnterPredicate]="noReturnPredicate"
                                        class="table-plantillas-prod">
    
                                        <ng-container matColumnDef="id">
                                            <th mat-header-cell *matHeaderCellDef> ID </th>
                                            <td mat-cell *matCellDef="let plantillaProd">{{plantillaProd.pp_id_plantilla_productos}}</td>
                                        </ng-container>
                                    
                                        <ng-container matColumnDef="fecha">
                                            <th mat-header-cell *matHeaderCellDef> Fecha </th>
                                            <td mat-cell *matCellDef="let plantillaProd"> {{plantillaProd.pp_fecha_creacion | date:'shortDate'}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="venta">
                                            <th mat-header-cell *matHeaderCellDef> Venta </th>
                                            <td mat-cell *matCellDef="let plantillaProd"> {{plantillaProd.pp_precio_venta | number:'1.2-2'}}€ </td>
                                        </ng-container>

                                        <ng-container matColumnDef="IVA">
                                            <th mat-header-cell *matHeaderCellDef> IVA </th>
                                            <td mat-cell *matCellDef="let plantillaProd"> {{plantillaProd.pp_iva}}%</td>
                                        </ng-container>

                                        <ng-container matColumnDef="anadir">
                                            <th mat-header-cell *matHeaderCellDef> Añadir </th>
                                            <td mat-cell *matCellDef="let plantillaProd">
                                                <button mat-mini-fab color="primary" aria-label="Añadir" class="boton" (click)="movePlantillaProd(plantillaProd)">
                                                    <mat-icon>keyboard_arrow_right</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>
                                    
                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsPlantillasProd"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsPlantillasProd;" cdkDrag></tr>
                                    </table>
    
    
                                </mat-tab>
                                <mat-tab label="Llamadas de seguimiento">
    
                                    <table mat-table #tableLlamadasSeg
                                        #listLlamadasSeg="cdkDropList"
                                        [dataSource]="dataSourceLlamadasSeg"
                                        cdkDropList
                                        [cdkDropListData]="dataSourceLlamadasSeg.data"
                                        [cdkDropListConnectedTo]="[listElementosFacturados]"
                                        (cdkDropListDropped)="drop($event)"
                                        [cdkDropListEnterPredicate]="noReturnPredicate"
                                        class="table-llamadas-seg">
    
                                        <ng-container matColumnDef="id">
                                            <th mat-header-cell *matHeaderCellDef> ID </th>
                                            <td mat-cell *matCellDef="let llamadaSeg">{{llamadaSeg.lls_id_llamadas_seg}}</td>
                                        </ng-container>
                                    
                                        <ng-container matColumnDef="fecha">
                                            <th mat-header-cell *matHeaderCellDef> Fecha </th>
                                            <td mat-cell *matCellDef="let llamadaSeg"> {{llamadaSeg.lls_fecha_creacion | date:'shortDate'}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="venta">
                                            <th mat-header-cell *matHeaderCellDef> Venta </th>
                                            <td mat-cell *matCellDef="let llamadaSeg"> {{llamadaSeg.lls_precio | number:'1.2-2'}}€ </td>
                                        </ng-container>

                                        <ng-container matColumnDef="IVA">
                                            <th mat-header-cell *matHeaderCellDef> IVA </th>
                                            <td mat-cell *matCellDef="let llamadaSeg"> {{llamadaSeg.lls_iva}}%</td>
                                        </ng-container>

                                        <ng-container matColumnDef="anadir">
                                            <th mat-header-cell *matHeaderCellDef> Añadir </th>
                                            <td mat-cell *matCellDef="let llamadaSeg">
                                                <button mat-mini-fab color="primary" aria-label="Añadir" class="boton" (click)="moveLlamadaSeg(llamadaSeg)">
                                                    <mat-icon>keyboard_arrow_right</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>
                                    
                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsLlamadasSeg"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsLlamadasSeg;" cdkDrag></tr>
                                    </table>
    
                                </mat-tab>
                              </mat-tab-group> -->
                        </mat-card-content>

                    </div>


                    
                </mat-card>
            </div>
            <div class="p-3 element4">
                <mat-card class="h-100 d-flex flex-column mat-elevation-z8">
                    <mat-card-header>
                        <mat-card-title>Información económica</mat-card-title>
                    </mat-card-header>

                    <mat-divider inset></mat-divider>

                    <mat-card-content class="flex-grow-1 overflow-auto card-body">
                        <div class="h-100 row">
                            <div class="col-6">
                                <div class="d-flex justify-content-between border-bottom">
                                    <p class="m-0">Base imponible (0%):</p>
                                    <p class="m-0">{{basImp0 | number:'1.2-2'}}€</p>
                                </div>
                                <div class="d-flex justify-content-between border-bottom">
                                    <p class="m-0">Base imponible (4%):</p>
                                    <p class="m-0">{{basImp4 | number:'1.2-2'}}€</p>
                                </div>
                                <div class="d-flex justify-content-between border-bottom">
                                    <p class="m-0">Base imponible (10%):</p>
                                    <p class="m-0">{{basImp10 | number:'1.2-2'}}€</p>
                                </div>
                                <div class="d-flex justify-content-between border-bottom">
                                    <p class="m-0">Base imponible (21%):</p>
                                    <p class="m-0">{{basImp21 | number:'1.2-2'}}€</p>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="d-flex justify-content-between border-bottom">
                                    <p class="m-0"><br></p>
                                </div>
                                <div class="d-flex justify-content-between border-bottom">
                                    <p class="m-0">IVA (4%):</p>
                                    <p class="m-0">{{totalIva4 | number:'1.2-2'}}€</p>
                                </div>
                                <div class="d-flex justify-content-between border-bottom">
                                    <p class="m-0">Total IVA (10%):</p>
                                    <p class="m-0">{{totalIva10 | number:'1.2-2'}}€</p>
                                </div>
                                <div class="d-flex justify-content-between border-bottom">
                                    <p class="m-0">Total IVA (21%):</p>
                                    <p class="m-0">{{totalIva21 | number:'1.2-2'}}€</p>
                                </div>
                            </div>
                            <div class="col-12 d-flex align-items-end">
                                <p class="m-0"><strong>Total facturado: </strong>{{totalFact | number:'1.2-2'}}€</p>                                             
                            </div>
                        </div>
                        
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

        <div class="col-sm-12 col-md-5 min-vh-100">
            <div class="p-3 element3">
                <mat-card class="h-100 d-flex flex-column mat-elevation-z8">
                    <mat-card-header>
                        <mat-card-title>Información de factura</mat-card-title>
                    </mat-card-header>
                    
                    <mat-divider inset></mat-divider>

                    <mat-card-content class="d-flex flex-column overflow-auto card-body">

                        <div class="flex-grow-1 mb-5 d-flex flex-column">

                            <div class="flex-shrink-1">

                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label *ngIf="productos.length > 0">Productos</mat-label>
                                    <mat-label *ngIf="productos.length == 0">No hay productos disponibles</mat-label>
                                    <mat-select [formControl]="productosForm" [disabled]="productos.length == 0">
                                        <div class="p-3 sticky-top b-white border-bottom">
                                            <input matInput placeholder="Filtrar productos" [(ngModel)]="nombreProducto" (keydown)="$event.stopPropagation()" (keyup)="filterProductos(nombreProducto)">
                                        </div>
                                        <mat-option *ngFor="let producto of filteredProductos" [value]="producto" (click)="addProductoToTable(producto)">
                                            {{producto.prd_nombre}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
        
                                <mat-form-field appearance="outline" class="w-50" *ngIf="!isEditingFlag">
                                    <mat-label>Serie</mat-label>
                                    <mat-select [(ngModel)]="serie" (selectionChange)="checkSerieValida()">
                                        <mat-option class="h-auto" *ngFor="let serieValue of series" [value]="serieValue">
                                          <div>{{serieValue.ss_id_series}} | {{serieValue.ss_prefijo}}</div>
                                          <div class="serie-description"><small>{{serieValue.ss_descripcion}}</small></div>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="w-50" *ngIf="numberFacturaShowFlag && !isEditingFlag">
                                    <mat-label>Número de factura</mat-label>
                                    <input type="text" matInput [(ngModel)]="numFactura">
                                </mat-form-field>
        
                                <h3>Elementos facturados</h3>
        
                            </div>
        
                            <div class="h-100 border overflow-auto">
                
                                <table mat-table #tableElementosFact
                                    #listElementosFacturados="cdkDropList"
                                    [dataSource]="dataSourceElementosFact"
                                    cdkDropList
                                    [cdkDropListData]="dataSourceElementosFact.data"
                                    [cdkDropListConnectedTo]="[listElementosFacturados]"
                                    (cdkDropListDropped)="drop($event)"
                                    class="table-elementos-fact">
        
                                    <ng-container matColumnDef="tipo">
                                        <th mat-header-cell *matHeaderCellDef> Tipo </th>
                                        <td mat-cell *matCellDef="let elementoFact" [ngClass]="{'registro-a': getObjectType(elementoFact) == 'Autorización', 'registro-c': getObjectType(elementoFact) == 'Cita', 'registro-pp': getObjectType(elementoFact) == 'Plantilla de productos', 'registro-lls': getObjectType(elementoFact) == 'Llamada de seguimiento', 'registro-p': getObjectType(elementoFact) == 'Producto'}">{{getObjectType(elementoFact)}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef> Id </th>
                                        <td mat-cell *matCellDef="let elementoFact" [ngClass]="{'registro-a': getObjectType(elementoFact) == 'Autorización', 'registro-c': getObjectType(elementoFact) == 'Cita', 'registro-pp': getObjectType(elementoFact) == 'Plantilla de productos', 'registro-lls': getObjectType(elementoFact) == 'Llamada de seguimiento', 'registro-p': getObjectType(elementoFact) == 'Producto'}">{{getId(elementoFact)}}</td>
                                    </ng-container>
                                
                                    <ng-container matColumnDef="fecha">
                                        <th mat-header-cell *matHeaderCellDef> Fecha </th>
                                        <td mat-cell *matCellDef="let elementoFact" [ngClass]="{'registro-a': getObjectType(elementoFact) == 'Autorización', 'registro-c': getObjectType(elementoFact) == 'Cita', 'registro-pp': getObjectType(elementoFact) == 'Plantilla de productos', 'registro-lls': getObjectType(elementoFact) == 'Llamada de seguimiento', 'registro-p': getObjectType(elementoFact) == 'Producto'}">{{getDate(elementoFact) | date:'shortDate'}}</td>
                                    </ng-container>
                                
                                    <ng-container matColumnDef="precio">
                                        <th mat-header-cell *matHeaderCellDef> Precio </th>
                                        <td mat-cell *matCellDef="let elementoFact" [ngClass]="{'registro-a': getObjectType(elementoFact) == 'Autorización', 'registro-c': getObjectType(elementoFact) == 'Cita', 'registro-pp': getObjectType(elementoFact) == 'Plantilla de productos', 'registro-lls': getObjectType(elementoFact) == 'Llamada de seguimiento', 'registro-p': getObjectType(elementoFact) == 'Producto'}">{{getPrice(elementoFact) | number:'1.2-2'}}€</td>
                                    </ng-container>

                                    <ng-container matColumnDef="iva">
                                        <th mat-header-cell *matHeaderCellDef> Iva </th>
                                        <td mat-cell *matCellDef="let elementoFact" [ngClass]="{'registro-a': getObjectType(elementoFact) == 'Autorización', 'registro-c': getObjectType(elementoFact) == 'Cita', 'registro-pp': getObjectType(elementoFact) == 'Plantilla de productos', 'registro-lls': getObjectType(elementoFact) == 'Llamada de seguimiento', 'registro-p': getObjectType(elementoFact) == 'Producto'}">{{getIva(elementoFact)}}%</td>
                                    </ng-container>
        
                                    <ng-container matColumnDef="accion">
                                        <th mat-header-cell *matHeaderCellDef> Eliminar </th>
                                        <td mat-cell *matCellDef="let elementoFact" [ngClass]="{'registro-a': getObjectType(elementoFact) == 'Autorización', 'registro-c': getObjectType(elementoFact) == 'Cita', 'registro-pp': getObjectType(elementoFact) == 'Plantilla de productos', 'registro-lls': getObjectType(elementoFact) == 'Llamada de seguimiento', 'registro-p': getObjectType(elementoFact) == 'Producto'}">
                                            <button mat-mini-fab color="warn" aria-label="Borrar" (click)="deleteElementoFacturado(elementoFact)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>
                                
                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsElementosFact"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsElementosFact;" cdkDrag></tr>
                                </table>
        
                            </div>

                        </div>


                        <div>
        
                            <mat-form-field class="w-50" appearance="outline">
                                <mat-label>Detalles</mat-label>
                                <textarea matInput rows="7" [(ngModel)]="detalles"></textarea>
                            </mat-form-field>
    
                            <mat-form-field class="w-50" appearance="outline">
                                <mat-label>Observaciones</mat-label>
                                <textarea matInput rows="7" [(ngModel)]="observaciones"></textarea>
                            </mat-form-field>
    
                            <mat-form-field class="w-50" appearance="outline">
                                <mat-label>A facturar</mat-label>
                                <textarea matInput rows="7" [(ngModel)]="aFacturar"></textarea>
                            </mat-form-field>
    
                            <mat-form-field class="w-50" appearance="outline">
                                <mat-label>Observaciones de facturacion</mat-label>
                                <textarea matInput rows="7" [(ngModel)]="observacionesFact"></textarea>
                            </mat-form-field>
    
                        </div>

                    </mat-card-content>

                </mat-card>
            </div>
        </div>

    </div>
    
</div>

<div class="button-container imprimir" *ngIf="!loadingPageFlag">
    <button mat-fab disabled color="secondary" title="Imprimir factura" aria-label="Imprimir factura">
        <mat-icon>insert_drive_file</mat-icon>
    </button>
</div>

<div class="button-container guardar" *ngIf="!loadingPageFlag">        
    <button mat-fab color="secondary" title="Guardar factura" aria-label="Guardar factura" (click)="guardarFactura()">
        <mat-icon>save</mat-icon>
    </button>
</div>
<div class="button-container export" *ngIf="!loadingPageFlag">        
    <button mat-fab color="secondary" title="Exportar factura" aria-label="Exportar factura" (click)="exportarListadoProductosFact()">
        <img width="30px" src="assets/excel.png" />
    </button>
</div>
