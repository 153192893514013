import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormatoConsulta } from '../../../models/formato_consulta/formato-consulta.model';
import { Tramitador } from '../../../models/tramitador';
import { TramitadorPortalService } from '../../../services/tramitador-portal.service';
import { PageEvent } from '@angular/material/paginator';
import { FuncionesPrincipalService } from '../../../services/funciones-principal.service';
import { Encargo } from 'src/app/models/encargo';

@Component({
  selector: 'app-encargo-pericial-medico',
  templateUrl: './encargo-pericial-medico.component.html',
  styleUrls: ['./encargo-pericial-medico.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EncargoPericialMedicoComponent implements OnInit {

  encargosTramitacion : Encargo[];

  encargosLength : number;
  estados : any[] = [
    {
      id          : 0,
      description : "Pendientes de revisión",
    },
    {
      id          : 1,
      description : "Revisados",
    },
    {
      id          : 2,
      description : "Finalizados",
    }
  ];

  //variables para el filtro.
  company           : string = "";
  sinesterReference : string = "";
  dateStart         : Date   = null;
  dateLimit         : Date   = null;
  dateConsulta      : Date   = null;
  lesionado         : string = "";
  encargosFilter    : Encargo[];

  //Formato consulta
  formatoConsulta : FormatoConsulta;
  mark            : boolean;

  //PAGINATION
  startIndex = 0;
  length = 100;
  pageSize = 5;
  endIndex = this.pageSize;

  pageSizeOptions: number[] = [5, 10, 20, 50, 100];
  DISPLAY_FOOTER: number = 0;


  paginaFinalizados: number = 1;

  encargosArrayAux : Encargo[];
  busquedaType : string;

  tramitador: Tramitador;
  mostrarProgressSpinner1 = true;
  mostrarProgressSpinner2 = true;
  mostrarProgressSpinner3 = true;
  mostrarProgressSpinner4 = true;
  mostrarProgressSpinner5 = true;
  mostrarProgressSpinner6 = true;

  loadingPageFlag: boolean = false;

  checkFechaCreacion: boolean = false;
  checkFechaLimite: boolean = false;

  clickEstado : number;



  constructor(private tramitadorService: TramitadorPortalService, private funcionesService: FuncionesPrincipalService) { }

  ngOnInit(): void {
    this.getEncargosPericial(2);

    this.DISPLAY_FOOTER = this.pageSizeOptions[0];
    this.pageSize = this.pageSizeOptions[0];
  }

  setpage(pagedata: PageEvent) {
    this.startIndex = pagedata.pageIndex * pagedata.pageSize;
    this.endIndex = (pagedata.pageIndex + 1) * pagedata.pageSize;
  }


  getEncargosPericial(clickEstado : number) {
    this.encargosFilter = [];
    this.tramitadorService.getEncargosPericialMedico(clickEstado).subscribe(
      data => {
        this.mostrarProgressSpinner5 = false;
        
        if(data['response'] == "ok"){
          //this.encargosPendienteEnviar = data['encargosPendienteEnviar'];
          this.encargosFilter = data['encargosTramitacion'];
          this.encargosArrayAux = this.encargosFilter;
          this.encargosLength = this.encargosFilter.length;
          this.loadingPageFlag = true;
        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner5 = false;
        this.loadingPageFlag = false;
      }
    );
  }


  colorEncargo(fechaLimite: string){
    var color = '';
    var fechaNow = new Date();

    if(fechaLimite != null){
      var quitarHoraFechaLimite = fechaLimite.split(" ");
      var arrayFechaLimite = quitarHoraFechaLimite[0].split("/");
      var fechaLimiteDosAntes = new Date(parseInt(arrayFechaLimite[2]), parseInt(arrayFechaLimite[1]) - 1, parseInt(arrayFechaLimite[0]));
      var fechaLimiteFormat = new Date(parseInt(arrayFechaLimite[2]), parseInt(arrayFechaLimite[1]) - 1, parseInt(arrayFechaLimite[0]));

      fechaLimiteDosAntes.setDate(fechaLimiteFormat.getDate() - 2);

      if(fechaNow<fechaLimiteDosAntes){
        color = 'color_verde';
      }else if(fechaNow.getFullYear()==fechaLimiteDosAntes.getFullYear() && fechaNow.getMonth()==fechaLimiteDosAntes.getMonth() && fechaNow.getDate()==fechaLimiteDosAntes.getDate()
      || (fechaNow>fechaLimiteDosAntes && fechaNow<fechaLimiteFormat)){
        color = 'color_amarillo';
      }else if(fechaNow.getFullYear()==fechaLimiteFormat.getFullYear() && fechaNow.getMonth()==fechaLimiteFormat.getMonth() && fechaNow.getDate()==fechaLimiteFormat.getDate() 
      || fechaNow>fechaLimiteFormat){
        color = 'color_rojo';
      }else{
        color = '';
      }
  
      return color;
    }
  }


  colorEncargoFila(fechaLimite: string, prioridad: number){
    var color = '';
    var fechaNow = new Date();

    if(fechaLimite != null){
      var quitarHoraFechaLimite = fechaLimite.split(" ");
      var arrayFechaLimite = quitarHoraFechaLimite[0].split("/");
      var fechaLimiteDosAntes = new Date(parseInt(arrayFechaLimite[2]), parseInt(arrayFechaLimite[1]) - 1, parseInt(arrayFechaLimite[0]));
      var fechaLimiteFormat = new Date(parseInt(arrayFechaLimite[2]), parseInt(arrayFechaLimite[1]) - 1, parseInt(arrayFechaLimite[0]));

      fechaLimiteDosAntes.setDate(fechaLimiteFormat.getDate() - 2);

      if(fechaNow<fechaLimiteDosAntes){
        color = 'color_verde_fila';
      }else if(fechaNow.getFullYear()==fechaLimiteDosAntes.getFullYear() && fechaNow.getMonth()==fechaLimiteDosAntes.getMonth() && fechaNow.getDate()==fechaLimiteDosAntes.getDate()
      || (fechaNow>fechaLimiteDosAntes && fechaNow<fechaLimiteFormat)){
        color = 'color_amarillo_fila';
      }else if(fechaNow.getFullYear()==fechaLimiteFormat.getFullYear() && fechaNow.getMonth()==fechaLimiteFormat.getMonth() && fechaNow.getDate()==fechaLimiteFormat.getDate() 
      || fechaNow>fechaLimiteFormat){
        color = 'color_rojo_fila';
      }else{
        color = '';
      }
      
    }

    if(prioridad != null){
      if(prioridad == 1){
        color = color + ' color_urgente_fila';
      }
    }

    return color;
  }

  //Cuando cambiamos de pestaña, la barra de búsqueda se pone en blanco, y el array recibe el valor en función de la
  //pestaña en que se encuentre
  onTabChanged($event) {
    let clickedIndex = $event.index;
    this.busquedaType = "";
    switch(clickedIndex) { 
      case 0: { 
          /*this.encargosArrayAux = this.encargosTramitacion;
          this.encargosFilter   = this.encargosTramitacion;*/
          this.loadingPageFlag = false;
          this.clickEstado = 2;
          this.getEncargosPericial(this.clickEstado);
         break; 
      } 
      case 1: { 
        /*this.encargosArrayAux = this.encargosEnviados;
        this.encargosFilter   = this.encargosEnviados;*/
        this.loadingPageFlag = false;
        this.clickEstado = 3;
        this.getEncargosPericial(this.clickEstado);

         break; 
      } 
      case 2: { 
        /*this.encargosArrayAux = this.encargosPendienteRevision;
        this.encargosFilter   = this.encargosPendienteRevision;*/
        this.loadingPageFlag = false;
        this.clickEstado = 4;
        this.getEncargosPericial(this.clickEstado);

         break; 
      } 
    } 
  }

  sortOrder(encargo : Encargo[], sort: string) {

    switch(sort) {
      case 'ciaNombre' : {
        encargo.sort((a,b) => (a.ciaNombre > b.ciaNombre ? -1 : 1));
        break;
      }
      case 'created_at' : {
        encargo.sort((a,b) => (new Date(a.created_at.split('/').reverse().join('-')) < new Date(b.created_at.split('/').reverse().join('-')) ? 1 : -1));
        break;
      }
      case 'enup_lesionado' : {
        encargo.sort((a,b) => (a.enup_lesionado < b.enup_lesionado ? -1 : 1));
        break;
      }
      case 'enup_localidad' : {
        encargo.sort((a,b) => (a.enup_localidad > b.enup_localidad ? -1 : 1));
        break;
      }
      case 'enup_fecha_limite' : {
        encargo.sort((a,b) => (new Date(a.enup_fecha_limite.split('/').reverse().join('-')) > new Date(b.enup_fecha_limite.split('/').reverse().join('-')) ? -1 : 1));
        break;
      }
      case 'nombreMedico' : {
        encargo.sort((a,b) => (a.nombreMedico < b.nombreMedico ? -1 : 1)); 
      }
      case 'reset' : {
        encargo.sort((a,b) => (new Date(a.created_at.split('/').reverse().join('-')) < new Date(b.created_at.split('/').reverse().join('-')) ? -1 : 1));
        this.encargosArrayAux = this.encargosFilter;
        this.encargosLength = this.encargosArrayAux.length;
        this.sinesterReference = "";
        this.lesionado = "";
        this.company = "";
        this.dateStart = null;
        this.dateLimit = null;
        this.dateConsulta = null;
        break;
      }
    }
  }

  filterEncargos(valor : number) {
    var filterEncargos: Encargo[] = [];
    var today : Date = new Date();
    var dateToday : string;

    if(today.getMonth() < 10) {
      dateToday = today.getDate().toString()+ '/0' + (today.getMonth() +1).toString() +'/' + today.getFullYear().toString();
    }else{
      console.log(today.getMonth())
      dateToday = today.getDate().toString()+ '/' + today.getMonth().toString() +'/' + today.getFullYear().toString();
    }
    
    
    if(this.company != "" ||
    this.sinesterReference != "" ||
    this.dateStart ||
    this.dateLimit ||
    this.lesionado != ""
    ){
      this.encargosFilter.forEach(order => {
        if((this.company != "" ? order.ciaNombre.toLowerCase().includes(this.company.toLowerCase()) : true) &&
            (this.sinesterReference != "" ? order.enup_referencia_siniestro.toLowerCase().includes(this.sinesterReference.toLowerCase()) : true) && 
            (this.dateStart ? new Date(order.created_at) >= new Date(this.dateStart) : true) &&     
            (this.dateLimit ? new Date(order.created_at) <= new Date(this.dateLimit) : true) &&
            (this.lesionado !="" ? order.enup_lesionado.toLowerCase().includes(this.lesionado.toLowerCase()) : true )
        ){
          filterEncargos.push(order);
          
        }
        this.encargosArrayAux = filterEncargos; 
      });
      
    }
    else{
      this.encargosArrayAux = this.encargosFilter;
    }
    this.encargosLength = this.encargosArrayAux.length;
  }

  public onClickEstrella(encargo: Encargo){

    this.encargosArrayAux.find(encargoAux => encargoAux.id == encargo.id).enup_estrella = !encargo.enup_estrella;

    let datos = {
      'encargoIdPlataforma': encargo.id_plataforma
    };

    this.funcionesService.cambiarEstadoEstrella(datos).subscribe(
      data => {
        console.log(data);
      },
      error =>{
        console.log(error);
      }
    );
  }



}
