<app-header-facturacion></app-header-facturacion>

<div class="loading w-100 h-100 d-flex justify-content-center align-items-center position-absolute" *ngIf="loadingPageFlag">
    <mat-spinner></mat-spinner>
</div>

<div class="min-vh-100 container">

    <div class="row">
        
        <div class="col-4 p-4">
            <div class="row">
                <div class="col-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Año creación de facturas:</mat-label>
                        <input matInput type="number" min="1900" max="2099" step="1" [(ngModel)]="yearFilter" (change)="obtenerFacturasEmitidas()">
                    </mat-form-field>
                </div>

            </div>

        </div>

        <div class="col-8 p-4">

            <div class="h-100 row">
                <div class="col d-flex flex-column align-items-center button-container">
                    <button mat-fab color="primary" aria-label="Example icon button with a home icon" (click)="updateToDuplicada()" (keyup)="searchFactura()">
                        <mat-icon>arrow_right_alt</mat-icon>
                    </button>
                    <p>Duplicada</p>
                </div>

                <div class="col d-flex flex-column align-items-center button-container">
                    <button mat-fab color="primary" aria-label="Example icon button with a home icon" (click)="createIncidencia()">
                        <mat-icon>warning</mat-icon>
                    </button>
                    <p>Incidencia</p>
                </div>

                <div class="col d-flex flex-column align-items-center button-container">
                    <button mat-fab color="primary" aria-label="Example icon button with a home icon" (click)="contabilizarFacturaEmitida()">
                        <mat-icon>bar_chart</mat-icon>
                    </button>
                    <p>Contabilizar</p>
                </div>

                <div class="col d-flex flex-column align-items-center button-container">
                    <button mat-fab color="primary" aria-label="Example icon button with a home icon" (click)="exportarFacturas()">
                        <img width="30px" src="assets/excel.png" />
                    </button>
                    <p>Excel</p>
                </div>

                <div class="col d-flex flex-column align-items-center button-container">
                    <button mat-fab color="primary" aria-label="Example icon button with a home icon" (click)="generarInformeFactura()">
                        <mat-icon>print</mat-icon>
                    </button>
                    <p>Imprimir</p>
                </div>

                <div class="col d-flex flex-column align-items-center button-container">
                    <button mat-fab color="primary" aria-label="Example icon button with a home icon" (click)="updateToCobrada()">
                        <mat-icon>money</mat-icon>
                    </button>
                    <p>Todo cobrado</p>
                </div>

                <div class="col d-flex flex-column align-items-center button-container">
                    <button mat-fab color="primary" aria-label="Example icon button with a home icon" (click)="obtenerFacturasEmitidas()">
                        <mat-icon>refresh</mat-icon>
                    </button>
                    <p>Actualizar</p>
                </div>
            </div>
                
        </div>

        <div class="col-12 py-4 px-0 border-top border-bottom">

            <div class="mat-elevation-z8 px-3">
                <div class="overflow-auto">
                    <table mat-table #tableFacturasEmitidas [dataSource]="dataSourceFacturasEmitidas" class="w-100 custom-inputs custom-align-header">

                        <ng-container matColumnDef="checkbox">
                            <th class="p-2" mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                                [checked]="selection.hasValue() && isAllSelected()"
                                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                                [aria-label]="checkboxLabel()">
                                </mat-checkbox>
                            </th>
                            <td class="p-2" mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                                (change)="$event ? selection.toggle(row) : null"
                                                [checked]="selection.isSelected(row)"
                                                [aria-label]="checkboxLabel(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>
    
                        <ng-container matColumnDef="nFactura">
                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header>
                                Nº Factura
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input matInput type="text" [(ngModel)]="filteredValues['nFactura']" (keyup)="searchFactura()">
                                </mat-form-field>
                            </th>
                            <td class="p-2" mat-cell *matCellDef="let facturaEmitida" (click)="obtenerFacturaEmitidaProductos(facturaEmitida)" (dblclick)="navigateToFacturacion(facturaEmitida)"> {{facturaEmitida.facte_id_facturas_emitidas}} </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="expediente">
                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> 
                                Expediente
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input matInput type="text" [(ngModel)]="filteredValues['expediente']" (keyup)="searchFactura()">
                                </mat-form-field>
                            </th>
                            <td class="p-2" mat-cell *matCellDef="let facturaEmitida" (click)="obtenerFacturaEmitidaProductos(facturaEmitida)" (dblclick)="navigateToFacturacion(facturaEmitida)"> {{facturaEmitida.facte_expediente}} </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="compañia">
                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> 
                                Compañia
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input matInput type="text" [(ngModel)]="filteredValues['compania']" (keyup)="searchFactura()">
                                </mat-form-field>
                            </th>
                            <td class="p-2" mat-cell *matCellDef="let facturaEmitida" (click)="obtenerFacturaEmitidaProductos(facturaEmitida)" (dblclick)="navigateToFacturacion(facturaEmitida)"> {{facturaEmitida.facte_compania}} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="lesionado">
                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> 
                                Lesionado
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input matInput type="text" [(ngModel)]="filteredValues['lesionado']" (keyup)="searchFactura()">
                                </mat-form-field>
                            </th>
                            <td class="p-2" mat-cell *matCellDef="let facturaEmitida" (click)="obtenerFacturaEmitidaProductos(facturaEmitida)" (dblclick)="navigateToFacturacion(facturaEmitida)"> {{facturaEmitida.facte_lesionado}} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="refSiniestro">
                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header>
                                Ref. Siniestro
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input matInput type="text" [(ngModel)]="filteredValues['refSiniestro']" (keyup)="searchFactura()">
                                </mat-form-field>
                            </th>
                            <td class="p-2" mat-cell *matCellDef="let facturaEmitida" (click)="obtenerFacturaEmitidaProductos(facturaEmitida)" (dblclick)="navigateToFacturacion(facturaEmitida)"> {{facturaEmitida.facte_expediente_ref_siniestro}}  </td>
                        </ng-container>
    
                        <ng-container matColumnDef="provincia">
                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header>
                                Provincia
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input matInput type="text" [(ngModel)]="filteredValues['provincia']" (keyup)="searchFactura()">
                                </mat-form-field>
                            </th>
                            <td class="p-2" mat-cell *matCellDef="let facturaEmitida" (click)="obtenerFacturaEmitidaProductos(facturaEmitida)" (dblclick)="navigateToFacturacion(facturaEmitida)"> {{facturaEmitida.facte_provincia}} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="fechaFactura">
                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header>  
                                Fecha Factura
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input matInput type="text" [(ngModel)]="filteredValues['fechaFactura']" (keyup)="searchFactura()">
                                </mat-form-field>
                            </th>
                            <td class="p-2" mat-cell *matCellDef="let facturaEmitida" (click)="obtenerFacturaEmitidaProductos(facturaEmitida)" (dblclick)="navigateToFacturacion(facturaEmitida)"> {{facturaEmitida.facte_fecha_creacion}} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="totalFacturado">
                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> 
                                Total facturado 
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input matInput type="text" [(ngModel)]="filteredValues['totalFacturado']" (keyup)="searchFactura()">
                                </mat-form-field>
                            </th>
                            <td class="p-2" mat-cell *matCellDef="let facturaEmitida" (click)="obtenerFacturaEmitidaProductos(facturaEmitida)" (dblclick)="navigateToFacturacion(facturaEmitida)"> {{facturaEmitida.facte_total_facturado | number:'1.2-2'}}€ </td>
                        </ng-container>
    
                        <ng-container matColumnDef="totalImponible">
                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header>  
                                Total imponible
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input matInput type="text" [(ngModel)]="filteredValues['totalImponible']" (keyup)="searchFactura()">
                                </mat-form-field>
                            </th>
                            <td class="p-2" mat-cell *matCellDef="let facturaEmitida" (click)="obtenerFacturaEmitidaProductos(facturaEmitida)" (dblclick)="navigateToFacturacion(facturaEmitida)"> {{facturaEmitida.facte_total_imponible | number:'1.2-2'}}€ </td>
                        </ng-container>
    
                        <ng-container matColumnDef="totalIva">
                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> 
                                Total IVA 
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input matInput type="text" [(ngModel)]="filteredValues['totalIva']" (keyup)="searchFactura()">
                                </mat-form-field>
                            </th>
                            <td class="p-2" mat-cell *matCellDef="let facturaEmitida" (click)="obtenerFacturaEmitidaProductos(facturaEmitida)" (dblclick)="navigateToFacturacion(facturaEmitida)"> {{facturaEmitida.facte_total_iva | number:'1.2-2'}}€ </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="estado">
                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header>  
                                Estado
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input matInput type="text" [(ngModel)]="filteredValues['estado']" (keyup)="searchFactura()">
                                </mat-form-field>
                            </th>
                            <td class="p-2" mat-cell *matCellDef="let facturaEmitida" (click)="obtenerFacturaEmitidaProductos(facturaEmitida)" (dblclick)="navigateToFacturacion(facturaEmitida)"> {{facturaEmitida.facte_estado_factura | titlecase}} </td>
                        </ng-container>

                        <ng-container matColumnDef="fechaCobro">
                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header>  
                                Fecha Cobro
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input matInput type="text" [(ngModel)]="filteredValues['fechaCobro']" (keyup)="searchFactura()">
                                </mat-form-field>
                            </th>
                            <td class="p-2" mat-cell *matCellDef="let facturaEmitida" (click)="obtenerFacturaEmitidaProductos(facturaEmitida)" (dblclick)="navigateToFacturacion(facturaEmitida)"> {{facturaEmitida.facte_fecha_cobro | titlecase}} </td>
                        </ng-container>
    
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsFacturasEmitidas"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsFacturasEmitidas;" class="custom-row">
                        </tr>
    
                    </table>
                </div>

                <div class="d-flex justify-content-between align-items-center">
                    <div class="col-6 d-flex align-items-center">
                        <p class="m-0 px-3"><strong>Total de registros: {{dataSourceFacturasEmitidas.filteredData.length}}</strong></p>
                        <p class="m-0"><strong>Total facturado: {{totalFacturado| number:'1.2-2'}}€</strong></p>
                    </div>
                    <div class="col-6">
                        <mat-paginator #facturasEmitidasPaginator [length]="facturasEmitidasLength" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                    </div>
                </div>

            </div>
            
        </div>
    </div>

    <div class="col-12 p-4 d-flex justify-content-center align-items-center" *ngIf="loadingProductos">
        <mat-spinner></mat-spinner>
    </div>

    <div class="col-12 p-4" *ngIf="productos.length > 0 && !loadingProductos">

        <h2>Resumen productos:</h2>

        <div class="mat-elevation-z8 px-3">
            
            <div>
                <table mat-table #tableProductos [dataSource]="dataSourceProductos" class="w-100">
                    <ng-container matColumnDef="id">
                        <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Id producto </th>
                        <td class="p-2" mat-cell *matCellDef="let producto"> {{producto.factep_id_facturas_emitidas_productos}} </td>
                    </ng-container>
                    <ng-container matColumnDef="tipo">
                        <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
                        <td class="p-2" mat-cell *matCellDef="let producto"> {{producto.factep_tipo | titlecase}} </td>
                    </ng-container>
                    <ng-container matColumnDef="precio">
                        <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Precio total </th>
                        <td class="p-2" mat-cell *matCellDef="let producto"> {{producto.factep_precio_total | number:'1.2-2'}}€  </td>
                    </ng-container>
                    <ng-container matColumnDef="fecha">
                        <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha creación </th>
                        <td class="p-2" mat-cell *matCellDef="let producto"> {{producto.factep_fecha_creacion}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsProductos"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsProductos;"></tr>
                </table>
            </div>
            
        </div>
    </div>

</div>
