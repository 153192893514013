import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
import { Expediente } from '../../models/expediente';
import { ExpedientesAlta, FacturacionServiceService } from '../../services/facturacion-service.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-expedientes-alta',
  templateUrl: './expedientes-alta.component.html',
  styleUrls: ['./expedientes-alta.component.scss']
})
export class ExpedientesAltaComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  expedientes : Expediente[];

  displayedColumns: string[] = [ 'numExp', 'refSin', 'fechaEncargo', 'lesionado', 'compania', 'tipoEncargo', 'estado', 'provincia','selected'];

  dataSource :MatTableDataSource<any>;

  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;

  constructor(private facturacionService : FacturacionServiceService, private router: Router) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getExpedientes()
    this.paginator._intl.itemsPerPageLabel = 'Mostrando:';

  }

  getExpedientes() {

    this.isLoadingResults = true;

    this.facturacionService.obtenerExpedientesEnAlta().subscribe({
      next: (data: ExpedientesAlta) => {
        this.expedientes = data.data;
        this.dataSource = new MatTableDataSource(data.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.resultsLength = this.dataSource.filteredData.length;
        //console.log(data);
      },

      error: (err: any) => {
        console.log(err);
        this.isLoadingResults = false;
        // Catch if the GitHub API has reached its rate limit. Return empty data.
        this.isRateLimitReached = true;
      },
      complete: () => {
        this.isLoadingResults = false;
      
    }
    });
  }

  navigateToFacturar(expediente : Expediente){
    this.router.navigate(['/facturar-expediente'], {
      queryParams: {
          expediente: expediente.exp_id_expedientes_up,
      }
    });
  }

  navigateToFacturasEmitidas(){
    this.router.navigate(['facturas-emitidas']);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  exportarExpediente() {

    if(this.expedientes != null){

      var ws_data = [[
        "Número expediente",
        "Ref. Siniestro",
        "Fecha encargo",
        "Lesionado",
        "Compañia",
        "Tipo de encargo",
        "Estado",
        "Provincia"
      ]];

      this.expedientes.forEach(expediente => {
        ws_data.push([
          expediente.exp_id_expedientes_up,
          expediente.exp_ref_siniestro,
          expediente.exp_fecha_encargo.toString(),
          expediente.exp_lesionado,
          expediente.exp_cia.cia_nombre,
          expediente.exp_tipo_encargo,
          expediente.exp_estado,
          expediente.exp_provincia.prv_descripcion,
        ])
      });

       /* pass here the table id */
      const ws: XLSX.WorkSheet =XLSX.utils.aoa_to_sheet(ws_data);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */  
      XLSX.writeFile(wb, "Expedientes.xlsx");

    }

  }

}
