import { FormatoConsulta } from "./formato_consulta/formato-consulta.model";

export class Encargo {
    id : number;
    id_plataforma: string;
    enup_expediente : string;
    enup_id_expediente : string;
    enup_id_autorizacion: string;
    enup_id_medico : number;
    nombreMedico : string;
    numColegiadoMedico: string;
    especialidadMedico: string;
    logrosMedico: string;
    enup_id_tramitador : number;
    nombreTramitador : string;
    enup_usuario_creacion : Date;
    enup_usuario_modificacion : Date;
    enup_fecha_tarea : Date;
    enup_fecha_limite : string;
    enup_prioridad : number;
    enup_lesionado : string;
    enup_edad_lesionado : number;
    enup_telefono : string;
    enup_referencia_siniestro : string;
    enup_fecha_siniestro : string;
    enup_id_estado_encargo : number;
    enup_estado_encargo : string;
    enup_id_tipo_encargo : number;
    nombre_tipo_encargo : string;
    enup_condicion_lesionado : string;
    enup_cia : string;
    ciaNombre : string;
    enup_responsabilidad_aseguradora : string;
    enup_peticion : string;
    //puede ser number
    enup_provincias : string;
    enup_localidad : string;
    enup_forma_produccion : string;
    enup_objeto_informe: string;
    enup_profesion : string;
    enup_situacion_laboral : string;
    enup_baja_laboral : string;
    enup_contingencia : string;
    enup_antecedentes_personales : string;
    enup_centro_urgencias : string;
    enup_cuando_acude : string;
    enup_diagnostico_urgencias : string;
    enup_centro_rehabilitacion : string;
    enup_medico_seguimiento : string;
    enup_inicio_tratamiento : string;
    enup_alta_medica : string;
    enup_num_consultas : string;
    enup_num_sesiones : string;
    enup_pruebas_realizadas : string;
    enup_evolucion : string;
    enup_consideracion : string;
    enup_conclusiones : string;
    enup_fuente_documental: string;
    enup_num_referencia_informe: string;
    enup_firmado_por: string;
    enup_tipo_impacto: string;
    enup_delta_v: string;
    enup_aceleracion: string;
    enup_fecha_video_consulta: string;
    enup_medios_usados: string;
    enup_anamnesis: string;
    enup_exploracion_fisica: string;
    enup_pruebas_realizadas_video_consulta: string;
    enup_tratamiento_rehabilitador: string;
    enup_intervenciones_quirurgicas: string;
    enup_dias_ppp_basicos: string;
    enup_dias_ppp_moderados: string;
    enup_dias_ppp_graves: string;
    enup_dias_ppp_muy_graves: string;
    enup_dias_ppp_totales: string;
    enup_dias_ppp_basicos_max: string;
    enup_dias_ppp_moderados_max: string;
    enup_dias_ppp_graves_max: string;
    enup_dias_ppp_muy_graves_max: string;
    enup_dias_ppp_totales_max: string;
    enup_perjuicio_moral: string;
    enup_grado: string;
    enup_porcentaje: string;
    enup_perjuicio_personal: string;
    enup_grupo_quirurgico: string;
    enup_otros_correctores: string;
    enup_observaciones: string;
    enup_observaciones_aseguradora: string;
    enup_secuelas: string;
    enup_descripcion_perjuicio_estetico: string;
    enup_puntos_perjuicio_estetico: string;
    enup_puntos_perjuicio_estetico_max: string;
    enup_evolutivo_consultas_valoracion: string;
    enup_fecha_proxima_consulta: string;
    enup_fecha_consulta_video: string;
    enup_hora_consulta_video: string;
    enup_mecanismo_produccion: string;
    enup_url_videoconferencia: string;
    enup_num_informe: string;
    enup_fecha_asesoria: string;
    enup_asesor_medico: string;
    enup_observaciones_asesoria: string;
    enup_estrella: boolean;
    enup_fecha_baja_laboral: string;
    enup_fecha_alta_laboral: string;
    enup_hospitalizacion_urgencias: string;
    mensajes_count : number;
    created_at : string;
    updated_at : string;
    formato_consulta : number;
    nombre_formato_consulta : string;
    enup_x_dano_moral: boolean;
    enup_x_dano_moral_estetico: boolean;
    enup_x_perjuicio_moral: boolean;
    enup_x_perjuicio_excepcional: boolean;
    enup_x_gastos_previsibles: boolean;
    enup_x_protesis_ortesis: boolean;
    enup_x_rehabilitacion: boolean;
    enup_x_ayudas_tecnicas: boolean;
    enup_x_adecuacion: boolean;
    enup_x_perjuicio_patrimonial: boolean;
    enup_x_horas_necesarias: boolean;
    enup_horas_necesarias: string;
    enup_valoracion_final: boolean;
    enup_valoracion_prov: boolean;
    enup_imd: boolean;
    enup_reserva_tecnica : boolean;
    enup_intervenciones : string;
    enup_observaciones_imd : string;
    enup_proxima_revision : string;

    //Reserva técnica
    enup_dias_ppp_basicos_reserva : string;
    enup_dias_ppp_moderados_reserva : string;
    enup_dias_ppp_graves_reserva : string;
    enup_dias_ppp_muy_graves_reserva : string;
    enup_dias_ppp_totales_reserva : string;
    enup_secuelas_reserva : string;
    enup_descripcion_perjuicio_estetico_reserva : string;
    enup_puntos_perjuicio_estetico_reserva : string;
    enup_perjuicio_moral_reserva : string;
    enup_perjuicio_personal_reserva : string;
    enup_grado_reserva : string;
    enup_porcentaje_reserva : string;
    enup_x_dano_moral_reserva : boolean;
    enup_x_dano_moral_estetico_reserva : boolean;
    enup_x_perjuicio_moral_reserva : boolean;
    enup_x_perjuicio_excepcional_reserva : boolean;
    enup_x_gastos_previsibles_reserva : boolean ;
    enup_x_protesis_ortesis_reserva : boolean;
    enup_x_rehabilitacion_reserva : boolean;
    enup_x_ayudas_tecnicas_reserva : boolean;
    enup_x_adecuacion_reserva : boolean;
    enup_x_perjuicio_patrimonial_reserva : boolean;
    enup_x_horas_necesarias_reserva : boolean;
    enup_horas_necesarias_reserva : string;
    enup_otros_correctores_reserva : string;
    enup_observaciones_reserva : string;
    enup_intervenciones_reserva : string;
    enup_situacion_incapacidad : string;
    enup_tiempo_recuperacion : number;
    enup_id_nombre_tramitador : number;

    enup_encargo_anterior: boolean;

    //informe coherencia
    enup_diagnostico_inicial : string;
    enup_tmcc : boolean;
    enup_pato_columna : boolean;
    enup_enfermedades_sistemicas : boolean;
    enup_baja_mutua : boolean;
    enup_bio_funcional : boolean;
    enup_fecha_prueba_bio : string;
    enup_prueba_consistente : boolean;
    enup_nfhas : string;
    enup_resultado_prueba_bio : boolean;
    enup_coherente_bio : boolean;
    enup_termo_realizada : boolean;
    enup_fecha_prueba_termo : string;
    enup_indicador_coherencia : boolean;
    enup_zona_analizada : string;
    enup_resultado_eco : string;
    enup_valor_deltaT : string;
    enup_resultado_prueba_termo : boolean;
    enup_coherente_termo : boolean;
    enup_determinacion : string;
    enup_medico_consulta_medica : string;
    enup_colegiado_consulta_medica : string;
    enup_fecha_ultima_consulta : string;
    enup_condiciones_rehuse : boolean;
}
