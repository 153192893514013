import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../services/session.service';
import { TramitadorPortalService } from '../../services/tramitador-portal.service';

@Component({
  selector: 'app-header-tramitador',
  templateUrl: './header-tramitador.component.html',
  styleUrls: ['./header-tramitador.component.scss']
})
export class HeaderTramitadorComponent implements OnInit {

  nombreTramitador: string;
  countMensajes: number;

  constructor(private sessionService: SessionService, private tramitadorService: TramitadorPortalService ) {
    this.nombreTramitador = this.sessionService.getCurrentSession().tramitador.trm_nombre;
   }

  ngOnInit(): void {
    this.tramitadorService.getCountMensajesSinLeerTramitador().subscribe(
      data => {
        if(data['response'] == "ok"){
          this.countMensajes = data['totalMensajes'];
        }else{

        }
      }
    );
  }

  logout(){
    const confirmed = confirm("Está a punto de cerrar sesión, y eso conlleva a cerrar sesión en TODAS las pestañas de CELSUS.");

    if(confirmed) {
      this.sessionService.logout();
    }
  }

}
