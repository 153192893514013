<mat-toolbar>
    <mat-icon *ngIf="canNavigateUp" class="pointer" (click)="navigateUp()">arrow_back</mat-icon>
    <span style="margin-left: 8px">{{path || 'Archivos'}}</span>
    <span class="spacer"></span>
    <div class="pointer" *ngIf="mostrarPegar" (click)="pegarArchivo()">
      <mat-icon>content_copy</mat-icon>
      <span class="contextMenuDocu">Pegar</span>
    </div>
    
    
</mat-toolbar>
  
<div class="container" fxFlex fxLayout="row" fxLayoutAlign="space-between stretch">
  <div class="content" fxFlex fxLayout="row">
    <mat-grid-list cols="4" rowHeight="100px" fxFlex>
      <mat-grid-tile *ngFor="let element of fileElements" class=file-or-folder>
        <span [matMenuTriggerFor]="rootMenu" [matMenuTriggerData]="{element: element}" #menuTrigger="matMenuTrigger"></span>
        <div fxLayout="column" fxLayoutAlign="space-between center" (click)="navigate(element)" (contextmenu)="openMenu($event,element, menuTrigger)">
          <mat-icon color="primary" class="file-or-folder-icon pointer" *ngIf="element.archIsFolder" (click)="openModalFile(element)">folder</mat-icon>
          <mat-icon color="primary" class="file-or-folder-icon pointer" *ngIf="!element.archIsFolder" (click)="openModalFile(element)">insert_drive_file</mat-icon>
          <span>{{element.archDescripcion}}</span>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>

<mat-menu #rootMenu="matMenu" [overlapTrigger]="false">
  <ng-template matMenuContent let-element="element">
    <button mat-menu-item (click)="deleteElement(element)">
      <mat-icon [ngStyle]="{'color':'red'}">delete</mat-icon>
      <span class="contextMenuDocu">Borrar</span>
    </button>

    <button mat-menu-item (click)="copyElement(element)">
      <mat-icon>file_copy</mat-icon>
      <span class="contextMenuDocu">Copiar</span>
    </button>
  </ng-template>
</mat-menu>
