import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { FacturasRecibidas } from 'src/app/facturacion/models/facturas-recibidas';
import { IncidenciaPago } from 'src/app/facturacion/models/incidencia-pago';
import { Motivo } from 'src/app/facturacion/models/motivo';
import { FacturacionServiceService, Motivos, Users } from 'src/app/facturacion/services/facturacion-service.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-lista-incidencias-pago-dialog',
  templateUrl: './lista-incidencias-pago-dialog.component.html',
  styleUrls: ['./lista-incidencias-pago-dialog.component.scss']
})
export class ListaIncidenciasPagoDialogComponent implements OnInit {

  incidenciasPago: IncidenciaPago[] = [];
  motivos: Motivo[] = [];
  users: User[] = [];

  //---Tabla Incidencias Pago---
  @ViewChild('tableIncidenciasPago')
  incidenciasPagoTable: MatTable<IncidenciaPago>;
  displayedColumnsIncidenciasPago: string[] = [
    'inc_id_incidencias', 
    'inc_fecha_creacion', 
    'inc_usuario_creacion', 
    'inc_estado', 
    'inc_tipo', 
    'inc_usuario_asignado', 
    'incp_motivo', 
    'incp_incidencia_asociada',
    'incp_remesa_asociada'
  ];
  dataSourceIncidenciasPago: MatTableDataSource<IncidenciaPago> = new MatTableDataSource(this.incidenciasPago);

  constructor(
    public dialogRef: MatDialogRef<ListaIncidenciasPagoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataListaIncidencias,
    private facturacionService : FacturacionServiceService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.facturacionService.obtenerMotivos().subscribe({
      next: (data: Motivos) => {
        this.motivos = data.data;

        this.facturacionService.obtenerUsuariosFacturacion().subscribe({
          next: (data: Users) => {
            this.users = data.data;
            this.incidenciasPago = this.data.incidenciaPago;
            this.dataSourceIncidenciasPago.data = this.incidenciasPago;
          },
          error: (err: any) => {
            console.log(err);
            this.snackBar.open(err, undefined, {
              duration: 3 * 1000,
            });
          }
        })

        
      },

      error: (err: any) => {
        console.log(err);
        this.snackBar.open(err, undefined, {
          duration: 3 * 1000,
        });
      }
    });
  }

  incidenciaSelected(incidenciaPago: IncidenciaPago){
    this.dialogRef.close({
      incidencia: incidenciaPago['incidencia'],
      incidenciaPago: incidenciaPago
    });
  }

  obtenerNombreMotivo(incidenciaPago: IncidenciaPago): string{
    return this.motivos.filter(motivo => motivo.mot_id_motivos == incidenciaPago.incp_motivo)[0].mot_descripcion;
  }

  obtenerNombreUsuario(userId: string): string{
    return this.users.filter(user => user.id.toString() == userId)[0].name;
  }

}

interface DialogDataListaIncidencias {
  factura: FacturasRecibidas;
  incidenciaPago: IncidenciaPago[];
}
