<app-header-facturacion></app-header-facturacion>

<div class="loading w-100 h-100 d-flex justify-content-center align-items-center position-absolute" *ngIf="loadingPageFlag">
    <mat-spinner></mat-spinner>
</div>

<mat-tab-group mat-align-tabs="center" class="w-100 height-100vh" [ngStyle]="{'opacity': loadingPageFlag ? 0 : 1}">

    <!-- Tab de Proveedores -->
    <mat-tab label="Proveedores" class="h-100">
        <div class="row h-100 w-100 m-0 p-2">
            <div class="col-2 h-100 d-flex flex-column p-2">
                <button mat-flat-button color="primary" class="mb-1" (click)="navigateToProveedor()">Ver ficha</button>
                <button mat-flat-button color="primary" class="mb-1" (click)="createIncidenciaProveedor()">Incidencia</button>
                <button mat-flat-button color="primary" class="mb-1" (click)="createAndNavigateToIncluirFactura()">Incluir factura</button>
                <button mat-flat-button color="primary" class="mb-2" (click)="obtenerProveedores()">Actualizar listado</button>
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Búsqueda rápida</mat-label>
                    <input
                        matInput
                        [(ngModel)]="proveedorSearch"
                        (keyup)="applyFilterProveedor(proveedorSearch)">
                </mat-form-field>
                <mat-radio-group
                    class="d-flex flex-column mb-3"
                    [(ngModel)]="proveedorFilter"
                    (change)="applyFilterProveedor(proveedorSearch)">
                    <mat-radio-button value="nombre" class="mb-1">
                        Nombre
                    </mat-radio-button>
                    <mat-radio-button value="cif" class="mb-1">
                        CIF
                    </mat-radio-button>
                    <mat-radio-button value="provincia">
                        Provincia
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col-10 h-100 m-0 p-2">
                <div class="mat-elevation-z8 h-100 d-flex flex-column">
                    <div class="flex-grow-1 overflow-auto px-3">

                        <table mat-table #tableProveedores [dataSource]="dataSourceProveedores" class="w-100 ">

                            <ng-container matColumnDef="prov_id_crm">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Id proveedor </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_id_crm}} </td>
                            </ng-container>
        
                            <ng-container matColumnDef="prov_fecha_creacion">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha creación </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_fecha_creacion}} </td>
                            </ng-container>
        
                            <ng-container matColumnDef="prov_fecha_modificacion">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha modificación </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_fecha_modificacion}} </td>
                            </ng-container>
        
                            <ng-container matColumnDef="prov_estado">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_estado}} </td>
                            </ng-container>
        
                            <ng-container matColumnDef="prov_nombre">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_nombre}} </td>
                            </ng-container>
        
                            <ng-container matColumnDef="prov_cif">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> CIF </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_cif}} </td>
                            </ng-container>
        
                            <ng-container matColumnDef="prov_domicilio">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Domicilio </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_domicilio}} </td>
                            </ng-container>
    
                            <ng-container matColumnDef="prov_poblacion">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Provincia </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_provincia.prv_nombre_crm}} </td>
                            </ng-container>
    
                            <ng-container matColumnDef="prov_codigo_postal">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Código postal </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_codigo_postal}} </td>
                            </ng-container>

                            <ng-container matColumnDef="prov_telefono">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Teléfono </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_telefono}} </td>
                            </ng-container>

                            <ng-container matColumnDef="prov_movil">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Móvil </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_movil}} </td>
                            </ng-container>

                            <ng-container matColumnDef="prov_fax">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fax </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_fax}} </td>
                            </ng-container>

                            <ng-container matColumnDef="prov_email">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> E-Mail </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_email}} </td>
                            </ng-container>

                            <ng-container matColumnDef="prov_retencion">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Retención </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_retencion}} </td>
                            </ng-container>

                            <ng-container matColumnDef="prov_iban">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> IBAN </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_iban}} </td>
                            </ng-container>

                            <ng-container matColumnDef="prov_cuenta">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Nº cuenta </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_cuenta}} </td>
                            </ng-container>

                            <ng-container matColumnDef="prov_codigoiae">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Codigo IAE </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_codigoiae}} </td>
                            </ng-container>

                            <ng-container matColumnDef="prov_inicio_actividad">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Inicio actividad </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_inicio_actividad}} </td>
                            </ng-container>

                            <ng-container matColumnDef="prov_periodo_pago">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Periodo pago </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_periodo_pago}} </td>
                            </ng-container>

                            <ng-container matColumnDef="prov_contrato">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Tiene contrato </th>
                                <td class="p-2" mat-cell *matCellDef="let proveedor" (click)="selectProveedor(proveedor)" [ngStyle]="{'font-weight': proveedor.checked ? 'bold' : 'normal'}"> {{proveedor.prov_contrato}} </td>
                            </ng-container>
    
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsProveedores" class="sticky-top"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsProveedores;" class="custom-row"></tr>
                        </table>
                        
                    </div>

                    <div class="d-flex">
                        <div class="flex-grow-1 d-flex align-items-center">
                            <p class="m-0 px-3"><strong>Total registros: {{dataSourceProveedores.filteredData.length}}</strong></p>
                        </div>
                        <div>
                            <mat-paginator #proveedoresPaginator [length]="proveedoresLength" [pageSizeOptions]="[25, 100]"></mat-paginator>
                        </div>
                    </div>

                </div>
                
            </div>
        </div>
    </mat-tab>

    <!-- Tab de Facturas Recibidas -->
    <mat-tab label="Facturas recibidas" class="h-100">
        <div class="row h-100 w-100 m-0 p-2">
            <div class="col-2 h-100 d-flex flex-column p-2">
                <button mat-flat-button color="primary" *ngIf="!sinFechaFlag" class="mb-1" (click)="changeVerFecha()">Ver sin fecha</button>
                <button mat-flat-button color="accent" *ngIf="sinFechaFlag"class="mb-1" (click)="changeVerFecha()">Ver sin fecha</button>
                <button mat-flat-button color="primary" class="mb-1" (click)="createIncidenciaFactura()">Incidencia contabilidad</button>
                <button mat-flat-button color="primary" class="mb-1" (click)="contabilizarFacturaRecibida()">Contabilizar</button>
                <button mat-flat-button color="primary" class="mb-1" (click)="generarRemesa()">Remesa</button>
                <button mat-flat-button color="primary" class="mb-1" (click)="generateExcel()">Excel</button>
                <button mat-flat-button color="primary" class="mb-2" (click)="obtenerFacturasRecibidas()">Actualizar listado</button>
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Fecha</mat-label>
                    <input
                        type="number"
                        matInput
                        [(ngModel)]="facturasRecibidasFecha"
                        (change)="obtenerFacturasRecibidas()">
                </mat-form-field>
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Búsqueda rápida</mat-label>
                    <input
                        matInput
                        [(ngModel)]="facturasRecibidasSearch"
                        (keyup)="applyFilterFacturasRecibidas(facturasRecibidasSearch)">
                </mat-form-field>
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Aplicar estado</mat-label>
                    <mat-select>
                        <mat-option value="test">TODO</mat-option>
                    </mat-select>
                    </mat-form-field>
            </div>
            <div class="col-10 h-100 m-0 p-2">
                <div class="mat-elevation-z8 h-100 d-flex flex-column">
                    <div class="flex-grow-1 overflow-auto px-3">

                        <table mat-table #tableFacturasRecibidas [dataSource]="dataSourceFacturasRecibidas" class="w-100 ">

                            <ng-container matColumnDef="factr_id_facturas_recibidas">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Id factura recibida </th>
                                <td class="p-2" mat-cell *matCellDef="let facturaRecibida" (click)="selectFacturaRecibida(facturaRecibida)" (dblclick)="navigateToIncluirFactura(facturaRecibida)" [ngStyle]="{'font-weight': facturaRecibida.checked ? 'bold' : 'normal'}"> {{facturaRecibida.factr_id_facturas_recibidas}} </td>
                            </ng-container>

                            <ng-container matColumnDef="factr_fecha_creacion">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha creación </th>
                                <td class="p-2" mat-cell *matCellDef="let facturaRecibida" (click)="selectFacturaRecibida(facturaRecibida)" (dblclick)="navigateToIncluirFactura(facturaRecibida)" [ngStyle]="{'font-weight': facturaRecibida.checked ? 'bold' : 'normal'}"> {{facturaRecibida.factr_fecha_creacion}} </td>
                            </ng-container>

                            <ng-container matColumnDef="factr_numero_factura">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Nº factura </th>
                                <td class="p-2" mat-cell *matCellDef="let facturaRecibida" (click)="selectFacturaRecibida(facturaRecibida)" (dblclick)="navigateToIncluirFactura(facturaRecibida)" [ngStyle]="{'font-weight': facturaRecibida.checked ? 'bold' : 'normal'}"> {{facturaRecibida.factr_numero_factura}} </td>
                            </ng-container>

                            <ng-container matColumnDef="factr_fecha_recepcion">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha recepción </th>
                                <td class="p-2" mat-cell *matCellDef="let facturaRecibida" (click)="selectFacturaRecibida(facturaRecibida)" (dblclick)="navigateToIncluirFactura(facturaRecibida)" [ngStyle]="{'font-weight': facturaRecibida.checked ? 'bold' : 'normal'}"> {{facturaRecibida.factr_fecha_recepcion}} </td>
                            </ng-container>

                            <ng-container matColumnDef="factr_fecha_pago">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha pago </th>
                                <td class="p-2" mat-cell *matCellDef="let facturaRecibida" (click)="selectFacturaRecibida(facturaRecibida)" (dblclick)="navigateToIncluirFactura(facturaRecibida)" [ngStyle]="{'font-weight': facturaRecibida.checked ? 'bold' : 'normal'}"> {{facturaRecibida.factr_fecha_pago}} </td>
                            </ng-container>

                            <ng-container matColumnDef="factr_base_imponible">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Base imponible </th>
                                <td class="p-2" mat-cell *matCellDef="let facturaRecibida" (click)="selectFacturaRecibida(facturaRecibida)" (dblclick)="navigateToIncluirFactura(facturaRecibida)" [ngStyle]="{'font-weight': facturaRecibida.checked ? 'bold' : 'normal'}"> {{facturaRecibida.factr_base_imponible | number:'1.2-2'}}€ </td>
                            </ng-container>

                            <ng-container matColumnDef="factr_total_iva">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Total IVA </th>
                                <td class="p-2" mat-cell *matCellDef="let facturaRecibida" (click)="selectFacturaRecibida(facturaRecibida)" (dblclick)="navigateToIncluirFactura(facturaRecibida)" [ngStyle]="{'font-weight': facturaRecibida.checked ? 'bold' : 'normal'}"> {{facturaRecibida.factr_total_iva | number:'1.2-2'}}€ </td>
                            </ng-container>

                            <ng-container matColumnDef="factr_total_factura">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Total factura </th>
                                <td class="p-2" mat-cell *matCellDef="let facturaRecibida" (click)="selectFacturaRecibida(facturaRecibida)" (dblclick)="navigateToIncluirFactura(facturaRecibida)" [ngStyle]="{'font-weight': facturaRecibida.checked ? 'bold' : 'normal'}"> {{facturaRecibida.factr_total_factura | number:'1.2-2'}}€ </td>
                            </ng-container>

                            <ng-container matColumnDef="factr_estado">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                                <td class="p-2" mat-cell *matCellDef="let facturaRecibida" (click)="selectFacturaRecibida(facturaRecibida)" (dblclick)="navigateToIncluirFactura(facturaRecibida)" [ngStyle]="{'font-weight': facturaRecibida.checked ? 'bold' : 'normal'}"> {{facturaRecibida.factr_estado}} </td>
                            </ng-container>

                            <ng-container matColumnDef="factr_contabilidad">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Contabilidad </th>
                                <td class="p-2" mat-cell *matCellDef="let facturaRecibida" (click)="selectFacturaRecibida(facturaRecibida)" (dblclick)="navigateToIncluirFactura(facturaRecibida)" [ngStyle]="{'font-weight': facturaRecibida.checked ? 'bold' : 'normal'}"> {{facturaRecibida.factr_contabilidad}} </td>
                            </ng-container>

                            <ng-container matColumnDef="factr_proveedor">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Proveedor </th>
                                <td class="p-2" mat-cell *matCellDef="let facturaRecibida" (click)="selectFacturaRecibida(facturaRecibida)" (dblclick)="navigateToIncluirFactura(facturaRecibida)" [ngStyle]="{'font-weight': facturaRecibida.checked ? 'bold' : 'normal'}"> {{obtenerNombreProveedor(facturaRecibida.factr_proveedor)}} </td>
                            </ng-container>

                            <ng-container matColumnDef="factr_estado_factura">
                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Estado factura </th>
                                <td class="p-2" mat-cell *matCellDef="let facturaRecibida" (click)="selectFacturaRecibida(facturaRecibida)" (dblclick)="navigateToIncluirFactura(facturaRecibida)" [ngStyle]="{'font-weight': facturaRecibida.checked ? 'bold' : 'normal'}"> {{getEstadoFacturaDescripcion(facturaRecibida.factr_estado_factura)}} </td>
                            </ng-container>
    
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsFacturasRecibidas" class="sticky-top"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsFacturasRecibidas;" class="custom-row"></tr>
                        </table>
                        
                    </div>

                    <div class="d-flex">
                        <div class="flex-grow-1 d-flex align-items-center">
                            <p class="m-0 px-3"><strong>Total registros: {{dataSourceFacturasRecibidas.filteredData.length}}</strong></p>
                            <p class="m-0 px-2"><strong>Base Imponible: {{baseImponible | number:'1.2-2'}}€</strong></p>
                            <p class="m-0 px-2"><strong>IVA: {{iva | number:'1.2-2'}}€</strong></p>
                            <p class="m-0 px-2"><strong>IRPF: {{irpf | number:'1.2-2'}}€</strong></p>
                            <p class="m-0 px-2"><strong>Total facturado: {{totalFacturado | number:'1.2-2'}}€</strong></p>
                        </div>
                        <div>
                            <mat-paginator #facturasRecibidasPaginator [length]="facturasRecibidasLength" [pageSizeOptions]="[25, 100]"></mat-paginator>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </mat-tab>
</mat-tab-group>
