import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import {MatTabsModule} from '@angular/material/tabs';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatBadgeModule} from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
import {MatToolbarModule} from '@angular/material/toolbar'; 
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';
import {MatExpansionModule} from '@angular/material/expansion';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';
import { MedicoPrincipalComponent } from './Medico/medico-principal/medico-principal.component';
import { HeaderMedicoComponent } from './Medico/header-medico/header-medico.component';
import { EncargoComponent } from './Medico/encargo/encargo.component';
import { HeaderTramitadorComponent } from './Tramitador/header-tramitador/header-tramitador.component';
import { TramitadorPrincipalComponent } from './Tramitador/tramitador-principal/tramitador-principal.component';
import { TramitadorListadoComponent } from './Tramitador/tramitador-listado/tramitador-listado.component';
import { EncargoTramitadorComponent } from './Tramitador/encargo-tramitador/encargo-tramitador.component';
import { FileManagerComponent } from './Tramitador/file-manager/file-manager.component';
import { LoginComponent } from './login/login.component';
import { MedicoMensajesComponent } from './Medico/medico-mensajes/medico-mensajes.component';
import { CancelarEncargoDialogComponent } from './dialogs/cancelar-encargo-dialog/cancelar-encargo-dialog.component';
import { PendienteRevisionDialogComponent } from './dialogs/pendiente-revision-dialog/pendiente-revision-dialog.component';
import { FinalizadoDialogComponent } from './dialogs/finalizado-dialog/finalizado-dialog.component';
import { RevisadoDialogComponent } from './dialogs/revisado-dialog/revisado-dialog.component';

import {DatePipe} from '@angular/common';
import { CommonModule } from '@angular/common';
import { BorrarArchivoDialogComponent } from './dialogs/borrar-archivo-dialog/borrar-archivo-dialog.component';
import { FileManagerMedicoComponent } from './Medico/file-manager-medico/file-manager-medico.component';
import { FacturacionPrincipalComponent } from './facturacion/components/facturacion-principal/facturacion-principal.component';
import { HeaderFacturacionComponent } from './facturacion/components/header-facturacion/header-facturacion.component';
import { EnviarDialogComponent } from './dialogs/enviar-dialog/enviar-dialog.component';
import { ReEnviarDesdeFinalizadoDialogComponent } from './dialogs/re-enviar-desde-finalizado-dialog/re-enviar-desde-finalizado-dialog.component';
import { UnidadNegocioComponent } from './facturacion/components/unidad-negocio/unidad-negocio.component';
import { GestionarEstadoComponent } from './facturacion/components/gestionar-estado/gestionar-estado.component';
import { InformesComponent } from './facturacion/components/informes/informes.component';
import { CambiosPendientesComponent } from './facturacion/components/cambios-pendientes/cambios-pendientes.component';
import { BusquedaComponent } from './facturacion/components/busqueda/busqueda.component';
import { ContabilidadComponent } from './facturacion/components/contabilidad/contabilidad.component';
import { RemesasComponent } from './facturacion/components/remesas/remesas.component';
import { FacturasRecibidasComponent } from './facturacion/components/facturas-recibidas/facturas-recibidas.component';
import { FacturasEmitidasComponent } from './facturacion/components/facturas-emitidas/facturas-emitidas.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { TramitadorMensajesComponent } from './Tramitador/tramitador-mensajes/tramitador-mensajes.component';
import { FacturarExpedienteComponent } from './facturacion/components/facturar-expediente/facturar-expediente.component';
import { BorrarEncargoDialogComponent } from './dialogs/borrar-encargo-dialog/borrar-encargo-dialog.component';
import { CrearFacturaDialogComponent } from './dialogs/crear-factura-dialog/crear-factura-dialog.component';
import { ExpedientesAltaComponent } from './facturacion/components/expedientes-alta/expedientes-alta.component';
import { ActualizarFacturaDialogComponent } from './dialogs/actualizar-factura-dialog/actualizar-factura-dialog.component';
import { ProveedorComponent } from './facturacion/components/proveedor/proveedor.component';
import { IncluirFacturaProveedorComponent } from './facturacion/components/incluir-factura-proveedor/incluir-factura-proveedor.component';
import { IncidenciaFacturaEmitidaDialogComponent } from './dialogs/incidencia-factura-emitida-dialog/incidencia-factura-emitida-dialog.component';
import { IncidenciaFacturaRecibidaDialogComponent } from './dialogs/incidencia-factura-recibida-dialog/incidencia-factura-recibida-dialog.component';
import { OtroGastoDialogComponent } from './dialogs/otro-gasto-dialog/otro-gasto-dialog.component';
import { TablasAdminComponent } from './facturacion/components/tablas-admin/tablas-admin.component';
import { IncidenciaProveedorDialogComponent } from './dialogs/incidencia-proveedor-dialog/incidencia-proveedor-dialog.component';
import { IncidenciaPagoDialogComponent } from './dialogs/incidencia-pago-dialog/incidencia-pago-dialog.component';
import { ListaIncidenciasPagoDialogComponent } from './dialogs/lista-incidencias-pago-dialog/lista-incidencias-pago-dialog.component';
import { ListaFacturasRemesaDialogComponent } from './dialogs/lista-facturas-remesa-dialog/lista-facturas-remesa-dialog.component';
import { RemesaDialogComponent } from './dialogs/remesa-dialog/remesa-dialog.component';
import { ListaFacturasRecibidasContabilidadDialogComponent } from './dialogs/lista-facturas-recibidas-contabilidad-dialog/lista-facturas-recibidas-contabilidad-dialog.component';
import { HeaderEncargoComponent } from './Tramitador/header-encargo/header-encargo.component';
import { HeaderEncargoMedicoComponent } from './Medico/header-encargo-medico/header-encargo-medico.component';
import { EncargoTramitadorAntComponent } from './encargo-tramitador-ant/encargo-tramitador-ant.component';
import { EncargoVdcAsistencialComponent } from './Tramitador/vdc-asistencial/encargo-vdc-asistencial/encargo-vdc-asistencial.component';
import { EncargoTramitadorVdcComponent } from './Tramitador/vdc-asistencial/encargo-tramitador-vdc/encargo-tramitador-vdc.component';
import { EncargoVdcAsistencialMedicoComponent } from './Medico/medico-vdc-asistencial/encargo-vdc-asistencial-medico/encargo-vdc-asistencial-medico.component';
import { EncargoMedicoVdcComponent } from './Medico/medico-vdc-asistencial/encargo-medico-vdc/encargo-medico-vdc.component';
import { NuevaTareaVdcComponent } from './Tramitador/vdc-asistencial/nueva-tarea-vdc/nueva-tarea-vdc.component';
import { HeaderEncargoMedicoVdcComponent } from './Medico/medico-vdc-asistencial/header-encargo-medico-vdc/header-encargo-medico-vdc.component';
import { HeaderEncargoTramitadorVdcComponent } from './Tramitador/vdc-asistencial/header-encargo-tramitador-vdc/header-encargo-tramitador-vdc.component';
import { EncargoPericialComponent } from './Tramitador/intranet-periciales/encargo-pericial/encargo-pericial.component';
import { HeaderEncargoPericialComponent } from './Tramitador/intranet-periciales/header-encargo-pericial/header-encargo-pericial.component';
import { EncargoTramitadorPericialComponent } from './Tramitador/intranet-periciales/encargo-tramitador-pericial/encargo-tramitador-pericial.component';

import { HoraFormatDirective } from './services/horaFormatDirective';
import { EncargoMedicoPericialComponent } from './Medico/intranet-periciales-medico/encargo-medico-pericial/encargo-medico-pericial.component';
import { HeaderEncargoPericialMedicoComponent } from './Medico/intranet-periciales-medico/header-encargo-pericial-medico/header-encargo-pericial-medico.component';
import { EncargoPericialMedicoComponent } from './Medico/intranet-periciales-medico/encargo-pericial-medico/encargo-pericial-medico.component';
import { EnviarFisionDialogComponent } from './dialogs/enviar-fision-dialog/enviar-fision-dialog.component';




@NgModule({
  declarations: [
    AppComponent,
    MedicoPrincipalComponent,
    HeaderMedicoComponent,
    EncargoComponent,
    HeaderTramitadorComponent,
    TramitadorPrincipalComponent,
    TramitadorListadoComponent,
    EncargoTramitadorComponent,
    FileManagerComponent,
    LoginComponent,
    MedicoMensajesComponent,
    CancelarEncargoDialogComponent,
    PendienteRevisionDialogComponent,
    FinalizadoDialogComponent,
    RevisadoDialogComponent,
    BorrarArchivoDialogComponent,
    FileManagerMedicoComponent,
    FacturacionPrincipalComponent,
    HeaderFacturacionComponent,
    EnviarDialogComponent,
    ReEnviarDesdeFinalizadoDialogComponent,
    UnidadNegocioComponent,
    GestionarEstadoComponent,
    InformesComponent,
    CambiosPendientesComponent,
    BusquedaComponent,
    ContabilidadComponent,
    RemesasComponent,
    FacturasRecibidasComponent,
    FacturasEmitidasComponent,
    TramitadorMensajesComponent,
    FacturarExpedienteComponent,
    BorrarEncargoDialogComponent,
    CrearFacturaDialogComponent,
    ExpedientesAltaComponent,
    ActualizarFacturaDialogComponent,
    ProveedorComponent,
    IncluirFacturaProveedorComponent,
    IncidenciaFacturaEmitidaDialogComponent,
    IncidenciaFacturaRecibidaDialogComponent,
    OtroGastoDialogComponent,
    TablasAdminComponent,
    IncidenciaProveedorDialogComponent,
    IncidenciaPagoDialogComponent,
    ListaIncidenciasPagoDialogComponent,
    ListaFacturasRemesaDialogComponent,
    RemesaDialogComponent,
    ListaFacturasRecibidasContabilidadDialogComponent,
    HeaderEncargoComponent,
    HeaderEncargoMedicoComponent,
    EncargoTramitadorAntComponent,
    EncargoVdcAsistencialComponent,
    EncargoTramitadorVdcComponent,
    EncargoVdcAsistencialMedicoComponent,
    EncargoMedicoVdcComponent,
    NuevaTareaVdcComponent,
    HeaderEncargoMedicoVdcComponent,
    HeaderEncargoTramitadorVdcComponent,
    EncargoPericialComponent,
    HeaderEncargoPericialComponent,
    EncargoTramitadorPericialComponent,
    HoraFormatDirective,
    EncargoMedicoPericialComponent,
    HeaderEncargoPericialMedicoComponent,
    EncargoPericialMedicoComponent,
    EnviarFisionDialogComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    NgxExtendedPdfViewerModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatSelectModule,
    MatBadgeModule,
    MatMenuModule,
    MatGridListModule,
    MatToolbarModule,
    MatDialogModule,
    MatDividerModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    DragDropModule,
    MatAutocompleteModule,
    MatRippleModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatExpansionModule,
    CommonModule
    ],
  providers: [
    DatePipe,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
