import { DatePipe, formatDate } from '@angular/common';
import { AfterViewChecked, Component, ElementRef, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { BorrarArchivoDialogComponent } from '../../../dialogs/borrar-archivo-dialog/borrar-archivo-dialog.component';
import { BorrarEncargoDialogComponent } from '../../../dialogs/borrar-encargo-dialog/borrar-encargo-dialog.component';
import { CancelarEncargoDialogComponent } from '../../../dialogs/cancelar-encargo-dialog/cancelar-encargo-dialog.component';
import { EnviarDialogComponent } from '../../../dialogs/enviar-dialog/enviar-dialog.component';
import { FinalizadoDialogComponent } from '../../../dialogs/finalizado-dialog/finalizado-dialog.component';
import { PendienteRevisionDialogComponent } from '../../../dialogs/pendiente-revision-dialog/pendiente-revision-dialog.component';
import { ReEnviarDesdeFinalizadoDialogComponent } from '../../../dialogs/re-enviar-desde-finalizado-dialog/re-enviar-desde-finalizado-dialog.component';
import { Archivo } from '../../../models/archivo';
import { CamposVideoConsulta } from '../../../models/campos-video-consulta';
import { Encargo } from '../../../models/encargo';
import { FormatoConsulta } from '../../../models/formato_consulta/formato-consulta.model';
import { Medico } from '../../../models/medico';
import { Mensaje } from '../../../models/mensaje';
import { Tipo } from '../../../models/tipo';
import { Tramitador } from '../../../models/tramitador';
import { FileService } from '../../../services/file.service';
import { FuncionesPrincipalService } from '../../../services/funciones-principal.service';
import { MedicoPortalService } from '../../../services/medico-portal.service';
import { NotificacionService } from '../../../services/notificacion.service';
import { SessionService } from '../../../services/session.service';
import { TramitadorPortalService } from '../../../services/tramitador-portal.service';
import { Tramitadores } from '../../../models/tramitadores';
import { RevisadoDialogComponent } from 'src/app/dialogs/revisado-dialog/revisado-dialog.component';
import { Title } from '@angular/platform-browser';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-encargo-medico-pericial',
  templateUrl: './encargo-medico-pericial.component.html',
  styleUrls: ['./encargo-medico-pericial.component.scss']
})
export class EncargoMedicoPericialComponent implements OnInit {

  
  private idEncargo;
  medico: Medico = new Medico();
  mostrarProgressSpinner = true;
  textoMensaje: string;
  encargo: Encargo;
  encargoAnterior : Encargo;
  encargosAnterioresAsesoriaGraves: Encargo[];
  encargosBySiniestro: Encargo[];
  countMensajes: number;
  mensajes: Mensaje[] = [];
  mostrarRevisado: boolean = false;
  comentario: string;
  isUrgente: boolean;
  private mensajesChecked: number[] = [];
  public archivos: Observable<Archivo[]>;
  public imageSelect: string;
  public tipoArchivo: string = '';
  rutaSala: string;
  medicoUser : User;

 
  //Campos informe
  numExpediente: string;
  nombreMedico: string;
  numColegiado: string;
  nombreLesionado: string;
  edadLesionado: number;
  provincia: string;
  localidad: string;
  responsabilidad: string;
  peticion: string;
  fechaSiniestro: string;
  refSiniestro: string;
  consideracion: string;
  conclusiones: string;
  idTipo: number;
  mecanismoProduccion: string;
  condicionLesionado: string;

  //Campos informe REHUSE CAS
  objetoInforme: string;
  profesion: string;
  situacionLaboral: string;
  bajaLaboral: string;
  contingencia: string;
  antecedentes: string;
  centroUrgencias: string;
  cuandoAcude: string;
  diagnosticoUrgencias: string;
  centroRehab: string;
  medicoSeguimiento: string;
  inicioTratamiento: string;
  altaMedica: string;
  numConsultas: string;
  numSesiones: string;
  pruebasRealizadas: string;
  evolucion: string;
  fechaBajaLaboral: string;
  fechaAltaLaboral: string;
  hospitalizacionUrgencias: string;

  //Campos informe REHUSE CAS + VIDEO
  fuenteDocumental: string;
  referenciaInforme: string;
  firmadoPor: string;
  tipoImpacto: string;
  deltaV: string;
  aceleracion: string;
  fechaVideoConsulta: string;
  mediosUsados: string;
  anamnesis: string;
  exploracionFisica: string;
  id_formato_consulta: number;
  formatoConsulta : FormatoConsulta[];

  //Campos VALORACIÓN VIDEO-CONSULTA
  pruebasRealizadasVideoConsulta: string;
  tratamientoRehabilitador: string;
  intervencionesQuirurgicas: string;
  diasBasicos: string = "0";
  diasModerados: string = "0";
  diasGraves: string = "0";
  diasMuyGraves: string = "0";
  diasTotales: string = "0";
  diasBasicosMax: string = "0";
  diasModeradosMax: string = "0";
  diasGravesMax: string = "0";
  diasMuyGravesMax: string = "0";
  diasTotalesMax: string = "0";
  descripcionPerjuicioEstetico: string;
  puntosPerjuicioEstetico: string;
  puntosPerjuicioEsteticoMax: string;
  perjuicioMoral: string = "No";
  grado: string;
  porcentajeGrado: string;
  perjuicioPersonal: string = "No";
  grupoQuirurgico: string;
  otrosCorrectores: string = "No";
  observaciones: string;
  secuelaForm: FormGroup;
  secuelaHorquillaForm: FormGroup;
  secuelaObj;
  numSecuelas: number;
  puntosTotalesSecuelas: number = 0;
  puntosMinTotalesSecuelas: number = 0;
  puntosMaxTotalesSecuelas: number = 0;
  observacionesAseguradora: string;
  observacionesIMD: string;
  secuelaReservaForm: FormGroup;
  intervencionForm: FormGroup;
  intervencionReservaForm: FormGroup;
  secuelaReservaObj;
  intervencionObj;
  intervencionReservaObj;
  numSecuelasReserva: number;
  numIntervenciones: number;
  numIntervencionesReserva: number;
  puntosTotalesSecuelasReserva: number = 0;
  puntosTotalesIntervenciones: number = 0;
  puntosTotalesIntervencionesReserva: number = 0;
  proximaRevision: string;

  //Modelo estudio documental
  xDanoMoral: boolean = false;
  xDanoMoralEstetico: boolean = false;
  xPerjuicioMoral: boolean = false;
  xPerjuicioExcepcional: boolean = false;
  xGastosPrevisibles: boolean = false;
  xProtesisOrtesis: boolean = false;
  xRehabilitacion: boolean = false;
  xAyudasTecnicas: boolean = false;
  xAdecuacion: boolean = false;
  xPerjuicioPatrimonial: boolean = false;
  xHorasNecesarias: boolean = false;
  horasNecesarias: string = "0";
  valoracionFinal: boolean = false;
  valoracionProv: boolean = false;
  imd: boolean = true;

  //reserva técnica
  reservaTecnica : boolean = false;
  dias_ppp_basicos_reserva : string;
  dias_ppp_moderados_reserva : string;
  dias_ppp_graves_reserva : string;
  dias_ppp_muy_graves_reserva : string;
  dias_ppp_totales_reserva : string;
  secuelas_reserva : string;
  descripcion_perjuicio_estetico_reserva : string;
  puntos_perjuicio_estetico_reserva : string;
  perjuicio_moral_reserva : string;
  perjuicio_personal_reserva : string;
  grado_reserva : string;
  porcentaje_reserva : string;
  x_dano_moral_reserva : boolean;
  x_dano_moral_estetico_reserva : boolean;
  x_perjuicio_moral_reserva : boolean;
  x_perjuicio_excepcional_reserva : boolean;
  x_gastos_previsibles_reserva : boolean ;
  x_protesis_ortesis_reserva : boolean;
  x_rehabilitacion_reserva : boolean;
  x_ayudas_tecnicas_reserva : boolean;
  x_adecuacion_reserva : boolean;
  x_perjuicio_patrimonial_reserva : boolean;
  x_horas_necesarias_reserva : boolean;
  otros_correctores_reserva : string;
  horas_necesarias_reserva : string;
  observaciones_reserva : string;
  addClassSecuelasAntiguas: boolean = false;
  addClassSecuelasAntiguasReserva: boolean = false;
  addClassIntervencionesAntiguas: boolean = false;
  addClassIntervencionesAntiguasReserva: boolean = false;

  getEncargoEjecutado   = false;

  //Seguimiento Diversos
  evolutivoConsultasValoracion: string;
  fechaProximaConsulta: string;

  //Seguimiento No Auto
  situacionIncapacidad : string;
  tiempoRecuperacion : number;

  //Asesoria graves
  numInforme: string;
  fechaAsesoria: string;
  asesorMedico: string;
  observacionesAsesoria: string;

  fechaConsultaVideoConsulta = new FormControl(null);
  fechaLimite =  new FormControl(null);
  horaConsultaVideoConsulta: string;
  idNombreTramitador : number;
  idMedico: number;


  formUploadFile: FormGroup;

  camposVideoConsultaAnteriores: CamposVideoConsulta[];

  idIntervalGuardado;

  dpFechaVideoConsulta = new FormControl(null);

  rutaCarpetaDocMedico : string;
  archivosCarpetaDocMedico: Archivo[];

  constructor(private el: ElementRef, 
    private route: ActivatedRoute, 
    private medicoService: MedicoPortalService, 
    private tramitadorService : TramitadorPortalService,
    private notificacion: NotificacionService, 
    public fileService: FileService, 
    private funcionesService: FuncionesPrincipalService, 
    private router: Router, 
    private dialog: MatDialog, 
    private sessionService: SessionService,
    private fb:FormBuilder,
    private title : Title) {

      this.formUploadFile = this.fb.group({
        archFile: null
      });

      this.secuelaForm = this.fb.group({
        secuelas: this.fb.array([])
      });

      this.secuelaReservaForm = this.fb.group({
        secuelas: this.fb.array([])
      });

      this.intervencionForm = this.fb.group({
        intervenciones: this.fb.array([])
      });

      this.intervencionReservaForm = this.fb.group({
        intervenciones: this.fb.array([])
      });

      this.secuelaHorquillaForm = this.fb.group({
        secuelas: this.fb.array([])
      });

      this.encargo = new Encargo();
      //Para poder refrescar cambios en la pagina cuando navego desde ella misma, ej cambio de encargo en el historico siniestro
      this.route.params.subscribe(params =>{
        this.idEncargo = params['idEncargo'];
        this.idTipo    = params['idTipo'];
        //this.ngOnInit();
      });

      this.medico = this.sessionService.getCurrentSession().medico;

      //Cuando haya algun cambio en las secuelas para mostrar el total de puntos
      this.secuelaForm.get("secuelas").valueChanges.subscribe(selectedValue =>{
        this.totalPuntosSecuelas();
      });

      //Cuando haya algun cambio en las secuelas para mostrar el total de puntos
      this.secuelaReservaForm.get("secuelas").valueChanges.subscribe(selectedValue =>{
        this.totalPuntosSecuelasReserva();
      });

      //Cuando haya algun cambio en las secuelas para mostrar el total de puntos
      this.secuelaHorquillaForm.get("secuelas").valueChanges.subscribe(selectedValue =>{
        this.totalPuntosSecuelasMin();
        this.totalPuntosSecuelasMax();
      });



   }

  ngOnInit(): void {
    
    this.mostrarProgressSpinner = true;
    this.archivos = null;
    //Inicio explorador de archivos
    this.fileService.init();

    this.getEncargo();
    this.getMensajesEncargo();
    this.getFormatoConsulta();

    //Cuando haya algun cambio en las secuelas para mostrar el total de puntos
    this.secuelaForm.get("secuelas").valueChanges.subscribe(selectedValue =>{
      this.totalPuntosSecuelas();
    });
    this.title.setTitle(this.encargo.id_plataforma);
  }

  getFormatoConsulta() { 
    this.tramitadorService.getFormatoConsulta().subscribe({
      next: (data: any) => {
        //console.log(data);
        this.formatoConsulta = data['data'];
      },
      error: (err: any) => {
        console.log(err);
      },
      
    });
  }

  ngOnDestroy(): void{
    //Eliminar guardado automatico
    if(this.idIntervalGuardado){
      clearInterval(this.idIntervalGuardado);
    }
  }

  //Guardado automatico
  guardadoAutomatico(){
    this.idIntervalGuardado = setInterval(()=>{
      this.guardarDatosEncargo("automatico");
    }, 15000);
  }

  secuelas(): FormArray{
    return this.secuelaForm.get("secuelas") as FormArray;
  }

  secuelasReserva(): FormArray{
    return this.secuelaReservaForm.get("secuelas") as FormArray;
  }

  intervenciones(): FormArray{
    return this.intervencionForm.get("intervenciones") as FormArray;
  }

  intervencionesReserva(): FormArray{
    return this.intervencionReservaForm.get("intervenciones") as FormArray;
  }

  nuevaSecuela(): FormGroup {  
    return this.fb.group({  
      codigo: '',  
      descripcion: '',
      puntos: '',
    });
  }  

  nuevaIntervencion(): FormGroup {  
    return this.fb.group({  
      grupo: '',  
      descripcion: '',
    });
  }  

  addNuevasSecuelas(){
    this.secuelas().push(this.nuevaSecuela());
    this.addClassSecuelasAntiguas = false;
  }

  addNuevasSecuelasReserva(){
    this.secuelasReserva().push(this.nuevaSecuela());
    this.addClassSecuelasAntiguasReserva = false;
  }

  addNuevasIntervenciones(){
    this.intervenciones().push(this.nuevaIntervencion());
    this.addClassIntervencionesAntiguas = false;
  }

  addNuevasIntervencionesReserva(){
    this.intervencionesReserva().push(this.nuevaIntervencion());
    this.addClassIntervencionesAntiguas = false;
  }

  quitarSecuela(i:number){
    this.secuelas().removeAt(i);
    this.addClassSecuelasAntiguas = false;
  }

  quitarSecuelaReserva(i:number){
    this.secuelasReserva().removeAt(i);
    this.addClassSecuelasAntiguasReserva = false;
  }

  quitarIntervencion(i:number){
    this.intervenciones().removeAt(i);
    this.addClassIntervencionesAntiguas = false;
  }

  quitarIntervencionReserva(i:number){
    this.intervencionesReserva().removeAt(i);
    this.addClassIntervencionesAntiguasReserva = false;
  }

  secuelasHorquilla(): FormArray{
    return this.secuelaHorquillaForm.get("secuelas") as FormArray;
  }

  nuevaSecuelaHorquilla(): FormGroup {  
    return this.fb.group({  
      codigo: '',  
      descripcion: '',
      puntosMin: '',
      puntosMax: '',
    });
  }  

  addNuevasSecuelasHorquilla(){
    this.secuelasHorquilla().push(this.nuevaSecuelaHorquilla());
  }

  quitarSecuelaHorquilla(i:number){
    this.secuelasHorquilla().removeAt(i);
  }

  sumarDiasPPP(){
    let total : number;
    total = parseInt(this.diasBasicos) + parseInt(this.diasModerados) + parseInt(this.diasGraves) + parseInt(this.diasMuyGraves);
    this.diasTotales = total.toString();
  }

  sumarDiasPPPMax(){
    let total : number;
    total = parseInt(this.diasBasicosMax) + parseInt(this.diasModeradosMax) + parseInt(this.diasGravesMax) + parseInt(this.diasMuyGravesMax);
    this.diasTotalesMax = total.toString();
  }
  
  getArchivosExplorador(){
    let datos = {
      'numExpediente': this.encargo.enup_expediente,
      'idTipoEncargo': this.encargo.enup_id_tipo_encargo,
      'idEstado': this.encargo.enup_id_estado_encargo,
      'idCia': this.encargo.enup_cia
    };
    //Me traigo los archivos para el explorador
    this.medicoService.getArchivosExploradorMedicoPericial(datos).subscribe(
      data =>{
        if(data['response'] == "ok"){
          this.fileService.loadMap(data['archivos']);
          this.archivos = observableOf(data['archivos']);
          console.log(this.archivos);
          //this.updateFileElementQuery();
          this.rutaCarpetaDocMedico = data['ruta'];
          this.archivosCarpetaDocMedico =data['archivos'];
        }else if(data['response'] == "fail"){
          this.notificacion.notificationFail(data['mensaje']);
        }else{
          this.notificacion.notificationFail("Error");
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  rellenarCamposInforme(){
    console.log( this.dateStringtoDate(this.encargo.enup_fecha_limite));
    this.numExpediente = this.encargo.enup_expediente;
    //TODO No se si el nombre y el numero de colegiado del medico estaran vacios para el tramitador y los campos desabilitados
    this.nombreMedico = this.encargo.nombreMedico;
    //Si esta en tramitacion seleccionar el medico del select y el tipo
    this.mecanismoProduccion = this.encargo.enup_mecanismo_produccion;
    this.idMedico = this.encargo.enup_id_medico;
    this.idTipo = this.encargo.enup_id_tipo_encargo;
    this.idNombreTramitador = this.encargo.enup_id_nombre_tramitador;
    this.numColegiado = this.encargo.numColegiadoMedico;
    this.nombreLesionado = this.encargo.enup_lesionado;
    this.edadLesionado = this.encargo.enup_edad_lesionado;
    this.provincia = this.encargo.enup_provincias;
    this.localidad = this.encargo.enup_localidad;
    this.responsabilidad = this.encargo.enup_responsabilidad_aseguradora;
    this.peticion = this.encargo.enup_peticion;
    this.fechaSiniestro = this.encargo.enup_fecha_siniestro;
    this.fechaLimite = new FormControl(this.encargo.enup_fecha_limite == null ? null : this.dateStringtoDate(this.encargo.enup_fecha_limite));
    this.refSiniestro = this.encargo.enup_referencia_siniestro;
    this.profesion = this.encargo.enup_profesion;
    this.situacionLaboral = this.encargo.enup_situacion_laboral;
    this.bajaLaboral = this.encargo.enup_baja_laboral;
    this.fechaBajaLaboral = this.encargo.enup_fecha_baja_laboral;
    this.fechaAltaLaboral = this.encargo.enup_fecha_alta_laboral;
    this.contingencia = this.encargo.enup_contingencia;
    this.antecedentes = this.encargo.enup_antecedentes_personales;
    this.centroUrgencias = this.encargo.enup_centro_urgencias;
    this.cuandoAcude = this.encargo.enup_cuando_acude;
    this.diagnosticoUrgencias = this.encargo.enup_diagnostico_urgencias;
    this.hospitalizacionUrgencias = this.encargo.enup_hospitalizacion_urgencias;
    this.centroRehab = this.encargo.enup_centro_rehabilitacion;
    this.medicoSeguimiento = this.encargo.enup_medico_seguimiento;
    this.inicioTratamiento = this.encargo.enup_inicio_tratamiento;
    this.altaMedica = this.encargo.enup_alta_medica;
    this.numConsultas = this.encargo.enup_num_consultas;
    this.numSesiones = this.encargo.enup_num_sesiones;
    this.pruebasRealizadas = this.encargo.enup_pruebas_realizadas;
    this.fuenteDocumental = this.encargo.enup_fuente_documental;
    this.referenciaInforme = this.encargo.enup_num_referencia_informe;
    this.firmadoPor = this.encargo.enup_firmado_por;
    this.isUrgente = this.encargo.enup_prioridad == 1 ? true : false;
    this.diasBasicos = this.encargo.enup_dias_ppp_basicos == null ? "0" : this.encargo.enup_dias_ppp_basicos;
    this.diasModerados = this.encargo.enup_dias_ppp_moderados == null ? "0" : this.encargo.enup_dias_ppp_moderados;
    this.diasGraves = this.encargo.enup_dias_ppp_graves == null ? "0" : this.encargo.enup_dias_ppp_graves;
    this.diasMuyGraves = this.encargo.enup_dias_ppp_muy_graves == null ? "0" : this.encargo.enup_dias_ppp_muy_graves;
    this.diasTotales = this.encargo.enup_dias_ppp_totales == null ? "0" : this.encargo.enup_dias_ppp_totales;
    this.diasBasicosMax = this.encargo.enup_dias_ppp_basicos_max == null ? "0" : this.encargo.enup_dias_ppp_basicos_max;
    this.diasModeradosMax = this.encargo.enup_dias_ppp_moderados_max == null ? "0" : this.encargo.enup_dias_ppp_moderados_max;
    this.diasGravesMax = this.encargo.enup_dias_ppp_graves_max == null ? "0" : this.encargo.enup_dias_ppp_graves_max;
    this.diasMuyGravesMax = this.encargo.enup_dias_ppp_muy_graves_max == null ? "0" : this.encargo.enup_dias_ppp_muy_graves_max;
    this.diasTotalesMax = this.encargo.enup_dias_ppp_totales_max == null ? "0" : this.encargo.enup_dias_ppp_totales_max;
    this.observaciones = this.encargo.enup_observaciones;
    this.rutaSala = this.encargo.enup_url_videoconferencia;
    this.condicionLesionado = this.encargo.enup_condicion_lesionado;
    this.id_formato_consulta = this.encargo.formato_consulta;
    this.horaConsultaVideoConsulta = this.encargo.enup_hora_consulta_video;
    this.fechaConsultaVideoConsulta = new FormControl(this.encargo.enup_fecha_consulta_video == null ? null : this.dateStringtoDate(this.encargo.enup_fecha_consulta_video));

    //rellenar campo dinamico secuelas
    //Si es VALORACIÓN VIDEO-CONSULTA
    //if(this.encargo.enup_id_tipo_encargo == 5 || this.encargo.enup_id_tipo_encargo == 8){
      this.secuelaObj = JSON.parse(this.encargo.enup_secuelas == null ? '{"secuelas":[]}': this.encargo.enup_secuelas);
      this.secuelaObj.secuelas.forEach(secuela =>{
        this.secuelas().push(this.fb.group(secuela));
      });
      this.numSecuelas = this.secuelas().length;
      //Si hay secuelas sumar puntos
      if(this.secuelas().length > 0){
        this.totalPuntosSecuelas();
      }

  }

  rellenarCamposInformeEncargoAnterior(){
    this.responsabilidad                          == null ? this.encargoAnterior.enup_responsabilidad_aseguradora             != null ? (this.responsabilidad                         = this.encargoAnterior.enup_responsabilidad_aseguradora,              this.addClassAnterior('responsabilidad')) : null : null;
    this.peticion                                 == null ? this.encargoAnterior.enup_peticion                                != null ? (this.peticion                                = this.encargoAnterior.enup_peticion,                                 this.addClassAnterior('peticion')) : null : null;
    this.profesion                                == null ? this.encargoAnterior.enup_profesion                               != null ? (this.profesion                               = this.encargoAnterior.enup_profesion,                                this.addClassAnterior('profesion')) : null : null;
    this.situacionLaboral                         == null ? this.encargoAnterior.enup_situacion_laboral                       != null ? (this.situacionLaboral                        = this.encargoAnterior.enup_situacion_laboral,                        this.addClassAnterior('situacionLaboral')) : null : null;
    this.bajaLaboral                              == null ? this.encargoAnterior.enup_baja_laboral                            != null ? (this.bajaLaboral                             = this.encargoAnterior.enup_baja_laboral,                             this.addClassAnterior('bajaLaboral')) : null : null;
    this.contingencia                             == null ? this.encargoAnterior.enup_contingencia                            != null ? (this.contingencia                            = this.encargoAnterior.enup_contingencia,                             this.addClassAnterior('contingencia')) : null : null;
    this.antecedentes                             == null ? this.encargoAnterior.enup_antecedentes_personales                 != null ? (this.antecedentes                            = this.encargoAnterior.enup_antecedentes_personales,                  this.addClassAnterior('antecedentes')) : null : null;
    this.centroUrgencias                          == null ? this.encargoAnterior.enup_centro_urgencias                        != null ? (this.centroUrgencias                         = this.encargoAnterior.enup_centro_urgencias,                         this.addClassAnterior('centroUrgencias')) : null : null;
    this.cuandoAcude                              == null ? this.encargoAnterior.enup_cuando_acude                            != null ? (this.cuandoAcude                             = this.encargoAnterior.enup_cuando_acude,                             this.addClassAnterior('cuandoAcude')) : null : null;
    this.diagnosticoUrgencias                     == null ? this.encargoAnterior.enup_diagnostico_urgencias                   != null ? (this.diagnosticoUrgencias                    = this.encargoAnterior.enup_diagnostico_urgencias,                    this.addClassAnterior('diagnosticoUrgencias')) : null : null;
    this.centroRehab                              == null ? this.encargoAnterior.enup_centro_rehabilitacion                   != null ? (this.centroRehab                             = this.encargoAnterior.enup_centro_rehabilitacion,                    this.addClassAnterior('centroRehab')) : null : null;
    this.medicoSeguimiento                        == null ? this.encargoAnterior.enup_medico_seguimiento                      != null ? (this.medicoSeguimiento                       = this.encargoAnterior.enup_medico_seguimiento,                       this.addClassAnterior('medicoSeguimiento')) : null : null;
    this.inicioTratamiento                        == null ? this.encargoAnterior.enup_inicio_tratamiento                      != null ? (this.inicioTratamiento                       = this.encargoAnterior.enup_inicio_tratamiento,                       this.addClassAnterior('inicioTratamiento')) : null : null;
    this.altaMedica                               == null ? this.encargoAnterior.enup_alta_medica                             != null ? (this.altaMedica                              = this.encargoAnterior.enup_alta_medica,                              this.addClassAnterior('altaMedica')) : null : null;
    this.numConsultas                             == null ? this.encargoAnterior.enup_num_consultas                           != null ? (this.numConsultas                            = this.encargoAnterior.enup_num_consultas,                            this.addClassAnterior('numConsultas')) : null : null;
    this.numSesiones                              == null ? this.encargoAnterior.enup_num_sesiones                            != null ? (this.numSesiones                             = this.encargoAnterior.enup_num_sesiones,                             this.addClassAnterior('numSesiones')) : null : null;
    this.pruebasRealizadas                        == null ? this.encargoAnterior.enup_pruebas_realizadas                      != null ? (this.pruebasRealizadas                       = this.encargoAnterior.enup_pruebas_realizadas,                       this.addClassAnterior('pruebasRealizadas')) : null : null;
    this.fuenteDocumental                         == null ? this.encargoAnterior.enup_fuente_documental                       != null ? (this.fuenteDocumental                        = this.encargoAnterior.enup_fuente_documental,                        this.addClassAnterior('fuenteDocumental')) : null : null;
    this.referenciaInforme                        == null ? this.encargoAnterior.enup_num_referencia_informe                  != null ? (this.referenciaInforme                       = this.encargoAnterior.enup_num_referencia_informe,                   this.addClassAnterior('referenciaInforme')) : null : null;
    this.firmadoPor                               == null ? this.encargoAnterior.enup_firmado_por                             != null ? (this.firmadoPor                              = this.encargoAnterior.enup_firmado_por,                              this.addClassAnterior('firmadoPor')) : null : null;
    this.diasBasicos                              == "0"  ? this.encargoAnterior.enup_dias_ppp_basicos                        != null && this.encargoAnterior.enup_dias_ppp_basicos   != "0" ? (this.diasBasicos    = this.encargoAnterior.enup_dias_ppp_basicos,       this.addClassAnterior('diasBasicos')) : null : null;
    this.diasModerados                            == "0"  ? this.encargoAnterior.enup_dias_ppp_moderados                      != null && this.encargoAnterior.enup_dias_ppp_moderados != "0" ? (this.diasModerados  = this.encargoAnterior.enup_dias_ppp_moderados,     this.addClassAnterior('diasModerados')) : null : null;
    this.diasGraves                               == "0"  ? this.encargoAnterior.enup_dias_ppp_graves                         != null && this.encargoAnterior.enup_dias_ppp_graves    != "0" ? (this.diasGraves     = this.encargoAnterior.enup_dias_ppp_graves,        this.addClassAnterior('diasGraves')) : null : null;
    this.diasMuyGraves                            == "0"  ? this.encargoAnterior.enup_dias_ppp_muy_graves                     != null && this.encargoAnterior.enup_dias_ppp_muy_graves!= "0" ? (this.diasMuyGraves  = this.encargoAnterior.enup_dias_ppp_muy_graves,    this.addClassAnterior('diasMuyGraves')) : null : null;
    this.diasTotales                              == "0"  ? this.encargoAnterior.enup_dias_ppp_totales                        != null && this.encargoAnterior.enup_dias_ppp_totales   != "0" ? (this.diasTotales    = this.encargoAnterior.enup_dias_ppp_totales,       this.addClassAnterior('diasTotales')) : null : null;
    this.observaciones                            == null ? this.encargoAnterior.enup_observaciones                           != null ? (this.observaciones                           = this.encargoAnterior.enup_observaciones,                            this.addClassAnterior('observaciones')) : null : null;
    this.secuelaObj                               == null ? this.encargoAnterior.enup_secuelas                                != null ? (this.secuelaObj                              = this.encargoAnterior.enup_secuelas,                                 this.addClassAnterior('secuelaObj')) : null : null;


    //rellenar campo dinamico secuelas
    //Si es VALORACIÓN VIDEO-CONSULTA
    // if(this.encargo.enup_id_tipo_encargo == 5 || this.encargo.enup_id_tipo_encargo == 8){
      if(this.encargo.enup_secuelas == null || this.encargo.enup_secuelas == '{"secuelas":[]}'){
        if(this.secuelaObj.secuelas.length == 0){
          this.secuelaObj = JSON.parse(this.encargoAnterior.enup_secuelas == null ? '{"secuelas":[]}': this.encargoAnterior.enup_secuelas);
          this.secuelaObj.secuelas.forEach(secuela =>{
            this.secuelas().push(this.fb.group(secuela));
          });
          this.numSecuelas = this.secuelas().length;
          
          //Si hay secuelas sumar puntos
          if(this.secuelas().length > 0){
            this.totalPuntosSecuelas();
            //Añadir clase a secuelas antiguas
            this.addClassSecuelasAntiguas = true;
          }
        }
      }
    
  }

  //calcular puntos de las secuelas con la formula de balthazar
  totalPuntosSecuelas(){
    let totalSecuelas;
    let puntosArray: number[] = [];
    let i = 0;
    let resultado = 0;

    totalSecuelas = this.secuelaForm.value;

    totalSecuelas.secuelas.forEach(secuela =>{
      puntosArray.push(parseInt(secuela.puntos));
    });

    if(puntosArray.length != 1){
      puntosArray.sort((a,b) => b-a);

      while(i<puntosArray.length){
        if(i==0){
          resultado = Math.ceil((((100-puntosArray[i]) * puntosArray[i+1])/100)+puntosArray[i]);
          i = i+2;
        }else{
          resultado = Math.ceil((((100-resultado) * puntosArray[i])/100)+resultado);
          i++;
        }
      }
    }else{
      resultado = puntosArray[0];
    }

    //Numero de secuelas
    this.numSecuelas = puntosArray.length;

    //Mostrar en variable
    this.puntosTotalesSecuelas = resultado;
    
    return resultado;

  }

  //calcular puntos de las secuelas con la formula de balthazar
  totalPuntosSecuelasReserva(){
    let totalSecuelas;
    let puntosArray: number[] = [];
    let i = 0;
    let resultado = 0;

    totalSecuelas = this.secuelaReservaForm.value;

    totalSecuelas.secuelas.forEach(secuela =>{
      puntosArray.push(parseInt(secuela.puntos));
    });

    if(puntosArray.length != 1){
      puntosArray.sort((a,b) => b-a);

      while(i<puntosArray.length){
        if(i==0){
          resultado = Math.ceil((((100-puntosArray[i]) * puntosArray[i+1])/100)+puntosArray[i]);
          i = i+2;
        }else{
          resultado = Math.ceil((((100-resultado) * puntosArray[i])/100)+resultado);
          i++;
        }
      }
    }else{
      resultado = puntosArray[0];
    }

    //Numero de secuelas
    this.numSecuelasReserva = puntosArray.length;
    
    //Mostrar en variable
    this.puntosTotalesSecuelasReserva = resultado;
    return resultado;

  }

  //calcular puntos de las secuelas con la formula de balthazar
  totalPuntosSecuelasMin(){
    let totalSecuelas;
    let puntosArray: number[] = [];
    let i = 0;
    let resultado = 0;

    totalSecuelas = this.secuelaHorquillaForm.value;

    //console.log(totalSecuelas);

    totalSecuelas.secuelas.forEach(secuela =>{
      puntosArray.push(parseInt(secuela.puntosMin));
    });

    if(puntosArray.length != 1){
      puntosArray.sort((a,b) => b-a);

      while(i<puntosArray.length){
        if(i==0){
          resultado = Math.ceil((((100-puntosArray[i]) * puntosArray[i+1])/100)+puntosArray[i]);
          i = i+2;
        }else{
          resultado = Math.ceil((((100-resultado) * puntosArray[i])/100)+resultado);
          i++;
        }
      }
    }else{
      resultado = puntosArray[0];
    }

    //Numero de secuelas
    this.numSecuelas = puntosArray.length;
    
    //Mostrar en variable
    this.puntosMinTotalesSecuelas = resultado;
    return resultado;

  }

  //calcular puntos de las secuelas con la formula de balthazar
  totalPuntosSecuelasMax(){
    let totalSecuelas;
    let puntosArray: number[] = [];
    let i = 0;
    let resultado = 0;

    totalSecuelas = this.secuelaHorquillaForm.value;

    totalSecuelas.secuelas.forEach(secuela =>{
      puntosArray.push(parseInt(secuela.puntosMax));
    });

    if(puntosArray.length != 1){
      puntosArray.sort((a,b) => b-a);

      while(i<puntosArray.length){
        if(i==0){
          resultado = Math.ceil((((100-puntosArray[i]) * puntosArray[i+1])/100)+puntosArray[i]);
          i = i+2;
        }else{
          resultado = Math.ceil((((100-resultado) * puntosArray[i])/100)+resultado);
          i++;
        }
      }
    }else{
      resultado = puntosArray[0];
    }

    //Numero de secuelas
    this.numSecuelas = puntosArray.length;
    
    //Mostrar en variable
    this.puntosMaxTotalesSecuelas = resultado;
    return resultado;

  }

  getMedicoEncargo(id: number) {

    this.tramitadorService.getMedicoEncargo(id).subscribe(
      data => {
        if(data['status'] == 200){
         this.medicoUser = data['data'];
        }else if(data['response'] == "fail"){
          this.notificacion.notificationFail(data['mensaje']);
        }
        
      }, error => {
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );

  }



  generarInforme(){
    this.mostrarProgressSpinner = true;
    var datos;
    console.log(this.sessionService.getCurrentSession());

    datos = {
      'idTipoEncargo': this.encargo.enup_id_tipo_encargo,
      'idEstado': this.encargo.enup_id_estado_encargo,
      'idCia': this.encargo.enup_cia,
      'numExpediente': this.numExpediente,
      'nombreMedico': this.encargo.nombreMedico,
      'numColegiado': this.encargo.numColegiadoMedico,
      'logrosMedico': this.encargo.logrosMedico,
      'nombreLesionado': this.nombreLesionado,
      'edadLesionado': this.edadLesionado,
      'provincia': this.provincia,
      'localidad': this.localidad,
      'fechaSiniestro': this.fechaSiniestro,
      'refSiniestro': this.refSiniestro,
      'urlFirma': this.sessionService.getCurrentSession().user.url_firma,
      'genero': this.sessionService.getCurrentSession().user.genero,
      'consideracion': this.consideracion,
      'conclusiones': this.conclusiones,
      'mecanismoProduccion': this.mecanismoProduccion,
      'fechaConsultaVideoConsulta': this.fechaConsultaVideoConsulta  == null ? null : formatDate(this.fechaConsultaVideoConsulta.value, 'dd/MM/yyyy', "en-GB"),
      'horaConsultaVideoConsulta' : this.horaConsultaVideoConsulta,
      'condicionLesionado': this.condicionLesionado,
      'formato_consulta' : this.id_formato_consulta
    };


    datos['fechaLimite'] = this.fechaLimite.value == null ? null : formatDate(this.fechaLimite.value, 'dd/MM/yyyy', "en-GB");
    datos['fuenteDocumental'] = this.fuenteDocumental == null ? "No se aporta" : this.fuenteDocumental;
    datos['observaciones'] = this.observaciones;

    this.funcionesService.generarInformeMedicoPericial(datos).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          this.notificacion.notificationOk(data['mensaje']);
          //traerme los archivos del encargo
          this.getArchivosExplorador();
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error => {
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  enviarMensajeEncargo(){
    this.mostrarProgressSpinner = true;
    var datos = {
      'idEncargo': this.idEncargo,
      'idUsuario': this.medico.med_id_user,
      'textoMensaje': this.textoMensaje
    };
    
    this.funcionesService.addMensajeEncargo(datos).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          console.log(data['mensaje']);
          //Limpiar text area
          this.textoMensaje = '';
          this.notificacion.notificationOk(data['mensaje']);
          //enviar email al tramitador porque hay un nuevo mensaje
          this.enviarEmailNuevoMensaje();
          this.getMensajesEncargo();
          this.getEncargo();
        }else{
          console.log(data['mensaje']);
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  enviarEmailNuevoMensaje(){
    let datosEmail ={
      'idEncargo': this.idEncargo
    };

    this.medicoService.enviarEmailNuevoMensajeEncargo(datosEmail).subscribe(
      data =>{
        if(data['response'] == "ok"){
          console.log(data['mensaje']);
        }else{
          console.log(data['mensaje']);
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  getEncargo(){
    this.funcionesService.getEncargoByIdPericial(this.idEncargo, this.medico.med_id_user).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        
        console.log(data);
        if(data['response'] == "ok"){
          this.encargo = data['encargo'];
          this.encargoAnterior = data['encargoAnterior'];
          this.id_formato_consulta = this.encargo.formato_consulta;
          this.camposVideoConsultaAnteriores = data['encargoVideoConsultaAnteriores'];
          this.encargosAnterioresAsesoriaGraves = data['encargosAnterioresAsesoriaGraves'];
          //Si el encargo esta en estado enviado para el medico será pendiente de revision
          if(this.encargo.enup_estado_encargo == "Enviado"){
            this.encargo.enup_estado_encargo = "Pendiente de revisión";
          //Si el encargo esta en estado Pendiente de revision para el medico será Revisado
          }else if(this.encargo.enup_estado_encargo == "Pendiente de revision"){
            this.encargo.enup_estado_encargo = "Revisado";
          }
          this.countMensajes = data['countMensajes'];
          //mostrar botones Fab
          this.mostrarBotonesFab();
          //Traerme los encargos con la misma referencia siniestro
          this.getEncargosBySiniestro(this.encargo.enup_referencia_siniestro);
          //Una vez cargado el encargo relleno el campo de los informes
          this.rellenarCamposInforme();
          //traerme los archivos del encargo
          this.getArchivosExplorador();

          this.getMedicoEncargo(this.encargo.enup_id_medico);
          

          if(this.encargo.enup_id_estado_encargo != 1){
            this.guardadoAutomatico();
          }
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  mostrarBotonesFab(){
    switch(this.encargo.enup_estado_encargo){
      //estado pendiente
      case 'Pendiente de revisión':
        this.mostrarRevisado = true;
        break;
    }
  }

  getEncargosBySiniestro(refSiniestro: string){
    this.mostrarProgressSpinner = true;
    this.medicoService.getEncargosBySiniestro(refSiniestro, this.medico.med_id_user, 0).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          this.encargosBySiniestro = data['encargosBySiniestro'];
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  mensajeCheck(event: MatCheckboxChange, idMensaje: number){
    if(event.checked == true){
      this.mensajesChecked.push(idMensaje);
    }else{
      let posicion = this.mensajesChecked.indexOf(idMensaje);
      this.mensajesChecked.splice(posicion, 1);
    }
    console.log(this.mensajesChecked);
  }

  guardarDatosEncargo(opcion: string){
    if(opcion != "automatico"){
      this.mostrarProgressSpinner = true;
    }
    let datos = {
      'idMensajesMarcados': this.mensajesChecked,
      'idEncargo': this.idEncargo,
      'numExpediente': this.numExpediente,
      'nombreMedico': this.nombreMedico,
      'idMedico': this.idMedico,
      'idTipo': this.idTipo,
      'numColegiado': this.numColegiado,
      'nombreLesionado': this.nombreLesionado,
      'edadLesionado': this.edadLesionado,
      'provincia': this.provincia,
      'localidad': this.localidad,
      'responsabilidad': this.responsabilidad,
      'peticion': this.peticion,
      'prioridad': this.isUrgente == true ? 1 : null,
      'fechaSiniestro': this.fechaSiniestro,
      'fechaLimite' : this.fechaLimite.value == null ? null : formatDate(this.fechaLimite.value, 'dd/MM/yyyy', "en-GB"),
      'refSiniestro': this.refSiniestro,
      'objetoInforme': this.objetoInforme,
      'profesion': this.profesion,
      'situacionLaboral': this.situacionLaboral,
      'bajaLaboral': this.bajaLaboral,
      'fechaBajaLaboral': this.fechaBajaLaboral,
      'fechaAltaLaboral': this.fechaAltaLaboral,
      'contingencia': this.contingencia,
      'antecedentes': this.antecedentes,
      'centroUrgencias': this.centroUrgencias,
      'cuandoAcude': this.cuandoAcude,
      'diagnosticoUrgencias': this.diagnosticoUrgencias,
      'hospitalizacionUrgencias': this.hospitalizacionUrgencias,
      'centroRehab': this.centroRehab,
      'medicoSeguimiento': this.medicoSeguimiento,
      'inicioTratamiento': this.inicioTratamiento,
      'altaMedica': this.altaMedica,
      'numConsultas': this.numConsultas,
      'numSesiones': this.numSesiones,
      'pruebasRealizadas': this.pruebasRealizadas,
      'evolucion': this.evolucion,
      'fuenteDocumental': this.fuenteDocumental,
      'referenciaInforme': this.referenciaInforme,
      'firmadoPor': this.firmadoPor,
      'tipoImpacto': this.tipoImpacto,
      'deltaV': this.deltaV,
      'aceleracion': this.aceleracion,
      'fechaVideoConsulta': formatDate(this.dpFechaVideoConsulta.value, 'dd/MM/yyyy', "en-GB") == "01/01/1970" ? null : formatDate(this.dpFechaVideoConsulta.value, 'dd/MM/yyyy', "en-GB"),
      'mediosUsados': this.mediosUsados,
      'anamnesis': this.anamnesis,
      'exploracionFisica': this.exploracionFisica,
      'consideracion': this.consideracion,
      'conclusiones': this.conclusiones,
      'pruebasRealizadasVideoConsulta': this.pruebasRealizadasVideoConsulta,
      'tratamientoRehabilitador': this.tratamientoRehabilitador,
      'intervencionesQuirurgicas': this.intervencionesQuirurgicas,
      'diasBasicos': this.diasBasicos,
      'diasModerados': this.diasModerados,
      'diasGraves': this.diasGraves,
      'diasMuyGraves': this.diasMuyGraves,
      'diasTotales': this.diasTotales,
      'diasBasicosMax': this.diasBasicosMax,
      'diasModeradosMax': this.diasModeradosMax,
      'diasGravesMax': this.diasGravesMax,
      'diasMuyGravesMax': this.diasMuyGravesMax,
      'diasTotalesMax': this.diasTotalesMax,
      'perjuicioMoral': this.perjuicioMoral,
      'grado': this.grado,
      'porcentajeGrado': this.porcentajeGrado,
      'perjuicioPersonal': this.perjuicioPersonal,
      'grupoQuirurgico': this.grupoQuirurgico,
      'otrosCorrectores': this.otrosCorrectores,
      'observaciones': this.observaciones,
      'observacionesIMD': this.observacionesIMD,
      'observacionesAseguradora': this.observacionesAseguradora,
      'proximaRevision': this.proximaRevision,
      'secuelasJson': JSON.stringify(this.secuelaForm.value),
      'descripcionPerjuicioEstetico': this.descripcionPerjuicioEstetico,
      'puntosPerjuicioEstetico': this.puntosPerjuicioEstetico,
      'puntosPerjuicioEsteticoMax': this.puntosPerjuicioEsteticoMax,
      'evolutivoConsultasValoracion': this.evolutivoConsultasValoracion,
      'fechaProximaConsulta': this.fechaProximaConsulta,
      'fechaConsultaVideoConsulta': this.fechaConsultaVideoConsulta.value == null ? null : formatDate(this.fechaConsultaVideoConsulta.value, 'dd/MM/yyyy', "en-GB"),
      'horaConsultaVideoConsulta': this.horaConsultaVideoConsulta,
      'mecanismoProduccion': this.mecanismoProduccion,
      'rutaSala': this.rutaSala,
      'numInforme': this.numInforme,
      'fechaAsesoria': this.fechaAsesoria,
      'asesorMedico': this.asesorMedico,
      'observacionesAsesoria': this.observacionesAsesoria,
      'formato_consulta' : this.id_formato_consulta,
      'valoracionFinal'              : this.valoracionFinal,
      'valoracionProv'               : this.valoracionProv,
      'imd'                          : this.imd,
      'jsonIntervenciones'           : JSON.stringify(this.intervencionForm.value),
      'reservaTecnica'               : this.reservaTecnica,
      'situacionIncapacidad' : this.situacionIncapacidad,
      'tiempoRecuperacion' : this.tiempoRecuperacion,
      'idNombreTramitador' : this.idNombreTramitador,
      'encargoAnterior'       : this.getEncargoEjecutado

    };

    //console.log('Opción', opcion);

    if(opcion == "revisado"){
      this.funcionesService.updateDatosEncargoPericial(datos).subscribe(
        data =>{
          this.mostrarProgressSpinner = false;
          if(data['response'] == "ok"){
            this.pasarEncargoRevisado();
          }else{
            this.notificacion.notificationFail(data['mensaje']);

          }
        }, error =>{
          this.mostrarProgressSpinner = false;
          this.notificacion.notificationFail("Error en el servidor");
        }
      );
    }else if(opcion == "informe"){
      //let checkCampos = this.checkCamposObligatorios();
      //if(checkCampos.check){
        this.funcionesService.updateDatosEncargoPericial(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              this.generarInforme();
            }else{
              //console.log(data);
              this.notificacion.notificationFail(data['mensaje']);
  
            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            this.notificacion.notificationFail("Error en el servidor");
          }
        );
      /*}
      else{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail(checkCampos.camposFaltantes);
      }*/
    }else if(opcion == "automatico"){
      this.funcionesService.updateDatosEncargoPericial(datos).subscribe(
        data =>{
          this.mostrarProgressSpinner = false;
          if(data['response'] == "ok"){
            console.log("Guardado automático realizado");
          }else{
            console.log("Error guardado automático");
          }
        }, error =>{
          this.mostrarProgressSpinner = false;
          console.log("Error servidor guardado automático");
        }
      );
    }else if(opcion == "descargarDocMedico") {
      this.funcionesService.downloadZipFolder(this.archivosCarpetaDocMedico, this.rutaCarpetaDocMedico).subscribe({
        next: (data: any) => {
          //console.log(data);
          this.funcionesService.verArchivo(data['ruta']).subscribe({
            next: (data: any) => {
              this.descargarchivos(data, "DocMedicoZip", "application/zip");
   
            },
            error: (err: any) => {},
            complete: () => {
              this.mostrarProgressSpinner = false;
              this.funcionesService.deleteZipFolder(data['ruta']).subscribe({
                next: (data: any) => {

                },
                error: (err: any) => {},
                complete: () => {}
              });
            }
          });

        },
        error: (err: any) => {
                
        },
        complete: () => {
          
        }
      })
    }else{
      this.funcionesService.updateDatosEncargoPericial(datos).subscribe(
        data =>{
          this.mostrarProgressSpinner = false;
          if(data['response'] == "ok"){
            this.notificacion.notificationOk(data['mensaje']);
            //Borrar secuelas para no sumarlas al array ya creado
            this.secuelas().clear();
            this.secuelasReserva().clear();
            this.intervenciones().clear();
            this.intervencionesReserva().clear();
            this.secuelasHorquilla().clear();
            this.getEncargo();
            this.getMensajesEncargo();
          }else{
            this.notificacion.notificationFail(data['mensaje']);
            this.getEncargo();
            this.getMensajesEncargo();
          }
        }, error =>{
          this.mostrarProgressSpinner = false;
          this.notificacion.notificationFail("Error en el servidor");
          this.getEncargo();
          this.getMensajesEncargo();
        }
      );
    }
    
  }

  pasarEncargoRevisado(){
    //Mostrar dialogo de confirmacion
    const dialogRevisado = this.dialog.open(RevisadoDialogComponent,{
      data: {comentario: this.comentario}
    });
    dialogRevisado.afterClosed().subscribe(
      result =>{
        //Si pulsa el boton "Si" del dialog
        if(result){
          //Enviar mensaje al encargo
          let datosMensaje = {
            'idEncargo': this.idEncargo,
            'idUsuario': this.medico.med_id_user,
            'textoMensaje': result
          };

          this.funcionesService.addMensajeEncargo(datosMensaje).subscribe(
            data => {
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.mostrarProgressSpinner = true;
                //ID de estado Revisado para el tramitador será pendiente de revision
                const idEstadoRevisado = 3;

                let datos = {
                  'idEncargo': this.idEncargo,
                  'idEstado': idEstadoRevisado
                };

                this.funcionesService.cambiarEstadoEncargo(datos).subscribe(
                  data =>{
                    this.mostrarProgressSpinner = false;
                    if(data['response'] == "ok"){
                      this.mostrarProgressSpinner = true;
                      //enviar email de cambio de estado
                      this.enviarEmailCambioEstado();
                      //Actualizar autorizacion check recibido y fecha actuacion
                      
                      this.notificacion.notificationOk(data['mensaje'] + " Se te redireccionará en 3 segundos");
                      setTimeout(() => {
                        this.router.navigate(['medico-principal/valoracionDocumental']);
                      }, 3000);
                      

                    }else{
                      this.notificacion.notificationFail(data['mensaje']);
                    }
                  }, error =>{
                    this.mostrarProgressSpinner = false;
                    this.notificacion.notificationFail("Error en el servidor");
                  }
                );
              }else{
                console.log(data['mensaje']);
                this.notificacion.notificationFail(data['mensaje']);
              }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );

        }
      }
    );
  }

  enviarEmailCambioEstado(){
    let datosEmail ={
      'idEncargo': this.idEncargo
    };

    this.medicoService.enviarEmailCambioEstadoEncargo(datosEmail).subscribe(
      data =>{
        if(data['response'] == "ok"){
          console.log(data['mensaje']);
        }else{
          console.log(data['mensaje']);
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  getMensajesEncargo(){
    this.mostrarProgressSpinner = true;
    this.funcionesService.getMensajesEncargo(this.idEncargo, this.medico.med_id_user).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        
        //console.log(data);
        if(data['response'] == "ok"){
          this.mensajes = data['mensajes'];
        }else{

        }
      }
    );
  }

  abrirEncargoSiniestro(idEncargo: number){
    this.router.navigate(['encargo', {idEncargo: idEncargo}]);
  }

  mensajesLeidos(){
    this.funcionesService.mensajesLeidos(this.mensajes, this.medico.med_id_user).subscribe(
      data =>{
        if(data['response'] == "ok"){
          this.countMensajes = 0;
          this.notificacion.notificationOk("Los mensajes se han marcados como leídos");
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  onChangeOption(type): void {

    let myActive = this.el.nativeElement.querySelector(".active");
    let divMyActive = this.el.nativeElement.querySelector("." + myActive.id);
    let myTag = this.el.nativeElement.querySelector("[id='" + type + "']");
    let divMyTag = this.el.nativeElement.querySelector("." + type);

    divMyActive.classList.add('noshow');
    myActive.classList.remove('active');

    if (!myTag.classList.contains('active')) {
      myTag.classList.add('active');
      divMyTag.classList.remove('noshow');
    }

  }

  colorEncargo(fechaLimite: string){
    var color = '';
    var fechaNow = new Date();
    var fechaLimiteDosAntes = new Date(fechaLimite);
    var fechaLimiteFormat = new Date(fechaLimite);

    if(fechaLimite != null){

      fechaLimiteDosAntes.setDate(fechaLimiteFormat.getDate() - 2);

      if(fechaNow<fechaLimiteDosAntes){
        color = 'color_verde';
      }else if(fechaNow.getFullYear()==fechaLimiteDosAntes.getFullYear() && fechaNow.getMonth()==fechaLimiteDosAntes.getMonth() && fechaNow.getDate()==fechaLimiteDosAntes.getDate()
      || (fechaNow>fechaLimiteDosAntes && fechaNow<fechaLimiteFormat)){
        color = 'color_amarillo';
      }else if(fechaNow.getFullYear()==fechaLimiteFormat.getFullYear() && fechaNow.getMonth()==fechaLimiteFormat.getMonth() && fechaNow.getDate()==fechaLimiteFormat.getDate() 
      || fechaNow>fechaLimiteFormat){
        color = 'color_rojo';
      }else{
        color = '';
      }
  
      return color;
    }
  }

  onChangeLimpiarMoral(){
    this.grado = null;
    this.porcentajeGrado = null;
  }

  onChangeLimpiarMoralReserva(){
    this.grado = null;
    this.porcentajeGrado = null;
  }

  onChangeLimpiarPersonal(){
    this.grupoQuirurgico = null;
  }

  onChangeLimpiarPersonalReserva(){
    this.grupoQuirurgico = null;
  }

  //File Explorer
  currentRoot: Archivo;
  currentPath: string;
  canNavigateUp = false;

  navigateToFolder(element: Archivo) {
    this.currentRoot = element;
    this.updateFileElementQuery();
    this.currentPath = this.pushToPath(this.currentPath, element.archDescripcion);
    this.canNavigateUp = true;
  }

  navigateUp() {
    if (this.currentRoot && this.currentRoot.archParent === 'root') {
      this.currentRoot = null;
      this.canNavigateUp = false;
      this.updateFileElementQuery();
    } else {
      this.currentRoot = this.fileService.get(this.currentRoot.archParent);
      this.updateFileElementQuery();
    }
    this.currentPath = this.popFromPath(this.currentPath);
  }

  //Actualizar lista de archivos
  updateFileElementQuery() {
    //file explorer lista archivos
    this.archivos = this.fileService.queryInFolder(this.currentRoot ? this.currentRoot.archPath : 'root');
  }

  pushToPath(path: string, folderName: string) {
    let p = path ? path : '';
    p += `${folderName}/`;
    return p;
  }

  popFromPath(path: string) {
    let p = path ? path : '';
    let split = p.split('/');
    split.splice(split.length - 2, 1);
    p = split.join('/');
    return p;
  }

  // File manager
  openModalFile(element: Archivo) {
    if (element != null)
      this.funcionesService.verArchivo(element.archPath).subscribe(
        data => {
          console.log(data);
          if (element.archType == 'pdf') {
            this.imageSelect = data;
            this.tipoArchivo = 'pdf';
            this.el.nativeElement.querySelector('#modalFile').classList.toggle('open');

          }
          else if (element.archType == 'jpg' || element.archType == 'jpeg') {
            this.imageSelect = data;
            this.tipoArchivo = 'imagen';
            this.el.nativeElement.querySelector('#modalFile').classList.toggle('open');
          }
          else {
            console.log(element.archContentType);
            this.descargarchivos(data, element.archDescripcion, element.archContentType);
          }



        });
  }

  openModal(name, obj: any): void {
    this.el.nativeElement.querySelector('#' + name).classList.toggle('open');
  }

  descargarchivos(archivo: string, descripcion: string, type: string) {
    let byteImage = atob(archivo);

    let byteNumbers = new Array(byteImage.length);

    for (var i = 0; i < byteImage.length; i++) {
      byteNumbers[i] = byteImage.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    let blob = new Blob([byteArray], { "type": type });

    if (navigator.msSaveBlob) {
      let filename = descripcion;
      navigator.msSaveBlob(blob, filename);
    } else {
      let link = document.createElement("a");

      link.href = URL.createObjectURL(blob);

      link.setAttribute('visibility', 'hidden');
      link.download = descripcion;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  // Upload file at server VD
  uploadFile(): void {
    const formModel = this.formUploadFile.value;
    let datos = {
      'numExpediente': this.encargo.enup_expediente,
      'idTipoEncargo': this.encargo.enup_id_tipo_encargo,
      'idEstado': this.encargo.enup_id_estado_encargo,
      'idCia': this.encargo.enup_cia,
      'archDescripcion': formModel.archFile.filename,
      'archType': formModel.archFile.filetype,
      'archFile': formModel.archFile.value
    };

    if (this.formUploadFile != null){
      this.medicoService.subirArchivoMedicoPericial(datos).subscribe(
        data => {
          // Close modal
          this.el.nativeElement.querySelector('#modalUploadFile').classList.toggle('open');
          //Enviar email al tramitador para avisar que se a añadido nueva documentacion
          this.enviarEmailAddDocEncargo(datos['archDescripcion']);
          //traerme los archivos del encargo para que aparezca el archivo nuevo subido
          this.getArchivosExplorador();
          // Notification success
          this.notificacion.notificationOk("El archivo ha sido subido satisfactoriamente");
        },
        error => {
          // Notification error
          this.notificacion.notificationFail("Error en el servidor");
        },
      );
    }
  }

  // Update caracteristics of input type file.
  onFileChange(event, input, formSave) {
    let reader = new FileReader();

    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.formUploadFile.get(input).setValue({
          filename: file.name,
          filetype: file.type,
          value: reader.result.toString().split(',')[1]
        });
      };
    }
  }

  enviarEmailAddDocEncargo(nombreArchivo: string){
    let datosEmail ={
      'idEncargo': this.idEncargo,
      'nombreArchivo': nombreArchivo
    };

    this.medicoService.enviarEmailAddDocEncargo(datosEmail).subscribe(
      data =>{
        if(data['response'] == "ok"){
          console.log(data['mensaje']);
        }else{
          console.log(data['mensaje']);
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  checkCamposObligatorios() : {check: boolean, camposFaltantes: string}{

    let camposFaltantes : string = "Campos obligatorios faltantes en:\n";
    let campos : string[] = [];
    let check : boolean = true;

    switch (this.encargo.enup_id_tipo_encargo) {
      //REHUSE CAS
      case 1:      
        if(
          (this.consideracion == null || this.consideracion === "") ||
          (this.conclusiones == null || this.conclusiones === "")
          ){
          campos.push("Consideraciones Médicas");
          check = false;
        }
        break;

      //REHUSE CAS + VIDEO
      case 4:      
        if(
          //Fuente documental
          (this.fuenteDocumental == null || this.fuenteDocumental === "")
          ){
          campos.push("Fuente documental");
          check = false;
        }
        if(
          //Mecanismo prodducción
          (this.mecanismoProduccion == null || this.mecanismoProduccion === "")
        ){
          campos.push("Mecanismo de producción");
          check = false;
        }
        if(
          //Resumen evolutivo
          (this.profesion == null || this.profesion === "") ||
          (this.situacionLaboral == null || this.situacionLaboral === "") ||
          (this.bajaLaboral == null || this.bajaLaboral === "") ||
          (this.contingencia == null || this.contingencia === "") ||
          (this.antecedentes == null || this.antecedentes === "") ||
          (this.centroUrgencias == null || this.centroUrgencias === "") ||
          (this.cuandoAcude == null || this.cuandoAcude === "") ||
          (this.diagnosticoUrgencias == null || this.diagnosticoUrgencias === "") ||
          (this.centroRehab == null || this.centroRehab === "") ||
          (this.inicioTratamiento == null || this.inicioTratamiento === "") ||
          (this.altaMedica == null || this.altaMedica === "") ||
          (this.numSesiones == null || this.numSesiones === "") ||
          (this.pruebasRealizadas == null || this.pruebasRealizadas === "") ||
          (this.evolucion == null || this.evolucion === "")
        ){
          campos.push("Resumen Evolutivo");
          check = false;
        }
        if(
          //Datos relativos al análisis del impacto
          (this.referenciaInforme == null || this.referenciaInforme === "") ||
          (this.firmadoPor == null || this.firmadoPor === "") ||
          (this.tipoImpacto == null || this.tipoImpacto === "") ||
          (this.deltaV == null || this.deltaV === "") ||
          (this.aceleracion == null || this.aceleracion === "")
        ){
          campos.push("Datos relativos al análisis del impacto");
          check = false;
        }
        if(
          //Video-consulta médica
          (this.dpFechaVideoConsulta == null || this.dpFechaVideoConsulta.value === "") ||
          (this.mediosUsados == null || this.mediosUsados === "") ||
          (this.anamnesis == null || this.anamnesis === "") ||
          (this.exploracionFisica == null || this.exploracionFisica === "")
        ){
          campos.push("Video-consulta Médica");
          check = false;
        }
        if(
          //Consideraciones médicas
          (this.consideracion == null || this.consideracion === "") ||
          (this.conclusiones == null || this.conclusiones === "")
        ){
          campos.push("Consideraciones Médicas");
          check = false;
        }
        break;
    
      //VALORACIÓN VIDEO-CONSULTA
      case 5:
      //VALORACIÓN VIDEO-CONSULTA-MUTUAS
      case 8:
        if(
          //Fuente documental
          (this.fuenteDocumental == null || this.fuenteDocumental === "")
          ){
          campos.push("Fuente documental");
          check = false;
        }
        if(
          //Mecanismo prodducción
          (this.mecanismoProduccion == null || this.mecanismoProduccion === "")
        ){
          campos.push("Mecanismo de producción");
          check = false;
        }
        if(
          //Proceso clínico documentado
          (this.profesion == null || this.profesion === "") ||
          (this.situacionLaboral == null || this.situacionLaboral === "") ||
          (this.bajaLaboral == null || this.bajaLaboral === "") ||
          (this.contingencia == null || this.contingencia === "") ||
          (this.antecedentes == null || this.antecedentes === "") ||
          (this.centroUrgencias == null || this.centroUrgencias === "") ||
          (this.cuandoAcude == null || this.cuandoAcude === "") ||
          (this.diagnosticoUrgencias == null || this.diagnosticoUrgencias === "") ||
          (this.centroRehab == null || this.centroRehab === "") ||
          (this.inicioTratamiento == null || this.inicioTratamiento === "") ||
          (this.altaMedica == null || this.altaMedica === "") ||
          (this.numSesiones == null || this.numSesiones === "") ||
          (this.pruebasRealizadas == null || this.pruebasRealizadas === "") ||
          (this.intervencionesQuirurgicas == null || this.intervencionesQuirurgicas === "") ||
          (this.evolucion == null || this.evolucion === "")
        ){
          campos.push("Proceso Clínico Documentado");
          check = false;
        }
        if(
          //Video-consulta médica
          (this.dpFechaVideoConsulta == null || this.dpFechaVideoConsulta.value === "") ||
          (this.mediosUsados == null || this.mediosUsados === "") ||
          (this.anamnesis == null || this.anamnesis === "") ||
          (this.exploracionFisica == null || this.exploracionFisica === "")
        ){
          campos.push("Video-consulta Médica");
          check = false;
        }
        if(
          //Valoración - Lesiones temporales
          (this.diasBasicos == null || this.diasBasicos === "") ||
          (this.diasModerados == null || this.diasModerados === "") ||
          (this.diasGraves == null || this.diasGraves === "") ||
          (this.diasMuyGraves == null || this.diasMuyGraves === "") ||
          (this.diasTotales == null || this.diasTotales === "")
        ){
          campos.push("Valoración - Lesiones temporales");
          check = false;
        }
        //TODO Correctores (obligatorio)
        break;  

      //VALORACIÓN BASE DOCUMENTAL
      case 6:
      //ALTA+IAE BASE DOCUMENTAL
      case 7:
        //ALTA+IAE MUTUA LABORAL
      case 10:
        console.log("Total secuelas min: " + this.totalPuntosSecuelasMin());
        console.log("Total secuelas max: " + this.totalPuntosSecuelasMax());
        //Si no hay secuelas
        if(this.secuelasHorquilla().length > 0){
          console.log(this.totalPuntosSecuelasMin());
          if(
            //Lesiones temporales mínimas
            (this.totalPuntosSecuelasMin() == 0) 
          ){
            campos.push("Lesiones temporales mínimas");
            check = false;
          }
          if(
            //Lesiones temporales máximas
            (this.totalPuntosSecuelasMax() == 0) 
          ){
            campos.push("Lesiones temporales máximas");
            check = false;
          }
        }
        
        /*this.secuelaForm.value.secuelas.forEach(secuela => {
          if(
            (secuela.puntosMax == null || secuela.puntosMax === 0) ||
            (secuela.puntosMin == null || secuela.puntosMin === 0)
          ){
            campos.push("Puntos secuela");
            check = false;
          }
        });*/
        if(
          //Observaciones
          (this.observaciones == null || this.observaciones === "")       
        ){
          campos.push("Observaciones");
          check = false;
        }
        if(
          //Observaciones de caracter interno
          (this.observacionesAseguradora == null || this.observacionesAseguradora === "") 
        ){
          campos.push("Observaciones de caracter interno");
          check = false;
        }

        
        break;
      //SEGUIMIENTO DIVERSOS
      case 9:
        if(
          //Fuente documental
          (this.fuenteDocumental == null || this.fuenteDocumental === "")
          ){
          campos.push("Fuente documental");
          check = false;
        }
        if(
          //Resumen Evolutivo
          (this.profesion == null || this.profesion === "") ||
          (this.situacionLaboral == null || this.situacionLaboral === "") ||
          (this.bajaLaboral == null || this.bajaLaboral === "") ||
          (this.altaMedica == null || this.altaMedica === "") ||
          (this.antecedentes == null || this.antecedentes === "") ||
          (this.pruebasRealizadas == null || this.pruebasRealizadas === "") ||
          (this.evolucion == null || this.evolucion === "")
          ){
          campos.push("Resumen Evolutivo");
          check = false;
        }
        if(
          //Valoración del daño corporal
          (this.observaciones == null || this.observaciones === "") ||
          (this.conclusiones == null || this.conclusiones === "") ||
          (this.fechaProximaConsulta == null || this.fechaProximaConsulta === "")
          ){
          campos.push("Valoración del daño corporal");
          check = false;
        }
        break;
      
      //ASESORÍA GRAVES
      case 11:
        
        break;

      default:
        break;
    }

    campos.forEach((campo, index, array) => {

      if (index === array.length - 1){ 
        camposFaltantes += campo;
      }
      else{
        camposFaltantes += campo + "\n";
      }
    });
    
    return {check, camposFaltantes};
  }

   //Pasar fecha en string con foramto dd/MM/yyyy a Date
   dateStringtoDate(fechaString: string): Date {
    // Separar la fecha y la hora
    let [fecha, _] = fechaString.split(' ');
  
    // Separar los componentes de la fecha (día, mes, año)
    let [anio, mes, dia] = fecha.split('-');
  
    // Nota: Los meses en JavaScript comienzan desde 0 (enero = 0, febrero = 1, ...)
    return new Date(Number(anio), Number(mes) - 1, Number(dia));
  }

  addClassAnterior(idDiv){
    let div = document.getElementById(idDiv);
    if(div != null && div.innerText != null){
      div.classList.add('anterior');
    }
  }


  keyupClaseAnterior(value, id, valueAnterior){
    if(value != valueAnterior){
      document.getElementById(id).classList.remove('anterior');
    }else{
      document.getElementById(id).classList.add('anterior');
    }
  }

}
