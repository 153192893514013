import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { Encargo } from '../../models/encargo';
import { FormatoConsulta } from '../../models/formato_consulta/formato-consulta.model';
import { Tramitador } from '../../models/tramitador';
import { FuncionesPrincipalService } from '../../services/funciones-principal.service';
import { SessionService } from '../../services/session.service';
import { TramitadorPortalService } from '../../services/tramitador-portal.service';

@Component({
  selector: 'app-tramitador-listado',
  templateUrl: './tramitador-listado.component.html',
  styleUrls: ['./tramitador-listado.component.scss'],
  //Cambios css en las clases de material angular
  encapsulation: ViewEncapsulation.None
})
export class TramitadorListadoComponent implements OnInit {


  encargosTramitacion : Encargo[];
  encargosEnviados : Encargo[];
  encargosFinalizados : Encargo[];
  encargosPendienteRevision : Encargo[];
  encargosRevisado : Encargo[];
  encargosPendienteEnviar : Encargo[];

  encargosLength : number;
  estados : any[] = [
    {
      id          : 0,
      description : "En tramitación",
    },
    {
      id          : 1,
      description : "Enviados",
    },
    {
      id          : 2,
      description : "Pendiente de revisión",
    },
    {
      id          : 3,
      description : "Finalizados",
    }
  ];

  //variables para el filtro.
  company           : string = "";
  sinesterReference : string = "";
  dateStart         : Date   = null;
  dateLimit         : Date   = null;
  dateConsulta      : Date   = null;
  lesionado         : string = "";
  encargosFilter    : Encargo[];

  //Formato consulta
  formatoConsulta : FormatoConsulta;
  mark            : boolean;

  //PAGINATION
  startIndex = 0;
  length = 100;
  pageSize = 5;
  endIndex = this.pageSize;

  pageSizeOptions: number[] = [5, 10, 20, 50, 100];
  DISPLAY_FOOTER: number = 0;


  paginaFinalizados: number = 1;

  encargosArrayAux : Encargo[];
  busquedaType : string;

  tramitador: Tramitador;
  mostrarProgressSpinner1 = true;
  mostrarProgressSpinner2 = true;
  mostrarProgressSpinner3 = true;
  mostrarProgressSpinner4 = true;
  mostrarProgressSpinner5 = true;
  mostrarProgressSpinner6 = true;

  loadingPageFlag: boolean = false;

  checkFechaCreacion: boolean = false;
  checkFechaLimite: boolean = false;

  opc: string = "";

  constructor(private tramitadorService: TramitadorPortalService, private router: Router,
    private sessionService: SessionService, private route: ActivatedRoute, private funcionesService: FuncionesPrincipalService) {

    //forzar a reload cuando cambia los parametros del route
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    if(this.route.snapshot.params['opcion'] == "valoracionDocumental"){
      this.opc = null;
    }else if(this.route.snapshot.params['opcion'] == "consultaVideoConsulta"){
      this.opc = "enup_fecha_consulta_video";
    }else if(this.route.snapshot.params['opcion'] == "asesorias"){
      this.opc = "asesorias";
    }else if(this.route.snapshot.params['opcion'] == "vdcAsistencial") {
      this.opc = "vdcAsistencial";

    }

    console.log(this.opc);
      
    this.tramitador = this.sessionService.getCurrentSession().tramitador;

  }

  ngOnInit(): void {
    //this.encargos();
    if(this.route.snapshot.params['opcion'] == "valoracionDocumental" || 
    this.route.snapshot.params['opcion'] == "consultaVideoConsulta" ||
    this.route.snapshot.params['opcion'] == "asesorias" ||
    this.route.snapshot.params['opcion'] == "vdcAsistencial"){
      this.getEncargosTramitacion();
    }

    this.DISPLAY_FOOTER = this.pageSizeOptions[0];
    this.pageSize = this.pageSizeOptions[0];
  }

  setpage(pagedata: PageEvent) {
    this.startIndex = pagedata.pageIndex * pagedata.pageSize;
    this.endIndex = (pagedata.pageIndex + 1) * pagedata.pageSize;
  }


  getEncargosTramitacion() {
    this.encargosFilter = [];
    this.tramitadorService.getEncargosTramitacion(this.opc).subscribe(
      data => {
        this.mostrarProgressSpinner1 = false;
        
        if(data['response'] == "ok"){
//          this.encargosArrayAux = data['encargosTramitacion'];
          this.encargosFilter = data['encargosTramitacion'];
          this.encargosArrayAux = this.encargosFilter;
          this.encargosLength = this.encargosFilter.length;
          this.loadingPageFlag = true;
        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner1 = false;
        this.loadingPageFlag = false;
      }
    );
  }

  getEncargosEnviados() {
    this.encargosFilter = [];
    this.tramitadorService.getEncargosEnviados(this.opc).subscribe(
      data => {
        this.mostrarProgressSpinner5 = false;
        
        if(data['response'] == "ok"){
          //this.encargosEnviados = data['encargosEnviados'];
          this.encargosFilter = data['encargosEnviados'];
          this.encargosArrayAux = this.encargosFilter;
          this.encargosLength = this.encargosFilter.length;
          this.loadingPageFlag = true;
        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner5 = false;
        this.loadingPageFlag = false;
      }
    );
  }

  getEncargosFinalizados() {
    this.encargosFilter = [];
    this.tramitadorService.getEncargosFinalizados(this.opc).subscribe(
      data => {
        this.mostrarProgressSpinner2 = false;

        if(data['response'] == "ok"){
          //this.encargosFinalizados = data['encargosFinalizados'];
          this.encargosFilter = data['encargosFinalizados'];
          this.encargosArrayAux = this.encargosFilter;
          this.encargosLength = this.encargosFilter.length;
          this.loadingPageFlag = true;
        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner2 = false;
        this.loadingPageFlag = false;
      }
    );
  }

  getEncargosPendientesRevision() {
    this.encargosFilter = [];
    this.tramitadorService.getEncargosPendienteRevision(this.opc).subscribe(
      data => {
        this.mostrarProgressSpinner3 = false;
        
        if(data['response'] == "ok"){
          //this.encargosPendienteRevision = data['encargosPendienteRevision'];
          this.encargosFilter = data['encargosPendienteRevision'];
          this.encargosArrayAux = this.encargosFilter;
          this.encargosLength = this.encargosFilter.length;
          this.loadingPageFlag = true;
        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner3 = false;
        this.loadingPageFlag = false;
      }
    );
  }


  getEncargosRevisado() {
    this.encargosFilter = [];
    this.tramitadorService.getEncargosRevisado(this.opc).subscribe(
      data => {
        this.mostrarProgressSpinner4 = false;
        
        if(data['response'] == "ok"){
          //this.encargosRevisado = data['encargosRevisado'];
          this.encargosFilter = data['encargosRevisado'];
          this.encargosArrayAux = this.encargosFilter;
          this.encargosLength = this.encargosFilter.length;
          this.loadingPageFlag = true;
        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner4 = false;
        this.loadingPageFlag = false;
      }
    );
  }

  getEncargosPendienteEnviar() {
    this.encargosFilter = [];
    this.tramitadorService.getEncargosPendienteEnviar(this.opc).subscribe(
      data => {
        this.mostrarProgressSpinner5 = false;
        
        if(data['response'] == "ok"){
          //this.encargosPendienteEnviar = data['encargosPendienteEnviar'];
          this.encargosFilter = data['encargosPendienteEnviar'];
          this.encargosArrayAux = this.encargosFilter;
          this.encargosLength = this.encargosFilter.length;
          this.loadingPageFlag = true;
        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner5 = false;
        this.loadingPageFlag = false;
      }
    );
  }



  /*encargos(){
    this.encargosFilter = [];
    this.tramitadorService.getEncargosTramitacion(this.opc).subscribe(
      data => {
        this.mostrarProgressSpinner1 = false;
        
        if(data['response'] == "ok"){
//          this.encargosArrayAux = data['encargosTramitacion'];
          this.encargosFilter = data['encargosTramitacion'];

          this.encargosLength = this.encargosArrayAux.length;
        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner1 = false;
      }
    );

    this.tramitadorService.getEncargosEnviados(this.opc).subscribe(
      data => {
        this.mostrarProgressSpinner5 = false;
        
        if(data['response'] == "ok"){
          //this.encargosEnviados = data['encargosEnviados'];
          this.encargosFilter = data['encargosEnviados'];
          this.encargosLength = this.encargosEnviados.length;

        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner5 = false;
      }
    );

    this.tramitadorService.getEncargosFinalizados(this.opc).subscribe(
      data => {
        this.mostrarProgressSpinner2 = false;

        if(data['response'] == "ok"){
          //this.encargosFinalizados = data['encargosFinalizados'];
          this.encargosFilter = data['encargosFinalizados'];
          this.encargosLength = this.encargosFinalizados.length;

        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner2 = false;
      }
    );

    /*this.tramitadorService.getEncargosFinalizados(this.opc, this.paginaFinalizados).subscribe(
      data => {
        this.mostrarProgressSpinner2 = false;

        if(data['response'] == "ok"){
          this.encargosFinalizados = data['encargosFinalizados']['data'];
        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner2 = false;
      }
    );

    this.tramitadorService.getEncargosPendienteRevision(this.opc).subscribe(
      data => {
        this.mostrarProgressSpinner3 = false;
        
        if(data['response'] == "ok"){
          //this.encargosPendienteRevision = data['encargosPendienteRevision'];
          this.encargosFilter = data['encargosPendienteRevision'];
          this.encargosLength = this.encargosPendienteRevision.length;

        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner3 = false;
      }
    );

    this.tramitadorService.getEncargosRevisado(this.opc).subscribe(
      data => {
        this.mostrarProgressSpinner4 = false;
        
        if(data['response'] == "ok"){
          //this.encargosRevisado = data['encargosRevisado'];
          this.encargosFilter = data['encargosRevisado'];

          this.encargosLength = this.encargosRevisado.length;

        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner4 = false;
      }
    );

    this.tramitadorService.getEncargosPendienteEnviar(this.opc).subscribe(
      data => {
        this.mostrarProgressSpinner5 = false;
        
        if(data['response'] == "ok"){
          //this.encargosPendienteEnviar = data['encargosPendienteEnviar'];
          this.encargosFilter = data['encargosPendienteEnviar'];

          this.encargosLength = this.encargosPendienteEnviar.length;

        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner5 = false;
      }
    );
  }*/

  colorEncargo(fechaLimite: string){
    var color = '';
    var fechaNow = new Date();

    if(fechaLimite != null){
      var quitarHoraFechaLimite = fechaLimite.split(" ");
      var arrayFechaLimite = quitarHoraFechaLimite[0].split("/");
      var fechaLimiteDosAntes = new Date(parseInt(arrayFechaLimite[2]), parseInt(arrayFechaLimite[1]) - 1, parseInt(arrayFechaLimite[0]));
      var fechaLimiteFormat = new Date(parseInt(arrayFechaLimite[2]), parseInt(arrayFechaLimite[1]) - 1, parseInt(arrayFechaLimite[0]));

      fechaLimiteDosAntes.setDate(fechaLimiteFormat.getDate() - 2);

      if(fechaNow<fechaLimiteDosAntes){
        color = 'color_verde';
      }else if(fechaNow.getFullYear()==fechaLimiteDosAntes.getFullYear() && fechaNow.getMonth()==fechaLimiteDosAntes.getMonth() && fechaNow.getDate()==fechaLimiteDosAntes.getDate()
      || (fechaNow>fechaLimiteDosAntes && fechaNow<fechaLimiteFormat)){
        color = 'color_amarillo';
      }else if(fechaNow.getFullYear()==fechaLimiteFormat.getFullYear() && fechaNow.getMonth()==fechaLimiteFormat.getMonth() && fechaNow.getDate()==fechaLimiteFormat.getDate() 
      || fechaNow>fechaLimiteFormat){
        color = 'color_rojo';
      }else{
        color = '';
      }
  
      return color;
    }
  }

  colorEncargoFila(fechaLimite: string, prioridad: number){
    var color = '';
    var fechaNow = new Date();

    if(fechaLimite != null){
      var quitarHoraFechaLimite = fechaLimite.split(" ");
      var arrayFechaLimite = quitarHoraFechaLimite[0].split("/");
      var fechaLimiteDosAntes = new Date(parseInt(arrayFechaLimite[2]), parseInt(arrayFechaLimite[1]) - 1, parseInt(arrayFechaLimite[0]));
      var fechaLimiteFormat = new Date(parseInt(arrayFechaLimite[2]), parseInt(arrayFechaLimite[1]) - 1, parseInt(arrayFechaLimite[0]));

      fechaLimiteDosAntes.setDate(fechaLimiteFormat.getDate() - 2);

      if(fechaNow<fechaLimiteDosAntes){
        color = 'color_verde_fila';
      }else if(fechaNow.getFullYear()==fechaLimiteDosAntes.getFullYear() && fechaNow.getMonth()==fechaLimiteDosAntes.getMonth() && fechaNow.getDate()==fechaLimiteDosAntes.getDate()
      || (fechaNow>fechaLimiteDosAntes && fechaNow<fechaLimiteFormat)){
        color = 'color_amarillo_fila';
      }else if(fechaNow.getFullYear()==fechaLimiteFormat.getFullYear() && fechaNow.getMonth()==fechaLimiteFormat.getMonth() && fechaNow.getDate()==fechaLimiteFormat.getDate() 
      || fechaNow>fechaLimiteFormat){
        color = 'color_rojo_fila';
      }else{
        color = '';
      }
      
    }

    if(prioridad != null){
      if(prioridad == 1){
        color = color + ' color_urgente_fila';
      }
    }

    return color;
  }

  abrirEncargo(idEncargo: number){
    this.router.navigateByUrl('/encargo-tramitador;idEncargo=' + idEncargo);
  }

  abrirEncargoTramitacion(idEncargo: number){
    this.router.navigate(['tramitador-principal', {idEncargo: idEncargo}]);
  }

  filtrarPorIdPlataforma(value : string, arrayEncargos : Encargo[]){
    var filtered : Encargo[] = [];
    //Compruebo si el input tiene escrito algo
    if(value){
      for(var i = 0; i < arrayEncargos.length; i++){
        var obj : Encargo = arrayEncargos[i];
        
        //Filtrar por varios parametros
        if(obj.id_plataforma.includes(value.toUpperCase()) || obj.enup_lesionado.includes(value.toUpperCase()) || 
          obj.nombre_tipo_encargo.includes(value.toUpperCase()) || (this.checkFechaCreacion == true ? obj.enup_fecha_tarea.toString().includes(value.toUpperCase()): false) || 
          (obj.enup_fecha_limite ? (this.checkFechaLimite == true ? obj.enup_fecha_limite.includes(value.toUpperCase()) : false) : false) || 
          (obj.nombreMedico ? obj.nombreMedico.toUpperCase().includes(value.toUpperCase()) : false) || 
          (obj.nombreTramitador ? obj.nombreTramitador.toUpperCase().includes(value.toUpperCase()) : false) || (obj.ciaNombre ? obj.ciaNombre.toUpperCase().includes(value.toUpperCase()) : false)){
          
            filtered.push(obj);
        }
      }   

      this.encargosArrayAux = filtered;

    }else{
      this.encargosArrayAux = arrayEncargos;
    }

  }

  //Cuando cambiamos de pestaña, la barra de búsqueda se pone en blanco, y el array recibe el valor en función de la
  //pestaña en que se encuentre
  onTabChanged($event) {
    let clickedIndex = $event.index;
    this.busquedaType = "";
    switch(clickedIndex) { 
      case 0: { 
          /*this.encargosArrayAux = this.encargosTramitacion;
          this.encargosFilter   = this.encargosTramitacion;*/
          this.loadingPageFlag = false;
          this.getEncargosTramitacion();
         break; 
      } 
      case 1: { 
        /*this.encargosArrayAux = this.encargosEnviados;
        this.encargosFilter   = this.encargosEnviados;*/
        this.loadingPageFlag = false;
        this.getEncargosEnviados();

         break; 
      } 
      case 2: { 
        /*this.encargosArrayAux = this.encargosPendienteRevision;
        this.encargosFilter   = this.encargosPendienteRevision;*/
        this.loadingPageFlag = false;
        this.getEncargosPendientesRevision();

         break; 
      } 
      case 3: { 
        /*this.encargosArrayAux = this.encargosFinalizados;
        this.encargosFilter   = this.encargosFinalizados;*/
        this.loadingPageFlag = false;
        this.getEncargosFinalizados();

         break; 
      } 
    } 
  }

  sortOrder(encargo : Encargo[], sort: string) {

    switch(sort) {
      case 'ciaNombre' : {
        encargo.sort((a,b) => (a.ciaNombre > b.ciaNombre ? -1 : 1));
        break;
      }
      case 'created_at' : {
        encargo.sort((a,b) => (new Date(a.created_at.split('/').reverse().join('-')) < new Date(b.created_at.split('/').reverse().join('-')) ? 1 : -1));
        break;
      }
      case 'enup_lesionado' : {
        encargo.sort((a,b) => (a.enup_lesionado < b.enup_lesionado ? -1 : 1));
        break;
      }
      case 'enup_localidad' : {
        encargo.sort((a,b) => (a.enup_localidad > b.enup_localidad ? -1 : 1));
        break;
      }
      case 'enup_fecha_limite' : {
        encargo.sort((a,b) => (new Date(a.enup_fecha_limite.split('/').reverse().join('-')) > new Date(b.enup_fecha_limite.split('/').reverse().join('-')) ? -1 : 1));
        break;
      }
      case 'enup_fecha_consulta_video' : {
        encargo.sort((a,b) => (new Date(a.enup_fecha_consulta_video.split('/').reverse().join('-')) < new Date(b.enup_fecha_consulta_video.split('/').reverse().join('-')) ? -1 : 1)); 
      }
      case 'nombreMedico' : {
        encargo.sort((a,b) => (a.nombreMedico < b.nombreMedico ? -1 : 1)); 
      }
      case 'reset' : {
        if(this.opc != "enup_fecha_consulta_video") {
          encargo.sort((a,b) => (new Date(a.created_at.split('/').reverse().join('-')) < new Date(b.created_at.split('/').reverse().join('-')) ? -1 : 1));
        }else{
          encargo.sort((a,b) => (new Date(a.enup_fecha_consulta_video.split('/').reverse().join('-')) < new Date(b.enup_fecha_consulta_video.split('/').reverse().join('-')) ? -1 : 1));
        }
        this.encargosArrayAux = this.encargosFilter;
        this.encargosLength = this.encargosArrayAux.length;
        this.sinesterReference = "";
        this.lesionado = "";
        this.company = "";
        this.dateStart = null;
        this.dateLimit = null;
        this.dateConsulta = null;
        break;
      }

    }
  }

  filterEncargos(valor : number) {
    var filterEncargos: Encargo[] = [];
    var today : Date = new Date();
    var dateToday : string;

    if(today.getMonth() < 10) {
      dateToday = today.getDate().toString()+ '/0' + (today.getMonth() +1).toString() +'/' + today.getFullYear().toString();
    }else{
      console.log(today.getMonth())
      dateToday = today.getDate().toString()+ '/' + today.getMonth().toString() +'/' + today.getFullYear().toString();
    }
    

    if(this.opc == "enup_fecha_consulta_video") {


      if(this.company != "" ||
       this.sinesterReference != "" ||
       this.dateConsulta ||
       this.lesionado != ""
      ){
        
        this.encargosFilter.forEach(order => {


          if((this.company != "" ? order.ciaNombre.toLowerCase().includes(this.company.toLowerCase()) : true) &&
            (this.sinesterReference != "" ? order.enup_referencia_siniestro.toLowerCase().includes(this.sinesterReference.toLowerCase()) : true) && 
            (this.dateConsulta ? new Date(order.enup_fecha_consulta_video) >= new Date(this.dateConsulta) : true) &&
            (this.lesionado !="" ? order.enup_lesionado.toLowerCase().includes(this.lesionado.toLowerCase()) : true) 
          ){
            filterEncargos.push(order);
            
          }
          this.encargosArrayAux = filterEncargos; 
        
        });
        
      }
      else{
        this.encargosArrayAux = this.encargosFilter;
      }

      if(valor == 1) {
        this.encargosFilter.forEach(order => {
          if(new Date(order.enup_fecha_consulta_video.split('/').reverse().join('-')) < new Date(dateToday.split('/').reverse().join('-'))) {
            filterEncargos.push(order);
            console.log(dateToday)
          }else{
            filterEncargos = []; 
            console.log("dateToday")
          }
          this.encargosArrayAux = filterEncargos; 

        })
      }

      if(valor == 2) {
        this.encargosFilter.forEach(order => {
          if(new Date(order.enup_fecha_consulta_video.split('/').reverse().join('-')) > new Date(dateToday.split('/').reverse().join('-'))) {
            filterEncargos.push(order);
            console.log("dateToday")
          }else{
            filterEncargos = [];
          }
          this.encargosArrayAux = filterEncargos; 

        })
      }
      this.encargosLength = this.encargosArrayAux.length;
  }
    else{
      if(this.company != "" ||
      this.sinesterReference != "" ||
      this.dateStart ||
      this.dateLimit ||
      this.lesionado != ""
      ){
        this.encargosFilter.forEach(order => {
          if((this.company != "" ? order.ciaNombre.toLowerCase().includes(this.company.toLowerCase()) : true) &&
              (this.sinesterReference != "" ? order.enup_referencia_siniestro.toLowerCase().includes(this.sinesterReference.toLowerCase()) : true) && 
              (this.dateStart ? new Date(order.created_at) >= new Date(this.dateStart) : true) &&     
              (this.dateLimit ? new Date(order.created_at) <= new Date(this.dateLimit) : true) &&
              (this.lesionado !="" ? order.enup_lesionado.toLowerCase().includes(this.lesionado.toLowerCase()) : true )
          ){
            filterEncargos.push(order);
            
          }
          this.encargosArrayAux = filterEncargos; 
        });
        
      }
      else{
        this.encargosArrayAux = this.encargosFilter;
      }
      this.encargosLength = this.encargosArrayAux.length;
    }
    
  }


  public specialMark(encargo : Encargo) {
    if(this.opc == "enup_fecha_consulta_video") {
      if(encargo.formato_consulta == 3 || encargo.formato_consulta == 6 || encargo.formato_consulta == null ) {
        return false;
      }else {
        return true;
      }
    }
  }

  public onClickEstrella(encargo: Encargo){

    this.encargosArrayAux.find(encargoAux => encargoAux.id == encargo.id).enup_estrella = !encargo.enup_estrella;

    let datos = {
      'encargoIdPlataforma': encargo.id_plataforma
    };

    this.funcionesService.cambiarEstadoEstrella(datos).subscribe(
      data => {
        console.log(data);
      },
      error =>{
        console.log(error);
      }
    );
  }

}
