<app-header-tramitador></app-header-tramitador>

<div class="progress_spinner" *ngIf="mostrarProgressSpinner1 && mostrarProgressSpinner2 && mostrarProgressSpinner3 && mostrarProgressSpinner4 && mostrarProgressSpinner5 && mostrarProgressSpinner6">
    <mat-spinner></mat-spinner>
</div>

<div class="container-fluid container_main">
    <div class="row">
        <div class="col-md-12">   
            <mat-tab-group (selectedTabChange)="onTabChanged($event);">
                <!-- Encargos Pendientes -->
                <mat-tab label="En tramitación">
                    <br>
                    <input [(ngModel)]="busquedaType" type="text" class="form-control" (input)="filtrarPorIdPlataforma($event.target.value,encargosTramitacion)" placeholder="Filtrar encargos">
                    
                    <div class="row m-0">
                        <div class="col-md-12 mt-2" style="padding-left: 20px;">
                            <mat-checkbox [(ngModel)]="checkFechaCreacion">Fecha creacion</mat-checkbox>
                            <mat-checkbox [(ngModel)]="checkFechaLimite" style="margin-left: 20px;">Fecha límite</mat-checkbox>
                        </div>
                    </div>

                    <!-- Filas -->
                    <div *ngFor="let encargoTramitacion of encargosArrayAux" class="row fila" [ngClass]="colorEncargoFila(encargoTramitacion.enup_fecha_limite, encargoTramitacion.enup_prioridad)" (click)="abrirEncargoTramitacion(encargoTramitacion.id)">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-2">
                                    <i class="bi bi-circle-fill icon_size" [ngClass]="colorEncargo(encargoTramitacion.enup_fecha_limite)"></i>
                                    <h3 class="pt-3 text_inline_icon_encargo">{{encargoTramitacion.id_plataforma}}</h3>
                                </div>

                                <div class="col-md-2">

                                </div>

                                <div class="col-md-2">

                                </div>

                                <div class="col-md-2">
                                    <div class="pt-3 row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Tramitador</h5>
                                            <h4 class="subtitulo_h4">{{encargoTramitacion.nombreTramitador}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="pt-3 row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Valorador</h5>
                                            <h4 class="subtitulo_h4">{{encargoTramitacion.nombreMedico}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="pt-3 row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Compañia</h5>
                                            <h4 class="subtitulo_h4">{{encargoTramitacion.ciaNombre}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-2">
                                    <i class="bi bi-chat-left-text-fill icon_size"><h3 class="text_inline_icon_mensaje">{{encargoTramitacion.mensajes_count}} Mensaje</h3></i>
                                </div>

                                <div class="col-md-2">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Fecha encargo</h5>
                                            <h4 class="subtitulo_h4">{{encargoTramitacion.enup_fecha_tarea}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <h5 class="m-0 subtitulo_h5">Estado</h5>
                                    <h4 class="subtitulo_h4">{{encargoTramitacion.enup_estado_encargo}}</h4>
                                </div>

                                <div class="col-md-2">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Tipo de encargo</h5>
                                            <h4 class="subtitulo_h4">{{encargoTramitacion.nombre_tipo_encargo}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Fecha Limite</h5>
                                            <h4 class="subtitulo_h4">{{encargoTramitacion.enup_fecha_limite}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <h5 class="m-0 subtitulo_h5">Lesionado</h5>
                                    <h4 class="subtitulo_h4">{{encargoTramitacion.enup_lesionado}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Leyenda -->
                    <div class="fila_leyenda_margin">
                        <i class="bi bi-circle-fill leyenda_icon_1"></i><h5 class="leyenda_text">Fecha actual menor a fecha limite</h5>
                        <i class="bi bi-circle-fill leyenda_icon_2"></i><h5 class="leyenda_text">Fecha actual igual o superior en 24 horas a fecha limite</h5>
                        <i class="bi bi-circle-fill leyenda_icon_3"></i><h5 class="leyenda_text" style="margin-right: 6px;">Fecha actual superior es más de 24 horas a fecha limite</h5>
                    </div>
                </mat-tab>

                <!-- Encargos Enviados -->
                <mat-tab label="Enviados">
                    <br>
                    <input [(ngModel)]="busquedaType" type="text" class="form-control" (input)="filtrarPorIdPlataforma($event.target.value,encargosEnviados)" placeholder="Filtrar encargos">

                    <div class="row m-0">
                        <div class="col-md-12 mt-2" style="padding-left: 20px;">
                            <mat-checkbox [(ngModel)]="checkFechaCreacion">Fecha creacion</mat-checkbox>
                            <mat-checkbox [(ngModel)]="checkFechaLimite" style="margin-left: 20px;">Fecha límite</mat-checkbox>
                        </div>
                    </div>

                    <!-- Filas -->
                    <div *ngFor="let encargosEnviado of encargosArrayAux" class="row fila" [ngClass]="colorEncargoFila(encargosEnviado.enup_fecha_limite, encargosEnviado.enup_prioridad)" (click)="abrirEncargo(encargosEnviado.id)">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-2">
                                    <i class="bi bi-circle-fill icon_size" [ngClass]="colorEncargo(encargosEnviado.enup_fecha_limite)"></i>
                                    <h3 class="pt-3 text_inline_icon_encargo">{{encargosEnviado.id_plataforma}}</h3>
                                </div>

                                <div class="col-md-2">

                                </div>

                                <div class="col-md-2">

                                </div>

                                <div class="col-md-2">
                                    <div class="pt-3 row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Tramitador</h5>
                                            <h4 class="subtitulo_h4">{{encargosEnviado.nombreTramitador}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="pt-3 row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Valorador</h5>
                                            <h4 class="subtitulo_h4">{{encargosEnviado.nombreMedico}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="pt-3 row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Compañia</h5>
                                            <h4 class="subtitulo_h4">{{encargosEnviado.ciaNombre}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-2">
                                    <i class="bi bi-chat-left-text-fill icon_size"><h3 class="text_inline_icon_mensaje">{{encargosEnviado.mensajes_count}} Mensaje</h3></i>
                                </div>

                                <div class="col-md-2">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Fecha encargo</h5>
                                            <h4 class="subtitulo_h4">{{encargosEnviado.enup_fecha_tarea}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <h5 class="m-0 subtitulo_h5">Estado</h5>
                                    <h4 class="subtitulo_h4">{{encargosEnviado.enup_estado_encargo}}</h4>
                                </div>

                                <div class="col-md-2">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Tipo de encargo</h5>
                                            <h4 class="subtitulo_h4">{{encargosEnviado.nombre_tipo_encargo}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Fecha Limite</h5>
                                            <h4 class="subtitulo_h4">{{encargosEnviado.enup_fecha_limite}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <h5 class="m-0 subtitulo_h5">Lesionado</h5>
                                    <h4 class="subtitulo_h4">{{encargosEnviado.enup_lesionado}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Leyenda -->
                    <div class="fila_leyenda_margin">
                        <i class="bi bi-circle-fill leyenda_icon_1"></i><h5 class="leyenda_text">Fecha actual menor a fecha limite</h5>
                        <i class="bi bi-circle-fill leyenda_icon_2"></i><h5 class="leyenda_text">Fecha actual igual o superior en 24 horas a fecha limite</h5>
                        <i class="bi bi-circle-fill leyenda_icon_3"></i><h5 class="leyenda_text" style="margin-right: 6px;">Fecha actual superior es más de 24 horas a fecha limite</h5>
                    </div>
                </mat-tab>

                <!-- Encargos Pendientes de revision -->
                <mat-tab label="Pendiente de revisión">
                    <br>
                    <input [(ngModel)]="busquedaType" type="text" class="form-control" (input)="filtrarPorIdPlataforma($event.target.value,encargosPendienteRevision)" placeholder="Filtrar encargos">

                    <div class="row m-0">
                        <div class="col-md-12 mt-2" style="padding-left: 20px;">
                            <mat-checkbox [(ngModel)]="checkFechaCreacion">Fecha creacion</mat-checkbox>
                            <mat-checkbox [(ngModel)]="checkFechaLimite" style="margin-left: 20px;">Fecha límite</mat-checkbox>
                        </div>
                    </div>

                    <!-- Filas -->
                    <div *ngFor="let encargoPendienteRevision of encargosArrayAux" class="row fila" [ngClass]="colorEncargoFila(encargoPendienteRevision.enup_fecha_limite, encargoPendienteRevision.enup_prioridad)" (click)="abrirEncargo(encargoPendienteRevision.id)">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-2">
                                    <i class="bi bi-circle-fill icon_size" [ngClass]="colorEncargo(encargoPendienteRevision.enup_fecha_limite)"></i>
                                    <h3 class="pt-3 text_inline_icon_encargo">{{encargoPendienteRevision.id_plataforma}}</h3>
                                </div>

                                <div class="col-md-2">

                                </div>

                                <div class="col-md-2">

                                </div>

                                <div class="col-md-2">
                                    <div class="pt-3 row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Tramitador</h5>
                                            <h4 class="subtitulo_h4">{{encargoPendienteRevision.nombreTramitador}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="pt-3 row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Valorador</h5>
                                            <h4 class="subtitulo_h4">{{encargoPendienteRevision.nombreMedico}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="pt-3 row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Compañia</h5>
                                            <h4 class="subtitulo_h4">{{encargoPendienteRevision.ciaNombre}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-md-2">
                                    <i class="bi bi-chat-left-text-fill icon_size"><h3 class="text_inline_icon_mensaje">{{encargoPendienteRevision.mensajes_count}} Mensaje</h3></i>
                                </div>

                                <div class="col-md-2">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Fecha encargo</h5>
                                            <h4 class="subtitulo_h4">{{encargoPendienteRevision.enup_fecha_tarea}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <h5 class="m-0 subtitulo_h5">Estado</h5>
                                    <h4 class="subtitulo_h4">{{encargoPendienteRevision.enup_estado_encargo}}</h4>
                                </div>

                                <div class="col-md-2">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Tipo de encargo</h5>
                                            <h4 class="subtitulo_h4">{{encargoPendienteRevision.nombre_tipo_encargo}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Fecha Limite</h5>
                                            <h4 class="subtitulo_h4">{{encargoPendienteRevision.enup_fecha_limite}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <h5 class="m-0 subtitulo_h5">Lesionado</h5>
                                    <h4 class="subtitulo_h4">{{encargoPendienteRevision.enup_lesionado}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                     <!-- Leyenda -->
                     <div class="fila_leyenda_margin">
                        <i class="bi bi-circle-fill leyenda_icon_1"></i><h5 class="leyenda_text">Fecha actual menor a fecha limite</h5>
                        <i class="bi bi-circle-fill leyenda_icon_2"></i><h5 class="leyenda_text">Fecha actual igual o superior en 24 horas a fecha limite</h5>
                        <i class="bi bi-circle-fill leyenda_icon_3"></i><h5 class="leyenda_text" style="margin-right: 6px;">Fecha actual superior es más de 24 horas a fecha limite</h5>
                    </div>
                </mat-tab>

                <!-- Encargos Finalizados -->
                <mat-tab label="Finalizados">
                    <br>
                    <input [(ngModel)]="busquedaType" type="text" class="form-control" (input)="filtrarPorIdPlataforma($event.target.value,encargosFinalizados)" placeholder="Filtrar encargos">

                    <div class="row m-0">
                        <div class="col-md-12 mt-2" style="padding-left: 20px;">
                            <mat-checkbox [(ngModel)]="checkFechaCreacion">Fecha creacion</mat-checkbox>
                            <mat-checkbox [(ngModel)]="checkFechaLimite" style="margin-left: 20px;">Fecha límite</mat-checkbox>
                        </div>
                    </div>

                    <!-- Filas -->
                    <div *ngFor="let encargoFinalizado of encargosArrayAux" class="row fila" [ngClass]="colorEncargoFila(encargoFinalizado.enup_fecha_limite, encargoFinalizado.enup_prioridad)" (click)="abrirEncargo(encargoFinalizado.id)">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-2">
                                    <i class="bi bi-circle-fill icon_size" [ngClass]="colorEncargo(encargoFinalizado.enup_fecha_limite)"></i>
                                    <h3 class="pt-3 text_inline_icon_encargo">{{encargoFinalizado.id_plataforma}}</h3>
                                </div>

                                <div class="col-md-2">

                                </div>

                                <div class="col-md-2">

                                </div>

                                <div class="col-md-2">
                                    <div class="pt-3 row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Tramitador</h5>
                                            <h4 class="subtitulo_h4">{{encargoFinalizado.nombreTramitador}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="pt-3 row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Valorador</h5>
                                            <h4 class="subtitulo_h4">{{encargoFinalizado.nombreMedico}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="pt-3 row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Compañia</h5>
                                            <h4 class="subtitulo_h4">{{encargoFinalizado.ciaNombre}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-2">
                                    <i class="bi bi-chat-left-text-fill icon_size"><h3 class="text_inline_icon_mensaje">{{encargoFinalizado.mensajes_count}} Mensaje</h3></i>
                                </div>

                                <div class="col-md-2">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Fecha encargo</h5>
                                            <h4 class="subtitulo_h4">{{encargoFinalizado.enup_fecha_tarea}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <h5 class="m-0 subtitulo_h5">Estado</h5>
                                    <h4 class="subtitulo_h4">{{encargoFinalizado.enup_estado_encargo}}</h4>
                                </div>

                                <div class="col-md-2">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Tipo de encargo</h5>
                                            <h4 class="subtitulo_h4">{{encargoFinalizado.nombre_tipo_encargo}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h5 class="m-0 subtitulo_h5">Fecha Limite</h5>
                                            <h4 class="subtitulo_h4">{{encargoFinalizado.enup_fecha_limite}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <mat-divider [vertical] ="true" style="height:50px"></mat-divider>
                                        </div>
                                        <div class="col-md-2">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <h5 class="m-0 subtitulo_h5">Lesionado</h5>
                                    <h4 class="subtitulo_h4">{{encargoFinalizado.enup_lesionado}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                     <!-- Leyenda -->
                     <div class="fila_leyenda_margin">
                        <i class="bi bi-circle-fill leyenda_icon_1"></i><h5 class="leyenda_text">Fecha actual menor a fecha limite</h5>
                        <i class="bi bi-circle-fill leyenda_icon_2"></i><h5 class="leyenda_text">Fecha actual igual o superior en 24 horas a fecha limite</h5>
                        <i class="bi bi-circle-fill leyenda_icon_3"></i><h5 class="leyenda_text" style="margin-right: 6px;">Fecha actual superior es más de 24 horas a fecha limite</h5>
                    </div>
                </mat-tab>

            </mat-tab-group>
        </div>
    </div>
</div>
