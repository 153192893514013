import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Expediente } from '../../models/expediente';
import { Autorizacion } from '../../models/autorizacion';
import { Autorizaciones, Citas, ExpedienteData, FacturacionServiceService, FacturaEmitidaData, FacturasEmitidasProductosList, LlamadasSegList, PlantillasProductos, Productos, Series } from '../../services/facturacion-service.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { Cita } from '../../models/cita';
import { PlantillaProductos } from '../../models/plantilla-productos';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import clonedeep from 'lodash.clonedeep';
import { FormControl } from '@angular/forms';
import { Producto } from '../../models/producto';
import { LlamadasSeg } from '../../models/llamadas-seg';

import * as XLSX from 'xlsx';
import { Serie } from '../../models/serie';
import { FacturasEmitidas } from '../../models/facturas-emitidas';
import { FacturasEmitidasProductos } from '../../models/facturas-emitidas-productos';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CrearFacturaDialogComponent } from '../../../dialogs/crear-factura-dialog/crear-factura-dialog.component';
import { ActualizarFacturaDialogComponent } from 'src/app/dialogs/actualizar-factura-dialog/actualizar-factura-dialog.component';

@Component({
  selector: 'app-facturar-expediente',
  templateUrl: './facturar-expediente.component.html',
  styleUrls: ['./facturar-expediente.component.scss']
})
export class FacturarExpedienteComponent implements OnInit {

  //Datos iniciales **Inicializados en el constructor**
  //---Expediente a facturar---
  expediente : Expediente;

  //---Factura a editar---
  factura : FacturasEmitidas;
  facturaProductos : FacturasEmitidasProductos[];

  //---Arrays iniciales de registros facturables---
  registrosFacturables: (Autorizacion | Cita | PlantillaProductos | LlamadasSeg)[] = [];
  autorizaciones: Autorizacion[] = [];
  citas: Cita[] = [];
  plantillasProd: PlantillaProductos[] = [];
  llamadasSeg: LlamadasSeg[] = [];
  productos: Producto[] = [];
  filteredProductos: Producto[] = [];
  series: Serie[] = [];

  //---Array inicial de elementos facturados---
  elementosFacturados: (Autorizacion | Cita | PlantillaProductos | LlamadasSeg | Producto)[] = [];

  //---Datos información general---
  idFactura: string = '';
  numeroExp : string = '';
  refSiniestroExp : string = '';
  provinciaExp : string = '';
  companiaExp : string = '';

  //Select productos y series
  productosForm = new FormControl();
  seriesForm = new FormControl();

  //Tablas
  //---Tabla Registros Facturables---
  @ViewChild('tableRegistrosFacturables')
  registrosFacturablesTable: MatTable<(Autorizacion | Cita | PlantillaProductos | LlamadasSeg)>;
  displayedColumnsRegistrosFacturables: string[] = ['tipo', 'id', 'fecha', 'precio', 'iva', 'accion'];
  dataSourceRegistrosFacturables: MatTableDataSource<(Autorizacion | Cita | PlantillaProductos | LlamadasSeg)> = new MatTableDataSource(this.registrosFacturables);

  //Tablas
  //---Tabla Autorizaciones---
  @ViewChild('tableAutorizaciones')
  autorizacionesTable: MatTable<Autorizacion>;
  displayedColumnsAutorizaciones: string[] = ['id', 'fecha', 'coste', 'venta', 'IVA', 'anadir'];
  dataSourceAutorizaciones: MatTableDataSource<Autorizacion> = new MatTableDataSource(this.autorizaciones);

  //---Tabla Citas---
  @ViewChild('tableCitas')
  citasTable: MatTable<Cita>;
  displayedColumnsCitas: string[] = ['id', 'fecha', 'coste', 'venta', 'IVA', 'anadir'];
  dataSourceCitas: MatTableDataSource<Cita> = new MatTableDataSource(this.citas);

  //---Tabla Plantillas de productos---
  @ViewChild('tablePlantillasProd')
  plantillasProdTable: MatTable<PlantillaProductos>;
  displayedColumnsPlantillasProd: string[] = ['id', 'fecha', 'venta', 'IVA', 'anadir'];
  dataSourcePlantillasProd: MatTableDataSource<PlantillaProductos> = new MatTableDataSource(this.plantillasProd);

  //---Tabla Llamadas de seguimiento---
  @ViewChild('tableLlamadasSeg')
  llamadasSegTable: MatTable<PlantillaProductos>;
  displayedColumnsLlamadasSeg: string[] = ['id', 'fecha', 'venta', 'IVA', 'anadir'];
  dataSourceLlamadasSeg: MatTableDataSource<LlamadasSeg> = new MatTableDataSource(this.llamadasSeg);

  //---Tabla Elementos facturados---
  @ViewChild('tableElementosFact')
  elememtosFactTable: MatTable<(Autorizacion | Cita | PlantillaProductos | LlamadasSeg | Producto)>;
  displayedColumnsElementosFact: string[] = ['tipo', 'id', 'fecha', 'precio', 'iva', 'accion'];
  dataSourceElementosFact: MatTableDataSource<(Autorizacion | Cita | PlantillaProductos | LlamadasSeg | Producto)> = new MatTableDataSource(this.elementosFacturados);
  
  //Precios
  //---Bases Imponibles---
  basImp0 = 0;
  basImp4 = 0;
  basImp10 = 0;
  basImp21 = 0;

  //---Total IVA---
  totalIva4 = 0;
  totalIva10 = 0;
  totalIva21 = 0;

  //---Total Facturado---
  totalFact = 0;

  //Valores a introducir
  serie : Serie;
  numFactura : string = '';
  detalles : string = '';
  observaciones : string = '';
  aFacturar : string = '';
  observacionesFact : string = '';
  nombreProducto: string = '';

  //Flags
  //---Flag mostrar campo opcional de número de factura---
  numberFacturaShowFlag = false;
  //---Flag mostrar loading cuando se crea o se actualice la factura---
  loadingPageFlag = true;
  //---Flag check si está editando factura---
  isEditingFlag = false;

  @HostListener('window:keydown', ['$event'])
  keyEvent(e: KeyboardEvent) {
    if (e.key == 's' && e.ctrlKey){
      e.preventDefault();
      this.guardarFactura();
    }
  }

  constructor(
    private routerActive: ActivatedRoute,
    private facturacionService : FacturacionServiceService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.routerActive.queryParams.subscribe(params => {

      if(params["expediente"]){
        this.obtenerExpediente(params["expediente"]);
        this.obtenerProductosParaFacturarExpediente();
      }
      else{
        this.isEditingFlag = true;
        this.obtenerFactura(params['factura']);
      }

      this.obtenerSeriesFacturacion();

    });

  }

  obtenerFactura(facte_id_facturas_emitidas: string){

    this.facturacionService.obtenerFacturaEmitida(facte_id_facturas_emitidas).subscribe({
      next: (data: FacturaEmitidaData) => {
        this.factura = data.data;

        this.idFactura = this.factura.facte_id_facturas_emitidas;
        this.detalles = this.factura.facte_detalles;
        this.aFacturar = this.factura.facte_a_facturar;
        this.observaciones = this.factura.facte_observaciones;
        this.observacionesFact = this.factura.facte_observaciones_factura;

        this.obtenerFacturaEmitidaProductos(this.factura);
      },
      error: (err: any) => {
        console.log(err);
      }
    })

  }

  obtenerExpediente(exp_id_expedientes_up: string){
    
    this.facturacionService.obtenerExpediente(exp_id_expedientes_up).subscribe({
      next: (data: ExpedienteData) => {
        this.expediente = data.data;
        //console.log('expediente', this.expediente);

        this.numeroExp = this.expediente.exp_id_expedientes_up;
        this.refSiniestroExp = this.expediente.exp_ref_siniestro;
        this.provinciaExp = this.expediente.exp_provincia.prv_descripcion;
        this.companiaExp = this.expediente.exp_cia.cia_nombre;

        if (this.isEditingFlag) {
          this.observacionesFact = this.expediente.exp_observaciones_tramitador;
        }

        this.obtenerAutorizacionesParaFacturarExpediente(this.expediente.exp_id_crm);
        this.obtenerCitasParaFacturarExpediente(this.expediente.exp_id_crm);
        this.obtenerPlantillasParaFacturarExpediente(this.expediente.exp_id_crm);
        this.obtenerLlamadasSegParaFacturarExpediente(this.expediente.exp_id_crm);
        this.obtenerProductosParaFacturarExpediente();
        this.loadingPageFlag = false;
      },
      error: (err: any) => {
        console.log(err);
        this.loadingPageFlag = false;
      }
    });

  }

  obtenerAutorizacionesParaFacturarExpediente(exp_id_crm: string){

    //Obtener listado de autorizaciones
    this.facturacionService.obtenerAutorizacionesParaFacturarExpediente(exp_id_crm).subscribe({
      next: (data: Autorizaciones) => {
        //console.log('Autorizaciones', data.data);
        data.data.filter(aut => aut.producto != null).forEach(aut => {
          if(aut.aut_factura_emitida_producto == undefined){
            this.autorizaciones.push(aut);
            this.registrosFacturables.push(aut);
          }
          else if(this.isEditingFlag && this.facturaProductos.filter(facturaProducto => facturaProducto.factep_id_facturas_emitidas_productos == aut.aut_factura_emitida_producto).length > 0){
            //console.log(aut);
            this.elementosFacturados.push(aut);
          }
        });
        this.dataSourceAutorizaciones = new MatTableDataSource(this.autorizaciones);
        this.dataSourceElementosFact = new MatTableDataSource(this.elementosFacturados);
        this.dataSourceRegistrosFacturables = new MatTableDataSource(this.registrosFacturables);
        this.calculateProductPrices();
      },

      error: (err: any) => {
        console.log(err);
      }
    });

  }

  obtenerCitasParaFacturarExpediente(exp_id_crm: string){

    //Obtener listado de citas
    this.facturacionService.obtenerCitasParaFacturarExpediente(exp_id_crm).subscribe({
      next: (data: Citas) => {
        //console.log('Citas', data.data);
        data.data.forEach(cita => {
          if(cita.cup_factura_emitida_producto == undefined){
            this.citas.push(cita);
            this.registrosFacturables.push(cita);
          }
          else if(this.isEditingFlag && this.facturaProductos.filter(facturaProducto => facturaProducto.factep_id_facturas_emitidas_productos == cita.cup_factura_emitida_producto).length > 0){
            this.elementosFacturados.push(cita);
          }
        });
        this.dataSourceCitas = new MatTableDataSource(this.citas);
        this.dataSourceElementosFact = new MatTableDataSource(this.elementosFacturados);
        this.dataSourceRegistrosFacturables = new MatTableDataSource(this.registrosFacturables);
        this.calculateProductPrices();
      },

      error: (err: any) => {
        console.log(err);
      }
    });

  }

  obtenerPlantillasParaFacturarExpediente(exp_id_crm: string){

    //Obtener listado de plantillas de productos
    this.facturacionService.obtenerPlantillasParaFacturarExpediente(exp_id_crm).subscribe({
      next: (data: PlantillasProductos) => {
        data.data.forEach(plantillaProdGroup => {
          plantillaProdGroup.forEach(plantillaProd => {
            //console.log('plantilla prod', plantillaProd);
            if(plantillaProd.pp_factura_emitida_producto == undefined){
              this.plantillasProd.push(plantillaProd);
              this.registrosFacturables.push(plantillaProd);
            }
            else if(this.isEditingFlag && this.facturaProductos.filter(facturaProducto => facturaProducto.factep_id_facturas_emitidas_productos == plantillaProd.pp_factura_emitida_producto).length > 0){
              this.elementosFacturados.push(plantillaProd);
            }
          });
        });
        this.dataSourcePlantillasProd = new MatTableDataSource(this.plantillasProd);
        this.dataSourceElementosFact = new MatTableDataSource(this.elementosFacturados);
        this.dataSourceRegistrosFacturables = new MatTableDataSource(this.registrosFacturables);
        this.calculateProductPrices();
      },

      error: (err: any) => {
        console.log(err);
      }
    });

  }

  obtenerLlamadasSegParaFacturarExpediente(exp_id_crm: string){

    //Obtener listado de llamadas de seguimiento
    this.facturacionService.obtenerLlamadasSegParaFacturarExpediente(exp_id_crm).subscribe({
      next: (data: LlamadasSegList) => {
        data.data.forEach(llSeg => {
          if(llSeg.lls_factura_emitida_producto == undefined){
            this.llamadasSeg.push(llSeg);
            this.registrosFacturables.push(llSeg);
          }
          else if(this.isEditingFlag && this.facturaProductos.filter(facturaProducto => facturaProducto.factep_id_facturas_emitidas_productos == llSeg.lls_factura_emitida_producto).length > 0){
            this.elementosFacturados.push(llSeg);
          }
        });
        this.dataSourceLlamadasSeg = new MatTableDataSource(this.llamadasSeg);
        this.dataSourceElementosFact = new MatTableDataSource(this.elementosFacturados);
        this.dataSourceRegistrosFacturables = new MatTableDataSource(this.registrosFacturables);
        this.calculateProductPrices();
      },

      error: (err: any) => {
        console.log(err);
      }
    });

  }

  obtenerProductosParaFacturarExpediente(){

    //Obtener listado de productos
    this.facturacionService.obtenerProductosParaFacturarExpediente().subscribe({
      next: (data: Productos) => {
        //console.log('Productos', data.data);
        //console.log('Factura Productos', this.facturaProductos);
        data.data.forEach(prod => {
          this.productos.push(prod);
          this.filteredProductos.push(prod);
          if(this.isEditingFlag && this.facturaProductos.filter(facturaProducto => facturaProducto.factep_producto == prod.prd_id_crm).length > 0){
            this.elementosFacturados.push(prod);
          }
        });
        this.dataSourceElementosFact = new MatTableDataSource(this.elementosFacturados);
        this.calculateProductPrices();
      },

      error: (err: any) => {
        console.log(err);
      }
    });

  }

  obtenerSeriesFacturacion(){

    //Obtener listado de series de facturacion
    this.facturacionService.obtenerSeriesFacturacion().subscribe({
      next: (data: Series) => {
        this.series = data.data;
      },

      error: (err: any) => {
        console.log(err);
      }
    });

    this.dataSourceElementosFact = new MatTableDataSource(this.elementosFacturados);
  
  }

  obtenerFacturaEmitidaProductos(factura: FacturasEmitidas){

    this.facturacionService.obtenerFacturaEmitidaProductos(factura).subscribe({
      next: (data: FacturasEmitidasProductosList) => {
        this.facturaProductos = data.data;
        this.obtenerExpediente(this.factura.facte_expediente);
      },

      error: (err: any) => {
        console.log(err);
      }
    });

  }

  //Metodo que ejecuta cuando se suelta un registro en el dragAndDrop
  drop(event: CdkDragDrop<any>){

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }

    //Sacado de método, dentro de él da error
    //--
    this.dataSourcePlantillasProd.data = clonedeep(this.dataSourcePlantillasProd.data);
    this.plantillasProd = clonedeep(this.dataSourcePlantillasProd.data);

    this.dataSourceLlamadasSeg.data = clonedeep(this.dataSourceLlamadasSeg.data);
    this.llamadasSeg = clonedeep(this.dataSourceLlamadasSeg.data);
    //--

    this._updateAllTableViews();

    this.calculateProductPrices();

  }

  //Metodo que impide arrastrar de elementos facturados a otras tablas
  noReturnPredicate() {
    return false;
  }

  //Metodo que se ejecuta cuando se borra un elemento facturado de su tabla
  deleteElementoFacturado(elemento: any){
    
    this.elementosFacturados = this.elementosFacturados.filter(elementoCheck => { return elementoCheck != elemento; }); 
    this.dataSourceElementosFact.data = this.dataSourceElementosFact.data.filter(function(elementoCheck) { return elementoCheck != elemento; }); 

    if(this.getObjectType(elemento) == "Producto"){
      this.productos.push(elemento);
    }
    else{
      this.registrosFacturables.push(elemento);
      this.dataSourceRegistrosFacturables.data.push(elemento);
    }

    // switch(this.getObjectType(elemento)){
    //   case 'Autorización':
    //     this.autorizaciones.push(elemento);
    //     this.dataSourceAutorizaciones.data.push(elemento);
    //     break;
    //   case 'Cita':
    //     this.citas.push(elemento);
    //     this.dataSourceCitas.data.push(elemento);
    //     break;
    //   case 'Plantilla de productos':
    //     this.plantillasProd.push(elemento);
    //     this.dataSourcePlantillasProd.data.push(elemento);
    //     break;
    //   case 'Llamada de seguimiento':
    //     this.llamadasSeg.push(elemento);
    //     this.dataSourceLlamadasSeg.data.push(elemento);
    //     break;
    //   case 'Producto':
    //     this.productos.push(elemento);
    //     break;
    //   default:
    //     break;
    // }

    // //Sacado de método, dentro de él da error
    // //--
    // this.dataSourcePlantillasProd.data = clonedeep(this.dataSourcePlantillasProd.data);
    // this.plantillasProd = clonedeep(this.dataSourcePlantillasProd.data);

    // this.dataSourceLlamadasSeg.data = clonedeep(this.dataSourceLlamadasSeg.data);
    // this.llamadasSeg = clonedeep(this.dataSourceLlamadasSeg.data);
    // //--

    this._updateAllTableViews();

    this.calculateProductPrices();

  }

  //Metodo que actualiza toda la información de las tablas.
  //Algunos dataSource se encuentran fuera de método ya que dan error (hay que revisar);
  private _updateAllTableViews(){

    this.dataSourceElementosFact.data = clonedeep(this.dataSourceElementosFact.data);
    this.dataSourceRegistrosFacturables.data = clonedeep(this.dataSourceRegistrosFacturables.data);
    // this.dataSourceAutorizaciones.data = clonedeep(this.dataSourceAutorizaciones.data);
    // this.dataSourceCitas.data = clonedeep(this.dataSourceCitas.data);

    this.elementosFacturados = clonedeep(this.dataSourceElementosFact.data);
    this.registrosFacturables = clonedeep(this.dataSourceRegistrosFacturables.data);
    // this.autorizaciones = clonedeep(this.dataSourceAutorizaciones.data);
    // this.citas = clonedeep(this.dataSourceCitas.data);

  }

  //Metodo que devuelve un string con el tipo de objeto
  getObjectType(elemento: Autorizacion | Cita | PlantillaProductos | LlamadasSeg | Producto): string{

    if(elemento.hasOwnProperty('aut_id_crm')){
      return 'Autorización';
    }
    if(elemento.hasOwnProperty('cup_id_crm')){
      return 'Cita';
    }
    if(elemento.hasOwnProperty('pp_id_crm')){
      return 'Plantilla de productos';
    }
    if(elemento.hasOwnProperty('lls_id_crm')){
      return 'Llamada de seguimiento';
    }
    if(elemento.hasOwnProperty('prd_id_crm')){
      return 'Producto';
    }

    return '';
  }

  //Metodo que devuelve el iva del producto
  getId(elemento: Autorizacion | Cita | PlantillaProductos | LlamadasSeg | Producto): string{

    if(elemento.hasOwnProperty('aut_id_crm')){
      return (elemento as Autorizacion).aut_id_autorizaciones.toString();
    }
    if(elemento.hasOwnProperty('cup_id_crm')){
      return (elemento as Cita).cup_id_citas.toString();
    }
    if(elemento.hasOwnProperty('pp_id_crm')){
      return (elemento as PlantillaProductos).pp_id_plantilla_productos.toString();
    }
    if(elemento.hasOwnProperty('lls_id_crm')){
      return (elemento as LlamadasSeg).lls_id_llamadas_seg.toString();
    }
    if(elemento.hasOwnProperty('prd_id_crm')){
      return (elemento as Producto).prd_id_crm.toString();
    }

    return '';
  }

  //Metodo que devuelve la fecha del producto
  getDate(elemento: Autorizacion | Cita | PlantillaProductos | LlamadasSeg | Producto): string{

    if(elemento.hasOwnProperty('aut_id_crm')){
      return (elemento as Autorizacion).aut_fecha_creacion.toString();
    }
    if(elemento.hasOwnProperty('cup_id_crm')){
      return (elemento as Cita).cup_fecha_creacion.toString();
    }
    if(elemento.hasOwnProperty('pp_id_crm')){
      return (elemento as PlantillaProductos).pp_fecha_creacion.toString();
    }
    if(elemento.hasOwnProperty('lls_id_crm')){
      return (elemento as LlamadasSeg).lls_fecha_creacion.toString();
    }
    if(elemento.hasOwnProperty('prd_id_crm')){
      return (elemento as Producto).prd_fecha_creacion.toString();
    }

    return '';
  }

  //Metodo que devuelve el precio del producto
  getPrice(elemento: Autorizacion | Cita | PlantillaProductos | LlamadasSeg | Producto): string{

    if(elemento.hasOwnProperty('aut_id_crm')){
      return (elemento as Autorizacion).producto.prd_coste_estandar != null ? (elemento as Autorizacion).producto.prd_coste_estandar.toString() : "0";
    }
    if(elemento.hasOwnProperty('cup_id_crm')){
      return (elemento as Cita).cup_coste_previsto != null ? (elemento as Cita).cup_coste_previsto.toString() : "0";
    }
    if(elemento.hasOwnProperty('pp_id_crm')){
      return (elemento as PlantillaProductos).pp_precio_venta != null ? (elemento as PlantillaProductos).pp_precio_venta.toString() : "0";
    }
    if(elemento.hasOwnProperty('lls_id_crm')){
      return (elemento as LlamadasSeg).lls_precio != null ? (elemento as LlamadasSeg).lls_precio.toString() : "0";
    }
    if(elemento.hasOwnProperty('prd_id_crm')){
      return (elemento as Producto).prd_coste_estandar != null ? (elemento as Producto).prd_coste_estandar.toString() : "0";
    }

    return '';
  }

  //Metodo que devuelve el iva del producto
  getIva(elemento: Autorizacion | Cita | PlantillaProductos | LlamadasSeg | Producto): string{

    if(elemento.hasOwnProperty('aut_id_crm')){
      return (elemento as Autorizacion).producto.prd_iva != null ? (elemento as Autorizacion).producto.prd_iva.toString() : "0";
    }
    if(elemento.hasOwnProperty('cup_id_crm')){
      return (elemento as Cita).cup_iva != null ? (elemento as Cita).cup_iva.toString() : "0";
    }
    if(elemento.hasOwnProperty('pp_id_crm')){
      return (elemento as PlantillaProductos).pp_iva != null ? (elemento as PlantillaProductos).pp_iva.toString() : "0";
    }
    if(elemento.hasOwnProperty('lls_id_crm')){
      return (elemento as LlamadasSeg).lls_iva != null ? (elemento as LlamadasSeg).lls_iva.toString() : "0";
    }
    if(elemento.hasOwnProperty('prd_id_crm')){
      return (elemento as Producto).prd_iva != null ? (elemento as Producto).prd_iva.toString() : "0";
    }

    return '';
  }

  //Metodo llamado para añadir producto del select a elementos facturados
  public addProductoToTable(producto: Producto){

    this.dataSourceElementosFact.data.push(producto);
    this.productos = this.productos.filter(productoFilter => productoFilter != producto);

    this._updateAllTableViews();
    this.calculateProductPrices();

  }

  //Mostrar nombre de serie al seleccionar
  public displayNameSerie(serie: Serie) {
    if(serie != null){
      return serie.ss_descripcion;
    }
    else{
      return null;
    }
  }

  //Metodo que calcula el precio, mas iva.
  private calculateProductPrices(){

    this.basImp0 =
    this.basImp4 =
    this.basImp10 =
    this.basImp21 =
    this.totalIva4 =
    this.totalIva10 =
    this.totalIva21 =
    this.totalFact = 0;

    var precio = 0;
    var iva = 0;

    //console.log("Elementos facturados", this.elementosFacturados);

    this.elementosFacturados.forEach(elemento => {
      switch(this.getObjectType(elemento)){
        case "Autorización":
          const autorizacion: Autorizacion = elemento as Autorizacion;
          precio = autorizacion.producto.prd_coste_estandar;
          iva = autorizacion.producto.prd_iva;
          console.log(precio, iva);
          break;
        case "Cita":
          const cita: Cita = elemento as Cita;
          precio = cita.cup_precio;
          iva = cita.cup_iva;
          break;
        case "Plantilla de productos":
          const plantillaProd: PlantillaProductos = elemento as PlantillaProductos;
          precio = plantillaProd.pp_precio_venta;
          iva = plantillaProd.pp_iva;
          break;
        case "Llamada de seguimiento":
          const llamadaSeg: LlamadasSeg = elemento as LlamadasSeg;
          precio = llamadaSeg.lls_precio;
          iva = llamadaSeg.lls_iva;
          break;
        case "Producto":
          const producto: Producto = elemento as Producto;
          precio = producto.prd_coste_estandar;
          iva = producto.prd_iva;
          break;
        default:
          break;
      }
      


      switch(iva){
        case null:
          this.basImp0 += precio;
          this.totalFact += precio;
          break;
        case 0:
          this.basImp0 += precio;
          this.totalFact += precio;
          break;
        case 4:
          this.basImp4 += precio;
          this.totalIva4 += precio * 0.04;
          this.totalFact += precio + (precio * 0.04);
          break;
        case 10:
          this.basImp10 += precio;
          this.totalIva10 += precio * 0.1;
          this.totalFact += precio + (precio * 0.1);
          break;
        case 21:
          this.basImp21 += precio;
          this.totalIva21 += precio * 0.21;
          this.totalFact += precio + (precio * 0.21);
          break;
      }

    });
  }

  //Metodo para exportar elementos facturados en Hoja de Excel
  exportarListadoProductosFact() {

    if(this.elementosFacturados.length > 0){

      var ws_data = [[
        "Tipo",
        "Fecha",
        "Precio",
        "IVA"
      ]];

      this.elementosFacturados.forEach(elemento => {
        ws_data.push([
          this.getObjectType(elemento),
          this.getDate(elemento),
          this.getPrice(elemento)+'€',
          this.getIva(elemento)+'%'
        ]);
      });

       /* pass here the table id */
      const ws: XLSX.WorkSheet =XLSX.utils.aoa_to_sheet(ws_data);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */  
      XLSX.writeFile(wb, "Productos facturados.xlsx");

    }

  }

  guardarFactura(){

    this.loadingPageFlag = true;

    //Comprobar si el usuario esta creando o actualizando factura
    if(this.isEditingFlag){

      if(this._checkParamsUpdate()){

        //Creo variables y las inicializo, encargadas de contemplar los cambios por si sucede una falla en la consulta a la API y así no se comprometen los datos de la vista
        let autorizacionesAct: Autorizacion[] = [];
        let citasAct: Cita[] = [];
        let plantillasProdAct: PlantillaProductos[] = [];
        let llamadasSegAct: LlamadasSeg[] = [];
        let productosAct: Producto[] = [];

        let productosFacturaAct: FacturasEmitidasProductos[] = []
        let facturaAct: FacturasEmitidas = this.factura;

        // autorizacionesAct = autorizacionesAct.concat(this.autorizaciones);
        autorizacionesAct = autorizacionesAct.concat(this.registrosFacturables.filter(registro => this.getObjectType(registro) == "Autorización") as Autorizacion[]);
        // citasAct = citasAct.concat(this.citas);
        citasAct = citasAct.concat(this.registrosFacturables.filter(registro => this.getObjectType(registro) == "Cita") as Cita[]);
        // plantillasProdAct = plantillasProdAct.concat(this.plantillasProd);
        plantillasProdAct = plantillasProdAct.concat(this.registrosFacturables.filter(registro => this.getObjectType(registro) == "Plantilla de productos") as PlantillaProductos[]);
        // llamadasSegAct = llamadasSegAct.concat(this.llamadasSeg);
        llamadasSegAct = llamadasSegAct.concat(this.registrosFacturables.filter(registro => this.getObjectType(registro) == "Llamada de seguimiento") as LlamadasSeg[]);
        productosAct = productosAct.concat(this.productos);

        //console.log(plantillasProdAct);
        //console.log(llamadasSegAct);

        productosFacturaAct = productosFacturaAct.concat(this.facturaProductos);

        //Los registros facturables que ya no formen parte de la factura paso su id interno de factura a nulo
        autorizacionesAct.forEach(autorizacion => {
          autorizacion.aut_factura_emitida_producto = null;
        });

        citasAct.forEach(cita => {
          cita.cup_factura_emitida_producto = null;
        });

        plantillasProdAct.forEach(plantillaProd => {
          plantillaProd.pp_factura_emitida_producto = null;
        });

        llamadasSegAct.forEach(llamadaSeg => {
          llamadaSeg.lls_factura_emitida_producto = null;
        });

        productosAct.forEach(producto => {
          producto.prd_factura_emitida_producto = null;
        });

        //Recorro el listado de elementos facturados de la tabla
        this.elementosFacturados.forEach(elementoFact => {

          //Recorrer cada listado de registros facturables para actualizarlos en la base de datos,
          switch(this.getObjectType(elementoFact)){
            case "Autorización":
              const autorizacion: Autorizacion = elementoFact as Autorizacion;
              autorizacionesAct.push(autorizacion);

              if(this.facturaProductos.filter(factProd => factProd.factep_producto == autorizacion.producto.prd_id_crm).length == 0){

                productosFacturaAct.push(
                  {
                    factep_id_facturas_emitidas_productos: undefined,
                    factep_fecha_creacion: undefined,
                    factep_fecha_modificacion: undefined,
                    factep_estado: 'Activo',
                    factep_producto: autorizacion.producto.prd_id_crm,
                    factep_factura_emitida: this.factura.facte_id_facturas_emitidas,
                    factep_fecha: undefined,
                    factep_tipo: 'Autorización',
                    factep_precio_unitario: 0,
                    factep_precio_iva: autorizacion.aut_precio * (autorizacion.aut_iva / 100),
                    factep_iva: autorizacion.aut_iva,
                    factep_base_imponible: autorizacion.aut_precio,
                    factep_precio_total: autorizacion.aut_precio + (autorizacion.aut_precio * (autorizacion.aut_iva / 100)),
                    factep_cantidad: 0,
                    factep_descuento: 0,
                  }
                );

              };

              break;
            case "Cita":
              const cita: Cita = elementoFact as Cita;
              citasAct.push(cita);

              if(this.facturaProductos.filter(factProd => factProd.factep_producto == cita.cup_id_citas).length == 0){
                
                productosFacturaAct.push(
                  {
                    factep_id_facturas_emitidas_productos: undefined,
                    factep_fecha_creacion: undefined,
                    factep_fecha_modificacion: undefined,
                    factep_estado: 'Activo',
                    factep_producto: cita.cup_id_citas,
                    factep_factura_emitida: this.factura.facte_id_facturas_emitidas,
                    factep_fecha: undefined,
                    factep_tipo: 'Cita',
                    factep_precio_unitario: 0,
                    factep_precio_iva: cita.cup_precio * (cita.cup_iva / 100),
                    factep_iva: cita.cup_iva,
                    factep_base_imponible: cita.cup_precio,
                    factep_precio_total: cita.cup_precio + (cita.cup_precio * (cita.cup_iva / 100)),
                    factep_cantidad: 0,
                    factep_descuento: 0,
                  }
                );

              };

              break;
            case "Plantilla de productos":
              const plantillaProd: PlantillaProductos = elementoFact as PlantillaProductos;
              plantillasProdAct.push(plantillaProd);

              if(this.facturaProductos.filter(factProd => factProd.factep_producto == plantillaProd.pp_id_plantilla_productos).length == 0){

                productosFacturaAct.push(
                  {
                    factep_id_facturas_emitidas_productos: undefined,
                    factep_fecha_creacion: undefined,
                    factep_fecha_modificacion: undefined,
                    factep_estado: 'Activo',
                    factep_producto: plantillaProd.pp_id_plantilla_productos,
                    factep_factura_emitida: this.factura.facte_id_facturas_emitidas,
                    factep_fecha: undefined,
                    factep_tipo: 'Plantilla de productos',
                    factep_precio_unitario: 0,
                    factep_precio_iva: plantillaProd.pp_precio_venta * (plantillaProd.pp_iva / 100),
                    factep_iva: plantillaProd.pp_iva,
                    factep_base_imponible: plantillaProd.pp_precio_venta,
                    factep_precio_total: plantillaProd.pp_precio_venta + (plantillaProd.pp_precio_venta * (plantillaProd.pp_iva / 100)),
                    factep_cantidad: 0,
                    factep_descuento: 0,
                  }
                );

              }

              break;
            case "Llamada de seguimiento":
              const llamadaSeg: LlamadasSeg = elementoFact as LlamadasSeg;
              llamadasSegAct.push(llamadaSeg);

              if(this.facturaProductos.filter(factProd => factProd.factep_producto == llamadaSeg.lls_id_llamadas_seg).length == 0){

                productosFacturaAct.push(
                  {
                    factep_id_facturas_emitidas_productos: undefined,
                    factep_fecha_creacion: undefined,
                    factep_fecha_modificacion: undefined,
                    factep_estado: 'Activo',
                    factep_producto: llamadaSeg.lls_id_llamadas_seg,
                    factep_factura_emitida: this.factura.facte_id_facturas_emitidas,
                    factep_fecha: undefined,
                    factep_tipo: 'Llamada de seguimiento',
                    factep_precio_unitario: 0,
                    factep_precio_iva: llamadaSeg.lls_precio * (llamadaSeg.lls_iva / 100),
                    factep_iva: llamadaSeg.lls_iva,
                    factep_base_imponible: llamadaSeg.lls_precio,
                    factep_precio_total: llamadaSeg.lls_precio + (llamadaSeg.lls_precio * (llamadaSeg.lls_iva / 100)),
                    factep_cantidad: 0,
                    factep_descuento: 0,
                  }
                );

              }

              break;
            case "Producto":
              const productoInd: Producto = elementoFact as Producto;
              productosAct.push(productoInd);
              

              if(this.facturaProductos.filter(factProd => factProd.factep_producto == productoInd.prd_id_productos).length == 0){

                productosFacturaAct.push(
                  {
                    factep_id_facturas_emitidas_productos: undefined,
                    factep_fecha_creacion: undefined,
                    factep_fecha_modificacion: undefined,
                    factep_estado: 'Activo',
                    factep_producto: productoInd.prd_id_crm,
                    factep_factura_emitida: this.factura.facte_id_facturas_emitidas,
                    factep_fecha: undefined,
                    factep_tipo: 'Producto',
                    factep_precio_unitario: 0,
                    factep_precio_iva: productoInd.prd_coste_estandar * (productoInd.prd_iva / 100),
                    factep_iva: productoInd.prd_iva,
                    factep_base_imponible: productoInd.prd_coste_estandar,
                    factep_precio_total: productoInd.prd_coste_estandar + (productoInd.prd_coste_estandar * (productoInd.prd_iva / 100)),
                    factep_cantidad: 0,
                    factep_descuento: 0,
                  }
                );

              }

              break;
            default:
              break;
          }
        });

        this.facturaProductos.forEach(factProd => {
          if(
            autorizacionesAct.filter(aut => aut.aut_factura_emitida_producto != undefined).filter(aut => aut.aut_id_autorizaciones == factProd.factep_producto).length == 0 &&
            citasAct.filter(cita => cita.cup_factura_emitida_producto != undefined).filter(cita => cita.cup_id_citas == factProd.factep_producto).length == 0 &&
            plantillasProdAct.filter(pp => pp.pp_factura_emitida_producto != undefined).filter(pp => pp.pp_id_plantilla_productos == factProd.factep_producto).length == 0 &&
            llamadasSegAct.filter(lls => lls.lls_factura_emitida_producto != undefined).filter(lls => lls.lls_id_llamadas_seg == factProd.factep_producto).length == 0 &&
            productosAct.filter(producto => producto.prd_factura_emitida_producto != undefined).filter(producto => producto.prd_id_crm == factProd.factep_producto).length == 0
          ){
            productosFacturaAct = productosFacturaAct.filter(factProdAct => factProdAct.factep_id_facturas_emitidas_productos != factProd.factep_id_facturas_emitidas_productos);
          }
        });

        Object.assign(facturaAct, {
            facte_total_facturado: this.totalFact,
            facte_total_imponible: this.basImp0 + this.basImp4 + this.basImp10 + this.basImp21,
            facte_total_iva: this.totalIva4 + this.totalIva10 + this.totalIva21,
            facte_total_imponible_al_0: this.basImp0,
            facte_total_imponible_al_4: this.basImp4,
            facte_total_imponible_al_10: this.basImp10,
            facte_total_imponible_al_21: this.basImp21,
            facte_total_iva_4: this.totalIva4,
            facte_total_iva_10: this.totalIva10,
            facte_total_iva_21: this.totalIva21,
            facte_detalles: this.detalles,
            facte_a_facturar: this.aFacturar,
            facte_observaciones: this.observaciones,
            facte_observaciones_factura: this.observacionesFact,
        });

        this.loadingPageFlag = false;

        //console.log('Delete', this.facturaProductos.filter( prod => !productosFacturaAct.map(prodFactAct=>prodFactAct.factep_id_facturas_emitidas_productos).includes(prod.factep_id_facturas_emitidas_productos)));
        //console.log('Add', productosFacturaAct.filter( prodFactAct => !this.facturaProductos.map(prod=>prod.factep_id_facturas_emitidas_productos).includes(prodFactAct.factep_id_facturas_emitidas_productos)));
        
        this.facturacionService.actualizarFacturaEmitida(
          this.factura,
          this.facturaProductos.filter( prod => !productosFacturaAct.map(prodFactAct=>prodFactAct.factep_id_facturas_emitidas_productos).includes(prod.factep_id_facturas_emitidas_productos) ),
          productosFacturaAct.filter( prodFactAct => !this.facturaProductos.map(prod=>prod.factep_id_facturas_emitidas_productos).includes(prodFactAct.factep_id_facturas_emitidas_productos) )
        ).subscribe({
          next: (data) => {
            this.loadingPageFlag = false;
            this.dialog.open(ActualizarFacturaDialogComponent, {
              disableClose: true
            });
          },
    
          error: (err: any) => {
            console.log(err);
            this.loadingPageFlag = false;
            this.snackBar.open(err.error.message, undefined, {
              duration: 3 * 1000,
            });
          }
        });

      }
      else{
        this.loadingPageFlag = false;

      }

    }

    else{

      if(this._checkParamsCreate()){

        const factura : FacturasEmitidas = {
          facte_id_facturas_emitidas: this.checkSerieValida ? this.serie.ss_prefijo + this.numFactura : undefined,
          facte_fecha_creacion: undefined,
          facte_fecha_modificacion: undefined,
          facte_estado: 'Activo',
          facte_numero_factura: undefined,
          facte_expediente: this.expediente.exp_id_expedientes_up,
          facte_expediente_ref_siniestro: this.expediente.exp_ref_siniestro,
          facte_fecha_validacion: undefined,
          facte_fecha_cobro: undefined,
          facte_total_facturado: this.totalFact,
          facte_total_imponible: this.basImp0 + this.basImp4 + this.basImp10 + this.basImp21,
          facte_total_iva: this.totalIva4 + this.totalIva10 + this.totalIva21,
          facte_total_imponible_al_0: this.basImp0,
          facte_total_imponible_al_4: this.basImp4,
          facte_total_imponible_al_10: this.basImp10,
          facte_total_imponible_al_21: this.basImp21,
          facte_total_iva_4: this.totalIva4,
          facte_total_iva_10: this.totalIva10,
          facte_total_iva_21: this.totalIva21,
          facte_estado_factura: 'facturado',
          facte_detalles: this.detalles,
          facte_a_facturar: this.aFacturar,
          facte_observaciones: this.observaciones,
          facte_observaciones_factura: this.observacionesFact,
          facte_fecha_cambio_a_validado: undefined,
          facte_usuario_cambio_a_validado: undefined,
          facte_fecha_cambio_a_cobrado: undefined,
          facte_usuario_cambio_a_cobrado: undefined,
          facte_fecha_cambio_a_anulada: undefined,
          facte_usuario_cambio_a_anulada: undefined,
          facte_unidad_de_negocio: undefined,
          facte_contabilidad: undefined,
          facte_incidencia: undefined,
          facte_lesionado: this.expediente.exp_lesionado,
          facte_provincia: this.expediente.exp_provincia.prv_descripcion,
          facte_compania: this.expediente.exp_cia.cia_nombre,
          facte_unidad_fiscal: 'UNIF-00001',
        }
    
        var productosFactura : FacturasEmitidasProductos[] = [];
    
        this.elementosFacturados.forEach(producto => {
          switch(this.getObjectType(producto)){
            case "Autorización":
              const autorizacion = producto as Autorizacion;
              productosFactura.push(
                {
                  factep_id_facturas_emitidas_productos: undefined,
                  factep_fecha_creacion: undefined,
                  factep_fecha_modificacion: undefined,
                  factep_estado: 'Activo',
                  factep_producto: autorizacion.aut_id_autorizaciones,
                  factep_factura_emitida: factura.facte_id_facturas_emitidas,
                  factep_fecha: undefined,
                  factep_tipo: 'Autorización',
                  factep_precio_unitario: 0,
                  factep_precio_iva: autorizacion.aut_precio * (autorizacion.aut_iva / 100),
                  factep_iva: autorizacion.aut_iva,
                  factep_base_imponible: autorizacion.aut_precio,
                  factep_precio_total: autorizacion.aut_precio + (autorizacion.aut_precio * (autorizacion.aut_iva / 100)),
                  factep_cantidad: 0,
                  factep_descuento: 0,
                }
              );
              break;
              case "Cita":
                const cita = producto as Cita;
                productosFactura.push(
                  {
                    factep_id_facturas_emitidas_productos: undefined,
                    factep_fecha_creacion: undefined,
                    factep_fecha_modificacion: undefined,
                    factep_estado: 'Activo',
                    factep_producto: cita.cup_id_citas,
                    factep_factura_emitida: factura.facte_id_facturas_emitidas,
                    factep_fecha: undefined,
                    factep_tipo: 'Cita',
                    factep_precio_unitario: 0,
                    factep_precio_iva: cita.cup_precio * (cita.cup_iva / 100),
                    factep_iva: cita.cup_iva,
                    factep_base_imponible: cita.cup_precio,
                    factep_precio_total: cita.cup_precio + (cita.cup_precio * (cita.cup_iva / 100)),
                    factep_cantidad: 0,
                    factep_descuento: 0,
                  }
                );
                break;
              case "Plantilla de productos":
                const plantillaProd = producto as PlantillaProductos;
                productosFactura.push(
                  {
                    factep_id_facturas_emitidas_productos: undefined,
                    factep_fecha_creacion: undefined,
                    factep_fecha_modificacion: undefined,
                    factep_estado: 'Activo',
                    factep_producto: plantillaProd.pp_id_plantilla_productos,
                    factep_factura_emitida: factura.facte_id_facturas_emitidas,
                    factep_fecha: undefined,
                    factep_tipo: 'Plantilla de productos',
                    factep_precio_unitario: 0,
                    factep_precio_iva: plantillaProd.pp_precio_venta * (plantillaProd.pp_iva / 100),
                    factep_iva: plantillaProd.pp_iva,
                    factep_base_imponible: plantillaProd.pp_precio_venta,
                    factep_precio_total: plantillaProd.pp_precio_venta + (plantillaProd.pp_precio_venta * (plantillaProd.pp_iva / 100)),
                    factep_cantidad: 0,
                    factep_descuento: 0,
                  }
                );
                break;
              case "Llamada de seguimiento":
                const llamadaSeg = producto as LlamadasSeg;
                productosFactura.push(
                  {
                    factep_id_facturas_emitidas_productos: undefined,
                    factep_fecha_creacion: undefined,
                    factep_fecha_modificacion: undefined,
                    factep_estado: 'Activo',
                    factep_producto: llamadaSeg.lls_id_llamadas_seg,
                    factep_factura_emitida: factura.facte_id_facturas_emitidas,
                    factep_fecha: undefined,
                    factep_tipo: 'Llamada de seguimiento',
                    factep_precio_unitario: 0,
                    factep_precio_iva: llamadaSeg.lls_precio * (llamadaSeg.lls_iva / 100),
                    factep_iva: llamadaSeg.lls_iva,
                    factep_base_imponible: llamadaSeg.lls_precio,
                    factep_precio_total: llamadaSeg.lls_precio + (llamadaSeg.lls_precio * (llamadaSeg.lls_iva / 100)),
                    factep_cantidad: 0,
                    factep_descuento: 0,
                  }
                );
                break;
              case "Producto":
                const productoInd = producto as Producto;
                productosFactura.push(
                  {
                    factep_id_facturas_emitidas_productos: undefined,
                    factep_fecha_creacion: undefined,
                    factep_fecha_modificacion: undefined,
                    factep_estado: 'Activo',
                    factep_producto: productoInd.prd_id_crm,
                    factep_factura_emitida: factura.facte_id_facturas_emitidas,
                    factep_fecha: undefined,
                    factep_tipo: 'Producto',
                    factep_precio_unitario: 0,
                    factep_precio_iva: productoInd.prd_coste_estandar * (productoInd.prd_iva / 100),
                    factep_iva: productoInd.prd_iva,
                    factep_base_imponible: productoInd.prd_coste_estandar,
                    factep_precio_total: productoInd.prd_coste_estandar + (productoInd.prd_coste_estandar * (productoInd.prd_iva / 100)),
                    factep_cantidad: 0,
                    factep_descuento: 0,
                  }
                );
                break;
          }
        });
    
        this.facturacionService.crearFacturaEmitida(factura, productosFactura, this.serie ).subscribe({
          next: (data: any) => {
            //console.log(data);
            this.loadingPageFlag = false;
            this.dialog.open(CrearFacturaDialogComponent, {
              disableClose: true
            });
          },
    
          error: (err: any) => {
            this.loadingPageFlag = false;
            this.snackBar.open(err.error.message, undefined, {
              duration: 3 * 1000,
            });
          }
        });

      }
      else{
        this.loadingPageFlag = false;

      }

    }

  }

  //Metodos para mover los registros facturables por el boton de la flecha

  moveRegistro(registro: Autorizacion | Cita | PlantillaProductos | LlamadasSeg){
    this.registrosFacturables = this.registrosFacturables.filter(registroCheck => registroCheck != registro); 
    this.dataSourceRegistrosFacturables.data = this.dataSourceRegistrosFacturables.data.filter(registroCheck => registroCheck != registro); 

    this.moveRegistroFact(registro);
  }

  moveAutorizacion(autorizacion: Autorizacion){
    this.autorizaciones = this.autorizaciones.filter(autorizacionCheck => { return autorizacionCheck != autorizacion; }); 
    this.dataSourceAutorizaciones.data = this.dataSourceAutorizaciones.data.filter(function(autorizacionCheck) { return autorizacionCheck != autorizacion; }); 

    this.moveRegistroFact(autorizacion);
  }

  moveCita(cita: Cita){
    this.citas = this.citas.filter(citaCheck => { return citaCheck != cita; }); 
    this.dataSourceCitas.data = this.dataSourceCitas.data.filter(function(citaCheck) { return citaCheck != cita; }); 

    this.moveRegistroFact(cita);
  }

  movePlantillaProd(plantillaProd: PlantillaProductos){
    this.plantillasProd = this.plantillasProd.filter(plantillaProdCheck => { return plantillaProdCheck != plantillaProd; }); 
    this.dataSourcePlantillasProd.data = this.dataSourcePlantillasProd.data.filter(function(plantillaProdCheck) { return plantillaProdCheck != plantillaProd; }); 

    this.moveRegistroFact(plantillaProd);
  }

  moveLlamadaSeg(llamadaSeg: LlamadasSeg){
    this.llamadasSeg = this.llamadasSeg.filter(llamadaSegCheck => { return llamadaSegCheck != llamadaSeg; }); 
    this.dataSourceLlamadasSeg.data = this.dataSourceLlamadasSeg.data.filter(function(llamadaSegCheck) { return llamadaSegCheck != llamadaSeg; }); 

    this.moveRegistroFact(llamadaSeg);
  }

  private moveRegistroFact(registro: any){

      this.dataSourceElementosFact.data.push(registro);

      //Sacado de método, dentro de él da error
      //--
      this.dataSourcePlantillasProd.data = clonedeep(this.dataSourcePlantillasProd.data);
      this.plantillasProd = clonedeep(this.dataSourcePlantillasProd.data);

      this.dataSourceLlamadasSeg.data = clonedeep(this.dataSourceLlamadasSeg.data);
      this.llamadasSeg = clonedeep(this.dataSourceLlamadasSeg.data);
      //--

      this._updateAllTableViews();

      this.calculateProductPrices();

  }

  checkSerieValida(){
    this.numberFacturaShowFlag = this.serie.ss_validez == 0;
  }

  private _checkParamsCreate() : boolean{
    console.log(this.numFactura);
    if(this.elementosFacturados.length == 0){
      this.snackBar.open("Debe añadir al menos un elemento para facturar", undefined, {
        duration: 3 * 1000,
      });
      return false;
    }
    if(this.serie != null){
      if(this.numberFacturaShowFlag && (this.numFactura === '' || this.numFactura == null)){
        this.snackBar.open("Debe introducir un número de factura", undefined, {
          duration: 3 * 1000,
        });
        return false;
      }
    }
    else{
      this.snackBar.open("Debe seleccionar una serie", undefined, {
        duration: 3 * 1000,
      });
      return false;
    }

    return true;
  }

  private _checkParamsUpdate() : boolean{
    if(this.elementosFacturados.length == 0){
      this.snackBar.open("Debe añadir al menos un elemento para facturar", undefined, {
        duration: 3 * 1000,
      });
      return false;
    }

    return true;
  }

  public filterProductos(nombreProducto: string){
    console.log(nombreProducto);
    if(nombreProducto != ""){
      this.filteredProductos = this.productos.filter(producto => producto.prd_nombre.toLowerCase().includes(nombreProducto.toLowerCase()));
    }

    if(this.filteredProductos.length == 0){
      this.filteredProductos = this.productos;
    }
  }
}
