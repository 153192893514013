export class Expediente {
    numExpediente : string;
    idExpediente : string;
    lesionado : string;
    edadLesionado : number;
    telefono : string;
    condicion : string;
    idCia : string;
    cia : string;
    siniestro : string;
    fechaSiniestro : string;
    provincia : string;
    localidad : string;
    formaProduccion : string;
}
