import { DatePipe, formatDate } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { CancelarEncargoDialogComponent } from '../../../dialogs/cancelar-encargo-dialog/cancelar-encargo-dialog.component';
import { RevisadoDialogComponent } from '../../../dialogs/revisado-dialog/revisado-dialog.component';
import { Archivo } from '../../../models/archivo';
import { CamposVideoConsulta } from '../../../models/campos-video-consulta';
import { FormatoConsulta } from '../../../models/formato_consulta/formato-consulta.model';
import { Medico } from '../../../models/medico';
import { Mensaje } from '../../../models/mensaje';
import { FileService } from '../../../services/file.service';
import { FuncionesPrincipalService } from '../../../services/funciones-principal.service';
import { MedicoPortalService } from '../../../services/medico-portal.service';
import { NotificacionService } from '../../../services/notificacion.service';
import { SessionService } from '../../../services/session.service';
import { TramitadorPortalService } from '../../../services/tramitador-portal.service';
import { EncargoGenerali } from '../../../models/encargoGenerali';
import { User } from '../../../models/user';
 
@Component({
  selector: 'app-encargo-medico-vdc',
  templateUrl: './encargo-medico-vdc.component.html',
  styleUrls: ['./encargo-medico-vdc.component.scss']
})
export class EncargoMedicoVdcComponent implements OnInit {

  private idEncargo;
  medico: Medico = new Medico();
  mostrarProgressSpinner = true;
  textoMensaje: string;
  encargo: EncargoGenerali;
  encargoAnterior : EncargoGenerali;
  encargosAnterioresAsesoriaGraves: EncargoGenerali[];
  encargosBySiniestro: EncargoGenerali[];
  countMensajes: number;
  idMedico: number;
  mensajes: Mensaje[] = [];
  mostrarRevisado: boolean = false;
  comentario: string;
  isUrgente: boolean;
  private mensajesChecked: number[] = [];
  public archivos: Observable<Archivo[]>;
  public imageSelect: string;
  public tipoArchivo: string = '';
  rutaSala: string;
 
  //Campos informe
  numExpediente: string;
  nombreMedico: string;
  numColegiado: string;
  nombreLesionado: string;
  edadLesionado: number;
  provincia: string;
  localidad: string;
  responsabilidad: string;
  peticion: string;
  fechaSiniestro: string;
  refSiniestro: string;
  consideracion: string;
  conclusiones: string;
  idTipo: number;
  mecanismoProduccion: string;
  condicionLesionado: string;
  idNombreTramitador : number;
  medicoUser : User;


  //Campos informe REHUSE CAS
  objetoInforme: string;
  profesion: string;
  situacionLaboral: string;
  bajaLaboral: string;
  contingencia: string;
  antecedentes: string;
  centroUrgencias: string;
  cuandoAcude: string;
  diagnosticoUrgencias: string;
  centroRehab: string;
  medicoSeguimiento: string;
  inicioTratamiento: string;
  altaMedica: string;
  numConsultas: string;
  numSesiones: string;
  pruebasRealizadas: string;
  evolucion: string;
  fechaBajaLaboral: string;
  fechaAltaLaboral: string;
  hospitalizacionUrgencias: string;

  //Campos informe REHUSE CAS + VIDEO
  fuenteDocumental: string;
  referenciaInforme: string;
  firmadoPor: string;
  tipoImpacto: string;
  deltaV: string;
  aceleracion: string;
  fechaVideoConsulta: string;
  mediosUsados: string;
  anamnesis: string;
  exploracionFisica: string;
  id_formato_consulta: number;
  formatoConsulta : FormatoConsulta[];

  //Campos VALORACIÓN VIDEO-CONSULTA
  pruebasRealizadasVideoConsulta: string;
  tratamientoRehabilitador: string;
  intervencionesQuirurgicas: string;
  diasBasicos: string = "0";
  diasModerados: string = "0";
  diasGraves: string = "0";
  diasMuyGraves: string = "0";
  diasTotales: string = "0";
  diasBasicosMax: string = "0";
  diasModeradosMax: string = "0";
  diasGravesMax: string = "0";
  diasMuyGravesMax: string = "0";
  diasTotalesMax: string = "0";
  descripcionPerjuicioEstetico: string;
  puntosPerjuicioEstetico: string;
  puntosPerjuicioEsteticoMax: string;
  perjuicioMoral: string = "No";
  grado: string;
  porcentajeGrado: string;
  perjuicioPersonal: string = "No";
  grupoQuirurgico: string;
  otrosCorrectores: string = "No";
  observaciones: string;
  secuelaForm: FormGroup;
  secuelaHorquillaForm: FormGroup;
  secuelaObj;
  numSecuelas: number;
  puntosTotalesSecuelas: number = 0;
  puntosMinTotalesSecuelas: number = 0;
  puntosMaxTotalesSecuelas: number = 0;
  observacionesAseguradora: string;
  observacionesIMD: string;
  secuelaReservaForm: FormGroup;
  intervencionForm: FormGroup;
  intervencionReservaForm: FormGroup;
  secuelaReservaObj;
  intervencionObj;
  intervencionReservaObj;
  numSecuelasReserva: number;
  numIntervenciones: number;
  numIntervencionesReserva: number;
  puntosTotalesSecuelasReserva: number = 0;
  puntosTotalesIntervenciones: number = 0;
  puntosTotalesIntervencionesReserva: number = 0;
  proximaRevision: string;

  //Modelo estudio documental
  xDanoMoral: boolean = false;
  xDanoMoralEstetico: boolean = false;
  xPerjuicioMoral: boolean = false;
  xPerjuicioExcepcional: boolean = false;
  xGastosPrevisibles: boolean = false;
  xProtesisOrtesis: boolean = false;
  xRehabilitacion: boolean = false;
  xAyudasTecnicas: boolean = false;
  xAdecuacion: boolean = false;
  xPerjuicioPatrimonial: boolean = false;
  xHorasNecesarias: boolean = false;
  horasNecesarias: string = "0";
  valoracionFinal: boolean = false;
  valoracionProv: boolean = false;
  imd: boolean = true;

  //reserva técnica
  reservaTecnica : boolean = false;
  dias_ppp_basicos_reserva : string;
  dias_ppp_moderados_reserva : string;
  dias_ppp_graves_reserva : string;
  dias_ppp_muy_graves_reserva : string;
  dias_ppp_totales_reserva : string;
  secuelas_reserva : string;
  descripcion_perjuicio_estetico_reserva : string;
  puntos_perjuicio_estetico_reserva : string;
  perjuicio_moral_reserva : string;
  perjuicio_personal_reserva : string;
  grado_reserva : string;
  porcentaje_reserva : string;
  x_dano_moral_reserva : boolean;
  x_dano_moral_estetico_reserva : boolean;
  x_perjuicio_moral_reserva : boolean;
  x_perjuicio_excepcional_reserva : boolean;
  x_gastos_previsibles_reserva : boolean ;
  x_protesis_ortesis_reserva : boolean;
  x_rehabilitacion_reserva : boolean;
  x_ayudas_tecnicas_reserva : boolean;
  x_adecuacion_reserva : boolean;
  x_perjuicio_patrimonial_reserva : boolean;
  x_horas_necesarias_reserva : boolean;
  otros_correctores_reserva : string;
  horas_necesarias_reserva : string;
  observaciones_reserva : string;
  addClassSecuelasAntiguas: boolean = false;
  addClassSecuelasEsteticasAntiguas: boolean = false;
  addClassSecuelasAntiguasReserva: boolean = false;
  addClassIntervencionesAntiguas: boolean = false;
  addClassIntervencionesAntiguasReserva: boolean = false;

  //Secuelas Estéticas
  secuelasEsteticasForm: FormGroup;
  secuelasEsteticasObj;
  numSecuelasEsteticas: number;
  puntosTotalesSecuelasEsteticas: number = 0;

   //Campos Generali nuevos
   estadoInicial         : string;
   fechaUltimaConsulta   = new FormControl(null);
   estadoFinal           : string;
   bioFuncional          : string;
   coherenciaSeguimiento : string;
   fechaPrueba           = new FormControl(null);
   consistenciaPrueba    : string;
   movilidad             : string;

  //Seguimiento Diversos
  evolutivoConsultasValoracion: string;
  fechaProximaConsulta: string;

  //Seguimiento No Auto
  situacionIncapacidad : string;
  tiempoRecuperacion : number;

  //Asesoria graves
  numInforme: string;
  fechaAsesoria: string;
  asesorMedico: string;
  observacionesAsesoria: string;

  fechaConsultaVideoConsulta: string;
  horaConsultaVideoConsulta: string;

  formUploadFile: FormGroup;

  camposVideoConsultaAnteriores: CamposVideoConsulta[];

  idIntervalGuardado;

  dpFechaVideoConsulta = new FormControl(null);

  rutaCarpetaDocMedico : string;
  archivosCarpetaDocMedico: Archivo[];

  constructor(private el: ElementRef, 
    private route: ActivatedRoute, 
    private medicoService: MedicoPortalService, 
    private tramitadorService : TramitadorPortalService,
    private notificacion: NotificacionService, 
    public fileService: FileService, 
    private funcionesService: FuncionesPrincipalService, 
    private router: Router, 
    private dialog: MatDialog, 
    private sessionService: SessionService,
    private fb:FormBuilder,
    private title : Title) {

      this.formUploadFile = this.fb.group({
        archFile: null
      });

      this.secuelaForm = this.fb.group({
        secuelas: this.fb.array([])
      });

      this.secuelasEsteticasForm = this.fb.group({
        secuelas: this.fb.array([])
      });

      this.secuelaReservaForm = this.fb.group({
        secuelas: this.fb.array([])
      });

      this.intervencionForm = this.fb.group({
        intervenciones: this.fb.array([])
      });

      this.intervencionReservaForm = this.fb.group({
        intervenciones: this.fb.array([])
      });

      this.secuelaHorquillaForm = this.fb.group({
        secuelas: this.fb.array([])
      });

      this.encargo = new EncargoGenerali();
      //Para poder refrescar cambios en la pagina cuando navego desde ella misma, ej cambio de encargo en el historico siniestro
      this.route.params.subscribe(params =>{
        this.idEncargo = params['idEncargo'];
        this.idTipo    = params['idTipo'];
        //this.ngOnInit();
      });

      this.medico = this.sessionService.getCurrentSession().medico;

      //Cuando haya algun cambio en las secuelas para mostrar el total de puntos
      this.secuelaForm.get("secuelas").valueChanges.subscribe(selectedValue =>{
        this.totalPuntosSecuelas();
      });

      //Cuando haya algun cambio en las secuelas para mostrar el total de puntos
      this.secuelaReservaForm.get("secuelas").valueChanges.subscribe(selectedValue =>{
        this.totalPuntosSecuelasReserva();
      });

      //Cuando haya algun cambio en las secuelas para mostrar el total de puntos
      this.secuelaHorquillaForm.get("secuelas").valueChanges.subscribe(selectedValue =>{
        this.totalPuntosSecuelasMin();
        this.totalPuntosSecuelasMax();
      });



   }

  ngOnInit(): void {
    
    this.mostrarProgressSpinner = true;
    this.archivos = null;
    //Inicio explorador de archivos
    this.fileService.init();

    this.getEncargo();
    this.getMensajesEncargo();
    this.getFormatoConsulta();

    //Cuando haya algun cambio en las secuelas para mostrar el total de puntos
    this.secuelaForm.get("secuelas").valueChanges.subscribe(selectedValue =>{
      this.totalPuntosSecuelas();
    });
    this.title.setTitle(this.encargo.id_plataforma);

    //Cuando haya algun cambio en las secuelas para mostrar el total de puntos
    this.secuelasEsteticasForm.get("secuelas").valueChanges.subscribe(selectedValue =>{
      this.totalPuntosSecuelasEsteticas();
    });
  }

  getFormatoConsulta() { 
    this.tramitadorService.getFormatoConsulta().subscribe({
      next: (data: any) => {
        //console.log(data);
        this.formatoConsulta = data['data'];
      },
      error: (err: any) => {
        console.log(err);
      },
      
    });
  }

  ngOnDestroy(): void{
    //Eliminar guardado automatico
    if(this.idIntervalGuardado){
      clearInterval(this.idIntervalGuardado);
    }
  }

  //Guardado automatico
  guardadoAutomatico(){
    this.idIntervalGuardado = setInterval(()=>{
      this.guardarDatosEncargo("automatico");
    }, 60000);
  }

  secuelas(): FormArray{
    return this.secuelaForm.get("secuelas") as FormArray;
  }

  secuelasEsteticas(): FormArray{
    return this.secuelasEsteticasForm.get("secuelas") as FormArray;
  }

  secuelasReserva(): FormArray{
    return this.secuelaReservaForm.get("secuelas") as FormArray;
  }

  intervenciones(): FormArray{
    return this.intervencionForm.get("intervenciones") as FormArray;
  }

  intervencionesReserva(): FormArray{
    return this.intervencionReservaForm.get("intervenciones") as FormArray;
  }

  nuevaSecuela(): FormGroup {  
    return this.fb.group({  
      codigo: '',  
      descripcion: '',
      puntos: '',
    });
  }  

  nuevaSecuelasEsteticas(): FormGroup {  
    return this.fb.group({  
      codigo: '',  
      descripcion: '',
      puntos: '',
    });
  }  

  nuevaIntervencion(): FormGroup {  
    return this.fb.group({  
      grupo: '',  
      descripcion: '',
    });
  }  

  addNuevasSecuelas(){
    this.secuelas().push(this.nuevaSecuela());
    this.addClassSecuelasAntiguas = false;
  }

  addNuevasSecuelasEsteticas(){
    this.secuelasEsteticas().push(this.nuevaSecuelasEsteticas());
    this.addClassSecuelasEsteticasAntiguas = false;
  }

  addNuevasSecuelasReserva(){
    this.secuelasReserva().push(this.nuevaSecuela());
    this.addClassSecuelasAntiguasReserva = false;
  }

  addNuevasIntervenciones(){
    this.intervenciones().push(this.nuevaIntervencion());
    this.addClassIntervencionesAntiguas = false;
  }

  addNuevasIntervencionesReserva(){
    this.intervencionesReserva().push(this.nuevaIntervencion());
    this.addClassIntervencionesAntiguas = false;
  }

  quitarSecuela(i:number){
    this.secuelas().removeAt(i);
    this.addClassSecuelasAntiguas = false;
  }

  quitarSecuelasEsteticas(i:number){
    this.secuelasEsteticas().removeAt(i);
    this.addClassSecuelasEsteticasAntiguas = false;
  }

  quitarSecuelaReserva(i:number){
    this.secuelasReserva().removeAt(i);
    this.addClassSecuelasAntiguasReserva = false;
  }

  quitarIntervencion(i:number){
    this.intervenciones().removeAt(i);
    this.addClassIntervencionesAntiguas = false;
  }

  quitarIntervencionReserva(i:number){
    this.intervencionesReserva().removeAt(i);
    this.addClassIntervencionesAntiguasReserva = false;
  }

  secuelasHorquilla(): FormArray{
    return this.secuelaHorquillaForm.get("secuelas") as FormArray;
  }

  nuevaSecuelaHorquilla(): FormGroup {  
    return this.fb.group({  
      codigo: '',  
      descripcion: '',
      puntosMin: '',
      puntosMax: '',
    });
  }  

  addNuevasSecuelasHorquilla(){
    this.secuelasHorquilla().push(this.nuevaSecuelaHorquilla());
  }

  quitarSecuelaHorquilla(i:number){
    this.secuelasHorquilla().removeAt(i);
  }

  sumarDiasPPP(){
    let total : number;
    total = parseInt(this.diasBasicos) + parseInt(this.diasModerados) + parseInt(this.diasGraves) + parseInt(this.diasMuyGraves);
    this.diasTotales = total.toString();
  }

  sumarDiasPPPMax(){
    let total : number;
    total = parseInt(this.diasBasicosMax) + parseInt(this.diasModeradosMax) + parseInt(this.diasGravesMax) + parseInt(this.diasMuyGravesMax);
    this.diasTotalesMax = total.toString();
  }
  
  getArchivosExplorador(){
    let datos = {
      'numExpediente': this.encargo.upgen_expediente,
      'idTipoEncargo': this.encargo.upgen_id_tipo_encargo,
      'idEstado': this.encargo.upgen_id_estado_encargo,
      'idCia': this.encargo.upgen_cia
    };
    //Me traigo los archivos para el explorador
    this.medicoService.getArchivosExploradorMedicoGenerali(datos).subscribe(
      data =>{
        if(data['response'] == "ok"){
          this.fileService.loadMap(data['archivos']);
          this.archivos = observableOf(data['archivos']);
          this.updateFileElementQuery();
          this.rutaCarpetaDocMedico = data['ruta'];
          this.archivosCarpetaDocMedico =data['archivos'];
        }else if(data['response'] == "fail"){
          this.notificacion.notificationFail(data['mensaje']);
        }else{
          this.notificacion.notificationFail("Error");
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  rellenarCamposInforme(){
    this.numExpediente = this.encargo.upgen_expediente;
    this.nombreMedico = this.medico.med_nombre;
    this.idMedico = this.encargo.upgen_id_medico;
    this.numColegiado = this.medico.med_num_colegiado;
    this.idTipo = this.encargo.upgen_id_tipo_encargo;
    this.nombreLesionado = this.encargo.upgen_lesionado;
    this.edadLesionado = this.encargo.upgen_edad_lesionado;
    this.provincia = this.encargo.upgen_provincias;
    this.localidad = this.encargo.upgen_localidad;
    this.responsabilidad = this.encargo.upgen_responsabilidad_aseguradora;
    this.peticion = this.encargo.upgen_peticion;
    this.fechaSiniestro = this.encargo.upgen_fecha_siniestro;
    this.refSiniestro = this.encargo.upgen_referencia_siniestro;
    this.profesion = this.encargo.upgen_profesion;
    this.situacionLaboral = this.encargo.upgen_situacion_laboral;
    this.bajaLaboral = this.encargo.upgen_baja_laboral;
    this.fechaBajaLaboral = this.encargo.upgen_fecha_baja_laboral;
    this.fechaAltaLaboral = this.encargo.upgen_fecha_alta_laboral;
    this.contingencia = this.encargo.upgen_contingencia;
    this.antecedentes = this.encargo.upgen_antecedentes_personales;
    this.centroUrgencias = this.encargo.upgen_centro_urgencias;
    this.hospitalizacionUrgencias = this.encargo.upgen_hospitalizacion_urgencias;
    this.cuandoAcude = this.encargo.upgen_cuando_acude;
    this.diagnosticoUrgencias = this.encargo.upgen_diagnostico_urgencias;
    this.centroRehab = this.encargo.upgen_centro_rehabilitacion;
    this.medicoSeguimiento = this.encargo.upgen_medico_seguimiento;
    this.inicioTratamiento = this.encargo.upgen_inicio_tratamiento;
    this.altaMedica = this.encargo.upgen_alta_medica;
    this.numConsultas = this.encargo.upgen_num_consultas;
    this.numSesiones = this.encargo.upgen_num_sesiones;
    this.pruebasRealizadas = this.encargo.upgen_pruebas_realizadas;
    this.fuenteDocumental = this.encargo.upgen_fuente_documental;
    this.referenciaInforme = this.encargo.upgen_num_referencia_informe;
    this.firmadoPor = this.encargo.upgen_firmado_por;
    //this.fechaVideoConsulta = this.encargo.upgen_fecha_video_consulta;
    this.isUrgente = this.encargo.upgen_prioridad == 1 ? true : false;

    this.diasBasicos = this.encargo.upgen_dias_ppp_basicos == null ? "0" : this.encargo.upgen_dias_ppp_basicos;
    this.diasModerados = this.encargo.upgen_dias_ppp_moderados == null ? "0" : this.encargo.upgen_dias_ppp_moderados;
    this.diasGraves = this.encargo.upgen_dias_ppp_graves == null ? "0" : this.encargo.upgen_dias_ppp_graves;
    this.diasMuyGraves = this.encargo.upgen_dias_ppp_muy_graves == null ? "0" : this.encargo.upgen_dias_ppp_muy_graves;
    this.diasTotales = this.encargo.upgen_dias_ppp_totales == null ? "0" : this.encargo.upgen_dias_ppp_totales;
    this.diasBasicosMax = this.encargo.upgen_dias_ppp_basicos_max == null ? "0" : this.encargo.upgen_dias_ppp_basicos_max;
    this.diasModeradosMax = this.encargo.upgen_dias_ppp_moderados_max == null ? "0" : this.encargo.upgen_dias_ppp_moderados_max;
    this.diasGravesMax = this.encargo.upgen_dias_ppp_graves_max == null ? "0" : this.encargo.upgen_dias_ppp_graves_max;
    this.diasMuyGravesMax = this.encargo.upgen_dias_ppp_muy_graves_max == null ? "0" : this.encargo.upgen_dias_ppp_muy_graves_max;
    this.diasTotalesMax = this.encargo.upgen_dias_ppp_totales_max == null ? "0" : this.encargo.upgen_dias_ppp_totales_max;
    this.observaciones = this.encargo.upgen_observaciones;
    this.mecanismoProduccion = this.encargo.upgen_mecanismo_produccion;
    this.rutaSala = this.encargo.upgen_url_videoconferencia;
    this.condicionLesionado = this.encargo.upgen_condicion_lesionado;
    this.id_formato_consulta = this.encargo.formato_consulta;
    this.estadoInicial = this.encargo.upgen_estado_inicial;
    this.fechaUltimaConsulta =  new FormControl(this.encargo.upgen_fecha_ultima_consulta == null ? null : this.dateStringtoDate(this.encargo.upgen_fecha_ultima_consulta));
    this.estadoFinal = this.encargo.upgen_estado_final;
    this.bioFuncional = this.encargo.upgen_bio_funcional;
    this.coherenciaSeguimiento = this.encargo.upgen_coherencia_seguimiento;
    this.fechaPrueba = new FormControl(this.encargo.upgen_fecha_prueba == null ? null : this.dateStringtoDate(this.encargo.upgen_fecha_prueba));
    this.consistenciaPrueba = this.encargo.upgen_consistencia_prueba;
    this.movilidad = this.encargo.upgen_movilidad;


    //rellenar campo dinamico secuelas
    //Si es VALORACIÓN VIDEO-CONSULTA
      this.secuelaObj = JSON.parse(this.encargo.upgen_secuelas == null ? '{"secuelas":[]}': this.encargo.upgen_secuelas);
      this.secuelaObj.secuelas.forEach(secuela =>{
        this.secuelas().push(this.fb.group(secuela));
      });
      this.numSecuelas = this.secuelas().length;
      
      //Si hay secuelas sumar puntos
      if(this.secuelas().length > 0){
        this.totalPuntosSecuelas();
      }

      //secuelas estéticas
      this.secuelasEsteticasObj = JSON.parse(this.encargo.upgen_secuelas_esteticas == null ? '{"secuelas":[]}': this.encargo.upgen_secuelas_esteticas);
      this.secuelasEsteticasObj.secuelas.forEach(secuela =>{
        this.secuelasEsteticas().push(this.fb.group(secuela));
      });
      this.numSecuelasEsteticas = this.secuelasEsteticas().length;
      //Si hay secuelas sumar puntos
      if(this.secuelasEsteticas().length > 0){
        this.totalPuntosSecuelasEsteticas();
      }

  }

  //calcular puntos de las secuelas con la formula de balthazar
  totalPuntosSecuelas(){
    let totalSecuelas;
    let puntosArray: number[] = [];
    let i = 0;
    let resultado = 0;

    totalSecuelas = this.secuelaForm.value;

    totalSecuelas.secuelas.forEach(secuela =>{
      puntosArray.push(parseInt(secuela.puntos));
    });

    if(puntosArray.length != 1){
      puntosArray.sort((a,b) => b-a);

      while(i<puntosArray.length){
        if(i==0){
          resultado = Math.ceil((((100-puntosArray[i]) * puntosArray[i+1])/100)+puntosArray[i]);
          i = i+2;
        }else{
          resultado = Math.ceil((((100-resultado) * puntosArray[i])/100)+resultado);
          i++;
        }
      }
    }else{
      resultado = puntosArray[0];
    }

    //Numero de secuelas
    this.numSecuelas = puntosArray.length;

    //Mostrar en variable
    this.puntosTotalesSecuelas = resultado;
    
    return resultado;

  }

  totalPuntosSecuelasEsteticas() {
    let totalSecuelas;
    let puntosArray: number[] = [];
    let resultado = 0;

    totalSecuelas = this.secuelasEsteticasForm.value;

    // Recolectar los puntos en un array
    totalSecuelas.secuelas.forEach(secuela =>{
      puntosArray.push(parseInt(secuela.puntos));
    });

    // Sumar todos los puntos
    resultado = puntosArray.reduce((sum, current) => sum + current, 0);

    // Numero de secuelas
    this.numSecuelas = puntosArray.length;

    // Mostrar en variable
    this.puntosTotalesSecuelasEsteticas = resultado;
    return resultado;
  }

  //calcular puntos de las secuelas con la formula de balthazar
  totalPuntosSecuelasReserva(){
    let totalSecuelas;
    let puntosArray: number[] = [];
    let i = 0;
    let resultado = 0;

    totalSecuelas = this.secuelaReservaForm.value;

    totalSecuelas.secuelas.forEach(secuela =>{
      puntosArray.push(parseInt(secuela.puntos));
    });

    if(puntosArray.length != 1){
      puntosArray.sort((a,b) => b-a);

      while(i<puntosArray.length){
        if(i==0){
          resultado = Math.ceil((((100-puntosArray[i]) * puntosArray[i+1])/100)+puntosArray[i]);
          i = i+2;
        }else{
          resultado = Math.ceil((((100-resultado) * puntosArray[i])/100)+resultado);
          i++;
        }
      }
    }else{
      resultado = puntosArray[0];
    }

    //Numero de secuelas
    this.numSecuelasReserva = puntosArray.length;
    
    //Mostrar en variable
    this.puntosTotalesSecuelasReserva = resultado;
    return resultado;

  }

  //calcular puntos de las secuelas con la formula de balthazar
  totalPuntosSecuelasMin(){
    let totalSecuelas;
    let puntosArray: number[] = [];
    let i = 0;
    let resultado = 0;

    totalSecuelas = this.secuelaHorquillaForm.value;

    //console.log(totalSecuelas);

    totalSecuelas.secuelas.forEach(secuela =>{
      puntosArray.push(parseInt(secuela.puntosMin));
    });

    if(puntosArray.length != 1){
      puntosArray.sort((a,b) => b-a);

      while(i<puntosArray.length){
        if(i==0){
          resultado = Math.ceil((((100-puntosArray[i]) * puntosArray[i+1])/100)+puntosArray[i]);
          i = i+2;
        }else{
          resultado = Math.ceil((((100-resultado) * puntosArray[i])/100)+resultado);
          i++;
        }
      }
    }else{
      resultado = puntosArray[0];
    }

    //Numero de secuelas
    this.numSecuelas = puntosArray.length;
    
    //Mostrar en variable
    this.puntosMinTotalesSecuelas = resultado;
    return resultado;

  }

  //calcular puntos de las secuelas con la formula de balthazar
  totalPuntosSecuelasMax(){
    let totalSecuelas;
    let puntosArray: number[] = [];
    let i = 0;
    let resultado = 0;

    totalSecuelas = this.secuelaHorquillaForm.value;

    totalSecuelas.secuelas.forEach(secuela =>{
      puntosArray.push(parseInt(secuela.puntosMax));
    });

    if(puntosArray.length != 1){
      puntosArray.sort((a,b) => b-a);

      while(i<puntosArray.length){
        if(i==0){
          resultado = Math.ceil((((100-puntosArray[i]) * puntosArray[i+1])/100)+puntosArray[i]);
          i = i+2;
        }else{
          resultado = Math.ceil((((100-resultado) * puntosArray[i])/100)+resultado);
          i++;
        }
      }
    }else{
      resultado = puntosArray[0];
    }

    //Numero de secuelas
    this.numSecuelas = puntosArray.length;
    
    //Mostrar en variable
    this.puntosMaxTotalesSecuelas = resultado;
    return resultado;

  }

  getMedicoEncargo(id: number) {

    this.tramitadorService.getMedicoEncargo(id).subscribe(
      data => {
        if(data['status'] == 200){
         this.medicoUser = data['data'];
        }else if(data['response'] == "fail"){
          this.notificacion.notificationFail(data['mensaje']);
        }
        
      }, error => {
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );

  }

  generarInforme(){
    this.mostrarProgressSpinner = true;
    var datos;
    console.log(this.sessionService.getCurrentSession());

    datos = {
      'idTipoEncargo': this.encargo.upgen_id_tipo_encargo,
      'idEstado': this.encargo.upgen_id_estado_encargo,
      'idCia': this.encargo.upgen_cia,
      'numExpediente': this.numExpediente,
      'nombreMedico': this.encargo.nombreMedico,
      'numColegiado': this.encargo.numColegiadoMedico,
      'logrosMedico': this.encargo.logrosMedico,
      'nombreLesionado': this.nombreLesionado,
      'edadLesionado': this.edadLesionado,
      'provincia': this.provincia,
      'localidad': this.localidad,
      'fechaSiniestro': this.fechaSiniestro,
      'refSiniestro': this.refSiniestro,
      'urlFirma': this.medicoUser.url_firma,
      'genero': this.sessionService.getCurrentSession().user.genero,
      'consideracion': this.consideracion,
      'conclusiones': this.conclusiones,
      'mecanismoProduccion': this.mecanismoProduccion,
      'fechaConsultaVideoConsulta': this.fechaConsultaVideoConsulta,
      'condicionLesionado': this.condicionLesionado,
      'formato_consulta' : this.id_formato_consulta
    };



      datos['profesion'] = this.profesion;
      datos['situacionLaboral'] = this.situacionLaboral;
      datos['bajaLaboral'] = this.bajaLaboral;
      datos['contingencia'] = this.contingencia;
      datos['antecedentes'] = this.antecedentes;
      datos['centroUrgencias'] = this.centroUrgencias;
      datos['cuandoAcude'] = this.cuandoAcude;
      datos['diagnosticoUrgencias'] = this.diagnosticoUrgencias;
      datos['hospitalizacionUrgencias'] = this.hospitalizacionUrgencias;
      datos['centroRehab'] = this.centroRehab;
      datos['medicoSeguimiento'] = this.medicoSeguimiento;
      datos['inicioTratamiento'] = this.inicioTratamiento;
      datos['altaMedica'] = this.altaMedica;
      datos['numConsultas'] = this.numConsultas;
      datos['numSesiones'] = this.numSesiones;
      datos['pruebasRealizadas'] = this.pruebasRealizadas;
      datos['evolucion'] = this.evolucion;
      datos['fuenteDocumental'] = this.fuenteDocumental == null ? "No se aporta" : this.fuenteDocumental;
      datos['fechaBajaLaboral'] = this.bajaLaboral == "Si" ? this.fechaBajaLaboral : null;
      datos['fechaAltaLaboral'] = this.bajaLaboral == "Si" ? this.fechaAltaLaboral : null;

      datos['diasBasicos'] = this.diasBasicos;
      datos['diasModerados'] = this.diasModerados;
      datos['diasGraves'] = this.diasGraves;
      datos['diasMuyGraves'] = this.diasMuyGraves;
      datos['diasTotales'] = this.diasTotales;
      datos['observaciones'] = this.observaciones;
    
      datos['estadoInicial'] = this.estadoInicial;
      datos['fechaUltimaConsulta'] = this.fechaUltimaConsulta.value == null ? null : formatDate(this.fechaUltimaConsulta.value, 'dd/MM/yyyy', "en-GB");
      datos['estadoFinal'] = this.estadoFinal;
      datos['bioFuncional'] = this.bioFuncional;
      datos['coherenciaSeguimiento'] = this.coherenciaSeguimiento;
      datos['fechaPrueba'] = this.fechaPrueba.value == null ? null : formatDate(this.fechaPrueba.value, 'dd/MM/yyyy', "en-GB");
      datos['consistenciaPrueba'] = this.consistenciaPrueba;
      datos['movilidad'] = this.movilidad;

      datos['puntosSecuelas'] = this.totalPuntosSecuelas();
      datos['numSecuelas'] = this.numSecuelas.toString();
      //Para que aparezca la tabla del informe pero con los datos vacios
      if(this.numSecuelas == 0){
        datos['secuelas'] = JSON.stringify({"secuelas":[{"codigo":"","descripcion":"","puntos":""}]});
      }else{
        datos['secuelas'] = JSON.stringify(this.secuelaForm.value);
      }

      datos['formatoConsulta']  = this.id_formato_consulta;

      datos['puntosSecuelasEsteticas'] = this.totalPuntosSecuelasEsteticas();

      if(this.numSecuelasEsteticas == 0){
        datos['secuelasEsteticas'] = JSON.stringify({"secuelas":[{"codigo":"","descripcion":"","puntos":""}]});
      }else{
        datos['secuelasEsteticas'] = JSON.stringify(this.secuelasEsteticasForm.value);
      }

    this.funcionesService.generarInformeMedicoGenerali(datos).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          this.notificacion.notificationOk(data['mensaje']);
          //traerme los archivos del encargo
          this.getArchivosExplorador();
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error => {
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  enviarMensajeEncargo(){
    this.mostrarProgressSpinner = true;
    var datos = {
      'idEncargo': this.idEncargo,
      'idUsuario': this.medico.med_id_user,
      'textoMensaje': this.textoMensaje
    };
    
    this.funcionesService.addMensajeEncargoGenerali(datos).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          console.log(data['mensaje']);
          //Limpiar text area
          this.textoMensaje = '';
          this.notificacion.notificationOk(data['mensaje']);
          //enviar email al tramitador porque hay un nuevo mensaje
          this.enviarEmailNuevoMensaje();
          this.getMensajesEncargo();
          this.getEncargo();
        }else{
          console.log(data['mensaje']);
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  enviarEmailNuevoMensaje(){
    let datosEmail ={
      'idEncargo': this.idEncargo
    };

    this.medicoService.enviarEmailNuevoMensajeEncargoGenerali(datosEmail).subscribe(
      data =>{
        if(data['response'] == "ok"){
          console.log(data['mensaje']);
        }else{
          console.log(data['mensaje']);
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  getEncargo(){
    this.funcionesService.getEncargoByIdGenerali(this.idEncargo, this.medico.med_id_user).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        
        console.log(data);
        if(data['response'] == "ok"){
          this.encargo = data['encargo'];
          this.encargoAnterior = data['encargoAnterior'];
          this.id_formato_consulta = this.encargo.formato_consulta;
          this.camposVideoConsultaAnteriores = data['encargoVideoConsultaAnteriores'];
          this.encargosAnterioresAsesoriaGraves = data['encargosAnterioresAsesoriaGraves'];
          //Si el encargo esta en estado enviado para el medico será pendiente de revision
          if(this.encargo.upgen_estado_encargo == "Enviado"){
            this.encargo.upgen_estado_encargo = "Pendiente de revisión";
          //Si el encargo esta en estado Pendiente de revision para el medico será Revisado
          }else if(this.encargo.upgen_estado_encargo == "Pendiente de revision"){
            this.encargo.upgen_estado_encargo = "Revisado";
          }
          this.countMensajes = data['countMensajes'];
          //mostrar botones Fab
          this.mostrarBotonesFab();
          //Traerme los encargos con la misma referencia siniestro
          this.getEncargosBySiniestro(this.encargo.upgen_referencia_siniestro);

          this.getMedicoEncargo(this.encargo.upgen_id_medico);
          //Una vez cargado el encargo relleno el campo de los informes
          this.rellenarCamposInforme();
          //traerme los archivos del encargo
          this.getArchivosExplorador();

          if(this.encargo.upgen_id_estado_encargo != 1){
            this.guardadoAutomatico();
          }
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  mostrarBotonesFab(){
    switch(this.encargo.upgen_estado_encargo){
      //estado pendiente
      case 'Pendiente de revisión':
        this.mostrarRevisado = true;
        break;
    }
  }

  getEncargosBySiniestro(refSiniestro: string){
    this.mostrarProgressSpinner = true;
    this.medicoService.getEncargosBySiniestro(refSiniestro, this.medico.med_id_user, 0).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          this.encargosBySiniestro = data['encargosBySiniestro'];
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  mensajeCheck(event: MatCheckboxChange, idMensaje: number){
    if(event.checked == true){
      this.mensajesChecked.push(idMensaje);
    }else{
      let posicion = this.mensajesChecked.indexOf(idMensaje);
      this.mensajesChecked.splice(posicion, 1);
    }
    console.log(this.mensajesChecked);
  }

  guardarDatosEncargo(opcion: string){
    console.log(this.idMedico);
    if(this.idTipo != null && this.idMedico != null){
      if(opcion != "automatico"){
        this.mostrarProgressSpinner = true;
      }

      // if(this.fechaUltimaConsulta.value == "Invalid Date") {
      //   this.fechaUltimaConsulta.setValue(null);
      // }
      // if(this.fechaPrueba.value == "Invalid Date") {
      //   this.fechaPrueba.setValue(null);
      // }
      console.log(this.fechaPrueba);
      let datos = {
        'idMensajesMarcados': this.mensajesChecked,
        'idEncargo': this.idEncargo,
        'numExpediente': this.numExpediente,
        'nombreMedico': this.nombreMedico,
        'idMedico': this.idMedico,
        'idTipo': this.idTipo,
        'numColegiado': this.numColegiado,
        'nombreLesionado': this.nombreLesionado,
        'edadLesionado': this.edadLesionado,
        'provincia': this.provincia,
        'localidad': this.localidad,
        'responsabilidad': this.responsabilidad,
        'peticion': this.peticion,
        'prioridad': this.isUrgente == true ? 1 : null,
        'fechaSiniestro': this.fechaSiniestro,
        'fechaLimite': this.encargo.upgen_fecha_limite,
        'refSiniestro': this.refSiniestro,
        'objetoInforme': this.objetoInforme,
        'profesion': this.profesion,
        'situacionLaboral': this.situacionLaboral,
        'bajaLaboral': this.bajaLaboral,
        'fechaBajaLaboral': this.fechaBajaLaboral,
        'fechaAltaLaboral': this.fechaAltaLaboral,
        'contingencia': this.contingencia,
        'antecedentes': this.antecedentes,
        'centroUrgencias': this.centroUrgencias,
        'cuandoAcude': this.cuandoAcude,
        'diagnosticoUrgencias': this.diagnosticoUrgencias,
        'hospitalizacionUrgencias': this.hospitalizacionUrgencias,
        'centroRehab': this.centroRehab,
        'medicoSeguimiento': this.medicoSeguimiento,
        'inicioTratamiento': this.inicioTratamiento,
        'altaMedica': this.altaMedica,
        'numConsultas': this.numConsultas,
        'numSesiones': this.numSesiones,
        'pruebasRealizadas': this.pruebasRealizadas,
        'evolucion': this.evolucion,
        'fuenteDocumental': this.fuenteDocumental,
        'referenciaInforme': this.referenciaInforme,
        'firmadoPor': this.firmadoPor,
        'tipoImpacto': this.tipoImpacto,
        'deltaV': this.deltaV,
        'aceleracion': this.aceleracion,
        'fechaVideoConsulta': formatDate(this.dpFechaVideoConsulta.value, 'dd/MM/yyyy', "en-GB") == "01/01/1970" ? null : formatDate(this.dpFechaVideoConsulta.value, 'dd/MM/yyyy', "en-GB"),
        'mediosUsados': this.mediosUsados,
        'anamnesis': this.anamnesis,
        'exploracionFisica': this.exploracionFisica,
        'consideracion': this.consideracion,
        'conclusiones': this.conclusiones,
        'pruebasRealizadasVideoConsulta': this.pruebasRealizadasVideoConsulta,
        'tratamientoRehabilitador': this.tratamientoRehabilitador,
        'intervencionesQuirurgicas': this.intervencionesQuirurgicas,
        'diasBasicos': this.diasBasicos,
        'diasModerados': this.diasModerados,
        'diasGraves': this.diasGraves,
        'diasMuyGraves': this.diasMuyGraves,
        'diasTotales': this.diasTotales,
        'diasBasicosMax': this.diasBasicosMax,
        'diasModeradosMax': this.diasModeradosMax,
        'diasGravesMax': this.diasGravesMax,
        'diasMuyGravesMax': this.diasMuyGravesMax,
        'diasTotalesMax': this.diasTotalesMax,
        'perjuicioMoral': this.perjuicioMoral,
        'grado': this.grado,
        'porcentajeGrado': this.porcentajeGrado,
        'perjuicioPersonal': this.perjuicioPersonal,
        'grupoQuirurgico': this.grupoQuirurgico,
        'otrosCorrectores': this.otrosCorrectores,
        'observaciones': this.observaciones,
        'observacionesIMD': this.observacionesIMD,
        'observacionesAseguradora': this.observacionesAseguradora,
        'proximaRevision': this.proximaRevision,
        'secuelasJson': JSON.stringify(this.secuelaForm.value),
        'descripcionPerjuicioEstetico': this.descripcionPerjuicioEstetico,
        'puntosPerjuicioEstetico': this.puntosPerjuicioEstetico,
        'puntosPerjuicioEsteticoMax': this.puntosPerjuicioEsteticoMax,
        'evolutivoConsultasValoracion': this.evolutivoConsultasValoracion,
        'fechaProximaConsulta': this.fechaProximaConsulta,
        'fechaConsultaVideoConsulta': this.fechaConsultaVideoConsulta,
        'horaConsultaVideoConsulta': this.horaConsultaVideoConsulta,
        'mecanismoProduccion': this.mecanismoProduccion,
        'rutaSala': this.rutaSala,
        'numInforme': this.numInforme,
        'fechaAsesoria': this.fechaAsesoria,
        'asesorMedico': this.asesorMedico,
        'observacionesAsesoria': this.observacionesAsesoria,
        'formato_consulta' : this.id_formato_consulta,
        'valoracionFinal'              : this.valoracionFinal,
        'valoracionProv'               : this.valoracionProv,
        'imd'                          : this.imd,
        'jsonIntervenciones'           : JSON.stringify(this.intervencionForm.value),
        'reservaTecnica'               : this.reservaTecnica,
        'situacionIncapacidad' : this.situacionIncapacidad,
        'tiempoRecuperacion' : this.tiempoRecuperacion,
        'idNombreTramitador' : this.idNombreTramitador,
        'estadoInicial'       : this.estadoInicial,
        'fechaUltimaConsulta' : this.fechaUltimaConsulta.value == null ? null : formatDate(this.fechaUltimaConsulta.value, 'dd/MM/yyyy', "en-GB"),
        'estadoFinal'         : this.estadoFinal,
        'bioFuncional'        : this.bioFuncional,
        'coherenciaSeguimiento' : this.coherenciaSeguimiento,
        'fechaPrueba'           : this.fechaPrueba.value == null ? null : formatDate(this.fechaPrueba.value, 'dd/MM/yyyy', "en-GB"),
        'consistenciaPrueba'    : this.consistenciaPrueba,
        'movilidad'             : this.movilidad,
        'secuelasEsteticasJson': JSON.stringify(this.secuelasEsteticasForm.value),

      };


      if(opcion == "revisado"){
        this.funcionesService.updateDatosEncargoGenerali(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              this.pasarEncargoRevisado();
            }else{
              this.notificacion.notificationFail(data['mensaje']);
  
            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            this.notificacion.notificationFail("Error en el servidor");
          }
        );
      }else if(opcion == "informe"){
        //let checkCampos = this.checkCamposObligatorios();
        //if(checkCampos.check){
          this.funcionesService.updateDatosEncargoGenerali(datos).subscribe(
            data =>{
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.generarInforme();
              }else{
                //console.log(data);
                this.notificacion.notificationFail(data['mensaje']);
    
              }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );
        /*}
        else{
          this.mostrarProgressSpinner = false;
          this.notificacion.notificationFail(checkCampos.camposFaltantes);
        }*/
      }else if(opcion == "automatico"){
        this.funcionesService.updateDatosEncargoGenerali(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              console.log("Guardado automático realizado");
            }else{
              console.log("Error guardado automático");
            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            console.log("Error servidor guardado automático");
          }
        );
      }else if(opcion == "descargarDocMedico") {
        this.funcionesService.downloadZipFolder(this.archivosCarpetaDocMedico, this.rutaCarpetaDocMedico).subscribe({
          next: (data: any) => {
            //console.log(data);
            this.funcionesService.verArchivo(data['ruta']).subscribe({
              next: (data: any) => {
                this.descargarchivos(data, "DocMedicoZip", "application/zip");
     
              },
              error: (err: any) => {},
              complete: () => {
                this.mostrarProgressSpinner = false;
                this.funcionesService.deleteZipFolder(data['ruta']).subscribe({
                  next: (data: any) => {
  
                  },
                  error: (err: any) => {},
                  complete: () => {}
                });
              }
            });
  
          },
          error: (err: any) => {
                  
          },
          complete: () => {
            
          }
        })
      }else{
        this.funcionesService.updateDatosEncargoGenerali(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              this.notificacion.notificationOk(data['mensaje']);
              //Borrar secuelas para no sumarlas al array ya creado
              this.secuelas().clear();
              this.secuelasEsteticas().clear();
              this.intervenciones().clear();
              this.secuelasHorquilla().clear();
              this.getEncargo();
              this.getMensajesEncargo();
            }else{
              this.notificacion.notificationFail(data['mensaje']);
              this.getEncargo();
              this.getMensajesEncargo();
            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            this.notificacion.notificationFail("Error en el servidor");
            this.getEncargo();
            this.getMensajesEncargo();
          }
        );
      }
    }else{
      this.mostrarProgressSpinner = false;
      this.notificacion.notificationFail("El campo 'Medicos' o 'Tipo' no estan rellenos");
    }
     
    
  }

  pasarEncargoRevisado(){
    //Mostrar dialogo de confirmacion
    const dialogRevisado = this.dialog.open(RevisadoDialogComponent,{
      data: {comentario: this.comentario}
    });
    dialogRevisado.afterClosed().subscribe(
      result =>{
        //Si pulsa el boton "Si" del dialog
        if(result){
          //Enviar mensaje al encargo
          let datosMensaje = {
            'idEncargo': this.idEncargo,
            'idUsuario': this.medico.med_id_user,
            'textoMensaje': result
          };

          this.funcionesService.addMensajeEncargoGenerali(datosMensaje).subscribe(
            data => {
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.mostrarProgressSpinner = true;
                //ID de estado Revisado para el tramitador será pendiente de revision
                const idEstadoRevisado = 3;

                let datos = {
                  'idEncargo': this.idEncargo,
                  'idEstado': idEstadoRevisado
                };

                this.funcionesService.cambiarEstadoEncargoGenerali(datos).subscribe(
                  data =>{
                    this.mostrarProgressSpinner = false;
                    if(data['response'] == "ok"){
                      this.mostrarProgressSpinner = true;
                      //enviar email de cambio de estado
                      this.enviarEmailCambioEstado();
                      
                      this.notificacion.notificationOk(data['mensaje'] + " Se te redireccionará en 3 segundos");
                      setTimeout(() => {
                        this.router.navigate(['encargo-vdc-asistencial-medico']);
                      }, 3000);
                    

                    }else{
                      this.notificacion.notificationFail(data['mensaje']);
                    }
                  }, error =>{
                    this.mostrarProgressSpinner = false;
                    this.notificacion.notificationFail("Error en el servidor");
                  }
                );
              }else{
                console.log(data['mensaje']);
                this.notificacion.notificationFail(data['mensaje']);
              }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );

        }
      }
    );
  }

  enviarEmailCambioEstado(){
    let datosEmail ={
      'idEncargo': this.idEncargo
    };

    this.medicoService.enviarEmailCambioEstadoEncargo(datosEmail).subscribe(
      data =>{
        if(data['response'] == "ok"){
          console.log(data['mensaje']);
        }else{
          console.log(data['mensaje']);
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  getMensajesEncargo(){
    this.mostrarProgressSpinner = true;
    this.funcionesService.getMensajesEncargoGenerali(this.idEncargo, this.medico.med_id_user).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        
        //console.log(data);
        if(data['response'] == "ok"){
          this.mensajes = data['mensajes'];
        }else{

        }
      }
    );
  }

  abrirEncargoSiniestro(idEncargo: number){
    this.router.navigate(['encargo', {idEncargo: idEncargo}]);
  }

  mensajesLeidos(){
    this.funcionesService.mensajesLeidosGenerali(this.mensajes, this.medico.med_id_user).subscribe(
      data =>{
        if(data['response'] == "ok"){
          this.countMensajes = 0;
          this.notificacion.notificationOk("Los mensajes se han marcados como leídos");
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  onChangeOption(type): void {

    let myActive = this.el.nativeElement.querySelector(".active");
    let divMyActive = this.el.nativeElement.querySelector("." + myActive.id);
    let myTag = this.el.nativeElement.querySelector("[id='" + type + "']");
    let divMyTag = this.el.nativeElement.querySelector("." + type);

    divMyActive.classList.add('noshow');
    myActive.classList.remove('active');

    if (!myTag.classList.contains('active')) {
      myTag.classList.add('active');
      divMyTag.classList.remove('noshow');
    }

  }

  colorEncargo(fechaLimite: string){
    var color = '';
    var fechaNow = new Date();
    var fechaLimiteDosAntes = new Date(fechaLimite);
    var fechaLimiteFormat = new Date(fechaLimite);

    if(fechaLimite != null){

      fechaLimiteDosAntes.setDate(fechaLimiteFormat.getDate() - 2);

      if(fechaNow<fechaLimiteDosAntes){
        color = 'color_verde';
      }else if(fechaNow.getFullYear()==fechaLimiteDosAntes.getFullYear() && fechaNow.getMonth()==fechaLimiteDosAntes.getMonth() && fechaNow.getDate()==fechaLimiteDosAntes.getDate()
      || (fechaNow>fechaLimiteDosAntes && fechaNow<fechaLimiteFormat)){
        color = 'color_amarillo';
      }else if(fechaNow.getFullYear()==fechaLimiteFormat.getFullYear() && fechaNow.getMonth()==fechaLimiteFormat.getMonth() && fechaNow.getDate()==fechaLimiteFormat.getDate() 
      || fechaNow>fechaLimiteFormat){
        color = 'color_rojo';
      }else{
        color = '';
      }
  
      return color;
    }
  }

  onChangeLimpiarMoral(){
    this.grado = null;
    this.porcentajeGrado = null;
  }

  onChangeLimpiarMoralReserva(){
    this.grado = null;
    this.porcentajeGrado = null;
  }

  onChangeLimpiarPersonal(){
    this.grupoQuirurgico = null;
  }

  onChangeLimpiarPersonalReserva(){
    this.grupoQuirurgico = null;
  }

  //File Explorer
  currentRoot: Archivo;
  currentPath: string;
  canNavigateUp = false;

  navigateToFolder(element: Archivo) {
    this.currentRoot = element;
    this.updateFileElementQuery();
    this.currentPath = this.pushToPath(this.currentPath, element.archDescripcion);
    this.canNavigateUp = true;
  }

  navigateUp() {
    if (this.currentRoot && this.currentRoot.archParent === 'root') {
      this.currentRoot = null;
      this.canNavigateUp = false;
      this.updateFileElementQuery();
    } else {
      this.currentRoot = this.fileService.get(this.currentRoot.archParent);
      this.updateFileElementQuery();
    }
    this.currentPath = this.popFromPath(this.currentPath);
  }

  //Actualizar lista de archivos
  updateFileElementQuery() {
    //file explorer lista archivos
    this.archivos = this.fileService.queryInFolder(this.currentRoot ? this.currentRoot.archPath : 'root');
  }

  pushToPath(path: string, folderName: string) {
    let p = path ? path : '';
    p += `${folderName}/`;
    return p;
  }

  popFromPath(path: string) {
    let p = path ? path : '';
    let split = p.split('/');
    split.splice(split.length - 2, 1);
    p = split.join('/');
    return p;
  }

  // File manager
  openModalFile(element: Archivo) {
    if (element != null)
      this.funcionesService.verArchivo(element.archPath).subscribe(
        data => {
          if (element.archType == 'pdf') {
            this.imageSelect = data;
            this.tipoArchivo = 'pdf';
            this.el.nativeElement.querySelector('#modalFile').classList.toggle('open');

          }
          else if (element.archType == 'jpg' || element.archType == 'jpeg') {
            this.imageSelect = data;
            this.tipoArchivo = 'imagen';
            this.el.nativeElement.querySelector('#modalFile').classList.toggle('open');
          }
          else {
            console.log(element.archContentType);
            this.descargarchivos(data, element.archDescripcion, element.archContentType);
          }



        });
  }

  openModal(name, obj: any): void {
    this.el.nativeElement.querySelector('#' + name).classList.toggle('open');
  }

  descargarchivos(archivo: string, descripcion: string, type: string) {
    let byteImage = atob(archivo);

    let byteNumbers = new Array(byteImage.length);

    for (var i = 0; i < byteImage.length; i++) {
      byteNumbers[i] = byteImage.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    let blob = new Blob([byteArray], { "type": type });

    if (navigator.msSaveBlob) {
      let filename = descripcion;
      navigator.msSaveBlob(blob, filename);
    } else {
      let link = document.createElement("a");

      link.href = URL.createObjectURL(blob);

      link.setAttribute('visibility', 'hidden');
      link.download = descripcion;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  // Upload file at server VD
  uploadFile(): void {
    const formModel = this.formUploadFile.value;
    let datos = {
      'numExpediente': this.encargo.upgen_expediente,
      'idTipoEncargo': this.encargo.upgen_id_tipo_encargo,
      'idEstado': this.encargo.upgen_id_estado_encargo,
      'idCia': this.encargo.upgen_cia,
      'archDescripcion': formModel.archFile.filename,
      'archType': formModel.archFile.filetype,
      'archFile': formModel.archFile.value
    };

    if (this.formUploadFile != null){
      this.medicoService.uploadFile(datos).subscribe(
        data => {
          // Close modal
          this.el.nativeElement.querySelector('#modalUploadFile').classList.toggle('open');
          //Enviar email al tramitador para avisar que se a añadido nueva documentacion
          this.enviarEmailAddDocEncargo(datos['archDescripcion']);
          //traerme los archivos del encargo para que aparezca el archivo nuevo subido
          this.getArchivosExplorador();
          // Notification success
          this.notificacion.notificationOk("El archivo ha sido subido satisfactoriamente");
        },
        error => {
          // Notification error
          this.notificacion.notificationFail("Error en el servidor");
        },
      );
    }
  }

  // Update caracteristics of input type file.
  onFileChange(event, input, formSave) {
    let reader = new FileReader();

    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.formUploadFile.get(input).setValue({
          filename: file.name,
          filetype: file.type,
          value: reader.result.toString().split(',')[1]
        });
      };
    }
  }

  enviarEmailAddDocEncargo(nombreArchivo: string){
    let datosEmail ={
      'idEncargo': this.idEncargo,
      'nombreArchivo': nombreArchivo
    };

    this.medicoService.enviarEmailAddDocEncargo(datosEmail).subscribe(
      data =>{
        if(data['response'] == "ok"){
          console.log(data['mensaje']);
        }else{
          console.log(data['mensaje']);
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  checkCamposObligatorios() : {check: boolean, camposFaltantes: string}{

    let camposFaltantes : string = "Campos obligatorios faltantes en:\n";
    let campos : string[] = [];
    let check : boolean = true;

    switch (this.encargo.upgen_id_tipo_encargo) {
      //REHUSE CAS
      case 1:      
        if(
          (this.consideracion == null || this.consideracion === "") ||
          (this.conclusiones == null || this.conclusiones === "")
          ){
          campos.push("Consideraciones Médicas");
          check = false;
        }
        break;

      //REHUSE CAS + VIDEO
      case 4:      
        if(
          //Fuente documental
          (this.fuenteDocumental == null || this.fuenteDocumental === "")
          ){
          campos.push("Fuente documental");
          check = false;
        }
        if(
          //Mecanismo prodducción
          (this.mecanismoProduccion == null || this.mecanismoProduccion === "")
        ){
          campos.push("Mecanismo de producción");
          check = false;
        }
        if(
          //Resumen evolutivo
          (this.profesion == null || this.profesion === "") ||
          (this.situacionLaboral == null || this.situacionLaboral === "") ||
          (this.bajaLaboral == null || this.bajaLaboral === "") ||
          (this.contingencia == null || this.contingencia === "") ||
          (this.antecedentes == null || this.antecedentes === "") ||
          (this.centroUrgencias == null || this.centroUrgencias === "") ||
          (this.cuandoAcude == null || this.cuandoAcude === "") ||
          (this.diagnosticoUrgencias == null || this.diagnosticoUrgencias === "") ||
          (this.centroRehab == null || this.centroRehab === "") ||
          (this.inicioTratamiento == null || this.inicioTratamiento === "") ||
          (this.altaMedica == null || this.altaMedica === "") ||
          (this.numSesiones == null || this.numSesiones === "") ||
          (this.pruebasRealizadas == null || this.pruebasRealizadas === "") ||
          (this.evolucion == null || this.evolucion === "")
        ){
          campos.push("Resumen Evolutivo");
          check = false;
        }
        if(
          //Datos relativos al análisis del impacto
          (this.referenciaInforme == null || this.referenciaInforme === "") ||
          (this.firmadoPor == null || this.firmadoPor === "") ||
          (this.tipoImpacto == null || this.tipoImpacto === "") ||
          (this.deltaV == null || this.deltaV === "") ||
          (this.aceleracion == null || this.aceleracion === "")
        ){
          campos.push("Datos relativos al análisis del impacto");
          check = false;
        }
        if(
          //Video-consulta médica
          (this.dpFechaVideoConsulta == null || this.dpFechaVideoConsulta.value === "") ||
          (this.mediosUsados == null || this.mediosUsados === "") ||
          (this.anamnesis == null || this.anamnesis === "") ||
          (this.exploracionFisica == null || this.exploracionFisica === "")
        ){
          campos.push("Video-consulta Médica");
          check = false;
        }
        if(
          //Consideraciones médicas
          (this.consideracion == null || this.consideracion === "") ||
          (this.conclusiones == null || this.conclusiones === "")
        ){
          campos.push("Consideraciones Médicas");
          check = false;
        }
        break;
    
      //VALORACIÓN VIDEO-CONSULTA
      case 5:
      //VALORACIÓN VIDEO-CONSULTA-MUTUAS
      case 8:
        if(
          //Fuente documental
          (this.fuenteDocumental == null || this.fuenteDocumental === "")
          ){
          campos.push("Fuente documental");
          check = false;
        }
        if(
          //Mecanismo prodducción
          (this.mecanismoProduccion == null || this.mecanismoProduccion === "")
        ){
          campos.push("Mecanismo de producción");
          check = false;
        }
        if(
          //Proceso clínico documentado
          (this.profesion == null || this.profesion === "") ||
          (this.situacionLaboral == null || this.situacionLaboral === "") ||
          (this.bajaLaboral == null || this.bajaLaboral === "") ||
          (this.contingencia == null || this.contingencia === "") ||
          (this.antecedentes == null || this.antecedentes === "") ||
          (this.centroUrgencias == null || this.centroUrgencias === "") ||
          (this.cuandoAcude == null || this.cuandoAcude === "") ||
          (this.diagnosticoUrgencias == null || this.diagnosticoUrgencias === "") ||
          (this.centroRehab == null || this.centroRehab === "") ||
          (this.inicioTratamiento == null || this.inicioTratamiento === "") ||
          (this.altaMedica == null || this.altaMedica === "") ||
          (this.numSesiones == null || this.numSesiones === "") ||
          (this.pruebasRealizadas == null || this.pruebasRealizadas === "") ||
          (this.intervencionesQuirurgicas == null || this.intervencionesQuirurgicas === "") ||
          (this.evolucion == null || this.evolucion === "")
        ){
          campos.push("Proceso Clínico Documentado");
          check = false;
        }
        if(
          //Video-consulta médica
          (this.dpFechaVideoConsulta == null || this.dpFechaVideoConsulta.value === "") ||
          (this.mediosUsados == null || this.mediosUsados === "") ||
          (this.anamnesis == null || this.anamnesis === "") ||
          (this.exploracionFisica == null || this.exploracionFisica === "")
        ){
          campos.push("Video-consulta Médica");
          check = false;
        }
        if(
          //Valoración - Lesiones temporales
          (this.diasBasicos == null || this.diasBasicos === "") ||
          (this.diasModerados == null || this.diasModerados === "") ||
          (this.diasGraves == null || this.diasGraves === "") ||
          (this.diasMuyGraves == null || this.diasMuyGraves === "") ||
          (this.diasTotales == null || this.diasTotales === "")
        ){
          campos.push("Valoración - Lesiones temporales");
          check = false;
        }
        //TODO Correctores (obligatorio)
        break;  

      //VALORACIÓN BASE DOCUMENTAL
      case 6:
      //ALTA+IAE BASE DOCUMENTAL
      case 7:
        //ALTA+IAE MUTUA LABORAL
      case 10:
        console.log("Total secuelas min: " + this.totalPuntosSecuelasMin());
        console.log("Total secuelas max: " + this.totalPuntosSecuelasMax());
        //Si no hay secuelas
        if(this.secuelasHorquilla().length > 0){
          console.log(this.totalPuntosSecuelasMin());
          if(
            //Lesiones temporales mínimas
            (this.totalPuntosSecuelasMin() == 0) 
          ){
            campos.push("Lesiones temporales mínimas");
            check = false;
          }
          if(
            //Lesiones temporales máximas
            (this.totalPuntosSecuelasMax() == 0) 
          ){
            campos.push("Lesiones temporales máximas");
            check = false;
          }
        }
        
        /*this.secuelaForm.value.secuelas.forEach(secuela => {
          if(
            (secuela.puntosMax == null || secuela.puntosMax === 0) ||
            (secuela.puntosMin == null || secuela.puntosMin === 0)
          ){
            campos.push("Puntos secuela");
            check = false;
          }
        });*/
        if(
          //Observaciones
          (this.observaciones == null || this.observaciones === "")       
        ){
          campos.push("Observaciones");
          check = false;
        }
        if(
          //Observaciones de caracter interno
          (this.observacionesAseguradora == null || this.observacionesAseguradora === "") 
        ){
          campos.push("Observaciones de caracter interno");
          check = false;
        }

        
        break;
      //SEGUIMIENTO DIVERSOS
      case 9:
        if(
          //Fuente documental
          (this.fuenteDocumental == null || this.fuenteDocumental === "")
          ){
          campos.push("Fuente documental");
          check = false;
        }
        if(
          //Resumen Evolutivo
          (this.profesion == null || this.profesion === "") ||
          (this.situacionLaboral == null || this.situacionLaboral === "") ||
          (this.bajaLaboral == null || this.bajaLaboral === "") ||
          (this.altaMedica == null || this.altaMedica === "") ||
          (this.antecedentes == null || this.antecedentes === "") ||
          (this.pruebasRealizadas == null || this.pruebasRealizadas === "") ||
          (this.evolucion == null || this.evolucion === "")
          ){
          campos.push("Resumen Evolutivo");
          check = false;
        }
        if(
          //Valoración del daño corporal
          (this.observaciones == null || this.observaciones === "") ||
          (this.conclusiones == null || this.conclusiones === "") ||
          (this.fechaProximaConsulta == null || this.fechaProximaConsulta === "")
          ){
          campos.push("Valoración del daño corporal");
          check = false;
        }
        break;
      
      //ASESORÍA GRAVES
      case 11:
        
        break;

      default:
        break;
    }

    campos.forEach((campo, index, array) => {

      if (index === array.length - 1){ 
        camposFaltantes += campo;
      }
      else{
        camposFaltantes += campo + "\n";
      }
    });
    
    return {check, camposFaltantes};
  }

  //Pasar fecha en string con foramto dd/MM/yyyy a Date
  dateStringtoDate(fechaString: string){
    let [dia, mes, anio] = fechaString.split('/');

    return new Date(Number(anio), Number(mes) - 1, Number(dia));
  }


  keyupClaseAnterior(value, id, valueAnterior){
    if(value != valueAnterior){
      document.getElementById(id).classList.remove('anterior');
    }else{
      document.getElementById(id).classList.add('anterior');
    }
  }

}
