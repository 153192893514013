<app-header-encargo-medico></app-header-encargo-medico>

<div class="progress_spinner" *ngIf="mostrarProgressSpinner">
    <mat-spinner></mat-spinner>
</div>

<div class="container-fluid">
    <!-- Encargo elegido -->
    <div class="row fila_encargo">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-2">
                    <div>
                        <i class="bi bi-circle-fill icon_size" [ngClass]="colorEncargo(encargo.enup_fecha_limite)"></i>
                        <h3 class="text_inline_icon_encargo">{{encargo.id_plataforma}}</h3>
                    </div>
                    <div>
                        <i class="bi bi-chat-left-text-fill icon_size"><h3 class="text_inline_icon_mensaje">{{countMensajes}} Mensaje</h3></i>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="row">
                        <div *ngIf="encargo.enup_fecha_consulta_video === null" class="col-md-8">
                            <h5 class="m-0 pt-3 subtitulo_h5">Fecha encargo</h5>
                            <h4 class="subtitulo_h4">{{encargo.enup_fecha_tarea}}</h4>
                        </div>
                        <div *ngIf="encargo.enup_fecha_consulta_video != null" class="col-md-8">
                            <h5 class="m-0 pt-3 subtitulo_h5">Fecha Consulta/Videoconsulta</h5>
                            <h4 class="subtitulo_h4">{{encargo.enup_fecha_consulta_video}}</h4>
                        </div>
                        <div class="col-md-2">
                            <mat-divider [vertical] ="true" class="mt-3" style="height:50px"></mat-divider>
                        </div>
                        <div class="col-md-2">
                           
                        </div>
                    </div>
                </div>
    
                <div class="col-md-2">
                    <h5 class="m-0 pt-3 subtitulo_h5">Estado</h5>
                    <h4 class="subtitulo_h4">{{encargo.enup_estado_encargo}}</h4>
                </div>
    
                <div class="col-md-2">
                    <div class="row">
                        <div class="col-md-8">
                            <h5 class="m-0 pt-3 subtitulo_h5">Tipo de encargo</h5>
                            <h4 class="subtitulo_h4">{{encargo.nombre_tipo_encargo}}</h4>
                        </div>
                        <div class="col-md-2">
                            <mat-divider [vertical] ="true" class="mt-3" style="height:50px"></mat-divider>
                        </div>
                        <div class="col-md-2">
                           
                        </div>
                    </div>
                </div>
    
                <div class="col-md-2">
                    <div class="row">
                        <div class="col-md-8">
                            <h5 class="m-0 pt-3 subtitulo_h5">Fecha Limite</h5>
                            <h4 class="subtitulo_h4">{{encargo.enup_fecha_limite | date: 'dd/MM/yyyy HH:mm:ss'}}</h4>
                        </div>
                        <div class="col-md-2">
                            <mat-divider [vertical] ="true" class="mt-3" style="height:50px"></mat-divider>
                        </div>
                        <div class="col-md-2">
                           
                        </div>
                    </div>
                </div>
    
                <div class="col-md-2">
                    <h5 class="m-0 pt-3 subtitulo_h5">Lesionado</h5>
                    <h4 class="subtitulo_h4">{{encargo.enup_lesionado}}</h4>
                </div>
            </div>
    
        </div>
    </div>

    <!-- Informacion -->
    <div class="cointainer_tab">
        <!-- Tabs -->
        <div class="tab">
            <button class="tablinks active" (click)="onChangeOption('datos')" id="datos">Datos Generales</button>
            <button class="tablinks" (click)="onChangeOption('informe')" id="informe">Informe</button>
            <button class="tablinks" (click)="onChangeOption('reservaTecnica')" id="reservaTecnica"  *ngIf="reservaTecnica">Reserva Técnica</button>
            <button class="tablinks" (click)="onChangeOption('documentacion')" id="documentacion">Documentación</button>
            <button class="tablinks" (click)="onChangeOption('mensajes')" id="mensajes">Mensajes<span *ngIf="countMensajes!=0" matBadge="{{countMensajes}}" matBadgeColor="warn" matBadgeOverlap="false"></span></button>
            <button class="tablinks" (click)="onChangeOption('historico')" id="historico">Histórico del siniestro</button>
        </div>

        <!-- Datos Generales -->
        <div class="tabcontent datos" id="datos">
            <div class="container-fluid" style="width: 92%;">
                <div class="row m-0 ms-3 fila_datos">
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Nombre del lesionado</h5>
                        <h4 class="subtitulo_h4">{{encargo.enup_lesionado}}</h4>
                    </div>
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Condición del lesionado</h5>
                        <h4 class="subtitulo_h4">{{encargo.enup_condicion_lesionado}}</h4>
                    </div>
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Provincia</h5>
                        <h4 class="subtitulo_h4">{{encargo.enup_provincias}}</h4>
                    </div>
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Localidad</h5>
                        <h4 class="subtitulo_h4">{{encargo.enup_localidad}}</h4>
                    </div>
                </div>

                <div class="row m-0 ms-3 fila_datos">
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Referencias del Siniestro</h5>
                        <h4 class="subtitulo_h4">{{encargo.enup_referencia_siniestro}}</h4>
                    </div>
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Teléfono</h5>
                        <h4 class="subtitulo_h4">{{encargo.enup_telefono}}</h4>
                    </div>
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Compañía</h5>
                        <h4 class="subtitulo_h4">{{encargo.ciaNombre}}</h4>
                    </div>
                </div>

                <div class="row m-0 ms-3 fila_datos" *ngIf="encargo.nombre_tipo_encargo != 'REHUSE CAS' && encargo.nombre_tipo_encargo != 'REHUSE CAS + VIDEO' && encargo.nombre_tipo_encargo != 'VALORACIÓN BASE DOCUMENTAL' && encargo.nombre_tipo_encargo != 'ALTA+IAE BASE DOCUMENTAL'">
                    <div class="col-md-9">
                        <h5 class="m-0 pt-3 subtitulo_h5">Forma de producción</h5>
                        <h4 class="subtitulo_h4">{{encargo.enup_forma_produccion}}</h4>
                    </div>
                </div>

                <div class="row m-0 ms-3 mt-3 fila_datos">
                    <div class="col-md-6">
                        <mat-form-field style="width: 100%;" appearance="outline">
                            <mat-label>Responsabilidad</mat-label>
                            <input matInput placeholder="Responsabilidad" [(ngModel)]="responsabilidad">
                        </mat-form-field>
                    </div>
                    <div *ngIf="encargo.enup_url_videoconferencia" class="col-md-6" style="text-align: center;">
                        <a mat-stroked-button class="btn_videoconsulta" [href]="rutaSala" target="_blank">Abrir sala videoconsulta<mat-icon class="icon_btn_videoconsulta">videocam</mat-icon>
                        </a>
                    </div>
                </div>

                <div class="row m-0 ms-3 fila_datos">
                    <div class="col-md-12">
                        <mat-form-field style="width: 100%;" appearance="outline">
                            <mat-label>Peticion</mat-label>
                            <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="4" cdkAutosizeMaxRows="20" matInput placeholder="Peticion" [(ngModel)]="peticion"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <!-- Informe -->
        <div class="tabcontent informe noshow" id="informe" >
            <div class="container-fluid" style="width: 95%;">
                <div class="row" style="margin-top: 20px;">
                    <div class="col-md-12">
                        <h1>Informe</h1>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="width: 92%;">

                <!-- Campos adicionales de informe REHUSE CAS-->
                 <div *ngIf="encargo.nombre_tipo_encargo == 'REHUSE CAS'">
                    <div class="row m-0">
                        <h2>Objeto del informe</h2> 
 
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Objeto del informe</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="objetoInforme" placeholder="Objeto del informe"></textarea>
                            </mat-form-field>
                        </div>
                        
                    </div>
                </div>
 
                <!-- Campos adicionales de informe REHUSE CAS+VIDEO || VALORACIÓN VIDEO-CONSULTA || VALORACIÓN BASE DOCUMENTAL-->
                <div *ngIf="encargo.nombre_tipo_encargo == 'REHUSE CAS + VIDEO' || encargo.nombre_tipo_encargo == 'VALORACIÓN VIDEO-CONSULTA' || encargo.nombre_tipo_encargo == 'VALORACIÓN BASE DOCUMENTAL' || encargo.nombre_tipo_encargo == 'ALTA+IAE BASE DOCUMENTAL' || encargo.nombre_tipo_encargo == 'ALTA+IAE MUTUA LABORAL' || encargo.nombre_tipo_encargo == 'VALORACIÓN VIDEO-CONSULTA-MUTUAS' || encargo.nombre_tipo_encargo == 'SEGUIMIENTO DIVERSOS' || encargo.nombre_tipo_encargo == 'ESTUDIO DOCUMENTAL' || encargo.nombre_tipo_encargo == 'REHUSE CAS BIO' || encargo.nombre_tipo_encargo == 'SEGUIMIENTO NO AUTOS' || encargo.nombre_tipo_encargo == 'SEGUIMIENTO AUTOS' || encargo.nombre_tipo_encargo == 'INFORME COHERENCIA'">
                    <div class="row m-0">
                        <h2>Fuente documental</h2>

                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Fuente documental</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="fuenteDocumental" placeholder="Fuente documental"></textarea>
                            </mat-form-field>
                        </div>
                        
                    </div>
                    
                </div>
            
                <div class="row m-0">
                    <h2>Datos Generales</h2>
                    <div class="col-md-4">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Número de expediente</mat-label>
                            <input matInput [(ngModel)]="numExpediente" placeholder="Número de expediente">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Nombre del médico</mat-label>
                            <input matInput [(ngModel)]="nombreMedico" placeholder="Nombre del médico">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Nº de colegiado</mat-label>
                            <input matInput [(ngModel)]="numColegiado" placeholder="Nº de colegiado">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row m-0">
                    <div class="col-md-4">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Nombre lesionado</mat-label>
                            <input matInput [(ngModel)]="nombreLesionado" placeholder="Nombre lesionado">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Edad lesionado</mat-label>
                            <input matInput [(ngModel)]="edadLesionado" placeholder="Edad lesionado">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Provincia lesionado</mat-label>
                            <input matInput [(ngModel)]="provincia" placeholder="Provincia lesionado">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row m-0">
                    <div class="col-md-4">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Localidad lesionado</mat-label>
                            <input matInput [(ngModel)]="localidad" placeholder="Localidad lesionado">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Fecha siniestro</mat-label>
                            <input matInput [(ngModel)]="fechaSiniestro" placeholder="Fecha siniestro">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Referencia siniestro</mat-label>
                            <input matInput [(ngModel)]="refSiniestro" placeholder="Referencia siniestro">
                        </mat-form-field>
                    </div>
                </div>

                <!-- Mecanismo de produccion -->
                <div *ngIf="encargo.nombre_tipo_encargo != 'SEGUIMIENTO DIVERSOS'" class="row m-0">
                    <h2>Mecanismo de producción</h2>
                    <div class="col-md-12">
                        <mat-form-field style="width: 92%;" appearance="outline">
                            <mat-label>Mecanismo de producción</mat-label>
                            <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="mecanismoProduccion" placeholder="Mecanismo de producción"></textarea>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Campos adicionales de informe Seguimiento Diversos-->
                <!-- <div *ngIf="encargo.nombre_tipo_encargo == 'SEGUIMIENTO DIVERSOS'" >
                    <div class="row m-0">
                        <h2>Resumen Evolutivo</h2>
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Profesión</mat-label>
                                <input matInput [(ngModel)]="profesion" placeholder="Profesión">
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Situación laboral</mat-label>
                                <input matInput [(ngModel)]="situacionLaboral" placeholder="Situación laboral">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Baja laboral</mat-label>
                                <input matInput [(ngModel)]="bajaLaboral" placeholder="Baja laboral">
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Alta Médica</mat-label>
                                <input matInput [(ngModel)]="altaMedica" placeholder="Alta Médica">
                            </mat-form-field>
                        </div>
                        
                        
                    </div>

                    <div class="row m-0">
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Antecedentes Personales</mat-label>
                                <textarea matInput [(ngModel)]="antecedentes" placeholder="Antecedentes Personales"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Pruebas realizadas</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="pruebasRealizadas" placeholder="Pruebas realizadas"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Evolución documentada</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="evolucion" placeholder="Evolución documentada"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <h2>Consultas Evolutivas Realizadas</h2>
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Evolutivo Consultas valoración</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="evolutivoConsultasValoracion" placeholder="Evolutivo consultas valoración"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                </div> -->

                <!--SEGUIMIENTO DIVERSOS -->
                <div *ngIf="encargo.nombre_tipo_encargo == 'SEGUIMIENTO DIVERSOS'">

                    <div class="row m-0">
                         <!--<h2 *ngIf="encargo.nombre_tipo_encargo == 'VALORACIÓN VIDEO-CONSULTA' || encargo.nombre_tipo_encargo == 'VALORACIÓN VIDEO-CONSULTA-MUTUAS'">Proceso Clínico Documentado</h2> -->
                        <!--<h2 *ngIf="encargo.nombre_tipo_encargo != 'VALORACIÓN VIDEO-CONSULTA' && encargo.nombre_tipo_encargo != 'VALORACIÓN VIDEO-CONSULTA-MUTUAS'">Resumen Evolutivo</h2> -->
                        <h2>Resumen Evolutivo</h2>
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Profesión</mat-label>
                                <input matInput [(ngModel)]="profesion" placeholder="Profesión">
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Situación laboral</mat-label>
                                <input matInput [(ngModel)]="situacionLaboral" placeholder="Situación laboral">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Baja laboral</mat-label>
                                <input matInput [(ngModel)]="bajaLaboral" placeholder="Baja laboral">
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Alta Médica</mat-label>
                                <input matInput [(ngModel)]="altaMedica" placeholder="Alta Médica">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div id="bajaLaboral" class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Baja laboral</mat-label>
                                <mat-select [(ngModel)]="bajaLaboral">
                                    <mat-option value="Si">Si</mat-option>
                                    <mat-option value="No">No</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div id="fechaBajaLaboral" class="col-md-4" *ngIf="bajaLaboral == 'Si'">
                            <h5 class="m-0 pt-3 subtitulo_h5">Fecha Baja laboral</h5>
                            <input type="date" [(ngModel)]="fechaBajaLaboral">
                        </div>
                        <div id="fechaAltaLaboral" class="col-md-4" *ngIf="bajaLaboral == 'Si'">
                            <h5 class="m-0 pt-3 subtitulo_h5">Fecha Alta laboral</h5>
                            <input type="date" [(ngModel)]="fechaAltaLaboral">
                        </div>
                    </div>

                    <div class="row m-0 mt-5">
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Antecedentes Personales</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="20" matInput [(ngModel)]="antecedentes" placeholder="Antecedentes Personales"></textarea>
                            </mat-form-field>
                        </div>
                    </div>


                    <div class="row m-0 mb-3">
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Pruebas Realizadas</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="pruebasRealizadas" placeholder="Pruebas Realizadas"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0 mb-3">
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Evolución documentada**</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="evolucion" placeholder="Evolución documentada"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                </div>


                <!-- Campos adicionales de informe REHUSE CAS-->
                <div *ngIf="encargo.nombre_tipo_encargo == 'REHUSE CAS' || encargo.nombre_tipo_encargo == 'ASESORÍA GRAVE SIMPLE' || encargo.nombre_tipo_encargo == 'ASESORÍA GRAVE COMPLEJA' || encargo.nombre_tipo_encargo == 'REHUSE CAS + VIDEO' || encargo.nombre_tipo_encargo == 'VALORACIÓN VIDEO-CONSULTA' || encargo.nombre_tipo_encargo == 'VALORACIÓN BASE DOCUMENTAL' || encargo.nombre_tipo_encargo == 'ALTA+IAE BASE DOCUMENTAL' || encargo.nombre_tipo_encargo == 'ALTA+IAE MUTUA LABORAL' || encargo.nombre_tipo_encargo == 'VALORACIÓN VIDEO-CONSULTA-MUTUAS' || encargo.nombre_tipo_encargo == 'REHUSE CAS BIO' || encargo.nombre_tipo_encargo == 'SEGUIMIENTO NO AUTOS' || encargo.nombre_tipo_encargo == 'SEGUIMIENTO AUTOS' || encargo.nombre_tipo_encargo == 'ESTUDIO DOCUMENTAL' || encargo.nombre_tipo_encargo == 'INFORME COHERENCIA'">

                    <div class="row m-0">
                        <h2 *ngIf="encargo.nombre_tipo_encargo == 'VALORACIÓN VIDEO-CONSULTA' || encargo.nombre_tipo_encargo == 'VALORACIÓN VIDEO-CONSULTA-MUTUAS'">Proceso Clínico Documentado</h2>
                        <h2 *ngIf="encargo.nombre_tipo_encargo != 'VALORACIÓN VIDEO-CONSULTA' && encargo.nombre_tipo_encargo != 'VALORACIÓN VIDEO-CONSULTA-MUTUAS'">Resumen Evolutivo</h2>
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Profesión</mat-label>
                                <input matInput [(ngModel)]="profesion" placeholder="Profesión">
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Situación laboral</mat-label>
                                <input matInput [(ngModel)]="situacionLaboral" placeholder="Situación laboral">
                            </mat-form-field>
                        </div>
                        
                    </div>

                    <div class="row m-0">
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Contingencia</mat-label>
                                <input matInput [(ngModel)]="contingencia" placeholder="Contingencia">
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Formato de la consulta</mat-label>
                                <mat-select [(ngModel)]="id_formato_consulta">
                                  <mat-option *ngFor="let formato of formatoConsulta" [value]="formato.id">
                                    {{formato.nombre}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0" *ngIf="encargo.nombre_tipo_encargo != 'REHUSE CAS'">
                        <div id="bajaLaboral" class="col-md-4" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Baja laboral</mat-label>
                                <mat-select [(ngModel)]="bajaLaboral">
                                    <mat-option value="Si">Si</mat-option>
                                    <mat-option value="No">No</mat-option>
                                    <mat-option value="No refiere">No refiere</mat-option>
                                    <mat-option value="Se desconoce">Se desconoce</mat-option>
                                </mat-select>
                            </mat-form-field> 
                        </div>
                        <div id="fechaBajaLaboral" class="col-md-4" *ngIf="bajaLaboral == 'Si'">
                            <h5 class="m-0 pt-3 subtitulo_h5">Fecha Baja laboral</h5>
                            <input type="date" [(ngModel)]="fechaBajaLaboral">
                        </div>
                        <div id="fechaAltaLaboral" class="col-md-4" *ngIf="bajaLaboral == 'Si'">
                            <h5 class="m-0 pt-3 subtitulo_h5">Fecha Alta laboral</h5>
                            <input type="date" [(ngModel)]="fechaAltaLaboral">
                        </div>
                    </div>
                    <!--<div class="row m-0">
                        <div id="bajaLaboral" class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Baja laboral</mat-label>
                                <mat-select [(ngModel)]="bajaLaboral">
                                    <mat-option value="Si">Si</mat-option>
                                    <mat-option value="No">No</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div id="fechaBajaLaboral" class="col-md-4" *ngIf="bajaLaboral == 'Si'">
                            <h5 class="m-0 pt-3 subtitulo_h5">Fecha Baja laboral</h5>
                            <input type="date" [(ngModel)]="fechaBajaLaboral">
                        </div>
                        <div id="fechaAltaLaboral" class="col-md-4" *ngIf="bajaLaboral == 'Si'">
                            <h5 class="m-0 pt-3 subtitulo_h5">Fecha Alta laboral</h5>
                            <input type="date" [(ngModel)]="fechaAltaLaboral">
                        </div>
                    </div>-->
                    

                    <div class="row m-0 mt-5">
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Antecedentes Personales</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="20" matInput [(ngModel)]="antecedentes" placeholder="Antecedentes Personales"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0 mt-5">
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Centro de Urgencias</mat-label>
                                <input matInput [(ngModel)]="centroUrgencias" placeholder="Centro de Urgencias">
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>¿Cuándo Acude?</mat-label>
                                <input matInput [(ngModel)]="cuandoAcude" placeholder="¿Cuándo Acude?">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Diagnóstico de Urgencias</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="20" matInput [(ngModel)]="diagnosticoUrgencias" placeholder="Diagnóstico de Urgencias"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div id="hospitalizacionUrgencias" class="col-md-6" *ngIf="encargo.nombre_tipo_encargo != 'REHUSE CAS' && encargo.nombre_tipo_encargo != 'INFORME COHERENCIA'">
                        <mat-form-field style="width: 92%;" appearance="outline">
                            <mat-label>Hospitalización Urgencias</mat-label>
                            <textarea matInput [(ngModel)]="hospitalizacionUrgencias" placeholder="Hospitalización Urgencias"></textarea>
                        </mat-form-field>
                    </div>

                    <div class="row m-0 mt-5">
                        <div id="centroRehab" class="col-md-4" *ngIf="encargo.nombre_tipo_encargo != 'SEGUIMIENTO NO AUTOS'">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Centro Rehabilitación</mat-label>
                                <input matInput [(ngModel)]="centroRehab" placeholder="Centro Rehabilitación" (keyup)="keyupClaseAnterior($event.target.value, 'centroRehab', encargoAnterior.enup_centro_rehabilitacion)">
                            </mat-form-field>
                        </div>
                        <div id="centroRehab" class="col-md-4" *ngIf="encargo.nombre_tipo_encargo == 'SEGUIMIENTO NO AUTOS'">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Centro Tratamiento</mat-label>
                                <input matInput [(ngModel)]="centroRehab" placeholder="Centro Rehabilitación" (keyup)="keyupClaseAnterior($event.target.value, 'centroRehab', encargoAnterior.enup_centro_rehabilitacion)">
                            </mat-form-field>
                        </div>
                        <div id="dpFechaUltimaConsulta" class="col-md-4" *ngIf="encargo.nombre_tipo_encargo == 'INFORME COHERENCIA'">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Fecha última consulta</mat-label>
                                <input matInput [matDatepicker]="pickerFechaUltimaConsulta" [formControl] ="dpFechaUltimaConsulta">
                                <mat-datepicker-toggle matSuffix [for]="pickerFechaUltimaConsulta"></mat-datepicker-toggle>
                                <mat-datepicker #pickerFechaUltimaConsulta></mat-datepicker>
                            </mat-form-field>
                            
                        </div>
                        <div class="col-md-4" *ngIf="encargo.nombre_tipo_encargo != 'REHUSE CAS' && encargo.nombre_tipo_encargo != 'REHUSE CAS + VIDEO' && encargo.nombre_tipo_encargo != 'VALORACIÓN BASE DOCUMENTAL' && encargo.nombre_tipo_encargo != 'ALTA+IAE BASE DOCUMENTAL' && encargo.nombre_tipo_encargo != 'SEGUIMIENTO NO AUTOS' && encargo.nombre_tipo_encargo != 'SEGUIMIENTO AUTOS' && encargo.nombre_tipo_encargo != 'ESTUDIO DOCUMENTAL'">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Médico de Seguimiento</mat-label>
                                <input matInput [(ngModel)]="medicoSeguimiento" placeholder="Médico de Seguimiento">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Inicio Tratamiento</mat-label>
                                <input matInput [(ngModel)]="inicioTratamiento" placeholder="Inicio Tratamiento">
                            </mat-form-field>
                        </div>
                        <div class="col-md-4" *ngIf="encargo.nombre_tipo_encargo != 'INFORME COHERENCIA'">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Alta Médica</mat-label>
                                <input matInput [(ngModel)]="altaMedica" placeholder="Alta Médica">
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline" *ngIf="encargo.nombre_tipo_encargo != 'REHUSE CAS' && encargo.nombre_tipo_encargo != 'REHUSE CAS + VIDEO' && encargo.nombre_tipo_encargo != 'VALORACIÓN BASE DOCUMENTAL' && encargo.nombre_tipo_encargo != 'ALTA+IAE BASE DOCUMENTAL' && encargo.nombre_tipo_encargo != 'SEGUIMIENTO NO AUTOS' && encargo.nombre_tipo_encargo != 'SEGUIMIENTO AUTOS' && encargo.nombre_tipo_encargo != 'ESTUDIO DOCUMENTAL'">
                                <mat-label>Nº de Consultas</mat-label>
                                <input matInput [(ngModel)]="numConsultas" placeholder="Nº de Consultas">
                            </mat-form-field>
                        </div>
                        <div class="col-md-4" *ngIf="encargo.nombre_tipo_encargo != 'SEGUIMIENTO NO AUTOS'">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Nº de Sesiones</mat-label>
                                <input matInput [(ngModel)]="numSesiones" placeholder="Nº de Sesiones">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0" *ngIf="encargo.nombre_tipo_encargo == 'INFORME COHERENCIA'">
                        <div id="diagnosticoInicial" class="col-md-12" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Diagnóstico inicial</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="diagnosticoInicial" placeholder="Diagnóstico inicial" (keyup)="keyupClaseAnterior($event.target.value, 'diagnosticoInicial', encargoAnterior.enup_diagnostico_inicial)"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0 mb-3">
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Pruebas Realizadas</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="pruebasRealizadas" placeholder="Pruebas Realizadas"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0" *ngIf="encargo.nombre_tipo_encargo == 'INFORME COHERENCIA'">
                        <div id="determinacion" class="col-md-12" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Determinación última consulta realizada</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="determinacion" placeholder="Determinación última consulta realizada" (keyup)="keyupClaseAnterior($event.target.value, 'determinacion', encargoAnterior.enup_determinacion)"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
 
                    <div *ngIf="encargo.nombre_tipo_encargo != 'REHUSE CAS' && encargo.nombre_tipo_encargo != 'REHUSE CAS + VIDEO' && encargo.nombre_tipo_encargo != 'SEGUIMIENTO NO AUTOS' && encargo.nombre_tipo_encargo != 'SEGUIMIENTO AUTOS' && encargo.nombre_tipo_encargo != 'ESTUDIO DOCUMENTAL' && encargo.nombre_tipo_encargo != 'INFORME COHERENCIA'" class="row m-0">
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Intervenciones Quirúrgicas</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="intervencionesQuirurgicas" placeholder="Intervenciones Quirúrgicas"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0 mb-3" *ngIf="encargo.nombre_tipo_encargo != 'INFORME COHERENCIA'">
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Evolución documentada**</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="evolucion" placeholder="Evolución documentada"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                </div>

                <!-- Asesoria Graves -->
                <div *ngIf="encargo.nombre_tipo_encargo == 'ASESORÍA GRAVES'">
                    <div class="row m-0">
                        <h2>Información inicial lesionado</h2>
                        <div id="profesion" class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Profesión</mat-label>
                                <input matInput [(ngModel)]="profesion" placeholder="Profesión">
                            </mat-form-field>
                        </div>
                        <div id="situacionLaboral" class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Situación laboral</mat-label>
                                <input matInput [(ngModel)]="situacionLaboral" placeholder="Situación laboral">
                            </mat-form-field>
                        </div>
                        <div id="bajaLaboral" class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Baja laboral</mat-label>
                                <input matInput [(ngModel)]="bajaLaboral" placeholder="Baja laboral">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div id="contingencia" class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Contingencia</mat-label>
                                <input matInput [(ngModel)]="contingencia" placeholder="Contingencia">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0 mt-5">
                        <div id="antecedentes" class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Antecedentes Personales</mat-label>
                                <textarea matInput [(ngModel)]="antecedentes" placeholder="Antecedentes Personales"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0 mt-5">
                        <div id="centroUrgencias" class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Centro de Urgencias</mat-label>
                                <input matInput [(ngModel)]="centroUrgencias" placeholder="Centro de Urgencias">
                            </mat-form-field>
                        </div>
                        <div id="cuandoAcude" class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>¿Cuándo Acude?</mat-label>
                                <input matInput [(ngModel)]="cuandoAcude" placeholder="¿Cuándo Acude?">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div id="diagnosticoUrgencias" class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Diagnóstico de Urgencias</mat-label>
                                <textarea matInput [(ngModel)]="diagnosticoUrgencias" placeholder="Diagnóstico de Urgencias"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
 
                <!-- Campos informe VALORACIÓN VIDEO-CONSULTA -->
                <div *ngIf="encargo.nombre_tipo_encargo == 'VALORACIÓN VIDEO-CONSULTA' || encargo.nombre_tipo_encargo == 'VALORACIÓN VIDEO-CONSULTA-MUTUAS' || encargo.nombre_tipo_encargo == 'INFORME COHERENCIA'">
                    <div class="row m-0">
                        <h2>Consulta Médica</h2>
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Fecha de la consulta</mat-label>
                                <input matInput [matDatepicker]="dpVideoConsulta" [formControl]="dpFechaVideoConsulta">
                                <mat-datepicker-toggle matSuffix [for]="dpVideoConsulta"></mat-datepicker-toggle>
                                <mat-datepicker #dpVideoConsulta disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Formato de la consulta</mat-label>
                                <mat-select [(ngModel)]="id_formato_consulta">
                                  <mat-option *ngFor="let formato of formatoConsulta" [value]="formato.id">
                                    {{formato.nombre}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Médico</mat-label>
                                <mat-select [(ngModel)]="medicoConsulta" [compareWith]="compareMedico">
                                  <mat-option *ngFor="let med of medicos" [value]="med">
                                    {{med.med_nombre}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Anamnesis</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="anamnesis" placeholder="Anamnesis"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Exploración Física</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="exploracionFisica" placeholder="Exploración Física"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div *ngIf="encargo.nombre_tipo_encargo == 'INFORME COHERENCIA'">
                        <div class="row mt-2 mb-0 mx-0">
                            <h3><b>Verificación Condiciones Lesionado Leve</b></h3>
                        </div>

                        <div class="row mt-2 mb-0 mx-0">
                            <div id="xTMCC" class="col-md-4">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>¿Diagnóstico principal TMCC?</mat-label>
                                    <mat-select [(ngModel)]="xTMCC">
                                        <mat-option [value]="1">Sí</mat-option>
                                        <mat-option [value]="0">No</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                
                            </div>
    
                            <div id="xPatoColumna" class="col-md-4">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>¿No patología previa columna?</mat-label>
                                    <mat-select [(ngModel)]="xPatoColumna">
                                        <mat-option [value]="1">Sí</mat-option>
                                        <mat-option [value]="0">No</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row mt-2 mb-0 mx-0">
                            <div id="xEnfermedades" class="col-md-4">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>¿NO enfermedades sistémicas(esclerosis, ELA...)?</mat-label>
                                    <mat-select [(ngModel)]="xEnfermedades">
                                        <mat-option [value]="1">Sí</mat-option>
                                        <mat-option [value]="0">No</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
    
                            <div id="xBajaMutua" class="col-md-4">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>¿Sin baja laboral a través de Mutua Laboral?</mat-label>
                                    <mat-select [(ngModel)]="xBajaMutua">
                                        <mat-option [value]="1">Sí</mat-option>
                                        <mat-option [value]="0">No</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <br>
                        <div class="row mt-2 mb-0 mx-0"> 
                            <h3><b>Verificación Coherencia Pruebas</b></h3>
                        </div>

                        <div class="row mt-2 mb-0 mx-0">
                            <div id="xBioFuncional" class="col-md-4">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>¿Biomecánica Funcional?</mat-label>
                                    <mat-select [(ngModel)]="xBioFuncional">
                                        <mat-option [value]="1">Sí</mat-option>
                                        <mat-option [value]="0">No</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div id="xPruebaConsistente" class="col-md-4" *ngIf="xBioFuncional">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>¿Prueba Consistente?</mat-label>
                                    <mat-select [(ngModel)]="xPruebaConsistente">
                                        <mat-option [value]="1">Sí</mat-option>
                                        <mat-option [value]="0">No</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div *ngIf="xBioFuncional">
                            <div class="row mt-2 mb-0 mx-0">
                                <div id="xResultPruebaBio" class="col-md-4">
                                    <mat-form-field class="input_informe_75" appearance="outline">
                                        <mat-label>¿Resultado Prueba?</mat-label>
                                        <mat-select [(ngModel)]="xResultPruebaBio">
                                            <mat-option [value]="1">Sí</mat-option>
                                            <mat-option [value]="0">No</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
    
                                <div id="xCoherenteBio" class="col-md-4">
                                    <mat-form-field class="input_informe_75" appearance="outline">
                                        <mat-label>¿Coherente con situación global?</mat-label>
                                        <mat-select [(ngModel)]="xCoherenteBio">
                                            <mat-option [value]="1">Sí</mat-option>
                                            <mat-option [value]="0">No</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
    
                            <br>
                            
                            <div class="row mt-2 mb-0 mx-0">
                                <div id="dpFechaPruebaBio" class="col-md-4">
                                    <mat-form-field class="input_informe_75" appearance="outline">
                                        <mat-label>Fecha Prueba Biomecánica</mat-label>
                                        <input matInput [matDatepicker]="pickerFechaPruebaBio" [formControl] ="dpFechaPruebaBio">
                                        <mat-datepicker-toggle matSuffix [for]="pickerFechaPruebaBio"></mat-datepicker-toggle>
                                        <mat-datepicker #pickerFechaPruebaBio></mat-datepicker>
                                    </mat-form-field>
                                    
                                </div>
                                <div id="nfhas" class="col-md-4">
                                    <mat-form-field class="input_informe_75" appearance="outline">
                                        <mat-label>Valor NFHAS</mat-label>
                                        <input matInput [(ngModel)]="valorNFHAS" placeholder="Valor NFHAS">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        

                        <hr>
                        <br>

                        <div class="row mt-2 mb-0 mx-0">
                            <div id="xTermoRealizada" class="col-md-4">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>¿Termografía realizada?</mat-label>
                                    <mat-select [(ngModel)]="xTermoRealizada">
                                        <mat-option [value]="1">Sí</mat-option>
                                        <mat-option [value]="0">No</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div id="xIndicadorCoherencia" class="col-md-4" *ngIf="xTermoRealizada">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>¿Indicador Coherencia?</mat-label>
                                    <mat-select [(ngModel)]="xIndicadorCoherencia">
                                        <mat-option [value]="1">Sí</mat-option>
                                        <mat-option [value]="0">No</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div *ngIf="xTermoRealizada">
                            <div class="row mt-2 mb-0 mx-0">
                                <div id="xResultadoPrueba" class="col-md-4">
                                    <mat-form-field class="input_informe_75" appearance="outline">
                                        <mat-label>¿Resultado Prueba?</mat-label>
                                        <mat-select [(ngModel)]="xResultadoPrueba">
                                            <mat-option [value]="1">Sí</mat-option>
                                            <mat-option [value]="0">No</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div id="xCoherenteTermo" class="col-md-4">
                                    <mat-form-field class="input_informe_75" appearance="outline">
                                        <mat-label>¿Coherente con situación global?</mat-label>
                                        <mat-select [(ngModel)]="xCoherenteTermo">
                                            <mat-option [value]="1">Sí</mat-option>
                                            <mat-option [value]="0">No</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
    
                            <br>
    
                            <div class="row mt-2 mb-0 mx-0">
                                <div id="dpFechaPruebaTermo" class="col-md-4">
                                    <mat-form-field class="input_informe_75" appearance="outline">
                                        <mat-label>Fecha Prueba Termografía</mat-label>
                                        <input matInput [matDatepicker]="pickerFechaPruebaTermo" [formControl] ="dpFechaPruebaTermo">
                                        <mat-datepicker-toggle matSuffix [for]="pickerFechaPruebaTermo"></mat-datepicker-toggle>
                                        <mat-datepicker #pickerFechaPruebaTermo></mat-datepicker>
                                    </mat-form-field>
                                    
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="input_informe_75" appearance="outline">
                                        <mat-label>Valor Δ T [◦C]</mat-label>
                                        <input matInput placeholder="Valor Δ T [◦C]" [(ngModel)] = "valorDeltaV">
                                    </mat-form-field>
                                </div>
                            </div>
                            
    
                            <div class="row mt-2 mb-0 mx-0">
                                <div class="col-md-4">
                                    <mat-form-field class="input_informe_75" appearance="outline">
                                        <mat-label>Zona Analizada</mat-label>
                                        <input matInput placeholder="Zona analizada" [(ngModel)] = "zonaAnalizada">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="input_informe_75" appearance="outline">
                                        <mat-label>Resultado Ecografía</mat-label>
                                        <input matInput placeholder="Resultado Ecografía" [(ngModel)] = "resultadoEco">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="encargo.nombre_tipo_encargo == 'VALORACIÓN VIDEO-CONSULTA' || encargo.nombre_tipo_encargo == 'VALORACIÓN VIDEO-CONSULTA-MUTUAS'">
                        <div class="row m-0" *ngFor="let campoVideoConsulta of camposVideoConsultaAnteriores">
                            <div class="col-md-12" style="width: 92%; margin-bottom: 20px;">
                                <mat-accordion>
                                    <mat-expansion-panel style="background-color: #ebebeb;">
                                        <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <b>Consulta Anterior</b>&nbsp; Medico: {{campoVideoConsulta.nombreMedico}}
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            Fecha de video-consulta: {{campoVideoConsulta.enup_fecha_video_consulta}}
                                        </mat-panel-description>
                                        </mat-expansion-panel-header>

                                        <div class="row m-0">
                                            <h2>Consulta Médica</h2>
                                            <div class="col-md-4">
                                                <mat-form-field class="input_informe_75" appearance="fill">
                                                    <mat-label>Fecha de la consulta</mat-label>
                                                    <input matInput placeholder="Fecha de la consulta" value="{{campoVideoConsulta.enup_fecha_video_consulta}}" readonly>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-4">
                                                <mat-form-field class="input_informe_75" appearance="outline">
                                                    <mat-label>Formato de la consulta</mat-label>
                                                    <mat-select [(ngModel)]="id_formato_consulta">
                                                      <mat-option *ngFor="let formato of formatoConsulta" [value]="formato.id">
                                                        {{formato.nombre}}
                                                      </mat-option>
                                                    </mat-select>
                                                  </mat-form-field>
                                            </div>
                                        </div>
                    
                                        <div class="row m-0">
                                            <div class="col-md-12">
                                                <mat-form-field style="width: 92%;" appearance="fill">
                                                    <mat-label>Anamnesis</mat-label>
                                                    <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput placeholder="Anamnesis" value="{{campoVideoConsulta.enup_anamnesis}}" readonly></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                    
                                        <div class="row m-0">
                                            <div class="col-md-12">
                                                <mat-form-field style="width: 92%;" appearance="fill">
                                                    <mat-label>Exploración Física</mat-label>
                                                    <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput placeholder="Exploración Física" value="{{campoVideoConsulta.enup_exploracion_fisica}}" readonly></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                    
                                        <!-- <div class="row m-0">
                                            <div id="pruebasRealizadas" class="col-md-12">
                                                <mat-form-field style="width: 92%;" appearance="fill">
                                                    <mat-label>Pruebas Realizadas</mat-label>
                                                    <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput placeholder="Pruebas Realizadas" value="{{campoVideoConsulta.enup_pruebas_realizadas_video_consulta}}" readonly></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                    
                                        <div class="row m-0">
                                            <div class="col-md-12">
                                                <mat-form-field style="width: 92%;" appearance="fill">
                                                    <mat-label>Tratamiento Rehabilitador</mat-label>
                                                    <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput placeholder="Tratamiento Rehabilitador" value="{{campoVideoConsulta.enup_tratamiento_rehabilitador}}" readonly></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                    
                                        <div class="row m-0">
                                            <div class="col-md-12">
                                                <mat-form-field style="width: 92%;" appearance="fill">
                                                    <mat-label>Intervenciones Quirúrgicas</mat-label>
                                                    <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput placeholder="Intervenciones Quirúrgicas" value="{{campoVideoConsulta.enup_intervenciones_quirurgicas}}" readonly></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div> -->
                                        
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Campos informe REHUSE CAS + VIDEO -->
                <div *ngIf="encargo.nombre_tipo_encargo == 'REHUSE CAS + VIDEO' || encargo.nombre_tipo_encargo == 'REHUSE CAS BIO' || encargo.nombre_tipo_encargo == 'INFORME COHERENCIA'">
                    <div class="row m-0">
                        <h2>Datos relativos al análisis de impacto</h2>
                        <div class="col-md-4" *ngIf="encargo.nombre_tipo_encargo != 'INFORME COHERENCIA'">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Nº Referencia del informe</mat-label>
                                <input matInput [(ngModel)]="referenciaInforme" placeholder="Nº Referencia del informe">
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Firmado por</mat-label>
                                <input matInput [(ngModel)]="firmadoPor" placeholder="Firmado por">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Tipo de impacto</mat-label>
                                <input matInput [(ngModel)]="tipoImpacto" placeholder="Tipo de impacto">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Δ v [km/h]</mat-label>
                                <input matInput [(ngModel)]="deltaV" placeholder="Delta-v">
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Aceleración [Gs]</mat-label>
                                <input matInput [(ngModel)]="aceleracion" placeholder="Aceleración">
                            </mat-form-field>
                        </div>
                        <div id="condicionesRehuse" class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>¿Condiciones de rehuse?</mat-label>
                                <mat-select [(ngModel)]="condicionesRehuse">
                                    <mat-option [value]="1">Cumple</mat-option>
                                    <mat-option [value]="0">No cumple</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div *ngIf="encargo.nombre_tipo_encargo == 'REHUSE CAS + VIDEO' || encargo.nombre_tipo_encargo == 'REHUSE CAS BIO'">
                        <div class="row m-0">
                            <h2>Consulta Médica</h2>
                            <div class="col-md-4">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>Fecha de la consulta</mat-label>
                                    <input matInput [matDatepicker]="dpVideoConsulta" [formControl]="dpFechaVideoConsulta">
                                    <mat-datepicker-toggle matSuffix [for]="dpVideoConsulta"></mat-datepicker-toggle>
                                    <mat-datepicker #dpVideoConsulta disabled="false"></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>Formato de la consulta</mat-label>
                                    <mat-select [(ngModel)]="id_formato_consulta">
                                      <mat-option *ngFor="let formato of formatoConsulta" [value]="formato.id">
                                        {{formato.nombre}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                            </div>
                        </div>

                        <div class="row m-0">
                            <div class="col-md-12">
                                <mat-form-field style="width: 92%;" appearance="outline">
                                    <mat-label>Anamnesis</mat-label>
                                    <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="anamnesis" placeholder="Anamnesis"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
        
                        <div class="row m-0">
                            <div class="col-md-12">
                                <mat-form-field style="width: 92%;" appearance="outline">
                                    <mat-label>Exploración Física</mat-label>
                                    <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="exploracionFisica" placeholder="Exploración Física"></textarea>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row m-0" *ngFor="let campoVideoConsulta of camposVideoConsultaAnteriores">
                            <div class="col-md-12" style="width: 92%; margin-bottom: 20px;">
                                <mat-accordion>
                                    <mat-expansion-panel style="background-color: #ebebeb;">
                                        <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <b>Consulta Anterior</b>&nbsp; Medico: {{campoVideoConsulta.nombreMedico}}
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            Fecha de video-consulta: {{campoVideoConsulta.enup_fecha_video_consulta}}
                                        </mat-panel-description>
                                        </mat-expansion-panel-header>

                                        <div class="row m-0">
                                            <h2>Consulta Médica</h2>
                                            <div class="col-md-4">
                                                <mat-form-field class="input_informe_75" appearance="fill">
                                                    <mat-label>Fecha de la consulta</mat-label>
                                                    <input matInput placeholder="Fecha de la consulta" value="{{campoVideoConsulta.enup_fecha_video_consulta}}" readonly>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-4">
                                                <mat-form-field class="input_informe_75" appearance="outline">
                                                    <mat-label>Formato de la consulta</mat-label>
                                                    <mat-select [(ngModel)]="id_formato_consulta">
                                                      <mat-option *ngFor="let formato of formatoConsulta" [value]="formato.id">
                                                        {{formato.nombre}}
                                                      </mat-option>
                                                    </mat-select>
                                                  </mat-form-field>
                                            </div>
                                        </div>
                    
                                        <div class="row m-0">
                                            <div class="col-md-12">
                                                <mat-form-field style="width: 92%;" appearance="fill">
                                                    <mat-label>Anamnesis</mat-label>
                                                    <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput placeholder="Anamnesis" value="{{campoVideoConsulta.enup_anamnesis}}" readonly></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                    
                                        <div class="row m-0">
                                            <div class="col-md-12">
                                                <mat-form-field style="width: 92%;" appearance="fill">
                                                    <mat-label>Exploración Física</mat-label>
                                                    <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput placeholder="Exploración Física" value="{{campoVideoConsulta.enup_exploracion_fisica}}" readonly></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Campos informe Seguimiento Auto y No Auto-->
                <div *ngIf="encargo.nombre_tipo_encargo == 'SEGUIMIENTO NO AUTOS' || encargo.nombre_tipo_encargo == 'SEGUIMIENTO AUTOS'  || encargo.nombre_tipo_encargo == 'SEGUIMIENTO DIVERSOS'">

                    <div class="row m-0">
                        <h2>Consulta Médica</h2>
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Fecha de la consulta</mat-label>
                                <input matInput [matDatepicker]="dpVideoConsulta" [formControl]="dpFechaVideoConsulta">
                                <mat-datepicker-toggle matSuffix [for]="dpVideoConsulta"></mat-datepicker-toggle>
                                <mat-datepicker #dpVideoConsulta disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Formato de la consulta</mat-label>
                                <mat-select [(ngModel)]="id_formato_consulta">
                                  <mat-option *ngFor="let formato of formatoConsulta" [value]="formato.id">
                                    {{formato.nombre}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Anamnesis</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="anamnesis" placeholder="Anamnesis"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Exploración Física</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="exploracionFisica" placeholder="Exploración Física"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0" *ngFor="let campoVideoConsulta of camposVideoConsultaAnteriores">
                        <div class="col-md-12" style="width: 92%; margin-bottom: 20px;">
                            <mat-accordion>
                                <mat-expansion-panel style="background-color: #ebebeb;">
                                    <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <b>Consulta Anterior</b>&nbsp; Medico: {{campoVideoConsulta.nombreMedico}}
                                    </mat-panel-title>
                                    <mat-panel-description>
                                        Fecha de video-consulta: {{campoVideoConsulta.enup_fecha_video_consulta}}
                                    </mat-panel-description>
                                    </mat-expansion-panel-header>

                                    <div class="row m-0">
                                        <h2>Consulta Médica</h2>
                                        <div class="col-md-4">
                                            <mat-form-field class="input_informe_75" appearance="fill">
                                                <mat-label>Fecha de la consulta</mat-label>
                                                <input matInput placeholder="Fecha de la consulta" value="{{campoVideoConsulta.enup_fecha_video_consulta}}" readonly>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="input_informe_75" appearance="outline">
                                                <mat-label>Formato de la consulta</mat-label>
                                                <mat-select [(ngModel)]="id_formato_consulta">
                                                  <mat-option *ngFor="let formato of formatoConsulta" [value]="formato.id">
                                                    {{formato.nombre}}
                                                  </mat-option>
                                                </mat-select>
                                              </mat-form-field>
                                        </div>
                                    </div>
                
                                    <div class="row m-0">
                                        <div class="col-md-12">
                                            <mat-form-field style="width: 92%;" appearance="fill">
                                                <mat-label>Anamnesis</mat-label>
                                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput placeholder="Anamnesis" value="{{campoVideoConsulta.enup_anamnesis}}" readonly></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                
                                    <div class="row m-0">
                                        <div class="col-md-12">
                                            <mat-form-field style="width: 92%;" appearance="fill">
                                                <mat-label>Exploración Física</mat-label>
                                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput placeholder="Exploración Física" value="{{campoVideoConsulta.enup_exploracion_fisica}}" readonly></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- Valoracion -->
                <div *ngIf="idTipo == 6 || idTipo == 7 || idTipo == 5 || idTipo == 8 || idTipo == 10 || idTipo == 11 || idTipo == 15 || idTipo == 13 || idTipo == 17 || idTipo == 22" class="row m-0">
                    <h2>Valoración</h2>

                    <div class="mb-2">
                        <div class="row mt-1 mb-4 mx-0" *ngIf="idTipo != 22">
                            <div id="valoracionFinal" class="col-md-4">
                                <mat-checkbox [(ngModel)]="valoracionFinal">
                                    Valoración final / lesionado estabilizado
                                </mat-checkbox>
                            </div>
    
                            <div id="valoracionProv" class="col-md-4">
                                <mat-checkbox [(ngModel)]="valoracionProv">
                                    Valoración provisional / lesionado NO estabilizado
                                </mat-checkbox>
                            </div>

                            <div id="reservaTecnica" class="col-md-4" *ngIf="idTipo != 11">
                                <mat-checkbox [(ngModel)]="reservaTecnica">
                                    Provisión / Reserva técnica
                                </mat-checkbox>
                            </div>
                        </div>

                        <div class="row mt-1 mb-2 mx-0" *ngIf="valoracionFinal == true && idTipo != 11">
                            <div id="imd" class="col-md-4">
                                <mat-checkbox [(ngModel)]="imd">
                                    ¿IMD necesario?
                                </mat-checkbox>
                            </div>
                            <div class="row mx-0 mb-3 mt-3" *ngIf="imd == true">
                                <h4>Observaciones IMD</h4>
                                <div id="observacionesIMD" class="col-md-12">
                                    <mat-form-field style="width: 92%;" appearance="outline">
                                        <mat-label>Observaciones IMD</mat-label>
                                        <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="observacionesIMD" placeholder="Observaciones IMD"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h4>Lesiones temporales</h4>
                    <div class="col-md-3">
                        <mat-form-field class="input_informe_67" appearance="outline">
                            <mat-label>Días PPP Básicos</mat-label>
                            <input matInput [(ngModel)]="diasBasicos" (change)="sumarDiasPPP()" placeholder="Días PPP Básicos" type="number" min="0">
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="input_informe_67" appearance="outline">
                            <mat-label>Días PPP Moderados</mat-label>
                            <input matInput [(ngModel)]="diasModerados" (change)="sumarDiasPPP()" placeholder="Días PPP Moderados" type="number" min="0">
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="input_informe_67" appearance="outline">
                            <mat-label>Días PPP Graves</mat-label>
                            <input matInput [(ngModel)]="diasGraves" (change)="sumarDiasPPP()" placeholder="Días PPP Graves" type="number" min="0">
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="input_informe_67" appearance="outline">
                            <mat-label>Días PPP Muy Graves</mat-label>
                            <input matInput [(ngModel)]="diasMuyGraves" (change)="sumarDiasPPP()" placeholder="Días PPP Muy Graves" type="number" min="0">
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="idTipo == 6 || idTipo == 7  || idTipo == 5  || idTipo == 8  || idTipo == 10 || idTipo == 11  || idTipo == 13 || idTipo == 15 || idTipo == 17 || idTipo == 22" class="row m-0">
                    <div class="col-md-3">
                        <mat-form-field class="input_informe_67" appearance="outline">
                            <mat-label>Dias totales</mat-label>
                            <input matInput disabled [(ngModel)]="diasTotales" placeholder="Dias totales">
                        </mat-form-field>
                    </div>
                </div>

                <!-- VALORACION BASE DOCUMENTAL -->
                <!-- <div *ngIf="idTipo == 6 || idTipo == 7 || idTipo == 10">
                    <div class="row m-0">
                        <h2>Valoración</h2>
                        <h4>Lesiones temporales Mínimas</h4>
                        <div class="col-md-3">
                            <mat-form-field class="input_informe_67" appearance="outline">
                                <mat-label>Días PPP Básicos Mínimos</mat-label>
                                <input matInput [(ngModel)]="diasBasicos" (change)="sumarDiasPPP()" placeholder="Días PPP Básicos Mínimos">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="input_informe_67" appearance="outline">
                                <mat-label>Días PPP Moderados Mínimos</mat-label>
                                <input matInput [(ngModel)]="diasModerados" (change)="sumarDiasPPP()" placeholder="Días PPP Moderados Mínimos">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="input_informe_67" appearance="outline">
                                <mat-label>Días PPP Graves Mínimos</mat-label>
                                <input matInput [(ngModel)]="diasGraves" (change)="sumarDiasPPP()" placeholder="Días PPP Graves Mínimos">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="input_informe_67" appearance="outline">
                                <mat-label>Días PPP Muy Graves Mínimos</mat-label>
                                <input matInput [(ngModel)]="diasMuyGraves" (change)="sumarDiasPPP()" placeholder="Días PPP Muy Graves Mínimos">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-md-3">
                            <mat-form-field class="input_informe_67" appearance="outline">
                                <mat-label>Dias totales Mínimos</mat-label>
                                <input matInput disabled [(ngModel)]="diasTotales" placeholder="Dias totales Mínimos">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <h4>Lesiones temporales Máximas</h4>
                        <div class="col-md-3">
                            <mat-form-field class="input_informe_67" appearance="outline">
                                <mat-label>Días PPP Básicos Máximos</mat-label>
                                <input matInput [(ngModel)]="diasBasicosMax" (change)="sumarDiasPPPMax()" placeholder="Días PPP Básicos Máximos">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="input_informe_67" appearance="outline">
                                <mat-label>Días PPP Moderados Máximos</mat-label>
                                <input matInput [(ngModel)]="diasModeradosMax" (change)="sumarDiasPPPMax()" placeholder="Días PPP Moderados Máximos">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="input_informe_67" appearance="outline">
                                <mat-label>Días PPP Graves Máximos</mat-label>
                                <input matInput [(ngModel)]="diasGravesMax" (change)="sumarDiasPPPMax()" placeholder="Días PPP Graves Máximos">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="input_informe_67" appearance="outline">
                                <mat-label>Días PPP Muy Graves Máximos</mat-label>
                                <input matInput [(ngModel)]="diasMuyGravesMax" (change)="sumarDiasPPPMax()" placeholder="Días PPP Muy Graves Máximos">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-md-3">
                            <mat-form-field class="input_informe_67" appearance="outline">
                                <mat-label>Dias totales Máximos</mat-label>
                                <input matInput disabled [(ngModel)]="diasTotalesMax" placeholder="Dias totales Máximos">
                            </mat-form-field>
                        </div>
                    </div>
                </div> -->

                <div *ngIf="idTipo == 6 || idTipo == 7 || idTipo == 5 || idTipo == 8 || idTipo == 10 || idTipo == 11 || idTipo == 15 || idTipo == 13 || idTipo == 17 || idTipo == 22" class="row m-0" [formGroup]="secuelaForm">
                    <div formArrayName="secuelas">
                        <h4>Secuelas<button style="margin-left: 20px;" (click)="addNuevasSecuelas()" mat-stroked-button>+</button></h4>

                        <div class="row m-0" *ngFor="let item of secuelas().controls; let i=index" [formGroupName]="i">
                            <div class="col-md-2">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>Código</mat-label>
                                    <input matInput formControlName="codigo" placeholder="Código">
                                </mat-form-field>
                            </div>

                            <div class="col-md-7">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>Descripción secuela</mat-label>
                                    <input matInput formControlName="descripcion" placeholder="Descripción secuela">
                                </mat-form-field>
                            </div>

                            <div class="col-md-2">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>Puntos</mat-label>
                                    <input matInput formControlName="puntos" placeholder="Puntos">
                                </mat-form-field>
                            </div>

                            <div class="col-md-1">
                                <button style="margin-top: 10px;" (click)="quitarSecuela(i)" mat-flat-button color="warn">-</button>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- Para informe Valoracion Base Documental -->
                <!-- <div *ngIf="idTipo == 6 || idTipo == 7 || idTipo == 10" class="row m-0" [formGroup]="secuelaHorquillaForm">
                    <div formArrayName="secuelas">
                        <h4>Secuelas<button style="margin-left: 20px;" (click)="addNuevasSecuelasHorquilla()" mat-stroked-button>+</button></h4>

                        <div class="row m-0" *ngFor="let item of secuelasHorquilla().controls; let i=index" [formGroupName]="i">
                            <div class="col-md-2">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>Código</mat-label>
                                    <input matInput formControlName="codigo" placeholder="Código">
                                </mat-form-field>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>Descripción secuela</mat-label>
                                    <input matInput formControlName="descripcion" placeholder="Descripción secuela">
                                </mat-form-field>
                            </div>

                            <div class="col-md-2">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>Puntos Mínimos</mat-label>
                                    <input matInput formControlName="puntosMin" placeholder="Puntos Min">
                                </mat-form-field>
                            </div>

                            <div class="col-md-2">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>Puntos Máximos</mat-label>
                                    <input matInput formControlName="puntosMax" placeholder="Puntos Max">
                                </mat-form-field>
                            </div>

                            <div class="col-md-1">
                                <button style="margin-top: 10px;" (click)="quitarSecuelaHorquilla(i)" mat-flat-button color="warn">-</button>
                            </div>
                        </div>

                    </div>
                </div> -->

                <div *ngIf="(idTipo == 6 || idTipo == 7 || idTipo == 5 || idTipo == 8 || idTipo == 10 || idTipo == 11 || idTipo == 15 || idTipo == 13 || idTipo == 17 || idTipo == 22) && numSecuelas > 0"class="row m-0">
                    <div class="col-md-2">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Total Puntos Secuelas</mat-label>
                            <input matInput disabled [(ngModel)]="puntosTotalesSecuelas" placeholder="Total Puntos Secuelas">
                        </mat-form-field>
                    </div>
                </div>

                <!-- <div *ngIf="(idTipo == 6 && numSecuelas > 0) || (idTipo == 7 && numSecuelas > 0) || (idTipo == 10 && numSecuelas > 0)" class="row m-0">
                    <div class="col-md-2">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Total Puntos Secuelas Mínimas</mat-label>
                            <input matInput disabled [(ngModel)]="puntosMinTotalesSecuelas" placeholder="Total Puntos Secuelas Mínimas">
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Total Puntos Secuelas Máximas</mat-label>
                            <input matInput disabled [(ngModel)]="puntosMaxTotalesSecuelas" placeholder="Total Puntos Secuelas Máximas">
                        </mat-form-field>
                    </div>
                </div> -->


                <div *ngIf="idTipo == 6 || idTipo == 7 || idTipo == 5 || idTipo == 8 || idTipo == 10 || idTipo == 11 || idTipo == 15 || idTipo == 13 || idTipo == 17" class="row m-0">
                    <h4>Perjuicio estético</h4>
                    <div class="col-md-8">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Descripcion Perjuicio estético</mat-label>
                            <input matInput [(ngModel)]="descripcionPerjuicioEstetico" placeholder="Descripcion Perjuicio estético">
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Puntos</mat-label>
                            <input matInput [(ngModel)]="puntosPerjuicioEstetico" placeholder="Puntos">
                        </mat-form-field>
                    </div>
                </div>

                <!-- <div *ngIf="idTipo == 6 || idTipo == 7 || idTipo == 10" class="row m-0">
                    <h4>Perjuicio estético</h4>
                    <div class="col-md-6">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Descripcion Perjuicio estético</mat-label>
                            <input matInput [(ngModel)]="descripcionPerjuicioEstetico" placeholder="Descripcion Perjuicio estético">
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Puntos Mínimos</mat-label>
                            <input matInput [(ngModel)]="puntosPerjuicioEstetico" placeholder="Puntos Mínimos">
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Puntos Máximos</mat-label>
                            <input matInput [(ngModel)]="puntosPerjuicioEsteticoMax" placeholder="Puntos Máximos">
                        </mat-form-field>
                    </div>
                </div> -->

                <div *ngIf="encargo.nombre_tipo_encargo != 'SEGUIMIENTO DIVERSOS' && encargo.nombre_tipo_encargo != 'REHUSE CAS' && encargo.nombre_tipo_encargo != 'REHUSE CAS + VIDEO' && encargo.nombre_tipo_encargo != 'REHUSE CAS BIO' && encargo.nombre_tipo_encargo != 'SEGUIMIENTO NO AUTOS' && encargo.nombre_tipo_encargo != 'ESTUDIO DOCUMENTAL ASESORÍA' && encargo.nombre_tipo_encargo != 'INFORME COHERENCIA'">

                    <div class="row m-0">
                        <h4>Correctores</h4>
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Perjuicio moral por pérdida de calidad de vida</mat-label>
                                <mat-select [(ngModel)]="perjuicioMoral" (selectionChange)="onChangeLimpiarMoral()">
                                    <mat-option value="No">No</mat-option>
                                    <mat-option value="Si">Si</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="perjuicioMoral == 'Si'" class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Grado</mat-label>
                                <input matInput [(ngModel)]="grado" placeholder="Grado">
                            </mat-form-field>
                        </div>
                        <div *ngIf="perjuicioMoral == 'Si'" class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>%</mat-label>
                                <input matInput [(ngModel)]="porcentajeGrado" placeholder="Porcentaje">
                            </mat-form-field>
                        </div>
                    </div>
    
                    <div class="row m-0">
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Perjuicio personal particular por intervención quirúrgica</mat-label>
                                <mat-select [(ngModel)]="perjuicioPersonal" (selectionChange)="onChangeLimpiarPersonal()">
                                    <mat-option value="No">No</mat-option>
                                    <mat-option value="Si">Si</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!-- <div *ngIf="perjuicioPersonal == 'Si'" class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Grupo Quirúrgico</mat-label>
                                <input matInput [(ngModel)]="grupoQuirurgico" placeholder="Grupo Quirúrgico">
                            </mat-form-field>
                        </div> -->
                        <div *ngIf="perjuicioPersonal == 'Si'" [formGroup]="intervencionForm">
                            <div formArrayName="intervenciones">
                                <h4>Intervenciones<button style="margin-left: 20px;" (click)="addNuevasIntervenciones()" mat-stroked-button>+</button></h4>
    
                                <div class="row m-0" *ngFor="let item of intervenciones().controls; let i=index" [formGroupName]="i">
                                    <div id="grupo" class="col-md-2">
                                        <mat-form-field class="input_informe_75" appearance="outline">
                                            <mat-label>Grupo</mat-label>
                                            <input matInput formControlName="grupo" placeholder="Código">
                                        </mat-form-field>
                                    </div>
        
                                    <div id="descripcionIntervencion" class="col-md-7">
                                        <mat-form-field class="input_informe_75" appearance="outline">
                                            <mat-label>Descripción</mat-label>
                                            <input matInput formControlName="descripcion" placeholder="Descripción">
                                        </mat-form-field>
                                    </div>
        
                                    <div class="col-md-1">
                                        <button style="margin-top: 10px;" (click)="quitarIntervencion(i)" mat-flat-button color="warn">-</button>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </div>
    
                    <div *ngIf="encargo.nombre_tipo_encargo != 'REHUSE CAS' && encargo.nombre_tipo_encargo != 'REHUSE CAS + VIDEO' && encargo.nombre_tipo_encargo != 'REHUSE CAS BIO' && encargo.nombre_tipo_encargo != 'SEGUIMIENTO NO AUTOS' && encargo.nombre_tipo_encargo != 'ESTUDIO DOCUMENTAL ASESORÍA' && encargo.nombre_tipo_encargo != 'INFORME COHERENCIA'">
                        <div class="row m-0">
                            <div class="col-md-4">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>¿Otros correctores? (Artículos 110 al 123 si necesario)</mat-label>
                                    <mat-select [(ngModel)]="otrosCorrectores">
                                        <mat-option value="No">No</mat-option>
                                        <mat-option value="Si">Si</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
        
                        <div *ngIf="otrosCorrectores == 'Si'">
                            <div class="row mt-2 mb-0 mx-0">
                                <div id="xDanoMoral" class="col-md-6">
                                    <mat-checkbox [(ngModel)]="xDanoMoral">
                                        Daños morales complementarios por perjuicio psicofísico, orgánico y sensorial
                                    </mat-checkbox>
                                </div>
        
                                <div id="xDanoMoralEstetico" class="col-md-6">
                                    <mat-checkbox [(ngModel)]="xDanoMoralEstetico">
                                        Daños morales complementarios por perjuicio estético
                                    </mat-checkbox>
                                </div>
                            </div>
        
                            <div class="row mt-1 mb-0 mx-0">
                                <div id="xPerjuicioMoral" class="col-md-6">
                                    <mat-checkbox [(ngModel)]="xPerjuicioMoral">
                                        Perjuicio moral por pérdida de calidad de vida de familiares de grandes lesionados
                                    </mat-checkbox>
                                </div>
        
                                <div id="xPerjuicioExcepcional" class="col-md-6">
                                    <mat-checkbox [(ngModel)]="xPerjuicioExcepcional">
                                        Perjuicio excepcional
                                    </mat-checkbox>
                                </div>
                            </div>
        
                            <div class="row mt-1 mb-0 mx-0">
                                <div id="xGastosPrevisibles" class="col-md-6">
                                    <mat-checkbox [(ngModel)]="xGastosPrevisibles">
                                        Gastos previsibles de asistencia sanitaria futura
                                    </mat-checkbox>
                                </div>
        
                                <div id="xProtesisOrtesis" class="col-md-6">
                                    <mat-checkbox [(ngModel)]="xProtesisOrtesis">
                                        Prótesis y órtesis
                                    </mat-checkbox>
                                </div>
                            </div>
        
                            <div class="row mt-1 mb-0 mx-0">
                                <div id="xRehabilitacion" class="col-md-6">
                                    <mat-checkbox [(ngModel)]="xRehabilitacion">
                                        Rehabilitación domiciliaria y ambulatoria
                                    </mat-checkbox>
                                </div>
        
                                <div id="xAyudasTecnicas" class="col-md-6">
                                    <mat-checkbox [(ngModel)]="xAyudasTecnicas">
                                        Ayudas técnicas o productos de apoyo para la autonomía personal
                                    </mat-checkbox>
                                </div>
                            </div>
        
                            <div class="row mt-1 mb-0 mx-0">
                                <div id="xAdecuacion" class="col-md-6">
                                    <mat-checkbox [(ngModel)]="xAdecuacion">
                                        Adecuación de vivienda
                                    </mat-checkbox>
                                </div>
        
                                <div id="xPerjuicioPatrimonial" class="col-md-6">
                                    <mat-checkbox [(ngModel)]="xPerjuicioPatrimonial">
                                        Perjuicio patrimonial por el incremento de costes de movilidad
                                    </mat-checkbox>
                                </div>
                            </div>
        
                            <div class="row mt-1 mb-0 mx-0">
                                <div id="xHorasNecesarias" class="col-md-6">
                                    <mat-checkbox [(ngModel)]="xHorasNecesarias">
                                        Número de horas necesarias de ayuda de tercera persona
                                    </mat-checkbox>
                                </div>
                            </div>
        
                            <div class="row mt-1 mb-0 mx-0" *ngIf="xHorasNecesarias == true">
                                <div id="horasNecesarias" class="col-md-4">
                                    <mat-form-field class="input_informe_75" appearance="outline">
                                        <mat-label>Horas necesarias</mat-label>
                                        <input matInput [(ngModel)]="horasNecesarias" placeholder="Horas necesarias">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
        
                        <div class="row m-0 mb-3" *ngIf="encargo.nombre_tipo_encargo == 'SEGUIMIENTO AUTOS' || encargo.nombre_tipo_encargo == 'ESTUDIO DOCUMENTAL'">
                            <h4>Observaciones a la compañía</h4>
                            <div class="col-md-12">
                                <mat-form-field style="width: 92%;" appearance="outline">
                                    <mat-label>Observaciones a la compañía</mat-label>
                                    <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="observaciones" placeholder="Observaciones a la compañía"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row m-0 mb-3" *ngIf="encargo.nombre_tipo_encargo != 'SEGUIMIENTO AUTOS' && encargo.nombre_tipo_encargo != 'ESTUDIO DOCUMENTAL'">
                            <h4>Observaciones</h4>
                            <div class="col-md-12">
                                <mat-form-field style="width: 92%;" appearance="outline">
                                    <mat-label>Observaciones</mat-label>
                                    <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="observaciones" placeholder="Observaciones"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="encargo.nombre_tipo_encargo == 'SEGUIMIENTO DIVERSOS'">
                    <div class="row m-0">
                        <h2>Consultas Evolutivas Realizadas</h2>
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Evolutivo Consultas valoración</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="evolutivoConsultasValoracion" placeholder="Evolutivo consultas valoración"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                

                <!-- <div *ngIf="idTipo == 6 || idTipo == 7 || idTipo == 10" class="row m-0 mb-3">
                    <h4>Observaciones hacia la aseguradora (Carácter INTERNO)</h4>
                    <div class="col-md-12">
                        <mat-form-field style="width: 92%;" appearance="outline">
                            <mat-label>Observaciones hacia la aseguradora</mat-label>
                            <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="observacionesAseguradora" placeholder="Observaciones Aseguradora"></textarea>
                        </mat-form-field>
                    </div>
                </div> -->

                
                
                <div class="row m-0 mb-3" *ngIf="encargo.nombre_tipo_encargo == 'INFORME COHERENCIA'">
                    <h4>Observaciones</h4>
                    <div class="col-md-12">
                        <mat-form-field style="width: 92%;" appearance="outline">
                            <mat-label>Observaciones</mat-label>
                            <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="observaciones" placeholder="Observaciones"></textarea>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Consideraciones Medicas -->
                <div *ngIf="encargo.nombre_tipo_encargo != 'VALORACIÓN VIDEO-CONSULTA' && encargo.nombre_tipo_encargo != 'VALORACIÓN BASE DOCUMENTAL' && encargo.nombre_tipo_encargo != 'ALTA+IAE BASE DOCUMENTAL' && encargo.nombre_tipo_encargo != 'ALTA+IAE MUTUA LABORAL' && encargo.nombre_tipo_encargo != 'VALORACIÓN VIDEO-CONSULTA-MUTUAS' && encargo.nombre_tipo_encargo != 'SEGUIMIENTO DIVERSOS' && encargo.nombre_tipo_encargo != 'ASESORÍA GRAVES' && encargo.nombre_tipo_encargo != 'REHUSE CAS + VIDEO' && encargo.nombre_tipo_encargo != 'ESTUDIO DOCUMENTAL' && encargo.nombre_tipo_encargo != 'SEGUIMIENTO AUTOS' && encargo.nombre_tipo_encargo != 'SEGUIMIENTO NO AUTOS' && encargo.nombre_tipo_encargo != 'INFORME COHERENCIA'">
                    <div class="row m-0">
                        <h2>Consideraciones Médicas</h2>
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Consideración medica-legales</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="4" cdkAutosizeMaxRows="20" matInput [(ngModel)]="consideracion" placeholder="Consideracion medica-legales"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
    
                    <div class="row m-0 mb-3">
                        <div class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Conclusiones</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="conclusiones" placeholder="Conclusiones"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <!-- Seguimiento Diversos -->
                <!-- Valoracion -->
                <div *ngIf="encargo.nombre_tipo_encargo == 'SEGUIMIENTO DIVERSOS' || encargo.nombre_tipo_encargo == 'SEGUIMIENTO NO AUTOS' ">
                    <div class="row m-0">
                        <h2>Valoración del daño corporal</h2>
                        <div id="observaciones" class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Observaciones</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="observaciones" placeholder="Observaciones" (keyup)="keyupClaseAnterior($event.target.value, 'observaciones', encargoAnterior.enup_observaciones)"></textarea>
                            </mat-form-field>
                        </div>
                        
                        <div id="conclusiones" class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Conclusiones</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="conclusiones" placeholder="Conclusiones" (keyup)="keyupClaseAnterior($event.target.value, 'conclusiones', encargoAnterior.enup_conclusiones)"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
    
                    <div class="row m-0 mb-3">
                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Fecha próxima consulta</mat-label>
                                <input matInput [(ngModel)]="fechaProximaConsulta" placeholder="Fecha próxima consulta">
                            </mat-form-field>
                        </div>

                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Situación de incapacidad</mat-label>
                                <input matInput [(ngModel)]="situacionIncapacidad" placeholder="Situación de incapacidad">
                            </mat-form-field>
                        </div>

                        <div class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Tiempo previsto de recuperación</mat-label>
                                <input matInput [(ngModel)]="tiempoRecuperacion" placeholder="Tiempo previsto de recuperación(días)">
                            </mat-form-field>
                        </div>
                    </div>

                </div>

                <!-- Asesoría Graves -->
                <!-- ASESORÍA MÉDICO-LEGAL -->
                <div *ngIf="encargo.nombre_tipo_encargo == 'ASESORÍA GRAVES'">
                    <div class="row m-0">
                        <h2>Asesoría médico-legal</h2>
                        <div id="numInforme" class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Nº de Informe</mat-label>
                                <input matInput [(ngModel)]="numInforme" placeholder="Nº de informe">
                            </mat-form-field>
                        </div>
                        
                        <div id="fechaAsesoria" class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Fecha Asesoría</mat-label>
                                <input matInput [(ngModel)]="fechaAsesoria" placeholder="Fecha de la Asesoría">
                            </mat-form-field>
                        </div>

                        <div id="asesorMedico" class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Asesor Médico</mat-label>
                                <input matInput [(ngModel)]="asesorMedico" placeholder="Asesor médico">
                            </mat-form-field>
                        </div>
                    </div>
    
                    <div class="row m-0 mb-3">
                        <div id="observacionesAsesoria" class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Observaciones Asesoría</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="observacionesAsesoria" placeholder="Observaciones asesoría"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0 mb-3">
                        <div id="proximaRevision" class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>¿Proxima Revision?</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="proximaRevision" placeholder="¿Proxima Revision?"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0" *ngFor="let encargoAnteriorAsesoria of encargosAnterioresAsesoriaGraves">
                        <div class="col-md-12" style="width: 92%; margin-bottom: 20px;">
                            <mat-accordion>
                                <mat-expansion-panel style="background-color: #ebebeb;">
                                    <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Asesoría médico-legal Anterior {{encargoAnteriorAsesoria.id_plataforma}}
                                    </mat-panel-title>
                                    <mat-panel-description>
                                        Fecha de asesoria: {{encargoAnteriorAsesoria.enup_fecha_asesoria}}
                                    </mat-panel-description>
                                    </mat-expansion-panel-header>

                                    <div class="row m-0">
                                        <h2>Asesoría médico-legal</h2>
                                        <div id="numInforme" class="col-md-4">
                                            <mat-form-field class="input_informe_75" appearance="fill">
                                                <mat-label>Nº de Informe</mat-label>
                                                <input matInput placeholder="Nº de informe" value="{{encargoAnteriorAsesoria.enup_num_informe}}" readonly>
                                            </mat-form-field>
                                        </div>
                                        
                                        <div id="fechaAsesoria" class="col-md-4">
                                            <mat-form-field class="input_informe_75" appearance="fill">
                                                <mat-label>Fecha Asesoría</mat-label>
                                                <input matInput placeholder="Fecha de la Asesoría" value="{{encargoAnteriorAsesoria.enup_fecha_asesoria}}" readonly>
                                            </mat-form-field>
                                        </div>
                
                                        <div id="asesorMedico" class="col-md-4">
                                            <mat-form-field class="input_informe_75" appearance="fill">
                                                <mat-label>Asesor Médico</mat-label>
                                                <input matInput placeholder="Asesor médico" value="{{encargoAnteriorAsesoria.enup_asesor_medico}}" readonly>
                                            </mat-form-field>
                                        </div>
                                    </div>
                    
                                    <div class="row m-0 mb-3">
                                        <div id="observacionesAsesoria" class="col-md-12">
                                            <mat-form-field style="width: 92%;" appearance="fill">
                                                <mat-label>Observaciones Asesoría</mat-label>
                                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput placeholder="Observaciones asesoría" value="{{encargoAnteriorAsesoria.enup_observaciones_asesoria}}" readonly></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="row m-0 mb-3">
                                        <div id="proximaRevision" class="col-md-12">
                                            <mat-form-field style="width: 92%;" appearance="fill">
                                                <mat-label>¿Proxima Revision?</mat-label>
                                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput placeholder="¿Proxima Revision?" value="{{encargoAnteriorAsesoria.enup_proxima_revision}}" readonly></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="tabcontent reservaTecnica noshow" id="reservaTecnica" *ngIf="reservaTecnica" >
            <div class="container-fluid">
                <div class="row" style="margin-top: 20px;">
                    <div class="col-md-12">
                        <h1>Provisión / Reserva Técnica</h1>
                    </div>
                </div>

                <div *ngIf="idTipo == 6 || idTipo == 7  || idTipo == 5  || idTipo == 8  || idTipo == 10 || idTipo == 11  || idTipo == 13 || idTipo == 15 || idTipo == 17" class="row m-0">
                    <h2>Valoración</h2>
                    <h4>Lesiones temporales</h4>
                    <div id="diasBasicosReserva" class="col-md-3">
                        <mat-form-field class="input_informe_67" appearance="outline">
                            <mat-label>Días PPP Básicos</mat-label>
                            <input matInput [(ngModel)]="dias_ppp_basicos_reserva" (change)="sumarDiasPPP()" placeholder="Días PPP Básicos" (keyup)="keyupClaseAnterior($event.target.value, 'dias_ppp_basicos_reserva', encargoAnterior.enup_dias_ppp_basicos_reserva)" type="number" min="0">
                        </mat-form-field>
                    </div>
                    <div id="diasModeradosReserva" class="col-md-3">
                        <mat-form-field class="input_informe_67" appearance="outline">
                            <mat-label>Días PPP Moderados</mat-label>
                            <input matInput [(ngModel)]="dias_ppp_moderados_reserva" (change)="sumarDiasPPP()" placeholder="Días PPP Moderados" (keyup)="keyupClaseAnterior($event.target.value, 'dias_ppp_moderados_reserva', encargoAnterior.enup_dias_ppp_moderados_reserva)" type="number" min="0">
                        </mat-form-field>
                    </div>
                    <div id="diasGravesReserva" class="col-md-3">
                        <mat-form-field class="input_informe_67" appearance="outline">
                            <mat-label>Días PPP Graves</mat-label>
                            <input matInput [(ngModel)]="dias_ppp_graves_reserva" (change)="sumarDiasPPP()" placeholder="Días PPP Graves" (keyup)="keyupClaseAnterior($event.target.value, 'dias_ppp_graves_reserva', encargoAnterior.enup_dias_ppp_graves)" type="number" min="0">
                        </mat-form-field>
                    </div>
                    <div id="diasMuyGravesReserva" class="col-md-3">
                        <mat-form-field class="input_informe_67" appearance="outline">
                            <mat-label>Días PPP Muy Graves</mat-label>
                            <input matInput [(ngModel)]="dias_ppp_muy_graves_reserva" (change)="sumarDiasPPP()" placeholder="Días PPP Muy Graves" (keyup)="keyupClaseAnterior($event.target.value, 'diasMuyGravesReserva', encargoAnterior.enup_dias_ppp_muy_graves)" type="number" min="0">
                        </mat-form-field>
                    </div>
                </div>

                <div id="diasTotalesReserva" *ngIf="idTipo == 6 || idTipo == 7  || idTipo == 5  || idTipo == 8  || idTipo == 10 || idTipo == 11  || idTipo == 13 || idTipo == 15 || idTipo == 17" class="row m-0">
                    <div id="dias_ppp_totales_reserva" class="col-md-3">
                        <mat-form-field class="input_informe_67" appearance="outline">
                            <mat-label>Dias totales</mat-label>
                            <input matInput disabled [(ngModel)]="dias_ppp_totales_reserva" placeholder="Dias totales">
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="idTipo == 6 || idTipo == 7 || idTipo == 5  || idTipo == 8  || idTipo == 10 || idTipo == 11  || idTipo == 13 || idTipo == 15" class="row m-0" [formGroup]="secuelaReservaForm">
                    <div formArrayName="secuelas">
                        <h4>Secuelas<button style="margin-left: 20px;" (click)="addNuevasSecuelasReserva()" mat-stroked-button>+</button></h4>

                        <div class="row m-0" *ngFor="let item of secuelasReserva().controls; let i=index" [formGroupName]="i">
                            <div id="codigoSecuelaReserva" class="col-md-2" [ngClass]="{anterior: addClassSecuelasAntiguasReserva}">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>Código</mat-label>
                                    <input matInput formControlName="codigo" placeholder="Código">
                                </mat-form-field>
                            </div>

                            <div id="descripcionSecuelaReserva" class="col-md-7" [ngClass]="{anterior: addClassSecuelasAntiguasReserva}">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>Descripción secuela</mat-label>
                                    <input matInput formControlName="descripcion" placeholder="Descripción secuela">
                                </mat-form-field>
                            </div>

                            <div id="puntosSecuelaReserva" class="col-md-2" [ngClass]="{anterior: addClassSecuelasAntiguasReserva}">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>Puntos</mat-label>
                                    <input matInput formControlName="puntos" placeholder="Puntos">
                                </mat-form-field>
                            </div>

                            <div class="col-md-1">
                                <button style="margin-top: 10px;" (click)="quitarSecuelaReserva(i)" mat-flat-button color="warn">-</button>
                            </div>
                        </div>

                    </div>
                </div>

                <div *ngIf="(idTipo == 6 || idTipo == 7 || idTipo == 5 || idTipo == 8 || idTipo == 10 || idTipo == 11  || idTipo == 13 || idTipo == 15 || idTipo == 17) && numSecuelasReserva > 0"class="row m-0">
                    <div class="col-md-2">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Total Puntos Secuelas</mat-label>
                            <input matInput disabled [(ngModel)]="puntosTotalesSecuelasReserva" placeholder="Total Puntos Secuelas">
                        </mat-form-field>
                    </div>
                </div>


                <div *ngIf="idTipo == 6 || idTipo == 7 || idTipo == 5 || idTipo == 8 || idTipo == 10 || idTipo == 11  || idTipo == 13 || idTipo == 15 || idTipo == 17" class="row m-0">
                    <h4>Perjuicio estético</h4>
                    <div id="descripcionPerjuicioEsteticoReserva" class="col-md-8">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Descripcion Perjuicio estético</mat-label>
                            <input matInput [(ngModel)]="descripcion_perjuicio_estetico_reserva" placeholder="Descripcion Perjuicio estético" (keyup)="keyupClaseAnterior($event.target.value, 'descripcion_perjuicio_estetico_reserva', encargoAnterior.enup_descripcion_perjuicio_estetico)">
                        </mat-form-field>
                    </div>
                    <div id="puntosPerjuicioEsteticoReserva" class="col-md-2">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Puntos</mat-label>
                            <input matInput [(ngModel)]="puntos_perjuicio_estetico_reserva" placeholder="Puntos" (keyup)="keyupClaseAnterior($event.target.value, 'puntos_perjuicio_estetico_reserva', encargoAnterior.enup_puntos_perjuicio_estetico)">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row m-0">
                    <h4>Correctores</h4>
                    <div id="perjuicioMoralReserva" class="col-md-4">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Perjuicio moral por pérdida de calidad de vida</mat-label>
                            <mat-select [(ngModel)]="perjuicio_moral_reserva" (selectionChange)="onChangeLimpiarMoralReserva()">
                                <mat-option value="No">No</mat-option>
                                <mat-option value="Si">Si</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div id="gradoReserva" *ngIf="perjuicio_moral_reserva == 'Si'" class="col-md-4">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Grado</mat-label>
                            <input matInput [(ngModel)]="grado_reserva" placeholder="Grado" (keyup)="keyupClaseAnterior($event.target.value, 'grado_reserva', encargoAnterior.enup_grado)">
                        </mat-form-field>
                    </div>
                    <div id="porcentajeGradoReserva" *ngIf="perjuicio_moral_reserva == 'Si'" class="col-md-4">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>%</mat-label>
                            <input matInput [(ngModel)]="porcentaje_reserva" placeholder="Porcentaje" (keyup)="keyupClaseAnterior($event.target.value, 'porcentaje_reserva', encargoAnterior.enup_porcentaje)">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row m-0">
                    <div id="perjuicioPersonalReserva" class="col-md-4">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Perjuicio personal particular por intervención quirúrgica</mat-label>
                            <mat-select [(ngModel)]="perjuicio_personal_reserva" (selectionChange)="onChangeLimpiarPersonalReserva()">
                                <mat-option value="No">No</mat-option>
                                <mat-option value="Si">Si</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- TODO: Grupo quirúrgico -->
                    <!-- <div id="grupoQuirurgico" *ngIf="perjuicio_personal_reserva == 'Si'" class="col-md-4">
                        <mat-form-field class="input_informe_75" appearance="outline">
                            <mat-label>Grupo Quirúrgico</mat-label>
                            <input matInput [(ngModel)]="grupoQuirurgicoReserva" placeholder="Grupo Quirúrgico" (keyup)="keyupClaseAnterior($event.target.value, 'grupoQuirurgicoReserva', encargoAnterior.enup_grupo_quirurgico)">
                        </mat-form-field>
                    </div> -->
                    <div *ngIf="perjuicio_personal_reserva == 'Si'" [formGroup]="intervencionReservaForm">
                        <div formArrayName="intervenciones">
                            <h4>Intervenciones<button style="margin-left: 20px;" (click)="addNuevasIntervencionesReserva()" mat-stroked-button>+</button></h4>

                            <div class="row m-0" *ngFor="let item of intervencionesReserva().controls; let i=index" [formGroupName]="i">
                                <div id="grupo" class="col-md-2" [ngClass]="{anterior: addClassIntervencionesAntiguasReserva}">
                                    <mat-form-field class="input_informe_75" appearance="outline">
                                        <mat-label>Grupo</mat-label>
                                        <input matInput formControlName="grupo" placeholder="Código">
                                    </mat-form-field>
                                </div>
    
                                <div id="descripcionIntervencion" class="col-md-7" [ngClass]="{anterior: addClassIntervencionesAntiguasReserva}">
                                    <mat-form-field class="input_informe_75" appearance="outline">
                                        <mat-label>Descripción</mat-label>
                                        <input matInput formControlName="descripcion" placeholder="Descripción">
                                    </mat-form-field>
                                </div>
    
                                <div class="col-md-1">
                                    <button style="margin-top: 10px;" (click)="quitarIntervencionReserva(i)" mat-flat-button color="warn">-</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div *ngIf="encargo.nombre_tipo_encargo != 'REHUSE CAS' && encargo.nombre_tipo_encargo != 'REHUSE CAS + VIDEO' && encargo.nombre_tipo_encargo != 'REHUSE CAS BIO' && encargo.nombre_tipo_encargo != 'ESTUDIO DOCUMENTAL ASESORÍA'">
                    <div class="row m-0">
                        <div id="otrosCorrectoresReserva" class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>¿Otros correctores? (Artículos 110 al 123 si necesario)</mat-label>
                                <mat-select [(ngModel)]="otros_correctores_reserva">
                                    <mat-option value="No">No</mat-option>
                                    <mat-option value="Si">Si</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div *ngIf="otros_correctores_reserva == 'Si'">
                        <div class="row mt-2 mb-0 mx-0">
                            <div id="xDanoMoralReserva" class="col-md-6">
                                <mat-checkbox [(ngModel)]="x_dano_moral_reserva">
                                    Daños morales complementarios por perjuicio psicofísico, orgánico y sensorial
                                </mat-checkbox>
                            </div>

                            <div id="xDanoMoralEsteticoReserva" class="col-md-6">
                                <mat-checkbox [(ngModel)]="x_dano_moral_estetico_reserva">
                                    Daños morales complementarios por perjuicio estético
                                </mat-checkbox>
                            </div>
                        </div>

                        <div class="row mt-1 mb-0 mx-0">
                            <div id="xPerjuicioMoralReserva" class="col-md-6">
                                <mat-checkbox [(ngModel)]="x_perjuicio_moral_reserva">
                                    Perjuicio moral por pérdida de calidad de vida de familiares de grandes lesionados
                                </mat-checkbox>
                            </div>

                            <div id="xPerjuicioExcepcionalReserva" class="col-md-6">
                                <mat-checkbox [(ngModel)]="x_perjuicio_excepcional_reserva">
                                    Perjuicio excepcional
                                </mat-checkbox>
                            </div>
                        </div>

                        <div class="row mt-1 mb-0 mx-0">
                            <div id="xGastosPrevisiblesReserva" class="col-md-6">
                                <mat-checkbox [(ngModel)]="x_gastos_previsibles_reserva">
                                    Gastos previsibles de asistencia sanitaria futura
                                </mat-checkbox>
                            </div>

                            <div id="xProtesisOrtesisReserva" class="col-md-6">
                                <mat-checkbox [(ngModel)]="x_protesis_ortesis_reserva">
                                    Prótesis y órtesis
                                </mat-checkbox>
                            </div>
                        </div>

                        <div class="row mt-1 mb-0 mx-0">
                            <div id="xRehabilitacionReserva" class="col-md-6">
                                <mat-checkbox [(ngModel)]="x_rehabilitacion_reserva">
                                    Rehabilitación domiciliaria y ambulatoria
                                </mat-checkbox>
                            </div>

                            <div id="xAyudasTecnicasReserva" class="col-md-6">
                                <mat-checkbox [(ngModel)]="x_ayudas_tecnicas_reserva">
                                    Ayudas técnicas o productos de apoyo para la autonomía personal
                                </mat-checkbox>
                            </div>
                        </div>

                        <div class="row mt-1 mb-0 mx-0">
                            <div id="xAdecuacionReserva" class="col-md-6">
                                <mat-checkbox [(ngModel)]="x_adecuacion_reserva">
                                    Adecuación de vivienda
                                </mat-checkbox>
                            </div>

                            <div id="xPerjuicioPatrimonialReserva" class="col-md-6">
                                <mat-checkbox [(ngModel)]="x_perjuicio_patrimonial_reserva">
                                    Perjuicio patrimonial por el incremento de costes de movilidad
                                </mat-checkbox>
                            </div>
                        </div>

                        <div class="row mt-1 mb-0 mx-0">
                            <div id="xHorasNecesariasReserva" class="col-md-6">
                                <mat-checkbox [(ngModel)]="x_horas_necesarias_reserva">
                                    Número de horas necesarias de ayuda de tercera persona
                                </mat-checkbox>
                            </div>
                        </div>

                        <div class="row mt-1 mb-0 mx-0" *ngIf="xHorasNecesarias == true">
                            <div id="horasNecesariasReserva" class="col-md-4">
                                <mat-form-field class="input_informe_75" appearance="outline">
                                    <mat-label>Horas necesarias</mat-label>
                                    <input matInput [(ngModel)]="horas_necesarias_reserva" placeholder="Horas necesarias" (keyup)="keyupClaseAnterior($event.target.value, 'horas_necesarias_reserva', encargoAnterior.enup_horas_necesarias)">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="row mx-0 mb-3 mt-3">
                        <h4>Observaciones</h4>
                        <div id="observacionesReserva" class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Observaciones</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="observaciones_reserva" placeholder="ObservacionesReserva" (keyup)="keyupClaseAnterior($event.target.value, 'observaciones_reserva', encargoAnterior.enup_observaciones)"></textarea>
                            </mat-form-field>
                        </div>
                    </div>    
                </div>            
            </div>
        </div>

        <!-- Documentacion -->
        <div class="tabcontent documentacion noshow" id="documentacion" >
            <div class="container-fluid" style="width: 95%;">
                <div class="row" style="margin-top: 20px;">
                    <div class="col-md-12">
                        <h1>Documentación</h1>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="width: 92%;">
                <div>
                    <app-file-manager-medico [fileElements]="archivos | async" [path]="currentPath" [canNavigateUp]="canNavigateUp"
                        (navigatedDown)="navigateToFolder($event)" (navigatedUp)="navigateUp()"
                        (openModalView)="openModalFile($event)">
                    </app-file-manager-medico>
                </div>
            </div>
        </div>

        <!-- Mensajes -->
        <div class="tabcontent mensajes noshow" id="mensajes" >
            <div class="container-fluid" style="width: 95%;">
                <div class="row" style="margin-top: 20px;">
                    <div class="col-md-12">
                        <h1>Mensajes</h1>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="width: 85%;">
                

                <div *ngIf="mensajes.length != 0" class="row" style="border: 2px solid black; max-height: 400px; overflow-y: scroll;" id="divMensajes">
                    <div class="col-md-12">
                        <div *ngFor="let mensaje of mensajes" class="row">
                            <div class="col-md-6">
                                <mat-card *ngIf="mensaje.tipoUsuario == 1" style="background-color: #B4DBCD; margin: 10px 0;">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <h4 style="font-weight: 500;">{{mensaje.nombreUsuario}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <h5 class="h5_mensajes">{{mensaje.fechaCreacion}}</h5>
                                        </div>
                                        <div class="col-md-8">
                                            <h5 class="h5_mensajes">{{mensaje.msj_texto}}</h5>
                                        </div>
                                    </div>
                                </mat-card>
                            </div>
                            <div class="col-md-6">
                                <mat-card *ngIf="mensaje.tipoUsuario == 0" style="background-color: grey; margin: 10px 0;">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <h4 style="font-weight: 500;">{{mensaje.nombreUsuario}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <h5 class="h5_mensajes">{{mensaje.fechaCreacion}}</h5>
                                        </div>
                                        <div class="col-md-8">
                                            <h5 class="h5_mensajes">{{mensaje.msj_texto}}</h5>
                                        </div>
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <!-- Boton mensajes leidos -->
                <div *ngIf="mensajes.length != 0" class="row" style="margin-top: 20px;">
                    <div class="col-md-2">
                        <button mat-stroked-button color="warn" (click)="mensajesLeidos()">Mensajes Leidos</button>
                    </div>
                </div>
                
                <div class="row" style="margin-top: 40px;">
                    <mat-divider class="separador_mensajes"></mat-divider>
                </div>

                <!-- Crear nuevo mensaje -->
                <div class="row" style="margin-top: 40px;">
                    <div class="col-md-12">
                        <mat-form-field appearance="outline" class="textarea_mensaje">
                            <mat-label>Crear nuevo mensaje</mat-label>
                            <textarea matInput placeholder="Texto del mensaje" [(ngModel)]="textoMensaje"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 80px;">
                    <div class="col-md-12">
                        <button mat-stroked-button class="boton_enviar_mensaje" (click)="enviarMensajeEncargo()">Enviar</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Histórico del siniestro -->
        <div class="tabcontent historico noshow" id="historico" >
            <div class="container-fluid" style="width: 95%;">
                <div class="row" style="margin-top: 20px;">
                    <div class="col-md-12">
                        <h1>Histórico del siniestro</h1>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="width: 85%;">
                <div *ngFor="let encargoBySiniestro of encargosBySiniestro" class="row fila_encargo_siniestro" (click)="abrirEncargoSiniestro(encargoBySiniestro.id)">
                    <div class="col-md-12">
                        <div class="row" style="margin-top: 10px;margin-bottom: 5px;">
                            <div class="col-md-2">
                                <div>
                                    <i class="bi bi-circle-fill icon_size" [ngClass]="colorEncargo(encargoBySiniestro.enup_fecha_limite)"></i>
                                    <h3 class="text_inline_icon_encargo">{{encargoBySiniestro.id}} Nº de encargo</h3>
                                </div>
                                <div>
                                    <i class="bi bi-chat-left-text-fill icon_size"><h3 class="text_inline_icon_mensaje">{{encargoBySiniestro.mensajes_count}} Mensaje</h3></i>
                                </div>
                            </div>
            
                            <div class="col-md-2">
                                <div class="row">
                                    <div class="col-md-8">
                                        <h5 class="m-0 pt-3 subtitulo_h5">Fecha encargo</h5>
                                        <h4 class="subtitulo_h4">{{encargoBySiniestro.enup_fecha_tarea}}</h4>
                                    </div>
                                    <div class="col-md-2">
                                        <mat-divider [vertical] ="true" class="mt-3" style="height:50px"></mat-divider>
                                    </div>
                                    <div class="col-md-2">
                                    
                                    </div>
                                </div>
                            </div>
                
                            <div class="col-md-2">
                                <h5 class="m-0 pt-3 subtitulo_h5">Estado</h5>
                                <h4 class="subtitulo_h4">{{encargoBySiniestro.enup_estado_encargo}}</h4>
                            </div>
                
                            <div class="col-md-2">
                                <div class="row">
                                    <div class="col-md-8">
                                        <h5 class="m-0 pt-3 subtitulo_h5">Tipo de encargo</h5>
                                        <h4 class="subtitulo_h4">{{encargoBySiniestro.nombre_tipo_encargo}}</h4>
                                    </div>
                                    <div class="col-md-2">
                                        <mat-divider [vertical] ="true" class="mt-3" style="height:50px"></mat-divider>
                                    </div>
                                    <div class="col-md-2">
                                    
                                    </div>
                                </div>
                            </div>
                
                            <div class="col-md-2">
                                <div class="row">
                                    <div class="col-md-8">
                                        <h5 class="m-0 pt-3 subtitulo_h5">Fecha Limite</h5>
                                        <h4 class="subtitulo_h4">{{encargoBySiniestro.enup_fecha_limite}}</h4>
                                    </div>
                                    <div class="col-md-2">
                                        <mat-divider [vertical] ="true" class="mt-3" style="height:50px"></mat-divider>
                                    </div>
                                    <div class="col-md-2">
                                    
                                    </div>
                                </div>
                            </div>
                
                            <div class="col-md-2">
                                <h5 class="m-0 pt-3 subtitulo_h5">Lesionado</h5>
                                <h4 class="subtitulo_h4">{{encargoBySiniestro.enup_lesionado}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- FAB -->
        <div class="fab_container guardar">
            <button mat-fab class="fab_datos" color="white" title="Guardar" aria-label="Guardar" (click)="guardarDatosEncargo('')">
                <mat-icon style="margin-top: -6px;">save</mat-icon>
            </button>
        </div>

        <div class="fab_container generarInforme">
            <button mat-fab class="fab_datos" color="white" title="Generar informe" aria-label="Generar informe" (click)="guardarDatosEncargo('informe')">
                <mat-icon style="margin-top: -6px;" style="color: #4b8f76;"><i></i>assignment</mat-icon>
            </button>
        </div>
        
        <!-- FAB -->
        <div *ngIf="mostrarRevisado" class="fab_container revisado">
            <button mat-fab class="fab_datos" color="white" title="Revisado" aria-label="Revisado" (click)="guardarDatosEncargo('revisado')">
                <mat-icon style="margin-top: -2px;">send</mat-icon>
            </button>
        </div>

        <!-- FAB -->
        <div class="fab_container subir">
            <button mat-fab class="fab_datos" color="white" title="Subir archivo" aria-label="Subir archivo" (click)="openModal('modalUploadFile', null)">
                <mat-icon style="margin-top: -2px;">add_circle</mat-icon>
            </button>
        </div>

        <div class="fab_container carpeta">
            <button mat-fab class="fab_datos" color="white" title="Descargar carpeta" aria-label="Descargar carpeta" (click)="guardarDatosEncargo('descargarDocMedico')">
                <mat-icon  style="color: #4b8f76;"><i></i>cloud_download</mat-icon>
            </button>
        </div>
    </div>
</div>

<!-- Modal Image -->
<div id="modalFile" class="modal-wrapper">
    <div class="modal">
        <div class="head">
            <a class="btn-close trigger" (click)="openModal('modalFile', null)">
                <mat-icon aria-hidden="true">close</mat-icon>
            </a>
        </div>
        <div class="content">
            <div class="good-job" *ngIf="imageSelect && tipoArchivo=='pdf'">
                <h5>PDF</h5>
                <ngx-extended-pdf-viewer [base64Src]="imageSelect" backgroundColor="#ffffff" [height]="'500px'"
                    [useBrowserLocale]="true" [handTool]="false" [showHandToolButton]="true">
                </ngx-extended-pdf-viewer>
            </div>
            <div class="good-job" *ngIf="imageSelect && tipoArchivo=='imagen'">
                <h5>Imagen</h5>
                <img height="500px" src="{{'data:image/jpg;base64,' + imageSelect}}">
            </div>
        </div>
    </div>
</div>

<!-- Modal File -->
<div id="modalUploadFile" class="modal-wrapper">
    <div class="modalFile">
        <div class="head">
            <a class="btn-close trigger" (click)="openModal('modalUploadFile', null)">
                <mat-icon aria-hidden="true">close</mat-icon>
            </a>
        </div>
        <div class="content">
            <div class="good-job">
                <h5>Subir archivo</h5>
                <form [formGroup]="formUploadFile" method="POST">
                    <div class="form-group">
                        <input type="file" id="archFile" (change)="onFileChange($event, 'archFile', false)"
                            #fileInput />
                    </div>
                </form>
                <button mat-fab class="fab_datos" color="white" title="Guardar" aria-label="Guardar" (click)="uploadFile()">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>