import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { EncargoComponent } from '../encargo/encargo.component';
import { MedicoPrincipalComponent } from '../medico-principal/medico-principal.component';
import { Encargo } from '../../models/encargo';
import { MedicoPortalService } from '../../services/medico-portal.service';
import { SessionService } from '../../services/session.service';
import { TramitadorPortalService } from '../../services/tramitador-portal.service';

@Component({
  selector: 'app-header-encargo-medico',
  templateUrl: './header-encargo-medico.component.html',
  styleUrls: ['./header-encargo-medico.component.scss']
})
export class HeaderEncargoMedicoComponent implements OnInit {
 
  nombreTramitador: string;
  countMensajes: number;
  idEncargo: string;
  idTipo: number;
  encargo : Encargo;

  constructor(private sessionService: SessionService, private tramitadorService: TramitadorPortalService, private title : Title,private route: ActivatedRoute,
    private medicoService : MedicoPortalService 
    ) {
    this.nombreTramitador = this.sessionService.getCurrentSession().medico.med_nombre;
    this.route.params.subscribe(params =>{
      this.idEncargo = params['idEncargo'];
      this.idTipo    = params['idTipo'];
      //this.ngOnInit();
    });
  }

  ngOnInit(): void {
    this.medicoService.getExpedienteById(this.idEncargo).subscribe(data => {
      if(data){
        this.encargo = data['data'][0];
        this.title.setTitle(this.encargo.enup_lesionado);
      }else{
        this.title.setTitle("CELSUS");
      }
    });
    this.tramitadorService.getCountMensajesSinLeerTramitador().subscribe(
      data => {
        if(data['response'] == "ok"){
          this.countMensajes = data['totalMensajes'];
        }else{

        }
      }
    );
  }


  logout(){
    const confirmed = confirm("Está a punto de cerrar sesión, y eso conlleva a cerrar sesión en TODAS las pestañas de CELSUS.");
    
    if(confirmed) {
      this.sessionService.logout();
    }
  }

}
