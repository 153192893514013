import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-finalizado-dialog',
  templateUrl: './finalizado-dialog.component.html',
  styleUrls: ['./finalizado-dialog.component.scss']
})
export class FinalizadoDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<FinalizadoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    data.moverEncargoFinalizado = false;
   }

  ngOnInit(): void {
  }

}
