<h1 mat-dialog-title>Generar remesa:</h1>

<div class="d-flex align-items-end overflow-auto">
    <div class="flex-grow-1 info-first-container px-3 py-2">
        <h5 class="m-0">Nº Remesa</h5>
        <h4 class="m-0">{{numRemesa}}</h4>
    </div>
    <div class="flex-grow-1 info-first-container px-3 py-2">
        <h5 class="m-0">Usuario</h5>
        <h4 class="m-0">{{usuario}}</h4>
    </div>
    <div class="flex-grow-1 info-first-container px-3 py-2">
        <h5 class="m-0">IBAN</h5>
        <h4 class="m-0">{{iban}}</h4>
    </div>
    <div class="flex-grow-1 info-first-container px-3 py-2">
        <h5 class="m-0">Estado</h5>
        <h4 class="m-0">{{estado}}</h4>
    </div>
</div>

<div class="d-flex align-items-end mb-4 overflow-auto">
    <div class="flex-grow-1 info-container px-3 py-2">
        <h5 class="m-0">Total preceptores</h5>
        <h4 class="m-0">{{totalPreceptores}}</h4>
    </div>
    <div class="flex-grow-1 info-container px-3 py-2">
        <h5 class="m-0">Total de facturas</h5>
        <h4 class="m-0">{{totalFacturas}}</h4>
    </div>
    <div class="flex-grow-1 info-container px-3 py-2">
        <h5 class="m-0">Total pagado</h5>
        <h4 class="m-0">{{totalPagado | number:'1.2-2'}}€</h4>
    </div>
</div>

<div mat-dialog-content>

    <div *ngIf="data.accion == 1">
        <mat-form-field appearance="outline">
            <mat-label>Fecha de pago</mat-label>
            <input matInput [matDatepicker]="fechaPagoDatePicker" [min]="minDate" [(ngModel)]="fechaPago" disabled>
            <mat-datepicker-toggle matSuffix [for]="fechaPagoDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #fechaPagoDatePicker disabled="false"></mat-datepicker>
        </mat-form-field>
    </div>

    <h3 class="mb-1">Pagos:</h3>
    <div class="border custom-height-300 overflow-auto">
        <table mat-table #tablePagos [dataSource]="dataSourcePagos" class="w-100 ">

            <ng-container matColumnDef="cif">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> CIF/NIF </th>
                <td class="p-2" mat-cell *matCellDef="let pago"> {{getNIF(pago)}} </td>
            </ng-container>
    
            <ng-container matColumnDef="nombre_proveedor">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Razón social </th>
                <td class="p-2" mat-cell *matCellDef="let pago"> {{getNombre(pago)}} </td>
            </ng-container>

            <ng-container matColumnDef="pag_iban_cuenta">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta IBAN </th>
                <td class="p-2" mat-cell *matCellDef="let pago"> {{pago.pag_iban_cuenta != null ? pago.pag_iban_cuenta : '--'}} </td>
            </ng-container>
    
            <ng-container matColumnDef="pag_concepto">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Concepto </th>
                <td class="p-2" mat-cell *matCellDef="let pago"> {{pago.pag_concepto != null ? pago.pag_concepto : '--'}} </td>
            </ng-container>
    
            <ng-container matColumnDef="pag_total_pagado">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Total pagado </th>
                <td class="p-2" mat-cell *matCellDef="let pago"> {{pago.pag_total_pagado != null ? (pago.pag_total_pagado | number:'1.2-2') : '--'}}€ </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumnsPagos" class="sticky-top"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsPagos;"></tr>
        </table>
    </div>

</div>
<div mat-dialog-actions class="justify-content-center">
    <button mat-flat-button color="primary" (click)="crearRemesa()" *ngIf="data.accion == 0">Guardar remesa</button>
    <button mat-flat-button color="primary" (click)="pagarRemesa()" *ngIf="data.accion == 1">Pagar</button>
    <button mat-flat-button color="warn" (click)="cancelarRemesa()" *ngIf="data.accion == 1">Cancelar</button>
    <button mat-flat-button color="primary" (click)="generarXMLRemesa()">Generar XML</button>
    <button mat-flat-button color="primary" (click)="generarExcel()">Generar Excel</button>
    <button mat-flat-button color="primary" (click)="generarInformeRemesa()">Generar PDF</button>
</div>