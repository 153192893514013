import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FacturasEmitidas } from 'src/app/facturacion/models/facturas-emitidas';
import { Incidencia } from 'src/app/facturacion/models/incidencia';
import { IncidenciaContabilidad } from 'src/app/facturacion/models/incidencia-contabilidad';
import { FacturacionServiceService } from 'src/app/facturacion/services/facturacion-service.service';
import { SessionService } from 'src/app/services/session.service';
import { IncidenciaFacturaRecibidaDialogComponent } from '../incidencia-factura-recibida-dialog/incidencia-factura-recibida-dialog.component';

@Component({
  selector: 'app-incidencia-factura-emitida-dialog',
  templateUrl: './incidencia-factura-emitida-dialog.component.html',
  styleUrls: ['./incidencia-factura-emitida-dialog.component.scss']
})
export class IncidenciaFacturaEmitidaDialogComponent implements OnInit {

  facturaEmitida: FacturasEmitidas;
  motivo: string = 'Cambio de importes';
  observaciones: string = '';

  constructor(
    public facturacionService: FacturacionServiceService, 
    private sessionService: SessionService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataIncidenciaEmitida,
    public dialogRef: MatDialogRef<IncidenciaFacturaRecibidaDialogComponent>,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.facturaEmitida = this.data.facturaEmitida;
  }

  crearIncidenciaContabilidad(){

    let incidencias: Incidencia[] = [];
    let incidenciasContabilidad: IncidenciaContabilidad[] = [];

    let incidencia: Incidencia = {
      inc_id_incidencias: undefined,
      inc_fecha_creacion: undefined,
      inc_fecha_modificacion: undefined,
      inc_usuario_creacion: this.sessionService.getCurrentSession().user.id.toString(),
      inc_estado: 'Pendiente',
      inc_tipo: 'Emitida',
      inc_usuario_asignado: '16',
      inc_descripcion: undefined,
      inc_fecha_finalizacion: undefined
    }

    let incidenciaContabilidad: IncidenciaContabilidad = {
      incc_id_incidencias_contabilidad: undefined,
      incc_fecha_creacion: undefined,
      incc_fecha_modificacion: undefined,
      incc_id_incidencia: undefined,
      incc_id_contablidad: this.facturaEmitida.facte_contabilidad,
      incc_id_factura: this.facturaEmitida.facte_id_facturas_emitidas,
      incc_comentario: this.observaciones,
      incc_motivo: this.motivo,
    }

    incidencias.push(incidencia);
    incidenciasContabilidad.push(incidenciaContabilidad);

    if(this.facturaEmitida.facte_estado_factura = 'EST-004'){
      let incidenciaCobro: Incidencia = {
        inc_id_incidencias: undefined,
        inc_fecha_creacion: undefined,
        inc_fecha_modificacion: undefined,
        inc_usuario_creacion: this.sessionService.getCurrentSession().user.id.toString(),
        inc_estado: 'Pendiente',
        inc_tipo: 'Cobro',
        inc_usuario_asignado: '16',
        inc_descripcion: undefined,
        inc_fecha_finalizacion: undefined
      }
  
      let incidenciaContabilidadCobro: IncidenciaContabilidad = {
        incc_id_incidencias_contabilidad: undefined,
        incc_fecha_creacion: undefined,
        incc_fecha_modificacion: undefined,
        incc_id_incidencia: undefined,
        incc_id_contablidad: this.facturaEmitida.facte_contabilidad,
        incc_id_factura: this.facturaEmitida.facte_id_facturas_emitidas,
        incc_comentario: this.observaciones,
        incc_motivo: this.motivo,
      }

      incidencias.push(incidenciaCobro);
      incidenciasContabilidad.push(incidenciaContabilidadCobro);

    }

    if(this.motivo != '' && this.observaciones != ''){
      //console.log("incidencias", incidencias);
      //console.log("incidencias Contablilidad",incidenciasContabilidad);
      this.facturacionService.crearIncidenciaContabilidadEmitida(incidencias, incidenciasContabilidad).subscribe({
        error: (err: any) => {
          console.log(err);
          this.snackBar.open("Error en el servidor", undefined, {
            duration: 3 * 1000,
          });
        },
        complete: () => {
          this.snackBar.open("Incidencia de contabilidad creada", undefined, {
            duration: 3 * 1000,
          });
          this.dialogRef.close();
        }
      }) 
    } 
    else{
      this.snackBar.open("Debe rellenar todos los campos", undefined, {
        duration: 3 * 1000,
      });
    }
    

  }

}

interface DialogDataIncidenciaEmitida{
  facturaEmitida: FacturasEmitidas
}
