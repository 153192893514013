<nav class="navbar navbar_primary">
    <span class="navbar-text navbar_primary_text">
        <h1 class="navbar_h" style="font-weight: 600;">Portal Médico</h1>
        <h2 class="navbar_h" style="padding-top: 6px;">{{nombreMedico}}</h2>
    </span>
    <span>
        <img src="../../assets/img/logoUpsan.jpg" alt="" style="width: 260px;">
    </span>
</nav>

