import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-otro-gasto-dialog',
  templateUrl: './otro-gasto-dialog.component.html',
  styleUrls: ['./otro-gasto-dialog.component.scss']
})
export class OtroGastoDialogComponent implements OnInit {

  concepto: string;
  iva: number;
  irpf: number;
  baseImponible: number;

  constructor(
    public dialogRef: MatDialogRef<OtroGastoDialogComponent>,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  doAction(){
    if(this.concepto != undefined && this.concepto != ""
            && this.iva != undefined
            && this.irpf != undefined
            && this.baseImponible != undefined){

        if((this.iva > 100 || this.iva < 0) || (this.irpf > 100 || this.irpf < 0)){
          this.snackBar.open('El iva e irpf debe estar entre 0 y 100', undefined, {
            duration: 3 * 1000,
          });
        }
        else if(this.baseImponible < 0){
          this.snackBar.open('La base imponible no puede ser negativa', undefined, {
            duration: 3 * 1000,
          });
        }
        else{
          this.dialogRef.close({concepto: this.concepto, iva: this.iva, irpf: this.irpf, baseImponible: this.baseImponible});
        }

    }
    else{
      this.snackBar.open('Debe rellenar todos los campos', undefined, {
        duration: 3 * 1000,
      });
    }
  }

}
