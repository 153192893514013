export class EncargoGenerali {
    id: number;
    id_plataforma: string;
    upgen_expediente : string;
    upgen_id_expediente : string;
    upgen_id_autorizacion : string;
    upgen_id_medico : number;
    nombreMedico : string;
    numColegiadoMedico : string;
    especialidadMedico : string;
    logrosMedico : string;
    upgen_mecanismo_produccion : string;
    upgen_id_tramitador : number;
    nombreTramitador : string;
    upgen_usuario_creacion : Date;
    upgen_usuario_modificacion : Date;
    upgen_fecha_tarea : Date;
    upgen_fecha_limite : string;
    upgen_fecha_pendiente_revision : Date;
    upgen_fecha_revisado : Date;
    upgen_prioridad : number;
    upgen_lesionado : string;
    upgen_edad_lesionado : number;
    upgen_telefono : string;
    upgen_referencia_siniestro : string;
    upgen_fecha_siniestro : string;
    upgen_id_estado_encargo : number;
    upgen_estado_encargo : string;
    upgen_comentario : string;
    upgen_id_tipo_encargo : number;
    nombre_tipo_encargo : string;
    upgen_condicion_lesionado : string;
    upgen_cia : string;
    ciaNombre : string;
    upgen_responsabilidad_aseguradora : string;
    upgen_peticion : string;
    upgen_provincias : string;
    upgen_localidad : string;
    upgen_forma_produccion : string;
    upgen_profesion : string;
    upgen_situacion_laboral : string;
    upgen_baja_laboral : string;
    upgen_contingencia : string;
    upgen_antecedentes_personales : string;
    upgen_centro_urgencias : string;
    upgen_cuando_acude : string;
    upgen_diagnostico_urgencias : string;
    upgen_centro_rehabilitacion : string;
    upgen_medico_seguimiento : string;
    upgen_inicio_tratamiento : string;
    upgen_alta_medica : string;
    upgen_num_consultas : string;
    upgen_num_sesiones : string;
    upgen_pruebas_realizadas : string;
    upgen_fuente_documental : string;
    upgen_num_referencia_informe : string;
    upgen_firmado_por : string;
    upgen_dias_ppp_basicos : string;
    upgen_dias_ppp_moderados : string;
    upgen_dias_ppp_graves : string;
    upgen_dias_ppp_muy_graves : string;
    upgen_dias_ppp_totales : string;
    upgen_dias_ppp_basicos_max : string;
    upgen_dias_ppp_moderados_max : string;
    upgen_dias_ppp_graves_max : string;
    upgen_dias_ppp_muy_graves_max : string;
    upgen_dias_ppp_totales_max : string;
    upgen_observaciones : string;
    upgen_secuelas : string;
    upgen_url_videoconferencia : string;
    upgen_estrella : boolean;
    upgen_fecha_baja_laboral : string;
    upgen_fecha_alta_laboral : string;
    upgen_hospitalizacion_urgencias : string;
    created_at : string;
    updated_at : string;
    formato_consulta : number;
    upgen_id_nombre_tramitador : number;
    upgen_estado_inicial : string;
    upgen_fecha_ultima_consulta : string;
    upgen_estado_final : string;
    upgen_bio_funcional : string;
    upgen_coherencia_seguimiento : string;
    upgen_fecha_prueba : string;
    upgen_consistencia_prueba : string;
    upgen_movilidad : string;
    upgen_secuelas_esteticas : string;
}