import { Component, OnInit } from '@angular/core';
import { MedicoPortalService } from '../../services/medico-portal.service';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-header-medico',
  templateUrl: './header-medico.component.html',
  styleUrls: ['./header-medico.component.scss']
})
export class HeaderMedicoComponent implements OnInit {

  countMensajes: number;
  nombreMedico: string;
  idMedico: number;

  constructor(private medicoService: MedicoPortalService, private sessionService: SessionService) {
    this.nombreMedico = this.sessionService.getCurrentSession().medico.med_nombre;
  }

  ngOnInit(): void {
    this.medicoService.getCountMensajesSinLeerMedicos().subscribe(
      data => {
        if(data['response'] == "ok"){
          this.countMensajes = data['totalMensajes'].length;
        }else{

        }
      }
    );
  }

  logout(){
    const confirmed = confirm("Está a punto de cerrar sesión, y eso conlleva a cerrar sesión en TODAS las pestañas de CELSUS.");
    
    if(confirmed) {
      this.sessionService.logout();
    }
  }

}
