import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DialogDataAction } from 'src/app/dialogs/dialog-utils/dialog-data-action';
import { RemesaDialogComponent } from 'src/app/dialogs/remesa-dialog/remesa-dialog.component';
import { User } from 'src/app/models/user';
import { EstadoRemesas } from '../../models/estado-remesas';
import { Remesa } from '../../models/remesa';
import { EstadosRemesas, FacturacionServiceService, Remesas, Users } from '../../services/facturacion-service.service';

@Component({
  selector: 'app-remesas',
  templateUrl: './remesas.component.html',
  styleUrls: ['./remesas.component.scss']
})
export class RemesasComponent implements OnInit {

  loadingPageFlag: boolean = false;

  remesas: Remesa[] = [];
  estadosRemesas: EstadoRemesas[] = [];
  usuarios: User[] = [];

  @ViewChild('tableRemesas')
  remesasTable: MatTable<Remesa>;
  displayedColumnsRemesas: string[] = [
    'rem_id_remesas', 
    'rem_fecha_creacion', 
    'rem_fecha_modificacion', 
    'rem_estado', 
    'rem_fecha', 
    'rem_fecha_pago',
    'rem_fecha_cancelada',
    'rem_iban',
    'rem_estado_remesa',
    'rem_perceptores',
    'rem_cantidad_facturas',
    'rem_total_remesa',
    'rem_usuario_genera',
    'rem_usuario_pagada',
    'rem_usuario_cancelada'
  ];
  dataSourceRemesas: MatTableDataSource<Remesa> = new MatTableDataSource(this.remesas);

  constructor(
    public facturacionService: FacturacionServiceService, 
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.obtenerEstadosRemesas()
  }

  obtenerRemesas(){
    this.facturacionService.obtenerRemesas().subscribe({
      next: (data: Remesas) => {
        this.remesas = data.data;
        this.dataSourceRemesas.data = this.remesas;
        this.loadingPageFlag = false;
      },
      error: (err: any) => {
        console.log(err);
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
        this.loadingPageFlag = false;
      }
    })
  }

  obtenerEstadosRemesas(){
    this.loadingPageFlag = true;
    this.facturacionService.obtenerEstadosRemesas().subscribe({
      next: (data: EstadosRemesas) => {
        //console.log(data);
        this.estadosRemesas = data.data;
        this.obtenerUsuarios()
      },
      error: (err: any) => {
        console.log(err);
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
      }
    })
  }

  obtenerUsuarios(){
    this.facturacionService.obtenerUsuariosFacturacion().subscribe({
      next: (data: Users) => {
        this.usuarios = data.data;
        this.obtenerRemesas()
      },
      error: (err: any) => {
        console.log(err);
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
      }
    })
  }

  getNombreEstadoRemesa(remesa: Remesa): string{
    return this.estadosRemesas.filter(estado => estado.estr_id_estados == remesa.rem_estado_remesa)[0].estr_descripcion;
  }

  getNombreUsuario(id: string): string{
    if(id != null){
      return this.usuarios.filter(usuario => usuario.id.toString() == id)[0].name;
    }
    else{
      return '--'
    }
  }

  modificarRemesa(remesa: Remesa){
    const remesaDialog = this.dialog.open(RemesaDialogComponent, {
      data: {
        pagos: null,
        pagosFacturas: null,
        proveedores: null,
        remesa: remesa,
        accion: DialogDataAction.modificar,
      },
    });

    remesaDialog.afterClosed().subscribe({
      next: () => {
        this.obtenerEstadosRemesas();
      }
    })
  }

}
