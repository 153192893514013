import { SelectionModel } from '@angular/cdk/collections';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, ElementRef, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Autorizacion } from '../../models/autorizacion';
import { Cita } from '../../models/cita';
import { EstadoFacturasRecibidas } from '../../models/estado-facturas-recibidas';
import { FacturasRecibidas } from '../../models/facturas-recibidas';
import { PlantillaProductos } from '../../models/plantilla-productos';
import { Proveedor } from '../../models/proveedor';
import { Autorizaciones, Citas, ComentariosFactura, EstadosFacturasRecibidas, FacturacionServiceService, FacturaRecibidaData, FacturasRecibidasList, FacturasRecibidasProductosList, incidenciasNormales, IncidenciasPago, PlantillasProductos } from '../../services/facturacion-service.service';

import {take} from 'rxjs/operators';
import { ComentarioFactura } from '../../models/comentario-factura';
import { SessionService } from 'src/app/services/session.service';
import { FacturasRecibidasProductos } from '../../models/facturas-recibidas-productos';
import { MatDialog } from '@angular/material/dialog';
import { OtroGastoDialogComponent } from 'src/app/dialogs/otro-gasto-dialog/otro-gasto-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IncidenciaPago } from '../../models/incidencia-pago';
import { IncidenciaPagoDialogComponent } from 'src/app/dialogs/incidencia-pago-dialog/incidencia-pago-dialog.component';
import { DialogDataAction } from 'src/app/dialogs/dialog-utils/dialog-data-action';
import { Incidencia } from '../../models/incidencia';
import { ListaIncidenciasPagoDialogComponent } from 'src/app/dialogs/lista-incidencias-pago-dialog/lista-incidencias-pago-dialog.component';

@Component({
  selector: 'app-incluir-factura-proveedor',
  templateUrl: './incluir-factura-proveedor.component.html',
  styleUrls: ['./incluir-factura-proveedor.component.scss'],
})
export class IncluirFacturaProveedorComponent implements OnInit {

  loadingPageFlag: boolean = false;

  factura: FacturasRecibidas;
  proveedor: Proveedor;
  estados: EstadoFacturasRecibidas[] = [];
  comentarios: ComentarioFactura[];

  autorizaciones: Autorizacion[] = [];
  autorizacionesAdded: Autorizacion[] = []
  citas: Cita[] = [];
  citasAdded: Cita[] = [];
  plantillasProd: PlantillaProductos[] = [];
  plantillasProdAdded: PlantillaProductos[] = [];

  productosFacturaRecibida: FacturasRecibidasProductos[] = []
  productosFacturaRecibidaInit: FacturasRecibidasProductos[] = []
  otrosGastos: FacturasRecibidasProductos[] = []
  otrosGastosInit: FacturasRecibidasProductos[] = []

  incidencias: [IncidenciaPago[], Incidencia[]] = [[], []];

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  @ViewChild('containerComentario') private containerComentario: ElementRef;

  //Parámetros input
  //---Información de la factura---
  idProveedor: string;
  idFactura: string;
  notas: string;
  iban: string;
  irpf: number;
  nFactura: string;
  estado: string;
  registro: string;
  fechaFactura: Date;
  fechaPago: Date;
  fechaRecepcion: string;
  remesa: string;
  //---endRegion---

  //---Información económica---
  importe: number = 0;
  retencion: number = 0;
  iva: number = 0;
  prorrata: number = 0;
  importe21: number = 0;
  importe10: number = 0;
  importe4: number = 0;
  importe0: number = 0;
  importeProrrata: number = 0;
  iva21: number = 0;
  iva10: number = 0;
  iva4: number = 0;
  ivaProrrata: number = 0;
  totalFactura: number = 0;
  //---endRegion---

  //Tablas
  @ViewChild('tableAutorizacones')
  autorizacionesTable: MatTable<Autorizacion>;
  displayedColumnsAutorizaciones: string[] = [
    'selectAutorizaciones',
    'aut_id_autorizaciones',
    'aut_fecha_creacion',
    'aut_estado',
    'aut_estado_autorizacion',
    'prd_iva',
    'prd_coste_estandar'
  ];
  dataSourceAutorizaciones: MatTableDataSource<Autorizacion> = new MatTableDataSource(this.autorizaciones);

  @ViewChild('tableCitas')
  citasTable: MatTable<Cita>;
  displayedColumnsCitas: string[] = [
    'selectCitas',
    'cup_id_citas',
    'cup_fecha_creacion',
    'cup_estado',
    'prd_iva',
    'prd_coste_estandar'
  ];
  dataSourceCitas: MatTableDataSource<Cita> = new MatTableDataSource(this.citas);

  @ViewChild('tablePlantillasProd')
  plantillasProdTable: MatTable<PlantillaProductos>;
  displayedColumnsPlantillasProd: string[] = [
    'selectPlantillasProd',
    'pp_id_plantilla_productos',
    'pp_fecha_creacion',
    'pp_estado',
    'pp_iva',
    'pp_precio_venta'
  ];
  dataSourcePlantillasProd: MatTableDataSource<PlantillaProductos> = new MatTableDataSource(this.plantillasProd);

  @ViewChild('tableProductosFactura')
  productosFacturaTable: MatTable<FacturasRecibidasProductos>;
  displayedColumnsProductosFactura: string[] = [
    'factrp_producto',
    'factrp_tipo',
    'factrp_base_imponible',
    'factrp_iva',
    'factrp_total_iva',
    'factrp_total_retencion',
    'factrp_total_factura',
    'delete_button'
  ];
  dataSourceProductosFactura: MatTableDataSource<FacturasRecibidasProductos> = new MatTableDataSource(this.productosFacturaRecibida);

  @ViewChild('tableOtrosGastos')
  otrosGastosFacturaTable: MatTable<FacturasRecibidasProductos>;
  displayedColumnsOtrosGastos: string[] = [
    'factrp_concepto',
    'factrp_base_imponible',
    'factrp_irpf',
    'factrp_iva',
    'factrp_total_iva',
    'factrp_total_factura',
    'delete_button'
  ];
  dataSourceOtrosGastos: MatTableDataSource<FacturasRecibidasProductos> = new MatTableDataSource(this.otrosGastos);

  //Paginadores
  @ViewChild('autorizacionesPaginator')
  autorizacionesPaginator: MatPaginator;
  autorizacionesLength = 0;

  selectionAutorizaciones = new SelectionModel<Autorizacion>(true, []);

  @ViewChild('citasPaginator')
  citasPaginator: MatPaginator;
  citasLength = 0;

  selectionCitas = new SelectionModel<Cita>(true, []);

  @ViewChild('plantillasProdPaginator')
  plantillasProdPaginator: MatPaginator;
  plantillasProdLength = 0;

  selectionPlantillasProd = new SelectionModel<PlantillaProductos>(true, []);

  @ViewChild('productosFacturaPaginator')
  productosFacturaPaginator: MatPaginator;
  productosFacturaLength = 0;

  @ViewChild('otrosGastosPaginator')
  otrosGastosPaginator: MatPaginator;
  otrosGastosLength = 0;

  @HostListener('window:keydown', ['$event'])
  keyEvent(e: KeyboardEvent) {
    if (e.key == 's' && e.ctrlKey){
      e.preventDefault();
      this.actualizarFacturaRecibida();
    }
  }

  constructor(
    private facturacionService : FacturacionServiceService,
    private routerActive: ActivatedRoute,
    private sessionService: SessionService,
    private _ngZone: NgZone,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {

    this.routerActive.queryParams.subscribe(params => {

      this.facturacionService.obtenerFacturaRecibida(params["factura"]).subscribe({
        next : (data: FacturaRecibidaData) => {
          this.factura = data.data;
          //console.log(this.factura);

          this.idFactura = this.factura.factr_id_facturas_recibidas;
          this.notas = this.factura.factr_observaciones;

          this.nFactura = this.factura.factr_numero_factura;

          if(this.factura.factr_fecha_factura != null){
            this.fechaFactura = new Date(this.factura.factr_fecha_factura);
          }
          if(this.factura.factr_fecha_pago != null){
            this.fechaPago = new Date(this.factura.factr_fecha_pago);
          }
          this.fechaRecepcion = new Date(this.factura.factr_fecha_recepcion).toISOString().slice(0, -1);
          console.log(this.fechaRecepcion);

          this.obtenerEstadosFacturasRecibidas();
          this.obtenerComentariosFactura(this.factura);
          this.obtenerFacturaRecibidaProductos(this.factura);
          this.obtenerFacturaRecibidaOtrosGastos(this.factura);
          this.obtenerIncidenciasPago(this.factura);
        },
        error : (err: any) => {

        }
      })

    });

  }

  ngAfterViewInit(): void {
    this.accordion.openAll();
  }

  obtenerIncidenciasPago(factura: FacturasRecibidas){
    this.facturacionService.obtenerIncidenciasPago(factura).subscribe({
      next: (data: IncidenciasPago) => {
        this.incidencias = data.data;
        this.incidencias[0].forEach((incidenciaNormal, key) => {
          incidenciaNormal['incidencia'] = this.incidencias[1][key];
        })
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }

  obtenerComentariosFactura(factura: FacturasRecibidas){
    this.facturacionService.obtenerComentariosFactura(factura).subscribe({
      next: (data: ComentariosFactura) => {
        this.comentarios = data.data;
        console.log(this.comentarios);
        this.containerComentario.nativeElement.scrollTop = this.containerComentario.nativeElement.scrollHeight;
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }

  obtenerFacturaRecibidaProductos(factura: FacturasRecibidas){
    
    this.facturacionService.obtenerFacturaRecibidaProductos(factura).subscribe({
      next: (data: FacturasRecibidasProductosList) => {
        this.productosFacturaRecibida = data.data;
        this.productosFacturaRecibidaInit = this.productosFacturaRecibidaInit.concat(this.productosFacturaRecibida);

        //console.log("Productos factura recibida", this.productosFacturaRecibida);
        //console.log("Productos factura recibida inicial", this.productosFacturaRecibidaInit);

        this.dataSourceProductosFactura = new MatTableDataSource(this.productosFacturaRecibida);
        this.dataSourceProductosFactura.paginator = this.productosFacturaPaginator;
        this.productosFacturaLength = this.dataSourceProductosFactura.filteredData.length;

        this.obtenerProveedor(this.factura);

      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }

  obtenerFacturaRecibidaOtrosGastos(factura: FacturasRecibidas){
    
    this.facturacionService.obtenerFacturaRecibidaOtrosGastos(factura).subscribe({
      next: (data: FacturasRecibidasProductosList) => {
        this.otrosGastos = data.data;
        this.otrosGastosInit = this.otrosGastosInit.concat(this.otrosGastos);

        //console.log("Otros gastos", this.otrosGastos);
        //console.log("Otros gastos inicial", this.otrosGastosInit);

        this.dataSourceOtrosGastos = new MatTableDataSource(this.otrosGastos);
        this.dataSourceOtrosGastos.paginator = this.otrosGastosPaginator;
        this.otrosGastosLength = this.dataSourceOtrosGastos.filteredData.length;

        this.calculateInfoEconomica();
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }

  obtenerProveedor(factura: FacturasRecibidas){

    this.loadingPageFlag = true;

    this.facturacionService.obtenerProveedor(factura.factr_proveedor).subscribe({
      next: (data: any) => {
        this.proveedor = data.data;

        //console.log('proveedor', this.proveedor)

        this.idProveedor = this.proveedor.prov_id_crm;

        this.iban = this.proveedor.prov_iban;
        this.irpf = this.proveedor.prov_irpf;

        this.calculateInfoEconomica();

        this.obtenerAutorizacionesParaIncluirFacturaRecibida(this.proveedor);
        this.obtenerCitasParaIncluirFacturaRecibida(this.proveedor);
        this.obtenerPlantillasParaIncluirFacturaRecibida(this.proveedor);

        this.loadingPageFlag = false;

      },
      error: (err: any) => {
        console.log(err);

        this.loadingPageFlag = false;

      }
    })
  }

  obtenerEstadosFacturasRecibidas(){
    this.facturacionService.obtenerEstadosFacturasRecibidas().subscribe({
      next: (data: EstadosFacturasRecibidas) => {
        this.estados = data.data;

        this.estado = this.factura.factr_estado_factura;
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }

  obtenerAutorizacionesParaIncluirFacturaRecibida(proveedor: Proveedor){
    this.facturacionService.obtenerAutorizacionesParaIncluirFacturaRecibida(proveedor).subscribe({
      next: (data: Autorizaciones) => {

        if(this.productosFacturaRecibida.filter(productoFactura => productoFactura.factrp_tipo == "Autorización").length > 0){
          data.data.forEach(autorizacion => {
            if(autorizacion.producto != null){
              if(this.productosFacturaRecibida.filter(productoFactura => productoFactura.factrp_producto == autorizacion.aut_id_autorizaciones).length == 0){
                this.autorizaciones.push(autorizacion);
              }
              else{
                this.autorizacionesAdded.push(autorizacion);
              }
            }
            
          });
        }
        else{
          this.autorizaciones = data.data.filter(autorizacion => autorizacion.producto != null);
        }

        //console.log("Autorizaciones", this.autorizaciones);

        this.dataSourceAutorizaciones = new MatTableDataSource(this.autorizaciones);
        this.dataSourceAutorizaciones.paginator = this.autorizacionesPaginator;
        this.autorizacionesLength = this.dataSourceAutorizaciones.filteredData.length;

      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }

  obtenerCitasParaIncluirFacturaRecibida(proveedor: Proveedor){
    this.facturacionService.obtenerCitasParaIncluirFacturaRecibida(proveedor).subscribe({
      next: (data: Citas) => {
        if(this.productosFacturaRecibida.filter(productoFactura => productoFactura.factrp_tipo == "Cita").length > 0){
          data.data.forEach(cita => {
            if(this.productosFacturaRecibida.filter(productoFactura => productoFactura.factrp_producto == cita.cup_id_citas).length == 0){
              this.citas.push(cita);
            }
            else{
              this.citasAdded.push(cita);
            }
          });
        }
        else{
          this.citas = data.data;
        }

        //console.log("Citas", this.citas);

        this.dataSourceCitas = new MatTableDataSource(this.citas);
        this.dataSourceCitas.paginator = this.citasPaginator;
        this.citasLength = this.dataSourceCitas.filteredData.length;
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }

  obtenerPlantillasParaIncluirFacturaRecibida(proveedor: Proveedor){
    this.facturacionService.obtenerPlantillasParaIncluirFacturaRecibida(proveedor).subscribe({
      next: (data: PlantillasProductos) => {

        if(this.productosFacturaRecibida.filter(productoFactura => productoFactura.factrp_tipo == "Plantilla de producto").length > 0){
          data.data.forEach(plantillaProdGroup => {
            plantillaProdGroup.forEach(plantillaProd => {
              if(this.productosFacturaRecibida.filter(productoFactura => productoFactura.factrp_producto == plantillaProd.pp_id_plantilla_productos).length == 0){
                this.plantillasProd.push(plantillaProd);
              }
              else{
                this.plantillasProdAdded.push(plantillaProd);
              }
            });
          });
        }
        else{
          data.data.forEach(plantillaProdGroup => {
            plantillaProdGroup.forEach(plantillaProd => {
              this.plantillasProd.push(plantillaProd);
            });
          });
        }

        //console.log(this.plantillasProd);

        this.dataSourcePlantillasProd = new MatTableDataSource(this.plantillasProd);
        this.dataSourcePlantillasProd.paginator = this.plantillasProdPaginator;
        this.plantillasProdLength = this.dataSourcePlantillasProd.filteredData.length;
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }

  //CHECKBOX

  //Autorizaciones

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedAutorizaciones() {
    const numSelected = this.selectionAutorizaciones.selected.length;
    const numRows = this.dataSourceAutorizaciones.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleAutorizaciones() {
    if (this.isAllSelectedAutorizaciones()) {
      this.selectionAutorizaciones.clear();
      return;
    }

    this.selectionAutorizaciones.select(...this.dataSourceAutorizaciones.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabelAutorizaciones(row?: Autorizacion): string {
    let positionId : number;
    this.autorizaciones.forEach((autorizacion, key) => {
      if(autorizacion == row) positionId = key;
    });
    if (!row) {
      return `${this.isAllSelectedAutorizaciones() ? 'deselect' : 'select'} all`;
    }
    return `${this.selectionAutorizaciones.isSelected(row) ? 'deselect' : 'select'} row ${positionId + 1}`;
  }

  //Citas
  
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedCitas() {
    const numSelected = this.selectionCitas.selected.length;
    const numRows = this.dataSourceCitas.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleCitas() {
    if (this.isAllSelectedCitas()) {
      this.selectionCitas.clear();
      return;
    }

    this.selectionCitas.select(...this.dataSourceCitas.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabelCitas(row?: Cita): string {
    let positionId : number;
    this.citas.forEach((cita, key) => {
      if(cita == row) positionId = key;
    });
    if (!row) {
      return `${this.isAllSelectedCitas() ? 'deselect' : 'select'} all`;
    }
    return `${this.selectionCitas.isSelected(row) ? 'deselect' : 'select'} row ${positionId + 1}`;
  }

  //Plantillas productos
  
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedPlantillasProd() {
    const numSelected = this.selectionPlantillasProd.selected.length;
    const numRows = this.dataSourcePlantillasProd.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterTogglePlantillasProd() {
    if (this.isAllSelectedPlantillasProd()) {
      this.selectionPlantillasProd.clear();
      return;
    }

    this.selectionPlantillasProd.select(...this.dataSourcePlantillasProd.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabelPlantillasProd(row?: PlantillaProductos): string {
    let positionId : number;
    this.plantillasProd.forEach((plantillaProd, key) => {
      if(plantillaProd == row) positionId = key;
    });
    if (!row) {
      return `${this.isAllSelectedPlantillasProd() ? 'deselect' : 'select'} all`;
    }
    return `${this.selectionPlantillasProd.isSelected(row) ? 'deselect' : 'select'} row ${positionId + 1}`;
  }

  // ---endregion---

  addAutorizacionesToProductos(){
    if(this.selectionAutorizaciones.selected.length > 0){
      this.selectionAutorizaciones.selected.forEach(autorizacion => {
        autorizacion['inProducts'] = true;
        this.autorizaciones.concat(this.selectionAutorizaciones.selected); 

        this.productosFacturaRecibida.push({
          factrp_id_facturas_recibidas_productos: (Math.random() + 1).toString(36).substring(7),
          factrp_fecha_creacion: undefined,
          factrp_fecha_modificacion: undefined,
          factrp_estado: undefined,
          factrp_factura_recibida: this.factura.factr_id_facturas_recibidas,
          factrp_producto: autorizacion.aut_id_autorizaciones,
          factrp_fecha: this.factura.factr_fecha_creacion,
          factrp_tipo: "Autorización",
          factrp_concepto: undefined,
          factrp_cantidad: undefined,
          factrp_precio_unitario: 0,
          factrp_base_imponible: autorizacion.producto.prd_coste_estandar,
          factrp_irpf: this.proveedor.prov_irpf,
          factrp_iva: autorizacion.producto.prd_iva,
          factrp_total_iva: autorizacion.producto.prd_coste_estandar * (autorizacion.producto.prd_iva / 100),
          factrp_retencion: this.proveedor.prov_irpf,
          factrp_total_retencion: autorizacion.producto.prd_coste_estandar * (this.proveedor.prov_irpf / 100),
          factrp_total_factura: (autorizacion.producto.prd_coste_estandar * (autorizacion.producto.prd_iva / 100)) + autorizacion.producto.prd_coste_estandar - (autorizacion.producto.prd_coste_estandar * (this.proveedor.prov_irpf / 100)),
          factrp_registro_asociado: undefined,
        })

        this.autorizaciones.forEach((autorizacionInd, key) => {
          if(autorizacionInd.aut_id_autorizaciones == autorizacion.aut_id_autorizaciones){
            this.autorizacionesAdded.push(autorizacionInd);
            this.autorizaciones.splice(key, 1);
          }
        });

        this.actualizarTablas();

        this.calculateInfoEconomica();

        this.selectionAutorizaciones.clear();
      });
    }
    else{
      this.snackBar.open('Debe seleccionar al menos una autorización', undefined, {
        duration: 3 * 1000,
      });
    }

  }

  addCitasToProductos(){
    if(this.selectionCitas.selected.length > 0){
      this.selectionCitas.selected.forEach(cita => {
        cita['inProducts'] = true;
        this.citas.concat(this.selectionCitas.selected); 

        this.productosFacturaRecibida.push({
          factrp_id_facturas_recibidas_productos: (Math.random() + 1).toString(36).substring(7),
          factrp_fecha_creacion: undefined,
          factrp_fecha_modificacion: undefined,
          factrp_estado: undefined,
          factrp_factura_recibida: this.factura.factr_id_facturas_recibidas,
          factrp_producto: cita.cup_id_citas,
          factrp_fecha: this.factura.factr_fecha_creacion,
          factrp_tipo: "Cita",
          factrp_concepto: undefined,
          factrp_cantidad: undefined,
          factrp_precio_unitario: 0,
          factrp_base_imponible: cita.cup_coste_real,
          factrp_irpf: this.proveedor.prov_irpf,
          factrp_iva: cita.cup_iva,
          factrp_total_iva: cita.cup_coste_real * (cita.cup_iva / 100),
          factrp_retencion: this.proveedor.prov_irpf,
          factrp_total_retencion: cita.cup_coste_real * (this.proveedor.prov_irpf / 100),
          factrp_total_factura: (cita.cup_coste_real * (cita.cup_iva / 100)) + cita.cup_coste_real - (cita.cup_coste_real * (this.proveedor.prov_irpf / 100)),
          factrp_registro_asociado: undefined,
        })

        this.citas.forEach((citaInd, key) => {
          if(citaInd.cup_id_citas == cita.cup_id_citas){
            this.citasAdded.push(citaInd);
            this.citas.splice(key, 1);
          }
        });

        this.actualizarTablas();

        this.calculateInfoEconomica();

        this.selectionCitas.clear();
      });
    }
    else{
      this.snackBar.open('Debe seleccionar al menos una cita', undefined, {
        duration: 3 * 1000,
      });
    }
  }

  addPlantillasProductosToProductos(){
    if(this.selectionPlantillasProd.selected.length > 0){
      this.selectionPlantillasProd.selected.forEach(plantillaProd => {
        plantillaProd['inProducts'] = true;
        this.plantillasProd.concat(this.selectionPlantillasProd.selected); 

        this.productosFacturaRecibida.push({
          factrp_id_facturas_recibidas_productos: (Math.random() + 1).toString(36).substring(7),
          factrp_fecha_creacion: undefined,
          factrp_fecha_modificacion: undefined,
          factrp_estado: undefined,
          factrp_factura_recibida: this.factura.factr_id_facturas_recibidas,
          factrp_producto: plantillaProd.pp_id_plantilla_productos,
          factrp_fecha: this.factura.factr_fecha_creacion,
          factrp_tipo: "Plantilla de producto",
          factrp_concepto: undefined,
          factrp_cantidad: undefined,
          factrp_precio_unitario: 0,
          factrp_base_imponible: plantillaProd.pp_precio_venta,
          factrp_irpf: this.proveedor.prov_irpf,
          factrp_iva: plantillaProd.pp_iva,
          factrp_total_iva: plantillaProd.pp_precio_venta * (plantillaProd.pp_iva / 100),
          factrp_retencion: this.proveedor.prov_irpf,
          factrp_total_retencion: plantillaProd.pp_precio_venta * (this.proveedor.prov_irpf / 100),
          factrp_total_factura: (plantillaProd.pp_precio_venta * (plantillaProd.pp_iva / 100)) + plantillaProd.pp_precio_venta - (plantillaProd.pp_precio_venta * (this.proveedor.prov_irpf / 100)),
          factrp_registro_asociado: undefined,
        })

        this.plantillasProd.forEach((plantillaProdInd, key) => {
          if(plantillaProdInd.pp_id_plantilla_productos == plantillaProd.pp_id_plantilla_productos){
            this.plantillasProdAdded.push(plantillaProdInd);
            this.plantillasProd.splice(key, 1);
          }
        });

        this.actualizarTablas();

        this.calculateInfoEconomica();

        this.selectionPlantillasProd.clear();
      });
    }
    else{
      this.snackBar.open('Debe seleccionar al menos una plantilla de producto', undefined, {
        duration: 3 * 1000,
      });
    }
  }

  arrayUnique(array) {
    var a = array.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
  }

  //Modificar tamaño de TextArea según los saltos de linea
  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  sendMessage(message: string){
    if(message.trim().length !== 0){

      const comentario: ComentarioFactura = {
        cfact_id_comentario: '',
        cfact_fecha_creacion: undefined,
        cfact_fecha_modificacion: undefined,
        cfact_id_factura_recibida: this.factura.factr_id_facturas_recibidas,
        cfact_id_user: this.sessionService.getCurrentSession().user.id.toString(),
        cfact_nombre: this.sessionService.getCurrentSession().user.name,
        cfact_text: message
      }

      this.facturacionService.crearComentarioFactura(comentario).subscribe({
        next : (data: any) => {
          console.log(data)
        },
        error : (err: any) => {
          console.log(err);
        }
      })

      this.obtenerComentariosFactura(this.factura);
    }
  }

  checkIfIsUser(comentario: ComentarioFactura){
    return comentario.cfact_id_user == this.sessionService.getCurrentSession().user.id.toString();
  }

  crearOtroGasto(){
    const dialogOtroGasto = this.dialog.open(OtroGastoDialogComponent, {
      width: '350px',
      disableClose: true
    });
    dialogOtroGasto.afterClosed().subscribe(result => {
      if(result != undefined){
        
        this.otrosGastos.push({
          //Genera un id random provisional para manejar los deletes. Cuando se inserte en database su id será el que le corresponde
          factrp_id_facturas_recibidas_productos: (Math.random() + 1).toString(36).substring(7),
          factrp_fecha_creacion: undefined,
          factrp_fecha_modificacion: undefined,
          factrp_estado: undefined,
          factrp_factura_recibida: this.factura.factr_id_facturas_recibidas,
          factrp_producto: undefined,
          factrp_fecha: undefined,
          factrp_tipo: 'Otro gasto',
          factrp_concepto: result.concepto,
          factrp_cantidad: undefined,
          factrp_precio_unitario: 0,
          factrp_base_imponible: result.baseImponible,
          factrp_irpf: result.irpf,
          factrp_iva: result.iva,
          factrp_total_iva: result.baseImponible * (result.iva / 100),
          factrp_retencion: result.irpf,
          factrp_total_retencion: result.baseImponible * (result.irpf / 100),
          factrp_total_factura: (result.baseImponible * (result.iva / 100)) + result.baseImponible - (result.baseImponible * (result.irpf / 100)),
          factrp_registro_asociado: undefined,
        })
        
      }

      this.dataSourceOtrosGastos = new MatTableDataSource(this.otrosGastos);

      this.calculateInfoEconomica();
    });

  }

  deleteOtroGasto(otroGasto: FacturasRecibidasProductos){

    this.otrosGastos.forEach((otroGastoInd, key) => {
      if(otroGastoInd.factrp_id_facturas_recibidas_productos == otroGasto.factrp_id_facturas_recibidas_productos){
        this.otrosGastos.splice(key, 1);
      }
    });

    this.actualizarTablas();

    this.calculateInfoEconomica();

  }

  deleteProductoFactura(productoFact: FacturasRecibidasProductos){

    if(productoFact.factrp_producto.match("AUT") != null){
      console.log(productoFact.factrp_producto)
      this.autorizacionesAdded.forEach((autorizacion, key) => {
        if(autorizacion.aut_id_autorizaciones == productoFact.factrp_producto){
          console.log(autorizacion.aut_id_autorizaciones)
          this.autorizaciones.push(autorizacion);
          this.autorizacionesAdded.splice(key, 1);
        }
      });
      console.log(this.autorizaciones);
      console.log(this.autorizacionesAdded);
    }else if(productoFact.factrp_producto.match("CUP") != null){
      console.log(productoFact.factrp_producto)
      this.citasAdded.forEach((cita, key) => {
        if(cita.cup_id_citas == productoFact.factrp_producto){
          this.citas.push(cita);
          this.citasAdded.splice(key, 1);
        }
      });
      console.log(this.citas);
      console.log(this.citasAdded);
    }else if(productoFact.factrp_producto.match("PP") != null){
      console.log(productoFact.factrp_producto)
      this.plantillasProdAdded.forEach((plantillaProd, key) => {
        if(plantillaProd.pp_id_plantilla_productos == productoFact.factrp_producto){
          this.plantillasProd.push(plantillaProd);
          this.plantillasProdAdded.splice(key, 1);
        }
      });
      console.log(this.plantillasProd);
      console.log(this.plantillasProdAdded);
    }

    console.log("Productos factura recibida b", this.productosFacturaRecibida);
    console.log("Productos factura recibida inicial b", this.productosFacturaRecibidaInit);

    this.productosFacturaRecibida.forEach((productoFacturaInd, key) => {
      if(productoFacturaInd.factrp_id_facturas_recibidas_productos == productoFact.factrp_id_facturas_recibidas_productos){
        this.productosFacturaRecibida.splice(key, 1);
      }
    });

    this.actualizarTablas();

    this.calculateInfoEconomica();

  }

  actualizarTablas(){
    this.dataSourceProductosFactura = new MatTableDataSource(this.productosFacturaRecibida);
    this.productosFacturaLength = this.dataSourceProductosFactura.filteredData.length;

    this.dataSourceOtrosGastos = new MatTableDataSource(this.otrosGastos);
    this.otrosGastosLength = this.dataSourceOtrosGastos.filteredData.length;

    this.dataSourceAutorizaciones = new MatTableDataSource(this.autorizaciones);
    this.autorizacionesLength = this.dataSourceAutorizaciones.filteredData.length;

    this.dataSourceCitas = new MatTableDataSource(this.citas);
    this.citasLength = this.dataSourceCitas.filteredData.length;

    this.dataSourcePlantillasProd = new MatTableDataSource(this.plantillasProd);
    this.plantillasProdLength = this.dataSourcePlantillasProd.filteredData.length;
  }

  calculateInfoEconomica(){

    this.importe =
    this.retencion =
    this.iva =
    this.prorrata =
    this.importe21 =
    this.importe10 =
    this.importe4 =
    this.importeProrrata =
    this.iva21 =
    this.iva10 =
    this.iva4 =
    this.ivaProrrata =
    this.totalFactura = 0;

    console.log(this.productosFacturaRecibida);

    this.productosFacturaRecibida.forEach(productoFactura => {
      this.importe += productoFactura.factrp_base_imponible;
      this.iva += productoFactura.factrp_base_imponible * (productoFactura.factrp_iva / 100);

      if(productoFactura.factrp_iva == 0){
        this.importe0 += productoFactura.factrp_base_imponible;
      }
      else if(productoFactura.factrp_iva == 4){
        this.importe4 += productoFactura.factrp_base_imponible;
        this.iva4 += productoFactura.factrp_base_imponible * (productoFactura.factrp_iva / 100);
      }
      else if(productoFactura.factrp_iva == 10){
        this.importe10 += productoFactura.factrp_base_imponible;
        this.iva10 += productoFactura.factrp_base_imponible * (productoFactura.factrp_iva / 100);
      }
      else if(productoFactura.factrp_iva == 21){
        this.importe21 += productoFactura.factrp_base_imponible;
        this.iva21 += productoFactura.factrp_base_imponible * (productoFactura.factrp_iva / 100);
      }

      this.retencion += productoFactura.factrp_base_imponible * (this.proveedor.prov_irpf / 100);
    });

    this.otrosGastos.forEach(otroGasto => {
      this.importe += otroGasto.factrp_base_imponible;
      this.iva += otroGasto.factrp_base_imponible * (otroGasto.factrp_iva / 100);
      this.retencion += otroGasto.factrp_base_imponible * (otroGasto.factrp_irpf / 100);
    });

    this.totalFactura = this.importe + this.iva - this.retencion;

  }

  verIncidenciasPago(){

    if(this.incidencias[0].length > 0){
      console.log(this.incidencias);

      const dialogRefLista = this.dialog.open(ListaIncidenciasPagoDialogComponent, {
        maxWidth: 1000,
        data: {
          factura: this.factura,
          incidenciaPago: this.incidencias[0],
        },
      })

      dialogRefLista.afterClosed().subscribe(result => {
        if(result != undefined){
          const dialogRefIncidencia = this.dialog.open(IncidenciaPagoDialogComponent, {
            maxWidth: 1000,
            data: {
              factura: this.factura,
              incidencia: result.incidencia,
              incidenciaPago: result.incidenciaPago,
              accion: DialogDataAction.modificar,
            },
          })

          dialogRefIncidencia.afterClosed().subscribe(() => {
            this.obtenerIncidenciasPago(this.factura);
          })
        }
          
      });
    }

  }

  crearIncidenciaPago(){

    const dialogRef = this.dialog.open(IncidenciaPagoDialogComponent, {
      maxWidth: 1000,
      data: {
        factura: this.factura,
        incidencia: null,
        incidenciaPago: null,
        accion: DialogDataAction.crear,
      },
    })

    dialogRef.afterClosed().subscribe( () => {
      this.obtenerIncidenciasPago(this.factura);
    });

  }

  actualizarFacturaRecibida(){

    //Inicializo otras variables para no afectar a las principales
    let facturaToAct = this.factura;

    facturaToAct.factr_numero_factura = this.nFactura;
    facturaToAct.factr_estado_factura = this.estado;
    facturaToAct.factr_observaciones = this.notas;

    facturaToAct.factr_base_imponible = this.importe;
    facturaToAct.factr_base_imponible_al_0 = this.importe0;
    facturaToAct.factr_base_imponible_al_4 = this.importe4;
    facturaToAct.factr_base_imponible_al_10 = this.importe10;
    facturaToAct.factr_base_imponible_al_21 = this.importe21;

    facturaToAct.factr_total_iva = this.iva;
    facturaToAct.factr_total_iva_al_4 = this.iva4;
    facturaToAct.factr_total_iva_al_10 = this.iva10;
    facturaToAct.factr_total_iva_al_21 = this.iva21;

    facturaToAct.factr_total_retencion = this.retencion;

    facturaToAct.factr_total_factura = this.totalFactura;

    facturaToAct.factr_fecha_factura = this.fechaFactura;
    
    facturaToAct.factr_fecha_pago = this.fechaPago;
    facturaToAct.factr_fecha_recepcion = new Date(this.fechaRecepcion);

    const productosFacturaRecibidaToAdd = this.productosFacturaRecibida.filter(pFact => !this.productosFacturaRecibidaInit.map(pFactMap => pFactMap.factrp_id_facturas_recibidas_productos).includes(pFact.factrp_id_facturas_recibidas_productos));
    const productosFacturaRecibidaToDelete = this.productosFacturaRecibidaInit.filter(pFact => !this.productosFacturaRecibida.map(pFactMap => pFactMap.factrp_id_facturas_recibidas_productos).includes(pFact.factrp_id_facturas_recibidas_productos));

    const otrosGastosToAdd = this.otrosGastos.filter(pFact => !this.otrosGastosInit.map(pFactMap => pFactMap.factrp_id_facturas_recibidas_productos).includes(pFact.factrp_id_facturas_recibidas_productos));
    const otrosGastosToDelete = this.otrosGastosInit.filter(pFact => !this.otrosGastos.map(pFactMap => pFactMap.factrp_id_facturas_recibidas_productos).includes(pFact.factrp_id_facturas_recibidas_productos));

    console.log(productosFacturaRecibidaToAdd);
    console.log(productosFacturaRecibidaToDelete);
    console.log(otrosGastosToAdd);
    console.log(otrosGastosToDelete);

    //Quitar variable temporal
    delete facturaToAct['checked'];

    this.facturacionService.actualizarFacturaRecibida(
      facturaToAct,
      productosFacturaRecibidaToAdd,
      productosFacturaRecibidaToDelete,
      otrosGastosToAdd,
      otrosGastosToDelete
    ).subscribe({
      next: (data: FacturasRecibidasList) => {
        console.log(data.data);
        this.snackBar.open('Factura recibida guardada con éxito', undefined, {
          duration: 3 * 1000,
          verticalPosition: 'top'
        });
      },
      error: (err: any) => {
        this.snackBar.open(err, undefined, {
          duration: 3 * 1000,
          verticalPosition: 'top'
        });
      }
    })
    
  }

  cancelarFacturaRecibida(){
    this.facturacionService.cancelarFacturaRecibida(this.factura).subscribe({
      next: (data: FacturasRecibidasList) => {
        console.log(data.data);
        this.snackBar.open('Factura recibida cancelada', undefined, {
          duration: 3 * 1000,
          verticalPosition: 'top'
        });
        this.ngOnInit();
      },
      error: (err: any) => {
        this.snackBar.open(err, undefined, {
          duration: 3 * 1000,
          verticalPosition: 'top'
        });
      }
    })
  }

}
