<app-header-facturacion></app-header-facturacion>

<div class="loading w-100 h-100 d-flex justify-content-center align-items-center position-absolute" *ngIf="loadingPageFlag">
    <mat-spinner></mat-spinner>
</div>

<div class="w-100 h-100 overflow-auto px-2">

    <table mat-table #tableRemesas [dataSource]="dataSourceRemesas" class="w-100" *ngIf="!loadingPageFlag">

        <ng-container matColumnDef="rem_id_remesas">
            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Id remesa </th>
            <td class="p-2" mat-cell *matCellDef="let remesa" (click)="modificarRemesa(remesa)" > {{remesa.rem_id_remesas != null ? remesa.rem_id_remesas : '--'}} </td>
        </ng-container>

        <ng-container matColumnDef="rem_fecha_creacion">
            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha creación </th>
            <td class="p-2" mat-cell *matCellDef="let remesa" (click)="modificarRemesa(remesa)" > {{remesa.rem_fecha_creacion != null ? remesa.rem_fecha_creacion : '--'}} </td>
        </ng-container>

        <ng-container matColumnDef="rem_fecha_modificacion">
            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha modificación </th>
            <td class="p-2" mat-cell *matCellDef="let remesa" (click)="modificarRemesa(remesa)" > {{remesa.rem_fecha_modificacion != null ? remesa.rem_fecha_modificacion : '--'}} </td>
        </ng-container>

        <ng-container matColumnDef="rem_estado">
            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
            <td class="p-2" mat-cell *matCellDef="let remesa" (click)="modificarRemesa(remesa)" > {{remesa.rem_estado != null ? remesa.rem_estado : '--'}} </td>
        </ng-container>

        <ng-container matColumnDef="rem_fecha">
            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
            <td class="p-2" mat-cell *matCellDef="let remesa" (click)="modificarRemesa(remesa)" > {{remesa.rem_fecha != null ? remesa.rem_fecha : '--'}} </td>
        </ng-container>

        <ng-container matColumnDef="rem_fecha_pago">
            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha pago </th>
            <td class="p-2" mat-cell *matCellDef="let remesa" (click)="modificarRemesa(remesa)" > {{remesa.rem_fecha_pago != null ? remesa.rem_fecha_pago : '--'}} </td>
        </ng-container>

        <ng-container matColumnDef="rem_fecha_cancelada">
            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha cancelada </th>
            <td class="p-2" mat-cell *matCellDef="let remesa" (click)="modificarRemesa(remesa)" > {{remesa.rem_fecha_cancelada != null ? remesa.rem_fecha_cancelada : '--'}} </td>
        </ng-container>

        <ng-container matColumnDef="rem_iban">
            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> IBAN </th>
            <td class="p-2" mat-cell *matCellDef="let remesa" (click)="modificarRemesa(remesa)" > {{remesa.rem_iban != null ? remesa.rem_iban : '--'}} </td>
        </ng-container>

        <ng-container matColumnDef="rem_estado_remesa">
            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Estado remesa </th>
            <td class="p-2" mat-cell *matCellDef="let remesa" (click)="modificarRemesa(remesa)" > {{getNombreEstadoRemesa(remesa)}} </td>
        </ng-container>

        <ng-container matColumnDef="rem_perceptores">
            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Perceptores </th>
            <td class="p-2" mat-cell *matCellDef="let remesa" (click)="modificarRemesa(remesa)" > {{remesa.rem_perceptores != null ? remesa.rem_perceptores : '--'}} </td>
        </ng-container>

        <ng-container matColumnDef="rem_cantidad_facturas">
            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad facturas </th>
            <td class="p-2" mat-cell *matCellDef="let remesa" (click)="modificarRemesa(remesa)" > {{remesa.rem_cantidad_facturas != null ? remesa.rem_cantidad_facturas : '--'}} </td>
        </ng-container>

        <ng-container matColumnDef="rem_total_remesa">
            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Total remesa </th>
            <td class="p-2" mat-cell *matCellDef="let remesa" (click)="modificarRemesa(remesa)" > {{remesa.rem_total_remesa != null ? (remesa.rem_total_remesa | number:'1.2-2') : '--'}}€ </td>
        </ng-container>

        <ng-container matColumnDef="rem_usuario_genera">
            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Usuario generador </th>
            <td class="p-2" mat-cell *matCellDef="let remesa" (click)="modificarRemesa(remesa)" > {{getNombreUsuario(remesa.rem_usuario_genera)}} </td>
        </ng-container>

        <ng-container matColumnDef="rem_usuario_pagada">
            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Usuario pago </th>
            <td class="p-2" mat-cell *matCellDef="let remesa" (click)="modificarRemesa(remesa)" > {{getNombreUsuario(remesa.rem_usuario_pagada)}} </td>
        </ng-container>

        <ng-container matColumnDef="rem_usuario_cancelada">
            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Usuario cancelación </th>
            <td class="p-2" mat-cell *matCellDef="let remesa" (click)="modificarRemesa(remesa)" > {{getNombreUsuario(remesa.rem_usuario_cancelada)}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsRemesas"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsRemesas;" class="custom-row"></tr>
    </table>
    
</div>