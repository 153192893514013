import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { Archivo } from '../../models/archivo';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss']
})
export class FileManagerComponent implements OnInit {

  mostrarPegar: boolean = false;

  constructor() { }

  @Input() fileElements: Archivo[];
  @Input() canNavigateUp: string;
  @Input() path: string;
  
  @Output() navigatedDown     = new EventEmitter<Archivo>();
  @Output() navigatedUp       = new EventEmitter();
  @Output() openModalView     = new EventEmitter<Archivo>();
  @Output() elementCopy       = new EventEmitter<Archivo>();
  @Output() elementPegar      = new EventEmitter<Archivo>();
  @Output() elementRemoved    = new EventEmitter<Archivo>();

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {}

  navigate(element: Archivo) {
    if (element.archIsFolder) {
      this.navigatedDown.emit(element);
    }
  }

  navigateUp() {
    this.navigatedUp.emit();
  }

  openModalFile(obj: Archivo) {
    if (!obj.archIsFolder)
      this.openModalView.emit(obj);
  }

  copyElement(element: Archivo){
    if(!element.archIsFolder) {
      this.elementCopy.emit(element);
      this.mostrarPegar = true;
    }

  }

  pegarArchivo(){
    this.elementPegar.emit();
    this.mostrarPegar = false;
  }


  deleteElement(element: Archivo) {
    this.elementRemoved.emit(element);
  }

  openMenu(event: MouseEvent, element: Archivo, viewChild: MatMenuTrigger) {
    event.preventDefault();
    viewChild.openMenu();
  }

}
