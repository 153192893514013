import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EncargoTramitadorComponent } from './Tramitador/encargo-tramitador/encargo-tramitador.component';
import { EncargoComponent } from './Medico/encargo/encargo.component';
import { BusquedaComponent } from './facturacion/components/busqueda/busqueda.component';
import { CambiosPendientesComponent } from './facturacion/components/cambios-pendientes/cambios-pendientes.component';
import { ContabilidadComponent } from './facturacion/components/contabilidad/contabilidad.component';
import { ExpedientesAltaComponent } from './facturacion/components/expedientes-alta/expedientes-alta.component';
import { FacturacionPrincipalComponent } from './facturacion/components/facturacion-principal/facturacion-principal.component';
import { FacturarExpedienteComponent } from './facturacion/components/facturar-expediente/facturar-expediente.component';
import { FacturasEmitidasComponent } from './facturacion/components/facturas-emitidas/facturas-emitidas.component';
import { FacturasRecibidasComponent } from './facturacion/components/facturas-recibidas/facturas-recibidas.component';
import { GestionarEstadoComponent } from './facturacion/components/gestionar-estado/gestionar-estado.component';
import { IncluirFacturaProveedorComponent } from './facturacion/components/incluir-factura-proveedor/incluir-factura-proveedor.component';
import { InformesComponent } from './facturacion/components/informes/informes.component';
import { ProveedorComponent } from './facturacion/components/proveedor/proveedor.component';
import { RemesasComponent } from './facturacion/components/remesas/remesas.component';
import { TablasAdminComponent } from './facturacion/components/tablas-admin/tablas-admin.component';
import { UnidadNegocioComponent } from './facturacion/components/unidad-negocio/unidad-negocio.component';
import { AuthorizatedGuard } from './guards/authorizated.guard';
import { LoginComponent } from './login/login.component';
import { MedicoMensajesComponent } from './Medico/medico-mensajes/medico-mensajes.component';
import { MedicoPrincipalComponent } from './Medico/medico-principal/medico-principal.component';
import { TramitadorListadoComponent } from './Tramitador/tramitador-listado/tramitador-listado.component';
import { TramitadorMensajesComponent } from './Tramitador/tramitador-mensajes/tramitador-mensajes.component';
import { TramitadorPrincipalComponent } from './Tramitador/tramitador-principal/tramitador-principal.component';
import { EncargoVdcAsistencialComponent } from './Tramitador/vdc-asistencial/encargo-vdc-asistencial/encargo-vdc-asistencial.component';
import { EncargoTramitadorVdcComponent } from './Tramitador/vdc-asistencial/encargo-tramitador-vdc/encargo-tramitador-vdc.component';
import { EncargoVdcAsistencialMedicoComponent } from './Medico/medico-vdc-asistencial/encargo-vdc-asistencial-medico/encargo-vdc-asistencial-medico.component';
import { EncargoMedicoVdcComponent } from './Medico/medico-vdc-asistencial/encargo-medico-vdc/encargo-medico-vdc.component';
import { NuevaTareaVdcComponent } from './Tramitador/vdc-asistencial/nueva-tarea-vdc/nueva-tarea-vdc.component';
import { EncargoPericialComponent } from './Tramitador/intranet-periciales/encargo-pericial/encargo-pericial.component';
import { EncargoTramitadorPericialComponent } from './Tramitador/intranet-periciales/encargo-tramitador-pericial/encargo-tramitador-pericial.component';
import { EncargoMedicoPericialComponent } from './Medico/intranet-periciales-medico/encargo-medico-pericial/encargo-medico-pericial.component';
import { EncargoPericialMedicoComponent } from './Medico/intranet-periciales-medico/encargo-pericial-medico/encargo-pericial-medico.component';


const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'medico-principal/:opcion', component: MedicoPrincipalComponent, canActivate: [AuthorizatedGuard]},
  {path: 'medico-mensajes', component: MedicoMensajesComponent, canActivate: [AuthorizatedGuard]},
  {path: 'encargo', component: EncargoComponent, canActivate: [AuthorizatedGuard]},
  {path: 'tramitador-principal', component: TramitadorPrincipalComponent, canActivate: [AuthorizatedGuard]},
  {path: 'tramitador-listado/:opcion', component: TramitadorListadoComponent, canActivate: [AuthorizatedGuard]},
  {path: 'tramitador-mensajes', component: TramitadorMensajesComponent, canActivate: [AuthorizatedGuard]},
  {path: 'encargo-tramitador', component: EncargoTramitadorComponent, canActivate: [AuthorizatedGuard]},
  {path: 'facturador-principal', component: FacturacionPrincipalComponent, canActivate: [AuthorizatedGuard]},
  {path: 'unidad-negocio', component: UnidadNegocioComponent, canActivate: [AuthorizatedGuard]},
  {path: 'gestionar-estado', component: GestionarEstadoComponent, canActivate: [AuthorizatedGuard]},
  {path: 'informes', component: InformesComponent, canActivate: [AuthorizatedGuard]},
  {path: 'cambios-pendientes', component: CambiosPendientesComponent, canActivate: [AuthorizatedGuard]},
  {path: 'busqueda', component: BusquedaComponent, canActivate: [AuthorizatedGuard]},
  {path: 'tablas', component: TablasAdminComponent, canActivate: [AuthorizatedGuard]},
  {path: 'contabilidad', component: ContabilidadComponent, canActivate: [AuthorizatedGuard]},
  {path: 'remesas', component: RemesasComponent, canActivate: [AuthorizatedGuard]},
  {path: 'facturas-recibidas', component: FacturasRecibidasComponent, canActivate: [AuthorizatedGuard]},
  {path: 'facturas-emitidas', component: FacturasEmitidasComponent, canActivate: [AuthorizatedGuard]},
  {path: 'expedientes-alta', component: ExpedientesAltaComponent, canActivate: [AuthorizatedGuard]},
  {path: 'facturar-expediente', component: FacturarExpedienteComponent, canActivate: [AuthorizatedGuard]},
  {path: 'proveedor', component: ProveedorComponent, canActivate: [AuthorizatedGuard]},
  {path: 'incluir-factura', component: IncluirFacturaProveedorComponent, canActivate: [AuthorizatedGuard]},
  {path: 'encargo-vdc-asistencial', component: EncargoVdcAsistencialComponent, canActivate: [AuthorizatedGuard]},
  {path: 'encargo-tramitador-vdc', component: EncargoTramitadorVdcComponent, canActivate: [AuthorizatedGuard]},
  {path: 'encargo-vdc-asistencial-medico', component: EncargoVdcAsistencialMedicoComponent, canActivate: [AuthorizatedGuard]},
  {path: 'encargo-medico-vdc', component: EncargoMedicoVdcComponent, canActivate: [AuthorizatedGuard]},
  {path: 'nueva-tarea-vdc', component: NuevaTareaVdcComponent, canActivate: [AuthorizatedGuard]},
  {path: 'encargo-pericial', component: EncargoPericialComponent, canActivate: [AuthorizatedGuard]},
  {path: 'encargo-tramitador-pericial', component: EncargoTramitadorPericialComponent, canActivate: [AuthorizatedGuard]},
  {path: 'encargo-pericial-medico', component: EncargoPericialMedicoComponent, canActivate: [AuthorizatedGuard]},
  {path: 'encargo-medico-pericial', component: EncargoMedicoPericialComponent, canActivate: [AuthorizatedGuard]},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
