<app-header-facturacion></app-header-facturacion>

<mat-tab-group #tabGroup (selectedTabChange)="filtrarCambiosPendientes($event)" animationDuration="0ms">
    <mat-tab *ngFor = "let name of tabsName let i = index" label = {{name}}> 
        
        <div class="loading" *ngIf="loadingPageFlag">
            <mat-spinner></mat-spinner>
        </div>
        
        <div class="contenedor">
            <div id="list" class="div1">
                <table mat-table [dataSource]="dataIDs" class="mat-elevation-z8 data-table">
                    
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> ID </th>
                        <td mat-cell *matCellDef = "let element let i = index" (dblclick)="selectElement(i)"> {{element.index}}</td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <div class="div2">
                <div class="antiguo">

                    <h2>Datos antiguos</h2>

                    <form *ngIf = "tabState == 0" class="form" [formGroup]="form">
                        <mat-form-field *ngFor = "let info of labelsAut" class="field" appearance="outline">
                            <mat-label>{{info[0]}}</mat-label>
                            <input matInput formControlName={{info[1]}}>
                        </mat-form-field>
                    </form>

                    <form *ngIf = "tabState == 1" class="form" [formGroup]="form">
                        <mat-form-field *ngFor = "let info of labelsCitas" class="field" appearance="outline">
                            <mat-label>{{info[0]}}</mat-label>
                            <input matInput formControlName={{info[1]}}>
                        </mat-form-field>
                    </form>

                    <form *ngIf = "tabState == 2" class="form" [formGroup]="form">
                        <mat-form-field *ngFor = "let info of labelsConsultas" class="field" appearance="outline">
                            <mat-label>{{info[0]}}</mat-label>
                            <input matInput formControlName={{info[1]}}>
                        </mat-form-field>
                    </form>

                    <!--FALTA LABELSEXPEDIENTE (tabState 3)-->

                    <form *ngIf = "tabState == 4" class="form" [formGroup]="form">
                        <mat-form-field *ngFor = "let info of labelsLlamadasSeg" class="field" appearance="outline">
                            <mat-label>{{info[0]}}</mat-label>
                            <input matInput formControlName={{info[1]}}>
                        </mat-form-field>
                    </form>

                    <form *ngIf = "tabState == 5" class="form" [formGroup]="form">
                        <mat-form-field *ngFor = "let info of labelsPlantillaProductos" class="field" appearance="outline">
                            <mat-label>{{info[0]}}</mat-label>
                            <input matInput formControlName={{info[1]}}>
                        </mat-form-field>
                    </form>

                    <form *ngIf = "tabState == 6" class="form" [formGroup]="form">
                        <mat-form-field *ngFor = "let info of labelsPlantillasProductosFactura" class="field" appearance="outline">
                            <mat-label>{{info[0]}}</mat-label>
                            <input matInput formControlName={{info[1]}}>
                        </mat-form-field>
                    </form>

                </div>
                <div class="nuevo">

                    <h2>Datos nuevos</h2>

                    <form *ngIf = "tabState == 0" class="form" [formGroup]="form2">
                        <mat-form-field *ngFor = "let info of labelsAut" class="field" appearance="outline">
                            <mat-label>{{info[0]}}</mat-label>
                            <input matInput formControlName={{info[1]}}>
                        </mat-form-field>
                    </form>

                    <form *ngIf = "tabState == 1" class="form" [formGroup]="form2">
                        <mat-form-field *ngFor = "let info of labelsCitas" class="field" appearance="outline">
                            <mat-label>{{info[0]}}</mat-label>
                            <input matInput formControlName={{info[1]}}>
                        </mat-form-field>
                    </form>

                    <form *ngIf = "tabState == 2" class="form" [formGroup]="form2">
                        <mat-form-field *ngFor = "let info of labelsConsultas" class="field" appearance="outline">
                            <mat-label>{{info[0]}}</mat-label>
                            <input matInput formControlName={{info[1]}}>
                        </mat-form-field>
                    </form>

                    <!--FALTA LABELSEXPEDIENTE (tabState 3)-->

                    <form *ngIf = "tabState == 4" class="form" [formGroup]="form2">
                        <mat-form-field *ngFor = "let info of labelsLlamadasSeg" class="field" appearance="outline">
                            <mat-label>{{info[0]}}</mat-label>
                            <input matInput formControlName={{info[1]}}>
                        </mat-form-field>
                    </form>

                    <form *ngIf = "tabState == 5" class="form" [formGroup]="form2">
                        <mat-form-field *ngFor = "let info of labelsPlantillaProductos" class="field" appearance="outline">
                            <mat-label>{{info[0]}}</mat-label>
                            <input matInput formControlName={{info[1]}}>
                        </mat-form-field>
                    </form>

                    <form *ngIf = "tabState == 6" class="form" [formGroup]="form2">
                        <mat-form-field *ngFor = "let info of labelsPlantillasProductosFactura" class="field" appearance="outline">
                            <mat-label>{{info[0]}}</mat-label>
                            <input matInput formControlName={{info[1]}}>
                        </mat-form-field>
                    </form>


                    <div class="example-button-container">
                        <button mat-fab color="$primary">
                          <mat-icon>publish</mat-icon>
                        </button>
                    </div>
                </div>   
            </div>
        </div>
    </mat-tab>
</mat-tab-group>
