import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificacionService {

  constructor(private snackBar: MatSnackBar) { }

  notificationOk(mensaje: string){
    this.snackBar.open(mensaje, "",{
      duration: 5000,
      panelClass: ['snackBar_ok']
    });
  }

  notificationFail(mensaje: string){
    this.snackBar.open(mensaje, "",{
      duration: 5000,
      panelClass: ['snackBar_error']
    });
  }
}
