import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { EncargoGenerali } from 'src/app/models/encargoGenerali';
import { FuncionesPrincipalService } from 'src/app/services/funciones-principal.service';
import { MedicoPortalService } from 'src/app/services/medico-portal.service';
import { SessionService } from 'src/app/services/session.service';
import { TramitadorPortalService } from 'src/app/services/tramitador-portal.service';

@Component({
  selector: 'app-header-encargo-medico-vdc',
  templateUrl: './header-encargo-medico-vdc.component.html',
  styleUrls: ['./header-encargo-medico-vdc.component.scss']
})
export class HeaderEncargoMedicoVdcComponent implements OnInit {

  nombreMedico: string;
  idMedico : number;
  countMensajes: number;
  idEncargo: number;
  idTipo: number;
  encargo : EncargoGenerali;

  constructor(private sessionService: SessionService, private tramitadorService: TramitadorPortalService, private title : Title,private route: ActivatedRoute,
    private funcionesService : FuncionesPrincipalService 
    ) {
    this.nombreMedico = this.sessionService.getCurrentSession().medico.med_nombre;
    this.idMedico     = this.sessionService.getCurrentSession().medico.med_id_user;
    this.route.params.subscribe(params =>{
      this.idEncargo = params['idEncargo'];
      this.idTipo    = params['idTipo'];
      //this.ngOnInit();
    });
  }

  ngOnInit(): void {
    this.funcionesService.getEncargoByIdGenerali(this.idEncargo, this.idMedico).subscribe(data => {
      if(data){
        this.encargo = data['encargo'];
        this.title.setTitle(this.encargo.upgen_lesionado);
      }else{
        this.title.setTitle("CELSUS");
      }
    });
    this.tramitadorService.getCountMensajesSinLeerTramitadorGenerali().subscribe(
      data => {
        if(data['response'] == "ok"){
          this.countMensajes = data['totalMensajes'];
        }else{

        }
      }
    );
  }


  logout(){
    const confirmed = confirm("Está a punto de cerrar sesión, y eso conlleva a cerrar sesión en TODAS las pestañas de CELSUS.");
    
    if(confirmed) {
      this.sessionService.logout();
    }
  }

}
