<app-header-medico></app-header-medico>

<div class="container-list">
    <div class="row">    
        
        <mat-tab-group (selectedTabChange)="onTabChanged($event);">
             Encargos Pendientes 
             <mat-tab *ngFor="let estado of estados" class="mini" label="{{estado.description}}" data-id = "{{estado.id}}">                    
             <br>
                <div class="container rowButtonFilter col d-flex justify-content-around">  
                    <div class=" centerTextFilter">Filtrar por:</div>               
                    <div class="matForm">
                        <mat-form-field appearance="legacy" >
                            <mat-label>Compañía</mat-label>
                            <input type="text" matInput  (input)="filterEncargos(0)"  [(ngModel)]="company">
                        </mat-form-field>                            
                    </div>
                    <div class="matForm">
                            <mat-form-field appearance="legacy">
                                <mat-label>Referencia siniestro</mat-label>
                                <input type="text" matInput  (input)="filterEncargos(0)" [(ngModel)]="sinesterReference">
                            </mat-form-field>                            
                    </div>  
                    <div class="matForm">
                        <mat-form-field appearance="legacy">
                            <mat-label>Lesionado</mat-label>
                            <input type="text" matInput (input)="filterEncargos(0)"  [(ngModel)]="lesionado">
                        </mat-form-field>
                    </div> 
                    <div class="matForm">
                        <mat-form-field appearance="legacy"> 
                            <mat-label>Fecha encargo desde:</mat-label>
                            <input type="date" matInput placeholder="Fecha encargo" [(ngModel)]="dateStart">
                        </mat-form-field>
                    </div>  
                    <div class="matForm">
                        <mat-form-field appearance="legacy">
                            <mat-label>Fecha encargo hasta:</mat-label>
                            <input type="date" matInput placeholder="Fecha encargo" (input)="filterEncargos(0)"  [(ngModel)]="dateLimit">
                        </mat-form-field>
                    </div> 

                    <div class=" centerTextFilter">
                        Total casos: {{encargosLength}} 
                    </div>
                </div>

                

                <div class="container rowButton col d-flex justify-content-around">

                    <div class="centerText">Ordenar por: </div>

                    <button class="colorButton btn btn-primary" 
                    (click)="sortOrder(encargosArrayAux, 'created_at')" 
                    type="submit">
                    Fecha encargo
                    </button>

                    <button class="colorButton btn btn-primary my-12" 
                    (click)="sortOrder(encargosArrayAux, 'enup_localidad')"
                    type="submit">
                    Localidad
                    </button>

                    <button class="colorButton btn btn-primary my-12" 
                    (click)="sortOrder(encargosArrayAux, 'enup_lesionado')"
                    type="submit">
                    Lesionado
                    </button>

                    <button class="colorButton btn btn-primary my-12" 
                    (click)="sortOrder(encargosArrayAux, 'ciaNombre')"
                    type="submit">
                    Compañía
                    </button>

                    <button class="colorButton btn btn-primary my-12" 
                    (click)="sortOrder(encargosArrayAux, 'enup_fecha_limite')"
                    type="submit">
                    Prioridad
                    </button>

                    <button class="colorButton btn btn-primary my-12" 
                    (click)="sortOrder(encargosArrayAux, 'nombreMedico')"
                    type="submit">
                    Valorador
                    </button>

                    <button class="colorButton btn btn-primary my-12" 
                    (click)="sortOrder(encargosArrayAux, 'reset')"
                    type="submit">
                    Reset
                    </button>

                    
                </div>

                <mat-divider class="m-3"></mat-divider>

                 <!--Filas --> 
                <div class="container-list-encargos">
                
                    <div class="loading" *ngIf="loadingPageFlag == false">
                        <mat-spinner></mat-spinner>
                    </div>
                    <div *ngIf="loadingPageFlag == true">
                    <div *ngFor="let encargoTramitacion of encargosArrayAux|slice:startIndex:endIndex;" class="d-flex">
                        <div class="estrella d-flex align-items-center">
                            <button mat-stroked-button class="h-100 px-3" (click)="onClickEstrella(encargoTramitacion)" [ngClass]="{'checkedColor': encargoTramitacion.enup_estrella}">
                                <mat-icon *ngIf="encargoTramitacion.enup_estrella">star</mat-icon>
                                <mat-icon *ngIf="!encargoTramitacion.enup_estrella">star_border</mat-icon>
                            </button>
                        </div>
                        <div class="row fila flex-grow-1"  [ngClass]="colorEncargoFila(encargoTramitacion.enup_fecha_limite, encargoTramitacion.enup_prioridad)" >
                            <a [href]="'/#/encargo-medico-pericial;idEncargo=' + encargoTramitacion.id" target="_blank">


                            
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-2">
                                        <i class="bi bi-circle-fill icon_size" [ngClass]="colorEncargo(encargoTramitacion.enup_fecha_limite)"></i>
                                        <h3 class="pt-3 text_inline_icon_encargo">{{encargoTramitacion.id_plataforma}}</h3>
                                    </div>
    
                                    <div class="col-md-2 pt-3">
                                        <h5 class="m-0 subtitulo_h5">Referencia Siniestro</h5>
                                        <h4 class="subtitulo_h4">{{encargoTramitacion.enup_referencia_siniestro}}</h4>
                                    </div>
    
                                    
    
                                    <div class="col-md-4">
                                        <div class="pt-3 row">
                                            <div class="col-md-12" style="margin-left: 80px;">
                                                <h5 class="m-0 subtitulo_h5">Compañía</h5>
                                                <h4 class="subtitulo_h4">{{encargoTramitacion.ciaNombre}}</h4>
                                            </div>
                                            
                                            <div class="col-md-2">
                                            
                                            </div>
                                        </div>
                                    </div>

                                    
    
                                    <div class="col-md-3">
                                        <div class="pt-3 row">
                                            <div class="col-md-12" style="margin-left: 50px;">
                                                <h5 class="m-0 subtitulo_h5">Valorador</h5>
                                                <h4 class="subtitulo_h4">{{encargoTramitacion.nombreMedico}}</h4>
                                            </div>
                                            
                                            <div class="col-md-2">
                                            
                                            </div>
                                        </div>
                                    </div>
    
                                </div>
    
                                <div class="row">
                                    <div class="col-md-2">
                                        <i class="bi bi-chat-left-text-fill icon_size"><h3 class="text_inline_icon_mensaje">{{encargoTramitacion.mensajes_count}} Mensaje</h3></i>
                                    </div>
    
                                    <div class="col-md-2">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h5 class="m-0 subtitulo_h5">Fecha encargo</h5>
                                                <h4 class="subtitulo_h4">{{encargoTramitacion.enup_fecha_tarea.substring(0,10)}}</h4>
                                            </div>
                                            
                                            <div class="col-md-2">
                                            
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="col-md-4">
                                        <div class="row">
                                            <div  class="col-md-12" style="margin-left: 80px;">
                                                <h5 class="m-0 subtitulo_h5">Tipo de encargo</h5>
                                                <h4 class="subtitulo_h4">{{encargoTramitacion.nombre_tipo_encargo}}</h4>
                                            </div>
                                            
                                            <div class="col-md-2">
                                            
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-3" style="margin-left: 50px;">
                                        <h5 class="m-0 subtitulo_h5">Lesionado</h5>
                                        <h4 class="subtitulo_h4">{{encargoTramitacion.enup_lesionado}}</h4>
                                    </div>
                                    
                                </div>
                            </div>
                        </a>
                        </div>
                        
                    </div>
                    
                    <footer *ngIf="encargosArrayAux.length > DISPLAY_FOOTER" class="align-items-center">
                        <mat-paginator [length]="encargosArrayAux.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                        (page)="setpage($event)">
                        </mat-paginator>
                    </footer>
                    </div>
                </div>

                 <!--Leyenda  -->
                <div class="fila_leyenda_margin">
                    <i class="bi bi-square-fill leyenda_icon_urgente"></i><h5 class="leyenda_text">Urgente</h5>
                    <i class="bi bi-circle-fill leyenda_icon_1"></i><h5 class="leyenda_text">Fecha actual menor a dos días antes de la fecha límite</h5>
                    <i class="bi bi-circle-fill leyenda_icon_2"></i><h5 class="leyenda_text">Fecha actual mayor a dos días antes fecha límite</h5>
                    <i class="bi bi-circle-fill leyenda_icon_3"></i><h5 class="leyenda_text" style="margin-right: 6px;">Fecha actual igual o mayor a fecha límite</h5>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>