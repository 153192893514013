<h1 mat-dialog-title>Incluir otro gasto</h1>
<div mat-dialog-content>

    <mat-form-field appearance="outline" class="w-100">
        <mat-label>Concepto</mat-label>
        <input matInput [(ngModel)]="concepto">
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
        <mat-label>IVA(%)</mat-label>
        <input matInput type="number" min="0" max="100" [(ngModel)]="iva">
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
        <mat-label>IRPF(%)</mat-label>
        <input matInput type="number" min="0" max="100" [(ngModel)]="irpf">
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
        <mat-label>Base imponible</mat-label>
        <input matInput type="number" [(ngModel)]="baseImponible">
    </mat-form-field>

</div>
<div mat-dialog-actions>

    <button mat-raised-button color="primary" (click)="doAction()">Incluir</button>
    <button mat-raised-button color="warn" (click)="onNoClick()">Cancelar</button>

</div>