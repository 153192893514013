<app-header-facturacion></app-header-facturacion>

<div class="loading w-100 h-100 d-flex justify-content-center align-items-center position-absolute" *ngIf="loadingPageFlag">
    <mat-spinner></mat-spinner>
</div>

<div class="h-100 w-100" *ngIf="!loadingPageFlag && data.length > 0">

    <div class="d-flex h-100 w-100">
        <div class="p-3 h-100 d-flex flex-column box-shrink">
            <h3 class="pb-2 mb-2 border-bottom">Columnas:</h3>
            <div class="d-flex flex-column overflow-auto flex-grow-1">
                <mat-checkbox *ngFor="let column of filterColumns"
                                [(ngModel)]="column.selected"
                                (change)="obtenerEntidadTabla()">
                    {{column.column_name}}
                </mat-checkbox>
            </div>
            
        </div>
        <div class="custom-border box-grow">

            <div class="h-100 w-100">
                
                <div class="loading w-100 h-100 d-flex justify-content-center align-items-center position-absolute" *ngIf="loadingTableFlag">
                    <mat-spinner></mat-spinner>
                </div>

                <table mat-table [dataSource]="dataSource" class="w-100 custom-inputs" *ngIf="!loadingTableFlag">
                    <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
                        <th class="p-2" mat-header-cell *matHeaderCellDef>
                            {{column}}
                            <mat-form-field appearance="outline">
                                <input matInput type="text" [(ngModel)]="filteredValues[column]" (keyup)="searchTabla()">
                            </mat-form-field>
                        </th>
                        <td class="p-2" mat-cell *matCellDef="let element"> {{ element[column] != null ? ((element[column].length>45) ? (element[column] | slice:0:45)+'..': (element[column])) : '--' }} </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>

        </div>
    </div>

    

</div>

<div class="w-100 p-5 d-flex justify-content-center" *ngIf="!loadingPageFlag && data.length == 0">
    <h2>No existen elementos a mostrar de esta tabla</h2>
</div>
 <!-- 
<div class="container align">
    <mat-form-field>
      <mat-label>Buscar</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Buscar">
    </mat-form-field>
  
    <div class="container mat-elevation-z8">
        <div class="loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
            <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
            <div class="rate-limit-reached" *ngIf="isRateLimitReached">
                Ha ocurrido un error inesperado. Se restablecerá enseguida.
            </div>
        </div>
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        
               Position Column                 
                <ng-container matColumnDef="numExp">
                <th mat-header-cell *matHeaderCellDef> Número expediente </th>
                <td mat-cell *matCellDef="let element"> {{element.exp_id_expedientes_up}} </td>
                </ng-container>
               
                
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <mat-paginator [length]="resultsLength"  [pageSizeOptions]="[10]"></mat-paginator>
    </div>
</div>
<div class="button-container export" >        
    <button mat-fab color="secondary" title="Exportar expediente" aria-label="Exportar expediente">
        <img width="30px" src="assets/excel.png" />
    </button>
</div>
 -->