import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Archivo } from '../models/archivo';
import { Mensaje } from '../models/mensaje';
import { Session } from '../models/session';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class FuncionesPrincipalService {

  protected basePath = this.sessionService.getBasePath();

  constructor(private http: HttpClient, private sessionService: SessionService) { }

  login(email: string, password: string){
    return this.http.post<Session>(this.basePath + 'login', {'email': email, 'password': password});
  }

  //Funcioness con autenticado
  addMensajeEncargo(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'addMensajeEncargo', datos, httpOptions);
  }

  addMensajeEncargoGenerali(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'addMensajeEncargoGenerali', datos, httpOptions);
  }
  
  getEncargoById(idEncargo: number, idUsuario : number){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getEncargoById', {'idEncargo': idEncargo, 'idUsuario': idUsuario}, httpOptions);
  }

  getEncargoByIdGenerali(idEncargo: number, idUsuario : number){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getEncargoByIdGenerali', {'idEncargo': idEncargo, 'idUsuario': idUsuario}, httpOptions);
  }

  getEncargoByIdPericial(idEncargo: number, idUsuario : number){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getEncargoByIdPericial', {'idEncargo': idEncargo, 'idUsuario': idUsuario}, httpOptions);
  }

  getMensajesEncargo(idEncargo: number, idUsuario : number){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getMensajesEncargo', { 'idEncargo': idEncargo, 'idUsuario': idUsuario}, httpOptions);
  }

  getMensajesEncargoGenerali(idEncargo: number, idUsuario : number){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getMensajesEncargoGenerali', { 'idEncargo': idEncargo, 'idUsuario': idUsuario}, httpOptions);
  }

  mensajesLeidos(mensajes: Mensaje[], idUsuario : number){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'mensajesLeidos', { 'mensajes': mensajes, 'idUsuario': idUsuario}, httpOptions);
  }

  mensajesLeidosGenerali(mensajes: Mensaje[], idUsuario : number){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'mensajesLeidosGenerali', { 'mensajes': mensajes, 'idUsuario': idUsuario}, httpOptions);
  }

  updateDatosEncargo(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'updateDatosEncargo', datos, httpOptions);
  }

  updateDatosEncargoGenerali(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'updateDatosEncargoGenerali', datos, httpOptions);
  }

  updateDatosEncargoPericial(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'updateDatosEncargoPericial', datos, httpOptions);
  }

  generarInformeMedico(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'generarInformeMedico', datos, httpOptions);
  }

  generarInformeMedicoGenerali(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'generarInformeMedicoGenerali', datos, httpOptions);
  }

  pasarInformeFision(origen: any, refSiniestro: string, nombreLesionado: string, fechaInforme: string){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'pasarInformeFision', {'origen' : origen, 'refSiniestro' : refSiniestro, 'nombreLesionado' : nombreLesionado, 'fechaInforme' : fechaInforme}, httpOptions);
  }

  generarInformeMedicoPericial(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'generarInformeMedicoPericial', datos, httpOptions);
  }

  borrarEncargo(idEncargo: number){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'borrarEncargo', {'idEncargo': idEncargo}, httpOptions);
  }

  borrarEncargoGenerali(idEncargo: number){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'borrarEncargoGenerali', {'idEncargo': idEncargo}, httpOptions);
  }

  cambiarEstadoEncargo(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'cambiarEstadoEncargo', datos, httpOptions);
  }

  cambiarEstadoEncargoPericial(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'cambiarEstadoEncargoPericial', datos, httpOptions);
  }

  cambiarEstadoEncargoGenerali(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'cambiarEstadoEncargoGenerali', datos, httpOptions);
  }

  verArchivo(path: string): any {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post<any>(this.basePath + 'verArchivo', {'archPath': path,}, httpOptions);
  }

  copiarPegar(datos: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'copyPaste', datos, httpOptions);
  }

  copiarPegarPericiales(datos: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'copyPastePericial', datos, httpOptions);
  }

  borrarArchivo(rutaArchivo: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'borrarArchivo', {'rutaArchivo': rutaArchivo}, httpOptions);
  }

  borrarArchivoPericiales(rutaArchivo: any, idEncargo: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'borrarArchivoPericial', {'rutaArchivo': rutaArchivo, 'idEncargo' : idEncargo}, httpOptions);
  }

  crearAut(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'crearAut', datos, httpOptions);
  }

  updateAut(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'updateAut', datos, httpOptions);
  }

  cambiarEstadoEstrella(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'cambiarEstadoEstrella', datos, httpOptions);
  }

  downloadZipFolder(archivos: Archivo[], rutaCarpetaDocMédico : string){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'downloadZipFolder', { 'archivos': archivos, 'rutaCarpetaDocMédico': rutaCarpetaDocMédico}, httpOptions);
  }

  deleteZipFolder(rutaCarpetaDocMédico : string){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'deleteZipFolder', {'ruta': rutaCarpetaDocMédico}, httpOptions);
  }  

  /* addMensajeEncargo(datos: any){
    return this.http.post(this.basePath + 'addMensajeEncargo', datos);
  }

  getEncargoById(idEncargo: number){
    return this.http.post(this.basePath + 'getEncargoById', {'idEncargo': idEncargo});
  }

  updateDatosEncargo(datos: any){
    return this.http.post(this.basePath + 'updateDatosEncargo', datos);
  }

  generarInformeMedico(datos: any){
    return this.http.post(this.basePath + 'generarInformeMedico', datos);
  }

  borrarEncargo(idEncargo: number){
    return this.http.post(this.basePath + 'borrarEncargo', {'idEncargo': idEncargo});
  }

  cambiarEstadoEncargo(datos: any){
    return this.http.post(this.basePath + 'cambiarEstadoEncargo', datos);
  } */
}
