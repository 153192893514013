import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-borrar-encargo-dialog',
  templateUrl: './borrar-encargo-dialog.component.html',
  styleUrls: ['./borrar-encargo-dialog.component.scss']
})
export class BorrarEncargoDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
