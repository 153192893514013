import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FacturacionServiceService } from '../../services/facturacion-service.service';

@Component({
  selector: 'app-tablas-admin',
  templateUrl: './tablas-admin.component.html',
  styleUrls: ['./tablas-admin.component.scss']
})
export class TablasAdminComponent implements OnInit {

  loadingPageFlag: boolean = false;
  loadingTableFlag: boolean = false;

  ent_id_entidades: string = '';
  data: any[] = [];
  filteredValues: any = {};

  displayedColumns: string[] = [];
  filterColumns: Filter[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource(this.data);

  constructor(
    private routerActive: ActivatedRoute,
    private facturacionSevice: FacturacionServiceService,
  ) { }

  ngOnInit(): void {
    this.data = [];
    this.routerActive.queryParams.subscribe(params => {
      this.ent_id_entidades = params['id'];
      console.log(this.ent_id_entidades);

      this.obtenerEntidadTablaInit();
      
    });
  }

  obtenerEntidadTablaInit(){

    this.loadingPageFlag = true;

    this.facturacionSevice.obtenerEntidadTabla(this.ent_id_entidades).subscribe({
      next : (data: any) => {
        this.displayedColumns = [];
        this.filterColumns = [];
        this.filteredValues = {};
        this.data = data.data;
        this.dataSource = new MatTableDataSource(this.data);

        if(this.data.length > 0){
          Object.getOwnPropertyNames(this.data['0']).forEach(name => {
            this.filterColumns.push({
              column_name : name,
              selected : false,
            });
            this.filteredValues[name] = '';  
          })

          Object.getOwnPropertyNames(this.data['0']).forEach(name => {
            this.displayedColumns.push(name);
          })
        }

        console.log(this.filteredValues);

        this.loadingPageFlag = false;

      },
      error : (err: any) => {
        console.log(err);
        this.loadingPageFlag = false;
      }
    })
  }

  obtenerEntidadTabla(){

    this.loadingTableFlag = true;

    this.facturacionSevice.obtenerEntidadTabla(this.ent_id_entidades).subscribe({
      next : (data: any) => {
        this.displayedColumns = [];
        this.filteredValues = {};
        this.data = data.data;
        this.dataSource = new MatTableDataSource(this.data);

        if(this.filterColumns.filter(value => value.selected).length == 0){
          Object.getOwnPropertyNames(this.data['0']).forEach(name => {
            this.displayedColumns.push(name);
            this.filteredValues[name] = '';  
          })
        }
        else{
          Object.getOwnPropertyNames(this.data['0']).forEach(name => {
            if(this.filterColumns.filter(value => value.column_name == name && value.selected).length > 0){
              this.displayedColumns.push(name);
              this.filteredValues[name] = '';  
            }
          })
        }

        console.log(this.filteredValues);

        this.loadingTableFlag = false;

      },
      error : (err: any) => {
        console.log(err);
        this.loadingTableFlag = false;
      }
    })
  }

  searchTabla() {
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.dataSource.filter = JSON.stringify(this.filteredValues);
  }

  customFilterPredicate() {
    const myFilterPredicate = (data: any, filter: string): boolean => {
      let searchString = JSON.parse(filter);
      let arrayCond: boolean[] = [];

      Object.entries(data).forEach((dataInd: [string, string]) => {
        arrayCond.push((dataInd[1] != undefined ? dataInd[1] : '').toString().toLowerCase()
        .includes((searchString[dataInd[0]] != undefined ? searchString[dataInd[0]] : '').toLowerCase()));
      });

      return arrayCond.filter(cond => !cond).length == 0;
    }
    return myFilterPredicate;
  }

}

interface Filter{
  column_name : string;
  selected : boolean;
}
