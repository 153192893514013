import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from './services/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'celsus';
  expires_at : Date;
  warningDisplayed: boolean = false; // Para evitar que el mensaje se muestre repetidamente


  constructor(private router: Router, private sessionService: SessionService ) {
    // this.expires_at = this.convertStringToDate(this.sessionService.getCurrentSession().expires_at);
    this.initSession();
    this.checkSession();
   }

   initSession() {
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      const parsedUser = JSON.parse(currentUser);
      this.expires_at = this.convertStringToDate(parsedUser.expires_at);
    }
  }

   checkSession() {
    setInterval(() => this.cerrarSesion(), 2000);
   }

   cerrarSesion() {
    const currentUser = localStorage.getItem('currentUser');
    if (!currentUser) {
      this.router.navigate(['']);
      return;
    }

    const parsedUser = JSON.parse(currentUser);
    this.expires_at = this.convertStringToDate(parsedUser.expires_at);
    const now = new Date();

    if (this.expires_at <= now && !this.warningDisplayed) {
      // Si expires_at es menor o igual que now y no se ha mostrado la advertencia
      this.showWarning();
      return;
    }

    const timeDifference = this.expires_at.getTime() - now.getTime();

    if (timeDifference <= 0) {
      localStorage.removeItem('currentUser');
      this.router.navigate(['']);
      return;
    } else if (timeDifference <= 600000 && !this.warningDisplayed) {
      this.showWarning();
    } else {
      if (this.router.url != '/') {
        if (!localStorage.getItem('currentUser')) {
          this.router.navigate(['']);
        }
      }else{
        if(localStorage.getItem('currentUser')) {
          if(parsedUser.tramitador) {
            this.router.navigate(['tramitador-principal']);
          }else{
            this.router.navigate(['medico-principal/valoracionDocumental']);
          }
        }
      }
    }
  }

  showWarning() {
    this.warningDisplayed = true;
    const confirmed = confirm("La sesión va a expirar en breve. ¿Desea continuar en CELSUS?");

    if (confirmed) {
      // Actualizar expires_at por 8 horas más
      const newExpiresAt = new Date();
      newExpiresAt.setHours(newExpiresAt.getHours() + 8);
      const currentUser = localStorage.getItem('currentUser');
      if (currentUser) {
        const parsedUser = JSON.parse(currentUser);
        parsedUser.expires_at = newExpiresAt.toISOString(); // Actualizar el valor en el objeto
        localStorage.setItem('currentUser', JSON.stringify(parsedUser)); // Actualizar en localStorage
        this.expires_at = newExpiresAt; // Actualizar en la instancia del componente
        this.warningDisplayed = false;
      }
    } else {
      // Cerrar sesión
      localStorage.removeItem('currentUser');
      this.router.navigate(['']);
    }
  }

  convertStringToDate(dateString: string): Date {
    // Reemplaza el espacio por una "T"
    const formattedDateString = dateString.replace(' ', 'T');
    // Crea un nuevo objeto Date
    return new Date(formattedDateString);
  }
}
