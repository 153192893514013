import { DatePipe, formatDate } from '@angular/common';
import { AfterViewChecked, Component, ElementRef, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { BorrarArchivoDialogComponent } from '../../../dialogs/borrar-archivo-dialog/borrar-archivo-dialog.component';
import { BorrarEncargoDialogComponent } from '../../../dialogs/borrar-encargo-dialog/borrar-encargo-dialog.component';
import { CancelarEncargoDialogComponent } from '../../../dialogs/cancelar-encargo-dialog/cancelar-encargo-dialog.component';
import { EnviarDialogComponent } from '../../../dialogs/enviar-dialog/enviar-dialog.component';
import { FinalizadoDialogComponent } from '../../../dialogs/finalizado-dialog/finalizado-dialog.component';
import { PendienteRevisionDialogComponent } from '../../../dialogs/pendiente-revision-dialog/pendiente-revision-dialog.component';
import { ReEnviarDesdeFinalizadoDialogComponent } from '../../../dialogs/re-enviar-desde-finalizado-dialog/re-enviar-desde-finalizado-dialog.component';
import { Archivo } from '../../../models/archivo';
import { CamposVideoConsulta } from '../../../models/campos-video-consulta';
import { EncargoGenerali } from '../../../models/encargoGenerali';
import { FormatoConsulta } from '../../../models/formato_consulta/formato-consulta.model';
import { Medico } from '../../../models/medico';
import { Mensaje } from '../../../models/mensaje';
import { Tipo } from '../../../models/tipo';
import { Tramitador } from '../../../models/tramitador';
import { FileService } from '../../../services/file.service';
import { FuncionesPrincipalService } from '../../../services/funciones-principal.service';
import { MedicoPortalService } from '../../../services/medico-portal.service';
import { NotificacionService } from '../../../services/notificacion.service';
import { SessionService } from '../../../services/session.service';
import { TramitadorPortalService } from '../../../services/tramitador-portal.service';
import { Tramitadores } from '../../../models/tramitadores';
import { EnviarFisionDialogComponent } from 'src/app/dialogs/enviar-fision-dialog/enviar-fision-dialog.component';

declare var JitsiMeetExternalAPI: any;


@Component({
  selector: 'app-encargo-tramitador-vdc',
  templateUrl: './encargo-tramitador-vdc.component.html',
  styleUrls: ['./encargo-tramitador-vdc.component.scss']
})
export class EncargoTramitadorVdcComponent implements OnInit, AfterViewChecked {

  private idEncargo;
  tramitador: Tramitador;
  mostrarProgressSpinner = true;
  textoMensaje: string;
  encargo: EncargoGenerali;
  encargoAnterior: EncargoGenerali;
  encargosAnterioresAsesoriaGraves: EncargoGenerali[];
  encargosBySiniestro: EncargoGenerali[];
  countMensajes: number;
  mensajes: Mensaje[] = [];
  mostrarCancel: boolean = false;
  mostrarBorrar: boolean = false;
  mostrarEnviar: boolean = false;
  mostrarReEnviar: boolean = false;
  mostrarPendienteRevision: boolean = false;
  mostrarFinalizado: boolean = false;
  mostrarReEnviarFinalizado: boolean = false;
  mostrarSelectTramitacion: boolean = false;
  comentario: string;
  isUrgente: boolean;
  moverEncargoFinalizado: boolean = false;
  enviarFision : boolean;
  private mensajesChecked: number[] = [];
  public archivoPegar: Archivo;
  public archivos: Observable<Archivo[]> = null;
  public imageSelect: string;
  public tipoArchivo: string = '';
  rutaSala: string;
  addClassSecuelasAntiguas: boolean = false;
  addClassSecuelasEsteticasAntiguas: boolean = false;
  addClassIntervencionesAntiguas: boolean = false;

  //Campos informe
  numExpediente: string;
  nombreMedico: string;
  numColegiado: string;
  nombreLesionado: string;
  edadLesionado: number;
  provincia: string;
  localidad: string;
  responsabilidad: string;
  peticion: string;
  fechaSiniestro: string;
  fechaLimite: string;
  refSiniestro: string;
  consideracion: string;
  conclusiones: string;
  idMedico: number;
  idTipo: number;
  medicos: Medico[];
  tipos: Tipo[];
  mecanismoProduccion: string;
  condicionLesionado: string;
  idNombreTramitador : number;
  tramitadores : Tramitadores[];
  fechaTarea : string;
  encargosAnterioresFision : any[];

  //Campos informe REHUSE CAS
  objetoInforme: string;
  profesion: string;
  situacionLaboral: string;
  bajaLaboral: string;
  fechaBajaLaboral: string;
  fechaAltaLaboral: string;
  contingencia: string;
  antecedentes: string;
  centroUrgencias: string;
  cuandoAcude: string;
  diagnosticoUrgencias: string;
  hospitalizacionUrgencias: string;
  centroRehab: string;
  medicoSeguimiento: string;
  inicioTratamiento: string;
  altaMedica: string;
  numConsultas: string;
  numSesiones: string;
  pruebasRealizadas: string;
  evolucion: string;

  //Campos informe REHUSE CAS + VIDEO
  fuenteDocumental: string;
  referenciaInforme: string;
  firmadoPor: string;
  tipoImpacto: string;
  deltaV: string;
  aceleracion: string;
  fechaVideoConsulta: string;
  mediosUsados: string;
  anamnesis: string;
  exploracionFisica: string;
  id_formato_consulta: number;
  formatoConsulta : FormatoConsulta[];


  //Campos VALORACIÓN VIDEO-CONSULTA
  pruebasRealizadasVideoConsulta: string;
  tratamientoRehabilitador: string;
  intervencionesQuirurgicas: string;
  diasBasicos: string;
  diasModerados: string;
  diasGraves: string;
  diasMuyGraves: string;
  diasTotales: string;
  diasBasicosMax: string;
  diasModeradosMax: string;
  diasGravesMax: string;
  diasMuyGravesMax: string;
  diasTotalesMax: string;
  descripcionPerjuicioEstetico: string;
  puntosPerjuicioEstetico: string;
  puntosPerjuicioEsteticoMax: string;
  perjuicioMoral: string = "No";
  grado: string;
  porcentajeGrado: string;
  perjuicioPersonal: string = "No";
  grupoQuirurgico: string;
  otrosCorrectores: string = "No";
  observaciones: string;
  observacionesIMD: string;
  secuelaForm: FormGroup;
  intervencionForm: FormGroup;
  secuelaHorquillaForm: FormGroup;
  secuelaObj;
  intervencionObj;
  numSecuelas: number;
  numIntervenciones: number;
  puntosTotalesSecuelas: number = 0;
  puntosTotalesIntervenciones: number = 0;
  puntosMinTotalesSecuelas: number = 0;
  puntosMaxTotalesSecuelas: number = 0;
  observacionesAseguradora: string;
  proximaRevision: string;

  //Modelo estudio documental

  valoracionFinal: boolean = false;
  valoracionProv: boolean = false;
  imd: boolean = true;


  //Secuelas estéticas
  secuelasEsteticasForm: FormGroup;
  secuelasEsteticasObj;
  numSecuelasEsteticas: number;
  puntosTotalesSecuelasEsteticas: number = 0;

  //reserva técnica
  reservaTecnica : boolean = false;
  dias_ppp_basicos_reserva : string;
  dias_ppp_moderados_reserva : string;
  dias_ppp_graves_reserva : string;
  dias_ppp_muy_graves_reserva : string;
  dias_ppp_totales_reserva : string;
  secuelas_reserva : string;
  descripcion_perjuicio_estetico_reserva : string;
  puntos_perjuicio_estetico_reserva : string;
  perjuicio_moral_reserva : string;
  perjuicio_personal_reserva : string;
  grado_reserva : string;
  porcentaje_reserva : string;
  x_dano_moral_reserva : boolean;
  x_dano_moral_estetico_reserva : boolean;
  x_perjuicio_moral_reserva : boolean;
  x_perjuicio_excepcional_reserva : boolean;
  x_gastos_previsibles_reserva : boolean ;
  x_protesis_ortesis_reserva : boolean;
  x_rehabilitacion_reserva : boolean;
  x_ayudas_tecnicas_reserva : boolean;
  x_adecuacion_reserva : boolean;
  x_perjuicio_patrimonial_reserva : boolean;
  x_horas_necesarias_reserva : boolean;
  otros_correctores_reserva : string;
  horas_necesarias_reserva : string;
  observaciones_reserva : string;

  //Seguimiento Diversos
  evolutivoConsultasValoracion: string;
  fechaProximaConsulta: string;

  //Seguimiento No Auto
  situacionIncapacidad : string;
  tiempoRecuperacion : number;

  //Asesoria graves
  numInforme: string;
  fechaAsesoria: string;
  asesorMedico: string;
  observacionesAsesoria: string;

  fechaConsultaVideoConsulta: string;
  horaConsultaVideoConsulta: string;

  fechaLimiteFormmated : string;

  camposVideoConsultaAnteriores: CamposVideoConsulta[];

  idIntervalGuardado;

  existeDocMedico: boolean = true;

  dpFechaVideoConsulta = new FormControl(null);

  rutaCarpetaDocMedico : string;
  archivosCarpetaDocMedico: Archivo[];

  //File Explorer
  currentRoot: Archivo;
  currentPath: string;
  canNavigateUp = false;

  //Campos Generali nuevos
  estadoInicial         : string;
  fechaUltimaConsulta   = new FormControl(null);
  estadoFinal           : string;
  bioFuncional          : string;
  coherenciaSeguimiento : string;
  fechaPrueba           = new FormControl(null);
  consistenciaPrueba    : string;
  movilidad             : string;

  constructor(private el: ElementRef, 
    private route: ActivatedRoute, 
    private medicoService: MedicoPortalService,
    private tramitadorService: TramitadorPortalService,
    private funcionesService: FuncionesPrincipalService, 
    private notificacion: NotificacionService, 
    public fileService: FileService, 
    private router: Router, 
    private dialog: MatDialog, 
    private sessionService: SessionService,
    private datePipe: DatePipe,
    private fb:FormBuilder) {

      this.secuelaForm = this.fb.group({
        secuelas: this.fb.array([])
      });

      this.intervencionForm = this.fb.group({
        intervenciones: this.fb.array([])
      });

      this.secuelasEsteticasForm = this.fb.group({
        secuelas: this.fb.array([])
      });

      this.secuelaHorquillaForm = this.fb.group({
        secuelas: this.fb.array([])
      });

      this.encargo = new EncargoGenerali();
      this.tramitador = this.sessionService.getCurrentSession().tramitador;
      //Para poder refrescar cambios en la pagina cuando navego desde ella misma, ej cambio de encargo en el historico siniestro
      this.route.params.subscribe(params =>{
        this.idEncargo = params['idEncargo'];
        this.idTipo    = params['idTipo'];
        //this.ngOnInit();
      });
  }

  //Bloquear todos los campos cuando esten en finalizado
  ngAfterViewChecked(): void {
    if(this.encargo.upgen_id_estado_encargo == 4){
      let inputDisable = document.querySelectorAll('input');
      let textareaDisable = document.querySelectorAll('textarea');
  
      inputDisable.forEach(element => {
        element.disabled = true;
      });
  
      textareaDisable.forEach(element => {
        //No desabilitar el text area del dialog para reenviar desde finalizado a enviado
        if(element.id == "dialogReEnviarFinalizado"){
          element.disabled = false;
        }else{
          element.disabled = true;
        }
      });
    }

    // si esta en estado de tramitacion
    if(this.encargoAnterior && this.encargo.upgen_id_estado_encargo == 1){
      //Rellenar los campos de informe con el encargo anterior
      this.rellenarCamposInformeEncargoAnterior();
    }

  }


  ngOnInit(): void {
    this.mostrarProgressSpinner = true;
    this.archivos = null;
    //Inicio explorador de archivos
    this.fileService.init();

    //Me traigo los medicos y estados para rellenar el select que wsolo aparece en estado tramitacion
    this.getMedicosTiposTramitacion();
    this.getFormatoConsulta();
    this.getTramitadores();

    this.getEncargo();
    this.getMensajesEncargo();

    //Cuando haya algun cambio en las secuelas para mostrar el total de puntos
    this.secuelaForm.get("secuelas").valueChanges.subscribe(selectedValue =>{
      this.totalPuntosSecuelas();
    });

    //Cuando haya algun cambio en las secuelas estéticas para mostrar el total de puntos
    this.secuelasEsteticasForm.get("secuelas").valueChanges.subscribe(selectedValue =>{
      this.totalPuntosSecuelasEsteticas();
    });


    //Cuando haya algun cambio en las secuelas para mostrar el total de puntos
    this.secuelaHorquillaForm.get("secuelas").valueChanges.subscribe(selectedValue =>{
      this.totalPuntosSecuelasMin();
      this.totalPuntosSecuelasMax();
    });
  }


  getFormatoConsulta() {
    this.tramitadorService.getFormatoConsulta().subscribe({
      next: (data: any) => {
        console.log(data);
        this.formatoConsulta = data['data'];
      },
      error: (err: any) => {
        console.log(err);
      },
      
    });
  }

  getTramitadores() {
    this.tramitadorService.getTramitadores().subscribe({
      next: (data: any) => {
        this.tramitadores = data['data'];
      },
      error: (err: any) => {
        console.log(err);
      },
      
    });
  }

  ngOnDestroy(): void{
    //Eliminar guardado automatico
    if(this.idIntervalGuardado){
      clearInterval(this.idIntervalGuardado);
    }
  }

  //Guardado automatico
  guardadoAutomatico(){
    this.idIntervalGuardado = setInterval(()=>{
      this.guardarDatosEncargo("automatico");
    }, 15000);
  }

  secuelas(): FormArray{
    return this.secuelaForm.get("secuelas") as FormArray;
  }

  secuelasEsteticas(): FormArray{
    return this.secuelasEsteticasForm.get("secuelas") as FormArray;
  }

  intervenciones(): FormArray{
    return this.intervencionForm.get("intervenciones") as FormArray;
  }

  nuevaSecuela(): FormGroup {  
    return this.fb.group({  
      codigo: '',  
      descripcion: '',
      puntos: '',
    });
  }  

  nuevaSecuelasEsteticas(): FormGroup {  
    return this.fb.group({  
      codigo: '',  
      descripcion: '',
      puntos: '',
    });
  }  

  nuevaIntervencion(): FormGroup {  
    return this.fb.group({  
      grupo: '',  
      descripcion: '',
    });
  }  

  addNuevasSecuelas(){
    this.secuelas().push(this.nuevaSecuela());
    this.addClassSecuelasAntiguas = false;
  }

  addNuevasSecuelasEsteticas(){
    this.secuelasEsteticas().push(this.nuevaSecuelasEsteticas());
    this.addClassSecuelasEsteticasAntiguas = false;
  }

  addNuevasIntervenciones(){
    this.intervenciones().push(this.nuevaIntervencion());
    this.addClassIntervencionesAntiguas = false;
  }

  quitarSecuela(i:number){
    this.secuelas().removeAt(i);
    this.addClassSecuelasAntiguas = false;
  }

  quitarSecuelasEsteticas(i:number){
    this.secuelasEsteticas().removeAt(i);
    this.addClassSecuelasEsteticasAntiguas = false;
  }

  quitarIntervencion(i:number){
    this.intervenciones().removeAt(i);
    this.addClassIntervencionesAntiguas = false;
  }

  secuelasHorquilla(): FormArray{
    return this.secuelaHorquillaForm.get("secuelas") as FormArray;
  }

  nuevaSecuelaHorquilla(): FormGroup {  
    return this.fb.group({  
      codigo: '',  
      descripcion: '',
      puntosMin: '',
      puntosMax: '',
    });
  }  

  addNuevasSecuelasHorquilla(){
    this.secuelasHorquilla().push(this.nuevaSecuelaHorquilla());
    this.addClassSecuelasAntiguas = false;
  }

  quitarSecuelaHorquilla(i:number){
    this.secuelasHorquilla().removeAt(i);
    this.addClassSecuelasAntiguas = false;
  }

  sumarDiasPPP(){
    let total : number;
    total = parseInt(this.diasBasicos) + parseInt(this.diasModerados) + parseInt(this.diasGraves) + parseInt(this.diasMuyGraves);
    this.diasTotales = total.toString();
  }

  sumarDiasPPPMax(){
    let total : number;
    total = parseInt(this.diasBasicosMax) + parseInt(this.diasModeradosMax) + parseInt(this.diasGravesMax) + parseInt(this.diasMuyGravesMax);
    this.diasTotalesMax = total.toString();
  }

  getEncargo(){
    this.funcionesService.getEncargoByIdGenerali(this.idEncargo,this.tramitador.trm_id_user).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        
        if(data['response'] == "ok"){
          this.encargo = data['encargo'];
          //console.log(this.encargo);
          this.encargoAnterior = data['encargoAnterior'];
          this.fechaLimiteFormmated = this.datePipe.transform(this.encargo.upgen_fecha_limite,"yyyy-MM-dd");
          this.id_formato_consulta = this.encargo.formato_consulta;

          this.countMensajes = data['countMensajes'];
          console.log(data['countMensajes']);
          //mostrar botones Fab
          this.mostrarBotonesFab();
          //Traerme los encargos con la misma referencia siniestro
          this.getEncargosBySiniestro(this.encargo.upgen_referencia_siniestro);

          //Una vez cargado el encargo relleno el campo de los informes
          this.rellenarCamposInforme();

          //traerme los archivos del encargo
          this.getArchivosExplorador();

          //comprobar si existe la carpeta DOC Medico
          this.existeCarpetaDocMedico();

          //Traigo los datos de las consultas anteriores del excel
          this.getDataExcel();

          //guardado automatico
          if(this.encargo.upgen_id_estado_encargo != 1){
            this.guardadoAutomatico();
          }
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  getEncargosBySiniestro(refSiniestro: string){
    this.mostrarProgressSpinner = true;
    this.medicoService.getEncargosBySiniestro(refSiniestro, this.tramitador.trm_id_user, 1).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          //Se omiten los encargos cancelados
          this.encargosBySiniestro = data['encargosBySiniestro'].filter(encargo => encargo.upgen_id_estado_encargo != 5);
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  getMensajesEncargo(){
    this.mostrarProgressSpinner = true;
    this.funcionesService.getMensajesEncargoGenerali(this.idEncargo,this.tramitador.trm_id_user).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          this.mensajes = data['mensajes'];
        }else{

        }
      }
    );
  }

  existeCarpetaDocMedico(){
    let datos = {
      'numExpediente': this.encargo.upgen_expediente,
      'idTipoEncargo': this.encargo.upgen_id_tipo_encargo,
      'idEstado': this.encargo.upgen_id_estado_encargo,
      'idCia': this.encargo.upgen_cia
    };

    //Me traigo los archivos para el explorador
    this.medicoService.getArchivosExploradorMedicoGenerali(datos).subscribe(
      data => {
        if(data['response'] == "ok"){
          //console.log(data['archivos']);
          this.rutaCarpetaDocMedico = data['ruta'];
          this.archivosCarpetaDocMedico =data['archivos'];
          this.existeDocMedico = true;
        }else if(data['response'] == "fail"){
          this.existeDocMedico = false;
          this.notificacion.notificationFail(data['mensaje']); 
        }else{
          this.existeDocMedico = false;
        }
        
      }, error => {
        this.mostrarProgressSpinner = false;
        this.existeDocMedico = false;
      }
    );
  }

  //calcular puntos de las secuelas con la formula de balthazar
  totalPuntosSecuelasMin(){
    let totalSecuelas;
    let puntosArray: number[] = [];
    let i = 0;
    let resultado = 0;

    totalSecuelas = this.secuelaHorquillaForm.value;

    totalSecuelas.secuelas.forEach(secuela =>{
      puntosArray.push(parseInt(secuela.puntosMin));
    });

    if(puntosArray.length != 1){
      puntosArray.sort((a,b) => b-a);

      while(i<puntosArray.length){
        if(i==0){
          resultado = Math.ceil((((100-puntosArray[i]) * puntosArray[i+1])/100)+puntosArray[i]);
          i = i+2;
        }else{
          resultado = Math.ceil((((100-resultado) * puntosArray[i])/100)+resultado);
          i++;
        }
      }
    }else{
      resultado = puntosArray[0];
    }

    //Numero de secuelas
    this.numSecuelas = puntosArray.length;
    
    //Mostrar en variable
    this.puntosMinTotalesSecuelas = resultado;
    return resultado;

  }

  //calcular puntos de las secuelas con la formula de balthazar
  totalPuntosSecuelasMax(){
    let totalSecuelas;
    let puntosArray: number[] = [];
    let i = 0;
    let resultado = 0;

    totalSecuelas = this.secuelaHorquillaForm.value;

    totalSecuelas.secuelas.forEach(secuela =>{
      puntosArray.push(parseInt(secuela.puntosMax));
    });

    if(puntosArray.length != 1){
      puntosArray.sort((a,b) => b-a);

      while(i<puntosArray.length){
        if(i==0){
          resultado = Math.ceil((((100-puntosArray[i]) * puntosArray[i+1])/100)+puntosArray[i]);
          i = i+2;
        }else{
          resultado = Math.ceil((((100-resultado) * puntosArray[i])/100)+resultado);
          i++;
        }
      }
    }else{
      resultado = puntosArray[0];
    }

    //Numero de secuelas
    this.numSecuelas = puntosArray.length;
    
    //Mostrar en variable
    this.puntosMaxTotalesSecuelas = resultado;
    return resultado;

  }

  getArchivosExplorador(){
    let datos = {
      'numExpediente': this.encargo.upgen_expediente,
      'idTipoEncargo': this.encargo.upgen_id_tipo_encargo,
      'idEstado': this.encargo.upgen_id_estado_encargo,
      'idCia': this.encargo.upgen_cia
    }; 

    //Me traigo los archivos para el explorador
    this.tramitadorService.getArchivosExploradorTramitadorGenerali(datos).subscribe(
      data => {
        if(data['response'] == "ok"){
          this.fileService.loadMap(data['archivos']);
          this.archivos = observableOf(data['archivos']);
          this.updateFileElementQuery();
        }else if(data['response'] == "fail"){
          this.notificacion.notificationFail(data['mensaje']);
        }else{
          this.notificacion.notificationFail("Error");
        }
        
      }, error => {
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  getDataExcel() {
    this.mostrarProgressSpinner = true;
    this.tramitadorService.ExportExcelToArray(this.refSiniestro + '/' + this.refSiniestro + '.xlsx').subscribe({
      next: (data: any) => {
        console.log(data);
        this.mostrarProgressSpinner = false;
        this.encargosAnterioresFision = data['consultasAnteriores'];
      },
      error: (err: any) => {
        console.log(err);
      },
      
    });
  }

  rellenarCamposInforme(){
    this.numExpediente = this.encargo.upgen_expediente;
    //TODO No se si el nombre y el numero de colegiado del medico estaran vacios para el tramitador y los campos desabilitados
    this.nombreMedico = this.encargo.nombreMedico;
    //Si esta en tramitacion seleccionar el medico del select y el tipo
    this.mecanismoProduccion = this.encargo.upgen_mecanismo_produccion;
    this.idMedico = this.encargo.upgen_id_medico;
    this.idTipo = this.encargo.upgen_id_tipo_encargo;
    this.idNombreTramitador = this.encargo.upgen_id_nombre_tramitador;
    this.numColegiado = this.encargo.numColegiadoMedico;
    this.nombreLesionado = this.encargo.upgen_lesionado;
    this.edadLesionado = this.encargo.upgen_edad_lesionado;
    this.provincia = this.encargo.upgen_provincias;
    this.localidad = this.encargo.upgen_localidad;
    this.responsabilidad = this.encargo.upgen_responsabilidad_aseguradora;
    this.peticion = this.encargo.upgen_peticion;
    this.fechaSiniestro = this.encargo.upgen_fecha_siniestro;
    this.fechaLimite = this.encargo.upgen_fecha_limite;
    this.refSiniestro = this.encargo.upgen_referencia_siniestro;
    this.profesion = this.encargo.upgen_profesion;
    this.situacionLaboral = this.encargo.upgen_situacion_laboral;
    this.bajaLaboral = this.encargo.upgen_baja_laboral;
    this.fechaBajaLaboral = this.encargo.upgen_fecha_baja_laboral;
    this.fechaAltaLaboral = this.encargo.upgen_fecha_alta_laboral;
    this.contingencia = this.encargo.upgen_contingencia;
    this.antecedentes = this.encargo.upgen_antecedentes_personales;
    this.centroUrgencias = this.encargo.upgen_centro_urgencias;
    this.cuandoAcude = this.encargo.upgen_cuando_acude;
    this.diagnosticoUrgencias = this.encargo.upgen_diagnostico_urgencias;
    this.hospitalizacionUrgencias = this.encargo.upgen_hospitalizacion_urgencias;
    this.centroRehab = this.encargo.upgen_centro_rehabilitacion;
    this.medicoSeguimiento = this.encargo.upgen_medico_seguimiento;
    this.inicioTratamiento = this.encargo.upgen_inicio_tratamiento;
    this.altaMedica = this.encargo.upgen_alta_medica;
    this.numConsultas = this.encargo.upgen_num_consultas;
    this.numSesiones = this.encargo.upgen_num_sesiones;
    this.pruebasRealizadas = this.encargo.upgen_pruebas_realizadas;
    this.fuenteDocumental = this.encargo.upgen_fuente_documental;
    this.referenciaInforme = this.encargo.upgen_num_referencia_informe;
    this.firmadoPor = this.encargo.upgen_firmado_por;
    this.isUrgente = this.encargo.upgen_prioridad == 1 ? true : false;
    this.diasBasicos = this.encargo.upgen_dias_ppp_basicos == null ? "0" : this.encargo.upgen_dias_ppp_basicos;
    this.diasModerados = this.encargo.upgen_dias_ppp_moderados == null ? "0" : this.encargo.upgen_dias_ppp_moderados;
    this.diasGraves = this.encargo.upgen_dias_ppp_graves == null ? "0" : this.encargo.upgen_dias_ppp_graves;
    this.diasMuyGraves = this.encargo.upgen_dias_ppp_muy_graves == null ? "0" : this.encargo.upgen_dias_ppp_muy_graves;
    this.diasTotales = this.encargo.upgen_dias_ppp_totales == null ? "0" : this.encargo.upgen_dias_ppp_totales;
    this.diasBasicosMax = this.encargo.upgen_dias_ppp_basicos_max == null ? "0" : this.encargo.upgen_dias_ppp_basicos_max;
    this.diasModeradosMax = this.encargo.upgen_dias_ppp_moderados_max == null ? "0" : this.encargo.upgen_dias_ppp_moderados_max;
    this.diasGravesMax = this.encargo.upgen_dias_ppp_graves_max == null ? "0" : this.encargo.upgen_dias_ppp_graves_max;
    this.diasMuyGravesMax = this.encargo.upgen_dias_ppp_muy_graves_max == null ? "0" : this.encargo.upgen_dias_ppp_muy_graves_max;
    this.diasTotalesMax = this.encargo.upgen_dias_ppp_totales_max == null ? "0" : this.encargo.upgen_dias_ppp_totales_max;
    this.observaciones = this.encargo.upgen_observaciones;
    this.rutaSala = this.encargo.upgen_url_videoconferencia;
    this.condicionLesionado = this.encargo.upgen_condicion_lesionado;
    this.id_formato_consulta = this.encargo.formato_consulta;
    this.estadoInicial = this.encargo.upgen_estado_inicial;
    this.fechaUltimaConsulta =  new FormControl(this.encargo.upgen_fecha_ultima_consulta == null ? null : this.dateStringtoDate(this.encargo.upgen_fecha_ultima_consulta));
    this.estadoFinal = this.encargo.upgen_estado_final;
    this.bioFuncional = this.encargo.upgen_bio_funcional;
    this.coherenciaSeguimiento = this.encargo.upgen_coherencia_seguimiento;
    this.fechaPrueba = new FormControl(this.encargo.upgen_fecha_prueba == null ? null : this.dateStringtoDate(this.encargo.upgen_fecha_prueba));
    this.consistenciaPrueba = this.encargo.upgen_consistencia_prueba;
    this.movilidad = this.encargo.upgen_movilidad;


    //rellenar campo dinamico secuelas
    //Si es VALORACIÓN VIDEO-CONSULTA
    //if(this.encargo.upgen_id_tipo_encargo == 5 || this.encargo.upgen_id_tipo_encargo == 8){
      this.secuelaObj = JSON.parse(this.encargo.upgen_secuelas == null ? '{"secuelas":[]}': this.encargo.upgen_secuelas);
      this.secuelaObj.secuelas.forEach(secuela =>{
        this.secuelas().push(this.fb.group(secuela));
      });
      this.numSecuelas = this.secuelas().length;
      //Si hay secuelas sumar puntos
      if(this.secuelas().length > 0){
        this.totalPuntosSecuelas();
      }

      //secuelas estéticas
      this.secuelasEsteticasObj = JSON.parse(this.encargo.upgen_secuelas_esteticas == null ? '{"secuelas":[]}': this.encargo.upgen_secuelas_esteticas);
      this.secuelasEsteticasObj.secuelas.forEach(secuela =>{
        this.secuelasEsteticas().push(this.fb.group(secuela));
      });
      this.numSecuelasEsteticas = this.secuelasEsteticas().length;
      //Si hay secuelas sumar puntos
      if(this.secuelasEsteticas().length > 0){
        this.totalPuntosSecuelasEsteticas();
      }

  }

  rellenarCamposInformeEncargoAnterior(){
    this.responsabilidad                          == null ? this.encargoAnterior.upgen_responsabilidad_aseguradora             != null ? (this.responsabilidad                         = this.encargoAnterior.upgen_responsabilidad_aseguradora,              this.addClassAnterior('responsabilidad')) : null : null;
    this.peticion                                 == null ? this.encargoAnterior.upgen_peticion                                != null ? (this.peticion                                = this.encargoAnterior.upgen_peticion,                                 this.addClassAnterior('peticion')) : null : null;
    this.profesion                                == null ? this.encargoAnterior.upgen_profesion                               != null ? (this.profesion                               = this.encargoAnterior.upgen_profesion,                                this.addClassAnterior('profesion')) : null : null;
    this.situacionLaboral                         == null ? this.encargoAnterior.upgen_situacion_laboral                       != null ? (this.situacionLaboral                        = this.encargoAnterior.upgen_situacion_laboral,                        this.addClassAnterior('situacionLaboral')) : null : null;
    this.bajaLaboral                              == null ? this.encargoAnterior.upgen_baja_laboral                            != null ? (this.bajaLaboral                             = this.encargoAnterior.upgen_baja_laboral,                             this.addClassAnterior('bajaLaboral')) : null : null;
    this.contingencia                             == null ? this.encargoAnterior.upgen_contingencia                            != null ? (this.contingencia                            = this.encargoAnterior.upgen_contingencia,                             this.addClassAnterior('contingencia')) : null : null;
    this.antecedentes                             == null ? this.encargoAnterior.upgen_antecedentes_personales                 != null ? (this.antecedentes                            = this.encargoAnterior.upgen_antecedentes_personales,                  this.addClassAnterior('antecedentes')) : null : null;
    this.centroUrgencias                          == null ? this.encargoAnterior.upgen_centro_urgencias                        != null ? (this.centroUrgencias                         = this.encargoAnterior.upgen_centro_urgencias,                         this.addClassAnterior('centroUrgencias')) : null : null;
    this.cuandoAcude                              == null ? this.encargoAnterior.upgen_cuando_acude                            != null ? (this.cuandoAcude                             = this.encargoAnterior.upgen_cuando_acude,                             this.addClassAnterior('cuandoAcude')) : null : null;
    this.diagnosticoUrgencias                     == null ? this.encargoAnterior.upgen_diagnostico_urgencias                   != null ? (this.diagnosticoUrgencias                    = this.encargoAnterior.upgen_diagnostico_urgencias,                    this.addClassAnterior('diagnosticoUrgencias')) : null : null;
    this.centroRehab                              == null ? this.encargoAnterior.upgen_centro_rehabilitacion                   != null ? (this.centroRehab                             = this.encargoAnterior.upgen_centro_rehabilitacion,                    this.addClassAnterior('centroRehab')) : null : null;
    this.medicoSeguimiento                        == null ? this.encargoAnterior.upgen_medico_seguimiento                      != null ? (this.medicoSeguimiento                       = this.encargoAnterior.upgen_medico_seguimiento,                       this.addClassAnterior('medicoSeguimiento')) : null : null;
    this.inicioTratamiento                        == null ? this.encargoAnterior.upgen_inicio_tratamiento                      != null ? (this.inicioTratamiento                       = this.encargoAnterior.upgen_inicio_tratamiento,                       this.addClassAnterior('inicioTratamiento')) : null : null;
    this.altaMedica                               == null ? this.encargoAnterior.upgen_alta_medica                             != null ? (this.altaMedica                              = this.encargoAnterior.upgen_alta_medica,                              this.addClassAnterior('altaMedica')) : null : null;
    this.numConsultas                             == null ? this.encargoAnterior.upgen_num_consultas                           != null ? (this.numConsultas                            = this.encargoAnterior.upgen_num_consultas,                            this.addClassAnterior('numConsultas')) : null : null;
    this.numSesiones                              == null ? this.encargoAnterior.upgen_num_sesiones                            != null ? (this.numSesiones                             = this.encargoAnterior.upgen_num_sesiones,                             this.addClassAnterior('numSesiones')) : null : null;
    this.pruebasRealizadas                        == null ? this.encargoAnterior.upgen_pruebas_realizadas                      != null ? (this.pruebasRealizadas                       = this.encargoAnterior.upgen_pruebas_realizadas,                       this.addClassAnterior('pruebasRealizadas')) : null : null;
    this.fuenteDocumental                         == null ? this.encargoAnterior.upgen_fuente_documental                       != null ? (this.fuenteDocumental                        = this.encargoAnterior.upgen_fuente_documental,                        this.addClassAnterior('fuenteDocumental')) : null : null;
    this.referenciaInforme                        == null ? this.encargoAnterior.upgen_num_referencia_informe                  != null ? (this.referenciaInforme                       = this.encargoAnterior.upgen_num_referencia_informe,                   this.addClassAnterior('referenciaInforme')) : null : null;
    this.firmadoPor                               == null ? this.encargoAnterior.upgen_firmado_por                             != null ? (this.firmadoPor                              = this.encargoAnterior.upgen_firmado_por,                              this.addClassAnterior('firmadoPor')) : null : null;
    this.diasBasicos                              == "0"  ? this.encargoAnterior.upgen_dias_ppp_basicos                        != null && this.encargoAnterior.upgen_dias_ppp_basicos   != "0" ? (this.diasBasicos    = this.encargoAnterior.upgen_dias_ppp_basicos,       this.addClassAnterior('diasBasicos')) : null : null;
    this.diasModerados                            == "0"  ? this.encargoAnterior.upgen_dias_ppp_moderados                      != null && this.encargoAnterior.upgen_dias_ppp_moderados != "0" ? (this.diasModerados  = this.encargoAnterior.upgen_dias_ppp_moderados,     this.addClassAnterior('diasModerados')) : null : null;
    this.diasGraves                               == "0"  ? this.encargoAnterior.upgen_dias_ppp_graves                         != null && this.encargoAnterior.upgen_dias_ppp_graves    != "0" ? (this.diasGraves     = this.encargoAnterior.upgen_dias_ppp_graves,        this.addClassAnterior('diasGraves')) : null : null;
    this.diasMuyGraves                            == "0"  ? this.encargoAnterior.upgen_dias_ppp_muy_graves                     != null && this.encargoAnterior.upgen_dias_ppp_muy_graves!= "0" ? (this.diasMuyGraves  = this.encargoAnterior.upgen_dias_ppp_muy_graves,    this.addClassAnterior('diasMuyGraves')) : null : null;
    this.diasTotales                              == "0"  ? this.encargoAnterior.upgen_dias_ppp_totales                        != null && this.encargoAnterior.upgen_dias_ppp_totales   != "0" ? (this.diasTotales    = this.encargoAnterior.upgen_dias_ppp_totales,       this.addClassAnterior('diasTotales')) : null : null;
    this.observaciones                            == null ? this.encargoAnterior.upgen_observaciones                           != null ? (this.observaciones                           = this.encargoAnterior.upgen_observaciones,                            this.addClassAnterior('observaciones')) : null : null;
    this.secuelaObj                               == null ? this.encargoAnterior.upgen_secuelas                                != null ? (this.secuelaObj                              = this.encargoAnterior.upgen_secuelas,                                 this.addClassAnterior('secuelaObj')) : null : null;

    this.estadoInicial                            == null ? this.encargoAnterior.upgen_estado_inicial                          != null ? (this.estadoInicial                           = this.encargoAnterior.upgen_estado_inicial,                           this.addClassAnterior('estadoInicial')) : null : null;
    //this.fechaUltimaConsulta                      == null ? this.encargoAnterior.upgen_fecha_ultima_consulta                   != null ? (this.fechaUltimaConsulta                     = this.encargoAnterior.upgen_fecha_ultima_consulta,                    this.addClassAnterior('fechaUltimaConsulta')) : null : null;
    this.estadoFinal                              == null ? this.encargoAnterior.upgen_estado_final                            != null ? (this.estadoFinal                             = this.encargoAnterior.upgen_estado_final,                             this.addClassAnterior('estadoFinal')) : null : null;
    this.bioFuncional                             == null ? this.encargoAnterior.upgen_bio_funcional                           != null ? (this.bioFuncional                            = this.encargoAnterior.upgen_bio_funcional,                            this.addClassAnterior('bioFuncional')) : null : null;
    this.coherenciaSeguimiento                    == null ? this.encargoAnterior.upgen_coherencia_seguimiento                  != null ? (this.coherenciaSeguimiento                   = this.encargoAnterior.upgen_coherencia_seguimiento,                   this.addClassAnterior('coherenciaSeguimiento')) : null : null;
    //this.fechaPrueba                              == null ? this.encargoAnterior.upgen_fecha_prueba                            != null ? (this.fechaPrueba                             = this.encargoAnterior.upgen_fecha_prueba,                             this.addClassAnterior('fechaPrueba')) : null : null;
    this.consistenciaPrueba                       == null ? this.encargoAnterior.upgen_consistencia_prueba                     != null ? (this.consistenciaPrueba                      = this.encargoAnterior.upgen_consistencia_prueba,                      this.addClassAnterior('consistenciaPrueba')) : null : null;
    this.movilidad                                == null ? this.encargoAnterior.upgen_movilidad                               != null ? (this.movilidad                               = this.encargoAnterior.upgen_movilidad,                                this.addClassAnterior('movilidad')) : null : null;
    
    this.secuelasEsteticasObj                     == null ? this.encargoAnterior.upgen_secuelas_esteticas                      != null ? (this.secuelasEsteticasObj                    = this.encargoAnterior.upgen_secuelas_esteticas,                       this.addClassAnterior('secuelasEsteticasObj')) : null : null;



    //rellenar campo dinamico secuelas
    //Si es VALORACIÓN VIDEO-CONSULTA
    // if(this.encargo.upgen_id_tipo_encargo == 5 || this.encargo.upgen_id_tipo_encargo == 8){
      if(this.encargo.upgen_secuelas == null || this.encargo.upgen_secuelas == '{"secuelas":[]}'){
        if(this.secuelaObj.secuelas.length == 0){
          this.secuelaObj = JSON.parse(this.encargoAnterior.upgen_secuelas == null ? '{"secuelas":[]}': this.encargoAnterior.upgen_secuelas);
          this.secuelaObj.secuelas.forEach(secuela =>{
            this.secuelas().push(this.fb.group(secuela));
          });
          this.numSecuelas = this.secuelas().length;
          
          //Si hay secuelas sumar puntos
          if(this.secuelas().length > 0){
            this.totalPuntosSecuelas();
            //Añadir clase a secuelas antiguas
            this.addClassSecuelasAntiguas = true;
          }
        }
      }

      //secuelas estéticas
      if(this.encargo.upgen_secuelas_esteticas == null || this.encargo.upgen_secuelas_esteticas == '{"secuelas":[]}'){
        if(this.secuelasEsteticasObj.secuelas.length == 0){
          this.secuelasEsteticasObj = JSON.parse(this.encargoAnterior.upgen_secuelas_esteticas == null ? '{"secuelas":[]}': this.encargoAnterior.upgen_secuelas_esteticas);
          this.secuelasEsteticasObj.secuelas.forEach(secuela =>{
            this.secuelasEsteticas().push(this.fb.group(secuela));
          });
          this.numSecuelasEsteticas = this.secuelasEsteticas().length;
          
          //Si hay secuelas sumar puntos
          if(this.secuelasEsteticas().length > 0){
            this.totalPuntosSecuelasEsteticas();
            //Añadir clase a secuelas antiguas
            this.addClassSecuelasEsteticasAntiguas = true;
          }
        }
      }
    
  }

  addClassAnterior(idDiv){
    let div = document.getElementById(idDiv);
    if(div != null && div.innerText != null){
      div.classList.add('anterior');
    }
  }

  keyupClaseAnterior(value, id, valueAnterior){
    if(value != valueAnterior){
      document.getElementById(id).classList.remove('anterior');
    }else{
      document.getElementById(id).classList.add('anterior');
    }
  }

  //calcular puntos de las secuelas con la formula de balthazar
  totalPuntosSecuelas(){
    let totalSecuelas;
    let puntosArray: number[] = [];
    let i = 0;
    let resultado = 0;

    totalSecuelas = this.secuelaForm.value;

    totalSecuelas.secuelas.forEach(secuela =>{
      puntosArray.push(parseInt(secuela.puntos));
    });

    if(puntosArray.length != 1){
      puntosArray.sort((a,b) => b-a);

      while(i<puntosArray.length){
        if(i==0){
          resultado = Math.ceil((((100-puntosArray[i]) * puntosArray[i+1])/100)+puntosArray[i]);
          i = i+2;
        }else{
          resultado = Math.ceil((((100-resultado) * puntosArray[i])/100)+resultado);
          i++;
        }
      }
    }else{
      resultado = puntosArray[0];
    }

    //Numero de secuelas
    this.numSecuelas = puntosArray.length;
    
    //Mostrar en variable
    this.puntosTotalesSecuelas = resultado;
    return resultado;

  }

  totalPuntosSecuelasEsteticas() {
    let totalSecuelas;
    let puntosArray: number[] = [];
    let resultado = 0;

    totalSecuelas = this.secuelasEsteticasForm.value;

    // Recolectar los puntos en un array
    totalSecuelas.secuelas.forEach(secuela =>{
      puntosArray.push(parseInt(secuela.puntos));
    });

    // Sumar todos los puntos
    resultado = puntosArray.reduce((sum, current) => sum + current, 0);

    // Numero de secuelas
    this.numSecuelas = puntosArray.length;

    // Mostrar en variable
    this.puntosTotalesSecuelasEsteticas = resultado;
    return resultado;
  }

  mostrarBotonesFab(){
    switch(this.encargo.upgen_estado_encargo){
      //estado Tramitacion
      case 'Tramitacion':
        this.mostrarBorrar = true;
        this.mostrarEnviar = true;
        this.mostrarSelectTramitacion = true;
        break;

      case 'Enviado':
        this.mostrarCancel = true;
        this.mostrarPendienteRevision = true;
        this.mostrarFinalizado = true;
        this.mostrarSelectTramitacion = true;
        break;

      case 'Pendiente de revision':
        this.mostrarCancel = true;
        this.mostrarFinalizado = true;
        this.mostrarReEnviar = true;
        break;
      case 'Finalizado':
        this.mostrarCancel = true;
        this.mostrarReEnviarFinalizado = true;
        break;
    }
  }

  guardarDatosEncargo(opcion: string){
    console.log(this.id_formato_consulta);
    if(this.idTipo != null && this.idMedico != null){
      if(opcion != "automatico"){
        this.mostrarProgressSpinner = true;
      }

      if(this.fechaUltimaConsulta.value == "Invalid Date") {
        this.fechaUltimaConsulta.setValue(null);
      }
      if(this.fechaPrueba.value == "Invalid Date") {
        this.fechaPrueba.setValue(null);
      }
      let datos = {
        'idMensajesMarcados': this.mensajesChecked,
        'idEncargo': this.idEncargo,
        'numExpediente': this.numExpediente,
        'nombreMedico': this.nombreMedico,
        'idMedico': this.idMedico,
        'idTipo': this.idTipo,
        'numColegiado': this.numColegiado,
        'nombreLesionado': this.nombreLesionado,
        'edadLesionado': this.edadLesionado,
        'provincia': this.provincia,
        'localidad': this.localidad,
        'responsabilidad': this.responsabilidad,
        'peticion': this.peticion,
        'prioridad': this.isUrgente == true ? 1 : null,
        'fechaSiniestro': this.fechaSiniestro,
        'fechaLimite': this.encargo.upgen_fecha_limite,
        'refSiniestro': this.refSiniestro,
        'objetoInforme': this.objetoInforme,
        'profesion': this.profesion,
        'situacionLaboral': this.situacionLaboral,
        'bajaLaboral': this.bajaLaboral,
        'fechaBajaLaboral': this.fechaBajaLaboral,
        'fechaAltaLaboral': this.fechaAltaLaboral,
        'contingencia': this.contingencia,
        'antecedentes': this.antecedentes,
        'centroUrgencias': this.centroUrgencias,
        'cuandoAcude': this.cuandoAcude,
        'diagnosticoUrgencias': this.diagnosticoUrgencias,
        'hospitalizacionUrgencias': this.hospitalizacionUrgencias,
        'centroRehab': this.centroRehab,
        'medicoSeguimiento': this.medicoSeguimiento,
        'inicioTratamiento': this.inicioTratamiento,
        'altaMedica': this.altaMedica,
        'numConsultas': this.numConsultas,
        'numSesiones': this.numSesiones,
        'pruebasRealizadas': this.pruebasRealizadas,
        'evolucion': this.evolucion,
        'fuenteDocumental': this.fuenteDocumental,
        'referenciaInforme': this.referenciaInforme,
        'firmadoPor': this.firmadoPor,
        'tipoImpacto': this.tipoImpacto,
        'deltaV': this.deltaV,
        'aceleracion': this.aceleracion,
        'fechaVideoConsulta': formatDate(this.dpFechaVideoConsulta.value, 'dd/MM/yyyy', "en-GB") == "01/01/1970" ? null : formatDate(this.dpFechaVideoConsulta.value, 'dd/MM/yyyy', "en-GB"),
        'mediosUsados': this.mediosUsados,
        'anamnesis': this.anamnesis,
        'exploracionFisica': this.exploracionFisica,
        'consideracion': this.consideracion,
        'conclusiones': this.conclusiones,
        'pruebasRealizadasVideoConsulta': this.pruebasRealizadasVideoConsulta,
        'tratamientoRehabilitador': this.tratamientoRehabilitador,
        'intervencionesQuirurgicas': this.intervencionesQuirurgicas,
        'diasBasicos': this.diasBasicos,
        'diasModerados': this.diasModerados,
        'diasGraves': this.diasGraves,
        'diasMuyGraves': this.diasMuyGraves,
        'diasTotales': this.diasTotales,
        'diasBasicosMax': this.diasBasicosMax,
        'diasModeradosMax': this.diasModeradosMax,
        'diasGravesMax': this.diasGravesMax,
        'diasMuyGravesMax': this.diasMuyGravesMax,
        'diasTotalesMax': this.diasTotalesMax,
        'perjuicioMoral': this.perjuicioMoral,
        'grado': this.grado,
        'porcentajeGrado': this.porcentajeGrado,
        'perjuicioPersonal': this.perjuicioPersonal,
        'grupoQuirurgico': this.grupoQuirurgico,
        'otrosCorrectores': this.otrosCorrectores,
        'observaciones': this.observaciones,
        'observacionesIMD': this.observacionesIMD,
        'observacionesAseguradora': this.observacionesAseguradora,
        'proximaRevision': this.proximaRevision,
        'secuelasJson': JSON.stringify(this.secuelaForm.value),
        'descripcionPerjuicioEstetico': this.descripcionPerjuicioEstetico,
        'puntosPerjuicioEstetico': this.puntosPerjuicioEstetico,
        'puntosPerjuicioEsteticoMax': this.puntosPerjuicioEsteticoMax,
        'evolutivoConsultasValoracion': this.evolutivoConsultasValoracion,
        'fechaProximaConsulta': this.fechaProximaConsulta,
        'fechaConsultaVideoConsulta': this.fechaConsultaVideoConsulta,
        'horaConsultaVideoConsulta': this.horaConsultaVideoConsulta,
        'mecanismoProduccion': this.mecanismoProduccion,
        'rutaSala': this.rutaSala,
        'numInforme': this.numInforme,
        'fechaAsesoria': this.fechaAsesoria,
        'asesorMedico': this.asesorMedico,
        'observacionesAsesoria': this.observacionesAsesoria,
        'formato_consulta' : this.id_formato_consulta,
        'valoracionFinal'              : this.valoracionFinal,
        'valoracionProv'               : this.valoracionProv,
        'imd'                          : this.imd,
        'jsonIntervenciones'           : JSON.stringify(this.intervencionForm.value),
        'reservaTecnica'               : this.reservaTecnica,
        'situacionIncapacidad' : this.situacionIncapacidad,
        'tiempoRecuperacion' : this.tiempoRecuperacion,
        'idNombreTramitador' : this.idNombreTramitador,
        'estadoInicial'       : this.estadoInicial,
        'fechaUltimaConsulta' : this.fechaUltimaConsulta.value == null ? null : formatDate(this.fechaUltimaConsulta.value, 'dd/MM/yyyy', "en-GB"),
        'estadoFinal'         : this.estadoFinal,
        'bioFuncional'        : this.bioFuncional,
        'coherenciaSeguimiento' : this.coherenciaSeguimiento,
        'fechaPrueba'           : this.fechaPrueba.value == null ? null : formatDate(this.fechaPrueba.value, 'dd/MM/yyyy', "en-GB"),
        'consistenciaPrueba'    : this.consistenciaPrueba,
        'movilidad'             : this.movilidad,
        'secuelasEsteticasJson': JSON.stringify(this.secuelasEsteticasForm.value),

      };


      if(opcion == "enviar"){
        this.funcionesService.updateDatosEncargoGenerali(datos).subscribe(
          data =>{
            if(data['response'] == "ok"){
              if(this.existeDocMedico){
                this.enviarEncargo();
                
              }else{
                this.notificacion.notificationFail("Carpeta DOC Médico no creada");
              }
            }else{
              this.notificacion.notificationFail(data['mensaje']);

            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            this.notificacion.notificationFail("Error en el servidor");
          }
        );
      }else if(opcion == "reEnviar"){
        this.funcionesService.updateDatosEncargoGenerali(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              this.pasarEncargoReEnviar();
            }else{
              this.notificacion.notificationFail(data['mensaje']);

            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            this.notificacion.notificationFail("Error en el servidor");
          }
        );

      }else if(opcion == "reEnviarFinalizado"){
        this.funcionesService.updateDatosEncargoGenerali(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              this.pasarEncargoDeFinalizadoAEnviado();
            }else{
              this.notificacion.notificationFail(data['mensaje']);

            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            this.notificacion.notificationFail("Error en el servidor");
          }
        );
      }else if(opcion == "pendienteRevision"){
        this.funcionesService.updateDatosEncargoGenerali(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              this.pasarEncargoPendienteRevision();
            }else{
              this.notificacion.notificationFail(data['mensaje']);

            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            this.notificacion.notificationFail("Error en el servidor");
          }
        );
      }else if(opcion == "finalizado"){
        this.funcionesService.updateDatosEncargoGenerali(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              this.pasarEncargoFinalizado();
            }else{
              this.notificacion.notificationFail(data['mensaje']);

            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            this.notificacion.notificationFail("Error en el servidor");
          }
        );
      }else if(opcion == "informe"){
        //let checkCampos = this.checkCamposObligatorios();
        //Checkea los campos obligatorios a la hora de generar informe
        if(datos){
          this.funcionesService.updateDatosEncargoGenerali(datos).subscribe(
            data =>{
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.generarInforme();
              }else{
                this.notificacion.notificationFail(data['mensaje']);
  
              }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );
        }
        else{
          this.mostrarProgressSpinner = false;
          this.notificacion.notificationFail("Error en el servidor");
        }
        
      }else if(opcion == "automatico"){
        this.funcionesService.updateDatosEncargoGenerali(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              console.log("Guardado automático realizado");
            }else{
              console.log("Error guardado automático");
            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            console.log("Error servidor guardado automático");
          }
        );
      }else if(opcion == "descargarDocMedico") {
        this.funcionesService.downloadZipFolder(this.archivosCarpetaDocMedico, this.rutaCarpetaDocMedico).subscribe({
          next: (data: any) => {
            //console.log(data);
            this.funcionesService.verArchivo(data['ruta']).subscribe({
              next: (data: any) => {
                this.descargarchivos(data, "DocMedicoZip", "application/zip");
    
              },
              error: (err: any) => {
                this.funcionesService.deleteZipFolder(data['ruta']).subscribe({
                  next: (data: any) => {

                  },
                  error: (err: any) => {},
                  complete: () => {}
                });
              },
              complete: () => {
                this.mostrarProgressSpinner = false;
                this.funcionesService.deleteZipFolder(data['ruta']).subscribe({
                  next: (data: any) => {

                  },
                  error: (err: any) => {},
                  complete: () => {}
                });
              }
            });

          },
          error: (err: any) => {
                  
          },
          complete: () => {
            
          }
        })
      }else if(opcion == "fision") {
        //Mostrar dialogo de confirmacion
        const dialogEnviarFision = this.dialog.open(EnviarFisionDialogComponent, {
          data: {enviarFision: this.enviarFision}
        });
        dialogEnviarFision.afterClosed().subscribe(
          result =>{
            console.log(result);
            if(result){
              //No hacer nada ya que ha clickado en NO
              if(result === false){
              //Enviar informe
              }else{
                this.funcionesService.pasarInformeFision(this.rutaCarpetaDocMedico, this.refSiniestro, this.nombreLesionado, this.encargo.upgen_fecha_tarea.toString()).subscribe(
                  data =>{
                    this.mostrarProgressSpinner = false;
                    if(data['response'] == "ok"){
                      //this.pasarEncargoReEnviar();
                      this.notificacion.notificationOk(data['mensaje']);
                    }else{
                      this.notificacion.notificationFail(data['mensaje']);
        
                    }
                  }, error =>{
                    this.mostrarProgressSpinner = false;
                    this.notificacion.notificationFail("Error en el servidor");
                  }
                );        
              }
            }
          }
        )
      }else{
        this.funcionesService.updateDatosEncargoGenerali(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              this.notificacion.notificationOk(data['mensaje']);
              //Borrar secuelas para no sumarlas al array ya creado
              this.secuelas().clear();
              this.intervenciones().clear();
              this.secuelasHorquilla().clear();
              this.secuelasEsteticas().clear();
              this.getEncargo();
              this.getMensajesEncargo();
            }else{
              this.notificacion.notificationFail(data['mensaje']);
              this.getEncargo();
              this.getMensajesEncargo();
            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            this.notificacion.notificationFail("Error en el servidor");
            this.getEncargo();
            this.getMensajesEncargo();
          }
        );
      }
    }else{
      this.mostrarProgressSpinner = false;
      this.notificacion.notificationFail("El campo 'Medicos' o 'Tipo' no estan rellenos");
    }
     
    
  }

  enviarEncargo(){
    //Mostrar dialogo de confirmacion
    const dialogEnviar = this.dialog.open(EnviarDialogComponent,{
      data: {comentario: this.comentario}
    });

    dialogEnviar.afterClosed().subscribe(
      result =>{
        const idEstadoEnviado = 2;
        let datos = {
          'idEncargo': this.idEncargo,
          'idEstado': idEstadoEnviado
        };

        this.funcionesService.cambiarEstadoEncargoGenerali(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              let datosMensaje = {
                'idEncargo': this.idEncargo,
                'idUsuario': this.tramitador.trm_id_user,
                'textoMensaje': result
              };
              this.notificacion.notificationOk(data['mensaje'] + " Se te redireccionará en 3 segundos.");
                  setTimeout(() => {
                    this.router.navigate(['nueva-tarea-vdc'])
                    .then(() => {
                      window.location.reload();
                    });
                  }, 3000);
              //Si es un encargo urgente se le envia un email al medico
              if(this.isUrgente){
                let datosEmail = {
                  'idEncargo': this.idEncargo
                };
                this.mostrarProgressSpinner = true;
                //Enviar email al medico
                //this.enviarEmailEncargoUrgente(datosEmail);
              }

              //crear autorizacion y enviar mensaje
              //this.crearAutorizacion(datosMensaje);

            }else{
              this.notificacion.notificationFail(data['mensaje']);
            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            this.notificacion.notificationFail("Error en el servidor");
          }
        ); 
      }
    );
 
  }

  //Crear autorizacion y enviar mensaje

  // crearAutorizacion(datosMensaje){
  //   this.mostrarProgressSpinner = true;
  //   let datos = {
  //     'idMedico': this.idMedico,
  //     'idEncargo': this.idEncargo,
  //     'idExpedienteCrm': this.encargo.upgen_id_expediente,
  //     'idTipo' : this.idTipo,
  //     'idCia': this.encargo.upgen_cia
  //   };
  //   this.funcionesService.crearAut(datos).subscribe(
  //     data =>{
  //       this.mostrarProgressSpinner = false;
  //       if(data['response'] == "ok"){
  //         this.funcionesService.addMensajeEncargoGenerali(datosMensaje).subscribe(
  //           data => {
  //             this.mostrarProgressSpinner = false;
  //             if(data['response'] == "ok"){
  //               this.notificacion.notificationOk("Tarea guardada en estado: Enviado. Se te redireccionará en 3 segundos");
  //               setTimeout(() => {
  //                 this.router.navigate(['tramitador-listado/valoracionDocumental']);
  //               }, 3000);
  //             }else{
  //               this.notificacion.notificationFail(data['mensaje']);
  //             }
  //           }, error =>{
  //             this.mostrarProgressSpinner = false;
  //             this.notificacion.notificationFail("Error en el servidor");
  //           }
  //         );
  //       }else{
  //         this.notificacion.notificationFail(data['mensaje']);
  //       }
  //     }, error =>{
  //       this.mostrarProgressSpinner = false;
  //       this.notificacion.notificationFail("Error en el servidor. Autorización no creada");
  //       //const errorMessage = typeof error === 'string' ? error : JSON.stringify(error);
  //       //this.notificacion.notificationFail(errorMessage);
  //     }
  //   );
  // }

  pasarEncargoReEnviar(){
    //Mostrar dialogo de confirmacion
    const dialogEnviar = this.dialog.open(EnviarDialogComponent,{
      data: {comentario: this.comentario}
    });

    dialogEnviar.afterClosed().subscribe(
      result =>{
        //Si pulsa el boton "Si" del dialog
        if(result){
          //Enviar mensaje al encargo
          let datosMensaje = {
            'idEncargo': this.idEncargo,
            'idUsuario': this.tramitador.trm_id_user,
            'textoMensaje': result
          };

          this.funcionesService.addMensajeEncargoGenerali(datosMensaje).subscribe(
            data => {
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.mostrarProgressSpinner = true;
                //ID de estado Enviado
                const idEstadoEnviado = 2

                let datos = {
                  'idEncargo': this.idEncargo,
                  'idEstado': idEstadoEnviado
                };

                this.funcionesService.cambiarEstadoEncargoGenerali(datos).subscribe(
                  data =>{
                    this.mostrarProgressSpinner = false;
                    if(data['response'] == "ok"){
                      this.notificacion.notificationOk(data['mensaje'] + " Se te redireccionará en 3 segundos");
                      setTimeout(() => {
                        this.router.navigate(['nueva-tarea-vdc']);
                      }, 3000);
                    }else{
                      this.notificacion.notificationFail(data['mensaje']);
                    }
                  }, error =>{
                    this.mostrarProgressSpinner = false;
                    this.notificacion.notificationFail("Error en el servidor");
                  }
                );
              }else{
                this.notificacion.notificationFail(data['mensaje']);
              }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );

        }
      }
    );  
  }

  pasarEncargoDeFinalizadoAEnviado(){
    //Mostrar dialogo de confirmacion
    const dialogReEnviarDesdeFinalizado = this.dialog.open(ReEnviarDesdeFinalizadoDialogComponent,{
      data: {comentario: this.comentario}
    });

    dialogReEnviarDesdeFinalizado.afterClosed().subscribe(
      result =>{
        //Si pulsa el boton "Si" del dialog
        if(result){
          //Enviar mensaje al encargo
          let datosMensaje = {
            'idEncargo': this.idEncargo,
            'idUsuario': this.tramitador.trm_id_user,
            'textoMensaje': result
          };

          this.funcionesService.addMensajeEncargoGenerali(datosMensaje).subscribe(
            data => {
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.mostrarProgressSpinner = true;
                //ID de estado Enviado
                const idEstadoEnviado = 2

                let datos = {
                  'idEncargo': this.idEncargo,
                  'idEstado': idEstadoEnviado
                };

                this.funcionesService.cambiarEstadoEncargoGenerali(datos).subscribe(
                  data =>{
                    this.mostrarProgressSpinner = false;
                    if(data['response'] == "ok"){
                      this.notificacion.notificationOk(data['mensaje'] + " Se te redireccionará en 3 segundos");
                      setTimeout(() => {
                        this.router.navigate(['nueva-tarea-vdc']);
                      }, 3000);
                    }else{
                      this.notificacion.notificationFail(data['mensaje']);
                    }
                  }, error =>{
                    this.mostrarProgressSpinner = false;
                    this.notificacion.notificationFail("Error en el servidor");
                  }
                );
              }else{
                this.notificacion.notificationFail(data['mensaje']);
              }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );

        }
      }
    ); 
  }

  pasarEncargoPendienteRevision(){
    //Mostrar dialogo de confirmacion
    const dialogPendienteRevision = this.dialog.open(PendienteRevisionDialogComponent,{
      data: {comentario: this.comentario}
    });
    dialogPendienteRevision.afterClosed().subscribe(
      result =>{
        //Si pulsa el boton "Si" del dialog
        if(result){
          //Enviar mensaje al encargo
          let datosMensaje = {
            'idEncargo': this.idEncargo,
            'idUsuario': this.tramitador.trm_id_user,
            'textoMensaje': result
          };

          this.funcionesService.addMensajeEncargoGenerali(datosMensaje).subscribe(
            data => {
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.mostrarProgressSpinner = true;
                //ID de estado Pendiente revision
                const idEstadoPendienteRevision = 3;

                let datos = {
                  'idEncargo': this.idEncargo,
                  'idEstado': idEstadoPendienteRevision
                };

                this.funcionesService.cambiarEstadoEncargoGenerali(datos).subscribe(
                  data =>{
                    this.mostrarProgressSpinner = false;
                    if(data['response'] == "ok"){
                      this.notificacion.notificationOk(data['mensaje'] + " Se te redireccionará en 3 segundos");
                      setTimeout(() => {
                        this.router.navigate(['nueva-tarea-vdc']);
                      }, 3000);
                    }else{
                      this.notificacion.notificationFail(data['mensaje']);
                    }
                  }, error =>{ 
                    this.mostrarProgressSpinner = false;
                    this.notificacion.notificationFail("Error en el servidor");
                  }
                );
              }else{
                this.notificacion.notificationFail(data['mensaje']);
              }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );

        }
      }
    );
  }

  pasarEncargoFinalizado(){
    //Mostrar dialogo de confirmación
    const dialogFinalizado = this.dialog.open(FinalizadoDialogComponent, {
      data: {moverEncargoFinalizado: this.moverEncargoFinalizado}
    });

    dialogFinalizado.afterClosed().subscribe(
      result =>{
        if(result){
          //No hacer nada ya que ha clickado en NO
          if(result === 2){
          //Pasar a finalizado y mover carpeta
          }else{
            this.mostrarProgressSpinner = true;
            //ID de estado Pendiente revision
            const idEstadoFinalizado = 4;

            let datos = {
              'idEncargo': this.idEncargo,
              'idEstado': idEstadoFinalizado,
              'moverEncargo': true
            };

            this.funcionesService.cambiarEstadoEncargoGenerali(datos).subscribe(
              data =>{
                this.mostrarProgressSpinner = false;
                if(data['response'] == "ok"){
                  this.notificacion.notificationOk(data['mensaje'] + " Se te redireccionará en 3 segundos");
                  this.mensajesLeidosGenerali();
                  setTimeout(() => {
                    this.router.navigate(['nueva-tarea-vdc']);
                  }, 3000);
                }else{
                  this.notificacion.notificationFail(data['mensaje']);
                }
              }, error =>{
                this.mostrarProgressSpinner = false;
                this.notificacion.notificationFail("Error en el servidor");
              }
            );
          }
        //Pasar a finalizado y NO mover carpeta
        }else if(result === false){
          this.mostrarProgressSpinner = true;
          //ID de estado Pendiente revision
          const idEstadoFinalizado = 4;

          let datos = {
            'idEncargo': this.idEncargo,
            'idEstado': idEstadoFinalizado,
            'moverEncargo': false
          };

          this.funcionesService.cambiarEstadoEncargoGenerali(datos).subscribe(
            data =>{
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.notificacion.notificationOk(data['mensaje'] + " Se te redireccionará en 3 segundos");
                this.mensajesLeidosGenerali();
                setTimeout(() => {
                  this.router.navigate(['nueva-tarea-vdc']);
                }, 3000);
              }else{
                this.notificacion.notificationFail(data['mensaje']);
              }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );
        }
      }
    );
  }

  generarInforme(){
    this.mostrarProgressSpinner = true;
    var datos;
    console.log(this.sessionService.getCurrentSession());

    datos = {
      'idTipoEncargo': this.encargo.upgen_id_tipo_encargo,
      'idEstado': this.encargo.upgen_id_estado_encargo,
      'idCia': this.encargo.upgen_cia,
      'numExpediente': this.numExpediente,
      'nombreMedico': this.encargo.nombreMedico,
      'numColegiado': this.encargo.numColegiadoMedico,
      'logrosMedico': this.encargo.logrosMedico,
      'nombreLesionado': this.nombreLesionado,
      'edadLesionado': this.edadLesionado,
      'provincia': this.provincia,
      'localidad': this.localidad,
      'fechaSiniestro': this.fechaSiniestro,
      'refSiniestro': this.refSiniestro,
      'urlFirma': this.sessionService.getCurrentSession().user.url_firma,
      'genero': this.sessionService.getCurrentSession().user.genero,
      'consideracion': this.consideracion,
      'conclusiones': this.conclusiones,
      'mecanismoProduccion': this.mecanismoProduccion,
      'fechaConsultaVideoConsulta': this.fechaConsultaVideoConsulta,
      'condicionLesionado': this.condicionLesionado,
      'formato_consulta' : this.id_formato_consulta
    };



      datos['profesion'] = this.profesion;
      datos['situacionLaboral'] = this.situacionLaboral;
      datos['bajaLaboral'] = this.bajaLaboral;
      datos['contingencia'] = this.contingencia;
      datos['antecedentes'] = this.antecedentes;
      datos['centroUrgencias'] = this.centroUrgencias;
      datos['cuandoAcude'] = this.cuandoAcude;
      datos['diagnosticoUrgencias'] = this.diagnosticoUrgencias;
      datos['hospitalizacionUrgencias'] = this.hospitalizacionUrgencias;
      datos['centroRehab'] = this.centroRehab;
      datos['medicoSeguimiento'] = this.medicoSeguimiento;
      datos['inicioTratamiento'] = this.inicioTratamiento;
      datos['altaMedica'] = this.altaMedica;
      datos['numConsultas'] = this.numConsultas;
      datos['numSesiones'] = this.numSesiones;
      datos['pruebasRealizadas'] = this.pruebasRealizadas;
      datos['evolucion'] = this.evolucion;
      datos['fuenteDocumental'] = this.fuenteDocumental == null ? "No se aporta" : this.fuenteDocumental;
      datos['fechaBajaLaboral'] = this.bajaLaboral == "Si" ? this.fechaBajaLaboral : null;
      datos['fechaAltaLaboral'] = this.bajaLaboral == "Si" ? this.fechaAltaLaboral : null;

      datos['diasBasicos'] = this.diasBasicos;
      datos['diasModerados'] = this.diasModerados;
      datos['diasGraves'] = this.diasGraves;
      datos['diasMuyGraves'] = this.diasMuyGraves;
      datos['diasTotales'] = this.diasTotales;
      datos['observaciones'] = this.observaciones;
    
      datos['estadoInicial'] = this.estadoInicial;
      datos['fechaUltimaConsulta'] = this.fechaUltimaConsulta.value == null ? null : formatDate(this.fechaUltimaConsulta.value, 'dd/MM/yyyy', "en-GB");
      datos['estadoFinal'] = this.estadoFinal;
      datos['bioFuncional'] = this.bioFuncional;
      datos['coherenciaSeguimiento'] = this.coherenciaSeguimiento;
      datos['fechaPrueba'] = this.fechaPrueba.value == null ? null : formatDate(this.fechaPrueba.value, 'dd/MM/yyyy', "en-GB");
      datos['consistenciaPrueba'] = this.consistenciaPrueba;
      datos['movilidad'] = this.movilidad;

      datos['puntosSecuelas'] = this.totalPuntosSecuelas();
      datos['numSecuelas'] = this.numSecuelas.toString();
      //Para que aparezca la tabla del informe pero con los datos vacios
      if(this.numSecuelas == 0){
        datos['secuelas'] = JSON.stringify({"secuelas":[{"codigo":"","descripcion":"","puntos":""}]});
      }else{
        datos['secuelas'] = JSON.stringify(this.secuelaForm.value);
      }

      datos['puntosSecuelasEsteticas'] = this.totalPuntosSecuelasEsteticas();

      if(this.numSecuelasEsteticas == 0){
        datos['secuelasEsteticas'] = JSON.stringify({"secuelas":[{"codigo":"","descripcion":"","puntos":""}]});
      }else{
        datos['secuelasEsteticas'] = JSON.stringify(this.secuelasEsteticasForm.value);
      }

      datos['formatoConsulta']  = this.id_formato_consulta;

    this.funcionesService.generarInformeMedicoGenerali(datos).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          this.notificacion.notificationOk(data['mensaje']);
          //traerme los archivos del encargo
          this.getArchivosExplorador();
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error => {
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  cancelarEncargo(){
    //Mostrar dialogo de confirmacion
    const dialogCancelar = this.dialog.open(CancelarEncargoDialogComponent,{
      data: {comentario: this.comentario}
    });
    dialogCancelar.afterClosed().subscribe(
      result =>{
        //Si pulsa el boton "Si" del dialog
        if(result){
          this.mostrarProgressSpinner = true;
          //ID de estado cancelado
          const idEstadoCancelado = 5;

          let datos = {
            'idEncargo': this.idEncargo,
            'idEstado': idEstadoCancelado,
            'comentario': result
          };

          this.funcionesService.cambiarEstadoEncargoGenerali(datos).subscribe(
            data =>{
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.notificacion.notificationOk(data['mensaje'] + " Se te redireccionará en 3 segundos");
                setTimeout(() => {
                  this.router.navigate(['nueva-tarea-vdc']);
                }, 3000);
              }else{
                this.notificacion.notificationFail(data['mensaje']);
              }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );
        }
      }
    );
    
  }

  borrarEncargo(){
    //Mostrar dialogo de confirmacion
    const dialogBorrarEncargo = this.dialog.open(BorrarEncargoDialogComponent);

    dialogBorrarEncargo.afterClosed().subscribe(
      result =>{
        //Si pulsa el boton "Si" del dialog
        if(result){
          //Eliminar encargo
          this.funcionesService.borrarEncargoGenerali(this.idEncargo).subscribe(
            data =>{
              this.mostrarProgressSpinner = false;
                if(data['response'] == "ok"){
                  this.mostrarProgressSpinner = true;
                  this.notificacion.notificationOk(data['mensaje'] + " Se te redireccionará en 3 segundos.");
                  setTimeout(() => {
                    this.router.navigate(['encargo-vdc-asistencial'])
                    .then(() => {
                      window.location.reload();
                    });
                  }, 3000);
                  
                }else{
                  this.notificacion.notificationFail(data['mensaje']);
                }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );
        }
      }
    );
    
  }

    //Enviar email encargo urgente
    // enviarEmailEncargoUrgente(datosEmail){
    //   this.tramitadorService.enviarEmailEncargoUrgente(datosEmail).subscribe(
    //     data =>{
    //       this.mostrarProgressSpinner = false;
    //       if(data['response'] == "ok"){
  
    //       }else{
    //         this.notificacion.notificationFail(data['mensaje']);
    //       }
    //     }, error =>{
    //       this.mostrarProgressSpinner = false;
    //       this.notificacion.notificationFail("Error en el servidor");
    //     }
    //   );
    // }

  //Listado de tipos y medicos del estado en tramitacion
  getMedicosTiposTramitacion(){
    this.tramitadorService.getDatosNuevaTarea().subscribe(
      data =>{
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          this.medicos = data['medicos'];
          this.tipos = data['tipos'];
        }
        this.tipos.splice(0,11);
        
      }, error=>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  //Actualizar lista de archivos
  updateFileElementQuery() {
    //file explorer lista archivos
    this.archivos = this.fileService.queryInFolder(this.currentRoot ? this.currentRoot.archPath : 'root');
  }

  mensajesLeidos(conNotificacion?){
    this.funcionesService.mensajesLeidosGenerali(this.mensajes, this.tramitador.trm_id_user).subscribe(
      data =>{
        if(data['response'] == "ok"){
          this.countMensajes = 0;
          if(conNotificacion == null){
            this.notificacion.notificationOk("Los mensajes se han marcados como leídos");
          }
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  descargarchivos(archivo: string, descripcion: string, type: string) {
    let byteImage = atob(archivo);

    let byteNumbers = new Array(byteImage.length);

    for (var i = 0; i < byteImage.length; i++) {
      byteNumbers[i] = byteImage.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    let blob = new Blob([byteArray], { "type": type });

    if (navigator.msSaveBlob) {
      let filename = descripcion;
      navigator.msSaveBlob(blob, filename);
    } else {
      let link = document.createElement("a");

      link.href = URL.createObjectURL(blob);

      link.setAttribute('visibility', 'hidden');
      link.download = descripcion;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  openModal(name, obj: any): void {
    this.el.nativeElement.querySelector('#' + name).classList.toggle('open');
  }

  abrirEncargoSiniestro(idEncargo: number){
    this.router.navigate(['encargo-tramitador-vdc', {idEncargo: idEncargo}]);
  }

  enviarMensajeEncargo(){
    this.mostrarProgressSpinner = true;
    var datos = {
      'idEncargo': this.idEncargo,
      'idUsuario': this.tramitador.trm_id_user,
      'textoMensaje': this.textoMensaje
    };
    
    this.funcionesService.addMensajeEncargoGenerali(datos).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          //Limpiar text area
          this.textoMensaje = '';
          this.notificacion.notificationOk(data['mensaje']);
          this.getMensajesEncargo();
          this.getEncargo();
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  mensajesLeidosGenerali(){
    this.funcionesService.mensajesLeidosGenerali(this.mensajes, this.tramitador.trm_id_user).subscribe(
      data =>{
        if(data['response'] == "ok"){
          this.countMensajes = 0;
          this.notificacion.notificationOk("Los mensajes se han marcados como leídos");
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  colorEncargo(fechaLimite: string){
    var color = '';
    var fechaNow = new Date();
    var fechaLimiteDosAntes = new Date(fechaLimite);
    var fechaLimiteFormat = new Date(fechaLimite);

    if(fechaLimite != null){

      fechaLimiteDosAntes.setDate(fechaLimiteFormat.getDate() - 2);

      if(fechaNow<fechaLimiteDosAntes){
        color = 'color_verde';
      }else if(fechaNow.getFullYear()==fechaLimiteDosAntes.getFullYear() && fechaNow.getMonth()==fechaLimiteDosAntes.getMonth() && fechaNow.getDate()==fechaLimiteDosAntes.getDate()
      || (fechaNow>fechaLimiteDosAntes && fechaNow<fechaLimiteFormat)){
        color = 'color_amarillo';
      }else if(fechaNow.getFullYear()==fechaLimiteFormat.getFullYear() && fechaNow.getMonth()==fechaLimiteFormat.getMonth() && fechaNow.getDate()==fechaLimiteFormat.getDate() 
      || fechaNow>fechaLimiteFormat){
        color = 'color_rojo';
      }else{
        color = '';
      }
  
      return color;
    }
  }

  onChangeFechaLimite(){
    this.encargo.upgen_fecha_limite = this.fechaLimiteFormmated;
  }

  onChangeOption(type): void {

    let myActive = this.el.nativeElement.querySelector(".active");
    let divMyActive = this.el.nativeElement.querySelector("." + myActive.id);
    let myTag = this.el.nativeElement.querySelector("[id='" + type + "']");
    let divMyTag = this.el.nativeElement.querySelector("." + type);

    divMyActive.classList.add('noshow');
    myActive.classList.remove('active');

    if (!myTag.classList.contains('active')) {
      myTag.classList.add('active');
      divMyTag.classList.remove('noshow');
    }
  }


  navigateToFolder(element: Archivo) {
    this.currentRoot = element;
    this.updateFileElementQuery();
    this.currentPath = this.pushToPath(this.currentPath, element.archDescripcion);
    this.canNavigateUp = true;
  }

  navigateUp() {
    if (this.currentRoot && this.currentRoot.archParent === 'root') {
      this.currentRoot = null;
      this.canNavigateUp = false;
      this.updateFileElementQuery();
    } else {
      this.currentRoot = this.fileService.get(this.currentRoot.archParent);
      this.updateFileElementQuery();
    }
    this.currentPath = this.popFromPath(this.currentPath);
  }


  pushToPath(path: string, folderName: string) {
    let p = path ? path : '';
    p += `${folderName}/`;
    return p;
  }

  popFromPath(path: string) {
    let p = path ? path : '';
    let split = p.split('/');
    split.splice(split.length - 2, 1);
    p = split.join('/');
    return p;
  }

  copyElement(element: Archivo){
    //Capturo el archivo a pegar
    this.archivoPegar = element;
  }

  pegarElement(){
    this.mostrarProgressSpinner = true;
    //Ruta donde pegar el archivo
    let rutaPegar = this.currentRoot ? this.currentRoot.archPath : 'root';
    //Pegar archivo capturado en el copyelement en la ruta indicada
    let datos = {
      'idEncargo': this.encargo.id,
      'archivo': this.archivoPegar,
      'rutaPegar': rutaPegar
    };
    this.funcionesService.copiarPegar(datos).subscribe(
      data =>{
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          this.notificacion.notificationOk(data['mensaje']);
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
        this.getArchivosExplorador();
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
        this.getArchivosExplorador();
      }
    );
  }

  removeElement(element: Archivo){
    //Mostrar dialogo de confirmacion
    const dialogBorrarArchivo = this.dialog.open(BorrarArchivoDialogComponent, {data:{nombreArchivo: element.archDescripcion}});

    dialogBorrarArchivo.afterClosed().subscribe(
      result =>{
        //Si pulsa el boton "Si" del dialog
        if(result){
          this.mostrarProgressSpinner = true;
          this.funcionesService.borrarArchivo(element.archPath).subscribe(
            data =>{
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.fileService.delete(element.archPath);
                this.notificacion.notificationOk(data['mensaje']);
              }else{
                this.notificacion.notificationFail(data['mensaje']);
              }
              this.getArchivosExplorador();
            }, error=>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
              this.getArchivosExplorador();
            }
          );
        }
      }
    );
  }

  // File manager
  openModalFile(element: Archivo) {
    if (element != null)
      this.funcionesService.verArchivo(element.archPath).subscribe(
        data => {
          if (element.archType == 'pdf') {
            this.imageSelect = data;
            this.tipoArchivo = 'pdf';
            this.el.nativeElement.querySelector('#modalFile').classList.toggle('open');
            //console.log(data);

          }
          else if (element.archType == 'jpg' || element.archType == 'jpeg') {
            this.imageSelect = data;
            this.tipoArchivo = 'imagen';
            this.el.nativeElement.querySelector('#modalFile').classList.toggle('open');
          }
          else {
            this.descargarchivos(data, element.archDescripcion, element.archContentType);
          }



        });
  }

  //Pasar fecha en string con foramto dd/MM/yyyy a Date
  dateStringtoDate(fechaString: string){
    let [dia, mes, anio] = fechaString.split('/');

    return new Date(Number(anio), Number(mes) - 1, Number(dia));
  }

  formatearFecha(fecha: string): string {
    // Crear un objeto Date a partir de la cadena de texto
    const fechaDate = new Date(fecha);

    // Obtener los componentes de la fecha
    const dia = fechaDate.getDate();
    const mes = fechaDate.getMonth() + 1; // Los meses comienzan desde 0
    const anio = fechaDate.getFullYear();

    // Agregar ceros a la izquierda si es necesario
    const diaFormateado = dia < 10 ? '0' + dia : dia.toString();
    const mesFormateado = mes < 10 ? '0' + mes : mes.toString();

    // Formatear la fecha como "dd-mm-yyyy"
    return diaFormateado + '-' + mesFormateado + '-' + anio;
}

}
