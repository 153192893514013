<h1 mat-dialog-title>Listado de incidencias:</h1>
<div mat-dialog-content>

    <div class="p-5 d-flex justify-content-center" *ngIf="incidenciasPago.length == 0">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="incidenciasPago.length > 0">
        <table mat-table #tableIncidenciasPago [dataSource]="dataSourceIncidenciasPago" class="w-100">
    
            <ng-container matColumnDef="inc_id_incidencias">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Id incidencia pago </th>
                <td class="p-2" mat-cell *matCellDef="let incidenciaPago" (click)="incidenciaSelected(incidenciaPago)"> {{incidenciaPago.incidencia.inc_id_incidencias}} </td>
            </ng-container>
    
            <ng-container matColumnDef="inc_fecha_creacion">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha creación </th>
                <td class="p-2" mat-cell *matCellDef="let incidenciaPago" (click)="incidenciaSelected(incidenciaPago)"> {{incidenciaPago.incidencia.inc_fecha_creacion}} </td>
            </ng-container>
            
            <ng-container matColumnDef="inc_usuario_creacion">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Usuario creador </th>
                <td class="p-2" mat-cell *matCellDef="let incidenciaPago" (click)="incidenciaSelected(incidenciaPago)"> {{obtenerNombreUsuario(incidenciaPago.incidencia.inc_usuario_creacion)}} </td>
            </ng-container>
            
            <ng-container matColumnDef="inc_estado">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td class="p-2" mat-cell *matCellDef="let incidenciaPago" (click)="incidenciaSelected(incidenciaPago)"> {{incidenciaPago.incidencia.inc_estado}} </td>
            </ng-container>
            
            <ng-container matColumnDef="inc_tipo">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
                <td class="p-2" mat-cell *matCellDef="let incidenciaPago" (click)="incidenciaSelected(incidenciaPago)"> {{incidenciaPago.incidencia.inc_tipo}} </td>
            </ng-container>
    
            <ng-container matColumnDef="inc_usuario_asignado">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Usuario asignado </th>
                <td class="p-2" mat-cell *matCellDef="let incidenciaPago" (click)="incidenciaSelected(incidenciaPago)"> {{obtenerNombreUsuario(incidenciaPago.incidencia.inc_usuario_asignado)}} </td>
            </ng-container>
    
            <ng-container matColumnDef="incp_motivo">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Motivo </th>
                <td class="p-2" mat-cell *matCellDef="let incidenciaPago" (click)="incidenciaSelected(incidenciaPago)"> {{obtenerNombreMotivo(incidenciaPago)}} </td>
            </ng-container>
    
            <ng-container matColumnDef="incp_incidencia_asociada">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Incidencia asociada </th>
                <td class="p-2" mat-cell *matCellDef="let incidenciaPago" (click)="incidenciaSelected(incidenciaPago)"> {{incidenciaPago.incp_incidencia_asociada}} </td>
            </ng-container>
    
            <ng-container matColumnDef="incp_remesa_asociada">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Remesa asociada </th>
                <td class="p-2" mat-cell *matCellDef="let incidenciaPago" (click)="incidenciaSelected(incidenciaPago)"> {{incidenciaPago.incp_remesa_asociada}} </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumnsIncidenciasPago" class="sticky-top"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsIncidenciasPago;" class="custom-row"></tr>
        </table>
    </div>

</div>