import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Autorizaciones, Citas, ExpedientesAlta, FacturacionServiceService, LlamadasSegList, PlantillasProductos, PlantillasProductosData } from '../../services/facturacion-service.service';
import { Autorizacion } from '../../models/autorizacion';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Cita } from '../../models/cita';
import { Consulta } from '../../models/consulta';
import { Expediente } from '../../models/expediente';
import { HistorialClinico } from '../../models/historial-clinico';
import { LlamadasSeg } from '../../models/llamadas-seg';
import { PlantillaProductos } from '../../models/plantilla-productos';
import { PlantillaProductosFacturas } from '../../models/plantilla-productos-facturas';

@Component({
  selector: 'app-cambios-pendientes',
  templateUrl: './cambios-pendientes.component.html',
  styleUrls: ['./cambios-pendientes.component.scss']
})

export class CambiosPendientesComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['id'];  
  inital: PeriodicElement[] = [{index: '0'}]
  dataIDs: PeriodicElement[] = [];
  dataMySQL: String[] = [];
  dataCRM: String[] = [];
  loadingPageFlag: boolean = true;
  form : FormGroup;
  form2 : FormGroup;
  tabState: number;
  
  tabsName: string[] = ["Autorizaciones", "Citas", "Consultas", "Expedientes", "Llamadas de seguimiento", "Plantilla de productos", "Plantilla producto tienen facturas"];
  labelsAut: string[][] = [
    ["Autorización ID",         "inputAutId"],
    ["Fecha de creación",       "inputAutFCreacion"],
    ["Fecha de modificación",   "inputAutFModeificacion"],
    ["Estado",                  "inputAutEstado"],
    ["Nº Expediente",           "inputAutExpediente"],
    ["Fecha de actuación",      "inputAutFActuacion"],
    ["Producto",                "inputAutProducto"],
    ["Cantidad",                "inputAutCantidad"],
    ["Estado autorización",     "inputAutEstadoAut"],
    ["Centro",                  "inputAutCentro"],
    ["Médico",                  "inputAutMedico"],
    ["Otro servicio",           "inputAutOServicio"]
  ];

  labelsCitas: string[][] = [
    ["Cita ID",               "inputCupId"],
    ["Numero Cita",           "inputCupNum"],
    ["Asiste",                "inputCupAsis"],
    ["Precio",                "inputCupPrecio"],
    ["Consulta",              "inputCupConsul"],
    ["Coste Previsto",        "inputCupCostPre"],
    ["Coste Real",            "inputCupCostReal"],
    ["Estado",                "inputCupEstado"],
    ["Expediente",            "inputCupExpediente"],
    ["Factura Emitida",       "inputCupFacturaEmitida"],
    ["Factura Recibida",      "inputCupFacturaRecibida"],
    ["Fecha de creación",     "inputCupFechaCre"],
    ["Fecha de modificación", "inputCupFechaMod"],
    ["Hora",                  "inputCupHora"],
    ["Minutos",               "inputCupMinutos"],
    ["IVA",                   "inputCupIVA"],
    ["Abonar",                "inputCupAbonar"],
    ["Observaciones",         "inputCupObservaciones"],
  ];

  labelsConsultas: string[][] = [
    ["Id Consultas",        "inputCsupIdConsultas"],
    ["Centro Consulta",     "inputCsupCentroConsulta"],
    ["Estado",              "inputCsupEstado"],
    ["Estado Consulta",     "inputCsupEstadoConsulta"],
    ["Factura Recibida",    "inputCsupFacturaRecibida"],
    ["Fecha Consulta",      "inputCsupFechaConsuta"],
    ["Fecha Creación",      "inputCsupFechaCreacion"],
    ["Fecha Modificación",  "inputCsupFechaModificacion"],
    ["Kilometros",          "inputCsupKilometros"],
    ["Medico Consultas",    "inputCsupMedicoConsultas"],
    ["Observaciones",       "inputCsupObservaciones"],
    ["Precio Kilometros",   "inputCsupPrecioKilometros"],
    ["Provincia",           "inputCsupProvincia"],
    ["Tipo Consulta",       "inputCsupTipoConsulta"],
    ["Total Kilometros",    "inputCsupTotalKilometros"],
  ];

  labelsExpedientes: string[][] = [
    ["ID Expediente",                   "inputUPIdExpediente"],
    ["Fecha Creación",                  "inputUPFechaCreacion"],
    ["Fecha Modificación",              "inputUPFechaModificacion"],
    ["Estado",                          "inputUPEstado"],
    ["Fecha Encargo",                   "inputUPFechaEncargo"],
    ["Estado tramitación",              "inputUPEstadoTramitacion"],
    ["Días de evolución",               "inputUPDiasEvolucion"],
    ["Situación",                       "inputUPSituacion"],
    ["Tipo",                            "inputUPTipo"],
    ["Fecha Alta Estimada",             "inputUPFechaAltaEstimada"],
    ["Fecha Alta Laboral",              "inputUPFechaAltaLaboral"],
    ["Fecha Alta Médica",               "inputUPFechaAltaMedica"],
    ["CIA",                             "inputUPCia"],
    ["Lesionado",                       "inputUPLesionado"],
    ["Tipo Lesionado",                  "inputUPTipoLesionado"],
    ["Historial Clínico",               "inputHistorialClinico"],
    ["Marca",                           "inputUPMarca"],
    ["Valorador",                       "inputUPValorador"],
    ["Fecha Informe Inicial",           "inputUPFechaAInfInicial"],
    ["Tipo Encargo",                    "inputUPTipoEncargo"],
    ["Motivo Cancelación",              "inputUPMotivoCancelacion"],
    ["Encargo",                         "inputUPEncargo"],
    ["Observaciones",                   "inputUPObservaciones"],
    ["Acciones Especiales",             "inputUPAccionesEspeciales"],
    ["Prestación",                      "inputUPPrestacion"],
    ["Trámite Interno GNR",             "inputUPTramiteInternoGNM"],
    ["Recaptado",                       "inputUPRecaptado"],
    ["Otros Lesionados",                "inputUPOtrosLesionados"],
    ["Nombre Otros Lesionado",          "inputUPNombreOtrosLesionados"],
    ["Derivado Asesoría Médica",        "inputUPDerivadoAsesoriaMedica"],
    ["Fecha Derivado Asesoria Médica",  "inputUPFechaDerivadoAsesoriaMedica"],
    ["Fecha Respuesta Asesoria",        "inputUPFechaRespuestaAsesoria"],
    ["Asesor Médico",                   "inputUPAsesorMedico"],
    ["Derivado Dirección Médica",       "inputUPDerivadoDireccionMedica"],
    ["Observaciones Tramitador",        "inputUPObservacionesTramitador"],
    ["Teléfono",                        "inputUPTelefono"],
    ["Móvil",                           "inputUPMovil"],
    ["Horario Preferente",              "inputUPHoriarioPreferente"],
    ["Fecha Nacimiento",                "inputUPFechaNacimiento"],
    ["Edad",                            "inputUPEdad"],
    ["Fecha Comunicación",              "inputUPFechaComunicacion"],
    ["Sexo",                            "inputUPSexo"],
    ["Correo Electrónico",              "inputUPCorreoElectronico"],
    ["Estado Civil",                    "inputUPEstadoCivil"],
    ["Consentimiento Informado",        "inputUPConsentimientoinformado"],
    ["Nueva Documentación",             "inputUPNuevaDocumentacion"],
    ["Nueva Comunicación",              "inputUPNuevaComunicacion"],
    ["Actividad Deportiva",             "inputUPActividadDeportiva"],
    ["Nacionalidad",                    "inputUPNacionalidad"],
    ["Situación Laboral",               "inputUPSituacionLaboral"],
    ["Dirección",                       "inputUPDireccion"],
    ["Número",                          "inputUPNumero"],
    ["Localidad",                       "inputUPLocalidad"],
    ["Provincia",                       "inputUPProvincia"],
    ["Código Postal",                   "inputUPCodigoPostal"],
  ];

  labelsLlamadasSeg: string[][] = [
    ["ID Llamadas Seguimiento",     "inputLlsIdlls"],
    ["Contesta",                    "inputLlsContesta"],
    ["Estado",                      "inputLlsEstado"],
    ["Expediente",                  "inputLlsExpediente"],
    ["Factura Emitida Producto",    "inputLlsFacturaEmitidaProducto"],
    ["Facturación Especial",        "inputLlsFacturacionEspecial"],
    ["Fecha Creación",              "inputLlsFechaCreacion"],
    ["FechaGestion",                "inputLlsFechaGestion"],
    ["FechaModificación",           "inputLlsFechaMod"],
    ["Interna",                     "inputLlsInterna"],
    ["Iva",                         "inputLlsIva"],
    ["Kilometraje",                 "inputLlsKilometraje"],
    ["Observaciones",               "inputLlsObservaciones"],
    ["Precio",                      "inputLlsPrecio"],
    ["Próxima Gestión",             "inputLlsProxGestion"],
    ["Próxima Llamada",             "inputLlsproxLlamada"],
    ["Realizado",                   "inputLlsRealizado"],
    ["Realizado Por",               "inputLlsRealizadoPor"],
    ["Refiere Lesionado",           "inputLlsRefiereLesionado"],
    ["Tipo Gestión",                "inputLlsTipoGestion"],
    ["Tipo Informe",                "inputLlsTipoInforme"],
  ];

  labelsPlantillaProductos: string[][] = [
    ["ID Plantilla Productos",      "inputPPIdPP"],
    ["Autorización",                "inputPPAut"],
    ["Centro",                      "inputPPCentro"],
    ["Cita",                        "inputPPCita"],
    ["Compartido",                  "inputPPCompartido"],
    ["Estado",                      "inputPPEstado"],
    ["Factura Emitida Producto",    "inputPPFacturaEmitidaProducto"],
    ["Fecha Creación",              "inputPPFechaCreacion"],
    ["Fecha Fin",                   "inputPPFechaFin"],
    ["Fecha Inicio",                "inputPPFechaInicio"],
    ["Fecha Modificación",          "inputPPFechaMod"],
    ["Iva",                         "inputPPIva"],
    ["Médico",                      "inputPPMedico"],
    ["Otro Servicio",               "inputPPOtroServicio"],
    ["Precio Compra",               "inputPPPrecioCompra"],
    ["Precio Venta",                "inputPPPrecioVenta"],
    ["Producto",                    "inputPPProducto"],
    ["Resultado",                   "inputPPResultado"],
    ["Total Compra",                "inputPPTotalCompra"],
    ["Total Días",                  "inputPPTotalDias"],
    ["Total Ventas",                "inputPPTotalVentas"],
    ["Unidades Compra",             "inputPPUnidadesCompra"],
    ["Unidades Venta",              "inputPPUnidadesVenta"],
  ];

  labelsPlantillasProductosFactura: string[][] = [
    ["ID Plantilla Productos Facturas",   "inputPPFidPPF"],
    ["Estado",                            "inputPPFEstado"],
    ["Factura Emitida Producto",          "inputPPFFacturaEmitida"],
    ["Factura Recibida Producto",         "inputPPFFacturaRecibidaProducto"],
    ["Fecha Creación",                    "inputPPFFechaCreacion"],
    ["Fecha Modificación",                "inputPPFFechaMod"],
    ["Iva",                               "inputPPFIva"],
    ["Número Actos",                      "inputPPFNumeroActos"],
    ["Número Factura",                    "inputPPFNumeroFactura"],
    ["Plantilla",                         "inputPPFPlantilla"],
    ["Precio Unitario",                   "inputPPFPrecioUnitario"],
    ["Total",                             "inputPPFTotal"],
    ["Total Iva",                         "inputPPF"],
  ];
  
  constructor( private facturacionService : FacturacionServiceService, private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.filtrarCambiosPendientes(this.inital[0]) 
  }

  // Guarda los datos de MySQL a CRM.
  guardarCambios(): void{
      //Code
  }

  // 1. Filtra la tabla cambios pendientes de MySQL por tipo entidad.
  filtrarCambiosPendientes(tabChangeEvent: any) {

    this.tabState = tabChangeEvent.index;

    if(tabChangeEvent.index == 0){      
      this.getAut(0);
    }
    if(tabChangeEvent.index == 1){      
      this.getCitas(0);
    }
    if(tabChangeEvent.index == 2){      
      this.getConsultas(0);
    }
    if(tabChangeEvent.index == 3){      
      this.getExpedientes(0);
    }
    if(tabChangeEvent.index == 4){      
      this.getLlamadasSeg(0);
    }
    if(tabChangeEvent.index == 5){      
      this.getPlantillaProductos(0);
    }
    if(tabChangeEvent.index == 6){      
      this.getPlantillaProductosTienenFactura(0);
    }

  }

  // 1. Introduce los datos (nuevos y antiguos) en el formulario.
  loadFormAut(index:number){

    this.form = new FormGroup({
      inputAutId              : new FormControl({ value: this.dataMySQL[index]['aut_id_autorizaciones']             ?? " ", disabled: true }),
      inputAutFCreacion       : new FormControl({ value: this.dataMySQL[index]['aut_fecha_creacion']                ?? " ", disabled: true }),
      inputAutFModeificacion  : new FormControl({ value: this.dataMySQL[index]['aut_fecha_modificacion']            ?? " ", disabled: true }),
      inputAutEstado          : new FormControl({ value: this.dataMySQL[index]['aut_estado']                        ?? " ", disabled: true }),
      inputAutExpediente      : new FormControl({ value: this.dataMySQL[index]['aut_expediente']                    ?? " ", disabled: true }),
      inputAutFActuacion      : new FormControl({ value: this.dataMySQL[index]['aut_fecha_actuacion']               ?? " ", disabled: true }),
      inputAutProducto        : new FormControl({ value: this.dataMySQL[index]['aut_producto']                      ?? " ", disabled: true }),
      inputAutCantidad        : new FormControl({ value: this.dataMySQL[index]['aut_cantidad']                      ?? " ", disabled: true }),
      inputAutEstadoAut       : new FormControl({ value: this.dataMySQL[index]['aut_estado_autorizacion']           ?? " ", disabled: true }),
      inputAutCentro          : new FormControl({ value: this.dataMySQL[index]['aut_centro']                        ?? " ", disabled: true }),
      inputAutMedico          : new FormControl({ value: this.dataMySQL[index]['aut_medico']                        ?? " ", disabled: true }),
      inputAutOServicio       : new FormControl({ value: this.dataMySQL[index]['aut_otro_servicio']                 ?? " ", disabled: true })
    });

    this.form2 = new FormGroup({
      inputAutId              : new FormControl({ value: this.dataCRM[index]['sym_descripcion']                     ?? " ", disabled: true }),
      inputAutFCreacion       : new FormControl({ value: this.dataCRM[index]['createdon']                           ?? " ", disabled: true }),
      inputAutFModeificacion  : new FormControl({ value: this.dataCRM[index]['modifiedon']                          ?? " ", disabled: true }),
      inputAutEstado          : new FormControl({ value: this.dataCRM[index]['sym_estado']                          ?? " ", disabled: true }),
      inputAutExpediente      : new FormControl({ value: this.dataCRM[index]['sym_expediente']                      ?? " ", disabled: true }),
      inputAutFActuacion      : new FormControl({ value: this.dataCRM[index]['sym_fechaactuacin']                   ?? " ", disabled: true }),
      inputAutProducto        : new FormControl({ value: this.dataCRM[index]['sym_producto']                        ?? " ", disabled: true }),
      inputAutCantidad        : new FormControl({ value: this.dataCRM[index]['sym_cantidad']                        ?? " ", disabled: true }),
      inputAutEstadoAut       : new FormControl({ value: this.dataCRM[index]['statecode']                           ?? " ", disabled: true }),
      inputAutCentro          : new FormControl({ value: this.dataCRM[index]['sym_centro']                          ?? " ", disabled: true }),
      inputAutMedico          : new FormControl({ value: this.dataCRM[index]['new_mdicoaut']                        ?? " ", disabled: true }),
      inputAutOServicio       : new FormControl({ value: this.dataCRM[index]['new_otroservicio']                    ?? " ", disabled: true })
    });
    
  }

  loadFormCitas(index:number){
    this.form = new FormGroup({
      inputCupAsis              : new FormControl({ value: this.dataMySQL[index]['cup_asiste']                      ?? " ", disabled: true }),
      inputCupConsul            : new FormControl({ value: this.dataMySQL[index]['cup_consulta']                    ?? " ", disabled: true }),
      inputCupCostPre           : new FormControl({ value: this.dataMySQL[index]['cup_coste_previsto']              ?? " ", disabled: true }),
      inputCupCostReal          : new FormControl({ value: this.dataMySQL[index]['cup_coste_real']                  ?? " ", disabled: true }),
      inputCupEstado            : new FormControl({ value: this.dataMySQL[index]['cup_estado']                      ?? " ", disabled: true }),
      inputCupExpediente        : new FormControl({ value: this.dataMySQL[index]['cup_expediente']                  ?? " ", disabled: true }),
      inputCupFacturaEmitida    : new FormControl({ value: this.dataMySQL[index]['cup_factura_emitida_producto']    ?? " ", disabled: true }),
      inputCupFacturaRecibida   : new FormControl({ value: this.dataMySQL[index]['cup_factura_recibida_producto']   ?? " ", disabled: true }),
      inputCupFechaCre          : new FormControl({ value: this.dataMySQL[index]['cup_fecha_creacion']              ?? " ", disabled: true }),
      inputCupFechaMod          : new FormControl({ value: this.dataMySQL[index]['cup_fecha_modificacion']          ?? " ", disabled: true }),
      inputCupHora              : new FormControl({ value: this.dataMySQL[index]['cup_hora']                        ?? " ", disabled: true }),
      inputCupId                : new FormControl({ value: this.dataMySQL[index]['cup_id_citas']                    ?? " ", disabled: true }),
      inputCupIVA               : new FormControl({ value: this.dataMySQL[index]['cup_iva']                         ?? " ", disabled: true }),
      inputCupMinutos           : new FormControl({ value: this.dataMySQL[index]['cup_minutos']                     ?? " ", disabled: true }),
      inputCupAbonar            : new FormControl({ value: this.dataMySQL[index]['cup_no_abonar']                   ?? " ", disabled: true }),
      inputCupNum               : new FormControl({ value: this.dataMySQL[index]['cup_numero_cita']                 ?? " ", disabled: true }),
      inputCupObservaciones     : new FormControl({ value: this.dataMySQL[index]['cup_observaciones']               ?? " ", disabled: true }),
      inputCupPrecio            : new FormControl({ value: this.dataMySQL[index]['cup_precio']                      ?? " ", disabled: true })
    });
  }

  loadFormConsultas(index:number) {

    this.form = new FormGroup({
      inputCsupCentroConsulta     : new FormControl({ value: this.dataMySQL[index]['csup_centro_consulta']    ?? " ",     disabled: true }),
      inputCsupEstado             : new FormControl({ value: this.dataMySQL[index]['csup_estado']             ?? " ",     disabled: true }),
      inputCsupEstadoConsulta     : new FormControl({ value: this.dataMySQL[index]['csup_estado_consulta']    ?? " ",     disabled: true }),
      inputCsupFacturaRecibida    : new FormControl({ value: this.dataMySQL[index]['csup_factura_recibida']   ?? " ",     disabled: true }),
      inputCsupFechaConsuta       : new FormControl({ value: this.dataMySQL[index]['csup_fecha_consulta']     ?? " ",     disabled: true }),
      inputCsupFechaCreacion      : new FormControl({ value: this.dataMySQL[index]['csup_fecha_creacion']     ?? " ",     disabled: true }),
      inputCsupFechaModificacion  : new FormControl({ value: this.dataMySQL[index]['csup_fecha_modificacion'] ?? " ",     disabled: true }),
      inputCsupIdConsultas        : new FormControl({ value: this.dataMySQL[index]['csup_id_consultas']       ?? " ",     disabled: true }),
      inputCsupKilometros         : new FormControl({ value: this.dataMySQL[index]['csup_kilometros']         ?? " ",     disabled: true }),
      inputCsupMedicoConsultas    : new FormControl({ value: this.dataMySQL[index]['csup_medico_consulta']    ?? " ",     disabled: true }),
      inputCsupObservaciones      : new FormControl({ value: this.dataMySQL[index]['csup_observaciones']      ?? " ",     disabled: true }),
      inputCsupPrecioKilometros   : new FormControl({ value: this.dataMySQL[index]['csup_precio_kilometros']  ?? " ",     disabled: true }),
      inputCsupProvincia          : new FormControl({ value: this.dataMySQL[index]['csup_provincia']          ?? " ",     disabled: true }),
      inputCsupTipoConsulta       : new FormControl({ value: this.dataMySQL[index]['csup_tipo_consulta']      ?? " ",     disabled: true }),
      inputCsupTotalKilometros    : new FormControl({ value: this.dataMySQL[index]['csup_total_kilometros']   ?? " ",     disabled: true }),
    })
  }

  loadFormExpedientes(index:number) {

    this.form = new FormGroup({
      inputUPIdExpediente                 : new FormControl({ value: this.dataMySQL[index]['exp_id_expedientes_up']                   ?? " ",     disabled: true }),
      inputUPFechaCreacion                : new FormControl({ value: this.dataMySQL[index]['exp_fecha_creacion']                      ?? " ",     disabled: true }),
      inputUPFechaModificacion            : new FormControl({ value: this.dataMySQL[index]['exp_fecha_modificacion']                  ?? " ",     disabled: true }),
      inputUPEstado                       : new FormControl({ value: this.dataMySQL[index]['exp_estado']                              ?? " ",     disabled: true }),
      inputUPFechaEncargo                 : new FormControl({ value: this.dataMySQL[index]['exp_fecha_encargo']                       ?? " ",     disabled: true }),
      inputUPEstadoTramitacion            : new FormControl({ value: this.dataMySQL[index]['exp_estado_tramitacion']                  ?? " ",     disabled: true }),
      inputUPDiasEvolucion                : new FormControl({ value: this.dataMySQL[index]['exp_dias_evolucion']                      ?? " ",     disabled: true }),
      inputUPSituacion                    : new FormControl({ value: this.dataMySQL[index]['exp_situacion_expediente']                ?? " ",     disabled: true }),
      inputUPTipo                         : new FormControl({ value: this.dataMySQL[index]['exp_tipo']                                ?? " ",     disabled: true }),
      inputUPFechaAltaEstimada            : new FormControl({ value: this.dataMySQL[index]['exp_fecha_estimada_alta']                 ?? " ",     disabled: true }),
      inputUPFechaAltaLaboral             : new FormControl({ value: this.dataMySQL[index]['exp_fecha_alta_laboral']                  ?? " ",     disabled: true }),
      inputUPFechaAltaMedica              : new FormControl({ value: this.dataMySQL[index]['exp_fecha_alta_medica']                   ?? " ",     disabled: true }),
      inputUPCia                          : new FormControl({ value: this.dataMySQL[index]['exp_cia']                                 ?? " ",     disabled: true }),
      inputUPLesionado                    : new FormControl({ value: this.dataMySQL[index]['exp_lesionado']                           ?? " ",     disabled: true }),
      inputUPTipoLesionado                : new FormControl({ value: this.dataMySQL[index]['exp_tipo_lesionado']                      ?? " ",     disabled: true }),
      inputHistorialClinico               : new FormControl({ value: this.dataMySQL[index]['exp_historial_clinico']                   ?? " ",     disabled: true }),
      inputUPMarca                        : new FormControl({ value: this.dataMySQL[index]['exp_marca']                               ?? " ",     disabled: true }),
      inputUPValorador                    : new FormControl({ value: this.dataMySQL[index]['exp_valorador_asignado']                  ?? " ",     disabled: true }),
      inputUPFechaAInfInicial             : new FormControl({ value: this.dataMySQL[index]['exp_fecha_informe_inicial']               ?? " ",     disabled: true }),
      inputUPTipoEncargo                  : new FormControl({ value: this.dataMySQL[index]['exp_tipo_encargo']                        ?? " ",     disabled: true }),
      inputUPMotivoCancelacion            : new FormControl({ value: this.dataMySQL[index]['exp_motivo_cancelacion']                  ?? " ",     disabled: true }),
      inputUPEncargo                      : new FormControl({ value: this.dataMySQL[index]['exp_encargo']                             ?? " ",     disabled: true }),
      inputUPObservaciones                : new FormControl({ value: this.dataMySQL[index]['exp_observaciones_acciones_especiales']   ?? " ",     disabled: true }),
      inputUPAccionesEspeciales           : new FormControl({ value: this.dataMySQL[index]['exp_acciones_especiales']                 ?? " ",     disabled: true }),
      inputUPPrestacion                   : new FormControl({ value: this.dataMySQL[index]['exp_prestacion']                          ?? " ",     disabled: true }),
      inputUPTramiteInternoGNM            : new FormControl({ value: this.dataMySQL[index]['exp_tramite_interno_gnr']                 ?? " ",     disabled: true }),
      inputUPRecaptado                    : new FormControl({ value: this.dataMySQL[index]['exp_recaptado_gm']                        ?? " ",     disabled: true }),
      inputUPOtrosLesionados              : new FormControl({ value: this.dataMySQL[index]['exp_otros_lesionados']                    ?? " ",     disabled: true }),
      inputUPNombreOtrosLesionados        : new FormControl({ value: this.dataMySQL[index]['exp_nombre_otros_lesionados']             ?? " ",     disabled: true }),
      inputUPDerivadoAsesoriaMedica       : new FormControl({ value: this.dataMySQL[index]['exp_derivado_asesoria_medica']            ?? " ",     disabled: true }),
      inputUPFechaDerivadoAsesoriaMedica  : new FormControl({ value: this.dataMySQL[index]['exp_fecha_derivacion_asesor']             ?? " ",     disabled: true }),
      inputUPFechaRespuestaAsesoria       : new FormControl({ value: this.dataMySQL[index]['exp_fecha_respuesta_asesor']              ?? " ",     disabled: true }),
      inputUPAsesorMedico                 : new FormControl({ value: this.dataMySQL[index]['exp_asesor_medico']                       ?? " ",     disabled: true }),
      inputUPDerivadoDireccionMedica      : new FormControl({ value: this.dataMySQL[index]['exp_derivado_direccion_medica']           ?? " ",     disabled: true }),
      inputUPObservacionesTramitador      : new FormControl({ value: this.dataMySQL[index]['exp_observaciones_tramitador']            ?? " ",     disabled: true }),
      inputUPTelefono                     : new FormControl({ value: this.dataMySQL[index]['exp_telefono']                            ?? " ",     disabled: true }),
      inputUPMovil                        : new FormControl({ value: this.dataMySQL[index]['exp_movil']                               ?? " ",     disabled: true }),
      inputUPHoriarioPreferente           : new FormControl({ value: this.dataMySQL[index]['exp_horario_preferente']                  ?? " ",     disabled: true }),
      inputUPFechaNacimiento              : new FormControl({ value: this.dataMySQL[index]['exp_fecha_nacimiento']                    ?? " ",     disabled: true }),
      inputUPEdad                         : new FormControl({ value: this.dataMySQL[index]['exp_edad']                                ?? " ",     disabled: true }),
      inputUPFechaComunicacion            : new FormControl({ value: this.dataMySQL[index]['exp_fecha_comunicacion']                  ?? " ",     disabled: true }),
      inputUPSexo                         : new FormControl({ value: this.dataMySQL[index]['exp_sexo']                                ?? " ",     disabled: true }),
      inputUPCorreoElectronico            : new FormControl({ value: this.dataMySQL[index]['exp_correo_electronico']                  ?? " ",     disabled: true }),
      inputUPEstadoCivil                  : new FormControl({ value: this.dataMySQL[index]['exp_estado_civil']                        ?? " ",     disabled: true }),
      inputUPConsentimientoinformado      : new FormControl({ value: this.dataMySQL[index]['exp_consentimiento_informado']            ?? " ",     disabled: true }),
      inputUPNuevaDocumentacion           : new FormControl({ value: this.dataMySQL[index]['exp_nueva_documentacion']                 ?? " ",     disabled: true }),
      inputUPNuevaComunicacion            : new FormControl({ value: this.dataMySQL[index]['exp_nueva_comunicacion']                  ?? " ",     disabled: true }),
      inputUPActividadDeportiva           : new FormControl({ value: this.dataMySQL[index]['exp_actividad_deportiva']                 ?? " ",     disabled: true }),
      inputUPNacionalidad                 : new FormControl({ value: this.dataMySQL[index]['exp_nacionalidad']                        ?? " ",     disabled: true }),
      inputUPSituacionLaboral             : new FormControl({ value: this.dataMySQL[index]['exp_situacion_laboral']                   ?? " ",     disabled: true }),
      inputUPDireccion                    : new FormControl({ value: this.dataMySQL[index]['exp_direccion']                           ?? " ",     disabled: true }),
      inputUPNumero                       : new FormControl({ value: this.dataMySQL[index]['exp_numero']                              ?? " ",     disabled: true }),
      inputUPLocalidad                    : new FormControl({ value: this.dataMySQL[index]['exp_localidad']                           ?? " ",     disabled: true }),
      inputUPProvincia                    : new FormControl({ value: this.dataMySQL[index]['exp_provincia']                           ?? " ",     disabled: true }),
      inputUPCodigoPostal                 : new FormControl({ value: this.dataMySQL[index]['exp_codigo_postal']                       ?? " ",     disabled: true })
    })

  }

  loadFormLlamadasSeg(index:number) {
    
    this.form = new FormGroup({
      inputLlsIdlls                     : new FormControl({ value: this.dataMySQL[index]['lls_id_llamadas_seg']             ?? " ",  disabled: true }),
      inputLlsContesta                  : new FormControl({ value: this.dataMySQL[index]['lls_contesta']                    ?? " ",  disabled: true }),
      inputLlsEstado                    : new FormControl({ value: this.dataMySQL[index]['lls_estado']                      ?? " ",  disabled: true }),
      inputLlsExpediente                : new FormControl({ value: this.dataMySQL[index]['lls_expediente']                  ?? " ",  disabled: true }),
      inputLlsFacturaEmitidaProducto    : new FormControl({ value: this.dataMySQL[index]['lls_factura_emitida_producto']    ?? " ",  disabled: true }),
      inputLlsFacturacionEspecial       : new FormControl({ value: this.dataMySQL[index]['lls_facturacion_especial']        ?? " ",  disabled: true }),
      inputLlsFechaCreacion             : new FormControl({ value: this.dataMySQL[index]['lls_fecha_creacion']              ?? " ",  disabled: true }),
      inputLlsFechaGestion              : new FormControl({ value: this.dataMySQL[index]['lls_fecha_gestion']               ?? " ",  disabled: true }),
      inputLlsFechaMod                  : new FormControl({ value: this.dataMySQL[index]['lls_fecha_modificacion']          ?? " ",  disabled: true }),
      inputLlsInterna                   : new FormControl({ value: this.dataMySQL[index]['lls_interna']                     ?? " ",  disabled: true }),
      inputLlsIva                       : new FormControl({ value: this.dataMySQL[index]['lls_iva']                         ?? " ",  disabled: true }),
      inputLlsKilometraje               : new FormControl({ value: this.dataMySQL[index]['lls_kilometraje']                 ?? " ",  disabled: true }),
      inputLlsObservaciones             : new FormControl({ value: this.dataMySQL[index]['lls_observaciones']               ?? " ",  disabled: true }),
      inputLlsPrecio                    : new FormControl({ value: this.dataMySQL[index]['lls_precio']                      ?? " ",  disabled: true }),
      inputLlsProxGestion               : new FormControl({ value: this.dataMySQL[index]['lls_proxima_gestion']             ?? " ",  disabled: true }),
      inputLlsproxLlamada               : new FormControl({ value: this.dataMySQL[index]['lls_proxima_llamada']             ?? " ",  disabled: true }),
      inputLlsRealizado                 : new FormControl({ value: this.dataMySQL[index]['lls_realizada']                   ?? " ",  disabled: true }),
      inputLlsRealizadoPor              : new FormControl({ value: this.dataMySQL[index]['lls_realizado_por']               ?? " ",  disabled: true }),
      inputLlsRefiereLesionado          : new FormControl({ value: this.dataMySQL[index]['lls_refiere_lesionado']           ?? " ",  disabled: true }),
      inputLlsTipoGestion               : new FormControl({ value: this.dataMySQL[index]['lls_tipo_gestion']                ?? " ",  disabled: true }),
      inputLlsTipoInforme               : new FormControl({ value: this.dataMySQL[index]['lls_tipo_informe']                ?? " ",  disabled: true }),
    })
  }

  loadFormPlantillaProducto(index:number) {
    this.form = new FormGroup({
      inputPPIdPP                       : new FormControl({ value: this.dataMySQL[index]['pp_id_plantilla_productos']       ?? " ",  disabled: true }),
      inputPPAut                        : new FormControl({ value: this.dataMySQL[index]['pp_autorizacion']                 ?? " ",  disabled: true }),
      inputPPCentro                     : new FormControl({ value: this.dataMySQL[index]['pp_centro']                       ?? " ",  disabled: true }),
      inputPPCita                       : new FormControl({ value: this.dataMySQL[index]['pp_cita']                         ?? " ",  disabled: true }),
      inputPPCompartido                 : new FormControl({ value: this.dataMySQL[index]['pp_compartido']                   ?? " ",  disabled: true }),
      inputPPEstado                     : new FormControl({ value: this.dataMySQL[index]['pp_estado']                       ?? " ",  disabled: true }),
      inputPPFacturaEmitidaProducto     : new FormControl({ value: this.dataMySQL[index]['pp_factura_emitida_producto']     ?? " ",  disabled: true }),
      inputPPFechaCreacion              : new FormControl({ value: this.dataMySQL[index]['pp_fecha_creacion']               ?? " ",  disabled: true }),
      inputPPFechaFin                   : new FormControl({ value: this.dataMySQL[index]['pp_fecha_fin']                    ?? " ",  disabled: true }),
      inputPPFechaInicio                : new FormControl({ value: this.dataMySQL[index]['pp_fecha_inicio']                 ?? " ",  disabled: true }),
      inputPPFechaMod                   : new FormControl({ value: this.dataMySQL[index]['pp_fecha_modificacion']           ?? " ",  disabled: true }),
      inputPPIva                        : new FormControl({ value: this.dataMySQL[index]['pp_iva']                          ?? " ",  disabled: true }),
      inputPPMedico                     : new FormControl({ value: this.dataMySQL[index]['pp_medico']                       ?? " ",  disabled: true }),
      inputPPOtroServicio               : new FormControl({ value: this.dataMySQL[index]['pp_otro_servicio']                ?? " ",  disabled: true }),
      inputPPPrecioCompra               : new FormControl({ value: this.dataMySQL[index]['pp_precio_compra']                ?? " ",  disabled: true }),
      inputPPPrecioVenta                : new FormControl({ value: this.dataMySQL[index]['pp_precio_venta']                 ?? " ",  disabled: true }),
      inputPPProducto                   : new FormControl({ value: this.dataMySQL[index]['pp_producto']                     ?? " ",  disabled: true }),
      inputPPResultado                  : new FormControl({ value: this.dataMySQL[index]['pp_resultado']                    ?? " ",  disabled: true }),
      inputPPTotalCompra                : new FormControl({ value: this.dataMySQL[index]['pp_total_compra']                 ?? " ",  disabled: true }),
      inputPPTotalDias                  : new FormControl({ value: this.dataMySQL[index]['pp_total_dias']                   ?? " ",  disabled: true }),
      inputPPTotalVentas                : new FormControl({ value: this.dataMySQL[index]['pp_total_venta']                  ?? " ",  disabled: true }),
      inputPPUnidadesCompra             : new FormControl({ value: this.dataMySQL[index]['pp_unidades_compra']              ?? " ",  disabled: true }),
      inputPPUnidadesVenta              : new FormControl({ value: this.dataMySQL[index]['pp_unidades_venta']               ?? " ",  disabled: true }),
    })
  }

  loadFormPlantillaProductosFacturas(index:number) {

    this.form = new FormGroup({
      inputPPFidPPF                     : new FormControl({ value: this.dataMySQL[index]['ppf_id_plantillas_productos_tienen_facturas']       ?? " ",  disabled: true }),
      inputPPFEstado                    : new FormControl({ value: this.dataMySQL[index]['ppf_estado']                                        ?? " ",  disabled: true }),
      inputPPFFacturaEmitida            : new FormControl({ value: this.dataMySQL[index]['ppf_factura_emitida_producto']                      ?? " ",  disabled: true }),
      inputPPFFacturaRecibidaProducto   : new FormControl({ value: this.dataMySQL[index]['ppf_factura_recibida_producto']                     ?? " ",  disabled: true }),
      inputPPFFechaCreacion             : new FormControl({ value: this.dataMySQL[index]['ppf_fecha_creacion']                                ?? " ",  disabled: true }),
      inputPPFFechaMod                  : new FormControl({ value: this.dataMySQL[index]['ppf_fecha_modificacion']                            ?? " ",  disabled: true }),
      inputPPFIva                       : new FormControl({ value: this.dataMySQL[index]['ppf_iva']                                           ?? " ",  disabled: true }),
      inputPPFNumeroActos               : new FormControl({ value: this.dataMySQL[index]['ppf_numero_actos']                                  ?? " ",  disabled: true }),
      inputPPFNumeroFactura             : new FormControl({ value: this.dataMySQL[index]['ppf_numero_factura']                                ?? " ",  disabled: true }),
      inputPPFPlantilla                 : new FormControl({ value: this.dataMySQL[index]['ppf_plantilla']                                     ?? " ",  disabled: true }),
      inputPPFPrecioUnitario            : new FormControl({ value: this.dataMySQL[index]['ppf_precio_unitario']                               ?? " ",  disabled: true }),
      inputPPFTotal                     : new FormControl({ value: this.dataMySQL[index]['ppf_total']                                         ?? " ",  disabled: true }),
      inputPPF                          : new FormControl({ value: this.dataMySQL[index]['ppf_total_iva']                                     ?? " ",  disabled: true }),
    })
  }

  getAut(index: number) {
    this.loadingPageFlag = true;
    let getData : MatTableDataSource<any>;
    let autorizacion: Autorizacion[];

    this.facturacionService.obtenerAutMySQL().subscribe({
      next: (data: Autorizaciones) => {
        autorizacion = data.data;
        getData = new MatTableDataSource(data.data);
        getData.paginator = this.paginator;
        getData.sort = this.sort;
        this.dataIDs = [];
        this.dataMySQL = [];
        for(let i = 0; i < autorizacion.length; i++){
          this.dataIDs.push({index: autorizacion[i][0]['aut_id_autorizaciones']});
          this.dataMySQL.push(autorizacion[i][0]);
        }
      },

      error: (err: any) => {
        console.log(err);
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
        
      },
      complete: () => {
        
        this.facturacionService.obtenerAutorizacionesCRM(this.dataMySQL[index]['aut_id_autorizaciones']).subscribe({
          next: (data: Autorizaciones) => {
            autorizacion = data.data;
            getData = new MatTableDataSource(data.data);
            getData.paginator = this.paginator;
            getData.sort = this.sort;
            this.dataCRM = [];
            for(let i = 0; i < autorizacion.length; i++){
              this.dataCRM.push(autorizacion[i][0]);
            }
          },
    
          error: (err: any) => {
            console.log(err);
            this.snackBar.open("Error en el servidor", undefined, {
              duration: 3 * 1000,
            });
            this.loadingPageFlag = false;
           
          },
          complete: () => {
            this.loadingPageFlag = false;
            this.loadFormAut(index);
        }
        });
    }
    });

    

  }

  getCitas(index: number) {
    this.loadingPageFlag = true;
    let citas: Cita[];
    this.facturacionService.obtenerCitasMySQL().subscribe({
      next: (data: Citas) => {
        citas = data.data;
        this.dataIDs = [];
        this.dataMySQL = [];
        for(let i = 0; i < citas.length; i++) {
          this.dataIDs.push({index: citas[i][0]['cup_id_citas']});
          this.dataMySQL.push(citas[i][0]);
        }
        //console.log(this.dataMySQL);
      },
      error: (err: any) => {
        console.log(err);
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
        this.loadingPageFlag = false;
       
      },
      complete: () => {
        this.loadingPageFlag = false;
        this.loadFormCitas(index);
      }
    })
  }

  getConsultas(index : number) {
    this.loadingPageFlag = true;
    let consultas : Consulta[];
    this.facturacionService.obtenerConsultasMySQL().subscribe({
      next: (data: any) => {
        consultas = data.data;
        this.dataIDs = [];
        this.dataMySQL = [];
        for(let i = 0; i < consultas.length; i++) {
          this.dataIDs.push({index: consultas[i][0]['csup_id_consultas']});
          this.dataMySQL.push(consultas[i][0]);
        }
        //console.log(this.dataMySQL);
      },
      error: (err: any) => {
        console.log(err);
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
        this.loadingPageFlag = false;
       
      },
      complete: () => {
        this.loadingPageFlag = false;
        this.loadFormConsultas(index);
      }
    })
  }

  getExpedientes(index:number) {

    this.loadingPageFlag = true;
    let expedientes : Expediente[];
    this.facturacionService.obtenerExpedientesMySQL().subscribe({
      next: (data: ExpedientesAlta) => {
        expedientes = data.data;
        this.dataIDs = [];
        this.dataMySQL = [];
        for(let i = 0; i < expedientes.length; i++) {
          this.dataIDs.push({index: expedientes[i][0]['exp_id_expedientes_up']});
          this.dataMySQL.push(expedientes[i][0]);
          
        }
        //console.log(this.dataMySQL);
      },
      error: (err: any) => {
        console.log(err);
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
        this.loadingPageFlag = false;
       
      },
      complete: () => {
        this.loadingPageFlag = false;
        this.loadFormExpedientes(index);
      }
    })
  }

  getLlamadasSeg(index:number) {

    this.loadingPageFlag = true;
    let llamadaSeg : LlamadasSeg[];
    this.facturacionService.obtenerLlamadasSegMySQL().subscribe({
      next: (data: LlamadasSegList) => {
        llamadaSeg = data.data;
        this.dataIDs = [];
        this.dataMySQL = [];
        for(let i = 0; i < llamadaSeg.length; i++) {
          this.dataIDs.push({index: llamadaSeg[i][0]['lls_id_llamadas_seg']});
          this.dataMySQL.push(llamadaSeg[i][0]);
          
        }
        //console.log(this.dataMySQL);
      },
      error: (err: any) => {
        console.log(err);
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
        this.loadingPageFlag = false;
       
      },
      complete: () => {
        this.loadingPageFlag = false;
        this.loadFormLlamadasSeg(index);
      }
    })

  }

  getPlantillaProductos(index: number) {

    this.loadingPageFlag = true;
    let plantillaProductos : PlantillaProductos[];
    this.facturacionService.obtenerPlantillaProductosMySQL().subscribe({
      next: (data: PlantillasProductosData) => {
        plantillaProductos = data.data;
        this.dataIDs = [];
        this.dataMySQL = [];
        for(let i = 0; i < plantillaProductos.length; i++) {
          this.dataIDs.push({index: plantillaProductos[i][0]['pp_id_plantilla_productos']});
          this.dataMySQL.push(plantillaProductos[i][0]);
          
        }
        //console.log(this.dataMySQL);
      },
      error: (err: any) => {
        console.log(err);
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
        this.loadingPageFlag = false;
       
      },
      complete: () => {
        this.loadingPageFlag = false;
        this.loadFormPlantillaProducto(index);
      }
    })

  }

  getPlantillaProductosTienenFactura(index: number) {

    this.loadingPageFlag = true;
    let plantillaProductos : PlantillaProductosFacturas[];
    this.facturacionService.obtenerPlantillaProductoTienenFacturasMySQL().subscribe({
      next: (data: any) => {
        plantillaProductos = data.data;
        this.dataIDs = [];
        this.dataMySQL = [];
        for(let i = 0; i < plantillaProductos.length; i++) {
          this.dataIDs.push({index: plantillaProductos[i][0]['ppf_id_plantillas_productos_tienen_facturas']});
          this.dataMySQL.push(plantillaProductos[i][0]);
          
        }
        //console.log(this.dataMySQL);
      },
      error: (err: any) => {
        console.log(err);
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
        this.loadingPageFlag = false;
       
      },
      complete: () => {
        this.loadingPageFlag = false;
        this.loadFormPlantillaProductosFacturas(index);
      }
    })

  }

  selectElement(index: number){
    if(this.tabState == 0){
      this.loadFormAut(index);
    }
    
    if (this.tabState == 1) {
      this.loadFormCitas(index);
    } 
    
    if (this.tabState == 3) {
      this.loadFormConsultas(index);
    }

    if (this.tabState == 4) {
      this.loadFormExpedientes(index);
    }

    if (this.tabState == 5) {
      this.loadFormLlamadasSeg(index);
    }

    if (this.tabState == 6) {
      this.loadFormPlantillaProducto(index);
    }

    if (this.tabState == 7) {
      this.loadFormPlantillaProductosFacturas(index);
    }
  }

}

export interface PeriodicElement {
  index: string;
}
