import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Archivo } from '../models/archivo';

export interface IFileService {
  queryInFolder(folderId: string): Observable<Archivo[]>;
  get(id: string): Archivo;
  loadMap(archivos: Archivo[]);
  init();
  delete(id: string);
}

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private map : Map<string, Archivo>;

  constructor() { }

  init(){
    this.map = new Map<string, Archivo>();
  }

  delete(id: string) {
    this.map.delete(id);
  }

  update(id: string, update: Partial<Archivo>) {
    let element = this.map.get(id);
    element = Object.assign(element, update);
    this.map.set(element.archDescripcion, element);
  }

  loadMap(archivos: Archivo[]) {
    archivos.forEach(element => {
      this.map.set(element.archPath, this.clone(element));
    });
  }

  

  private querySubject: BehaviorSubject<Archivo[]> = new BehaviorSubject<Archivo[]>([]);

queryInFolder(folderId: string): Observable<Archivo[]> {
  // Filtrar los archivos que pertenecen a la carpeta especificada
  const result: Archivo[] = [];
  this.map.forEach(element => {
    if (element.archParent === folderId) {
      result.push(this.clone(element));
    }
  });

  // Actualizar el estado del BehaviorSubject con el nuevo resultado
  this.querySubject.next(result);

  return this.querySubject.asObservable();
}

  get(id: string) {
    return this.map.get(id);
  }

  clone(element: Archivo) {
    return JSON.parse(JSON.stringify(element));
  }
}
