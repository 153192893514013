<app-header-encargo-tramitador-vdc></app-header-encargo-tramitador-vdc>

<div class="progress_spinner" *ngIf="mostrarProgressSpinner">
    <mat-spinner></mat-spinner>
</div>

<div class="container-fluid">
    <!-- Encargo elegido -->
    <div class="row fila_encargo">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-2">
                    <div>
                        <i class="bi bi-circle-fill icon_size" [ngClass]="colorEncargo(encargo.upgen_fecha_limite)"></i>
                        <h3 class="text_inline_icon_encargo">{{encargo.id_plataforma}}</h3>
                    </div>
                    <div>
                        <i class="bi bi-chat-left-text-fill icon_size"><h3 class="text_inline_icon_mensaje">{{countMensajes}} Mensaje</h3></i>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="row">
                        <div  class="col-md-8">
                            <h5 class="m-0 pt-3 subtitulo_h5">Fecha encargo</h5>
                            <h4 class="subtitulo_h4">{{encargo.upgen_fecha_tarea | date: 'dd/MM/yyyy HH:mm:ss'}}</h4>
                        </div>
                        <div class="col-md-2">
                            <mat-divider [vertical] ="true" class="mt-3" style="height:50px"></mat-divider>
                        </div>
                        <div class="col-md-2">
                           
                        </div>
                    </div> 
                </div>
    
                <div class="col-md-4">
                    <h5 class="m-0 pt-3 subtitulo_h5">Estado</h5>
                    <h4 class="subtitulo_h4">{{encargo.upgen_estado_encargo}}</h4>
                </div>
    
                <div class="col-md-2">
                    <div class="row">
                        <div class="col-md-8">
                            <h5 class="m-0 pt-3 subtitulo_h5">Tipo de encargo</h5>
                            <h4 class="subtitulo_h4">{{encargo.nombre_tipo_encargo}}</h4>
                        </div>
                        <div class="col-md-2">
                            <mat-divider [vertical] ="true" class="mt-3" style="height:50px"></mat-divider>
                        </div>
                        <div class="col-md-2">
                           
                        </div>
                    </div>
                </div>
    
                <!-- <div class="col-md-2">
                    <div class="row">
                        <div class="col-md-8">
                            <h5 class="m-0 pt-3 subtitulo_h5">Fecha Limite</h5>
                            <h4 class="subtitulo_h4">{{encargo.upgen_fecha_limite | date: 'dd/MM/yyyy'}}</h4>
                        </div>
                        <div class="col-md-2">
                            <mat-divider [vertical] ="true" class="mt-3" style="height:50px"></mat-divider>
                        </div>
                        <div class="col-md-2">
                           
                        </div>
                    </div>
                </div> -->
    
                <div class="col-md-2">
                    <h5 class="m-0 pt-3 subtitulo_h5">Lesionado</h5>
                    <h4 class="subtitulo_h4">{{encargo.upgen_lesionado}}</h4>
                </div>
            </div>
    
        </div>
    </div>

    <!-- Informacion -->
    <div class="cointainer_tab">
        <!-- Tabs -->
        <div class="tab">
            <button class="tablinks active" (click)="onChangeOption('datos')" id="datos">Datos Generales</button>
            <button class="tablinks" (click)="onChangeOption('informe')" id="informe">Informe</button>
            <button class="tablinks" (click)="onChangeOption('documentacion')" id="documentacion">Documentación</button>
            <button class="tablinks" (click)="onChangeOption('mensajes')" id="mensajes">Mensajes<span *ngIf="countMensajes!=0" matBadge="{{countMensajes}}" matBadgeColor="warn" matBadgeOverlap="false"></span></button>
            <button class="tablinks" (click)="onChangeOption('historico')" id="historico">Histórico del siniestro</button>
        </div>

        <!-- Datos Generales -->
        <div class="tabcontent datos" id="datos">

            <div class="container-fluid" style="width: 92%;">
                <div class="row m-0 ms-3 fila_datos">
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Nombre del lesionado</h5>
                        <h4 class="subtitulo_h4">{{encargo.upgen_lesionado}}</h4>
                    </div>
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Condición del lesionado</h5>
                        <h4 class="subtitulo_h4">{{encargo.upgen_condicion_lesionado}}</h4>
                    </div>
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Provincia</h5>
                        <h4 class="subtitulo_h4">{{encargo.upgen_provincias}}</h4>
                    </div>
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Localidad</h5>
                        <h4 class="subtitulo_h4">{{encargo.upgen_localidad}}</h4>
                    </div>
                </div>

                <div class="row m-0 ms-3 fila_datos">
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Referencias del Siniestro</h5>
                        <h4 class="subtitulo_h4">{{encargo.upgen_referencia_siniestro}}</h4>
                    </div>
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Teléfono</h5>
                        <h4 class="subtitulo_h4">{{encargo.upgen_telefono}}</h4>
                    </div>
                </div>

                <div class="row m-0 ms-3 fila_datos">
                    <div class="col-md-9">
                        <h5 class="m-0 pt-3 subtitulo_h5">Forma de producción</h5>
                        <h4 class="subtitulo_h4">{{encargo.upgen_forma_produccion}}</h4>
                    </div>
                </div>

                

                <div class="row m-0 ms-3 fila_datos">
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Fecha Límite</h5>
                        <input type="date" [(ngModel)]="fechaLimiteFormmated" (change)="onChangeFechaLimite()">
                    </div>
                    <div class="col-md-3 pt-3">
                        <mat-checkbox [(ngModel)]="isUrgente">Urgente</mat-checkbox>
                    </div>
                </div>

                <div class="row m-0 ms-3 mt-3 fila_datos">
                    <div *ngIf="mostrarSelectTramitacion" class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tramitador</mat-label>
                            <mat-select [(ngModel)]="idNombreTramitador">
                            <mat-option *ngFor="let tramitador of tramitadores" [value]="tramitador.id">
                                {{tramitador.nombre}}
                            </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                

                <div class="row m-0 ms-3 mt-3 fila_datos">
                    <div id="responsabilidad" class="col-md-6">
                        <mat-form-field style="width: 100%;" appearance="outline">
                            <mat-label>Responsabilidad</mat-label>
                            <mat-select [(ngModel)]="responsabilidad" placeholder="Responsabilidad">
                                <mat-option value="Gasto médico">Gasto médico</mat-option>
                                <mat-option value="Reclamación">Reclamación</mat-option>
                                <mat-option value="Defensa">Defensa</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    
                    <div *ngIf="encargo.upgen_url_videoconferencia" class="col-md-6">
                        <h5 class="m-0 pt-1 subtitulo_h5">Url Videoconsulta</h5>
                        <h4 class="subtitulo_h4">{{encargo.upgen_url_videoconferencia}}</h4>
                    </div>
                </div>

                <div class="row m-0 ms-3 fila_datos">
                    <div id="peticion" class="col-md-12">
                        <mat-form-field style="width: 100%;" appearance="outline">
                            <mat-label>Petición</mat-label>
                            <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="4" cdkAutosizeMaxRows="20" matInput placeholder="Petición" [(ngModel)]="peticion" (keyup)="keyupClaseAnterior($event.target.value, 'peticion', encargoAnterior.upgen_peticion)"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <!-- Informe -->
        <div class="tabcontent informe noshow" id="informe" >
            <div class="container-fluid" style="width: 95%;">
                <div class="row" style="margin-top: 20px;">
                    <div class="col-md-12">
                        <h1>Informe</h1>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="width: 92%;">

                <div>
                    <div class="row m-0">
                        <h2>Fuente documental</h2>

                        <div id="fuenteDocumental" class="col-md-12" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Fuente documental</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="fuenteDocumental" placeholder="Fuente documental" (keyup)="keyupClaseAnterior($event.target.value, 'fuenteDocumental', encargoAnterior.upgen_fuente_documental)"></textarea>
                            </mat-form-field>
                        </div>
                        
                    </div> 
                    <!--<div class="row m-0">
                        <h2>Objeto del informe</h2>

                        <div id="objetoInforme" class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Objeto del informe</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="objetoInforme" placeholder="Objeto del informe" (keyup)="keyupClaseAnterior($event.target.value, 'objetoInforme', encargoAnterior.upgen_objeto_informe)"></textarea>
                            </mat-form-field>
                        </div>
                        
                    </div>-->
                </div>

                <!--<div *ngIf="encargo.nombre_tipo_encargo == 'PRUEBA! ESTUDIO DOCUMENTAL'">
                    <div class="row m-0">
                        <h2>Fuente documental</h2>

                        <div id="fuenteDocumental" class="col-md-12">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Fuente documental</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="fuenteDocumental" placeholder="Fuente documental" (keyup)="keyupClaseAnterior($event.target.value, 'fuenteDocumental', encargoAnterior.upgen_fuente_documental)"></textarea>
                            </mat-form-field>
                        </div>
                </div> -->
            
                <div class="row m-0">
                    <h2>Datos generales</h2>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Número de expediente</mat-label>
                            <input matInput [(ngModel)]="numExpediente" placeholder="Número de expediente">
                        </mat-form-field>
                    </div>
                    <div *ngIf="mostrarSelectTramitacion == false" class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nombre del médico</mat-label>
                            <input matInput disabled [(ngModel)]="nombreMedico" placeholder="Nombre del médico">
                        </mat-form-field>
                    </div>
                    <div *ngIf="mostrarSelectTramitacion" class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Médico</mat-label>
                            <mat-select [(ngModel)]="idMedico">
                              <mat-option *ngFor="let med of medicos" [value]="med.med_id_user">
                                {{med.med_nombre}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                    <div *ngIf="mostrarSelectTramitacion == false" class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nº de colegiado</mat-label>
                            <input matInput disabled [(ngModel)]="numColegiado" placeholder="Nº de colegiado">
                        </mat-form-field>
                    </div>
                    <div *ngIf="mostrarSelectTramitacion" class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo</mat-label>
                            <mat-select [(ngModel)]="idTipo">
                              <mat-option *ngFor="let tipo of tipos" [value]="tipo.id">
                                {{tipo.nombre}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                </div>

                <div class="row m-0">
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nombre lesionado</mat-label>
                            <input matInput [(ngModel)]="nombreLesionado" placeholder="Nombre lesionado">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Edad lesionado</mat-label>
                            <input matInput [(ngModel)]="edadLesionado" placeholder="Edad lesionado">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Provincia lesionado</mat-label>
                            <input matInput [(ngModel)]="provincia" placeholder="Provincia lesionado">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row m-0">
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Localidad lesionado</mat-label>
                            <input matInput [(ngModel)]="localidad" placeholder="Localidad lesionado">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Fecha siniestro</mat-label>
                            <input matInput [(ngModel)]="fechaSiniestro" placeholder="Fecha siniestro">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Referencia siniestro</mat-label>
                            <input matInput [(ngModel)]="refSiniestro" placeholder="Referencia siniestro">
                        </mat-form-field>
                    </div>
                </div>

                <!-- Mecanismo de produccion -->
                <div class="row m-0">
                    <!--<h2>Mecanismo de producción</h2> -->
                    <div id="mecanismoProduccion" class="col-md-12" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                        <mat-form-field style="width: 92%;" appearance="outline">
                            <mat-label>Mecanismo de producción</mat-label>
                            <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="mecanismoProduccion" placeholder="Mecanismo de producción" (keyup)="keyupClaseAnterior($event.target.value, 'mecanismoProduccion', encargoAnterior.upgen_mecanismo_produccion)"></textarea>
                        </mat-form-field>
                    </div>
                </div>


 
                <div>

                    <div class="row m-0">
                        <h2>Datos laborales</h2>
                        <div id="profesion" class="col-md-6" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Profesión</mat-label>
                                <input matInput [(ngModel)]="profesion" placeholder="Profesión" (keyup)="keyupClaseAnterior($event.target.value, 'profesion', encargoAnterior.upgen_profesion)">
                            </mat-form-field>
                        </div>
                        <div id="situacionLaboral" class="col-md-6" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Situación laboral</mat-label>
                                <input matInput [(ngModel)]="situacionLaboral" placeholder="Situación laboral" (keyup)="keyupClaseAnterior($event.target.value, 'situacionLaboral', encargoAnterior.upgen_situacion_laboral)">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div id="contingencia" class="col-md-6" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Contingencia</mat-label>
                                <input matInput [(ngModel)]="contingencia" placeholder="Contingencia" (keyup)="keyupClaseAnterior($event.target.value, 'contingencia', encargoAnterior.upgen_contingencia)">
                            </mat-form-field>
                        </div>
    
                    </div>
                    <div class="row m-0">
                        <div id="bajaLaboral" class="col-md-4" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Baja laboral</mat-label>
                                <mat-select [(ngModel)]="bajaLaboral">
                                    <mat-option value="Si">Si</mat-option>
                                    <mat-option value="No">No</mat-option>
                                    <mat-option value="No refiere">No refiere</mat-option>
                                    <mat-option value="Se desconoce">Se desconoce</mat-option>
                                </mat-select>
                            </mat-form-field> 
                        </div>
                        <div id="fechaBajaLaboral" class="col-md-4" *ngIf="bajaLaboral == 'Si'">
                            <h5 class="m-0 pt-3 subtitulo_h5">Fecha Baja laboral</h5>
                            <input type="date" [(ngModel)]="fechaBajaLaboral">
                        </div>
                        <div id="fechaAltaLaboral" class="col-md-4" *ngIf="bajaLaboral == 'Si'">
                            <h5 class="m-0 pt-3 subtitulo_h5">Fecha Alta laboral</h5>
                            <input type="date" [(ngModel)]="fechaAltaLaboral">
                        </div>
                    </div>

                    <div class="row m-0">
                        <h2>Historia clínica</h2>
                    </div>

                    <div class="row m-0">
                        <div id="antecedentes" class="col-md-12" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Antecedentes Personales</mat-label>
                                <textarea matInput [(ngModel)]="antecedentes" placeholder="Antecedentes Personales" (keyup)="keyupClaseAnterior($event.target.value, 'antecedentes', encargoAnterior.upgen_antecedentes_personales)"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div id="centroUrgencias" class="col-md-4" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Centro de Urgencias</mat-label>
                                <input matInput [(ngModel)]="centroUrgencias" placeholder="Centro de Urgencias" (keyup)="keyupClaseAnterior($event.target.value, 'centroUrgencias', encargoAnterior.upgen_centro_urgencias)">
                            </mat-form-field>
                        </div>
                        <div id="cuandoAcude" class="col-md-6" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>¿Cuándo Acude?</mat-label>
                                <input matInput [(ngModel)]="cuandoAcude" placeholder="¿Cuándo Acude?" (keyup)="keyupClaseAnterior($event.target.value, 'cuandoAcude', encargoAnterior.upgen_cuando_acude)">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div id="diagnosticoUrgencias" class="col-md-12" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Diagnóstico de Urgencias</mat-label>
                                <textarea matInput [(ngModel)]="diagnosticoUrgencias" placeholder="Diagnóstico de Urgencias" (keyup)="keyupClaseAnterior($event.target.value, 'diagnosticoUrgencias', encargoAnterior.upgen_diagnostico_urgencias)"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div id="centroRehab" class="col-md-4" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Centro Rehabilitación</mat-label>
                                <input matInput [(ngModel)]="centroRehab" placeholder="Centro Rehabilitación" (keyup)="keyupClaseAnterior($event.target.value, 'centroRehab', encargoAnterior.upgen_centro_rehabilitacion)">
                            </mat-form-field>
                        </div>
                        
                        <div id="medicoSeguimiento" class="col-md-4"  [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Médico tratante</mat-label>
                                <input matInput [(ngModel)]="medicoSeguimiento" placeholder="Médico tratante" (keyup)="keyupClaseAnterior($event.target.value, 'medicoSeguimiento', encargoAnterior.upgen_medico_seguimiento)">
                            </mat-form-field>
                        </div>
                        <div id="numSesiones" class="col-md-4" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Nº de Sesiones</mat-label>
                                <input matInput [(ngModel)]="numSesiones" type="number" placeholder="Nº de Sesiones" (keyup)="keyupClaseAnterior($event.target.value, 'numSesiones', encargoAnterior.upgen_num_sesiones)">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div id="inicioTratamiento" class="col-md-4" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Inicio Tratamiento</mat-label>
                                <input matInput [(ngModel)]="inicioTratamiento" placeholder="Inicio Tratamiento" (keyup)="keyupClaseAnterior($event.target.value, 'inicioTratamiento', encargoAnterior.upgen_inicio_tratamiento)">
                            </mat-form-field>
                        </div>

                        <div id="altaMedica" class="col-md-4" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>¿Alta Médica?</mat-label>
                                <mat-select [(ngModel)]="altaMedica" placeholder="Alta Médica">
                                    <mat-option value="Sí">Sí</mat-option>
                                    <mat-option value="No">No</mat-option>
                                </mat-select>
                            </mat-form-field>
                            
                        </div>
                        <div id="numConsultas" class="col-md-4" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Nº de Consultas</mat-label>
                                <input matInput [(ngModel)]="numConsultas" type="number" placeholder="Nº de Consultas" (keyup)="keyupClaseAnterior($event.target.value, 'numConsultas', encargoAnterior.upgen_num_consultas)">
                            </mat-form-field>
                        </div>
                        
                    </div>

                    <div class="row m-0">
                        <div id="pruebasRealizadas" class="col-md-12" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Pruebas Realizadas</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="pruebasRealizadas" placeholder="Pruebas Realizadas" (keyup)="keyupClaseAnterior($event.target.value, 'pruebasRealizadas', encargoAnterior.upgen_pruebas_realizadas)"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div id="estadoInicial" class="col-md-12" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Estado inicial</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="estadoInicial" placeholder="Estado inicial" (keyup)="keyupClaseAnterior($event.target.value, 'estadoInicial', encargoAnterior.upgen_estado_inicial)"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div id="estadoFinal" class="col-md-12" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field style="width: 92%;" appearance="outline">
                                <mat-label>Determinación al alta clínica - Estado final</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="estadoFinal" placeholder="Estado final" (keyup)="keyupClaseAnterior($event.target.value, 'estadoFinal', encargoAnterior.upgen_estado_final)"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div id="bioFuncional" class="col-md-4" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>¿Bio funcional?</mat-label>
                                <input matInput [(ngModel)]="bioFuncional" placeholder="¿Bio funcional?" (keyup)="keyupClaseAnterior($event.target.value, 'bioFuncional', encargoAnterior.upgen_bio_funcional)">
                            </mat-form-field>
                        </div>
                        
                        <div id="coherenciaSeguimiento" class="col-md-4"  [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Coherencia con el seguimiento</mat-label>
                                <input matInput [(ngModel)]="coherenciaSeguimiento" placeholder="Coherencia con el seguimiento" (keyup)="keyupClaseAnterior($event.target.value, 'coherenciaSeguimiento', encargoAnterior.upgen_coherencia_seguimiento)">
                            </mat-form-field>
                        </div>

                        <div id="fechaUltimaConsulta" class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Fecha última consulta</mat-label>
                                <input matInput [matDatepicker]="pickerFechaUltimaConsulta" [formControl] ="fechaUltimaConsulta">
                                <mat-datepicker-toggle matSuffix [for]="pickerFechaUltimaConsulta"></mat-datepicker-toggle>
                                <mat-datepicker #pickerFechaUltimaConsulta></mat-datepicker>
                            </mat-form-field>
                            
                        </div>
                    </div>

                    <div class="row m-0">
                        <div id="fechaPrueba" class="col-md-4">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Fecha prueba</mat-label>
                                <input matInput [matDatepicker]="pickerFechaPrueba" [formControl] ="fechaPrueba">
                                <mat-datepicker-toggle matSuffix [for]="pickerFechaPrueba"></mat-datepicker-toggle>
                                <mat-datepicker #pickerFechaPrueba></mat-datepicker>
                              </mat-form-field>
                            
                        </div>
                        <div id="consistenciaPrueba" class="col-md-4" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Consistencia prueba</mat-label>
                                <input matInput [(ngModel)]="consistenciaPrueba" placeholder="Consistencia prueba" (keyup)="keyupClaseAnterior($event.target.value, 'consistenciaPrueba', encargoAnterior.upgen_consistencia_prueba)">
                            </mat-form-field>
                        </div>
                        <div id="movilidad" class="col-md-4" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Movilidad(NFHAS)</mat-label>
                                <input matInput [(ngModel)]="movilidad" placeholder="Movilidad(NFHAS)" (keyup)="keyupClaseAnterior($event.target.value, 'movilidad', encargoAnterior.upgen_movilidad)">
                            </mat-form-field>
                        </div>
                </div>


                <!-- Valoracion -->
                    <div class="row m-0">
                        <h2>Valoración</h2>

                        
                        <h4>Lesiones temporales</h4>
                        <div id="diasBasicos" class="col-md-3">
                            <mat-form-field class="input_informe_67" appearance="outline">
                                <mat-label>Días PPP Básicos</mat-label>
                                <input matInput [(ngModel)]="diasBasicos" (change)="sumarDiasPPP()" placeholder="Días PPP Básicos" (keyup)="keyupClaseAnterior($event.target.value, 'diasBasicos', encargoAnterior.upgen_dias_ppp_basicos)" type="number" min="0">
                            </mat-form-field>
                        </div>
                        <div id="diasModerados" class="col-md-3">
                            <mat-form-field class="input_informe_67" appearance="outline">
                                <mat-label>Días PPP Moderados</mat-label>
                                <input matInput [(ngModel)]="diasModerados" (change)="sumarDiasPPP()" placeholder="Días PPP Moderados" (keyup)="keyupClaseAnterior($event.target.value, 'diasModerados', encargoAnterior.upgen_dias_ppp_moderados)" type="number" min="0">
                            </mat-form-field>
                        </div>
                        <div id="diasGraves" class="col-md-3">
                            <mat-form-field class="input_informe_67" appearance="outline">
                                <mat-label>Días PPP Graves</mat-label>
                                <input matInput [(ngModel)]="diasGraves" (change)="sumarDiasPPP()" placeholder="Días PPP Graves" (keyup)="keyupClaseAnterior($event.target.value, 'diasGraves', encargoAnterior.upgen_dias_ppp_graves)" type="number" min="0">
                            </mat-form-field>
                        </div>
                        <div id="diasMuyGraves" class="col-md-3">
                            <mat-form-field class="input_informe_67" appearance="outline">
                                <mat-label>Días PPP Muy Graves</mat-label>
                                <input matInput [(ngModel)]="diasMuyGraves" (change)="sumarDiasPPP()" placeholder="Días PPP Muy Graves" (keyup)="keyupClaseAnterior($event.target.value, 'diasMuyGraves', encargoAnterior.upgen_dias_ppp_muy_graves)" type="number" min="0">
                            </mat-form-field>
                        </div>
                    </div>

                    <div id="diasTotales" class="row m-0">
                        <div id="diasTotales" class="col-md-3">
                            <mat-form-field class="input_informe_67" appearance="outline">
                                <mat-label>Días totales</mat-label>
                                <input matInput disabled [(ngModel)]="diasTotales" placeholder="Dias totales">
                            </mat-form-field>
                        </div>
                    </div>



                    <div class="row m-0" [formGroup]="secuelaForm">
                        <div formArrayName="secuelas">
                            <h4>Secuelas<button style="margin-left: 20px;" (click)="addNuevasSecuelas()" mat-stroked-button>+</button></h4>

                            <div class="row m-0" *ngFor="let item of secuelas().controls; let i=index" [formGroupName]="i">
                                <div id="codigoSecuela" class="col-md-2" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                                    <mat-form-field class="input_informe_75" appearance="outline">
                                        <mat-label>Código</mat-label>
                                        <input matInput formControlName="codigo" placeholder="Código">
                                    </mat-form-field>
                                </div>
    
                                <div id="descripcionSecuela" class="col-md-7" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                                    <mat-form-field class="input_informe_75" appearance="outline">
                                        <mat-label>Descripción secuela</mat-label>
                                        <input matInput formControlName="descripcion" placeholder="Descripción secuela">
                                    </mat-form-field>
                                </div>
    
                                <div id="puntosSecuela" class="col-md-2" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                                    <mat-form-field class="input_informe_75" appearance="outline">
                                        <mat-label>Puntos</mat-label>
                                        <input matInput formControlName="puntos" placeholder="Puntos">
                                    </mat-form-field>
                                </div>
    
                                <div class="col-md-1">
                                    <button style="margin-top: 10px;" (click)="quitarSecuela(i)" mat-flat-button color="warn">-</button>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div *ngIf="numSecuelas > 0"class="row m-0">
                        <div class="col-md-2">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Total Puntos Secuelas</mat-label>
                                <input matInput disabled [(ngModel)]="puntosTotalesSecuelas" placeholder="Total Puntos Secuelas">
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Secuelas estéticas -->
                    <div class="row m-0" [formGroup]="secuelasEsteticasForm">

                        <div formArrayName="secuelas">
                            <h4>Secuelas estéticas<button style="margin-left: 20px;" (click)="addNuevasSecuelasEsteticas()" mat-stroked-button>+</button></h4>
    
                            <div class="row m-0" *ngFor="let item of secuelasEsteticas().controls; let i=index" [formGroupName]="i">
                                <div id="codigoSecuelaEstetica" class="col-md-2" [ngClass]="{anterior: addClassSecuelasEsteticasAntiguas}">
                                    <mat-form-field class="input_informe_75" appearance="outline">
                                        <mat-label>Código</mat-label>
                                        <input matInput formControlName="codigo" placeholder="Código">
                                    </mat-form-field>
                                </div>
    
                                <div id="descripcionSecuelaEstetica" class="col-md-7" [ngClass]="{anterior: addClassSecuelasEsteticasAntiguas}">
                                    <mat-form-field class="input_informe_75" appearance="outline">
                                        <mat-label>Descripción secuela</mat-label>
                                        <input matInput formControlName="descripcion" placeholder="Descripción secuela">
                                    </mat-form-field>
                                </div>
    
                                <div id="puntosSecuelaEstetica" class="col-md-2" [ngClass]="{anterior: addClassSecuelasEsteticasAntiguas}">
                                    <mat-form-field class="input_informe_75" appearance="outline">
                                        <mat-label>Puntos</mat-label>
                                        <input matInput formControlName="puntos" placeholder="Puntos">
                                    </mat-form-field>
                                </div>
    
                                <div class="col-md-1">
                                    <button style="margin-top: 10px;" (click)="quitarSecuelasEsteticas(i)" mat-flat-button color="warn">-</button>
                                </div>
                            </div>
    
                        </div>

                    </div>

                    <div *ngIf="numSecuelasEsteticas > 0" class="row m-0">
                        <div class="col-md-2">
                            <mat-form-field class="input_informe_75" appearance="outline">
                                <mat-label>Total Puntos Secuelas Estéticas</mat-label>
                                <input matInput disabled [(ngModel)]="puntosTotalesSecuelasEsteticas" placeholder="Total Puntos Secuelas Estéticas">
                            </mat-form-field>
                        </div>
                    </div>
                    

                    <div id="observaciones" class="col-md-12">
                        <mat-form-field style="width: 92%;" appearance="outline">
                            <mat-label>Observaciones</mat-label>
                            <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="observaciones" placeholder="Observaciones" (keyup)="keyupClaseAnterior($event.target.value, 'observaciones', encargoAnterior.upgen_observaciones)"></textarea>
                        </mat-form-field>
                    </div>

                    <div class="row m-0" *ngFor="let encargoAnteriorFision of encargosAnterioresFision">
                        <div class="col-md-12" style="width: 92%; margin-bottom: 20px;">
                            <mat-accordion class="example-headers-align" multi>
                                <mat-expansion-panel style="background-color: #ebebeb;">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <b>{{encargoAnteriorFision['Consulta']}}:</b>
                                        </mat-panel-title>

                                        <mat-panel-description>
                                            <div>
                                                Médico: {{encargoAnteriorFision['Médico']}}
                                            </div>
                                            <div>
                                                Fecha de asesoría: {{encargoAnteriorFision['Fecha']}}
                                            </div>
                                        </mat-panel-description>
                                        
                                    </mat-expansion-panel-header>

                                    <div class="row m-0">
                                        <h2>{{encargoAnteriorFision['Consulta']}}</h2>
                                        <div id="numInforme" class="col-md-4">
                                            <mat-form-field class="input_informe_75" appearance="fill">
                                                <mat-label>Centro</mat-label>
                                                <input matInput placeholder="Centro" value="{{encargoAnteriorFision['Centro']}}" readonly>
                                            </mat-form-field>
                                        </div>
                                        
                                        <div id="fechaAsesoria" class="col-md-4">
                                            <mat-form-field class="input_informe_75" appearance="fill">
                                                <mat-label>Fecha Asesoría</mat-label>
                                                <input matInput placeholder="Fecha de la Asesoría" value="{{encargoAnteriorFision['Fecha']}}" readonly>
                                            </mat-form-field>
                                        </div>
                
                                        <div id="asesorMedico" class="col-md-4">
                                            <mat-form-field class="input_informe_75" appearance="fill">
                                                <mat-label>Médico</mat-label>
                                                <input matInput placeholder="médico" value="{{encargoAnteriorFision['Médico']}}" readonly>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="row m-0 mb-3">
                                        <div id="exploracionFisica" class="col-md-12">
                                            <mat-form-field style="width: 92%;" appearance="fill">
                                                <mat-label>Exploración Física</mat-label>
                                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput placeholder="Exploración Física" value="{{encargoAnteriorFision['ExploraciónFisicaAlta']}}" readonly></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    
                                    
                     
                                    <div class="row m-0 mb-3">
                                        <div id="observacionesAsesoria" class="col-md-12">
                                            <mat-form-field style="width: 92%;" appearance="fill">
                                                <mat-label>Anamnesis</mat-label>
                                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput placeholder="Anamnesis" value="{{encargoAnteriorFision['Anamnesis']}}" readonly></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="row m-0 mb-3">
                                        <div id="proximaRevision" class="col-md-12">
                                            <mat-form-field style="width: 92%;" appearance="fill">
                                                <mat-label>Diagnóstico</mat-label>
                                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput placeholder="Diagnóstico" value="{{encargoAnteriorFision['Diagnóstico']}}" readonly></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
            </div>
        </div>
        </div>


        <!-- Documentacion -->
        <div class="tabcontent documentacion noshow" id="documentacion" >
            <div class="container-fluid" style="width: 95%;">
                <div class="row" style="margin-top: 20px;">
                    <div class="col-md-12">
                        <h1>Documentación</h1>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="width: 92%;">
                <div>
                    <app-file-manager [fileElements]="archivos | async" [path]="currentPath" [canNavigateUp]="canNavigateUp"
                        (navigatedDown)="navigateToFolder($event)" (navigatedUp)="navigateUp()"
                        (openModalView)="openModalFile($event)" 
                        (elementRemoved)="removeElement($event)" (elementCopy)="copyElement($event)" (elementPegar)="pegarElement()">
                    </app-file-manager>
                </div>
            </div>
        </div>

        <!-- Mensajes -->
        <div class="tabcontent mensajes noshow" id="mensajes" >
            <div class="container-fluid" style="width: 95%;">
                <div class="row" style="margin-top: 20px;">
                    <div class="col-md-12">
                        <h1>Mensajes</h1>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="width: 85%;">

                <!-- Mensajes sin leer -->
                <!-- <div *ngFor="let mensaje of mensajes" class="row fila_mensajes">
                    <div class="col-md-12">
                        <div class="row" style="margin-top: 10px;">
                            <div class="col-md-2">
                                <h4 style="font-weight: 500;">{{mensaje.msj_usuario_creacion}}</h4>
                            </div>
                            <div class="col-md-2">
                                <h5 class="h5_mensajes">{{mensaje.created_at}}</h5>
                            </div>
                            <div class="col-md-7">
                                <h5 class="h5_mensajes">{{mensaje.msj_texto}}
                                </h5>
                            </div>
                            <div class="col-md-1 check_mensaje_container">
                                <mat-checkbox style="margin: auto;" (change)="mensajeCheck($event, mensaje.id)"></mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div *ngIf="mensajes.length != 0" class="row" style="border: 2px solid black; max-height: 400px; overflow-y: scroll;">
                    <div class="col-md-12">
                        <div *ngFor="let mensaje of mensajes" class="row">
                            <div class="col-md-6">
                                <mat-card *ngIf="mensaje.tipoUsuario == 1" style="background-color: #B4DBCD; margin: 10px 0;">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <h4 style="font-weight: 500;">{{mensaje.nombreUsuario}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <h5 class="h5_mensajes">{{mensaje.fechaCreacion}}</h5>
                                        </div>
                                        <div class="col-md-8">
                                            <h5 class="h5_mensajes">{{mensaje.msj_texto}}</h5>
                                        </div>
                                    </div>
                                </mat-card>
                            </div>
                            <div class="col-md-6">
                                <mat-card *ngIf="mensaje.tipoUsuario == 0" style="background-color: grey; margin: 10px 0;">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <h4 style="font-weight: 500;">{{mensaje.nombreUsuario}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <h5 class="h5_mensajes">{{mensaje.fechaCreacion}}</h5>
                                        </div>
                                        <div class="col-md-8">
                                            <h5 class="h5_mensajes">{{mensaje.msj_texto}}</h5>
                                        </div>
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <!-- Boton mensajes leidos -->
                <div *ngIf="mensajes.length != 0" class="row" style="margin-top: 20px;">
                    <div class="col-md-2">
                        <button mat-stroked-button color="warn" (click)="mensajesLeidos()">Mensajes Leidos</button>
                    </div>
                </div>

                <div class="row" style="margin-top: 40px;">
                    <mat-divider class="separador_mensajes"></mat-divider>
                </div>

                <!-- Crear nuevo mensaje -->
                <div class="row" style="margin-top: 40px;">
                    <div class="col-md-12">
                        <mat-form-field appearance="outline" class="textarea_mensaje">
                            <mat-label>Crear nuevo mensaje</mat-label>
                            <textarea matInput placeholder="Texto del mensaje" [(ngModel)]="textoMensaje"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 80px;">
                    <div class="col-md-12">
                        <button mat-stroked-button class="boton_enviar_mensaje" (click)="enviarMensajeEncargo()">Enviar</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Histórico del siniestro -->
        <div class="tabcontent historico noshow" id="historico" >
            <div class="container-fluid" style="width: 95%;">
                <div class="row" style="margin-top: 20px;">
                    <div class="col-md-12">
                        <h1>Histórico del siniestro</h1>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="width: 85%;">
                <div *ngFor="let encargoBySiniestro of encargosBySiniestro" class="row fila_encargo_siniestro" (click)="abrirEncargoSiniestro(encargoBySiniestro.id)">
                    <div class="col-md-12">
                        <div class="row" style="margin-top: 10px;margin-bottom: 5px;">
                            <div class="col-md-2">
                                <div>
                                    <i class="bi bi-circle-fill icon_size" [ngClass]="colorEncargo(encargoBySiniestro.upgen_fecha_limite)"></i>
                                    <h3 class="text_inline_icon_encargo">{{encargoBySiniestro.id_plataforma}}</h3>
                                </div>
                                <div>
                                    <i class="bi bi-chat-left-text-fill icon_size"><h3 class="text_inline_icon_mensaje">{{encargoBySiniestro.mensajes_count}} Mensaje</h3></i>
                                </div>
                            </div>
            
                            <div class="col-md-2">
                                <div class="row">
                                    <div class="col-md-8">
                                        <h5 class="m-0 pt-3 subtitulo_h5">Fecha encargo</h5>
                                        <h4 class="subtitulo_h4">{{encargoBySiniestro.upgen_fecha_tarea | date: 'dd/MM/yyyy HH:mm:ss'}}</h4>
                                    </div>
                                    <div class="col-md-2">
                                        <mat-divider [vertical] ="true" class="mt-3" style="height:50px"></mat-divider>
                                    </div>
                                    <div class="col-md-2">
                                    
                                    </div>
                                </div>
                            </div>
                
                            <div class="col-md-2">
                                <h5 class="m-0 pt-3 subtitulo_h5">Estado</h5>
                                <h4 class="subtitulo_h4">{{encargoBySiniestro.upgen_estado_encargo}}</h4>
                            </div>
                
                            <div class="col-md-2">
                                <div class="row">
                                    <div class="col-md-8">
                                        <h5 class="m-0 pt-3 subtitulo_h5">Tipo de encargo</h5>
                                        <h4 class="subtitulo_h4">{{encargoBySiniestro.nombre_tipo_encargo}}</h4>
                                    </div>
                                    <div class="col-md-2">
                                        <mat-divider [vertical] ="true" class="mt-3" style="height:50px"></mat-divider>
                                    </div>
                                    <div class="col-md-2">
                                    
                                    </div>
                                </div>
                            </div>
                
                            <div class="col-md-2">
                                <div class="row">
                                    <div class="col-md-8">
                                        <h5 class="m-0 pt-3 subtitulo_h5">Fecha Limite</h5>
                                        <h4 class="subtitulo_h4">{{encargoBySiniestro.upgen_fecha_limite | date: 'dd/MM/yyyy HH:mm:ss'}}</h4>
                                    </div>
                                    <div class="col-md-2">
                                        <mat-divider [vertical] ="true" class="mt-3" style="height:50px"></mat-divider>
                                    </div>
                                    <div class="col-md-2">
                                    
                                    </div>
                                </div>
                            </div>
                
                            <div class="col-md-2">
                                <h5 class="m-0 pt-3 subtitulo_h5">Lesionado</h5>
                                <h4 class="subtitulo_h4">{{encargoBySiniestro.upgen_lesionado}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

         <!-- FAB -->
         <div *ngIf="mostrarCancel" class="fab_container cancelar">
            <button mat-fab class="fab_datos" color="white" title="Cancelar" aria-label="Cancelar" (click)="cancelarEncargo()">
                <mat-icon style="margin-top: -4px; color: #dc3545;">cancel</mat-icon>
            </button>
        </div>

        <!-- FAB -->
        <div *ngIf="mostrarBorrar" class="fab_container cancelar">
            <button mat-fab class="fab_datos" color="white" title="Borrar" aria-label="Borrar" (click)="borrarEncargo()">
                <mat-icon style="margin-top: -4px; color: #dc3545;">delete</mat-icon>
            </button>
        </div>

        <!-- FAB -->
        <div class="fab_container guardar">
            <button mat-fab class="fab_datos" color="white" title="Guardar" aria-label="Guardar" (click)="guardarDatosEncargo('guardar')">
                <mat-icon style="margin-top: -6px;">save</mat-icon>
            </button>
        </div>

        <!-- FAB -->
        <div class="fab_container fision">
            <button mat-fab class="fab_datos" color="white" title="Enviar Fisión" aria-label="Enviar Fisión" (click)="guardarDatosEncargo('fision')">
                <mat-icon style="margin-top: -6px;">share</mat-icon>
            </button>
        </div>

         <!-- FAB -->
         <div *ngIf="mostrarEnviar" class="fab_container enviar">
            <button mat-fab class="fab_datos" color="white" title="Enviar" aria-label="Enviar" (click)="guardarDatosEncargo('enviar')">
                <mat-icon style="margin-top: -2px;">send</mat-icon>
            </button>
        </div>

        <!-- FAB -->
        <div *ngIf="mostrarReEnviar" class="fab_container enviar">
            <button mat-fab class="fab_datos" color="white" title="Enviar" aria-label="Enviar" (click)="guardarDatosEncargo('reEnviar')">
                <mat-icon style="margin-top: -2px;">replay</mat-icon>
            </button>
        </div>

        <!-- FAB -->
        <div *ngIf="mostrarReEnviarFinalizado" class="fab_container enviar">
            <button mat-fab class="fab_datos" style="background-color: #dc3545;" color="white" title="Pasar a enviado" aria-label="Enviar" (click)="guardarDatosEncargo('reEnviarFinalizado')">
                <mat-icon style="margin-top: -2px; color: white;">replay</mat-icon>
            </button>
        </div>

        <!-- FAB -->
        <div *ngIf="mostrarPendienteRevision" class="fab_container pendienteRevision">
            <button mat-fab class="fab_datos" color="white" title="Pendiente revisión" aria-label="Pendiente revisión" (click)="guardarDatosEncargo('pendienteRevision')">
                <mat-icon style="margin-top: -2px;">send</mat-icon>
            </button>
        </div>

        <!-- FAB -->
        <div *ngIf="mostrarFinalizado" class="fab_container finalizado">
            <button mat-fab class="fab_datos" color="white" title="Finalizado" aria-label="Finalizado" (click)="guardarDatosEncargo('finalizado')">
                <mat-icon style="margin-top: -2px;">send</mat-icon>
            </button>
        </div>

        <!-- Generar informe -->
        <div class="fab_container generarInforme">
            <button mat-fab class="fab_datos" color="white" title="Generar informe" aria-label="Generar informe" (click)="guardarDatosEncargo('informe')">
                <mat-icon style="margin-top: -6px;" style="color: #4b8f76;"><i></i>assignment</mat-icon>
            </button>
        </div>

        <div *ngIf="existeDocMedico" class="fab_container carpeta">
            <button mat-fab class="fab_datos" color="white" title="Descargar carpeta" aria-label="Descargar carpeta" (click)="guardarDatosEncargo('descargarDocMedico')">
                <mat-icon  style="color: #4b8f76;"><i></i>cloud_download</mat-icon>
            </button>
        </div>

    </div>
</div>

<!-- Modal Image -->
<div id="modalFile" class="modal-wrapper">
    <div class="modal">
        <div class="head">
            <a class="btn-close trigger" (click)="openModal('modalFile', null)">
                <mat-icon aria-hidden="true">close</mat-icon>
            </a>
        </div>
        <div class="content">
            <div class="good-job" *ngIf="imageSelect && tipoArchivo=='pdf'">
                <h5>PDF</h5>
                <ngx-extended-pdf-viewer [base64Src]="imageSelect" backgroundColor="#ffffff" [height]="'500px'"
                    [useBrowserLocale]="true" [handTool]="false" [showHandToolButton]="true">
                </ngx-extended-pdf-viewer>
            </div>
            <div class="good-job" *ngIf="imageSelect && tipoArchivo=='imagen'">
                <h5>Imagen</h5>
                <img height="500px" src="{{'data:image/jpg;base64,' + imageSelect}}">
            </div>
        </div>
    </div>
</div>

<div id="meet" style="display: none;">

</div>
