import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Archivo } from '../models/archivo';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class MedicoPortalService {  
  protected basePath = this.sessionService.getBasePath();
  public nombreLesionado : string = "nombre lesionado";

  setNombreLesionado(nombreLesionado: any) {
    this.nombreLesionado = nombreLesionado;
  }

  getNombreLesionado() {
    return this.nombreLesionado;
  }

  constructor(private http: HttpClient, private sessionService: SessionService) { }

  //Funcioness con autenticado
  //Los encargos pendiente de Revision para el medico son los enviados del tramitador
  getEncargosPendientesRevision(noNulo: string){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getEncargosMedicoPendientesRevision', { 'idMedico': this.sessionService.getCurrentSession().user.id, 'noNulo': noNulo}, httpOptions);
  }

  getEncargosFinalizados(noNulo: string){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getEncargosMedicoFinalizados', { 'idMedico': this.sessionService.getCurrentSession().user.id, 'noNulo': noNulo}, httpOptions);
  }

  getEncargosRevisados(noNulo: string){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getEncargosMedicoRevisados', { 'idMedico': this.sessionService.getCurrentSession().user.id, 'noNulo': noNulo}, httpOptions);
  }


  getEncargosBySiniestro(siniestro: string, idUsuario: any, tipoUsuario: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getEncargosBySiniestro', { 'siniestro': siniestro, 'idUsuario': idUsuario, 'tipoUsuario': tipoUsuario}, httpOptions);
  }

  getCountMensajesSinLeerMedicos(){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getCountMensajesSinLeerMedicos', { 'idMedico': this.sessionService.getCurrentSession().user.id}, httpOptions);
  }

  getCountMensajesSinLeerMedicosGenerali(){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getCountMensajesSinLeerMedicosGenerali', { 'idMedico': this.sessionService.getCurrentSession().user.id}, httpOptions);
  }

  getArchivosExploradorMedico(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getArchivosExploradorMedico', datos, httpOptions);
  }

  getArchivosExploradorMedicoGenerali(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getArchivosExploradorMedicoGenerali', datos, httpOptions);
  }

  getArchivosExploradorMedicoPericial(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getArchivosExploradorMedicoPericial', datos, httpOptions);
  }

  uploadFile(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post<Archivo>(this.basePath + 'subirArchivoMedico', datos,httpOptions);
  }

  subirArchivoMedicoPericial(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post<Archivo>(this.basePath + 'subirArchivoMedicoPericial', datos,httpOptions);
  }

  enviarEmailNuevoMensajeEncargo(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'enviarEmailNuevoMensajeEncargo', datos, httpOptions);
  }

  enviarEmailNuevoMensajeEncargoGenerali(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'enviarEmailNuevoMensajeEncargoGenerali', datos, httpOptions);
  }

  enviarEmailCambioEstadoEncargo(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'enviarEmailCambioEstadoEncargo', datos, httpOptions);
  }

  enviarEmailAddDocEncargo(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'enviarEmailAddDocEncargo', datos, httpOptions);
  }

  getFormatoConsulta() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.get(this.basePath + 'getFormatoConsulta', httpOptions);

  }  

  getExpedienteById(id : string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.get(this.basePath + 'getExpedienteById/' + id, httpOptions);

  }  

  getEncargosGeneraliMedico(idEstado : number) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getEncargosGeneraliMedico', { 'idMedico': this.sessionService.getCurrentSession().user.id, 'idEstado': idEstado}, httpOptions);
  }

  /* getEncargosPendientes(idMedico: number){
    return this.http.post(this.basePath + 'getEncargosMedicoPendientes', { 'idMedico': idMedico});
  }

  getEncargosEnviados(idMedico: number){
    return this.http.post(this.basePath + 'getEncargosMedicoEnviados', { 'idMedico': idMedico});
  }

  getEncargosFinalizados(idMedico: number){
    return this.http.post(this.basePath + 'getEncargosMedicoFinalizados', { 'idMedico': idMedico});
  }

  getEncargosRevision(idMedico: number){
    return this.http.post(this.basePath + 'getEncargosMedicoRevision', { 'idMedico': idMedico});
  }

  getEncargosRevisado(idMedico: number){
    return this.http.post(this.basePath + 'getEncargosMedicoRevisado', { 'idMedico': idMedico});
  }

  getEncargosMedicoPendienteEnviar(idMedico: number){
    return this.http.post(this.basePath + 'getEncargosMedicoPendienteEnviar', { 'idMedico': idMedico});
  }

  getMensajesEncargo(idEncargo: number){
    return this.http.post(this.basePath + 'getMensajesEncargo', { 'idEncargo': idEncargo});
  }

  getEncargosBySiniestro(siniestro: string){
    return this.http.post(this.basePath + 'getEncargosBySiniestro', { 'siniestro': siniestro});
  }

  getCountMensajesSinLeer(idMedico: number){
    return this.http.post(this.basePath + 'getCountMensajesSinLeer', { 'idMedico': idMedico});
  } */
}
