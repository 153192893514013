<app-header-facturacion></app-header-facturacion>

<div class="loading vw-100 vh-100 d-flex justify-content-center align-items-center position-absolute" *ngIf="loadingPageFlag">
    <mat-spinner></mat-spinner>
</div>

<div class="min-vh-100" [ngStyle]="{'opacity': loadingPageFlag ? 0 : 1}">
    
    <div class="py-4">
    
        <div class="container">

            <div class="d-flex">
                <div *ngIf="proveedor != undefined">
                    <h2 class="m-0 p-0">Proveedor: {{proveedor.prov_nombre}}</h2>
        
                    <div class="d-flex">
                        <div>
                            <h5 class="m-0 p-0">Dirección: {{proveedor.prov_poblacion}}</h5>
                            <h5 class="m-0 p-0">CIF: {{proveedor.prov_cif}}</h5>
                            <h5 class="m-0 p-0">IRPF: {{proveedor.prov_irpf}}%</h5>
                        </div>
                        <div class="px-3">
                            <h5 class="m-0 p-0">E-Mail: {{proveedor.prov_email}}</h5>
                            <h5 class="m-0 p-0">Teléfono: {{proveedor.prov_telefono}}</h5>  
                            <h5 class="mb-2 p-0">Fax: {{proveedor.prov_fax}}</h5>  
                        </div>
                    </div>
                </div>
            </div>
            
    
            <mat-divider class="mb-3"></mat-divider>
    
            <div class="p-2">
    
                <mat-accordion class="example-headers-align" multi>
                    <mat-expansion-panel class="custom-inputs">
    
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                            <strong>Información de la factura</strong>
                        </mat-panel-title>
                        <mat-panel-description class="justify-content-end">
                          <mat-icon>assignment</mat-icon>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                  
                      <div class="pt-3 border-top row">
    
                        <div class="col-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Id proveedor</mat-label>
                                <input matInput [(ngModel)]="idProveedor" disabled>
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Id factura</mat-label>
                                <input matInput [(ngModel)]="idFactura" disabled>
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Notas</mat-label>
                                <textarea class="custom-textarea" matInput [(ngModel)]="notas"></textarea>
                            </mat-form-field>
                        </div>
                        <div class="col-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>IBAN</mat-label>
                                <input matInput [(ngModel)]="iban" disabled>
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>IRPF(%)</mat-label>
                                <input matInput [(ngModel)]="irpf" disabled>
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Nº factura</mat-label>
                                <input matInput maxlength="10" [(ngModel)]="nFactura">
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Estado</mat-label>
                                <mat-select [(ngModel)]="estado">
                                    <mat-option *ngFor="let estadoInd of estados" [value]="estadoInd.est_id_estados">
                                      {{estadoInd.est_descripcion}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field *ngIf="estado == 'EST-002'" class="w-100" appearance="outline">
                                <mat-label>Registro</mat-label>
                                <input matInput [(ngModel)]="registro">
                            </mat-form-field>
                        </div>
                        <div class="col-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Fecha factura</mat-label>
                                <input matInput [matDatepicker]="fechaFacturaDatePicker" [(ngModel)]="fechaFactura">
                                <mat-datepicker-toggle matSuffix [for]="fechaFacturaDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #fechaFacturaDatePicker></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Fecha pago</mat-label>
                                <input matInput [matDatepicker]="fechaPagoDatePicker" [(ngModel)]="fechaPago">
                                <mat-datepicker-toggle matSuffix [for]="fechaPagoDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #fechaPagoDatePicker></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Fecha recepción</mat-label>
                                <input matInput type="datetime-local" [(ngModel)]="fechaRecepcion" disabled>
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Remesa</mat-label>
                                <input matInput [(ngModel)]="remesa">
                            </mat-form-field>
                        </div>
            
                    </div>
                  
                    </mat-expansion-panel>
    
                    <mat-expansion-panel class="custom-background custom-inputs">
                        
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                              <strong>Información económica</strong>
                          </mat-panel-title>
                          <mat-panel-description class="justify-content-end">
                            <mat-icon>euro_symbol</mat-icon>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                    
                        <div class="pt-3 border-top row">
    
                            <div class="col-4">
                                <div class="info-container px-3 py-2 border-bottom">
                                    <h5 class="m-0">Importe</h5>
                                    <h4 class="m-0">{{importe | number:'1.2-2'}}€</h4>
                                </div>
                                <div class="info-container px-3 py-2 border-bottom">
                                    <h5 class="m-0">Retención</h5>
                                    <h4 class="m-0">{{retencion | number:'1.2-2'}}€</h4>
                                </div>
                                <div class="info-container px-3 py-2 border-bottom">
                                    <h5 class="m-0">IVA(€)</h5>
                                    <h4 class="m-0">{{iva | number:'1.2-2'}}€</h4>
                                </div>
                                <div class="info-container px-3 py-2 border-bottom">
                                    <h5 class="m-0">Prorrata(%)</h5>
                                    <h4 class="m-0">{{prorrata}}%</h4>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="info-container px-3 py-2 border-bottom">
                                    <h5 class="m-0">Importe(21%)</h5>
                                    <h4 class="m-0">{{importe21 | number:'1.2-2'}}€</h4>
                                </div>
                                <div class="info-container px-3 py-2 border-bottom">
                                    <h5 class="m-0">Importe(10%)</h5>
                                    <h4 class="m-0">{{importe10 | number:'1.2-2'}}€</h4>
                                </div>
                                <div class="info-container px-3 py-2 border-bottom">
                                    <h5 class="m-0">Importe(4%)</h5>
                                    <h4 class="m-0">{{importe4 | number:'1.2-2'}}€</h4>
                                </div>
                                <div class="info-container px-3 py-2 border-bottom">
                                    <h5 class="m-0">Importe(prorrata)</h5>
                                    <h4 class="m-0">{{importeProrrata | number:'1.2-2'}}€</h4>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="info-container px-3 py-2 border-bottom">
                                    <h5 class="m-0">IVA(21%)</h5>
                                    <h4 class="m-0">{{iva21 | number:'1.2-2'}}€</h4>
                                </div>
                                <div class="info-container px-3 py-2 border-bottom">
                                    <h5 class="m-0">IVA(10%)</h5>
                                    <h4 class="m-0">{{iva10 | number:'1.2-2'}}€</h4>
                                </div>
                                <div class="info-container px-3 py-2 border-bottom">
                                    <h5 class="m-0">IVA(4%)</h5>
                                    <h4 class="m-0">{{iva4 | number:'1.2-2'}}€</h4>
                                </div>
                                <div class="info-container px-3 py-2 border-bottom">
                                    <h5 class="m-0">IVA(prorrata)</h5>
                                    <h4 class="m-0">{{ivaProrrata | number:'1.2-2'}}€</h4>
                                </div>
                            </div>
                
                            <div class="col-12">
                                <div class="info-container px-3 py-2 border-bottom">
                                    <h5 class="m-0"><strong>Total factura</strong></h5>
                                    <h4 class="m-0">{{totalFactura | number:'1.2-2'}}€</h4>
                                </div>
                                <!-- <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Total factura</mat-label>
                                    <input matInput type="number" [(ngModel)]="totalFactura" disabled>
                                </mat-form-field> -->
                            </div>
                
                        </div>
                    
                      </mat-expansion-panel>

                      <mat-expansion-panel>
                        
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                              <strong>Registros</strong>
                          </mat-panel-title>
                          <mat-panel-description class="justify-content-end">
                            <mat-icon>list</mat-icon>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                    
                        <div class="border-top">

                            <mat-tab-group>
                                <mat-tab label="Autorizaciones">
                                        
                                    <button *ngIf="autorizaciones.length > 0" class="custom-add-button my-2" mat-flat-button color="primary" (click)="addAutorizacionesToProductos()"><mat-icon>add</mat-icon> Añadir a productos de factura</button>

                                    <table *ngIf="autorizaciones.length > 0" mat-table #tableAutorizaciones [dataSource]="dataSourceAutorizaciones" class="w-100 flex-grow-1 border-top">

                                        <ng-container matColumnDef="selectAutorizaciones">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <mat-checkbox (change)="$event ? masterToggleAutorizaciones() : null"
                                                            [checked]="selectionAutorizaciones.hasValue() && isAllSelectedAutorizaciones()"
                                                            [indeterminate]="selectionAutorizaciones.hasValue() && !isAllSelectedAutorizaciones()"
                                                            [aria-label]="checkboxLabelAutorizaciones()">
                                                </mat-checkbox>
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                <mat-checkbox (click)="$event.stopPropagation()"
                                                            (change)="$event ? selectionAutorizaciones.toggle(row) : null"
                                                            [checked]="selectionAutorizaciones.isSelected(row)"
                                                            [aria-label]="checkboxLabelAutorizaciones(row)">
                                                </mat-checkbox>
                                            </td>
                                            </ng-container>

                                        <ng-container matColumnDef="aut_id_autorizaciones">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Id autorizacion </th>
                                            <td class="p-2" mat-cell *matCellDef="let autorizacion"> {{autorizacion.aut_id_autorizaciones}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="aut_fecha_creacion">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha creación </th>
                                            <td class="p-2" mat-cell *matCellDef="let autorizacion"> {{autorizacion.aut_fecha_creacion}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="aut_estado">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                                            <td class="p-2" mat-cell *matCellDef="let autorizacion"> {{autorizacion.aut_estado}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="aut_estado_autorizacion">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Estado autorización </th>
                                            <td class="p-2" mat-cell *matCellDef="let autorizacion"> {{autorizacion.aut_estado_autorizacion}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="prd_iva">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> IVA </th>
                                            <td class="p-2" mat-cell *matCellDef="let autorizacion"> {{autorizacion.producto.prd_iva}}% </td>
                                        </ng-container>

                                        <ng-container matColumnDef="prd_coste_estandar">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Precio </th>
                                            <td class="p-2" mat-cell *matCellDef="let autorizacion"> {{autorizacion.producto.prd_coste_estandar | number:'1.2-2'}}€ </td>
                                        </ng-container>
                
                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsAutorizaciones" class="sticky-top"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsAutorizaciones;" (click)="selectionAutorizaciones.toggle(row)" class="custom-row"></tr>

                                    </table>
                                    
                                    <div *ngIf="autorizaciones.length > 0" class="d-flex">
                                        <div class="flex-grow-1 d-flex align-items-center">
                                            <p class="m-0 px-3"><strong>Total registros: {{dataSourceAutorizaciones.filteredData.length}}</strong></p>
                                        </div>
                                        <div>
                                            <mat-paginator #autorizacionesPaginator [length]="autorizacionesLength" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                                        </div>
                                    </div>

                                    <div *ngIf="autorizaciones.length == 0" class="custom-height-200 d-flex justify-content-center align-items-center">
                                        <h3><strong>No hay autorizaciones asociadas al proveedor</strong></h3>
                                    </div>

                                </mat-tab>
                                <mat-tab label="Citas">

                                    <button *ngIf="citas.length > 0" class="custom-add-button my-2" mat-flat-button color="primary" (click)="addCitasToProductos()"><mat-icon>add</mat-icon> Añadir a productos de factura</button>

                                    <table *ngIf="citas.length > 0" mat-table #tableCitas [dataSource]="dataSourceCitas" class="w-100 flex-grow-1 border-top">

                                        <ng-container matColumnDef="selectCitas">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <mat-checkbox (change)="$event ? masterToggleCitas() : null"
                                                            [checked]="selectionCitas.hasValue() && isAllSelectedCitas()"
                                                            [indeterminate]="selectionCitas.hasValue() && !isAllSelectedCitas()"
                                                            [aria-label]="checkboxLabelCitas()">
                                                </mat-checkbox>
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                <mat-checkbox (click)="$event.stopPropagation()"
                                                            (change)="$event ? selectionCitas.toggle(row) : null"
                                                            [checked]="selectionCitas.isSelected(row)"
                                                            [aria-label]="checkboxLabelCitas(row)">
                                                </mat-checkbox>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="cup_id_citas">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Id cita </th>
                                            <td class="p-2" mat-cell *matCellDef="let cita"> {{cita.cup_id_citas}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="cup_fecha_creacion">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha creación </th>
                                            <td class="p-2" mat-cell *matCellDef="let cita"> {{cita.cup_fecha_creacion}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="cup_estado">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                                            <td class="p-2" mat-cell *matCellDef="let cita"> {{cita.cup_estado}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="prd_iva">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> IVA </th>
                                            <td class="p-2" mat-cell *matCellDef="let cita"> {{cita.cup_iva}}% </td>
                                        </ng-container>

                                        <ng-container matColumnDef="prd_coste_estandar">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Precio </th>
                                            <td class="p-2" mat-cell *matCellDef="let cita"> {{cita.cup_coste_real | number:'1.2-2'}}€ </td>
                                        </ng-container>
                
                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsCitas" class="sticky-top"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsCitas;" (click)="selectionCitas.toggle(row)" class="custom-row"></tr>

                                    </table>
                                    
                                    <div *ngIf="citas.length > 0" class="d-flex">
                                        <div class="flex-grow-1 d-flex align-items-center">
                                            <p class="m-0 px-3"><strong>Total registros: {{dataSourceCitas.filteredData.length}}</strong></p>
                                        </div>
                                        <div>
                                            <mat-paginator #citasPaginator [length]="citasLength" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                                        </div>
                                    </div>

                                    <div *ngIf="citas.length == 0" class="custom-height-200 d-flex justify-content-center align-items-center">
                                        <h3><strong>No hay citas asociadas al proveedor</strong></h3>
                                    </div>

                                </mat-tab>
                                <mat-tab label="Plantillas de productos">
                                        
                                    <button *ngIf="plantillasProd.length > 0" class="custom-add-button my-2" mat-flat-button color="primary" (click)="addPlantillasProductosToProductos()"><mat-icon>add</mat-icon> Añadir a productos de factura</button>

                                    <table *ngIf="plantillasProd.length > 0" mat-table #tablePlantillasProd [dataSource]="dataSourcePlantillasProd" class="w-100 flex-grow-1 border-top">

                                        <ng-container matColumnDef="selectPlantillasProd">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <mat-checkbox (change)="$event ? masterTogglePlantillasProd() : null"
                                                            [checked]="selectionPlantillasProd.hasValue() && isAllSelectedPlantillasProd()"
                                                            [indeterminate]="selectionPlantillasProd.hasValue() && !isAllSelectedPlantillasProd()"
                                                            [aria-label]="checkboxLabelPlantillasProd()">
                                                </mat-checkbox>
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                <mat-checkbox (click)="$event.stopPropagation()"
                                                            (change)="$event ? selectionPlantillasProd.toggle(row) : null"
                                                            [checked]="selectionPlantillasProd.isSelected(row)"
                                                            [aria-label]="checkboxLabelPlantillasProd(row)">
                                                </mat-checkbox>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="pp_id_plantilla_productos">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Id plantilla producto </th>
                                            <td class="p-2" mat-cell *matCellDef="let plantillaProd"> {{plantillaProd.pp_id_plantilla_productos}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="pp_fecha_creacion">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha creación </th>
                                            <td class="p-2" mat-cell *matCellDef="let plantillaProd"> {{plantillaProd.pp_fecha_creacion}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="pp_estado">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                                            <td class="p-2" mat-cell *matCellDef="let plantillaProd"> {{plantillaProd.pp_estado}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="pp_iva">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> IVA </th>
                                            <td class="p-2" mat-cell *matCellDef="let plantillaProd"> {{plantillaProd.pp_iva}}% </td>
                                        </ng-container>

                                        <ng-container matColumnDef="pp_precio_venta">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Precio </th>
                                            <td class="p-2" mat-cell *matCellDef="let plantillaProd"> {{plantillaProd.pp_precio_venta | number:'1.2-2'}}€ </td>
                                        </ng-container>
                
                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsPlantillasProd" class="sticky-top"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsPlantillasProd;" (click)="selectionPlantillasProd.toggle(row)" class="custom-row"></tr>

                                    </table>
                                    
                                    <div *ngIf="plantillasProd.length > 0" class="d-flex">
                                        <div class="flex-grow-1 d-flex align-items-center">
                                            <p class="m-0 px-3"><strong>Total registros: {{dataSourcePlantillasProd.filteredData.length}}</strong></p>
                                        </div>
                                        <div>
                                            <mat-paginator #plantillasProdPaginator [length]="plantillasProdLength" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                                        </div>
                                    </div>

                                    <div *ngIf="plantillasProd.length == 0" class="custom-height-200 d-flex justify-content-center align-items-center">
                                        <h3><strong>No hay plantillas de productos asociadas al proveedor</strong></h3>
                                    </div>

                                </mat-tab>
                                <mat-tab label="Productos de factura">
                                    <table *ngIf="productosFacturaRecibida.length > 0" mat-table #tableProductosFactura [dataSource]="dataSourceProductosFactura" class="w-100">

                                        <ng-container matColumnDef="factrp_producto">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Concepto </th>
                                            <td class="p-2" mat-cell *matCellDef="let productoFact"> {{productoFact.factrp_producto}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="factrp_tipo">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
                                            <td class="p-2" mat-cell *matCellDef="let productoFact"> {{productoFact.factrp_tipo}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="factrp_base_imponible">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Base imponible </th>
                                            <td class="p-2" mat-cell *matCellDef="let productoFact"> {{productoFact.factrp_base_imponible | number:'1.2-2'}}€ </td>
                                        </ng-container>

                                        <ng-container matColumnDef="factrp_iva">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> IVA </th>
                                            <td class="p-2" mat-cell *matCellDef="let productoFact"> {{productoFact.factrp_iva}}% </td>
                                        </ng-container>

                                        <ng-container matColumnDef="factrp_total_iva">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Total IVA </th>
                                            <td class="p-2" mat-cell *matCellDef="let productoFact"> {{productoFact.factrp_total_iva | number:'1.2-2'}}€ </td>
                                        </ng-container>

                                        <ng-container matColumnDef="factrp_total_retencion">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Total retención </th>
                                            <td class="p-2" mat-cell *matCellDef="let productoFact"> {{productoFact.factrp_total_retencion | number:'1.2-2'}}€ </td>
                                        </ng-container>

                                        <ng-container matColumnDef="factrp_total_factura">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Total factura </th>
                                            <td class="p-2" mat-cell *matCellDef="let productoFact"> {{productoFact.factrp_total_factura | number:'1.2-2'}}€ </td>
                                        </ng-container>

                                        <ng-container matColumnDef="delete_button">
                                            <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Acción </th>
                                            <td class="p-2" mat-cell *matCellDef="let productoFact"> 
                                                <button mat-flat-button color="warn" (click)="deleteProductoFactura(productoFact)">Eliminar</button>
                                            </td>
                                        </ng-container>
                
                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsProductosFactura" class="sticky-top"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsProductosFactura;"></tr>

                                    </table>

                                    <div *ngIf="productosFacturaRecibida.length > 0" class="d-flex">
                                        <div class="flex-grow-1 d-flex align-items-center">
                                            <p class="m-0 px-3"><strong>Total registros: {{dataSourceProductosFactura.filteredData.length}}</strong></p>
                                        </div>
                                        <div>
                                            <mat-paginator #productosFacturaPaginator [length]="productosFacturaLength" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                                        </div>
                                    </div>

                                    <div *ngIf="productosFacturaRecibida.length == 0" class="custom-height-200 d-flex justify-content-center align-items-center">
                                        <h3><strong>No hay productos añadidos a esta factura</strong></h3>
                                    </div>
                                </mat-tab>
                                <mat-tab label="Otros gastos">
                                    <div *ngIf="otrosGastos.length > 0">

                                        <button class="custom-add-button my-2" mat-flat-button color="primary" (click)="crearOtroGasto()"><mat-icon>add</mat-icon> Incluir otro gasto</button>

                                        <table mat-table #tableOtrosGastos [dataSource]="dataSourceOtrosGastos" class="w-100 border-top">

                                            <ng-container matColumnDef="factrp_concepto">
                                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Concepto </th>
                                                <td class="p-2" mat-cell *matCellDef="let otroGasto"> {{otroGasto.factrp_concepto}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="factrp_base_imponible">
                                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Base imponible </th>
                                                <td class="p-2" mat-cell *matCellDef="let otroGasto"> {{otroGasto.factrp_base_imponible | number:'1.2-2'}}€ </td>
                                            </ng-container>

                                            <ng-container matColumnDef="factrp_irpf">
                                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> IRPF </th>
                                                <td class="p-2" mat-cell *matCellDef="let otroGasto"> {{otroGasto.factrp_irpf}}% </td>
                                            </ng-container>

                                            <ng-container matColumnDef="factrp_iva">
                                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> IVA </th>
                                                <td class="p-2" mat-cell *matCellDef="let otroGasto"> {{otroGasto.factrp_iva}}% </td>
                                            </ng-container>

                                            <ng-container matColumnDef="factrp_total_iva">
                                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Total IVA </th>
                                                <td class="p-2" mat-cell *matCellDef="let otroGasto"> {{otroGasto.factrp_total_iva | number:'1.2-2'}}€ </td>
                                            </ng-container>
                                            
                                            <ng-container matColumnDef="factrp_total_factura">
                                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Total factura </th>
                                                <td class="p-2" mat-cell *matCellDef="let otroGasto"> {{otroGasto.factrp_total_factura | number:'1.2-2'}}€ </td>
                                            </ng-container>

                                            <ng-container matColumnDef="delete_button">
                                                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Acción </th>
                                                <td class="p-2" mat-cell *matCellDef="let otroGasto"> 
                                                    <button mat-flat-button color="warn" (click)="deleteOtroGasto(otroGasto)">Eliminar</button>
                                                </td>
                                            </ng-container>
                    
                                            <tr mat-header-row *matHeaderRowDef="displayedColumnsOtrosGastos" class="sticky-top"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumnsOtrosGastos;"></tr>
    
                                        </table>

                                        <div class="d-flex">
                                            <div class="flex-grow-1 d-flex align-items-center">
                                                <p class="m-0 px-3"><strong>Total registros: {{dataSourceOtrosGastos.filteredData.length}}</strong></p>
                                            </div>
                                            <div>
                                                <mat-paginator #otrosGastosPaginator [length]="otrosGastosLength" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                                            </div>
                                        </div>

                                    </div>

                                    <div *ngIf="otrosGastos.length == 0" class="custom-height-200 d-flex flex-column justify-content-center align-items-center">
                                        <h3><strong>No hay otros gastos añadidos a esta factura</strong></h3>
                                        <button class="custom-add-button" mat-flat-button color="primary" (click)="crearOtroGasto()"><mat-icon>add</mat-icon> Incluir otro gasto</button>
                                    </div>
                                </mat-tab>
                                <mat-tab label="Comentarios"> 
                                    <div class="custom-height-600 d-flex flex-column">
                                        <div #containerComentario class="flex-grow-1 overflow-auto" [scrollTop]="containerComentario.scrollHeight">
                                            <div *ngFor="let comentario of comentarios" [ngClass]="{'custom-message-user': (checkIfIsUser(comentario)) , 'custom-message-another-user': (!checkIfIsUser(comentario)) }">
                                                <h3 class="m-0"><strong>{{comentario.cfact_nombre}}</strong></h3>
                                                <h6 class="mb-1">{{comentario.cfact_fecha_creacion}}</h6>
                                                <mat-divider class="my-1"></mat-divider>
                                                <p class="m-0 custom-format">{{comentario.cfact_text}}</p>
                                            </div>
                                        </div>
                                        <mat-divider class="my-2"></mat-divider>
                                        <div>
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Nota</mat-label>
                                                <textarea #message matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" class="overflow-hidden" (keyup.enter)="sendMessage(message.value); message.value = ''"></textarea>
                                                <button mat-icon-button matSuffix (click)="sendMessage(message.value); message.value = ''">
                                                    <mat-icon>send</mat-icon>
                                                </button>
                                                <mat-hint>Shift + Enter para salto de linea</mat-hint>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>
                
                        </div>
                    
                      </mat-expansion-panel>
                  
                </mat-accordion>

                <div class="mt-3 row justify-content-center">
                    <div class="col-2">
                        <button class="w-100" mat-flat-button color="primary" [matBadge]="incidencias[0].length" matBadgeColor="warn" (click)="verIncidenciasPago()">Ver incidencias</button>
                    </div>
                    <div class="col-2">
                        <button class="w-100" mat-flat-button color="primary" (click)="crearIncidenciaPago()">Incidencia pago</button>
                    </div>
                    <div class="col-2">
                        <button class="w-100" mat-flat-button color="primary">Ver factura</button>
                    </div>
                    <div class="col-2">
                        <button class="w-100" mat-flat-button color="primary" (click)="actualizarFacturaRecibida()">Guardar</button>
                    </div>
                    <div class="col-2">
                        <button class="w-100" mat-flat-button color="warn" (click)="cancelarFacturaRecibida()">Cancelar</button>
                    </div>
                </div>
    
            </div>
    
        </div>
    
    </div>    

</div>