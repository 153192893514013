<app-header-facturacion></app-header-facturacion>

<div class="w-100 container-body m-0 row border-top">

    <div class="h-100 m-0 p-0 col-3 d-flex flex-column">

        <div class="h-100">
            <mat-tab-group mat-align-tabs="center" class="h-100 custom-input-margin custom-background">
                <mat-tab class="h-100" label="Datos Personales" *ngIf="proveedor != null">

                    <div class="h-100 p-3 d-flex flex-column">
                        <div class="info-container px-3 py-2 border-bottom">
                            <h5 class="m-0">CIF/NIF</h5>
                            <h4 class="m-0">{{(CIFNIF !== null) ? CIFNIF : "--"}}</h4>
                        </div>
                        <div class="info-container px-3 py-2 border-bottom">
                            <h5 class="m-0">Nombre</h5>
                            <h4 class="m-0">{{(nombre !== null) ? nombre : "--"}}</h4>
                        </div>
                        <div class="info-container px-3 py-2 border-bottom">
                            <h5 class="m-0">Domicilio</h5>
                            <h4 class="m-0">{{(domicilio !== null) ? domicilio : "--"}}</h4>
                        </div>
                        <div class="info-container px-3 py-2 border-bottom">
                            <h5 class="m-0">Población</h5>
                            <h4 class="m-0">{{(poblacion !== null) ? poblacion : "--"}}</h4>
                        </div>
                        <div class="info-container px-3 py-2 border-bottom">
                            <h5 class="m-0">Provincia</h5>
                            <h4 class="m-0">{{(provincia !== null) ? provincia : "--"}}</h4>
                        </div>
                        <div class="info-container px-3 py-2 border-bottom">
                            <h5 class="m-0">Código Postal</h5>
                            <h4 class="m-0">{{(codigoPostal !== null) ? codigoPostal : "--"}}</h4>
                        </div>
                        <div class="info-container px-3 py-2 border-bottom">
                            <h5 class="m-0">Teléfono</h5>
                            <h4 class="m-0">{{(telefono !== null) ? telefono : "--"}}</h4>
                        </div>
                        <div class="info-container px-3 py-2 border-bottom">
                            <h5 class="m-0">Móvil</h5>
                            <h4 class="m-0">{{(movil !== null) ? movil : "--"}}</h4>
                        </div>
                        <div class="info-container px-3 py-2 border-bottom">
                            <h5 class="m-0">Fax</h5>
                            <h4 class="m-0">{{(fax !== null) ? fax : "--"}}</h4>
                        </div>
                        <div class="info-container px-3 py-2">
                            <h5 class="m-0">E-Mail</h5>
                            <h4 class="m-0">{{(eMail !== null) ? eMail : "--"}}</h4>
                        </div>
                    </div>

                </mat-tab>
                <mat-tab class="h-100" label="Datos Económicos" *ngIf="proveedor != null">
                    <div class="h-100 p-3 d-flex flex-column">
                        <div class="info-container px-3 py-2 border-bottom">
                            <h5 class="m-0">Nº Cuenta</h5>
                            <h4 class="m-0">{{(nCuenta !== null) ? nCuenta : "--"}}</h4>
                        </div>
                        <div class="info-container px-3 py-2 border-bottom">
                            <h5 class="m-0">Retención</h5>
                            <h4 class="m-0">{{(retencion !== null) ? retencion : "--"}}</h4>
                        </div>
                        <div class="info-container px-3 py-2 border-bottom">
                            <h5 class="m-0">Código IAE</h5>
                            <h4 class="m-0">{{(codigoIAE !== null) ? codigoIAE : "--"}}</h4>
                        </div>
                        <div class="info-container px-3 py-2 border-bottom">
                            <h5 class="m-0">Contrato</h5>
                            <h4 class="m-0">{{(contrato !== null) ? contrato : "--"}}</h4>
                        </div>
                        <div class="info-container px-3 py-2 border-bottom">
                            <h5 class="m-0">036/037</h5>
                            <h4 class="m-0">{{(cero3637 !== null) ? cero3637 : "--"}}</h4>
                        </div>
                        <div class="info-container px-3 py-2 border-bottom">
                            <h5 class="m-0">Periodo pago</h5>
                            <h4 class="m-0">{{(periodoPago !== null) ? periodoPago : "--"}}</h4>
                        </div>
                        <div class="info-container px-3 py-2 border-bottom">
                            <h5 class="m-0">Inicio actividad</h5>
                            <h4 class="m-0">{{(inicioActividad !== null) ? inicioActividad : "--"}}</h4>
                        </div>
                        <div class="px-3 py-2">
                            <mat-checkbox class="custom-checkbox" [(ngModel)]="checkedPago">
                                Pago por centro
                            </mat-checkbox>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>

    </div>

    <div class="h-100 m-0 p-0 col-9 custom-border">
        <div class="w-100 h-100">
            <mat-tab-group mat-align-tabs="center" class="h-100">
                <mat-tab class="h-100" label="Centros asignados">
    
                    <div *ngIf="centrosAsignados.length != 0" class="h-100 d-flex flex-column">
    
                        <div class="flex-grow-1 px-3 overflow-auto">
                            <table mat-table #tableCentros [dataSource]="dataSourceCentros" class="w-100">
    
                                <ng-container matColumnDef="cen_id_centros">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Id centro </th>
                                    <td class="p-2" mat-cell *matCellDef="let centro"> {{centro.cen_id_centros}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="cen_fecha_creacion">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha creación </th>
                                    <td class="p-2" mat-cell *matCellDef="let centro"> {{centro.cen_fecha_creacion}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="cen_fecha_modificacion">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha modificación </th>
                                    <td class="p-2" mat-cell *matCellDef="let centro"> {{centro.cen_fecha_modificacion}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="cen_estado">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                                    <td class="p-2" mat-cell *matCellDef="let centro"> {{centro.cen_estado}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="cen_nombre">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                    <td class="p-2" mat-cell *matCellDef="let centro"> {{centro.cen_nombre}} </td>
                                </ng-container>
    
                                <tr mat-header-row *matHeaderRowDef="displayedColumnsCentros" class="sticky-top"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsCentros;"></tr>
                            </table>
                        </div>
    
                        <mat-paginator #centrosPaginator [length]="centrosLength" [pageSizeOptions]="[10, 25, 100]" class="border-top"></mat-paginator>
    
                    </div>

                    <div *ngIf="centrosAsignados.length == 0" class="py-5 d-flex flex-column justify-content-center align-items-center">
                        <h3><strong>No hay centros asignados a este proveedor</strong></h3>
                    </div>
    
                </mat-tab>
                <mat-tab class="h-100" label="Médicos asignados">
                    <div *ngIf="medicosAsignados.length != 0" class="h-100 d-flex flex-column">
    
                        <div class="flex-grow-1 px-3 overflow-auto">
                            <table mat-table #tableMedicos [dataSource]="dataSourceMedicos" class="w-100">
    
                                <ng-container matColumnDef="med_id_medicos">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Id médico </th>
                                    <td class="p-2" mat-cell *matCellDef="let medico"> {{medico.med_id_medicos}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="med_fecha_creacion">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha creación </th>
                                    <td class="p-2" mat-cell *matCellDef="let medico"> {{medico.med_fecha_creacion}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="med_fecha_modificacion">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha modificación </th>
                                    <td class="p-2" mat-cell *matCellDef="let medico"> {{medico.med_fecha_modificacion}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="med_estado">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                                    <td class="p-2" mat-cell *matCellDef="let medico"> {{medico.med_estado}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="med_nombre">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                    <td class="p-2" mat-cell *matCellDef="let medico"> {{medico.med_nombre}} </td>
                                </ng-container>
    
                                <tr mat-header-row *matHeaderRowDef="displayedColumnsMedicos" class="sticky-top"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsMedicos;"></tr>
                            </table>
                        </div>
    
                        <mat-paginator #medicosPaginator [length]="medicosLength" [pageSizeOptions]="[10, 25, 100]" class="border-top"></mat-paginator>
    
                    </div>

                    <div *ngIf="medicosAsignados.length == 0" class="py-5 d-flex flex-column justify-content-center align-items-center">
                        <h3><strong>No hay médicos asignados a este proveedor</strong></h3>
                    </div>

                </mat-tab>
                <mat-tab class="h-100" label="Facturas recibidas">
                    <div *ngIf="facturasRecibidas.length != 0" class="h-100 w-100 d-flex flex-column">
    
                        <div class="flex-grow-1 px-3 overflow-auto">
                            <table mat-table #tableFacturasRecibidas [dataSource]="dataSourceFacturasRecibidas" class="w-100">
    
                                <ng-container matColumnDef="factr_id_facturas_recibidas">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Id Factura Recibida </th>
                                    <td class="p-2" mat-cell *matCellDef="let facturasRecibidas"> {{facturasRecibidas.factr_id_facturas_recibidas}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="factr_fecha_creacion">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha creación </th>
                                    <td class="p-2" mat-cell *matCellDef="let facturasRecibidas"> {{facturasRecibidas.factr_fecha_creacion}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="factr_fecha_modificacion">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha modificación </th>
                                    <td class="p-2" mat-cell *matCellDef="let facturasRecibidas"> {{facturasRecibidas.factr_fecha_modificacion}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="factr_estado">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                                    <td class="p-2" mat-cell *matCellDef="let facturasRecibidas"> {{facturasRecibidas.factr_estado}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="factr_numero_factura">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Nº factura </th>
                                    <td class="p-2" mat-cell *matCellDef="let facturasRecibidas"> {{facturasRecibidas.factr_numero_factura}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="factr_fecha_pago">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha pago </th>
                                    <td class="p-2" mat-cell *matCellDef="let facturasRecibidas"> {{facturasRecibidas.factr_fecha_pago}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="factr_base_imponible">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Base imponible </th>
                                    <td class="p-2" mat-cell *matCellDef="let facturasRecibidas"> {{facturasRecibidas.factr_base_imponible}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="factr_total_iva">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Total IVA </th>
                                    <td class="p-2" mat-cell *matCellDef="let facturasRecibidas"> {{facturasRecibidas.factr_total_iva}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="factr_total_retencion">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Total retención </th>
                                    <td class="p-2" mat-cell *matCellDef="let facturasRecibidas"> {{facturasRecibidas.factr_total_retencion}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="factr_total_factura">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Total factura </th>
                                    <td class="p-2" mat-cell *matCellDef="let facturasRecibidas"> {{facturasRecibidas.factr_total_factura}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="factr_estado_factura">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Estado factura </th>
                                    <td class="p-2" mat-cell *matCellDef="let facturasRecibidas"> {{facturasRecibidas.factr_estado_factura}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="factr_pago">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Pago </th>
                                    <td class="p-2" mat-cell *matCellDef="let facturasRecibidas"> {{facturasRecibidas.factr_pago}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="factr_contabilidad">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Contabilidad </th>
                                    <td class="p-2" mat-cell *matCellDef="let facturasRecibidas"> {{facturasRecibidas.factr_contabilidad}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="factr_fecha_prevision_de_pago">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Previsión de pago </th>
                                    <td class="p-2" mat-cell *matCellDef="let facturasRecibidas"> {{facturasRecibidas.factr_fecha_prevision_de_pago}} </td>
                                </ng-container>
    
                                <tr mat-header-row *matHeaderRowDef="displayedColumnsFacturasRecibidas" class="sticky-top"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsFacturasRecibidas;"></tr>
                            </table>
                        </div>
    
                        <mat-paginator #facturasRecibidasPaginator [length]="facturasRecibidasPaginator" [pageSizeOptions]="[10, 25, 100]" class="border-top"></mat-paginator>
    
                    </div>

                    <div *ngIf="facturasRecibidas.length == 0" class="py-5 d-flex flex-column justify-content-center align-items-center">
                        <h3><strong>No hay facturas recibidas asociadas a este proveedor</strong></h3>
                    </div>

                </mat-tab>
                <mat-tab class="h-100" label="Pagos">
                    <div *ngIf="pagos.length != 0" class="h-100 d-flex flex-column">
    
                        <div class="flex-grow-1 px-3 overflow-auto">
                            <table mat-table #tablePagos [dataSource]="dataSourcePagos" class="w-100">
    
                                <ng-container matColumnDef="pag_id_pagos">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Id pagos </th>
                                    <td class="p-2" mat-cell *matCellDef="let pago"> {{pago.pag_id_pagos}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="pag_fecha_creacion">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha creación </th>
                                    <td class="p-2" mat-cell *matCellDef="let pago"> {{pago.pag_fecha_creacion}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="pag_fecha_modificacion">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha modificación </th>
                                    <td class="p-2" mat-cell *matCellDef="let pago"> {{pago.pag_fecha_modificacion}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="pag_estado">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                                    <td class="p-2" mat-cell *matCellDef="let pago"> {{pago.pag_estado}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="pag_remesa">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Remesa </th>
                                    <td class="p-2" mat-cell *matCellDef="let pago"> {{pago.pag_remesa}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="pag_concepto">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Concepto </th>
                                    <td class="p-2" mat-cell *matCellDef="let pago"> {{pago.pag_concepto}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="pag_iban_cuenta">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> IBAN </th>
                                    <td class="p-2" mat-cell *matCellDef="let pago"> {{pago.pag_iban_cuenta}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="pag_total_pagado">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Total pagado </th>
                                    <td class="p-2" mat-cell *matCellDef="let pago"> {{pago.pag_total_pagado | number:'1.2-2'}}€ </td>
                                </ng-container>
    
                                <tr mat-header-row *matHeaderRowDef="displayedColumnsPagos" class="sticky-top"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsPagos;"></tr>
                            </table>
                        </div>
    
                        <mat-paginator #pagosPaginator [length]="pagosLength" [pageSizeOptions]="[10, 25, 100]" class="border-top"></mat-paginator>
    
                    </div>

                    <div *ngIf="pagos.length == 0" class="py-5 d-flex flex-column justify-content-center align-items-center">
                        <h3><strong>No hay pagos asociados a este proveedor</strong></h3>
                    </div>

                </mat-tab>
                <mat-tab class="h-100" label="Incidencias normales">
                    <div *ngIf="incidenciasNormales.length != 0" class="h-100 d-flex flex-column">
    
                        <div class="flex-grow-1 px-3 overflow-auto">
                            <table mat-table #tableIncidenciasNormales [dataSource]="dataSourceIncidenciasNormales" class="w-100">
    
                                <ng-container matColumnDef="inc_id_incidencias">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Id incidencia normal </th>
                                    <td class="p-2" mat-cell *matCellDef="let incidenciaNormal" (click)="modificarIncidencia(incidenciaNormal)"> {{incidenciaNormal.incidencia.inc_id_incidencias}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="inc_fecha_creacion">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha creación </th>
                                    <td class="p-2" mat-cell *matCellDef="let incidenciaNormal" (click)="modificarIncidencia(incidenciaNormal)"> {{incidenciaNormal.incidencia.inc_fecha_creacion}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="inc_usuario_creacion">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Usuario creador </th>
                                    <td class="p-2" mat-cell *matCellDef="let incidenciaNormal" (click)="modificarIncidencia(incidenciaNormal)"> {{obtenerNombreUsuario(incidenciaNormal.incidencia.inc_usuario_creacion)}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="inc_estado">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                                    <td class="p-2" mat-cell *matCellDef="let incidenciaNormal" (click)="modificarIncidencia(incidenciaNormal)"> {{incidenciaNormal.incidencia.inc_estado}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="inc_tipo">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
                                    <td class="p-2" mat-cell *matCellDef="let incidenciaNormal" (click)="modificarIncidencia(incidenciaNormal)"> {{incidenciaNormal.incidencia.inc_tipo}} </td>
                                </ng-container>

                                <ng-container matColumnDef="inc_usuario_asignado">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Usuario asignado </th>
                                    <td class="p-2" mat-cell *matCellDef="let incidenciaNormal" (click)="modificarIncidencia(incidenciaNormal)"> {{obtenerNombreUsuario(incidenciaNormal.incidencia.inc_usuario_asignado)}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="incn_motivo">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Motivo </th>
                                    <td class="p-2" mat-cell *matCellDef="let incidenciaNormal" (click)="modificarIncidencia(incidenciaNormal)"> {{obtenerNombreMotivo(incidenciaNormal)}} </td>
                                </ng-container>

                                <ng-container matColumnDef="incn_alarma">
                                    <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Alarma </th>
                                    <td class="p-2" mat-cell *matCellDef="let incidenciaNormal">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <mat-icon *ngIf="incidenciaNormal.incn_alarma == 1">check</mat-icon>
                                            <mat-icon *ngIf="incidenciaNormal.incn_alarma == 0">close</mat-icon>
                                        </div>
                                    </td>
                                </ng-container>
    
                                <tr mat-header-row *matHeaderRowDef="displayedColumnsIncidenciasNormales" class="sticky-top"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsIncidenciasNormales;" class="custom-row"></tr>
                            </table>
                        </div>
    
                        <mat-paginator #incidenciasNormalesPaginator [length]="incidenciasNormalesLength" [pageSizeOptions]="[10, 25, 100]" class="border-top"></mat-paginator>
    
                    </div>

                    <div *ngIf="incidenciasNormales.length == 0" class="py-5 d-flex flex-column justify-content-center align-items-center">
                        <h3><strong>No hay incidencias asociados a este proveedor</strong></h3>
                    </div>

                </mat-tab>
            </mat-tab-group>
        </div>
    </div>

</div>
