import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Archivo } from '../models/archivo';
import { Encargo } from '../models/encargo';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class TramitadorPortalService {

  protected basePath = this.sessionService.getBasePath();

  constructor(private http: HttpClient, private sessionService: SessionService) { }

  //Funcioness con autenticado
  getExpediente(numExpediente: string){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getExpediente', { 'numExpediente': numExpediente}, httpOptions);
  }

  crearTareaEncargo(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'crearTareaEncargo', datos, httpOptions);
  }

  actualizarTareaEncargo(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'actualizarTareaEncargo', datos, httpOptions);
  }

  getDatosNuevaTarea(){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getDatosNuevaTarea', null, httpOptions);
  }

  getCountMensajesSinLeerTramitador(){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getCountMensajesSinLeerTramitador', { 'idTramitador': this.sessionService.getCurrentSession().user.id}, httpOptions);
  }

  getCountMensajesSinLeerTramitadorGenerali(){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getCountMensajesSinLeerTramitadorGenerali', { 'idTramitador': this.sessionService.getCurrentSession().user.id}, httpOptions);
  }

  getEncargosTramitacion(noNulo: string){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getEncargosTramitadorTramitacion', { 'idTramitador': this.sessionService.getCurrentSession().user.id, 'noNulo': noNulo}, httpOptions);
  }

  getEncargosEnviados(noNulo: string){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getEncargosTramitadorEnviados', { 'idTramitador': this.sessionService.getCurrentSession().user.id, 'noNulo': noNulo}, httpOptions);
  }

  /*getEncargosFinalizados(noNulo: string, pagina: number){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getEncargosTramitadorFinalizados?page=' + pagina.toString, { 'idTramitador': this.sessionService.getCurrentSession().user.id, 'noNulo': noNulo}, httpOptions);
  }*/

  getEncargosFinalizados(noNulo: string){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getEncargosTramitadorFinalizados' , { 'idTramitador': this.sessionService.getCurrentSession().user.id, 'noNulo': noNulo}, httpOptions);
  }

  getEncargosPendienteRevision(noNulo: string){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getEncargosTramitadorPendienteRevision', { 'idTramitador': this.sessionService.getCurrentSession().user.id, 'noNulo': noNulo}, httpOptions);
  }

  getEncargosRevisado(noNulo: string){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getEncargosTramitadorRevisado', { 'idTramitador': this.sessionService.getCurrentSession().user.id, 'noNulo': noNulo}, httpOptions);
  }

  getEncargosPendienteEnviar(noNulo: string){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getEncargosTramitadorPendienteEnviar', { 'idTramitador': this.sessionService.getCurrentSession().user.id, 'noNulo': noNulo}, httpOptions);
  }

  getArchivosExploradorTramitador(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getArchivosExploradorTramitador', datos, httpOptions);
  }

  getArchivosExploradorTramitadorGenerali(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getArchivosExploradorTramitadorGenerali', datos, httpOptions);
  }

  getArchivosExploradorTramitadorPericial(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getArchivosExploradorTramitadorPericial', datos, httpOptions);
  }

  enviarEmailEncargoUrgente(datos: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'enviarEmailEncargoUrgente', datos, httpOptions);
  }

  getFormatoConsulta() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.get(this.basePath + 'getFormatoConsulta', httpOptions);

  }  

  getFormatoConsultaById(id : number) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.get(this.basePath + 'getFormatoConsultaById/' + id, httpOptions);

  }  

  getExpedienteById(id : string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.get(this.basePath + 'getExpedienteById/' + id, httpOptions);

  } 

  getExpedienteByIdGenerali(id : string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.get(this.basePath + 'getExpedienteById/' + id, httpOptions);

  } 

  getExpedienteByIdPericial(id : string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.get(this.basePath + 'getExpedienteById/' + id, httpOptions);

  } 

  getTramitadores() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.get(this.basePath + 'getTramitadores', httpOptions);
  }

  getEncargosGeneraliTramitador(idEstado : number) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getEncargosGeneraliTramitador', { 'idTramitador': this.sessionService.getCurrentSession().user.id, 'idEstado': idEstado}, httpOptions);
  }

  getEncargosPericialTramitador(idEstado : number) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getEncargosPericialTramitador', { 'idTramitador': this.sessionService.getCurrentSession().user.id, 'idEstado': idEstado}, httpOptions);
  }

  getEncargosPericialMedico(idEstado : number) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getEncargosPericialMedico', { 'idMedico': this.sessionService.getCurrentSession().user.id, 'idEstado': idEstado}, httpOptions);
  }

  getEncargosMedicoMensajes(idEstado : number) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getEncargosMedicoMensajes', { 'idMedico': this.sessionService.getCurrentSession().user.id, 'idEstado': idEstado}, httpOptions);
  }

  getMedicoEncargo(id : number) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getMedicoEncargo', { 'id' : id}, httpOptions);
  }

  getCias() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.get(this.basePath + 'getCias', httpOptions);
  }

  getMedicoByName(nombreMedico : string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'getMedicoByName', { 'nombreMedico' : nombreMedico}, httpOptions);
  }

  ExportExcelToArray(refSiniestro : string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.post(this.basePath + 'ExportExcelToArray', { 'refSiniestro' : refSiniestro}, httpOptions);
  }
  

  /* getExpediente(numExpediente: string){
    return this.http.post(this.basePath + 'getExpediente', { 'numExpediente': numExpediente});
  }

  crearTareaEncargo(datos: any){
    return this.http.post(this.basePath + 'crearTareaEncargo', datos);
  }

  getDatosNuevaTarea(){
    return this.http.post(this.basePath + 'getDatosNuevaTarea', null);
  }

  getEncargosPendientes(idTramitador: number){
    return this.http.post(this.basePath + 'getEncargosTramitadorPendientes', { 'idTramitador': idTramitador});
  }

  getEncargosEnviados(idTramitador: number){
    return this.http.post(this.basePath + 'getEncargosTramitadorEnviados', { 'idTramitador': idTramitador});
  }

  getEncargosFinalizados(idTramitador: number){
    return this.http.post(this.basePath + 'getEncargosTramitadorFinalizados', { 'idTramitador': idTramitador});
  }

  getEncargosRevision(idTramitador: number){
    return this.http.post(this.basePath + 'getEncargosTramitadorRevision', { 'idTramitador': idTramitador});
  }

  getEncargosRevisado(idTramitador: number){
    return this.http.post(this.basePath + 'getEncargosTramitadorRevisado', { 'idTramitador': idTramitador});
  }

  getEncargosPendienteEnviar(idTramitador: number){
    return this.http.post(this.basePath + 'getEncargosTramitadorPendienteEnviar', { 'idTramitador': idTramitador});
  } */
}
