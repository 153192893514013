import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { Archivo } from '../../models/archivo';

@Component({
  selector: 'app-file-manager-medico',
  templateUrl: './file-manager-medico.component.html',
  styleUrls: ['./file-manager-medico.component.scss']
})
export class FileManagerMedicoComponent implements OnInit {

  constructor() { }

  @Input() fileElements: Archivo[];
  @Input() canNavigateUp: string;
  @Input() path: string;

  @Output() navigatedDown     = new EventEmitter<Archivo>();
  @Output() navigatedUp       = new EventEmitter();
  @Output() openModalView     = new EventEmitter<Archivo>();

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {}

  navigate(element: Archivo) {
    if (element.archIsFolder) {
      this.navigatedDown.emit(element);
    }
  }

  navigateUp() {
    this.navigatedUp.emit();
  }

  openModalFile(obj: Archivo) {
    if (!obj.archIsFolder)
      this.openModalView.emit(obj);
  }

  openMenu(event: MouseEvent, element: Archivo, viewChild: MatMenuTrigger) {
    event.preventDefault();
    viewChild.openMenu();
  }

}
