<app-header-tramitador></app-header-tramitador>

<div class="progress_spinner" *ngIf="mostrarProgressSpinner">
    <mat-spinner></mat-spinner>
</div>


<div class="container-fluid">

    <div class="row mt-5">
        <div class="col-md-1">
            <label for="expediente">Buscar expediente</label>
        </div>
        <div class="col-md-3">
            <input type="text" name="expediente" id="expediente" (keyup.enter)="getExpediente()" [(ngModel)]="numExpediente" required>
            <i class="bi bi-search icono_buscar" (click)="getExpediente()"></i>
            <span id="error">{{msgErrorInputExpediente}}</span>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-md-1">
            <label for="nombre">Nombre completo</label>
        </div>
        <div class="col-md-2">
            <input type="text" name="nombre" id="nombre" [(ngModel)]="expediente.lesionado">
        </div>
        <div class="col-md-1">
            <label for="edadLesionado">Edad Lesionado</label>
        </div>
        <div class="col-md-2">
            <input type="text" name="edadLesionado" id="edadLesionado" [(ngModel)]="expediente.edadLesionado">
        </div>
        <div class="col-md-1">
            <label for="siniestro">Siniestro</label>
        </div>
        <div class="col-md-2">
            <input type="text" name="siniestro" id="siniestro" [(ngModel)]="expediente.siniestro">
        </div>
        <div class="col-md-1">
            <label for="fechaSiniestro">Fecha del Siniestro</label>
        </div>
        <div class="col-md-2">
            <input type="text" name="fechaSiniestro" id="fechaSiniestro" [(ngModel)]="expediente.fechaSiniestro">
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-1">
            <label for="provincia">Provincia</label>
        </div>
        <div class="col-md-2">
            <input type="text" name="provincia" id="provincia" [(ngModel)]="expediente.provincia">
        </div>
        <div class="col-md-1">
            <label for="localidad">Localidad</label>
        </div>
        <div class="col-md-2">
            <input type="text" name="localidad" id="localidad" [(ngModel)]="expediente.localidad">
        </div>
        <div class="col-md-1">
            <label for="medicos">Médicos</label>
        </div>
        <div class="col-md-2">
            <select  class="select_tarea_medicos" [(ngModel)]="idMedico" required>
                <option value="-1">--</option>
                <option *ngFor="let med of medicos" [value]="med.med_id_user">{{med.med_nombre}}</option>
            </select>
        </div>
        <div class="col-md-1">
            <label for="tipos">Tipo</label>
        </div>
        <div class="col-md-2">
            <select  class="select_tarea_medicos" [(ngModel)]="idTipo" required>
                <option value="-1">--</option>
                <option *ngFor="let tipo of tipos" [value]="tipo.id">{{tipo.nombre}}</option>
            </select>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-1">
            <label for="condicion">Condición</label>
        </div>
        <div class="col-md-2">
            <input type="text" name="condicion" id="condicion" [(ngModel)]="expediente.condicion">
        </div>
        <div class="col-md-1">
            <label for="cia">Compañía</label>
        </div>
        <div class="col-md-2">
            <input type="text" name="cia" id="cia" [(ngModel)]="expediente.cia">
        </div>
        <div class="col-md-1">
            <label for="telefono">Teléfono</label>
        </div>
        <div class="col-md-2">
            <input type="text" id="telefono" [(ngModel)]="expediente.telefono">
        </div>
        <div class="col-md-1">
            <label for="fechaLimite">Fecha Límite</label>
        </div>
        <div class="col-md-2">
            <input type="date" id="fechaLimite" [(ngModel)]="fechaLimite">
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-1">
            <label for="produccion">Forma de producción</label>
        </div>
        <div class="col-md-5">
            <textarea name="produccion" id="produccion" cols="30" rows="1" style="width: 100%;" [(ngModel)]="expediente.formaProduccion">{{expediente.formaProduccion}}</textarea>
        </div>
        <div class="col-md-1">
            <label for="fechaConsultaVideo">Fecha Consulta/Vídeo Consulta</label>
        </div>
        <div class="col-md-2">
            <input type="date" id="fechaConsultaVideo" [(ngModel)]="fechaConsultaVideo">
        </div>
        <div class="col-md-1">
            <label for="horaConsultaVideo">Hora Consulta/Vídeo Consulta</label>
        </div>
        <div class="col-md-2">
            <input type="time" id="horaConsultaVideo" [(ngModel)]="horaConsultaVideo">
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-1">
            <label for="patoAutomatica">Pato automática</label>
        </div>
        <div class="col-md-2">
            <input type="text" name="patoAutomatica" id="patoAutomatica" [(ngModel)]="patoAutomatica">
        </div>
        <div class="col-md-2">
            <label for="formatoConsulta">Formato de consulta</label>
        </div>
        <div class="col-md-3">
            <select  class="select_tarea_medicos" [(ngModel)]="idFormatoConsulta" required>
                <option value="-1">--</option>
                <option *ngFor="let formato of formatoConsulta" [value]="formato.id">{{formato.nombre}}</option>
            </select>
        </div>
        <div class="col-md-3">
            <mat-checkbox [(ngModel)]="isUrgente">Urgente</mat-checkbox>
        </div>
    </div>
</div>

<!-- FAB Cancelar-->
<div *ngIf="mostrarCancel" class="fab_container cancel">
    <button mat-fab class="fab_datos" style="background-color: #dc3545;" color="white" title="Cancelar" aria-label="Example icon button with a delete icon" (click)="cancelar()">
        <mat-icon style="margin-top: -4px; color: white;">cancel</mat-icon>
    </button>
</div>

<!-- FAB Guardar-->
<div class="fab_container save">
    <button mat-fab class="fab_datos" color="white" title="En tramitacion" aria-label="Example icon button with a delete icon" (click)="guardarTarea('tramitacion')">
        <mat-icon style="margin-top: -6px;">save</mat-icon>
    </button>
</div>

<!-- FAB Enviar-->
<div class="fab_container send">
    <button mat-fab class="fab_datos" color="white" title="Enviado" aria-label="Example icon button with a delete icon" (click)="guardarTarea('enviado')">
        <mat-icon style="margin-top: -4px;">send</mat-icon>
    </button>
</div>

<div id="meet" style="display: none;">

</div>
