import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComentarioIncidencia } from 'src/app/facturacion/models/comentario-incidencia';
import { FacturasRecibidas } from 'src/app/facturacion/models/facturas-recibidas';
import { Incidencia } from 'src/app/facturacion/models/incidencia';
import { IncidenciaNormal } from 'src/app/facturacion/models/incidencia-normal';
import { IncidenciaPago } from 'src/app/facturacion/models/incidencia-pago';
import { Motivo } from 'src/app/facturacion/models/motivo';
import { Proveedor } from 'src/app/facturacion/models/proveedor';
import { Remesa } from 'src/app/facturacion/models/remesa';
import { ComentariosIncidencia, FacturacionServiceService, incidenciasNormales, Motivos, Remesas, Users } from 'src/app/facturacion/services/facturacion-service.service';
import { User } from 'src/app/models/user';
import { SessionService } from 'src/app/services/session.service';
import { DialogDataAction } from '../dialog-utils/dialog-data-action';

@Component({
  selector: 'app-incidencia-pago-dialog',
  templateUrl: './incidencia-pago-dialog.component.html',
  styleUrls: ['./incidencia-pago-dialog.component.scss']
})
export class IncidenciaPagoDialogComponent implements OnInit {

  incidencia: string = '';
  user: string = '--';
  fechaCreacion: Date = new Date();
  motivos: Motivo[] = [];
  usersAsignar: User[] = [];
  incidenciasNormales: IncidenciaNormal[] = [];
  remesas: Remesa[] = [];

  tipoIncidenciaSelected: string = '';
  estadoSelected: string = 'EST-001';
  usuarioAsignadoSelected: string = '';
  importePagado: number = 0;
  importeCorrecto: number = 0;
  diferencia: number = 0;
  incidenciaAsociadaSelected: string = '';
  remesaAsociadaSelected: string = '';
  motivoSelected: string = '';
  alarma: boolean = false;
  descripcion: string = '';

  comentarios: ComentarioIncidencia[] = [];

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(
    public facturacionService: FacturacionServiceService, 
    private sessionService: SessionService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataIncidenciaPago,
    public dialogRef: MatDialogRef<DialogDataIncidenciaPago>,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {

    this.user = this.sessionService.getCurrentSession().user.name;

    if(this.data != null && this.data.accion == DialogDataAction.modificar){
      this.incidencia = this.data.incidencia.inc_id_incidencias;

      this.tipoIncidenciaSelected = this.data.incidencia.inc_tipo;
      this.estadoSelected = this.data.incidencia.inc_estado;
      this.usuarioAsignadoSelected = this.data.incidencia.inc_usuario_asignado;
      this.importePagado = this.data.incidenciaPago.incp_importe_pagado;
      this.importeCorrecto = this.data.incidenciaPago.incp_importe_correcto;
      this.incidenciaAsociadaSelected = this.data.incidenciaPago.incp_incidencia_asociada;
      this.remesaAsociadaSelected = this.data.incidenciaPago.incp_remesa_asociada;
      this.motivoSelected = this.data.incidenciaPago.incp_motivo;
      this.descripcion = this.data.incidencia.inc_descripcion;

      this.obtenerComentariosIncidenciaPago();
      this.calcularDiferencia();
    }
    this.obtenerMotivos();
    this.obtenerUsuariosFacturacion();
    this.obtenerTodasIncidenciasNormales();
    this.obtenerRemesas();
  }

  obtenerMotivos(){
    this.facturacionService.obtenerMotivos().subscribe({
      next: (data: Motivos) => {
        this.motivos = data.data;
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }

  obtenerComentariosIncidenciaPago(){
    this.facturacionService.obtenerComentariosIncidenciaPago(this.data.incidenciaPago).subscribe({
      next: (data: ComentariosIncidencia) => {
        this.comentarios = data.data;
        console.log(this.comentarios);
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }

  obtenerUsuariosFacturacion(){
    this.facturacionService.obtenerUsuariosFacturacion().subscribe({
      next: (data: Users) => {
        this.usersAsignar = data.data.filter(user => user.id != this.sessionService.getCurrentSession().user.id);
        console.log(this.usersAsignar);
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }

  obtenerTodasIncidenciasNormales(){
    this.facturacionService.obtenerTodasIncidenciasNormales().subscribe({
      next: (data: incidenciasNormales) => {
        this.incidenciasNormales = data.data[0];
        this.incidenciasNormales.forEach((incidenciaNormal, key) => {
          incidenciaNormal['incidencia'] = data.data[1][key];
        })
        //console.log("incidencias normales", this.incidenciasNormales);
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }

  obtenerRemesas(){
    this.facturacionService.obtenerRemesas().subscribe({
      next: (data: Remesas) => {
        this.remesas = data.data;
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }

  checkIfIsUser(comentario: ComentarioIncidencia){
    return comentario.cinc_id_user == this.sessionService.getCurrentSession().user.id.toString();
  }
  
  calcularDiferencia(){
    this.diferencia = this.importeCorrecto - this.importePagado;
  }

  crearComentario(message: string){
    if(message.trim().length !== 0){

      const comentario: ComentarioIncidencia = {
        cinc_id_comentarios_incidencia: '',
        cinc_fecha_creacion: new Date(),
        cinc_fecha_modificacion: undefined,
        cinc_id_incidencia_normal: null,
        cinc_id_incidencia_contabilidad: null,
        cinc_id_incidencia_pago: null,
        cinc_id_user: this.sessionService.getCurrentSession().user.id.toString(),
        cinc_nombre: this.sessionService.getCurrentSession().user.name,
        cinc_text: message
      }

      this.comentarios.push(comentario);

      if(this.data.accion == DialogDataAction.modificar){
        this.facturacionService.crearComentarioIncidenciaPago(comentario, this.data.incidenciaPago).subscribe({
          next: (data: any) => {
            this.snackBar.open('Subido nuevo comentario', undefined, {
              duration: 3 * 1000,
            });
          },
          error: (err: any) => {
            console.log(err);
            this.snackBar.open(err, undefined, {
              duration: 3 * 1000,
            });
          }
        });
      }

    }
  }

  crearIncidenciaPago(){

    const incidencia: Incidencia = {
      inc_id_incidencias: '',
      inc_fecha_creacion: undefined,
      inc_fecha_modificacion: undefined,
      inc_usuario_creacion: this.sessionService.getCurrentSession().user.id.toString(),
      inc_estado: this.estadoSelected,
      inc_tipo: this.tipoIncidenciaSelected,
      inc_usuario_asignado: this.usuarioAsignadoSelected,
      inc_descripcion: this.descripcion,
      inc_fecha_finalizacion: undefined,
    }

    const incidenciaPago: IncidenciaPago = {
      incp_id_incidencias_pago: '',
      incp_fecha_creacion: undefined,
      incp_fecha_modificacion: undefined,
      incp_id_incidencia: '',
      incp_factura_recibida: this.data.factura.factr_id_facturas_recibidas,
      incp_importe_pagado: this.importePagado,
      incp_importe_correcto: this.importeCorrecto,
      incp_diferencia: this.diferencia,
      incp_incidencia_asociada: this.incidenciaAsociadaSelected,
      incp_remesa_asociada: this.remesaAsociadaSelected,
      incp_motivo: this.motivoSelected
    }

    if(
      this.tipoIncidenciaSelected == '' ||
      this.estadoSelected == '' ||
      this.usuarioAsignadoSelected == '' ||
      this.incidenciaAsociadaSelected == '' ||
      this.remesaAsociadaSelected == '' ||
      this.motivoSelected == '' ||
      this.descripcion == ''
    ){
      this.snackBar.open('Rellena los campos obligatorios *', undefined, {
        duration: 3 * 1000,
      });
    }
    else{
      this.facturacionService.crearIncidenciaPago(incidencia, incidenciaPago, this.comentarios).subscribe({
        next: (data: any) => {
          console.log(data);
          this.snackBar.open('Incidencia creada', undefined, {
            duration: 3 * 1000,
          });
          this.dialogRef.close();
        },
        error: (err: any) => {
          console.log(err);
          this.snackBar.open(err, undefined, {
            duration: 3 * 1000,
          });
        }
      })
    }
    
  }

  actualizarIncidenciaPago(){

    const incidencia: Incidencia = {
      inc_id_incidencias: this.data.incidencia.inc_id_incidencias,
      inc_fecha_creacion: this.data.incidencia.inc_fecha_creacion,
      inc_fecha_modificacion: this.data.incidencia.inc_fecha_modificacion,
      inc_usuario_creacion: this.data.incidencia.inc_usuario_creacion,
      inc_estado: this.estadoSelected,
      inc_tipo: this.tipoIncidenciaSelected,
      inc_usuario_asignado: this.usuarioAsignadoSelected,
      inc_descripcion: this.descripcion,
      inc_fecha_finalizacion: this.data.incidencia.inc_fecha_finalizacion,
    }

    const incidenciaPago: IncidenciaPago = {
      incp_id_incidencias_pago: this.data.incidenciaPago.incp_id_incidencias_pago,
      incp_fecha_creacion: this.data.incidenciaPago.incp_fecha_creacion,
      incp_fecha_modificacion: this.data.incidenciaPago.incp_fecha_modificacion,
      incp_id_incidencia: this.data.incidenciaPago.incp_id_incidencia,
      incp_factura_recibida: this.data.factura.factr_id_facturas_recibidas,
      incp_importe_pagado: this.importePagado,
      incp_importe_correcto: this.importeCorrecto,
      incp_diferencia: this.diferencia,
      incp_incidencia_asociada: this.incidenciaAsociadaSelected,
      incp_remesa_asociada: this.remesaAsociadaSelected,
      incp_motivo: this.motivoSelected
    }

    if(
      this.tipoIncidenciaSelected == '' ||
      this.estadoSelected == '' ||
      this.usuarioAsignadoSelected == '' ||
      this.importePagado == 0 ||
      this.importeCorrecto == 0 ||
      this.incidenciaAsociadaSelected == '' ||
      this.remesaAsociadaSelected == '' ||
      this.motivoSelected == '' ||
      this.descripcion == ''
    ){
      this.snackBar.open('Rellena los campos obligatorios *', undefined, {
        duration: 3 * 1000,
      });
    }
    else{
      this.facturacionService.actualizarIncidenciaPago(
        incidencia,
        incidenciaPago
      ).subscribe({
        next: (data: any) => {
          console.log(data);
          this.snackBar.open('Incidencia actualizada', undefined, {
            duration: 3 * 1000,
          });
          this.dialogRef.close();
        },
        error: (err: any) => {
          console.log(err);
          this.snackBar.open(err, undefined, {
            duration: 3 * 1000,
          });
        }
      });
    }

  }

}

interface DialogDataIncidenciaPago {
  factura: FacturasRecibidas;
  incidencia: Incidencia;
  incidenciaPago: IncidenciaPago;
  accion: DialogDataAction;
}