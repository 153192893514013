<h1 mat-dialog-title>Incidencia de pago: {{incidencia}}</h1>
<div mat-dialog-content>

    <div class="d-flex align-items-end mb-4">
        <div class="flex-grow-1 info-container px-3 py-2">
            <h5 class="m-0">Usuario</h5>
            <h4 class="m-0">{{user}}</h4>
        </div>
        <div class="flex-grow-1 info-container right-container px-3 py-2">
            <h5 class="m-0">Fecha</h5>
            <h4 class="m-0">{{fechaCreacion.getDate() | number:'2.0'}}/{{(fechaCreacion.getMonth()+1) | number:'2.0'}}/{{fechaCreacion.getFullYear()}}</h4>
        </div>
    </div>

    <div class="row custom-inputs">
        <div class="col-4">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Tipo incidencia *</mat-label>
                <mat-select [(ngModel)]="tipoIncidenciaSelected">
                    <mat-option value="Factura duplicada">Factura duplicada</mat-option>
                    <mat-option value="Diferente proveedor">Diferente proveedor</mat-option>
                    <mat-option value="Error IRPF">Error IRPF</mat-option>
                    <mat-option value="Error IVA">Error IVA</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Estado *</mat-label>
                <mat-select [(ngModel)]="estadoSelected">
                    <mat-option value="Cerrada">Cerrada</mat-option>
                    <mat-option value="Comunicada">Comunicada</mat-option>
                    <mat-option value="Pendiente">Pendiente</mat-option>
                    <mat-option value="Finalizada">Finalizada</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Asignado a *</mat-label>
                <mat-select [(ngModel)]="usuarioAsignadoSelected">
                    <mat-option *ngFor="let user of usersAsignar" [value]="user.id.toString()">{{user.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="row custom-inputs">
        <div class="col-4">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Importe pagado (€) *</mat-label>
                <input matInput type="number" [(ngModel)]="importePagado" (change)="calcularDiferencia()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Importe correcto (€) *</mat-label>
                <input matInput type="number" [(ngModel)]="importeCorrecto" (change)="calcularDiferencia()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Diferencia (€) *</mat-label>
                <input matInput type="number" [(ngModel)]="diferencia" disabled>
            </mat-form-field>
        </div>
    </div>

    <div class="row custom-inputs">
        <div class="col-4">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Incidencia asociada *</mat-label>
                <mat-select [(ngModel)]="incidenciaAsociadaSelected">
                    <mat-option class="h-auto" *ngFor="let incidenciaNormal of incidenciasNormales" [value]="incidenciaNormal.incn_id_incidencia">
                        <div>{{incidenciaNormal.incn_id_incidencia}} | {{incidenciaNormal.incidencia.inc_estado}}</div>
                        <div class="description"><small>{{incidenciaNormal.incidencia.inc_fecha_creacion}}</small></div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Remesa asociada *</mat-label>
                <mat-select [(ngModel)]="remesaAsociadaSelected">
                    <mat-option class="h-auto" *ngFor="let remesa of remesas" [value]="remesa.rem_id_remesas">
                        <div>{{remesa.rem_id_remesas}} | {{remesa.rem_estado}}</div>
                        <div class="description"><small>{{remesa.rem_total_remesa}}€</small></div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Motivo *</mat-label>
                <mat-select [(ngModel)]="motivoSelected">
                    <mat-option *ngFor="let motivo of motivos" [value]="motivo.mot_id_motivos">{{motivo.mot_descripcion}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="row mb-4 custom-inputs">
        <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Descripción *</mat-label>
                <textarea matInput rows="4" [(ngModel)]="descripcion"></textarea>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-12 d-flex flex-column custom-height-230">
            <div class="flex-grow-1 overflow-auto border">
                <div *ngFor="let comentario of comentarios" [ngClass]="{'custom-message-user': (checkIfIsUser(comentario)) , 'custom-message-another-user': (!checkIfIsUser(comentario)) }">
                    <h3 class="m-0"><strong>{{comentario.cinc_nombre}}</strong></h3>
                    <h6 class="mb-1">{{comentario.cinc_fecha_creacion | date: 'dd/MM/yyyy hh:mm a'}}</h6>
                    <mat-divider class="my-1"></mat-divider>
                    <p class="m-0 custom-format">{{comentario.cinc_text}}</p>
                </div>
            </div>
            <div>
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Comentario</mat-label>
                    <textarea #message matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" class="overflow-hidden" (keyup.enter)="crearComentario(message.value); message.value = ''"></textarea>
                    <button mat-icon-button matSuffix (click)="crearComentario(message.value); message.value = ''">
                        <mat-icon>send</mat-icon>
                    </button>
                    <mat-hint>Shift + Enter para salto de linea</mat-hint>
                </mat-form-field>
            </div>
        </div>
    </div>

</div>
<div mat-dialog-actions>
    <button *ngIf="data.accion == 0" mat-flat-button color="primary" (click)="crearIncidenciaPago()">Generar</button>
    <button *ngIf="data.accion == 1" mat-flat-button color="primary" (click)="actualizarIncidenciaPago()">Actualizar</button>
</div>