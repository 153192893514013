import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { EstadoRemesas } from "src/app/facturacion/models/estado-remesas";
import { Pago } from "src/app/facturacion/models/pago";
import { PagoFacturas } from "src/app/facturacion/models/pago-facturas";
import { Proveedor } from "src/app/facturacion/models/proveedor";
import { Remesa } from "src/app/facturacion/models/remesa";
import { EstadosRemesas, FacturacionServiceService, Pagos, Proveedores, Remesas, Users } from "src/app/facturacion/services/facturacion-service.service";
import { SessionService } from "src/app/services/session.service";
import { DialogDataAction } from "../dialog-utils/dialog-data-action";
import * as XLSX from 'xlsx-js-style';
import * as saveAs from "file-saver";
import * as vkbeautify from 'vkbeautify';
import dateFormat from "dateformat";


@Component({
  selector: 'app-remesa-dialog',
  templateUrl: './remesa-dialog.component.html',
  styleUrls: ['./remesa-dialog.component.scss']
})
export class RemesaDialogComponent implements OnInit {

  minDate: Date = new Date();

  numRemesa: string = '--';
  usuario: string = '--';
  iban: string = '--';
  estado: string = '--';

  totalPreceptores: number = 0;
  totalFacturas: number = 0;
  totalPagado: number = 0;

  fechaPago: Date;

  remesa: Remesa;
  pagos: Pago[] = [];
  pagosFacturas: PagoFacturas[] = [];
  proveedores: Proveedor[] = [];
  estadosRemesas: EstadoRemesas[] = [];

  //---Tabla Pagos---
  @ViewChild('tablePagos')
  pagosTable: MatTable<Pago>;
  displayedColumnsPagos: string[] = [
    'cif',
    'nombre_proveedor', 
    'pag_iban_cuenta', 
    'pag_concepto',
    'pag_total_pagado'
  ];
  dataSourcePagos: MatTableDataSource<Pago> = new MatTableDataSource(this.pagos);


  constructor(
    public facturacionService: FacturacionServiceService, 
    private sessionService: SessionService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataRemesa,
    public dialogRef: MatDialogRef<RemesaDialogComponent>,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
    //console.log(this.data);
    if(this.data.accion == DialogDataAction.crear){
      this.pagos = this.data.pagos;
      this.pagosFacturas = this.data.pagosFacturas;
      this.proveedores = this.data.proveedores;
      this.estado = 'PENDIENTE';

      this.dataSourcePagos.data = this.pagos;
      this.usuario = this.sessionService.getCurrentSession().user.name;

      this.totalFacturas = this.pagosFacturas.length;
      this.totalPreceptores = this.pagos.length;
      this.pagos.forEach(pago => {
        this.totalPagado += pago.pag_total_pagado;
      })
    }
    else{
      this.remesa = this.data.remesa;
      this.obtenerEstadoRemesa();
      this.obtenerProveedores();
      this.obtenerUsuario();

      this.numRemesa = this.remesa.rem_id_remesas;
      this.totalFacturas = this.remesa.rem_cantidad_facturas;
      this.totalPreceptores = this.remesa.rem_perceptores;
      this.totalPagado = this.remesa.rem_total_remesa;
    }
    

  }

  obtenerEstadoRemesa(){
    this.facturacionService.obtenerEstadosRemesas().subscribe({
      next: (data: EstadosRemesas) => {
        console.log(data);
        this.estadosRemesas = data.data;
        this.estado = this.estadosRemesas.filter(estadoRemesa => estadoRemesa.estr_id_estados == this.remesa.rem_estado_remesa)[0].estr_descripcion;
      },
      error: (err: any) => {
        console.log(err);
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
      }
    })
  }

  obtenerPagos(){
    this.facturacionService.obtenerPagosRemesa(this.remesa).subscribe({
      next: (data: Pagos) => {
        console.log(data);
        this.pagos = data.data;
        this.dataSourcePagos.data = this.pagos;
      },
      error: (err: any) => {
        console.log(err);
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
      }
    })
  }

  obtenerProveedores(){
    this.facturacionService.obtenerProveedores().subscribe({
      next: (data: Proveedores) => {
        this.proveedores = data.data;
        this.obtenerPagos();
      },
      error: (err: any) => {
        console.log(err);
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
      }
    })
  }
  
  obtenerUsuario(){
    this.facturacionService.obtenerUsuariosFacturacion().subscribe({
      next: (data: Users) => {
        this.usuario = data.data.filter(usuario => usuario.id.toString() == this.remesa.rem_usuario_genera)[0].name;
      },
      error: (err: any) => {
        console.log(err);
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
      }
    })
  }

  getNIF(pago: Pago): string{
    return this.proveedores.filter(proveedor => proveedor.prov_id_crm == pago.pag_proveedor)[0].prov_cif;
  }

  getNombre(pago: Pago): string{
    return this.proveedores.filter(proveedor => proveedor.prov_id_crm == pago.pag_proveedor)[0].prov_nombre;
  }

  crearRemesa(){

    let remesa: Remesa = {
      rem_id_remesas: null,
      rem_fecha_creacion: null,
      rem_fecha_modificacion: null,
      rem_estado: "Activo",
      rem_fecha: null,
      rem_fecha_pago: null,
      rem_fecha_cancelada: null,
      rem_iban: null,
      rem_estado_remesa: "ESTR-002",
      rem_perceptores: this.totalPreceptores,
      rem_cantidad_facturas: this.totalFacturas,
      rem_total_remesa: this.totalPagado,
      rem_usuario_genera: this.sessionService.getCurrentSession().user.id.toString(),
      rem_usuario_pagada: null,
      rem_usuario_cancelada: null
    }

    //console.log(this.pagosFacturas);

    this.facturacionService.crearRemesa(
      this.pagos,
      this.pagosFacturas,
      remesa
    ).subscribe({
      next: (data: any) => {
        this.snackBar.open("Remesa guardada con éxito", undefined, {
          duration: 3 * 1000,
        });
        this.dialogRef.close();
      },
      error: (err: any) => {
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
        this.dialogRef.close();
      }
    })

  }

  generarExcel() {

    if(this.pagos.length > 0){

      var ws_data = [[
        'CIF/NIF',
        'Razón social', 
        'Cuenta IBAN', 
        'Concepto',
        'Total pagado'
      ]];

      this.pagos.forEach(pago => {
        ws_data.push([
          this.getNIF(pago),
          this.getNombre(pago),
          pago.pag_iban_cuenta,
          pago.pag_concepto,
          pago.pag_total_pagado + '€'
        ])
      });

       /* pass here the table id */
      const ws: XLSX.WorkSheet =XLSX.utils.aoa_to_sheet(ws_data);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */  
      XLSX.writeFile(wb, "Pagos.xlsx");

      this.snackBar.open("Generada Hoja de Excel de pagos", undefined, {
        duration: 3 * 1000,
      });

    }

  }

  generarXMLRemesa(){
    
    this.facturacionService.generarXMLRemesa(this.remesa).subscribe({
      next: (data: any) => {
        //console.log(data.data);
        this.snackBar.open("Generado XML de remesa", undefined, {
          duration: 3 * 1000,
        });
        this.estado = 'GENERADA';

        const xml = document.implementation.createDocument("", "", null); 
        const xmlDocument = xml.createElement("Document");
        xmlDocument.setAttribute("xmlns", "urn:iso:std:iso:20022:tech:xsd:pain.001.001.03");
        xml.appendChild(xmlDocument);

          const cstmrCdtTrfInitn = xml.createElement("CstmrCdtTrfInitn");
          xmlDocument.appendChild(cstmrCdtTrfInitn);

            //Header GrpHdr

            const grpHdr = xml.createElement("GrpHdr");
            cstmrCdtTrfInitn.appendChild(grpHdr);

              const grpHdr_msgId = xml.createElement("MsgId");
              grpHdr.appendChild(grpHdr_msgId);
              grpHdr_msgId.textContent = 'B87446209' + '001' + dateFormat(new Date(), "yyyymmdd") + dateFormat(new Date(), "yyyymmdd") + dateFormat(new Date(), "HHMMss");
              const grpHdr_creDtTm = xml.createElement("CreDtTm");
              grpHdr.appendChild(grpHdr_creDtTm);
              grpHdr_creDtTm.textContent = dateFormat(new Date(), "yyyy-mm-dd") + "T" + dateFormat(new Date(), "HH:MM:ss");
              const grpHdr_nbOfTxs = xml.createElement("NbOfTxs");
              grpHdr.appendChild(grpHdr_nbOfTxs);
              grpHdr_nbOfTxs.textContent = this.totalPreceptores.toString();
              const grpHdr_ctrlSum = xml.createElement("CtrlSum");
              grpHdr.appendChild(grpHdr_ctrlSum);
              grpHdr_ctrlSum.textContent = this.remesa.rem_total_remesa.toString();
              const grpHdr_initgPty = xml.createElement("InitgPty");
              grpHdr.appendChild(grpHdr_initgPty);

                const grpHdr_initgPty_nm = xml.createElement("Nm");
                grpHdr_initgPty.appendChild(grpHdr_initgPty_nm);
                grpHdr_initgPty_nm.textContent = 'UNIÓN PERICIAL';
                const grpHdr_initgPty_id = xml.createElement("Id");
                grpHdr_initgPty.appendChild(grpHdr_initgPty_id);

                  const grpHdr_initgPty_id_orgId = xml.createElement("OrgId");
                  grpHdr_initgPty_id.appendChild(grpHdr_initgPty_id_orgId);

                    const grpHdr_initgPty_id_orgId_othr = xml.createElement("Othr");
                    grpHdr_initgPty_id_orgId.appendChild(grpHdr_initgPty_id_orgId_othr);

                      const grpHdr_initgPty_id_orgId_othr_id = xml.createElement("Id");
                      grpHdr_initgPty_id_orgId_othr.appendChild(grpHdr_initgPty_id_orgId_othr_id);
                      grpHdr_initgPty_id_orgId_othr_id.textContent = 'B87446209001';

            //---End Region---

            const pmtInf = xml.createElement("PmtInf");
            cstmrCdtTrfInitn.appendChild(pmtInf);

              const pmtInf_pmtInfId = xml.createElement("PmtInfId");
              pmtInf.appendChild(pmtInf_pmtInfId);
              pmtInf_pmtInfId.textContent = 'B874462090012022032420220324120613';

              const pmtInf_pmtMtd = xml.createElement("PmtMtd");
              pmtInf.appendChild(pmtInf_pmtMtd);
              pmtInf_pmtMtd.textContent = 'TRF';

              const pmtInf_btchBookg = xml.createElement("BtchBookg");
              pmtInf.appendChild(pmtInf_btchBookg);
              pmtInf_btchBookg.textContent = 'true';

              const pmtInf_pmtTpInf = xml.createElement("PmtTpInf");
              pmtInf.appendChild(pmtInf_pmtTpInf);

                const pmtInf_pmtTpInf_svcLvl = xml.createElement("SvcLvl");
                pmtInf_pmtTpInf.appendChild(pmtInf_pmtTpInf_svcLvl);

                  const pmtInf_pmtTpInf_svcLvl_cd = xml.createElement("Cd");
                  pmtInf_pmtTpInf_svcLvl.appendChild(pmtInf_pmtTpInf_svcLvl_cd);
                  pmtInf_pmtTpInf_svcLvl_cd.textContent = 'SEPA';

              const pmtInf_reqdExctnDt = xml.createElement("ReqdExctnDt");
              pmtInf.appendChild(pmtInf_reqdExctnDt);  
              pmtInf_reqdExctnDt.textContent = dateFormat(new Date(), "yyyy-mm-dd");

              const pmtInf_dbtr = xml.createElement("Dbtr");
              pmtInf.appendChild(pmtInf_dbtr);  

                const pmtInf_dbtr_nm = xml.createElement("Nm");
                pmtInf_dbtr.appendChild(pmtInf_dbtr_nm);  
                pmtInf_dbtr_nm.textContent = 'UNIÓN PERICIAL';

                const pmtInf_dbtr_pstlAdr = xml.createElement("PstlAdr");
                pmtInf_dbtr.appendChild(pmtInf_dbtr_pstlAdr);  

                  const pmtInf_dbtr_pstlAdr_ctry = xml.createElement("Ctry");
                  pmtInf_dbtr_pstlAdr.appendChild(pmtInf_dbtr_pstlAdr_ctry);  
                  pmtInf_dbtr_pstlAdr_ctry.textContent = 'ES';

                  const pmtInf_dbtr_pstlAdr_adrLine_1 = xml.createElement("AdrLine");
                  pmtInf_dbtr_pstlAdr.appendChild(pmtInf_dbtr_pstlAdr_adrLine_1);  
                  pmtInf_dbtr_pstlAdr_adrLine_1.textContent = 'Calle Aizgorri Nº10';

                  const pmtInf_dbtr_pstlAdr_adrLine_2 = xml.createElement("AdrLine");
                  pmtInf_dbtr_pstlAdr.appendChild(pmtInf_dbtr_pstlAdr_adrLine_2);  
                  pmtInf_dbtr_pstlAdr_adrLine_2.textContent = 'Madrid';

              const pmtInf_dbtrAcct = xml.createElement("DbtrAcct");
              pmtInf.appendChild(pmtInf_dbtrAcct); 

                const pmtInf_dbtrAcct_id = xml.createElement("Id");
                pmtInf_dbtrAcct.appendChild(pmtInf_dbtrAcct_id); 

                  const pmtInf_dbtrAcct_id_iban = xml.createElement("IBAN");
                  pmtInf_dbtrAcct_id.appendChild(pmtInf_dbtrAcct_id_iban); 
                  pmtInf_dbtrAcct_id_iban.textContent = 'ES6100494604812216164865';

              const pmtInf_dbtrAgt = xml.createElement("DbtrAgt");
              pmtInf.appendChild(pmtInf_dbtrAgt); 

                const pmtInf_dbtrAgt_finInstnId = xml.createElement("FinInstnId");
                pmtInf_dbtrAgt.appendChild(pmtInf_dbtrAgt_finInstnId); 

                  const pmtInf_dbtrAgt_finInstnId_bic = xml.createElement("BIC");
                  pmtInf_dbtrAgt_finInstnId.appendChild(pmtInf_dbtrAgt_finInstnId_bic); 
                  pmtInf_dbtrAgt_finInstnId_bic.textContent = 'BSCHESMMXXX';

              const pmtInf_chrgBr = xml.createElement("ChrgBr");
              pmtInf.appendChild(pmtInf_chrgBr); 
              pmtInf_chrgBr.textContent = 'SLEV';

            this.pagos.forEach(pago => {

              const pmtInf_cdtTrfTxInf = xml.createElement("CdtTrfTxInf");
              pmtInf.appendChild(pmtInf_cdtTrfTxInf);

                const pmtInf_cdtTrfTxInf_pmtId = xml.createElement("PmtId");
                pmtInf_cdtTrfTxInf.appendChild(pmtInf_cdtTrfTxInf_pmtId);

                  const pmtInf_cdtTrfTxInf_pmtId_endToEndId = xml.createElement("EndToEndId");
                  pmtInf_cdtTrfTxInf_pmtId.appendChild(pmtInf_cdtTrfTxInf_pmtId_endToEndId);
                  pmtInf_cdtTrfTxInf_pmtId_endToEndId.textContent = 'NOTPROVIDED';

                const pmtInf_cdtTrfTxInf_amt = xml.createElement("Amt");
                pmtInf_cdtTrfTxInf.appendChild(pmtInf_cdtTrfTxInf_amt);

                  const pmtInf_cdtTrfTxInf_amt_instdAmt = xml.createElement("InstdAmt");
                  pmtInf_cdtTrfTxInf_amt.appendChild(pmtInf_cdtTrfTxInf_amt_instdAmt);
                  pmtInf_cdtTrfTxInf_amt_instdAmt.setAttribute("Ccy", "EUR");
                  pmtInf_cdtTrfTxInf_amt_instdAmt.textContent = pago.pag_total_pagado.toString();

                const pmtInf_cdtTrfTxInf_cdtr = xml.createElement("Cdtr");
                pmtInf_cdtTrfTxInf.appendChild(pmtInf_cdtTrfTxInf_cdtr);

                  const pmtInf_cdtTrfTxInf_cdtr_nm = xml.createElement("Nm");
                  pmtInf_cdtTrfTxInf_cdtr.appendChild(pmtInf_cdtTrfTxInf_cdtr_nm);
                  pmtInf_cdtTrfTxInf_cdtr_nm.textContent = this.proveedores.filter(proveedor => proveedor.prov_id_crm == pago.pag_proveedor)[0].prov_nombre;

                const pmtInf_cdtTrfTxInf_cdtrAcct = xml.createElement("CdtrAcct");
                pmtInf_cdtTrfTxInf.appendChild(pmtInf_cdtTrfTxInf_cdtrAcct);

                  const pmtInf_cdtTrfTxInf_cdtrAcct_id = xml.createElement("Id");
                  pmtInf_cdtTrfTxInf_cdtrAcct.appendChild(pmtInf_cdtTrfTxInf_cdtrAcct_id);

                const pmtInf_cdtTrfTxInf_rmtInf = xml.createElement("RmtInf");
                pmtInf_cdtTrfTxInf.appendChild(pmtInf_cdtTrfTxInf_rmtInf);

                  const pmtInf_cdtTrfTxInf_rmtInf_ustrd = xml.createElement("Ustrd");
                  pmtInf_cdtTrfTxInf_rmtInf.appendChild(pmtInf_cdtTrfTxInf_rmtInf_ustrd);
                  pmtInf_cdtTrfTxInf_rmtInf_ustrd.textContent = "Nº FACT: " + pago.pag_concepto;

            });

        var xmltext = new XMLSerializer().serializeToString(xml);
        var xmlVersion = '<?xml version="1.0" encoding="utf-8" standalone="yes"?>';

        xmlVersion += xmltext;
        
        var blob = new Blob([vkbeautify.xml(xmlVersion)], {type: "text/xml"});
        saveAs(blob, "test.xml");

        console.log(xml);

      },
      error: (err: any) => {
        console.log(err);
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
      }
    })

  }

  generarInformeRemesa(){

    this.facturacionService.generarInformeRemesa(this.remesa).subscribe({
      next: (data: any) => {
        this.snackBar.open("PDF generado", undefined, {
          duration: 3 * 1000,
        });
      },
      error: (err: any) => {
        console.log(err);
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
      }
    })

  }

  pagarRemesa(){

    if(this.fechaPago != null){
      let remesaAPagar = this.remesa;
    
      remesaAPagar.rem_usuario_pagada = this.sessionService.getCurrentSession().user.id.toString();
      remesaAPagar.rem_fecha_pago = this.fechaPago;

      this.facturacionService.pagarRemesa(this.pagos, remesaAPagar).subscribe({
        next: (data: any) => {
          //console.log(data.data);
          this.snackBar.open("Remesa pagada", undefined, {
            duration: 3 * 1000,
          });
          this.dialogRef.close();
        },
        error: (err: any) => {
          console.log(err);
          this.snackBar.open("Error en el servidor", undefined, {
            duration: 3 * 1000,
          });
          this.dialogRef.close();
        }
      })
    }
    else{
      this.snackBar.open("Introduce una fecha de pago", undefined, {
        duration: 3 * 1000,
      });
    }

  }

  cancelarRemesa(){

    let remesaACancelar = this.remesa;
    
    remesaACancelar.rem_usuario_cancelada = this.sessionService.getCurrentSession().user.id.toString();

    this.facturacionService.cancelarRemesa(remesaACancelar).subscribe({
      next: (data: Remesas) => {
        //console.log(data.data);
        this.snackBar.open("Remesa cancelada", undefined, {
          duration: 3 * 1000,
        });
        this.dialogRef.close();
      },
      error: (err: any) => {
        console.log(err);
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
        this.dialogRef.close();
      }
    })
  }

}

interface DialogDataRemesa {
  pagos: Pago[];
  pagosFacturas: PagoFacturas[];
  proveedores: Proveedor[];
  remesa: Remesa;
  accion: DialogDataAction;
}
