<h1 mat-dialog-title>Pasar encargo a revisado</h1>
<div mat-dialog-content>
    <mat-form-field style="width: 100%;" appearance="outline">
        <mat-label>Añadir comentario</mat-label>
        <textarea matInput rows="6" [(ngModel)]="comentario" placeholder="comentario"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-content>¿Seguro que quiere pasar encargo a revisado?</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="comentario">Si</button>
    <button mat-button [mat-dialog-close]="false">No</button>
</div>