import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Proveedor } from '../../models/proveedor';
import { EstadosFacturasRecibidas, FacturacionServiceService, FacturaRecibidaData, FacturasRecibidasList, FacturasRecibidasProductosList, Proveedores } from '../../services/facturacion-service.service';
import { FacturasRecibidas } from '../../models/facturas-recibidas';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { IncidenciaFacturaRecibidaDialogComponent } from 'src/app/dialogs/incidencia-factura-recibida-dialog/incidencia-factura-recibida-dialog.component';

import * as XLSX from 'xlsx-js-style';
import { SessionService } from 'src/app/services/session.service';
import { IncidenciaProveedorDialogComponent } from 'src/app/dialogs/incidencia-proveedor-dialog/incidencia-proveedor-dialog.component';
import { FacturasRecibidasProductos } from '../../models/facturas-recibidas-productos';
import { DialogDataAction } from 'src/app/dialogs/dialog-utils/dialog-data-action';
import { EstadoFacturasRecibidas } from '../../models/estado-facturas-recibidas';
import { ListaFacturasRemesaDialogComponent } from 'src/app/dialogs/lista-facturas-remesa-dialog/lista-facturas-remesa-dialog.component';
import { RemesaDialogComponent } from 'src/app/dialogs/remesa-dialog/remesa-dialog.component';
import { Contabilidad } from '../../models/contabilidad';
import { UnidadFiscal } from '../../models/unidad-fiscal';
import { ListaFacturasRecibidasContabilidadDialogComponent } from 'src/app/dialogs/lista-facturas-recibidas-contabilidad-dialog/lista-facturas-recibidas-contabilidad-dialog.component';

@Component({
  selector: 'app-facturas-recibidas',
  templateUrl: './facturas-recibidas.component.html',
  styleUrls: ['./facturas-recibidas.component.scss']
})
export class FacturasRecibidasComponent implements OnInit {

  loadingPageFlag: boolean = false;

  //VARIABLES PROVEEDORES
  proveedores: Proveedor[] = [];

  proveedorSearch: string = '';
  proveedorFilter: string = "nombre";

  //Tablas
  @ViewChild('tableProveedores')
  proveedoresTable: MatTable<Proveedor>;
  displayedColumnsProveedores: string[] = [
    'prov_id_crm', 
    'prov_fecha_creacion', 
    'prov_fecha_modificacion', 
    'prov_estado', 
    'prov_nombre', 
    'prov_cif',
    'prov_domicilio',
    'prov_poblacion',
    'prov_codigo_postal',
    'prov_telefono',
    'prov_movil',
    'prov_fax',
    'prov_email',
    'prov_retencion',
    'prov_iban',
    'prov_cuenta',
    'prov_codigoiae',
    'prov_inicio_actividad',
    'prov_periodo_pago',
    'prov_contrato',
    
  ];
  dataSourceProveedores: MatTableDataSource<Proveedor> = new MatTableDataSource(this.proveedores);

  //Paginator Facturas
  @ViewChild('proveedoresPaginator')
  proveedoresPaginator: MatPaginator;
  proveedoresLength = 0;

  //VARIABLES FACTURAS RECIBIDAS
  facturasRecibidas: FacturasRecibidas[] = [];
  facturasRecibidasSinFecha: FacturasRecibidas[] = [];

  estadosFactura: EstadoFacturasRecibidas[] = [];

  facturasRecibidasFecha: number = new Date().getFullYear();
  facturasRecibidasSearch: string = '';
  facturasRecibidasFilter: string = "nombre";
  

  sinFechaFlag: boolean = false;

  baseImponible: number = 0;
  iva: number = 0;
  irpf: number = 0;
  totalFacturado: number = 0;

  //Tablas
  @ViewChild('tableFacturasRecibidas')
  facturasRecibidasTable: MatTable<FacturasRecibidas>;
  displayedColumnsFacturasRecibidas: string[] = [
    'factr_id_facturas_recibidas',
    'factr_fecha_creacion', 
    'factr_numero_factura',
    'factr_fecha_recepcion', 
    'factr_fecha_pago',
    'factr_base_imponible', 
    'factr_total_iva',
    'factr_total_factura', 
    'factr_estado', 
    'factr_contabilidad',
    'factr_proveedor',
    'factr_estado_factura'
  ];
  dataSourceFacturasRecibidas: MatTableDataSource<FacturasRecibidas> = new MatTableDataSource(this.facturasRecibidas);

  //Paginator Facturas
  @ViewChild('facturasRecibidasPaginator')
  facturasRecibidasPaginator: MatPaginator;
  facturasRecibidasLength = 0;

  constructor(
    private facturacionService : FacturacionServiceService,
    private snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
    private sessionService: SessionService
  ) { }

  ngOnInit(): void {
    this.obtenerProveedores();
    this.obtenerEstadosFacturasRecibidas();
  }

  ngAfterViewInit(): void {
    this.proveedoresPaginator._intl.itemsPerPageLabel = 'Mostrando:';
  }

  //------------------------ PROVEEDORES ------------------------------

  obtenerProveedores(){

    this.loadingPageFlag = true;

    this.facturacionService.obtenerProveedores().subscribe({
      next: (data: Proveedores) => {
        this.proveedores = data.data;

        this.proveedores.forEach(proveedor => {
          proveedor["checked"] = false;
        })

        this.dataSourceProveedores = new MatTableDataSource(this.proveedores);
        this.dataSourceProveedores.paginator = this.proveedoresPaginator;

        this.proveedoresLength = this.dataSourceProveedores.filteredData.length;

        this.loadingPageFlag = false;

        this.obtenerFacturasRecibidas()
      },

      error: (err: any) => {
        this.loadingPageFlag = false;
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
      }
    });
  }

  changeFilterProveedores(){
    if(this.proveedorFilter === 'nombre'){
      this.dataSourceProveedores.filterPredicate = function(data, filter: string): boolean {
        if(data.prov_nombre != null){
          return data.prov_nombre.toLowerCase().includes(filter);
        }
        else{
          return false;
        }
      };
    }
    else if(this.proveedorFilter === 'cif'){
      this.dataSourceProveedores.filterPredicate = function(data, filter: string): boolean {
        if(data.prov_cif != null){
          return data.prov_cif.toLowerCase().includes(filter);
        }
        else{
          return false;
        }
        
      };
    }
    else if(this.proveedorFilter === 'provincia'){
      this.dataSourceProveedores.filterPredicate = function(data, filter: string): boolean {
        if(data.prov_provincia != null){
          return data.prov_provincia.prv_nombre_crm.toLowerCase().includes(filter);
        }
        else{
          return false;
        }
      };
    }
  }

  applyFilterProveedor(filterValue: string) {

    this.changeFilterProveedores();

    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSourceProveedores.filter = filterValue;
  }

  selectProveedor(proveedor: Proveedor){

    this.proveedores.forEach(proveedorInd => {
      if(proveedorInd.prov_id_crm == proveedor.prov_id_crm){
        if(proveedorInd["checked"] == true){
          proveedorInd["checked"] = false;
        }
        else{
          proveedorInd["checked"] = true;
        }
      }
      else{
        proveedorInd["checked"] = false;
      }
    });

  }

  navigateToProveedor(){
    const proveedor: Proveedor = this.proveedores.filter(proveedorInd => proveedorInd["checked"])[0];
    if(proveedor != undefined){
      window.open("http://"+window.location.host+window.location.pathname+'#/proveedor?proveedor='+proveedor.prov_id_crm,  '');
      // this.router.navigate(['proveedor'], {
      //   queryParams: {
      //     proveedor: proveedor.prov_id_crm,
      //   }
      // });
    }
    else{
      this.snackBar.open("Debe seleccionar un proveedor", undefined, {
        duration: 3 * 1000,
      });
    }
    
  }

  createIncidenciaProveedor(){
    const proveedor: Proveedor = this.proveedores.filter(proveedor => proveedor["checked"])[0];

    if(proveedor != undefined){
      this.dialog.open(IncidenciaProveedorDialogComponent, {
        maxWidth: 1000,
        data: {
          proveedor: proveedor,
          incidencia: null,
          incidenciaNormal: null,
          accion: DialogDataAction.crear,
        },
      });
    }
    else{
      this.snackBar.open("Debe seleccionar un proveedor", undefined, {
        duration: 3 * 1000,
      });
    }

  }

  createAndNavigateToIncluirFactura(){

    const proveedor: Proveedor = this.proveedores.filter(proveedorInd => proveedorInd["checked"])[0];

    if(proveedor != undefined){

      let facturaRecibida: FacturasRecibidas = {
        factr_id_facturas_recibidas: undefined,
        factr_fecha_creacion: undefined,
        factr_fecha_modificacion: undefined,
        factr_estado: 'Activo',
        factr_proveedor: proveedor.prov_id_crm,
        factr_numero_factura: undefined,
        factr_fecha_factura: undefined,
        factr_fecha_recepcion: undefined,
        factr_fecha_pago: undefined,
        factr_base_imponible: 0,
        factr_base_imponible_al_0: 0,
        factr_base_imponible_al_4: 0,
        factr_base_imponible_al_10: 0,
        factr_base_imponible_al_21: 0,
        factr_total_iva: 0,
        factr_total_iva_al_4: 0,
        factr_total_iva_al_10: 0,
        factr_total_iva_al_21: 0,
        factr_total_retencion: 0,
        factr_total_factura: 0,
        factr_estado_factura: 'EST-001',
        factr_observaciones: undefined,
        factr_pago: undefined,
        factr_contabilidad: undefined,
        factr_fecha_prevision_de_pago: undefined,
        factr_dias_desde_recepcion_a_factura: 0,
        factr_dias_desde_pago_a_factura: 0,
        factr_dias_desde_prevision_a_factura: 0,
        factr_motivo_denegada: undefined,
        factr_usuario_creador: this.sessionService.getCurrentSession().user.id.toString(),
        factr_unidad_fiscal: 'UNIF-00001',
      }
  
      this.facturacionService.crearFacturaRecibida(facturaRecibida).subscribe({
        next: (data: FacturaRecibidaData) => {
          this.router.navigate(['incluir-factura'], {
            queryParams: {
              factura: data.data.factr_id_facturas_recibidas
            }
          });
        },
        error: (error: any) => {
          console.log(error);
          this.snackBar.open("Error en el servidor", undefined, {
            duration: 3 * 1000,
          });
        }
      })
      
    }
    else{
      this.snackBar.open("Debe seleccionar un proveedor", undefined, {
        duration: 3 * 1000,
      });
    }
    
  }

  //------------------------ FACTURAS RECIBIDAS ------------------------------

  obtenerFacturasRecibidas(){

    this.loadingPageFlag = true;

    this.facturacionService.obtenerFacturasRecibidas().subscribe({
      next: (data: FacturasRecibidasList) => {
        this.facturasRecibidas = data.data.filter(factura => 
          new Date(factura.factr_fecha_creacion).getFullYear() === this.facturasRecibidasFecha
        );
        this.facturasRecibidasSinFecha = data.data.filter(factura => factura.factr_fecha_factura == null);
        //console.log('Facturas sin fecha', this.facturasRecibidasSinFecha);
        //console.log('Facturas con fecha', this.facturasRecibidas);

        this.facturasRecibidas.forEach(facturaRecibida => {
          facturaRecibida["checked"] = false;
        })
        this.facturasRecibidasSinFecha.forEach(facturaRecibida => {
          facturaRecibida["checked"] = false;
        })

        if(this.sinFechaFlag){
          this.dataSourceFacturasRecibidas = new MatTableDataSource(this.facturasRecibidasSinFecha);
        }
        else{
          this.dataSourceFacturasRecibidas = new MatTableDataSource(this.facturasRecibidas);
        }

        this.dataSourceFacturasRecibidas.paginator = this.facturasRecibidasPaginator;

        this.facturasRecibidasLength = this.dataSourceFacturasRecibidas.filteredData.length;

        this.loadingPageFlag = false;

        this.calculateResumenEconomico();

        //console.log(this.facturasRecibidas);
      },

      error: (err: any) => {
        this.loadingPageFlag = false;

        console.log(err);
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
      }
    });
  }

  obtenerEstadosFacturasRecibidas(){
    this.facturacionService.obtenerEstadosFacturasRecibidas().subscribe({
      next : (data: EstadosFacturasRecibidas) => {
        this.estadosFactura = data.data;
      },
      error : (err: any) => {
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
      }
    });
  }

  changeVerFecha(){
    this.sinFechaFlag = !this.sinFechaFlag;

    this.obtenerFacturasRecibidas();
  }

  changeFilterFacturasRecibidas(){
    //TODO

    console.log('llamado');

  }

  applyFilterFacturasRecibidas(filterValue: string) {

    this.changeFilterFacturasRecibidas();

    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSourceFacturasRecibidas.filter = filterValue;
  }

  selectFacturaRecibida(facturaRecibida: FacturasRecibidas){

    if(this.sinFechaFlag){
      this.facturasRecibidasSinFecha.forEach(facturaSinFechaInd => {
        if(facturaSinFechaInd.factr_id_facturas_recibidas == facturaRecibida.factr_id_facturas_recibidas){
          if(facturaSinFechaInd["checked"] == true){
            facturaSinFechaInd["checked"] = false;
          }
          else{
            facturaSinFechaInd["checked"] = true;
          }
        }
        else{
          facturaSinFechaInd["checked"] = false;
        }

      });
    }
    else{
      this.facturasRecibidas.forEach(facturaInd => {
        if(facturaInd.factr_id_facturas_recibidas == facturaRecibida.factr_id_facturas_recibidas){
          if(facturaInd["checked"] == true){
            facturaInd["checked"] = false;
          }
          else{
            facturaInd["checked"] = true;
          }
        }
        else{
          facturaInd["checked"] = false;
        }
  
      });
    }

  }

  getEstadoFacturaDescripcion(est_id_estados): string{
    if(this.estadosFactura.filter(estadoFactura => estadoFactura.est_id_estados == est_id_estados).length > 0){
      return this.estadosFactura.filter(estadoFactura => estadoFactura.est_id_estados == est_id_estados)[0].est_descripcion;
    }
    else{
      return '--';
    }
  }

  createIncidenciaFactura(){
    let facturaRecibida: FacturasRecibidas;

    facturaRecibida = this.dataSourceFacturasRecibidas.filteredData.filter(facturaRecibidaInd => facturaRecibidaInd["checked"])[0];

    if(facturaRecibida != undefined){
      if(facturaRecibida.factr_contabilidad != null){
        this.dialog.open(IncidenciaFacturaRecibidaDialogComponent, {
          data: {
            facturaRecibida: facturaRecibida
          },
        });
      }
      else{
        this.snackBar.open("La factura no ha sido contabilizada", undefined, {
          duration: 3 * 1000,
        });
      }
    }
    else{
      this.snackBar.open("Debe seleccionar una factura", undefined, {
        duration: 3 * 1000,
      });
    }

  }

  contabilizarFacturaRecibida(){
    //TODO

    const listFacturas: FacturasRecibidas[] = this.dataSourceFacturasRecibidas.filteredData.filter(factura => ['EST-001', 'EST-003', 'EST-004', 'EST-005'].includes(factura.factr_estado_factura));
    
    if(listFacturas.length > 0){
      let totalFacturas = 0;
      listFacturas.forEach(facturaRecibida => {
        totalFacturas += facturaRecibida.factr_total_factura;
      });
      console.log(totalFacturas);

      const dialogListaFact = this.dialog.open(ListaFacturasRecibidasContabilidadDialogComponent, {
        data: {
          facturasRecibidas: listFacturas,
          proveedores: this.proveedores,
          estadosFactura: this.estadosFactura,
        },
      });

      dialogListaFact.afterClosed().subscribe({
        next: (result: any) => {
          if(result != undefined){
            this.snackBar.open("Contabilidad generada", undefined, {
              duration: 3 * 1000,
            });
          }
        }
      })

    }
    else{
      this.snackBar.open("No existen facturas disponibles para generar contabilidad", undefined, {
        duration: 3 * 1000,
      });
    }

  }

  generarRemesa() {

    const listFacturas: FacturasRecibidas[] = this.dataSourceFacturasRecibidas.filteredData.filter(facturaRecibida => ['EST-001', 'EST-003', 'EST-006'].includes(facturaRecibida.factr_estado_factura));
    
    if(listFacturas.length > 0){
      let totalFacturas = 0;
      listFacturas.forEach(facturaRecibida => {
        totalFacturas += facturaRecibida.factr_total_factura;
      });
      console.log(totalFacturas);

      const dialogListaFact = this.dialog.open(ListaFacturasRemesaDialogComponent, {
        data: {
          facturasRecibidas: listFacturas,
          proveedores: this.proveedores,
          estadosFactura: this.estadosFactura,
        },
      });

      dialogListaFact.afterClosed().subscribe({
        next: (result: any) => {
          if(result != undefined){
            const dialogRemesa = this.dialog.open(RemesaDialogComponent, {
              data: {
                pagos: result.pagos,
                pagosFacturas: result.pagosFacturas,
                proveedores: result.proveedores,
                remesa: null,
                accion: DialogDataAction.crear,
              },
            });

            dialogRemesa.afterClosed().subscribe({
              next: (result: any) => {
                this.obtenerFacturasRecibidas();
              }
            })
          }
        }
      })

    }
    else{
      this.snackBar.open("No existen facturas disponibles para generar remesa", undefined, {
        duration: 3 * 1000,
      });
    }
  }

  generateExcel() {

    if(this.dataSourceFacturasRecibidas.filteredData.length > 0){

      var ws_data = [[
        'Id factura recibida',
        'Fecha creación',
        'Estado',
        'Proveedor',
        'Fecha recepción',
        'Fecha pago',
        'Base imponible',
        'Total IVA',
        'Total retención',
        'Total factura',
        'Contabilidad'
      ]];

      this.dataSourceFacturasRecibidas.filteredData.forEach(factura => {
        ws_data.push([
          factura.factr_id_facturas_recibidas,
          factura.factr_fecha_creacion.toString(),
          factura.factr_estado,
          factura.factr_proveedor,
          factura.factr_fecha_recepcion.toString(),
          factura.factr_fecha_pago != null ? factura.factr_fecha_pago.toString() : '',
          factura.factr_base_imponible.toString(),
          factura.factr_total_iva.toString(),
          factura.factr_total_retencion.toString(),
          factura.factr_total_factura.toString(),
          factura.factr_contabilidad,
        ])
      });

       /* pass here the table id */
      const ws: XLSX.WorkSheet =XLSX.utils.aoa_to_sheet(ws_data);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */  
      XLSX.writeFile(wb, "Facturas recibidas.xlsx");

    }

  }

  navigateToIncluirFactura(factura: FacturasRecibidas){
    window.open("http://"+window.location.host+window.location.pathname+'#/incluir-factura?factura='+factura.factr_id_facturas_recibidas,  '');
    // this.router.navigate(['incluir-factura'], {
    //   queryParams: {
    //     factura: factura.factr_id_facturas_recibidas,
    //   }
    // });
  }

  calculateResumenEconomico(){

    this.baseImponible =
    this.iva =
    this.irpf =
    this.totalFacturado = 0;

    this.dataSourceFacturasRecibidas.filteredData.forEach(facturaRecibida => {
      this.baseImponible += facturaRecibida.factr_base_imponible;
      this.iva += facturaRecibida.factr_total_iva;
      this.irpf += facturaRecibida.factr_total_retencion;
      this.totalFacturado += facturaRecibida.factr_total_factura;
    })
  }

  obtenerNombreProveedor(factr_proveedor: string): string{
    return this.proveedores.filter(proveedor => proveedor.prov_id_crm == factr_proveedor)[0].prov_nombre;
  }
}