<app-header-tramitador></app-header-tramitador>

<div class="progress_spinner" *ngIf="mostrarProgressSpinner">
    <mat-spinner></mat-spinner>
</div>


<div class="container-fluid">


    <div class="row mt-5">
        <div class="col-md-1">
            <label for="expediente">Buscar expediente</label>
        </div>
        <div class="col-md-3">
            <input type="text" name="nombre" id="nombre" (keyup.enter)="getDataExcel()" [(ngModel)]="numExpediente" (keydown.backspace)="onBackspace($event)" 
            (keydown.space)="onSpace($event)"  required>
            <i class="bi bi-search icono_buscar" (click)="getDataExcel()"></i>
            <span id="error">{{msgErrorInputExpediente}}</span>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-md-1">
            <label for="nombre">Nombre completo</label>
        </div>
        <div class="col-md-2">
            <input type="text" name="nombre" id="nombre" [(ngModel)]="expediente.lesionado">
        </div>
        <div class="col-md-1">
            <label for="edadLesionado">Edad Lesionado</label>
        </div>
        <div class="col-md-2">
            <input type="text" name="edadLesionado" id="edadLesionado" [(ngModel)]="expediente.edadLesionado">
        </div>
        <div class="col-md-1">
            <label for="siniestro">Siniestro</label>
        </div>
        <div class="col-md-2">
            <input type="text" name="siniestro" id="siniestro" [(ngModel)]="expediente.siniestro">
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-1">
            <label for="provincia">Provincia</label>
        </div>
        <div class="col-md-2">
            <input type="text" name="provincia" id="provincia" [(ngModel)]="expediente.provincia">
        </div>
        <div class="col-md-1">
            <label for="localidad">Localidad</label>
        </div>
        <div class="col-md-2">
            <input type="text" name="localidad" id="localidad" [(ngModel)]="expediente.localidad">
        </div>
        <div class="col-md-1">
            <label for="medicos">Médico</label>
        </div>
        <div class="col-md-2">
            <select  class="select_tarea_medicos" [(ngModel)]="idMedico" required>
                <option value="-1">--</option>
                <option *ngFor="let med of medicos" [value]="med.med_id_user">{{med.med_nombre}}</option>
            </select>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-1">
            <label for="condicion">Condición</label>
        </div>
        <div class="col-md-2">
            <input type="text" name="condicion" id="condicion" [(ngModel)]="expediente.condicion">
        </div>
        <div class="col-md-1">
            <label for="cia">Compañía</label>
        </div>
        <div class="col-md-2">
            <select  class="select_tarea_medicos" [(ngModel)]="expediente.idCia" required>
                <option value="-1">--</option>
                <option *ngFor="let cia of cias" [ngValue]="cia.cia_id_cias" [selected]="cia.cia_id_cias === expediente.idCia">{{cia.cia_nombre}}</option>
            </select>
        </div>
        <div class="col-md-1 text-center">
            <label for="fechaSiniestro">Fecha del Siniestro</label>
        </div>
        
        <div class="col-md-2">
            <input type="date" id="fechaSiniestro" [(ngModel)]="expediente.fechaSiniestro">
        </div>
        
        
    </div>

    <div class="row mt-4">
        <div class="col-md-1">
            <label for="produccion">Forma de producción</label>
        </div>
        <div class="col-md-5">
            <textarea name="produccion" id="produccion" cols="30" rows="1" style="width: 100%;" [(ngModel)]="expediente.formaProduccion">{{expediente.formaProduccion}}</textarea>
        </div>
    </div>
</div>

<!-- FAB Cancelar-->
<div *ngIf="mostrarCancel" class="fab_container cancel">
    <button mat-fab class="fab_datos" style="background-color: #dc3545;" color="white" title="Cancelar" aria-label="Example icon button with a delete icon" (click)="cancelar()">
        <mat-icon style="margin-top: -4px; color: white;">cancel</mat-icon>
    </button>
</div>

<!-- FAB Guardar-->
<div class="fab_container save">
    <button mat-fab class="fab_datos" color="white" title="En tramitacion" aria-label="Example icon button with a delete icon" (click)="guardarTarea('tramitacion')">
        <mat-icon style="margin-top: -6px;">save</mat-icon>
    </button>
</div>

<!-- FAB Enviar-->
<div class="fab_container send">
    <button mat-fab class="fab_datos" color="white" title="Enviado" aria-label="Example icon button with a delete icon" (click)="guardarTarea('enviado')">
        <mat-icon style="margin-top: -4px;">send</mat-icon>
    </button>
</div>

<div id="meet" style="display: none;">

</div>
