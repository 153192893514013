

<!--<div class="navbar navbar_secondary">
    <ul>
        <li class="pestanias">
            <a [routerLink]="['/facturador-principal']"><i class="bi bi-house-fill navbar_i"></i></a>
        </li>
        <li class="nav-item dropdown">
            <a class="pestanias dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Administración</a>
            <div class="dropdown-menu">
                <a class="dropdown-item" [routerLink]="['/unidad-negocio']">Unidad de negocio</a>
                <a class="dropdown-item" [routerLink]="['/gestionar-estado']">Gestionar estado</a>
                <a class="dropdown-item" [routerLink]="['/informes']">Informes</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" [routerLink]="['/cambios-pendientes']">Cambios pendientes</a>
            </div>
        </li>
        <li class="pestanias">
            <a [routerLink]="['/facturador-principal']">Busqueda</a>
        </li>
        <li class="nav-item dropdown">
            <a class="pestanias dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Tablas</a>
            <div class="dropdown-menu" >

                <a class="dropdown-item"  *ngFor="let tabla of tablas" [routerLink]="['/tabla']">{{tabla}}</a>
            </div>
        </li>
        <li class="pestanias">
            <a [routerLink]="['/facturador-principal']">Contabilidad</a>
        </li>
        <li class="pestanias">
            <a [routerLink]="['/facturador-principal']">Remesas</a>
        </li>
    </ul>
</div>
-->

<nav class="navbar navbar_primary">
    <span class="navbar-text navbar_primary_text">
        <h1 class="navbar_h" style="font-weight: 600;">Portal Facturacion</h1>
        <h2 class="navbar_h" style="padding-top: 6px;">{{nombreFacturador}}</h2>
    </span>
    <span>
        <img src="../../assets/img/logoUpsan.jpg" alt="" style="width: 260px;">
    </span>
</nav>
<nav class="navbar navbar_secondary">
    <ul>
        <li class="pestanias">
            <a [routerLink]="['/facturador-principal']"><i class="bi bi-house-fill navbar_i"></i></a>
        </li>
        <button mat-button [matMenuTriggerFor]="menu"><mat-icon>list</mat-icon>Administración</button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="['/unidad-negocio']">Unidad de negocio</button>
            <button mat-menu-item [routerLink]="['/gestionar-estado']">Gestionar estado</button>
            <button mat-menu-item [routerLink]="['/informes']">Informes</button>
        </mat-menu>
        <button mat-button [routerLink]="['/cambios-pendientes']">Cambios pendiente</button>
        <button mat-button [matMenuTriggerFor]="menu2"><mat-icon>list</mat-icon>Búsqueda</button>
        <mat-menu #menu2="matMenu">
            <button mat-menu-item *ngFor="let entidad of listadoEntidades" (click)="navigateToBusqueda(entidad.ent_id_entidades)">{{entidad.ent_descripcion}}</button>
        </mat-menu>
        <button mat-button [matMenuTriggerFor]="menu3" *ngIf="userType == 2"><mat-icon>list</mat-icon>Tablas</button>
        <mat-menu #menu3="matMenu">
            <button mat-menu-item *ngFor="let entidad of listadoEntidades" (click)="navigateToTablas(entidad.ent_id_entidades)">{{entidad.ent_descripcion}}</button>
        </mat-menu>
        <button mat-button [routerLink]="['/contabilidad']">Contabilidad</button>
        <button mat-button [routerLink]="['/remesas']">Remesas</button>

    </ul>
   
</nav>
  