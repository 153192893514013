import { DatePipe, formatDate } from '@angular/common';
import { AfterViewChecked, Component, ElementRef, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { BorrarArchivoDialogComponent } from '../../dialogs/borrar-archivo-dialog/borrar-archivo-dialog.component';
import { BorrarEncargoDialogComponent } from '../../dialogs/borrar-encargo-dialog/borrar-encargo-dialog.component';
import { CancelarEncargoDialogComponent } from '../../dialogs/cancelar-encargo-dialog/cancelar-encargo-dialog.component';
import { EnviarDialogComponent } from '../../dialogs/enviar-dialog/enviar-dialog.component';
import { FinalizadoDialogComponent } from '../../dialogs/finalizado-dialog/finalizado-dialog.component';
import { PendienteRevisionDialogComponent } from '../../dialogs/pendiente-revision-dialog/pendiente-revision-dialog.component';
import { ReEnviarDesdeFinalizadoDialogComponent } from '../../dialogs/re-enviar-desde-finalizado-dialog/re-enviar-desde-finalizado-dialog.component';
import { Archivo } from '../../models/archivo';
import { CamposVideoConsulta } from '../../models/campos-video-consulta';
import { Encargo } from '../../models/encargo';
import { FormatoConsulta } from '../../models/formato_consulta/formato-consulta.model';
import { Medico } from '../../models/medico';
import { Mensaje } from '../../models/mensaje';
import { Tipo } from '../../models/tipo';
import { Tramitador } from '../../models/tramitador';
import { FileService } from '../../services/file.service';
import { FuncionesPrincipalService } from '../../services/funciones-principal.service';
import { MedicoPortalService } from '../../services/medico-portal.service';
import { NotificacionService } from '../../services/notificacion.service';
import { SessionService } from '../../services/session.service';
import { TramitadorPortalService } from '../../services/tramitador-portal.service';
import { Tramitadores } from '../../models/tramitadores';
import { User } from '../../models/user';

declare var JitsiMeetExternalAPI: any;

@Component({
  selector: 'app-encargo-tramitador',
  templateUrl: './encargo-tramitador.component.html',
  styleUrls: ['./encargo-tramitador.component.scss']
})
export class EncargoTramitadorComponent implements OnInit, AfterViewChecked {

  private idEncargo;
  tramitador: Tramitador;
  mostrarProgressSpinner = true;
  textoMensaje: string;
  encargo: Encargo;
  encargoAnterior: Encargo;
  encargosAnterioresAsesoriaGraves: Encargo[];
  encargosBySiniestro: Encargo[];
  countMensajes: number;
  mensajes: Mensaje[] = [];
  mostrarCancel: boolean = false;
  mostrarBorrar: boolean = false;
  mostrarEnviar: boolean = false;
  mostrarReEnviar: boolean = false;
  mostrarPendienteRevision: boolean = false;
  mostrarFinalizado: boolean = false;
  mostrarReEnviarFinalizado: boolean = false;
  mostrarSelectTramitacion: boolean = false;
  comentario: string;
  isUrgente: boolean;
  moverEncargoFinalizado: boolean = false;
  private mensajesChecked: number[] = [];
  public archivoPegar: Archivo;
  public archivos: Observable<Archivo[]> = null;
  public imageSelect: string;
  public tipoArchivo: string = '';
  rutaSala: string;
  addClassSecuelasAntiguas: boolean = false;
  addClassSecuelasAntiguasReserva: boolean = false;
  addClassIntervencionesAntiguas: boolean = false;
  addClassIntervencionesAntiguasReserva: boolean = false;

  //Campos informe
  numExpediente: string;
  nombreMedico: string;
  numColegiado: string;
  nombreLesionado: string;
  edadLesionado: number;
  provincia: string;
  localidad: string;
  responsabilidad: string;
  peticion: string;
  fechaSiniestro: string;
  fechaLimite: string;
  refSiniestro: string;
  consideracion: string;
  conclusiones: string;
  idMedico: number;
  idTipo: number;
  medicos: Medico[];
  tipos: Tipo[];
  mecanismoProduccion: string;
  condicionLesionado: string;
  idNombreTramitador : number;
  tramitadores : Tramitadores[];
  medico : User;

  //Campos informe REHUSE CAS
  objetoInforme: string;
  profesion: string;
  situacionLaboral: string;
  bajaLaboral: string;
  fechaBajaLaboral: string;
  fechaAltaLaboral: string;
  contingencia: string;
  antecedentes: string;
  centroUrgencias: string;
  cuandoAcude: string;
  diagnosticoUrgencias: string;
  hospitalizacionUrgencias: string;
  centroRehab: string;
  medicoSeguimiento: string;
  inicioTratamiento: string;
  altaMedica: string;
  numConsultas: string;
  numSesiones: string;
  pruebasRealizadas: string;
  evolucion: string;

  //Campos informe REHUSE CAS + VIDEO
  fuenteDocumental: string;
  referenciaInforme: string;
  firmadoPor: string;
  tipoImpacto: string;
  deltaV: string;
  aceleracion: string;
  fechaVideoConsulta: string;
  mediosUsados: string;
  anamnesis: string;
  exploracionFisica: string;
  id_formato_consulta: number;
  formatoConsulta : FormatoConsulta[];


  //Campos VALORACIÓN VIDEO-CONSULTA
  pruebasRealizadasVideoConsulta: string;
  tratamientoRehabilitador: string;
  intervencionesQuirurgicas: string;
  diasBasicos: string;
  diasModerados: string;
  diasGraves: string;
  diasMuyGraves: string;
  diasTotales: string;
  diasBasicosMax: string;
  diasModeradosMax: string;
  diasGravesMax: string;
  diasMuyGravesMax: string;
  diasTotalesMax: string;
  descripcionPerjuicioEstetico: string;
  puntosPerjuicioEstetico: string;
  puntosPerjuicioEsteticoMax: string;
  perjuicioMoral: string = "No";
  grado: string;
  porcentajeGrado: string;
  perjuicioPersonal: string = "No";
  grupoQuirurgico: string;
  otrosCorrectores: string = "No";
  observaciones: string;
  observacionesIMD: string;
  secuelaForm: FormGroup;
  secuelaReservaForm: FormGroup;
  intervencionForm: FormGroup;
  intervencionReservaForm: FormGroup;
  secuelaHorquillaForm: FormGroup;
  secuelaObj;
  secuelaReservaObj;
  intervencionObj;
  intervencionReservaObj;
  numSecuelas: number;
  numSecuelasReserva: number;
  numIntervenciones: number;
  numIntervencionesReserva: number;
  puntosTotalesSecuelas: number = 0;
  puntosTotalesSecuelasReserva: number = 0;
  puntosTotalesIntervenciones: number = 0;
  puntosTotalesIntervencionesReserva: number = 0;
  puntosMinTotalesSecuelas: number = 0;
  puntosMaxTotalesSecuelas: number = 0;
  observacionesAseguradora: string;
  proximaRevision: string;



  //Modelo estudio documental
  xDanoMoral: boolean;
  xDanoMoralEstetico: boolean;
  xPerjuicioMoral: boolean;
  xPerjuicioExcepcional: boolean;
  xGastosPrevisibles: boolean;
  xProtesisOrtesis: boolean;
  xRehabilitacion: boolean;
  xAyudasTecnicas: boolean;
  xAdecuacion: boolean;
  xPerjuicioPatrimonial: boolean;
  xHorasNecesarias: boolean;
  horasNecesarias: string;
  valoracionFinal: boolean = false;
  valoracionProv: boolean = false;
  imd: boolean = true;

  //reserva técnica
  reservaTecnica : boolean = false;
  dias_ppp_basicos_reserva : string;
  dias_ppp_moderados_reserva : string;
  dias_ppp_graves_reserva : string;
  dias_ppp_muy_graves_reserva : string;
  dias_ppp_totales_reserva : string;
  secuelas_reserva : string;
  descripcion_perjuicio_estetico_reserva : string;
  puntos_perjuicio_estetico_reserva : string;
  perjuicio_moral_reserva : string;
  perjuicio_personal_reserva : string;
  grado_reserva : string;
  porcentaje_reserva : string;
  x_dano_moral_reserva : boolean;
  x_dano_moral_estetico_reserva : boolean;
  x_perjuicio_moral_reserva : boolean;
  x_perjuicio_excepcional_reserva : boolean;
  x_gastos_previsibles_reserva : boolean ;
  x_protesis_ortesis_reserva : boolean;
  x_rehabilitacion_reserva : boolean;
  x_ayudas_tecnicas_reserva : boolean;
  x_adecuacion_reserva : boolean;
  x_perjuicio_patrimonial_reserva : boolean;
  x_horas_necesarias_reserva : boolean;
  otros_correctores_reserva : string;
  horas_necesarias_reserva : string;
  observaciones_reserva : string;

  //Seguimiento Diversos
  evolutivoConsultasValoracion: string;
  fechaProximaConsulta: string;

  //Seguimiento No Auto
  situacionIncapacidad : string;
  tiempoRecuperacion : number;

  //Asesoria graves
  numInforme: string;
  fechaAsesoria: string;
  asesorMedico: string;
  observacionesAsesoria: string;

  fechaConsultaVideoConsulta: string;
  horaConsultaVideoConsulta: string;

  fechaLimiteFormmated : string;

  camposVideoConsultaAnteriores: CamposVideoConsulta[];

  idIntervalGuardado;

  existeDocMedico: boolean = true;

  dpFechaVideoConsulta  = new FormControl(null);
  dpFechaPruebaBio      = new FormControl(null);
  dpFechaPruebaTermo    = new FormControl(null);
  dpFechaUltimaConsulta = new FormControl(null);


  rutaCarpetaDocMedico : string;
  archivosCarpetaDocMedico: Archivo[];

  private getEncargoEjecutado = false;

  //informe coherencia
  diagnosticoInicial : string;
  determinacion : string;
  xTMCC : boolean;
  xPatoColumna : boolean;
  xEnfermedades : boolean;
  xBajaMutua : boolean;
  xBioFuncional : boolean;
  xPruebaConsistente : boolean;
  valorNFHAS : string;
  valorDeltaV : string;
  xResultadoPrueba : boolean;
  xCoherenteBio : boolean;
  xTermoRealizada : boolean;
  xIndicadorCoherencia : boolean;
  xResultPruebaBio : boolean;
  xCoherenteTermo : boolean;
  resultadoEco : string;
  zonaAnalizada : string;
  medicoConsulta : Medico;
  condicionesRehuse : boolean;



  constructor(private el: ElementRef, 
    private route: ActivatedRoute, 
    private medicoService: MedicoPortalService,
    private tramitadorService: TramitadorPortalService,
    private funcionesService: FuncionesPrincipalService, 
    private notificacion: NotificacionService, 
    public fileService: FileService, 
    private router: Router, 
    private dialog: MatDialog, 
    private sessionService: SessionService,
    private datePipe: DatePipe,
    private fb:FormBuilder) {

      this.secuelaForm = this.fb.group({
        secuelas: this.fb.array([])
      });

      this.secuelaReservaForm = this.fb.group({
        secuelas: this.fb.array([])
      });

      

      this.intervencionForm = this.fb.group({
        intervenciones: this.fb.array([])
      });

      this.intervencionReservaForm = this.fb.group({
        intervenciones: this.fb.array([])
      });

      this.secuelaHorquillaForm = this.fb.group({
        secuelas: this.fb.array([])
      });

      this.encargo = new Encargo();
      this.tramitador = this.sessionService.getCurrentSession().tramitador;
      //Para poder refrescar cambios en la pagina cuando navego desde ella misma, ej cambio de encargo en el historico siniestro
      this.route.params.subscribe(params =>{
        this.idEncargo = params['idEncargo'];
        this.idTipo    = params['idTipo'];
        //this.ngOnInit();
      });
  }

  //Bloquear todos los campos cuando esten en finalizado
  ngAfterViewChecked(): void {
    if(this.encargo.enup_id_estado_encargo == 4){
      let inputDisable = document.querySelectorAll('input');
      let textareaDisable = document.querySelectorAll('textarea');
  
      inputDisable.forEach(element => {
        element.disabled = true;
      });
  
      textareaDisable.forEach(element => {
        //No desabilitar el text area del dialog para reenviar desde finalizado a enviado
        if(element.id == "dialogReEnviarFinalizado"){
          element.disabled = false;
        }else{
          element.disabled = true;
        }
      });
    }

    if (!this.encargo.enup_encargo_anterior && this.encargoAnterior && this.encargo.enup_id_estado_encargo === 1) {
      this.rellenarCamposInformeEncargoAnterior();
      this.getEncargoEjecutado = true;
    }

  }


  ngOnInit(): void {
    this.mostrarProgressSpinner = true;
    this.archivos = null;
    //Inicio explorador de archivos
    this.fileService.init();

    //Me traigo los medicos y estados para rellenar el select que wsolo aparece en estado tramitacion
    this.getMedicosTiposTramitacion();
    this.getFormatoConsulta();
    this.getTramitadores();

    this.getEncargo();

    

    this.getMensajesEncargo();

    //Cuando haya algun cambio en las secuelas para mostrar el total de puntos
    this.secuelaForm.get("secuelas").valueChanges.subscribe(selectedValue =>{
      this.totalPuntosSecuelas();
    });



    //Cuando haya algun cambio en las secuelas para mostrar el total de puntos
    this.secuelaReservaForm.get("secuelas").valueChanges.subscribe(selectedValue =>{
      this.totalPuntosSecuelasReserva();
    });

    //Cuando haya algun cambio en las secuelas para mostrar el total de puntos
    this.secuelaHorquillaForm.get("secuelas").valueChanges.subscribe(selectedValue =>{
      this.totalPuntosSecuelasMin();
      this.totalPuntosSecuelasMax();
    });

  }

  





  getFormatoConsulta() {
    this.tramitadorService.getFormatoConsulta().subscribe({
      next: (data: any) => {
        console.log(data);
        this.formatoConsulta = data['data'];
      },
      error: (err: any) => {
        console.log(err);
      },
      
    });
  }

  getTramitadores() {
    this.tramitadorService.getTramitadores().subscribe({
      next: (data: any) => {
        this.tramitadores = data['data'];
      },
      error: (err: any) => {
        console.log(err);
      },
      
    });
  }

  ngOnDestroy(): void{
    //Eliminar guardado automatico
    if(this.idIntervalGuardado){
      clearInterval(this.idIntervalGuardado);
    }
  }

  //Guardado automatico
  guardadoAutomatico(){
    this.idIntervalGuardado = setInterval(()=>{
      this.guardarDatosEncargo("automatico");
    }, 15000);
  }

  secuelas(): FormArray{
    return this.secuelaForm.get("secuelas") as FormArray;
  }



  secuelasReserva(): FormArray{
    return this.secuelaReservaForm.get("secuelas") as FormArray;
  }

  intervenciones(): FormArray{
    return this.intervencionForm.get("intervenciones") as FormArray;
  }

  intervencionesReserva(): FormArray{
    return this.intervencionReservaForm.get("intervenciones") as FormArray;
  }

  nuevaSecuela(): FormGroup {  
    return this.fb.group({  
      codigo: '',  
      descripcion: '',
      puntos: '',
    });
  }  



  nuevaIntervencion(): FormGroup {  
    return this.fb.group({  
      grupo: '',  
      descripcion: '',
    });
  }  

  addNuevasSecuelas(){
    this.secuelas().push(this.nuevaSecuela());
    this.addClassSecuelasAntiguas = false;
  }



  addNuevasSecuelasReserva(){
    this.secuelasReserva().push(this.nuevaSecuela());
    this.addClassSecuelasAntiguasReserva = false;
  }

  addNuevasIntervenciones(){
    this.intervenciones().push(this.nuevaIntervencion());
    this.addClassIntervencionesAntiguas = false;
  }

  addNuevasIntervencionesReserva(){
    this.intervencionesReserva().push(this.nuevaIntervencion());
    this.addClassIntervencionesAntiguas = false;
  }

  quitarSecuela(i:number){
    this.secuelas().removeAt(i);
    this.addClassSecuelasAntiguas = false;
  }



  quitarSecuelaReserva(i:number){
    this.secuelasReserva().removeAt(i);
    this.addClassSecuelasAntiguasReserva = false;
  }

  quitarIntervencion(i:number){
    this.intervenciones().removeAt(i);
    this.addClassIntervencionesAntiguas = false;
  }

  quitarIntervencionReserva(i:number){
    this.intervencionesReserva().removeAt(i);
    this.addClassIntervencionesAntiguasReserva = false;
  }

  secuelasHorquilla(): FormArray{
    return this.secuelaHorquillaForm.get("secuelas") as FormArray;
  }

  nuevaSecuelaHorquilla(): FormGroup {  
    return this.fb.group({  
      codigo: '',  
      descripcion: '',
      puntosMin: '',
      puntosMax: '',
    });
  }  

  addNuevasSecuelasHorquilla(){
    this.secuelasHorquilla().push(this.nuevaSecuelaHorquilla());
    this.addClassSecuelasAntiguas = false;
  }

  quitarSecuelaHorquilla(i:number){
    this.secuelasHorquilla().removeAt(i);
    this.addClassSecuelasAntiguas = false;
  }

  sumarDiasPPP(){
    let total : number;
    total = parseInt(this.diasBasicos) + parseInt(this.diasModerados) + parseInt(this.diasGraves) + parseInt(this.diasMuyGraves);
    this.diasTotales = total.toString();
  }

  sumarDiasPPPMax(){
    let total : number;
    total = parseInt(this.diasBasicosMax) + parseInt(this.diasModeradosMax) + parseInt(this.diasGravesMax) + parseInt(this.diasMuyGravesMax);
    this.diasTotalesMax = total.toString();
  }

  getArchivosExplorador(){
    let datos = {
      'numExpediente': this.encargo.enup_expediente,
      'idTipoEncargo': this.encargo.enup_id_tipo_encargo,
      'idEstado': this.encargo.enup_id_estado_encargo,
      'idCia': this.encargo.enup_cia
    }; 

    //Me traigo los archivos para el explorador
    this.tramitadorService.getArchivosExploradorTramitador(datos).subscribe(
      data => {
        if(data['response'] == "ok"){
          this.fileService.loadMap(data['archivos']);
          this.archivos = observableOf(data['archivos']);
          this.updateFileElementQuery();
        }else if(data['response'] == "fail"){
          this.notificacion.notificationFail(data['mensaje']);
        }else{
          this.notificacion.notificationFail("Error");
        }
        
      }, error => {
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  rellenarCamposInforme(){
    this.numExpediente = this.encargo.enup_expediente;
    //TODO No se si el nombre y el numero de colegiado del medico estaran vacios para el tramitador y los campos desabilitados
    this.nombreMedico = this.encargo.nombreMedico;
    //Si esta en tramitacion seleccionar el medico del select y el tipo
    this.idMedico = this.encargo.enup_id_medico;
    this.idTipo = this.encargo.enup_id_tipo_encargo;
    this.idNombreTramitador = this.encargo.enup_id_nombre_tramitador;

    this.numColegiado = this.encargo.numColegiadoMedico;
    this.nombreLesionado = this.encargo.enup_lesionado;
    this.edadLesionado = this.encargo.enup_edad_lesionado;
    this.provincia = this.encargo.enup_provincias;
    this.localidad = this.encargo.enup_localidad;
    this.responsabilidad = this.encargo.enup_responsabilidad_aseguradora;
    this.peticion = this.encargo.enup_peticion;
    this.fechaSiniestro = this.encargo.enup_fecha_siniestro;
    this.fechaLimite = this.encargo.enup_fecha_limite;
    this.refSiniestro = this.encargo.enup_referencia_siniestro;
    this.profesion = this.encargo.enup_profesion;
    this.objetoInforme = this.encargo.enup_objeto_informe;
    this.situacionLaboral = this.encargo.enup_situacion_laboral;
    this.bajaLaboral = this.encargo.enup_baja_laboral;
    this.fechaBajaLaboral = this.encargo.enup_fecha_baja_laboral;
    this.fechaAltaLaboral = this.encargo.enup_fecha_alta_laboral;
    this.contingencia = this.encargo.enup_contingencia;
    this.antecedentes = this.encargo.enup_antecedentes_personales;
    this.centroUrgencias = this.encargo.enup_centro_urgencias;
    this.cuandoAcude = this.encargo.enup_cuando_acude;
    this.diagnosticoUrgencias = this.encargo.enup_diagnostico_urgencias;
    this.hospitalizacionUrgencias = this.encargo.enup_hospitalizacion_urgencias;
    this.centroRehab = this.encargo.enup_centro_rehabilitacion;
    this.medicoSeguimiento = this.encargo.enup_medico_seguimiento;
    this.inicioTratamiento = this.encargo.enup_inicio_tratamiento;
    this.altaMedica = this.encargo.enup_alta_medica;
    this.numConsultas = this.encargo.enup_num_consultas;
    this.numSesiones = this.encargo.enup_num_sesiones;
    this.pruebasRealizadas = this.encargo.enup_pruebas_realizadas;
    this.evolucion = this.encargo.enup_evolucion;
    this.fuenteDocumental = this.encargo.enup_fuente_documental;
    this.referenciaInforme = this.encargo.enup_num_referencia_informe;
    this.firmadoPor = this.encargo.enup_firmado_por;
    this.tipoImpacto = this.encargo.enup_tipo_impacto;
    this.deltaV = this.encargo.enup_delta_v;
    this.aceleracion = this.encargo.enup_aceleracion;
    //this.fechaVideoConsulta = this.encargo.enup_fecha_video_consulta;
    this.dpFechaVideoConsulta = new FormControl(this.encargo.enup_fecha_video_consulta == null ? null : this.dateStringtoDate(this.encargo.enup_fecha_video_consulta));
    this.dpFechaPruebaBio = new FormControl(this.encargo.enup_fecha_prueba_bio == null ? null : this.dateStringtoDate(this.encargo.enup_fecha_prueba_bio));
    this.dpFechaPruebaTermo = new FormControl(this.encargo.enup_fecha_prueba_termo == null ? null : this.dateStringtoDate(this.encargo.enup_fecha_prueba_termo));
    this.dpFechaUltimaConsulta = new FormControl(this.encargo.enup_fecha_ultima_consulta == null ? null : this.dateStringtoDate(this.encargo.enup_fecha_ultima_consulta));
    this.mediosUsados = this.encargo.enup_medios_usados;
    this.anamnesis = this.encargo.enup_anamnesis == null ? "Lesionado refiere " : this.encargo.enup_anamnesis;
    this.exploracionFisica = this.encargo.enup_exploracion_fisica == null ? "(Movimientos realizados por el propio lesionado) " : this.encargo.enup_exploracion_fisica;
    this.consideracion = this.encargo.enup_consideracion;
    this.conclusiones = this.encargo.enup_conclusiones;
    this.isUrgente = this.encargo.enup_prioridad == 1 ? true : false;
    this.pruebasRealizadasVideoConsulta = this.encargo.enup_pruebas_realizadas_video_consulta;
    this.tratamientoRehabilitador = this.encargo.enup_tratamiento_rehabilitador == null ? "No ha realizado fisioterapia.\n\tCentro: \n\t\tInicio:\n\t\tFin:\n\t\tSesiones realizadas:" : this.encargo.enup_tratamiento_rehabilitador;
    this.intervencionesQuirurgicas = this.encargo.enup_intervenciones_quirurgicas;
    this.diasBasicos = this.encargo.enup_dias_ppp_basicos == null ? "0" : this.encargo.enup_dias_ppp_basicos;
    this.diasModerados = this.encargo.enup_dias_ppp_moderados == null ? "0" : this.encargo.enup_dias_ppp_moderados;
    this.diasGraves = this.encargo.enup_dias_ppp_graves == null ? "0" : this.encargo.enup_dias_ppp_graves;
    this.diasMuyGraves = this.encargo.enup_dias_ppp_muy_graves == null ? "0" : this.encargo.enup_dias_ppp_muy_graves;
    this.diasTotales = this.encargo.enup_dias_ppp_totales == null ? "0" : this.encargo.enup_dias_ppp_totales;
    this.diasBasicosMax = this.encargo.enup_dias_ppp_basicos_max == null ? "0" : this.encargo.enup_dias_ppp_basicos_max;
    this.diasModeradosMax = this.encargo.enup_dias_ppp_moderados_max == null ? "0" : this.encargo.enup_dias_ppp_moderados_max;
    this.diasGravesMax = this.encargo.enup_dias_ppp_graves_max == null ? "0" : this.encargo.enup_dias_ppp_graves_max;
    this.diasMuyGravesMax = this.encargo.enup_dias_ppp_muy_graves_max == null ? "0" : this.encargo.enup_dias_ppp_muy_graves_max;
    this.diasTotalesMax = this.encargo.enup_dias_ppp_totales_max == null ? "0" : this.encargo.enup_dias_ppp_totales_max;
    this.perjuicioMoral = this.encargo.enup_perjuicio_moral == null ? "No" : this.encargo.enup_perjuicio_moral;
    this.grado = this.encargo.enup_grado;
    this.porcentajeGrado = this.encargo.enup_porcentaje;
    this.perjuicioPersonal = this.encargo.enup_perjuicio_personal == null ? "No" : this.encargo.enup_perjuicio_personal;
    this.grupoQuirurgico = this.encargo.enup_grupo_quirurgico;
    this.otrosCorrectores = this.encargo.enup_otros_correctores == null ? "No" : this.encargo.enup_otros_correctores;
    this.observaciones = this.encargo.enup_observaciones;
    this.observacionesIMD = this.encargo.enup_observaciones_imd;
    this.observacionesAseguradora = this.encargo.enup_observaciones_aseguradora;
    this.proximaRevision = this.encargo.enup_proxima_revision;
    this.descripcionPerjuicioEstetico = this.encargo.enup_descripcion_perjuicio_estetico;
    this.puntosPerjuicioEstetico = this.encargo.enup_puntos_perjuicio_estetico;
    this.puntosPerjuicioEsteticoMax = this.encargo.enup_puntos_perjuicio_estetico_max;
    this.evolutivoConsultasValoracion = this.encargo.enup_evolutivo_consultas_valoracion;
    this.fechaProximaConsulta = this.encargo.enup_fecha_proxima_consulta;
    this.fechaConsultaVideoConsulta = this.encargo.enup_fecha_consulta_video;
    this.horaConsultaVideoConsulta = this.encargo.enup_hora_consulta_video;
    this.mecanismoProduccion = this.encargo.enup_mecanismo_produccion;
    this.rutaSala = this.encargo.enup_url_videoconferencia;
    this.condicionLesionado = this.encargo.enup_condicion_lesionado;
    this.numInforme = this.encargo.enup_num_informe;
    this.fechaAsesoria = this.encargo.enup_fecha_asesoria;
    this.asesorMedico = this.encargo.enup_asesor_medico;
    this.observacionesAsesoria = this.encargo.enup_observaciones_asesoria;
    this.id_formato_consulta = this.encargo.formato_consulta;
    this.xDanoMoral                   = (this.otrosCorrectores == "No" || this.otrosCorrectores == null) ? false : this.encargo.enup_x_dano_moral;
    this.xDanoMoralEstetico           = (this.otrosCorrectores == "No" || this.otrosCorrectores == null) ? false : this.encargo.enup_x_dano_moral_estetico;
    this.xPerjuicioMoral              = (this.otrosCorrectores == "No" || this.otrosCorrectores == null) ? false : this.encargo.enup_x_perjuicio_moral;
    this.xPerjuicioExcepcional        = (this.otrosCorrectores == "No" || this.otrosCorrectores == null) ? false : this.encargo.enup_x_perjuicio_excepcional;
    this.xGastosPrevisibles           = (this.otrosCorrectores == "No" || this.otrosCorrectores == null) ? false : this.encargo.enup_x_gastos_previsibles;
    this.xProtesisOrtesis             = (this.otrosCorrectores == "No" || this.otrosCorrectores == null) ? false : this.encargo.enup_x_protesis_ortesis;
    this.xRehabilitacion              = (this.otrosCorrectores == "No" || this.otrosCorrectores == null) ? false : this.encargo.enup_x_rehabilitacion;
    this.xAyudasTecnicas              = (this.otrosCorrectores == "No" || this.otrosCorrectores == null) ? false : this.encargo.enup_x_ayudas_tecnicas;
    this.xAdecuacion                  = (this.otrosCorrectores == "No" || this.otrosCorrectores == null) ? false : this.encargo.enup_x_adecuacion;
    this.xPerjuicioPatrimonial        = (this.otrosCorrectores == "No" || this.otrosCorrectores == null) ? false : this.encargo.enup_x_perjuicio_patrimonial;
    this.xHorasNecesarias             = (this.otrosCorrectores == "No" || this.otrosCorrectores == null) ? false : this.encargo.enup_x_horas_necesarias;
    this.horasNecesarias              = (this.otrosCorrectores == "No" || this.otrosCorrectores == null || !this.xHorasNecesarias) ? null : this.encargo.enup_horas_necesarias;
    this.valoracionFinal              = this.encargo.enup_valoracion_final;
    this.valoracionProv               = this.encargo.enup_valoracion_prov;
    this.imd                          = this.encargo.enup_valoracion_final ? this.encargo.enup_imd : null;

    this.reservaTecnica                                 = this.encargo.enup_reserva_tecnica;
    this.dias_ppp_basicos_reserva                       = this.encargo.enup_dias_ppp_basicos_reserva;
    this.dias_ppp_moderados_reserva                     = this.encargo.enup_dias_ppp_moderados_reserva;
    this.dias_ppp_graves_reserva                        = this.encargo.enup_dias_ppp_graves_reserva;
    this.dias_ppp_muy_graves_reserva                    = this.encargo.enup_dias_ppp_muy_graves_reserva;
    this.dias_ppp_totales_reserva                       = this.encargo.enup_dias_ppp_totales_reserva;
    this.secuelas_reserva                               = this.encargo.enup_secuelas_reserva;
    this.descripcion_perjuicio_estetico_reserva         = this.encargo.enup_descripcion_perjuicio_estetico_reserva;
    this.puntos_perjuicio_estetico_reserva              = this.encargo.enup_puntos_perjuicio_estetico_reserva;
    this.perjuicio_moral_reserva                        = this.encargo.enup_perjuicio_moral_reserva;
    this.perjuicio_personal_reserva                     = this.encargo.enup_perjuicio_personal_reserva;
    this.grado_reserva                                  = this.encargo.enup_grado_reserva;
    this.porcentaje_reserva                             = this.encargo.enup_porcentaje_reserva;
    this.x_dano_moral_reserva                           = this.encargo.enup_x_dano_moral_reserva;
    this.x_dano_moral_estetico_reserva                  = this.encargo.enup_x_dano_moral_estetico_reserva;
    this.x_perjuicio_excepcional_reserva                = this.encargo.enup_x_perjuicio_excepcional_reserva;
    this.x_gastos_previsibles_reserva                   = this.encargo.enup_x_gastos_previsibles_reserva ;
    this.x_protesis_ortesis_reserva                     = this.encargo.enup_x_protesis_ortesis_reserva;
    this.x_rehabilitacion_reserva                       = this.encargo.enup_x_rehabilitacion_reserva;
    this.x_ayudas_tecnicas_reserva                      = this.encargo.enup_x_ayudas_tecnicas_reserva;
    this.x_adecuacion_reserva                           = this.encargo.enup_x_adecuacion_reserva;
    this.x_perjuicio_patrimonial_reserva                = this.encargo.enup_x_perjuicio_patrimonial_reserva;
    this.x_horas_necesarias_reserva                     = this.encargo.enup_x_horas_necesarias_reserva;
    this.otros_correctores_reserva                      = this.encargo.enup_otros_correctores_reserva;
    this.horas_necesarias_reserva                       = this.encargo.enup_horas_necesarias_reserva;
    this.observaciones_reserva                          = this.encargo.enup_observaciones_reserva;

    this.situacionIncapacidad                           = this.encargo.enup_situacion_incapacidad;
    this.tiempoRecuperacion                             = this.encargo.enup_tiempo_recuperacion;

    //INFORME COHERENCIA
    this.diagnosticoInicial = this.encargo.enup_diagnostico_inicial;
    this.determinacion = this.encargo.enup_determinacion;
    this.resultadoEco = this.encargo.enup_resultado_eco;
    this.zonaAnalizada = this.encargo.enup_zona_analizada;
    this.xTMCC = this.encargo.enup_tmcc;
    this.xPatoColumna = this.encargo.enup_pato_columna;
    this.xEnfermedades = this.encargo.enup_enfermedades_sistemicas;
    this.xBajaMutua = this.encargo.enup_baja_mutua;
    this.xBioFuncional = this.encargo.enup_bio_funcional;
    this.xPruebaConsistente = this.encargo.enup_prueba_consistente;
    this.valorNFHAS = this.encargo.enup_nfhas;
    this.valorDeltaV = this.encargo.enup_valor_deltaT;
    this.xResultadoPrueba = this.encargo.enup_resultado_prueba_termo;
    this.xCoherenteBio = this.encargo.enup_coherente_bio;
    this.xTermoRealizada = this.encargo.enup_termo_realizada;
    this.xIndicadorCoherencia = this.encargo.enup_indicador_coherencia;
    this.xResultPruebaBio = this.encargo.enup_resultado_prueba_bio;
    this.xCoherenteTermo = this.encargo.enup_coherente_termo;
    this.condicionesRehuse = this.encargo.enup_condiciones_rehuse;


    //rellenar campo dinamico secuelas
    //Si es VALORACIÓN VIDEO-CONSULTA
    //if(this.encargo.enup_id_tipo_encargo == 5 || this.encargo.enup_id_tipo_encargo == 8){
      this.secuelaObj = JSON.parse(this.encargo.enup_secuelas == null ? '{"secuelas":[]}': this.encargo.enup_secuelas);
      this.secuelaObj.secuelas.forEach(secuela =>{
        this.secuelas().push(this.fb.group(secuela));
      });
      this.numSecuelas = this.secuelas().length;
      //Si hay secuelas sumar puntos
      if(this.secuelas().length > 0){
        this.totalPuntosSecuelas();
      }

      

      this.secuelaReservaObj = JSON.parse(this.encargo.enup_secuelas_reserva == null ? '{"secuelas":[]}': this.encargo.enup_secuelas_reserva);
      this.secuelaReservaObj.secuelas.forEach(secuela =>{
        this.secuelasReserva().push(this.fb.group(secuela));
      });
      this.numSecuelasReserva = this.secuelasReserva().length;
      //Si hay secuelas sumar puntos
      if(this.secuelasReserva().length > 0){
        this.totalPuntosSecuelasReserva();
      }

      this.intervencionObj = JSON.parse(this.encargo.enup_intervenciones == null ? '{"intervenciones":[]}': this.encargo.enup_intervenciones);
      this.intervencionObj.intervenciones.forEach(intervencion =>{
        this.intervenciones().push(this.fb.group(intervencion));
      });
      this.numIntervenciones = this.intervenciones().length;

      this.intervencionReservaObj = JSON.parse(this.encargo.enup_intervenciones_reserva == null ? '{"intervenciones":[]}': this.encargo.enup_intervenciones_reserva);
      this.intervencionReservaObj.intervenciones.forEach(intervencion =>{
        this.intervencionesReserva().push(this.fb.group(intervencion));
      });
      this.numIntervencionesReserva = this.intervencionesReserva().length;

    //}
    
    //Si es Valoracion Base Documental
    /*if(this.encargo.enup_id_tipo_encargo == 6 || this.encargo.enup_id_tipo_encargo == 7 || this.encargo.enup_id_tipo_encargo == 10){
      this.secuelaObj = JSON.parse(this.encargo.enup_secuelas == null ? '{"secuelas":[]}': this.encargo.enup_secuelas);
      this.secuelaObj.secuelas.forEach(secuela =>{
        this.secuelasHorquilla().push(this.fb.group(secuela));
      });
      this.numSecuelas = this.secuelasHorquilla().length;
      console.log("Secula horquilla", this.secuelasHorquilla());
      //Si hay secuelas sumar puntos
      if(this.secuelasHorquilla().length > 0){
        this.totalPuntosSecuelasMin();
        this.totalPuntosSecuelasMax();
      }
    }*/
    console.log(this.secuelaForm.value);
  }

  rellenarCamposInformeEncargoAnterior(){ 
    this.responsabilidad                          == null ? this.encargoAnterior.enup_responsabilidad_aseguradora             != null ? (this.responsabilidad                         = this.encargoAnterior.enup_responsabilidad_aseguradora,              this.addClassAnterior('responsabilidad')) : null : null;
    this.peticion                                 == null ? this.encargoAnterior.enup_peticion                                != null ? (this.peticion                                = this.encargoAnterior.enup_peticion,                                 this.addClassAnterior('peticion')) : null : null;
    this.objetoInforme                            == null ? this.encargoAnterior.enup_objeto_informe                          != null ? (this.objetoInforme                           = this.encargoAnterior.enup_objeto_informe,                           this.addClassAnterior('objetoInforme')) : null : null;
    this.profesion                                == null ? this.encargoAnterior.enup_profesion                               != null ? (this.profesion                               = this.encargoAnterior.enup_profesion,                                this.addClassAnterior('profesion')) : null : null;
    this.situacionLaboral                         == null ? this.encargoAnterior.enup_situacion_laboral                       != null ? (this.situacionLaboral                        = this.encargoAnterior.enup_situacion_laboral,                        this.addClassAnterior('situacionLaboral')) : null : null;
    this.bajaLaboral                              == null ? this.encargoAnterior.enup_baja_laboral                            != null ? (this.bajaLaboral                             = this.encargoAnterior.enup_baja_laboral,                             this.addClassAnterior('bajaLaboral')) : null : null;
    this.contingencia                             == null ? this.encargoAnterior.enup_contingencia                            != null ? (this.contingencia                            = this.encargoAnterior.enup_contingencia,                             this.addClassAnterior('contingencia')) : null : null;
    this.antecedentes                             == null ? this.encargoAnterior.enup_antecedentes_personales                 != null ? (this.antecedentes                            = this.encargoAnterior.enup_antecedentes_personales,                  this.addClassAnterior('antecedentes')) : null : null;
    this.centroUrgencias                          == null ? this.encargoAnterior.enup_centro_urgencias                        != null ? (this.centroUrgencias                         = this.encargoAnterior.enup_centro_urgencias,                         this.addClassAnterior('centroUrgencias')) : null : null;
    this.cuandoAcude                              == null ? this.encargoAnterior.enup_cuando_acude                            != null ? (this.cuandoAcude                             = this.encargoAnterior.enup_cuando_acude,                             this.addClassAnterior('cuandoAcude')) : null : null;
    this.diagnosticoUrgencias                     == null ? this.encargoAnterior.enup_diagnostico_urgencias                   != null ? (this.diagnosticoUrgencias                    = this.encargoAnterior.enup_diagnostico_urgencias,                    this.addClassAnterior('diagnosticoUrgencias')) : null : null;
    this.centroRehab                              == null ? this.encargoAnterior.enup_centro_rehabilitacion                   != null ? (this.centroRehab                             = this.encargoAnterior.enup_centro_rehabilitacion,                    this.addClassAnterior('centroRehab')) : null : null;
    this.medicoSeguimiento                        == null ? this.encargoAnterior.enup_medico_seguimiento                      != null ? (this.medicoSeguimiento                       = this.encargoAnterior.enup_medico_seguimiento,                       this.addClassAnterior('medicoSeguimiento')) : null : null;
    this.inicioTratamiento                        == null ? this.encargoAnterior.enup_inicio_tratamiento                      != null ? (this.inicioTratamiento                       = this.encargoAnterior.enup_inicio_tratamiento,                       this.addClassAnterior('inicioTratamiento')) : null : null;
    this.altaMedica                               == null ? this.encargoAnterior.enup_alta_medica                             != null ? (this.altaMedica                              = this.encargoAnterior.enup_alta_medica,                              this.addClassAnterior('altaMedica')) : null : null;
    this.numConsultas                             == null ? this.encargoAnterior.enup_num_consultas                           != null ? (this.numConsultas                            = this.encargoAnterior.enup_num_consultas,                            this.addClassAnterior('numConsultas')) : null : null;
    this.numSesiones                              == null ? this.encargoAnterior.enup_num_sesiones                            != null ? (this.numSesiones                             = this.encargoAnterior.enup_num_sesiones,                             this.addClassAnterior('numSesiones')) : null : null;
    this.pruebasRealizadas                        == null ? this.encargoAnterior.enup_pruebas_realizadas                      != null ? (this.pruebasRealizadas                       = this.encargoAnterior.enup_pruebas_realizadas,                       this.addClassAnterior('pruebasRealizadas')) : null : null;
    this.evolucion                                == null ? this.encargoAnterior.enup_evolucion                               != null ? (this.evolucion                               = this.encargoAnterior.enup_evolucion,                                this.addClassAnterior('evolucion')) : null : null;
    this.fuenteDocumental                         == null ? this.encargoAnterior.enup_fuente_documental                       != null ? (this.fuenteDocumental                        = this.encargoAnterior.enup_fuente_documental,                        this.addClassAnterior('fuenteDocumental')) : null : null;
    this.referenciaInforme                        == null ? this.encargoAnterior.enup_num_referencia_informe                  != null ? (this.referenciaInforme                       = this.encargoAnterior.enup_num_referencia_informe,                   this.addClassAnterior('referenciaInforme')) : null : null;
    this.firmadoPor                               == null ? this.encargoAnterior.enup_firmado_por                             != null ? (this.firmadoPor                              = this.encargoAnterior.enup_firmado_por,                              this.addClassAnterior('firmadoPor')) : null : null;
    this.tipoImpacto                              == null ? this.encargoAnterior.enup_tipo_impacto                            != null ? (this.tipoImpacto                             = this.encargoAnterior.enup_tipo_impacto,                             this.addClassAnterior('tipoImpacto')) : null : null;
    this.deltaV                                   == null ? this.encargoAnterior.enup_delta_v                                 != null ? (this.deltaV                                  = this.encargoAnterior.enup_delta_v,                                  this.addClassAnterior('deltaV')) : null : null;
    this.aceleracion                              == null ? this.encargoAnterior.enup_aceleracion                             != null ? (this.aceleracion                             = this.encargoAnterior.enup_aceleracion,                              this.addClassAnterior('aceleracion')) : null : null;
    this.consideracion                            == null ? this.encargoAnterior.enup_consideracion                           != null ? (this.consideracion                           = this.encargoAnterior.enup_consideracion,                            this.addClassAnterior('consideracion')) : null : null;
    this.conclusiones                             == null ? this.encargoAnterior.enup_conclusiones                            != null ? (this.conclusiones                            = this.encargoAnterior.enup_conclusiones,                             this.addClassAnterior('conclusiones')) : null : null;
    this.diasBasicos                              == "0"  ? this.encargoAnterior.enup_dias_ppp_basicos                        != null && this.encargoAnterior.enup_dias_ppp_basicos   != "0" ? (this.diasBasicos    = this.encargoAnterior.enup_dias_ppp_basicos,       this.addClassAnterior('diasBasicos')) : null : null;
    this.diasModerados                            == "0"  ? this.encargoAnterior.enup_dias_ppp_moderados                      != null && this.encargoAnterior.enup_dias_ppp_moderados != "0" ? (this.diasModerados  = this.encargoAnterior.enup_dias_ppp_moderados,     this.addClassAnterior('diasModerados')) : null : null;
    this.diasGraves                               == "0"  ? this.encargoAnterior.enup_dias_ppp_graves                         != null && this.encargoAnterior.enup_dias_ppp_graves    != "0" ? (this.diasGraves     = this.encargoAnterior.enup_dias_ppp_graves,        this.addClassAnterior('diasGraves')) : null : null;
    this.diasMuyGraves                            == "0"  ? this.encargoAnterior.enup_dias_ppp_muy_graves                     != null && this.encargoAnterior.enup_dias_ppp_muy_graves!= "0" ? (this.diasMuyGraves  = this.encargoAnterior.enup_dias_ppp_muy_graves,    this.addClassAnterior('diasMuyGraves')) : null : null;
    this.diasTotales                              == "0"  ? this.encargoAnterior.enup_dias_ppp_totales                        != null && this.encargoAnterior.enup_dias_ppp_totales   != "0" ? (this.diasTotales    = this.encargoAnterior.enup_dias_ppp_totales,       this.addClassAnterior('diasTotales')) : null : null;
    this.descripcionPerjuicioEstetico             == null ? this.encargoAnterior.enup_descripcion_perjuicio_estetico          != null ? (this.descripcionPerjuicioEstetico            = this.encargoAnterior.enup_descripcion_perjuicio_estetico,           this.addClassAnterior('descripcionPerjuicioEstetico')) : null : null;
    this.puntosPerjuicioEstetico                  == null ? this.encargoAnterior.enup_puntos_perjuicio_estetico               != null ? (this.puntosPerjuicioEstetico                 = this.encargoAnterior.enup_puntos_perjuicio_estetico,                this.addClassAnterior('puntosPerjuicioEstetico')) : null : null;
    this.puntosPerjuicioEsteticoMax               == null ? this.encargoAnterior.enup_puntos_perjuicio_estetico_max           != null ? (this.puntosPerjuicioEsteticoMax              = this.encargoAnterior.enup_puntos_perjuicio_estetico_max,            this.addClassAnterior('puntosPerjuicioEsteticoMax')) : null : null;
    this.perjuicioMoral                           == null ? this.encargoAnterior.enup_perjuicio_moral                         != null ? (this.perjuicioMoral                          = this.encargoAnterior.enup_perjuicio_moral,                          this.addClassAnterior('perjuicioMoral')) : null : null;
    this.grado                                    == null ? this.encargoAnterior.enup_grado                                   != null ? (this.grado                                   = this.encargoAnterior.enup_grado,                                    this.addClassAnterior('grado')) : null : null;
    this.porcentajeGrado                          == null ? this.encargoAnterior.enup_porcentaje                              != null ? (this.porcentajeGrado                         = this.encargoAnterior.enup_porcentaje,                               this.addClassAnterior('porcentajeGrado')) : null : null;
    this.perjuicioPersonal                        == null ? this.encargoAnterior.enup_perjuicio_personal                      != null ? (this.perjuicioPersonal                       = this.encargoAnterior.enup_perjuicio_personal,                       this.addClassAnterior('perjuicioPersonal')) : null : null;
    this.intervencionObj                          == null ? this.encargoAnterior.enup_intervenciones                          != null ? (this.intervencionObj                         = this.encargoAnterior.enup_intervenciones,                           this.addClassAnterior('intervencionObj')) : null : null;
    this.grupoQuirurgico                          == null ? this.encargoAnterior.enup_grupo_quirurgico                        != null ? (this.grupoQuirurgico                         = this.encargoAnterior.enup_grupo_quirurgico,                         this.addClassAnterior('grupoQuirurgico')) : null : null;
    this.otrosCorrectores                         == null ? this.encargoAnterior.enup_otros_correctores                       != null ? (this.otrosCorrectores                        = this.encargoAnterior.enup_otros_correctores,                        this.addClassAnterior('otrosCorrectores')) : null : null;
    this.observaciones                            == null ? this.encargoAnterior.enup_observaciones                           != null ? (this.observaciones                           = this.encargoAnterior.enup_observaciones,                            this.addClassAnterior('observaciones')) : null : null;
    this.observacionesIMD                         == null ? this.encargoAnterior.enup_observaciones_imd                       != null ? (this.observacionesIMD                        = this.encargoAnterior.enup_observaciones_imd,                        this.addClassAnterior('observacionesIMD')) : null : null;
    this.observacionesAseguradora                 == null ? this.encargoAnterior.enup_observaciones_aseguradora               != null ? (this.observacionesAseguradora                = this.encargoAnterior.enup_observaciones_aseguradora,                this.addClassAnterior('observacionesAseguradora')) : null : null;
    this.proximaRevision                          == null ? this.encargoAnterior.enup_proxima_revision                        != null ? (this.proximaRevision                         = this.encargoAnterior.enup_proxima_revision,                         this.addClassAnterior('proximaRevision')) : null : null;
    this.mecanismoProduccion                      == null ? this.encargoAnterior.enup_mecanismo_produccion                    != null ? (this.mecanismoProduccion                     = this.encargoAnterior.enup_mecanismo_produccion,                     this.addClassAnterior('mecanismoProduccion')) : null : null;
    this.situacionIncapacidad                     == null ? this.encargoAnterior.enup_situacion_incapacidad                   != null ? (this.situacionIncapacidad                    = this.encargoAnterior.enup_situacion_incapacidad,                    this.addClassAnterior('situacionIncapacidad')) : null : null;
    this.tiempoRecuperacion                       == null ? this.encargoAnterior.enup_tiempo_recuperacion                     != null ? (this.tiempoRecuperacion                      = this.encargoAnterior.enup_tiempo_recuperacion,                      this.addClassAnterior('tiempoRecuperacion')) : null : null;
    this.secuelaObj                               == null ? this.encargoAnterior.enup_secuelas                                != null ? (this.secuelaObj                              = this.encargoAnterior.enup_secuelas,                                 this.addClassAnterior('secuelaObj')) : null : null;
    this.otrosCorrectores                         == null ? this.encargoAnterior.enup_otros_correctores                       != null ? (this.otrosCorrectores                        = this.encargoAnterior.enup_otros_correctores,                        this.addClassAnterior('otrosCorrectores')) : null : null;
    this.xDanoMoral                               == null ? this.encargoAnterior.enup_x_dano_moral                            != null ? (this.xDanoMoral                              = this.encargoAnterior.enup_x_dano_moral,                             this.addClassAnterior('xDanoMoral')) : null : null;
    this.xPerjuicioMoral                          == null ? this.encargoAnterior.enup_x_perjuicio_moral                       != null ? (this.xPerjuicioMoral                         = this.encargoAnterior.enup_x_perjuicio_moral,                        this.addClassAnterior('xPerjuicioMoral')) : null : null;
    this.xGastosPrevisibles                       == null ? this.encargoAnterior.enup_x_gastos_previsibles                    != null ? (this.xGastosPrevisibles                      = this.encargoAnterior.enup_x_gastos_previsibles,                     this.addClassAnterior('xGastosPrevisibles')) : null : null;
    this.xRehabilitacion                          == null ? this.encargoAnterior.enup_x_rehabilitacion                        != null ? (this.xRehabilitacion                         = this.encargoAnterior.enup_x_rehabilitacion,                         this.addClassAnterior('xRehabilitacion')) : null : null;
    this.xAdecuacion                              == null ? this.encargoAnterior.enup_x_adecuacion                            != null ? (this.xAdecuacion                             = this.encargoAnterior.enup_x_adecuacion,                             this.addClassAnterior('xAdecuacion')) : null : null;
    this.xHorasNecesarias                         == null ? this.encargoAnterior.enup_x_horas_necesarias                      != null ? (this.xHorasNecesarias                        = this.encargoAnterior.enup_x_horas_necesarias,                       this.addClassAnterior('xHorasNecesarias')) : null : null;
    this.horasNecesarias                          == null ? this.encargoAnterior.enup_horas_necesarias                        != null ? (this.horasNecesarias                         = this.encargoAnterior.enup_horas_necesarias,                         this.addClassAnterior('horasNecesarias')) : null : null;
    this.xDanoMoralEstetico                       == null ? this.encargoAnterior.enup_x_dano_moral_estetico                   != null ? (this.xDanoMoralEstetico                      = this.encargoAnterior.enup_x_dano_moral_estetico,                    this.addClassAnterior('xDanoMoralEstetico')) : null : null;
    this.xPerjuicioExcepcional                    == null ? this.encargoAnterior.enup_x_perjuicio_excepcional                 != null ? (this.xPerjuicioExcepcional                   = this.encargoAnterior.enup_x_perjuicio_excepcional,                  this.addClassAnterior('xPerjuicioExcepcional')) : null : null;
    this.xProtesisOrtesis                         == null ? this.encargoAnterior.enup_x_protesis_ortesis                      != null ? (this.xProtesisOrtesis                        = this.encargoAnterior.enup_x_protesis_ortesis,                       this.addClassAnterior('xProtesisOrtesis')) : null : null;
    this.xAyudasTecnicas                          == null ? this.encargoAnterior.enup_x_ayudas_tecnicas                       != null ? (this.xAyudasTecnicas                         = this.encargoAnterior.enup_x_ayudas_tecnicas,                        this.addClassAnterior('xAyudasTecnicas')) : null : null;
    this.xPerjuicioPatrimonial                    == null ? this.encargoAnterior.enup_x_perjuicio_patrimonial                 != null ? (this.xPerjuicioPatrimonial                   = this.encargoAnterior.enup_x_perjuicio_patrimonial,                  this.addClassAnterior('xPerjuicioPatrimonial')) : null : null;

    //Reserva
    this.dias_ppp_basicos_reserva                 == "0"  ? this.encargoAnterior.enup_dias_ppp_basicos_reserva                != null && this.encargoAnterior.enup_dias_ppp_basicos_reserva     != "0" ? (this.dias_ppp_basicos_reserva     = this.encargoAnterior.enup_dias_ppp_basicos_reserva,     this.addClassAnterior('dias_ppp_basicos_reserva')) : null : null;
    this.dias_ppp_moderados_reserva               == "0"  ? this.encargoAnterior.enup_dias_ppp_moderados_reserva              != null && this.encargoAnterior.enup_dias_ppp_moderados_reserva   != "0" ? (this.dias_ppp_moderados_reserva   = this.encargoAnterior.enup_dias_ppp_moderados_reserva,   this.addClassAnterior('dias_ppp_moderados_reserva')) : null : null;
    this.dias_ppp_graves_reserva                  == "0"  ? this.encargoAnterior.enup_dias_ppp_graves_reserva                 != null && this.encargoAnterior.enup_dias_ppp_graves_reserva      != "0" ? (this.dias_ppp_graves_reserva      = this.encargoAnterior.enup_dias_ppp_graves_reserva,      this.addClassAnterior('dias_ppp_graves_reserva')) : null : null;
    this.dias_ppp_muy_graves_reserva              == "0"  ? this.encargoAnterior.enup_dias_ppp_muy_graves_reserva             != null && this.encargoAnterior.enup_dias_ppp_muy_graves_reserva  != "0" ? (this.dias_ppp_muy_graves_reserva  = this.encargoAnterior.enup_dias_ppp_muy_graves_reserva,  this.addClassAnterior('dias_ppp_muy_graves_reserva')) : null : null;
    this.dias_ppp_totales_reserva                 == "0"  ? this.encargoAnterior.enup_dias_ppp_totales_reserva                != null && this.encargoAnterior.enup_dias_ppp_totales_reserva     != "0" ? (this.dias_ppp_totales_reserva     = this.encargoAnterior.enup_dias_ppp_totales_reserva,     this.addClassAnterior('dias_ppp_totales_reserva')) : null : null;
    
    this.secuelaReservaObj                        == null ? this.encargoAnterior.enup_secuelas_reserva                        != null ? (this.secuelaReservaObj                       = this.encargoAnterior.enup_secuelas_reserva,                         this.addClassAnterior('secuelaReservaObj')) : null : null;

    this.descripcion_perjuicio_estetico_reserva   == null ? this.encargoAnterior.enup_descripcion_perjuicio_estetico_reserva  != null ? (this.descripcion_perjuicio_estetico_reserva  = this.encargoAnterior.enup_descripcion_perjuicio_estetico_reserva,   this.addClassAnterior('descripcion_perjuicio_estetico_reserva')) : null : null;
    this.puntos_perjuicio_estetico_reserva        == null ? this.encargoAnterior.enup_puntos_perjuicio_estetico_reserva       != null ? (this.puntos_perjuicio_estetico_reserva       = this.encargoAnterior.enup_puntos_perjuicio_estetico_reserva,        this.addClassAnterior('puntos_perjuicio_estetico_reserva')) : null : null;

    this.perjuicio_moral_reserva                  == null ? this.encargoAnterior.enup_perjuicio_moral_reserva                 != null ? (this.perjuicio_moral_reserva                 = this.encargoAnterior.enup_perjuicio_moral_reserva,                  this.addClassAnterior('perjuicio_moral_reserva')) : null : null;
    this.grado_reserva                            == null ? this.encargoAnterior.enup_grado_reserva                           != null ? (this.grado_reserva                           = this.encargoAnterior.enup_grado_reserva,                            this.addClassAnterior('grado_reserva')) : null : null;
    this.porcentaje_reserva                       == null ? this.encargoAnterior.enup_porcentaje_reserva                      != null ? (this.porcentaje_reserva                      = this.encargoAnterior.enup_porcentaje_reserva,                       this.addClassAnterior('porcentaje_reserva')) : null : null;
    
    this.perjuicio_personal_reserva               == null ? this.encargoAnterior.enup_perjuicio_personal_reserva              != null ? (this.perjuicio_personal_reserva              = this.encargoAnterior.enup_perjuicio_personal_reserva,               this.addClassAnterior('perjuicio_personal_reserva')) : null : null;

    this.otros_correctores_reserva                == null ? this.encargoAnterior.enup_otros_correctores_reserva               != null ? (this.otros_correctores_reserva               = this.encargoAnterior.enup_otros_correctores_reserva,                this.addClassAnterior('otros_correctores_reserva')) : null : null;
    this.x_dano_moral_reserva                     == null ? this.encargoAnterior.enup_x_dano_moral_reserva                    != null ? (this.x_dano_moral_reserva                    = this.encargoAnterior.enup_x_dano_moral_reserva,                     this.addClassAnterior('x_dano_moral_reserva')) : null : null;
    this.x_perjuicio_moral_reserva                == null ? this.encargoAnterior.enup_x_perjuicio_moral_reserva               != null ? (this.x_perjuicio_moral_reserva               = this.encargoAnterior.enup_x_perjuicio_moral_reserva,                this.addClassAnterior('x_perjuicio_moral_reserva')) : null : null;
    this.x_gastos_previsibles_reserva             == null ? this.encargoAnterior.enup_x_gastos_previsibles_reserva            != null ? (this.x_gastos_previsibles_reserva            = this.encargoAnterior.enup_x_gastos_previsibles_reserva,             this.addClassAnterior('x_gastos_previsibles_reserva')) : null : null;
    this.x_rehabilitacion_reserva                 == null ? this.encargoAnterior.enup_x_rehabilitacion_reserva                != null ? (this.x_rehabilitacion_reserva                = this.encargoAnterior.enup_x_rehabilitacion_reserva,                 this.addClassAnterior('x_rehabilitacion_reserva')) : null : null;
    this.x_adecuacion_reserva                     == null ? this.encargoAnterior.enup_x_adecuacion_reserva                    != null ? (this.x_adecuacion_reserva                    = this.encargoAnterior.enup_x_adecuacion_reserva,                     this.addClassAnterior('x_adecuacion_reserva')) : null : null;
    this.x_horas_necesarias_reserva               == null ? this.encargoAnterior.enup_x_horas_necesarias_reserva              != null ? (this.x_horas_necesarias_reserva              = this.encargoAnterior.enup_x_horas_necesarias_reserva,               this.addClassAnterior('x_horas_necesarias_reserva')) : null : null;
    this.horas_necesarias_reserva                 == null ? this.encargoAnterior.enup_horas_necesarias_reserva                != null ? (this.horas_necesarias_reserva                = this.encargoAnterior.enup_horas_necesarias_reserva,                 this.addClassAnterior('horas_necesarias_reserva')) : null : null;
    this.x_dano_moral_estetico_reserva            == null ? this.encargoAnterior.enup_x_dano_moral_estetico_reserva           != null ? (this.x_dano_moral_estetico_reserva           = this.encargoAnterior.enup_x_dano_moral_estetico_reserva,            this.addClassAnterior('x_dano_moral_estetico_reserva')) : null : null;
    this.x_perjuicio_excepcional_reserva          == null ? this.encargoAnterior.enup_x_perjuicio_excepcional_reserva         != null ? (this.x_perjuicio_excepcional_reserva         = this.encargoAnterior.enup_x_perjuicio_excepcional_reserva,          this.addClassAnterior('x_perjuicio_excepcional_reserva')) : null : null;
    this.x_protesis_ortesis_reserva               == null ? this.encargoAnterior.enup_x_protesis_ortesis_reserva              != null ? (this.x_protesis_ortesis_reserva              = this.encargoAnterior.enup_x_protesis_ortesis_reserva,               this.addClassAnterior('x_protesis_ortesis_reserva')) : null : null;
    this.x_ayudas_tecnicas_reserva                == null ? this.encargoAnterior.enup_x_ayudas_tecnicas_reserva               != null ? (this.x_ayudas_tecnicas_reserva               = this.encargoAnterior.enup_x_ayudas_tecnicas_reserva,                this.addClassAnterior('x_ayudas_tecnicas_reserva')) : null : null;
    this.x_perjuicio_patrimonial_reserva          == null ? this.encargoAnterior.enup_x_perjuicio_patrimonial_reserva         != null ? (this.x_perjuicio_patrimonial_reserva         = this.encargoAnterior.enup_x_perjuicio_patrimonial_reserva,          this.addClassAnterior('x_perjuicio_patrimonial_reserva')) : null : null;

    this.observaciones_reserva                    == null ? this.encargoAnterior.enup_observaciones_reserva                   != null ? (this.observaciones_reserva                   = this.encargoAnterior.enup_observaciones_reserva,                    this.addClassAnterior('observaciones_reserva')) : null : null;



    //rellenar campo dinamico secuelas
    //Si es VALORACIÓN VIDEO-CONSULTA
    // if(this.encargo.enup_id_tipo_encargo == 5 || this.encargo.enup_id_tipo_encargo == 8){
      if(this.encargo.enup_secuelas == null || this.encargo.enup_secuelas == '{"secuelas":[]}'){
        if(this.secuelaObj.secuelas.length == 0){
          this.secuelaObj = JSON.parse(this.encargoAnterior.enup_secuelas == null ? '{"secuelas":[]}': this.encargoAnterior.enup_secuelas);
          this.secuelaObj.secuelas.forEach(secuela =>{
            this.secuelas().push(this.fb.group(secuela));
          });
          this.numSecuelas = this.secuelas().length;
          
          //Si hay secuelas sumar puntos
          if(this.secuelas().length > 0){
            this.totalPuntosSecuelas();
            //Añadir clase a secuelas antiguas
            this.addClassSecuelasAntiguas = true;
          }
        }
      }

      //secuelas estéticas
      // if(this.encargo.enup_secuelas_esteticas == null || this.encargo.enup_secuelas_esteticas == '{"secuelas":[]}'){
      //   if(this.secuelasEsteticasObj.secuelas.length == 0){
      //     this.secuelasEsteticasObj = JSON.parse(this.encargoAnterior.enup_secuelas_esteticas == null ? '{"secuelas":[]}': this.encargoAnterior.enup_secuelas_esteticas);
      //     this.secuelasEsteticasObj.secuelas.forEach(secuela =>{
      //       this.secuelasEsteticas().push(this.fb.group(secuela));
      //     });
      //     this.numSecuelasEsteticas = this.secuelasEsteticas().length;
          
      //     //Si hay secuelas sumar puntos
      //     if(this.secuelasEsteticas().length > 0){
      //       this.totalPuntosSecuelasEsteticas();
      //       //Añadir clase a secuelas antiguas
      //       this.addClassSecuelasEsteticasAntiguas = true;
      //     }
      //   }
      // }

      if(this.encargo.enup_secuelas_reserva == null || this.encargo.enup_secuelas_reserva == '{"secuelas":[]}'){
        if(this.secuelaReservaObj.secuelas.length == 0){
          this.secuelaReservaObj = JSON.parse(this.encargoAnterior.enup_secuelas_reserva == null ? '{"secuelas":[]}': this.encargoAnterior.enup_secuelas_reserva);
          this.secuelaReservaObj.secuelas.forEach(secuela =>{
            this.secuelasReserva().push(this.fb.group(secuela));
          });
          this.numSecuelasReserva = this.secuelasReserva().length;
          
          //Si hay secuelas sumar puntos
          if(this.secuelasReserva().length > 0){
            this.totalPuntosSecuelasReserva();
            //Añadir clase a secuelas antiguas
            this.addClassSecuelasAntiguasReserva = true;
          }
        }
      }

      if(this.encargo.enup_intervenciones == null || this.encargo.enup_intervenciones == '{"intervenciones":[]}'){
        if(this.intervencionObj.intervenciones.length == 0){
          this.intervencionObj = JSON.parse(this.encargoAnterior.enup_intervenciones == null ? '{"intervenciones":[]}': this.encargoAnterior.enup_intervenciones);
          this.intervencionObj.intervenciones.forEach(intervencion =>{
            this.intervenciones().push(this.fb.group(intervencion));
          });
          this.numIntervenciones = this.intervenciones().length;
        }
      }

      if(this.encargo.enup_intervenciones_reserva == null || this.encargo.enup_intervenciones_reserva == '{"intervenciones":[]}'){
        if(this.intervencionReservaObj.intervenciones.length == 0){
          this.intervencionReservaObj = JSON.parse(this.encargoAnterior.enup_intervenciones_reserva == null ? '{"intervenciones":[]}': this.encargoAnterior.enup_intervenciones_reserva);
          this.intervencionReservaObj.intervenciones.forEach(intervencion =>{
            this.intervencionesReserva().push(this.fb.group(intervencion));
          });
          this.numIntervencionesReserva = this.intervencionesReserva().length;
        }
      }

    // }
    
    //Si es Valoracion Base Documental
    /*if(this.encargo.enup_id_tipo_encargo == 6 || this.encargo.enup_id_tipo_encargo == 7 || this.encargo.enup_id_tipo_encargo == 10){
      if(this.encargo.enup_secuelas == null || this.encargo.enup_secuelas == '{"secuelas":[]}'){
        if(this.secuelaObj.secuelas.length == 0){
          this.secuelaObj = JSON.parse(this.encargoAnterior.enup_secuelas == null ? '{"secuelas":[]}': this.encargoAnterior.enup_secuelas);
          this.secuelaObj.secuelas.forEach(secuela =>{
            this.secuelasHorquilla().push(this.fb.group(secuela));
          });
          this.numSecuelas = this.secuelasHorquilla().length;
          //Si hay secuelas sumar puntos
          if(this.secuelasHorquilla().length > 0){
            this.totalPuntosSecuelasMin();
            this.totalPuntosSecuelasMax();
            //Añadir clase a secuelas antiguas
            this.addClassSecuelasAntiguas = true;
          }
        }
      }
    }*/
  }

  addClassAnterior(idDiv){
    let div = document.getElementById(idDiv);
    if(div != null && div.innerText != null){
      div.classList.add('anterior');
    }
  }

  keyupClaseAnterior(value, id, valueAnterior){
    if(value != valueAnterior){
      document.getElementById(id).classList.remove('anterior');
    }else{
      document.getElementById(id).classList.add('anterior');
    }
  }

  //calcular puntos de las secuelas con la formula de balthazar
  totalPuntosSecuelas(){
    let totalSecuelas;
    let puntosArray: number[] = [];
    let i = 0;
    let resultado = 0;

    totalSecuelas = this.secuelaForm.value;

    totalSecuelas.secuelas.forEach(secuela =>{
      puntosArray.push(parseInt(secuela.puntos));
    });

    if(puntosArray.length != 1){
      puntosArray.sort((a,b) => b-a);

      while(i<puntosArray.length){
        if(i==0){
          resultado = Math.ceil((((100-puntosArray[i]) * puntosArray[i+1])/100)+puntosArray[i]);
          i = i+2;
        }else{
          resultado = Math.ceil((((100-resultado) * puntosArray[i])/100)+resultado);
          i++;
        }
      }
    }else{
      resultado = puntosArray[0];
    }

    //Numero de secuelas
    this.numSecuelas = puntosArray.length;
    
    //Mostrar en variable
    this.puntosTotalesSecuelas = resultado;
    return resultado;

  }



   //calcular puntos de las secuelas con la formula de balthazar
   totalPuntosSecuelasReserva(){
    let totalSecuelas;
    let puntosArray: number[] = [];
    let i = 0;
    let resultado = 0;

    totalSecuelas = this.secuelaReservaForm.value;

    totalSecuelas.secuelas.forEach(secuela =>{
      puntosArray.push(parseInt(secuela.puntos));
    });

    if(puntosArray.length != 1){
      puntosArray.sort((a,b) => b-a);

      while(i<puntosArray.length){
        if(i==0){
          resultado = Math.ceil((((100-puntosArray[i]) * puntosArray[i+1])/100)+puntosArray[i]);
          i = i+2;
        }else{
          resultado = Math.ceil((((100-resultado) * puntosArray[i])/100)+resultado);
          i++;
        }
      }
    }else{
      resultado = puntosArray[0];
    }

    //Numero de secuelas
    this.numSecuelasReserva = puntosArray.length;
    
    //Mostrar en variable
    this.puntosTotalesSecuelasReserva = resultado;
    return resultado;

  }

  //calcular puntos de las secuelas con la formula de balthazar
  totalPuntosSecuelasMin(){
    let totalSecuelas;
    let puntosArray: number[] = [];
    let i = 0;
    let resultado = 0;

    totalSecuelas = this.secuelaHorquillaForm.value;

    totalSecuelas.secuelas.forEach(secuela =>{
      puntosArray.push(parseInt(secuela.puntosMin));
    });

    if(puntosArray.length != 1){
      puntosArray.sort((a,b) => b-a);

      while(i<puntosArray.length){
        if(i==0){
          resultado = Math.ceil((((100-puntosArray[i]) * puntosArray[i+1])/100)+puntosArray[i]);
          i = i+2;
        }else{
          resultado = Math.ceil((((100-resultado) * puntosArray[i])/100)+resultado);
          i++;
        }
      }
    }else{
      resultado = puntosArray[0];
    }

    //Numero de secuelas
    this.numSecuelas = puntosArray.length;
    
    //Mostrar en variable
    this.puntosMinTotalesSecuelas = resultado;
    return resultado;

  }

  //calcular puntos de las secuelas con la formula de balthazar
  totalPuntosSecuelasMax(){
    let totalSecuelas;
    let puntosArray: number[] = [];
    let i = 0;
    let resultado = 0;

    totalSecuelas = this.secuelaHorquillaForm.value;

    totalSecuelas.secuelas.forEach(secuela =>{
      puntosArray.push(parseInt(secuela.puntosMax));
    });

    if(puntosArray.length != 1){
      puntosArray.sort((a,b) => b-a);

      while(i<puntosArray.length){
        if(i==0){
          resultado = Math.ceil((((100-puntosArray[i]) * puntosArray[i+1])/100)+puntosArray[i]);
          i = i+2;
        }else{
          resultado = Math.ceil((((100-resultado) * puntosArray[i])/100)+resultado);
          i++;
        }
      }
    }else{
      resultado = puntosArray[0];
    }

    //Numero de secuelas
    this.numSecuelas = puntosArray.length;
    
    //Mostrar en variable
    this.puntosMaxTotalesSecuelas = resultado;
    return resultado;

  }

  getMedicoEncargo(id: number) {

    this.tramitadorService.getMedicoEncargo(id).subscribe(
      data => {
        if(data['status'] == 200){
         this.medico = data['data'];
        }else if(data['response'] == "fail"){
          this.notificacion.notificationFail(data['mensaje']);
        }
        
      }, error => {
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );

  }

  generarInforme(){
    this.mostrarProgressSpinner = true;
    var datos;
    console.log(this.sessionService.getCurrentSession());


    datos = {
      'idTipoEncargo': this.encargo.enup_id_tipo_encargo,
      'idEstado': this.encargo.enup_id_estado_encargo,
      'idCia': this.encargo.enup_cia,
      'numExpediente': this.numExpediente,
      'nombreMedico': this.encargo.nombreMedico,
      'numColegiado': this.encargo.numColegiadoMedico,
      'logrosMedico': this.encargo.logrosMedico,
      'nombreLesionado': this.nombreLesionado,
      'edadLesionado': this.edadLesionado,
      'provincia': this.provincia,
      'localidad': this.localidad,
      'fechaSiniestro': this.fechaSiniestro,
      'refSiniestro': this.refSiniestro,
      'urlFirma': this.medico.url_firma,
      'genero': this.medico.genero,
      'consideracion': this.consideracion,
      'conclusiones': this.conclusiones,
      'mecanismoProduccion': this.mecanismoProduccion,
      'fechaConsultaVideoConsulta': this.fechaConsultaVideoConsulta,
      'condicionLesionado': this.condicionLesionado,
      'formato_consulta' : this.id_formato_consulta,
      'idEncargo' : this.encargo.id
    };

  
    

    //Si es REHUSE CAS o REHUSE CAS + VIDEO


      datos['objetoInforme'] = this.objetoInforme == null ? "No se aporta" : this.objetoInforme;
      datos['profesion'] = this.profesion;
      datos['situacionLaboral'] = this.situacionLaboral;
      datos['bajaLaboral'] = this.bajaLaboral;
      datos['contingencia'] = this.contingencia;
      datos['antecedentes'] = this.antecedentes;
      datos['centroUrgencias'] = this.centroUrgencias;
      datos['cuandoAcude'] = this.cuandoAcude;
      datos['diagnosticoUrgencias'] = this.diagnosticoUrgencias;
      datos['hospitalizacionUrgencias'] = this.hospitalizacionUrgencias;
      datos['centroRehab'] = this.centroRehab;
      datos['medicoSeguimiento'] = this.medicoSeguimiento;
      datos['inicioTratamiento'] = this.inicioTratamiento;
      datos['altaMedica'] = this.altaMedica;
      datos['numConsultas'] = this.numConsultas;
      datos['numSesiones'] = this.numSesiones;
      datos['pruebasRealizadas'] = this.pruebasRealizadas;
      datos['evolucion'] = this.evolucion;
      datos['videoConsultaAnteriores'] = this.camposVideoConsultaAnteriores;
      if(this.encargo.enup_id_tipo_encargo == 1 || this.encargo.enup_id_tipo_encargo == 2 || this.encargo.enup_id_tipo_encargo == 3 || this.encargo.enup_id_tipo_encargo == 12 || this.encargo.enup_id_tipo_encargo == 18){
        datos['fuenteDocumental'] = this.fuenteDocumental == null ? "No se aporta" : this.fuenteDocumental;
        datos['fechaBajaLaboral'] = this.bajaLaboral == "Si" ? this.fechaBajaLaboral : null;
        datos['fechaAltaLaboral'] = this.bajaLaboral == "Si" ? this.fechaAltaLaboral : null;
        datos['hospitalizacionUrgencias'] = "NO";
      }
      //Si es REHUSE CAS + VIDEO
      if(this.encargo.enup_id_tipo_encargo == 4 || this.encargo.enup_id_tipo_encargo == 14){
        datos['fuenteDocumental'] = this.fuenteDocumental == null ? "No se aporta" : this.fuenteDocumental;
        datos['fechaBajaLaboral'] = this.bajaLaboral == "Si" ? this.fechaBajaLaboral : null;
        datos['fechaAltaLaboral'] = this.bajaLaboral == "Si" ? this.fechaAltaLaboral : null;
        datos['hospitalizacionUrgencias'] = "NO";
        datos['referenciaInforme'] = this.referenciaInforme;
        datos['firmadoPor'] = this.firmadoPor;
        datos['tipoImpacto'] = this.tipoImpacto;
        datos['deltaV'] = this.deltaV;
        datos['aceleracion'] = this.aceleracion;
        datos['fechaVideoConsulta'] = formatDate(this.dpFechaVideoConsulta.value, 'dd/MM/yyyy', "en-GB") == "01/01/1970" ? null : formatDate(this.dpFechaVideoConsulta.value, 'dd/MM/yyyy', "en-GB");
        datos['mediosUsados'] = this.mediosUsados;
        datos['anamnesis'] = this.anamnesis;
        datos['exploracionFisica'] = this.exploracionFisica;
      }

      //Si es INFORME COHERENCIA
      if(this.encargo.enup_id_tipo_encargo == 22) {
        datos['fuenteDocumental'] = this.fuenteDocumental == null ? "No se aporta" : this.fuenteDocumental;
        datos['fechaPruebaBio'] = this.dpFechaPruebaBio.value == null ? null : formatDate(this.dpFechaPruebaBio.value, 'dd/MM/yyyy', "en-GB");
        datos['fechaPruebaTermo'] = this.dpFechaPruebaTermo.value == null ? null : formatDate(this.dpFechaPruebaTermo.value, 'dd/MM/yyyy', "en-GB");
        datos['fechaUltimaConsulta'] = this.dpFechaUltimaConsulta.value == null ? null : formatDate(this.dpFechaUltimaConsulta.value, 'dd/MM/yyyy', "en-GB");
        datos['diagnosticoInicial'] = this.diagnosticoInicial;
        datos['determinacion'] = this.determinacion;
        datos['resultadoEco'] = this.resultadoEco;
        datos['zonaAnalizada'] = this.zonaAnalizada;
        datos['valorNFHAS'] = this.valorNFHAS;
        datos['xTMCC'] = (this.xTMCC === null) ? null : (this.xTMCC === false ? 0 : this.xTMCC);
        datos['xPatoColumna'] = (this.xPatoColumna === null) ? null : (this.xPatoColumna === false ? 0 : this.xPatoColumna);
        datos['xEnfermedades'] = (this.xEnfermedades === null) ? null : (this.xEnfermedades === false ? 0 : this.xEnfermedades);
        datos['xBajaMutua'] = (this.xBajaMutua === null) ? null : (this.xBajaMutua === false ? 0 : this.xBajaMutua);
        datos['xBioFuncional'] = (this.xBioFuncional === null) ? null : (this.xBioFuncional === false ? 0 : this.xBioFuncional);
        datos['xPruebaConsistente'] = (this.xPruebaConsistente === null) ? null : (this.xPruebaConsistente === false ? 0 : this.xPruebaConsistente);
        datos['xCoherenteBio'] = (this.xCoherenteBio === null) ? null : (this.xCoherenteBio === false ? 0 : this.xCoherenteBio);
        datos['xTermoRealizada'] = (this.xTermoRealizada === null) ? null : (this.xTermoRealizada === false ? 0 : this.xTermoRealizada);
        datos['xIndicadorCoherencia'] = (this.xIndicadorCoherencia === null) ? null : (this.xIndicadorCoherencia === false ? 0 : this.xIndicadorCoherencia);
        datos['xResultPruebaBio'] = (this.xResultPruebaBio === null) ? null : (this.xResultPruebaBio === false ? 0 : this.xResultPruebaBio);
        datos['xResultadoPrueba'] = (this.xResultadoPrueba === null) ? null : (this.xResultadoPrueba === false ? 0 : this.xResultadoPrueba);
        datos['xCoherenteTermo'] = (this.xCoherenteTermo === null) ? null : (this.xCoherenteTermo === false ? 0 : this.xCoherenteTermo);
        datos['referenciaInforme'] = this.referenciaInforme;
        datos['condicionesRehuse'] = (this.condicionesRehuse === null) ? null : (this.condicionesRehuse === false ? 0 : this.condicionesRehuse);
        datos['firmadoPor'] = this.firmadoPor;
        datos['tipoImpacto'] = this.tipoImpacto;
        datos['deltaV'] = this.deltaV;
        datos['aceleracion'] = this.aceleracion;
        datos['fechaVideoConsulta'] = formatDate(this.dpFechaVideoConsulta.value, 'dd/MM/yyyy', "en-GB") == "01/01/1970" ? null : formatDate(this.dpFechaVideoConsulta.value, 'dd/MM/yyyy', "en-GB");
        datos['mediosUsados'] = this.mediosUsados;
        datos['anamnesis'] = this.anamnesis;
        datos['exploracionFisica'] = this.exploracionFisica;
        datos['diasBasicos'] = this.diasBasicos;
        datos['diasModerados'] = this.diasModerados;
        datos['diasGraves'] = this.diasGraves;
        datos['diasMuyGraves'] = this.diasMuyGraves;
        datos['diasTotales'] = this.diasTotales;
        datos['realizadoPor'] = this.medicoConsulta?.med_nombre ?? null;
        datos['numColegiadoMedicoConsulta'] = this.medicoConsulta?.med_num_colegiado ?? null;
        datos['observaciones'] = this.observaciones;
        datos['valorDeltaV'] = this.valorDeltaV;
        //calcular puntos de las secuelas con la formula de balthazar
        datos['puntosSecuelas'] = this.totalPuntosSecuelas();
        datos['numSecuelas'] = this.numSecuelas.toString();
        //Para que aparezca la tabla del informe pero con los datos vacios
        if(this.numSecuelas == 0){
          datos['secuelas'] = JSON.stringify({"secuelas":[{"codigo":"","descripcion":"","puntos":""}]});
        }else{
          datos['secuelas'] = JSON.stringify(this.secuelaForm.value);
        }

        
      }

      //Si es VALORACIÓN BASE DOCUMENTAL , ALTA+IAE BASE DOCUMENTAL
      if(this.encargo.enup_id_tipo_encargo == 6 || this.encargo.enup_id_tipo_encargo == 7 || this.encargo.enup_id_tipo_encargo == 11 || this.encargo.enup_id_tipo_encargo == 5 || this.encargo.enup_id_tipo_encargo == 9 || this.encargo.enup_id_tipo_encargo == 8 || this.encargo.enup_id_tipo_encargo == 10 || this.encargo.enup_id_tipo_encargo == 13 || this.encargo.enup_id_tipo_encargo == 15 || this.encargo.enup_id_tipo_encargo == 16 || this.encargo.enup_id_tipo_encargo == 17){
        datos['fuenteDocumental'] = this.fuenteDocumental == null ? "No se aporta" : this.fuenteDocumental;
        datos['fechaBajaLaboral'] = this.bajaLaboral == "Si" ? this.fechaBajaLaboral : null;
        datos['fechaAltaLaboral'] = this.bajaLaboral == "Si" ? this.fechaAltaLaboral : null;
        datos['diasBasicos'] = this.diasBasicos;
        datos['diasModerados'] = this.diasModerados;
        datos['diasGraves'] = this.diasGraves;
        datos['diasMuyGraves'] = this.diasMuyGraves;
        datos['diasTotales'] = this.diasTotales;
        datos['perjuicioMoral'] = this.perjuicioMoral;
        datos['grado'] = this.grado;
        datos['porcentajeGrado'] = this.porcentajeGrado;
        datos['perjuicioPersonal'] = this.perjuicioPersonal;
        datos['grupoQuirurgico'] = this.grupoQuirurgico;
        datos['observaciones'] = this.observaciones;
        datos['observacionesIMD'] = this.observacionesIMD;
        //calcular puntos de las secuelas con la formula de balthazar
        datos['puntosSecuelas'] = this.totalPuntosSecuelas();
        datos['numSecuelas'] = this.numSecuelas.toString();
        //Para que aparezca la tabla del informe pero con los datos vacios
        if(this.numSecuelas == 0){
          datos['secuelas'] = JSON.stringify({"secuelas":[{"codigo":"","descripcion":"","puntos":""}]});
        }else{
          datos['secuelas'] = JSON.stringify(this.secuelaForm.value);
        }

        
        datos['descripcionPerjuicioEstetico'] = this.descripcionPerjuicioEstetico;
        datos['puntosPerjuicioEstetico']      = this.puntosPerjuicioEstetico;
        datos['otrosCorrectores'] = this.otrosCorrectores;

        if(this.encargo.enup_id_tipo_encargo == 5 || this.encargo.enup_id_tipo_encargo == 15 || this.encargo.enup_id_tipo_encargo == 8 ) {
          datos['fechaVideoConsulta']   = formatDate(this.dpFechaVideoConsulta.value, 'dd/MM/yyyy', "en-GB") == "01/01/1970" ? null : formatDate(this.dpFechaVideoConsulta.value, 'dd/MM/yyyy', "en-GB");
          datos['mediosUsados']         = this.mediosUsados;
          datos['anamnesis']            = this.anamnesis;
          datos['exploracionFisica']    = this.exploracionFisica;
          datos['imd']                  = this.imd == null ? 0 : this.imd;
        }

        if(this.encargo.enup_id_tipo_encargo == 11 || this.encargo.enup_id_tipo_encargo == 17) {
          datos['numInforme'] = this.numInforme;
          datos['fechaAsesoria'] = this.fechaAsesoria;
          datos['asesorMedico'] = this.asesorMedico;
          datos['observacionesAsesoria'] = this.observacionesAsesoria;
          datos['proximaRevision'] = this.proximaRevision;
          datos['idEncargo'] = this.idEncargo;
        }
        //Nuevos campos creados
        datos['xDanoMoral']                   = this.xDanoMoral == null ? 0 : this.xDanoMoral;
        datos['xDanoMoralEstetico']           = this.xDanoMoralEstetico == null ? 0 : this.xDanoMoralEstetico;
        datos['xPerjuicioMoral']              = this.xPerjuicioMoral == null ? 0 : this.xPerjuicioMoral;
        datos['xPerjuicioExcepcional']        = this.xPerjuicioExcepcional == null ? 0 : this.xPerjuicioExcepcional;
        datos['xGastosPrevisibles']           = this.xGastosPrevisibles == null ? 0 : this.xGastosPrevisibles;
        datos['xProtesisOrtesis']             = this.xProtesisOrtesis == null ? 0 : this.xProtesisOrtesis;
        datos['xRehabilitacion']              = this.xRehabilitacion == null ? 0 : this.xRehabilitacion;
        datos['xAyudasTecnicas']              = this.xAyudasTecnicas == null ? 0 : this.xAyudasTecnicas;
        datos['xAdecuacion']                  = this.xAdecuacion == null ? 0 : this.xAdecuacion;
        datos['xPerjuicioPatrimonial']        = this.xPerjuicioPatrimonial == null ? 0 : this.xPerjuicioPatrimonial;
        datos['xHorasNecesarias']             = this.xHorasNecesarias == null ? 0 : this.xHorasNecesarias;
        datos['horasNecesarias']              = this.horasNecesarias;
        datos['valoracionFinal']              = this.valoracionFinal == null ? 0 : this.valoracionFinal;
        datos['valoracionProv']               = this.valoracionProv == null ? 0 : this.valoracionProv;
        datos['imd']                          = this.imd == null ? 0 : this.imd;
        datos['formatoConsulta']              = this.id_formato_consulta;
        //Para que aparezca la tabla de las intervenciones pero con los datos vacios
        if(this.numIntervenciones == 0){
          datos['jsonIntervenciones'] = JSON.stringify({"intervenciones":[{"grupo":"","descripcion":""}]});
        }else{
          datos['jsonIntervenciones'] = JSON.stringify(this.intervencionForm.value);
        }

        datos['diasBasicosReserva']                             = this.dias_ppp_basicos_reserva == null ? 0 : this.dias_ppp_basicos_reserva;
        datos['diasModeradosReserva']                       = this.dias_ppp_moderados_reserva == null ? 0 : this.dias_ppp_moderados_reserva
        datos['diasGravesReserva']                          = this.dias_ppp_graves_reserva == null ? 0 : this.dias_ppp_graves_reserva
        datos['diasMuyGravesReserva']                      = this.dias_ppp_muy_graves_reserva == null ? 0 : this.dias_ppp_muy_graves_reserva
        datos['diasTotalesReserva']                         = this.dias_ppp_totales_reserva;
        datos['jsonSecuelasReserva']                                 = this.secuelas_reserva;
        datos['numSecuelasReserva'] = this.numSecuelasReserva.toString();
        if(this.numSecuelasReserva == 0){
          datos['jsonSecuelasReserva'] = JSON.stringify({"secuelas":[{"codigo":"","descripcion":"","puntos":""}]});
        }else{
          datos['jsonSecuelasReserva'] = JSON.stringify(this.secuelaReservaForm.value);
        }
        datos['descripcionPerjuicioEsteticoReserva']           = this.descripcion_perjuicio_estetico_reserva;
        datos['puntosPerjuicioEsteticoReserva']                = this.puntos_perjuicio_estetico_reserva;
        //TODO: hacer método que calcule los puntos reserva min DE RESERVA
        datos['puntosSecuelasReserva']                     = this.totalPuntosSecuelasReserva();
        datos['perjuicioMoralReserva']                          = this.perjuicio_moral_reserva == null ? 0 : this.perjuicio_moral_reserva
        datos['perjuicioPersonalReserva']                       = this.perjuicio_personal_reserva == null ? 0 : this.perjuicio_personal_reserva
        datos['gradoReserva']                                    = this.grado_reserva;
        datos['porcentajeGradoReserva']                               = this.porcentaje_reserva;
        datos['xDanoMoralReserva']                             = this.x_dano_moral_reserva == null ? 0 : this.x_dano_moral_reserva
        datos['xDanoMoralEsteticoReserva']                    = this.x_dano_moral_estetico_reserva == null ? 0 : this.x_dano_moral_estetico_reserva
        datos['xPerjuicioMoralReserva']                        = this.x_perjuicio_moral_reserva == null ? 0 : this.x_perjuicio_moral_reserva
        datos['xPerjuicioExcepcionalReserva']                  = this.x_perjuicio_excepcional_reserva == null ? 0 : this.x_perjuicio_excepcional_reserva
        datos['xGastosPrevisiblesReserva']                     = this.x_gastos_previsibles_reserva == null ? 0 : this.x_gastos_previsibles_reserva
        datos['xProtesisOrtesisReserva']                       = this.x_protesis_ortesis_reserva == null ? 0 : this.x_protesis_ortesis_reserva
        datos['xRehabilitacionReserva']                         = this.x_rehabilitacion_reserva == null ? 0 : this.x_rehabilitacion_reserva
        datos['xAyudasTecnicasReserva']                        = this.x_ayudas_tecnicas_reserva == null ? 0 : this.x_ayudas_tecnicas_reserva
        datos['xAdecuacionReserva']                             = this.x_adecuacion_reserva == null ? 0 : this.x_adecuacion_reserva
        datos['xPerjuicioPatrimonialReserva']                  = this.x_perjuicio_patrimonial_reserva == null ? 0 : this.x_perjuicio_patrimonial_reserva
        datos['xHorasNecesariasReserva']                       = this.x_horas_necesarias_reserva == null ? 0 : this.x_horas_necesarias_reserva
        datos['otros_correctores_reserva']                       = this.x_horas_necesarias_reserva == null ? 0 : this.otros_correctores_reserva
        datos['horasNecesariasReserva']                         = this.horas_necesarias_reserva;
        datos['observacionesReserva']                            = this.observaciones_reserva;
        datos['reservaTecnica']                                   = this.reservaTecnica;
        datos['otrosCorrectoresReserva']                          = this.otros_correctores_reserva;
        //Para que aparezca la tabla de las intervenciones de reserva pero con los datos vacios
        if(this.numIntervencionesReserva == 0){
          datos['jsonIntervencionesReserva'] = JSON.stringify({"intervenciones":[{"grupo":"","descripcion":""}]});
        }else{
          datos['jsonIntervencionesReserva'] = JSON.stringify(this.intervencionReservaForm.value);
        }

        if(this.encargo.enup_id_tipo_encargo == 9 || this.encargo.enup_id_tipo_encargo == 16) {
          datos['fuenteDocumental'] = this.fuenteDocumental == null ? "No se aporta" : this.fuenteDocumental;
          datos['evolutivoConsultasValoracion'] = this.evolutivoConsultasValoracion;
          datos['observaciones'] = this.observaciones;
          datos['fechaProximaConsulta'] = this.fechaProximaConsulta;
          datos['fuenteDocumental'] = this.fuenteDocumental == null ? "No se aporta" : this.fuenteDocumental;
          datos['fechaBajaLaboral'] = this.bajaLaboral == "Si" ? this.fechaBajaLaboral : null;
          datos['fechaAltaLaboral'] = this.bajaLaboral == "Si" ? this.fechaAltaLaboral : null;
          datos['situacionIncapacidad'] = this.situacionIncapacidad;
          datos['tiempoRecuperacion'] = this.tiempoRecuperacion;
          datos['formatoConsulta'] = this.id_formato_consulta;
          datos['fechaVideoConsulta'] = formatDate(this.dpFechaVideoConsulta.value, 'dd/MM/yyyy', "en-GB") == "01/01/1970" ? null : formatDate(this.dpFechaVideoConsulta.value, 'dd/MM/yyyy', "en-GB");
          datos['mediosUsados'] = this.mediosUsados;
          datos['anamnesis'] = this.anamnesis;
          datos['exploracionFisica'] = this.exploracionFisica;
        }
        
        
      }
      //Si es VALORACIÓN VIDEO-CONSULTA
      /*if(this.encargo.enup_id_tipo_encargo == 5 || this.encargo.enup_id_tipo_encargo == 8 || this.encargo.enup_id_tipo_encargo == 10 ){
        datos['fuenteDocumental'] = this.fuenteDocumental == null ? "No se aporta" : this.fuenteDocumental;
        datos['formaProduccion'] = this.encargo.enup_forma_produccion;
        datos['fechaVideoConsulta'] = formatDate(this.dpFechaVideoConsulta.value, 'dd/MM/yyyy', "en-GB") == "01/01/1970" ? null : formatDate(this.dpFechaVideoConsulta.value, 'dd/MM/yyyy', "en-GB");
        datos['mediosUsados'] = this.mediosUsados;
        datos['anamnesis'] = this.anamnesis;
        datos['exploracionFisica'] = this.exploracionFisica;
        datos['pruebasRealizadasVideoConsulta'] = this.pruebasRealizadasVideoConsulta;
        datos['tratamientoRehabilitador'] = this.tratamientoRehabilitador;
        datos['intervencionesQuirurgicas'] = this.intervencionesQuirurgicas;
        datos['diasBasicos'] = this.diasBasicos;
        datos['diasModerados'] = this.diasModerados;
        datos['diasGraves'] = this.diasGraves;
        datos['diasMuyGraves'] = this.diasMuyGraves;
        datos['diasTotales'] = this.diasTotales;
        datos['perjuicioMoral'] = this.perjuicioMoral;
        datos['grado'] = this.grado;
        datos['porcentajeGrado'] = this.porcentajeGrado;
        datos['perjuicioPersonal'] = this.perjuicioPersonal;
        datos['grupoQuirurgico'] = this.grupoQuirurgico;
        datos['otrosCorrectores'] = this.otrosCorrectores;
        datos['observaciones'] = this.observaciones;
        //calcular puntos de las secuelas con la formula de balthazar
        datos['puntosSecuelas'] = this.totalPuntosSecuelas();
        datos['numSecuelas'] = this.numSecuelas.toString();
        //Para que aparezca la tabla del informe pero con los datos vacios
        if(this.numSecuelas == 0){
          datos['secuelas'] = JSON.stringify({"secuelas":[{"codigo":"","descripcion":"","puntos":""}]});
        }else{
          datos['secuelas'] = JSON.stringify(this.secuelaForm.value);
        }
        datos['descripcionPerjuicioEstetico'] = this.descripcionPerjuicioEstetico;
        datos['puntosPerjuicioEstetico'] = this.puntosPerjuicioEstetico;
        
      }
      if(this.encargo.enup_id_tipo_encargo == 9) {
        datos['fuenteDocumental'] = this.fuenteDocumental == null ? "No se aporta" : this.fuenteDocumental;
        datos['evolutivoConsultasValoracion'] = this.evolutivoConsultasValoracion;
        datos['observaciones'] = this.observaciones;
        datos['fechaProximaConsulta'] = this.fechaProximaConsulta;
        datos['fuenteDocumental'] = this.fuenteDocumental == null ? "No se aporta" : this.fuenteDocumental;
        datos['fechaBajaLaboral'] = this.bajaLaboral == "Si" ? this.fechaBajaLaboral : null;
        datos['fechaAltaLaboral'] = this.bajaLaboral == "Si" ? this.fechaAltaLaboral : null;
        datos['situacionIncapacidad'] = this.situacionIncapacidad;
        datos['tiempoRecuperacion'] = this.tiempoRecuperacion;
      }*/

      //Si es VALORACIÓN BASE DOCUMENTAL , ALTA+IAE BASE DOCUMENTAL y ALTA+IAE MUTUA LABORAL [Antiguo]
      /*if(this.encargo.enup_id_tipo_encargo == 6 || this.encargo.enup_id_tipo_encargo == 7 || this.encargo.enup_id_tipo_encargo == 10){
        datos['fuenteDocumental'] = this.fuenteDocumental == null ? "No se aporta" : this.fuenteDocumental;
        datos['intervencionesQuirurgicas'] = this.intervencionesQuirurgicas;
        datos['diasBasicos'] = this.diasBasicos;
        datos['diasModerados'] = this.diasModerados;
        datos['diasGraves'] = this.diasGraves;
        datos['diasMuyGraves'] = this.diasMuyGraves;
        datos['diasTotales'] = this.diasTotales;
        datos['diasBasicosMax'] = this.diasBasicosMax;
        datos['diasModeradosMax'] = this.diasModeradosMax;
        datos['diasGravesMax'] = this.diasGravesMax;
        datos['diasMuyGravesMax'] = this.diasMuyGravesMax;
        datos['diasTotalesMax'] = this.diasTotalesMax;
        datos['perjuicioMoral'] = this.perjuicioMoral;
        datos['grado'] = this.grado;
        datos['porcentajeGrado'] = this.porcentajeGrado;
        datos['perjuicioPersonal'] = this.perjuicioPersonal;
        datos['grupoQuirurgico'] = this.grupoQuirurgico;
        datos['otrosCorrectores'] = this.otrosCorrectores;
        datos['observaciones'] = this.observaciones;
        datos['observacionesAseguradora'] = this.observacionesAseguradora;
        //calcular puntos de las secuelas con la formula de balthazar
        datos['puntosSecuelasMin'] = this.totalPuntosSecuelasMin();
        datos['puntosSecuelasMax'] = this.totalPuntosSecuelasMax();
        datos['numSecuelas'] = this.numSecuelas.toString();
        //Para que aparezca la tabla del informe pero con los datos vacios
        if(this.numSecuelas == 0){
          datos['secuelas'] = JSON.stringify({"secuelas":[{"codigo":"","descripcion":"","puntosMin":"", "puntosMax":""}]});
        }else{
          datos['secuelas'] = JSON.stringify(this.secuelaHorquillaForm.value);
        }
        datos['descripcionPerjuicioEstetico'] = this.descripcionPerjuicioEstetico;
        datos['puntosPerjuicioEstetico'] = this.puntosPerjuicioEstetico;
        datos['puntosPerjuicioEsteticoMax'] = this.puntosPerjuicioEsteticoMax;
        
      }
      //Asesoria Graves
      if(this.encargo.enup_id_tipo_encargo == 11){
        datos['numInforme'] = this.numInforme;
        datos['fechaAsesoria'] = this.fechaAsesoria;
        datos['asesorMedico'] = this.asesorMedico;
        datos['observacionesAsesoria'] = this.observacionesAsesoria;
      }*/
    //Si es ASESORIA GRAVE
    
    /*else if(this.encargo.enup_id_tipo_encargo == 2 || this.encargo.enup_id_tipo_encargo == 3){
      datos['peticion'] = this.peticion;
    }*/

    this.funcionesService.generarInformeMedico(datos).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          this.notificacion.notificationOk(data['mensaje']);
          //traerme los archivos del encargo
          this.getArchivosExplorador();
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error => {
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }
 
  guardarDatosEncargo(opcion: string){
    if(this.idTipo != null && this.idMedico != null){
      if(opcion != "automatico"){
        this.mostrarProgressSpinner = true;
      }

      if(this.dpFechaPruebaBio.value == "Invalid Date") {
        this.dpFechaPruebaBio.setValue(null);
      }

      if(this.dpFechaPruebaTermo.value == "Invalid Date") {
        this.dpFechaPruebaTermo.setValue(null);
      }

      if(this.dpFechaUltimaConsulta.value == "Invalid Date") {
        this.dpFechaUltimaConsulta.setValue(null);
      }

      let datos = {
        'idMensajesMarcados': this.mensajesChecked,
        'idEncargo': this.idEncargo,
        'numExpediente': this.numExpediente,
        'nombreMedico': this.nombreMedico,
        'idMedico': this.idMedico,
        'idTipo': this.idTipo,
        'numColegiado': this.numColegiado,
        'nombreLesionado': this.nombreLesionado,
        'edadLesionado': this.edadLesionado,
        'provincia': this.provincia,
        'localidad': this.localidad,
        'responsabilidad': this.responsabilidad,
        'peticion': this.peticion,
        'prioridad': this.isUrgente == true ? 1 : null,
        'fechaSiniestro': this.fechaSiniestro,
        'fechaLimite': this.encargo.enup_fecha_limite,
        'refSiniestro': this.refSiniestro,
        'objetoInforme': this.objetoInforme,
        'profesion': this.profesion,
        'situacionLaboral': this.situacionLaboral,
        'bajaLaboral': this.bajaLaboral,
        'fechaBajaLaboral': this.fechaBajaLaboral,
        'fechaAltaLaboral': this.fechaAltaLaboral,
        'contingencia': this.contingencia,
        'antecedentes': this.antecedentes,
        'centroUrgencias': this.centroUrgencias,
        'cuandoAcude': this.cuandoAcude,
        'diagnosticoUrgencias': this.diagnosticoUrgencias,
        'hospitalizacionUrgencias': this.hospitalizacionUrgencias,
        'centroRehab': this.centroRehab,
        'medicoSeguimiento': this.medicoSeguimiento,
        'inicioTratamiento': this.inicioTratamiento,
        'altaMedica': this.altaMedica,
        'numConsultas': this.numConsultas,
        'numSesiones': this.numSesiones,
        'pruebasRealizadas': this.pruebasRealizadas,
        'evolucion': this.evolucion,
        'fuenteDocumental': this.fuenteDocumental,
        'referenciaInforme': this.referenciaInforme,
        'firmadoPor': this.firmadoPor,
        'tipoImpacto': this.tipoImpacto,
        'deltaV': this.deltaV,
        'aceleracion': this.aceleracion,
        'fechaVideoConsulta': formatDate(this.dpFechaVideoConsulta.value, 'dd/MM/yyyy', "en-GB") == "01/01/1970" ? null : formatDate(this.dpFechaVideoConsulta.value, 'dd/MM/yyyy', "en-GB"),
        'mediosUsados': this.mediosUsados,
        'anamnesis': this.anamnesis,
        'exploracionFisica': this.exploracionFisica,
        'consideracion': this.consideracion,
        'conclusiones': this.conclusiones,
        'pruebasRealizadasVideoConsulta': this.pruebasRealizadasVideoConsulta,
        'tratamientoRehabilitador': this.tratamientoRehabilitador,
        'intervencionesQuirurgicas': this.intervencionesQuirurgicas,
        'diasBasicos': this.diasBasicos,
        'diasModerados': this.diasModerados,
        'diasGraves': this.diasGraves,
        'diasMuyGraves': this.diasMuyGraves,
        'diasTotales': this.diasTotales,
        'diasBasicosMax': this.diasBasicosMax,
        'diasModeradosMax': this.diasModeradosMax,
        'diasGravesMax': this.diasGravesMax,
        'diasMuyGravesMax': this.diasMuyGravesMax,
        'diasTotalesMax': this.diasTotalesMax,
        'perjuicioMoral': this.perjuicioMoral,
        'grado': this.grado,
        'porcentajeGrado': this.porcentajeGrado,
        'perjuicioPersonal': this.perjuicioPersonal,
        'grupoQuirurgico': this.grupoQuirurgico,
        'otrosCorrectores': this.otrosCorrectores,
        'observaciones': this.observaciones,
        'observacionesIMD': this.observacionesIMD,
        'observacionesAseguradora': this.observacionesAseguradora,
        'proximaRevision': this.proximaRevision,
        'secuelasJson': JSON.stringify(this.secuelaForm.value),
        'descripcionPerjuicioEstetico': this.descripcionPerjuicioEstetico,
        'puntosPerjuicioEstetico': this.puntosPerjuicioEstetico,
        'puntosPerjuicioEsteticoMax': this.puntosPerjuicioEsteticoMax,
        'evolutivoConsultasValoracion': this.evolutivoConsultasValoracion,
        'fechaProximaConsulta': this.fechaProximaConsulta,
        'fechaConsultaVideoConsulta': this.fechaConsultaVideoConsulta,
        'horaConsultaVideoConsulta': this.horaConsultaVideoConsulta,
        'mecanismoProduccion': this.mecanismoProduccion,
        'rutaSala': this.rutaSala,
        'numInforme': this.numInforme,
        'fechaAsesoria': this.fechaAsesoria,
        'asesorMedico': this.asesorMedico,
        'observacionesAsesoria': this.observacionesAsesoria,
        'formato_consulta' : this.id_formato_consulta,
        'xDanoMoral'                   : this.xDanoMoral,
        'xDanoMoralEstetico'           : this.xDanoMoralEstetico,
        'xPerjuicioMoral'              : this.xPerjuicioMoral,
        'xPerjuicioExcepcional'        : this.xPerjuicioExcepcional,
        'xGastosPrevisibles'           : this.xGastosPrevisibles,
        'xProtesisOrtesis'             : this.xProtesisOrtesis,
        'xRehabilitacion'              : this.xRehabilitacion,
        'xAyudasTecnicas'              : this.xAyudasTecnicas,
        'xAdecuacion'                  : this.xAdecuacion,
        'xPerjuicioPatrimonial'        : this.xPerjuicioPatrimonial,
        'xHorasNecesarias'             : this.xHorasNecesarias,
        'horasNecesarias'              : this.horasNecesarias,
        'valoracionFinal'              : this.valoracionFinal,
        'valoracionProv'               : this.valoracionProv,
        'imd'                          : this.imd,
        'jsonIntervenciones'           : JSON.stringify(this.intervencionForm.value),
        'reservaTecnica'               : this.reservaTecnica,
        'diasBasicosReserva'  : this.dias_ppp_basicos_reserva,
        'diasModeradosReserva' : this.dias_ppp_moderados_reserva,
        'diasGravesReserva' : this.dias_ppp_graves_reserva,
        'diasMuyGravesReserva' : this.dias_ppp_muy_graves_reserva,
        'diasTotalesReserva' : this.dias_ppp_totales_reserva,
        'jsonSecuelasReserva' : JSON.stringify(this.secuelaReservaForm.value),
        'descripcionPerjuicioEsteticoReserva' : this.descripcion_perjuicio_estetico_reserva,
        'puntosPerjuicioEsteticoReserva' : this.puntos_perjuicio_estetico_reserva,
        'perjuicioMoralReserva' : this.perjuicio_moral_reserva,
        'perjuicioPersonalReserva' : this.perjuicio_personal_reserva,
        'gradoReserva' : this.grado_reserva,
        'porcentajeGradoReserva' : this.porcentaje_reserva,
        'xDanoMoralReserva' : this.x_dano_moral_reserva,
        'xDanoMoralEsteticoReserva' : this.x_dano_moral_estetico_reserva,
        'xPerjuicioMoralReserva' : this.x_dano_moral_reserva,
        'xPerjuicioExcepcionalReserva' : this.x_perjuicio_excepcional_reserva,
        'xGastosPrevisiblesReserva' : this.x_gastos_previsibles_reserva,
        'xProtesisOrtesisReserva' : this.x_protesis_ortesis_reserva,
        'xRehabilitacionReserva' : this.x_rehabilitacion_reserva,
        'xAyudasTecnicasReserva' : this.x_ayudas_tecnicas_reserva,
        'xAdecuacionReserva' : this.x_adecuacion_reserva,
        'xPerjuicioPatrimonialReserva' : this.x_perjuicio_patrimonial_reserva,
        'xHorasNecesariasReserva' : this.x_horas_necesarias_reserva,
        'otros_correctores_reserva' : this.otros_correctores_reserva,
        'horasNecesariasReserva' : this.horas_necesarias_reserva,
        'observacionesReserva' : this.observaciones_reserva,
        'jsonIntervencionesReserva' : JSON.stringify(this.intervencionReservaForm.value),
        'situacionIncapacidad' : this.situacionIncapacidad,
        'tiempoRecuperacion' : this.tiempoRecuperacion,
        'idNombreTramitador' : this.idNombreTramitador,
        'encargoAnterior'    : this.getEncargoEjecutado,
        //INFORME COHERENCIA
        'xBioFuncional':  this.xBioFuncional == false || this.xBioFuncional == true ? Number(this.xBioFuncional) : null,
        'xTermoRealizada':  this.xTermoRealizada == false || this.xTermoRealizada == true ? Number(this.xTermoRealizada) : null,

        'diagnosticoInicial':  this.diagnosticoInicial,
        'determinacion':  this.determinacion,
        'xTMCC':  Number(this.xTMCC),
        'xPatoColumna':  Number(this.xPatoColumna),
        'xEnfermedades':  Number(this.xEnfermedades),
        'xBajaMutua':  Number(this.xBajaMutua),
        'realizadoPor' : this.medicoConsulta?.med_nombre ?? null,
        'numColegiadoMedicoConsulta' : this.medicoConsulta?.med_num_colegiado ?? null,
        'fechaUltimaConsulta' : this.dpFechaUltimaConsulta.value == null ? null : formatDate(this.dpFechaUltimaConsulta.value, 'dd/MM/yyyy', "en-GB"),
        'condicionesRehuse' : this.condicionesRehuse == false || this.condicionesRehuse == true ? Number(this.condicionesRehuse) : null,

      };

      if (this.xBioFuncional) {
        datos['xPruebaConsistente'] = this.xPruebaConsistente == false || this.xPruebaConsistente == true ? Number(this.xPruebaConsistente) : null;
        datos['xResultPruebaBio'] = this.xResultPruebaBio == false || this.xResultPruebaBio == true ? Number(this.xResultPruebaBio) : null;
        datos['xCoherenteBio'] = this.xCoherenteBio == false || this.xCoherenteBio == true ? Number(this.xCoherenteBio) : null;
        datos['fechaPruebaBio'] = this.dpFechaPruebaBio.value == null ? null : formatDate(this.dpFechaPruebaBio.value, 'dd/MM/yyyy', "en-GB");
        datos['valorNFHAS'] = this.valorNFHAS;
      }

      if(this.xTermoRealizada) {
        datos['xIndicadorCoherencia']= this.xIndicadorCoherencia == false || this.xIndicadorCoherencia == true ? Number(this.xIndicadorCoherencia) : null;
        datos['xResultadoPrueba']= this.xResultadoPrueba == false || this.xResultadoPrueba == true ? Number(this.xResultadoPrueba) : null;
        datos['xCoherenteTermo']= this.xCoherenteTermo == false || this.xCoherenteTermo == true ? Number(this.xCoherenteTermo) : null;
        datos['fechaPruebaTermo']= this.dpFechaPruebaTermo.value == null ? null : formatDate(this.dpFechaPruebaTermo.value, 'dd/MM/yyyy', "en-GB");
        datos['valorDeltaV']= this.valorDeltaV;
        datos['zonaAnalizada']= this.zonaAnalizada;
        datos['resultadoEco']= this.resultadoEco;
      }


      if(opcion == "enviar"){
        if(!this.rutaSala && this.fechaConsultaVideoConsulta){
          datos['rutaSala'] = this.crearSalaVideoConsulta();
        } 
        this.funcionesService.updateDatosEncargo(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              if(this.existeDocMedico){
                this.enviarEncargo();
              }else{
                this.notificacion.notificationFail("Carpeta DOC Médico no creada");
              }
            }else{
              this.notificacion.notificationFail(data['mensaje']);

            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            this.notificacion.notificationFail("Error en el servidor");
          }
        );
      }else if(opcion == "reEnviar"){
        this.funcionesService.updateDatosEncargo(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              this.pasarEncargoReEnviar();
            }else{
              this.notificacion.notificationFail(data['mensaje']);

            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            this.notificacion.notificationFail("Error en el servidor");
          }
        );

      }else if(opcion == "reEnviarFinalizado"){
        this.funcionesService.updateDatosEncargo(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              this.pasarEncargoDeFinalizadoAEnviado();
            }else{
              this.notificacion.notificationFail(data['mensaje']);

            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            this.notificacion.notificationFail("Error en el servidor");
          }
        );
      }else if(opcion == "pendienteRevision"){
        this.funcionesService.updateDatosEncargo(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              this.pasarEncargoPendienteRevision();
            }else{
              this.notificacion.notificationFail(data['mensaje']);

            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            this.notificacion.notificationFail("Error en el servidor");
          }
        );
      }else if(opcion == "finalizado"){
        this.funcionesService.updateDatosEncargo(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              this.pasarEncargoFinalizado();
            }else{
              this.notificacion.notificationFail(data['mensaje']);

            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            this.notificacion.notificationFail("Error en el servidor");
          }
        );
      }else if(opcion == "informe"){
        let checkCampos = this.checkCamposObligatorios();
        //Checkea los campos obligatorios a la hora de generar informe
        if(checkCampos.check){
          this.funcionesService.updateDatosEncargo(datos).subscribe(
            data =>{
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.generarInforme();
              }else{
                this.notificacion.notificationFail(data['mensaje']);
  
              }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );
        }
        else{
          this.mostrarProgressSpinner = false;
          this.notificacion.notificationFail(checkCampos.camposFaltantes);
        }
        
      }else if(opcion == "automatico"){
        this.funcionesService.updateDatosEncargo(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              console.log("Guardado automático realizado");
            }else{
              console.log("Error guardado automático");
            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            console.log("Error servidor guardado automático");
          }
        );
      }else if(opcion == "descargarDocMedico") {
        this.funcionesService.downloadZipFolder(this.archivosCarpetaDocMedico, this.rutaCarpetaDocMedico).subscribe({
          next: (data: any) => {
            //console.log(data);
            this.funcionesService.verArchivo(data['ruta']).subscribe({
              next: (data: any) => {
                this.descargarchivos(data, "DocMedicoZip", "application/zip");
    
              },
              error: (err: any) => {
                this.funcionesService.deleteZipFolder(data['ruta']).subscribe({
                  next: (data: any) => {

                  },
                  error: (err: any) => {},
                  complete: () => {}
                });
              },
              complete: () => {
                this.mostrarProgressSpinner = false;
                this.funcionesService.deleteZipFolder(data['ruta']).subscribe({
                  next: (data: any) => {

                  },
                  error: (err: any) => {},
                  complete: () => {}
                });
              }
            });

          },
          error: (err: any) => {
                  
          },
          complete: () => {
            
          }
        })
      }else{
        this.funcionesService.updateDatosEncargo(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              this.notificacion.notificationOk(data['mensaje']);
              //Borrar secuelas para no sumarlas al array ya creado
              this.secuelas().clear();
              this.secuelasReserva().clear();
              this.intervenciones().clear();
              this.intervencionesReserva().clear();
              this.secuelasHorquilla().clear();
              this.getEncargo();
              this.getMensajesEncargo();
            }else{
              this.notificacion.notificationFail(data['mensaje']);
              //this.getEncargo();
              this.getMensajesEncargo();
            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            this.notificacion.notificationFail("Error en el servidor");
            //this.getEncargo();
            this.getMensajesEncargo();
          }
        );
      }
    }else{
      this.mostrarProgressSpinner = false;
      this.notificacion.notificationFail("El campo 'Medicos' o 'Tipo' no estan rellenos");
    }
     
    
  }

  getEncargo(){
    this.funcionesService.getEncargoById(this.idEncargo,this.tramitador.trm_id_user).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        
        if(data['response'] == "ok"){
          this.encargo = data['encargo'];
          //console.log(this.encargo);
          this.encargoAnterior = data['encargoAnterior'];
          this.camposVideoConsultaAnteriores = data['encargoVideoConsultaAnteriores'];
          this.encargosAnterioresAsesoriaGraves = data['encargosAnterioresAsesoriaGraves'];
          this.fechaLimiteFormmated = this.datePipe.transform(this.encargo.enup_fecha_limite,"yyyy-MM-dd");
          this.id_formato_consulta = this.encargo.formato_consulta;

          this.countMensajes = data['countMensajes'];
          //mostrar botones Fab
          this.mostrarBotonesFab();
          //Traerme los encargos con la misma referencia siniestro
          this.getEncargosBySiniestro(this.encargo.enup_referencia_siniestro);

          this.getMedicoEncargo(this.encargo.enup_id_medico);

          this.getMedicoByName();

          //Una vez cargado el encargo relleno el campo de los informes
          this.rellenarCamposInforme();

          //traerme los archivos del encargo
          this.getArchivosExplorador();

          //comprobar si existe la carpeta DOC Medico
          this.existeCarpetaDocMedico();
          
          console.log("encargo anterior:", this.encargo.enup_encargo_anterior);

          

          

          //guardado automatico
          if(this.encargo.enup_id_estado_encargo != 1){
            this.guardadoAutomatico();
          }
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }, 
    );
  }

  existeCarpetaDocMedico(){
    let datos = {
      'numExpediente': this.encargo.enup_expediente,
      'idTipoEncargo': this.encargo.enup_id_tipo_encargo,
      'idEstado': this.encargo.enup_id_estado_encargo,
      'idCia': this.encargo.enup_cia
    };

    //Me traigo los archivos para el explorador
    this.medicoService.getArchivosExploradorMedico(datos).subscribe(
      data => {
        if(data['response'] == "ok"){
          //console.log(data['archivos']);
          this.rutaCarpetaDocMedico = data['ruta'];
          this.archivosCarpetaDocMedico =data['archivos'];
          this.existeDocMedico = true;
        }else if(data['response'] == "fail"){
          this.existeDocMedico = false;
          this.notificacion.notificationFail(data['mensaje']); 
        }else{
          this.existeDocMedico = false;
        }
        
      }, error => {
        this.mostrarProgressSpinner = false;
        this.existeDocMedico = false;
      }
    );
  }

  enviarEncargo(){
    //Mostrar dialogo de confirmacion
    const dialogEnviar = this.dialog.open(EnviarDialogComponent,{
      data: {comentario: this.comentario}
    });

    dialogEnviar.afterClosed().subscribe(
      result =>{
        const idEstadoEnviado = 2;
        let datos = {
          'idEncargo': this.idEncargo,
          'idEstado': idEstadoEnviado
        };

        this.funcionesService.cambiarEstadoEncargo(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              let datosMensaje = {
                'idEncargo': this.idEncargo,
                'idUsuario': this.tramitador.trm_id_user,
                'textoMensaje': result
              };
              //Si es un encargo urgente se le envia un email al medico
              if(this.isUrgente){
                let datosEmail = {
                  'idEncargo': this.idEncargo
                };
                this.mostrarProgressSpinner = true;
                //Enviar email al medico
                this.enviarEmailEncargoUrgente(datosEmail);
              }

              //crear autorizacion y enviar mensaje
              this.crearAutorizacion(datosMensaje);

            }else{
              this.notificacion.notificationFail(data['mensaje']);
            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            this.notificacion.notificationFail("Error en el servidor");
          }
        ); 
      }
    );
 
  }

  //Enviar email encargo urgente
  enviarEmailEncargoUrgente(datosEmail){
    this.tramitadorService.enviarEmailEncargoUrgente(datosEmail).subscribe(
      data =>{
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){

        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  //Crear autorizacion y enviar mensaje

  crearAutorizacion(datosMensaje){
    this.mostrarProgressSpinner = true;
    let datos = {
      'idMedico': this.idMedico,
      'idEncargo': this.idEncargo,
      'idExpedienteCrm': this.encargo.enup_id_expediente,
      'idTipo' : this.idTipo,
      'idCia': this.encargo.enup_cia
    };
    this.funcionesService.crearAut(datos).subscribe(
      data =>{
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          this.funcionesService.addMensajeEncargo(datosMensaje).subscribe(
            data => {
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.notificacion.notificationOk("Tarea guardada en estado: Enviado. Se te redireccionará en 3 segundos");
                setTimeout(() => {
                  this.router.navigate(['/tramitador-listado/valoracionDocumental']);
                }, 3000);
              }else{
                this.notificacion.notificationFail(data['mensaje']);
              }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor. Autorización no creada");
        //const errorMessage = typeof error === 'string' ? error : JSON.stringify(error);
        //this.notificacion.notificationFail(errorMessage);
      }
    );
  }

  pasarEncargoDeFinalizadoAEnviado(){
    //Mostrar dialogo de confirmacion
    const dialogReEnviarDesdeFinalizado = this.dialog.open(ReEnviarDesdeFinalizadoDialogComponent,{
      data: {comentario: this.comentario}
    });

    dialogReEnviarDesdeFinalizado.afterClosed().subscribe(
      result =>{
        //Si pulsa el boton "Si" del dialog
        if(result){
          //Enviar mensaje al encargo
          let datosMensaje = {
            'idEncargo': this.idEncargo,
            'idUsuario': this.tramitador.trm_id_user,
            'textoMensaje': result
          };

          this.funcionesService.addMensajeEncargo(datosMensaje).subscribe(
            data => {
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.mostrarProgressSpinner = true;
                //ID de estado Enviado
                const idEstadoEnviado = 2

                let datos = {
                  'idEncargo': this.idEncargo,
                  'idEstado': idEstadoEnviado
                };

                this.funcionesService.cambiarEstadoEncargo(datos).subscribe(
                  data =>{
                    this.mostrarProgressSpinner = false;
                    if(data['response'] == "ok"){
                      this.notificacion.notificationOk(data['mensaje'] + " Se te redireccionará en 3 segundos");
                      setTimeout(() => {
                        this.router.navigate(['tramitador-listado/valoracionDocumental']);
                      }, 3000);
                    }else{
                      this.notificacion.notificationFail(data['mensaje']);
                    }
                  }, error =>{
                    this.mostrarProgressSpinner = false;
                    this.notificacion.notificationFail("Error en el servidor");
                  }
                );
              }else{
                this.notificacion.notificationFail(data['mensaje']);
              }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );

        }
      }
    ); 
  }

  pasarEncargoReEnviar(){
    //Mostrar dialogo de confirmacion
    const dialogEnviar = this.dialog.open(EnviarDialogComponent,{
      data: {comentario: this.comentario}
    });

    dialogEnviar.afterClosed().subscribe(
      result =>{
        //Si pulsa el boton "Si" del dialog
        if(result){
          //Enviar mensaje al encargo
          let datosMensaje = {
            'idEncargo': this.idEncargo,
            'idUsuario': this.tramitador.trm_id_user,
            'textoMensaje': result
          };

          this.funcionesService.addMensajeEncargo(datosMensaje).subscribe(
            data => {
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.mostrarProgressSpinner = true;
                //ID de estado Enviado
                const idEstadoEnviado = 2

                let datos = {
                  'idEncargo': this.idEncargo,
                  'idEstado': idEstadoEnviado
                };

                this.funcionesService.cambiarEstadoEncargo(datos).subscribe(
                  data =>{
                    this.mostrarProgressSpinner = false;
                    if(data['response'] == "ok"){
                      this.notificacion.notificationOk(data['mensaje'] + " Se te redireccionará en 3 segundos");
                      setTimeout(() => {
                        this.router.navigate(['tramitador-listado/valoracionDocumental']);
                      }, 3000);
                    }else{
                      this.notificacion.notificationFail(data['mensaje']);
                    }
                  }, error =>{
                    this.mostrarProgressSpinner = false;
                    this.notificacion.notificationFail("Error en el servidor");
                  }
                );
              }else{
                this.notificacion.notificationFail(data['mensaje']);
              }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );

        }
      }
    );  
  }

  mostrarBotonesFab(){
    switch(this.encargo.enup_estado_encargo){
      //estado Tramitacion
      case 'Tramitacion':
        this.mostrarBorrar = true;
        this.mostrarEnviar = true;
        this.mostrarSelectTramitacion = true;
        break;

      case 'Enviado':
        this.mostrarCancel = true;
        this.mostrarPendienteRevision = true;
        this.mostrarFinalizado = true;
        break;

      case 'Pendiente de revision':
        this.mostrarCancel = true;
        this.mostrarFinalizado = true;
        this.mostrarReEnviar = true;
        break;
      case 'Finalizado':
        this.mostrarCancel = true;
        this.mostrarReEnviarFinalizado = true;
        break;
    }
  }

  getMedicoByName() {
    this.tramitadorService.getMedicoByName(this.encargo.enup_medico_consulta_medica).subscribe(
      data =>{
        if(data['status'] == 200){
          this.medicoConsulta = data['data'][0];
        }

      }, error=>{
        this.notificacion.notificationFail("Error al cargar médico de consulta");
      }
    );
  }

  compareMedico(m1: any, m2: any): boolean {
    return m1 && m2 ? m1.med_nombre === m2.med_nombre : m1 === m2;
  }

  //Listado de tipos y medicos del estado en tramitacion
  getMedicosTiposTramitacion(){
    this.tramitadorService.getDatosNuevaTarea().subscribe(
      data =>{
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          this.medicos = data['medicos'];
          this.tipos = data['tipos'];
        }
        this.tipos.splice(0,11);
        
      }, error=>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  pasarEncargoFinalizado(){
    //Mostrar dialogo de confirmacion
    const dialogFinalizado = this.dialog.open(FinalizadoDialogComponent, {
      data: {moverEncargoFinalizado: this.moverEncargoFinalizado}
    });

    dialogFinalizado.afterClosed().subscribe(
      result =>{
        if(result){
          //No hacer nada ya que ha clickado en NO
          if(result === 2){
          //Pasar a finalizado y mover carpeta
          }else{
            this.mostrarProgressSpinner = true;
            //ID de estado Pendiente revision
            const idEstadoFinalizado = 4;

            let datos = {
              'idEncargo': this.idEncargo,
              'idEstado': idEstadoFinalizado,
              'moverEncargo': true
            };

            this.funcionesService.cambiarEstadoEncargo(datos).subscribe(
              data =>{
                this.mostrarProgressSpinner = false;
                if(data['response'] == "ok"){
                  this.notificacion.notificationOk(data['mensaje'] + " Se te redireccionará en 3 segundos");
                  this.mensajesLeidos("Con notificacion");
                  setTimeout(() => {
                    this.router.navigate(['tramitador-listado/valoracionDocumental']);
                  }, 3000);
                }else{
                  this.notificacion.notificationFail(data['mensaje']);
                }
              }, error =>{
                this.mostrarProgressSpinner = false;
                this.notificacion.notificationFail("Error en el servidor");
              }
            );
          }
        //Pasar a finalizado y NO mover carpeta
        }else if(result === false){
          this.mostrarProgressSpinner = true;
          //ID de estado Pendiente revision
          const idEstadoFinalizado = 4;

          let datos = {
            'idEncargo': this.idEncargo,
            'idEstado': idEstadoFinalizado,
            'moverEncargo': false
          };

          this.funcionesService.cambiarEstadoEncargo(datos).subscribe(
            data =>{
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.notificacion.notificationOk(data['mensaje'] + " Se te redireccionará en 3 segundos");
                this.mensajesLeidos("Con notificacion");
                setTimeout(() => {
                  this.router.navigate(['tramitador-listado/valoracionDocumental']);
                }, 3000);
              }else{
                this.notificacion.notificationFail(data['mensaje']);
              }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );
        }
      }
    );
  }

  pasarEncargoPendienteRevision(){
    //Mostrar dialogo de confirmacion
    const dialogPendienteRevision = this.dialog.open(PendienteRevisionDialogComponent,{
      data: {comentario: this.comentario}
    });
    dialogPendienteRevision.afterClosed().subscribe(
      result =>{
        //Si pulsa el boton "Si" del dialog
        if(result){
          //Enviar mensaje al encargo
          let datosMensaje = {
            'idEncargo': this.idEncargo,
            'idUsuario': this.tramitador.trm_id_user,
            'textoMensaje': result
          };

          this.funcionesService.addMensajeEncargo(datosMensaje).subscribe(
            data => {
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.mostrarProgressSpinner = true;
                //ID de estado Pendiente revision
                const idEstadoPendienteRevision = 3;

                let datos = {
                  'idEncargo': this.idEncargo,
                  'idEstado': idEstadoPendienteRevision
                };

                this.funcionesService.cambiarEstadoEncargo(datos).subscribe(
                  data =>{
                    this.mostrarProgressSpinner = false;
                    if(data['response'] == "ok"){
                      this.notificacion.notificationOk(data['mensaje'] + " Se te redireccionará en 3 segundos");
                      setTimeout(() => {
                        this.router.navigate(['tramitador-listado/valoracionDocumental']);
                      }, 3000);
                    }else{
                      this.notificacion.notificationFail(data['mensaje']);
                    }
                  }, error =>{
                    this.mostrarProgressSpinner = false;
                    this.notificacion.notificationFail("Error en el servidor");
                  }
                );
              }else{
                this.notificacion.notificationFail(data['mensaje']);
              }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );

        }
      }
    );
  }

  cancelarEncargo(){
    //Mostrar dialogo de confirmacion
    const dialogCancelar = this.dialog.open(CancelarEncargoDialogComponent,{
      data: {comentario: this.comentario}
    });
    dialogCancelar.afterClosed().subscribe(
      result =>{
        //Si pulsa el boton "Si" del dialog
        if(result){
          this.mostrarProgressSpinner = true;
          //ID de estado cancelado
          const idEstadoCancelado = 5;

          let datos = {
            'idEncargo': this.idEncargo,
            'idEstado': idEstadoCancelado,
            'comentario': result
          };

          this.funcionesService.cambiarEstadoEncargo(datos).subscribe(
            data =>{
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.notificacion.notificationOk(data['mensaje'] + " Se te redireccionará en 3 segundos");
                setTimeout(() => {
                  this.router.navigate(['tramitador-listado/valoracionDocumental']);
                }, 3000);
              }else{
                this.notificacion.notificationFail(data['mensaje']);
              }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );
        }
      }
    );
    
  }

  borrarEncargo(){
    //Mostrar dialogo de confirmacion
    const dialogBorrarEncargo = this.dialog.open(BorrarEncargoDialogComponent);

    dialogBorrarEncargo.afterClosed().subscribe(
      result =>{
        //Si pulsa el boton "Si" del dialog
        if(result){
          //Eliminar encargo
          this.funcionesService.borrarEncargo(this.idEncargo).subscribe(
            data =>{
              this.mostrarProgressSpinner = false;
                if(data['response'] == "ok"){
                  this.mostrarProgressSpinner = true;
                  this.notificacion.notificationOk(data['mensaje'] + " Se te redireccionará en 3 segundos.");
                  setTimeout(() => {
                    this.router.navigate(['tramitador-principal'])
                    .then(() => {
                      window.location.reload();
                    });
                  }, 3000);
                  
                }else{
                  this.notificacion.notificationFail(data['mensaje']);
                }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );
        }
      }
    );
    
  }

  getEncargosBySiniestro(refSiniestro: string){
    this.mostrarProgressSpinner = true;
    this.medicoService.getEncargosBySiniestro(refSiniestro, this.tramitador.trm_id_user, 1).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          //Se omiten los encargos cancelados
          this.encargosBySiniestro = data['encargosBySiniestro'].filter(encargo => encargo.enup_id_estado_encargo != 5);
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  getMensajesEncargo(){
    this.mostrarProgressSpinner = true;
    this.funcionesService.getMensajesEncargo(this.idEncargo,this.tramitador.trm_id_user).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          this.mensajes = data['mensajes'];
        }else{

        }
      }
    );
  }

  enviarMensajeEncargo(){
    this.mostrarProgressSpinner = true;
    var datos = {
      'idEncargo': this.idEncargo,
      'idUsuario': this.tramitador.trm_id_user,
      'textoMensaje': this.textoMensaje
    };
    
    this.funcionesService.addMensajeEncargo(datos).subscribe(
      data => {
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          //Limpiar text area
          this.textoMensaje = '';
          this.notificacion.notificationOk(data['mensaje']);
          this.getMensajesEncargo();
          //this.getEncargo();
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  mensajeCheck(event: MatCheckboxChange, idMensaje: number){
    if(event.checked == true){
      this.mensajesChecked.push(idMensaje);
    }else{
      let posicion = this.mensajesChecked.indexOf(idMensaje);
      this.mensajesChecked.splice(posicion, 1);
    }
  }

  mensajesLeidos(conNotificacion?){
    this.funcionesService.mensajesLeidos(this.mensajes, this.tramitador.trm_id_user).subscribe(
      data =>{
        if(data['response'] == "ok"){
          this.countMensajes = 0;
          if(conNotificacion == null){
            this.notificacion.notificationOk("Los mensajes se han marcados como leídos");
          }
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }

  abrirEncargoSiniestro(idEncargo: number){
    this.router.navigate(['encargo-tramitador', {idEncargo: idEncargo}]);
  }

  //Crear sala de videoConsulta
  crearSalaVideoConsulta(){
    let nombreSala = this.encargo.id_plataforma;
    let rutaSala;
    
    const domain = 'teleconsulta.upsan.net';
    const options = {
        roomName: nombreSala,
        width: 720,
        height: 720,
        parentNode: document.querySelector('#meet'),
        userInfo: {
          email: 'test@mail.ru',
          displayName: 'admin'
        }
    };
    const api = new JitsiMeetExternalAPI(domain, options);

    api.addEventListener('participantRoleChanged', function(event) {
      if (event.role === "moderator") {
          api.executeCommand('password', 'Ges7102-');
      }
    });

    rutaSala = "https://" + domain + "/" + nombreSala;
    return rutaSala;
  }

  onChangeOption(type): void {

    let myActive = this.el.nativeElement.querySelector(".active");
    let divMyActive = this.el.nativeElement.querySelector("." + myActive.id);
    let myTag = this.el.nativeElement.querySelector("[id='" + type + "']");
    let divMyTag = this.el.nativeElement.querySelector("." + type);

    divMyActive.classList.add('noshow');
    myActive.classList.remove('active');

    if (!myTag.classList.contains('active')) {
      myTag.classList.add('active');
      divMyTag.classList.remove('noshow');
    }

    //Si clica en el tab mensajes pone todos los mensajes como leidos
    /* if(type == "mensajes"){
      this.funcionesService.mensajesLeidos(this.mensajes, this.tramitador.trm_id_user).subscribe(
        data =>{
          if(data['response'] == "ok"){
            this.countMensajes = 0;
          }else{
            this.notificacion.notificationFail(data['mensaje']);
          }
        }, error =>{
          this.notificacion.notificationFail("Error en el servidor");
        }
      );
    } */
  }

  colorEncargo(fechaLimite: string){
    var color = '';
    var fechaNow = new Date();
    var fechaLimiteDosAntes = new Date(fechaLimite);
    var fechaLimiteFormat = new Date(fechaLimite);

    if(fechaLimite != null){

      fechaLimiteDosAntes.setDate(fechaLimiteFormat.getDate() - 2);

      if(fechaNow<fechaLimiteDosAntes){
        color = 'color_verde';
      }else if(fechaNow.getFullYear()==fechaLimiteDosAntes.getFullYear() && fechaNow.getMonth()==fechaLimiteDosAntes.getMonth() && fechaNow.getDate()==fechaLimiteDosAntes.getDate()
      || (fechaNow>fechaLimiteDosAntes && fechaNow<fechaLimiteFormat)){
        color = 'color_amarillo';
      }else if(fechaNow.getFullYear()==fechaLimiteFormat.getFullYear() && fechaNow.getMonth()==fechaLimiteFormat.getMonth() && fechaNow.getDate()==fechaLimiteFormat.getDate() 
      || fechaNow>fechaLimiteFormat){
        color = 'color_rojo';
      }else{
        color = '';
      }
  
      return color;
    }
  }

  onChangeFechaLimite(){
    this.encargo.enup_fecha_limite = this.fechaLimiteFormmated;
  }

  onChangeLimpiarMoral(){
    this.grado = null;
    this.porcentajeGrado = null;
  }

  onChangeLimpiarMoralReserva(){
    this.grado = null;
    this.porcentajeGrado = null;
  }

  onChangeLimpiarPersonal(){
    this.grupoQuirurgico = null;
  }

  onChangeLimpiarPersonalReserva(){
    this.grupoQuirurgico = null;
  }

  //File Explorer
  currentRoot: Archivo;
  currentPath: string;
  canNavigateUp = false;

  navigateToFolder(element: Archivo) {
    this.currentRoot = element;
    this.updateFileElementQuery();
    this.currentPath = this.pushToPath(this.currentPath, element.archDescripcion);
    this.canNavigateUp = true;
  }

  navigateUp() {
    if (this.currentRoot && this.currentRoot.archParent === 'root') {
      this.currentRoot = null;
      this.canNavigateUp = false;
      this.updateFileElementQuery();
    } else {
      this.currentRoot = this.fileService.get(this.currentRoot.archParent);
      this.updateFileElementQuery();
    }
    this.currentPath = this.popFromPath(this.currentPath);
  }

  //Actualizar lista de archivos
  updateFileElementQuery() {
    //file explorer lista archivos
    this.archivos = this.fileService.queryInFolder(this.currentRoot ? this.currentRoot.archPath : 'root');
  }

  pushToPath(path: string, folderName: string) {
    let p = path ? path : '';
    p += `${folderName}/`;
    return p;
  }

  popFromPath(path: string) {
    let p = path ? path : '';
    let split = p.split('/');
    split.splice(split.length - 2, 1);
    p = split.join('/');
    return p;
  }

  copyElement(element: Archivo){
    //Capturo el archivo a pegar
    this.archivoPegar = element;
  }

  pegarElement(){
    this.mostrarProgressSpinner = true;
    //Ruta donde pegar el archivo
    let rutaPegar = this.currentRoot ? this.currentRoot.archPath : 'root';
    //Pegar archivo capturado en el copyelement en la ruta indicada
    let datos = {
      'idEncargo': this.encargo.id,
      'archivo': this.archivoPegar,
      'rutaPegar': rutaPegar
    };
    this.funcionesService.copiarPegar(datos).subscribe(
      data =>{
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          this.notificacion.notificationOk(data['mensaje']);
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
        this.getArchivosExplorador();
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
        this.getArchivosExplorador();
      }
    );
  }

  removeElement(element: Archivo){
    //Mostrar dialogo de confirmacion
    const dialogBorrarArchivo = this.dialog.open(BorrarArchivoDialogComponent, {data:{nombreArchivo: element.archDescripcion}});

    dialogBorrarArchivo.afterClosed().subscribe(
      result =>{
        //Si pulsa el boton "Si" del dialog
        if(result){
          this.mostrarProgressSpinner = true;
          this.funcionesService.borrarArchivo(element.archPath).subscribe(
            data =>{
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.fileService.delete(element.archPath);
                this.notificacion.notificationOk(data['mensaje']);
              }else{
                this.notificacion.notificationFail(data['mensaje']);
              }
              this.getArchivosExplorador();
            }, error=>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
              this.getArchivosExplorador();
            }
          );
        }
      }
    );

    
  }


  // File manager
  openModalFile(element: Archivo) {
    if (element != null)
      this.funcionesService.verArchivo(element.archPath).subscribe(
        data => {
          if (element.archType == 'pdf') {
            this.imageSelect = data;
            this.tipoArchivo = 'pdf';
            this.el.nativeElement.querySelector('#modalFile').classList.toggle('open');
            //console.log(data);

          }
          else if (element.archType == 'jpg' || element.archType == 'jpeg') {
            this.imageSelect = data;
            this.tipoArchivo = 'imagen';
            this.el.nativeElement.querySelector('#modalFile').classList.toggle('open');
          }
          else {
            this.descargarchivos(data, element.archDescripcion, element.archContentType);
          }



        });
  }

  openModal(name, obj: any): void {
    this.el.nativeElement.querySelector('#' + name).classList.toggle('open');
  }

  descargarchivos(archivo: string, descripcion: string, type: string) {
    let byteImage = atob(archivo);

    let byteNumbers = new Array(byteImage.length);

    for (var i = 0; i < byteImage.length; i++) {
      byteNumbers[i] = byteImage.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    let blob = new Blob([byteArray], { "type": type });

    if (navigator.msSaveBlob) {
      let filename = descripcion;
      navigator.msSaveBlob(blob, filename);
    } else {
      let link = document.createElement("a");

      link.href = URL.createObjectURL(blob);

      link.setAttribute('visibility', 'hidden');
      link.download = descripcion;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  checkCamposObligatorios() : {check: boolean, camposFaltantes: string}{

    let camposFaltantes : string = "Campos obligatorios faltantes en:\n";
    let campos : string[] = [];
    let check : boolean = true;

    switch (this.encargo.enup_id_tipo_encargo) {
      //REHUSE CAS
      case 1:      
        if(
          (this.consideracion == null || this.consideracion === "") ||
          (this.conclusiones == null || this.conclusiones === "")
          ){
          campos.push("Consideraciones Médicas");
          check = false;
        }
        break;

      //REHUSE CAS + VIDEO
      case 4:      
        if(
          //Fuente documental
          (this.fuenteDocumental == null || this.fuenteDocumental === "")
          ){
          campos.push("Fuente documental");
          check = false;
        }
        if(
          //Mecanismo prodducción
          (this.mecanismoProduccion == null || this.mecanismoProduccion === "")
        ){
          campos.push("Mecanismo de producción");
          check = false;
        }
        if(
          //Resumen evolutivo
          (this.profesion == null || this.profesion === "") ||
          (this.situacionLaboral == null || this.situacionLaboral === "") ||
          (this.bajaLaboral == null || this.bajaLaboral === "") ||
          (this.contingencia == null || this.contingencia === "") ||
          (this.antecedentes == null || this.antecedentes === "") ||
          (this.centroUrgencias == null || this.centroUrgencias === "") ||
          (this.cuandoAcude == null || this.cuandoAcude === "") ||
          (this.diagnosticoUrgencias == null || this.diagnosticoUrgencias === "") ||
          (this.centroRehab == null || this.centroRehab === "") ||
          (this.inicioTratamiento == null || this.inicioTratamiento === "") ||
          (this.altaMedica == null || this.altaMedica === "") ||
          (this.numSesiones == null || this.numSesiones === "") ||
          (this.pruebasRealizadas == null || this.pruebasRealizadas === "") ||
          (this.evolucion == null || this.evolucion === "")
        ){
          campos.push("Resumen Evolutivo");
          check = false;
        }
        if(
          //Datos relativos al análisis del impacto
          (this.referenciaInforme == null || this.referenciaInforme === "") ||
          (this.firmadoPor == null || this.firmadoPor === "") ||
          (this.tipoImpacto == null || this.tipoImpacto === "") ||
          (this.deltaV == null || this.deltaV === "") ||
          (this.aceleracion == null || this.aceleracion === "")
        ){
          campos.push("Datos relativos al análisis del impacto");
          check = false;
        }
        if(
          //Video-consulta médica
          (this.dpFechaVideoConsulta == null || this.dpFechaVideoConsulta.value === "") ||
          (this.mediosUsados == null || this.mediosUsados === "") ||
          (this.anamnesis == null || this.anamnesis === "") ||
          (this.exploracionFisica == null || this.exploracionFisica === "")
        ){
          campos.push("Video-consulta Médica");
          check = false;
        }
        if(
          //Consideraciones médicas
          (this.consideracion == null || this.consideracion === "") ||
          (this.conclusiones == null || this.conclusiones === "")
        ){
          campos.push("Consideraciones Médicas");
          check = false;
        }
        break;
    
      //VALORACIÓN VIDEO-CONSULTA
      case 5:
      //VALORACIÓN VIDEO-CONSULTA-MUTUAS
      case 8:
        if(
          //Fuente documental
          (this.fuenteDocumental == null || this.fuenteDocumental === "")
          ){
          campos.push("Fuente documental");
          check = false;
        }
        if(
          //Mecanismo prodducción
          (this.mecanismoProduccion == null || this.mecanismoProduccion === "")
        ){
          campos.push("Mecanismo de producción");
          check = false;
        }
        if(
          //Proceso clínico documentado
          (this.profesion == null || this.profesion === "") ||
          (this.situacionLaboral == null || this.situacionLaboral === "") ||
          (this.bajaLaboral == null || this.bajaLaboral === "") ||
          (this.contingencia == null || this.contingencia === "") ||
          (this.antecedentes == null || this.antecedentes === "") ||
          (this.centroUrgencias == null || this.centroUrgencias === "") ||
          (this.cuandoAcude == null || this.cuandoAcude === "") ||
          (this.diagnosticoUrgencias == null || this.diagnosticoUrgencias === "") ||
          (this.centroRehab == null || this.centroRehab === "") ||
          (this.inicioTratamiento == null || this.inicioTratamiento === "") ||
          (this.altaMedica == null || this.altaMedica === "") ||
          (this.numSesiones == null || this.numSesiones === "") ||
          (this.pruebasRealizadas == null || this.pruebasRealizadas === "") ||
          (this.intervencionesQuirurgicas == null || this.intervencionesQuirurgicas === "") ||
          (this.evolucion == null || this.evolucion === "")
        ){
          campos.push("Proceso Clínico Documentado");
          check = false;
        }
        if(
          //Video-consulta médica
          (this.dpFechaVideoConsulta == null || this.dpFechaVideoConsulta.value === "") ||
          (this.mediosUsados == null || this.mediosUsados === "") ||
          (this.anamnesis == null || this.anamnesis === "") ||
          (this.exploracionFisica == null || this.exploracionFisica === "")
        ){
          campos.push("Video-consulta Médica");
          check = false;
        }
        if(
          //Valoración - Lesiones temporales
          (this.diasBasicos == null || this.diasBasicos === "") ||
          (this.diasModerados == null || this.diasModerados === "") ||
          (this.diasGraves == null || this.diasGraves === "") ||
          (this.diasMuyGraves == null || this.diasMuyGraves === "") ||
          (this.diasTotales == null || this.diasTotales === "")
        ){
          campos.push("Valoración - Lesiones temporales");
          check = false;
        }
        //TODO Correctores (obligatorio)
        break;  

      //VALORACIÓN BASE DOCUMENTAL
      case 6:
      //ALTA+IAE BASE DOCUMENTAL
      case 7:
        //ALTA+IAE MUTUA LABORAL
      case 10:
        console.log("Total secuelas min: " + this.totalPuntosSecuelasMin());
        console.log("Total secuelas max: " + this.totalPuntosSecuelasMax());
        //Si no hay secuelas
        if(this.secuelasHorquilla().length > 0){
          console.log(this.totalPuntosSecuelasMin());
          if(
            //Lesiones temporales mínimas
            (this.totalPuntosSecuelasMin() == 0) 
          ){
            campos.push("Lesiones temporales mínimas");
            check = false;
          }
          if(
            //Lesiones temporales máximas
            (this.totalPuntosSecuelasMax() == 0) 
          ){
            campos.push("Lesiones temporales máximas");
            check = false;
          }
        }
        
        //TODO: Exportar secuelas minimas y maximas a secuelas y secuelas de reserva
        /*this.secuelaForm.value.secuelas.forEach(secuela => {
          if(
            (secuela.puntosMax == null || secuela.puntosMax === 0) ||
            (secuela.puntosMin == null || secuela.puntosMin === 0)
          ){
            campos.push("Puntos secuela");
            check = false;
          }
        });*/
        if(
          //Observaciones
          (this.observaciones == null || this.observaciones === "")       
        ){
          campos.push("Observaciones");
          check = false;
        }
        /*if(
          //Observaciones de caracter interno
          (this.observacionesAseguradora == null || this.observacionesAseguradora === "") 
        ){
          campos.push("Observaciones de caracter interno");
          check = false;
        }*/

        
        break;
      //SEGUIMIENTO DIVERSOS
      case 9:
        if(
          //Fuente documental
          (this.fuenteDocumental == null || this.fuenteDocumental === "")
          ){
          campos.push("Fuente documental");
          check = false;
        }
        if(
          //Resumen Evolutivo
          (this.profesion == null || this.profesion === "") ||
          (this.situacionLaboral == null || this.situacionLaboral === "") ||
          (this.bajaLaboral == null || this.bajaLaboral === "") ||
          (this.altaMedica == null || this.altaMedica === "") ||
          (this.antecedentes == null || this.antecedentes === "") ||
          (this.pruebasRealizadas == null || this.pruebasRealizadas === "") ||
          (this.evolucion == null || this.evolucion === "")
          ){
          campos.push("Resumen Evolutivo");
          check = false;
        }
        if(
          //Valoración del daño corporal
          (this.observaciones == null || this.observaciones === "") ||
          (this.conclusiones == null || this.conclusiones === "") ||
          (this.fechaProximaConsulta == null || this.fechaProximaConsulta === "")
          ){
          campos.push("Valoración del daño corporal");
          check = false;
        }
        break;
      
      //ASESORÍA GRAVES
      case 11:
        
        break;

      default:
        break;
    }

    campos.forEach((campo, index, array) => {

      if (index === array.length - 1){ 
        camposFaltantes += campo;
      }
      else{
        camposFaltantes += campo + "\n";
      }
    });
    
    return {check, camposFaltantes};
  }

  //Pasar fecha en string con foramto dd/MM/yyyy a Date
  dateStringtoDate(fechaString: string){
    let [dia, mes, anio] = fechaString.split('/');

    return new Date(Number(anio), Number(mes) - 1, Number(dia));
  }


}
