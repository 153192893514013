import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Contabilidad } from 'src/app/facturacion/models/contabilidad';
import { EstadoFacturasRecibidas } from 'src/app/facturacion/models/estado-facturas-recibidas';
import { FacturasRecibidas } from 'src/app/facturacion/models/facturas-recibidas';
import { FacturasRecibidasProductos } from 'src/app/facturacion/models/facturas-recibidas-productos';
import { Proveedor } from 'src/app/facturacion/models/proveedor';
import { UnidadFiscal } from 'src/app/facturacion/models/unidad-fiscal';
import { FacturacionServiceService, FacturasRecibidasProductosList, IncidenciasPago } from 'src/app/facturacion/services/facturacion-service.service';
import { SessionService } from 'src/app/services/session.service';
import * as XLSX from 'xlsx-js-style';

@Component({
  selector: 'app-lista-facturas-recibidas-contabilidad-dialog',
  templateUrl: './lista-facturas-recibidas-contabilidad-dialog.component.html',
  styleUrls: ['./lista-facturas-recibidas-contabilidad-dialog.component.scss']
})
export class ListaFacturasRecibidasContabilidadDialogComponent implements OnInit {

  facturasRecibidas: FacturasRecibidas[] = [];
  proveedores: Proveedor[] = [];
  estadosFactura: EstadoFacturasRecibidas[] = [];

  total: number = 0;

  //---Tabla Facturas Recibidas---
  @ViewChild('tableFacturasRecibidas')
  facturasRecibidasTable: MatTable<FacturasRecibidas>;
  displayedColumnsFacturasRecibidas: string[] = [
    'checkbox',
    'factr_id_facturas_recibidas',
    'factr_fecha_creacion', 
    'factr_numero_factura',
    'factr_fecha_recepcion', 
    'factr_fecha_pago',
    'factr_base_imponible', 
    'factr_total_iva',
    'factr_total_factura', 
    'factr_estado', 
    'factr_contabilidad',
    'factr_proveedor',
    'factr_estado_factura'
  ];
  dataSourceFacturasRecibidas: MatTableDataSource<FacturasRecibidas> = new MatTableDataSource(this.facturasRecibidas);
  selection = new SelectionModel<FacturasRecibidas>(true, []);

  constructor(
    public facturacionService: FacturacionServiceService, 
    @Inject(MAT_DIALOG_DATA) public data: DialogDataListaFacturasRecibidasContabilidad,
    public dialogRef: MatDialogRef<ListaFacturasRecibidasContabilidadDialogComponent>,
    private snackBar: MatSnackBar,
    private sessionService: SessionService
  ) { }

  ngOnInit(): void {
    this.obtenerProveedoresyFacturas();
    this.calculateTotal();
  }

  obtenerProveedoresyFacturas(){
    this.proveedores = this.data.proveedores;
    this.facturasRecibidas = this.data.facturasRecibidas;
    this.dataSourceFacturasRecibidas.data = this.facturasRecibidas;

    //Seleccionar todo
    this.masterToggle();
  }

  obtenerNombreProveedor(factr_proveedor: string): string{
    return this.proveedores.filter(proveedor => proveedor.prov_id_crm == factr_proveedor)[0].prov_nombre;
  }

  getEstadoFacturaDescripcion(est_id_estados): string{
    if(this.estadosFactura.filter(estadoFactura => estadoFactura.est_id_estados == est_id_estados).length > 0){
      return this.estadosFactura.filter(estadoFactura => estadoFactura.est_id_estados == est_id_estados)[0].est_descripcion;
    }
    else{
      return '--';
    }
  }

  //Métodos selection

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceFacturasRecibidas.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSourceFacturasRecibidas.data);
  }

  checkboxLabel(row?: FacturasRecibidas): string {
    let positionId : number;
    this.facturasRecibidas.forEach((factura, key) => {
      if(factura == row) positionId = key;
    });
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${positionId + 1}`;
  }

  deselectAll(){
    this.selection.clear();
  }

  generarContabilidad(){

    //TODO

    let contadorFacturas = 0;
    const facturasRecibidasFiltered: FacturasRecibidas[] = this.selection.selected;

    var ws_data = [[
      'Tipo de Factura',
      'Fecha del Asiento',
      'Fecha de la Factura',
      'Tipo de operación',
      'Número de la Factura',
      'Subcuenta del Cliente/Proveedor',
      'NIF del Cliente/Proveedor',
      'Nombre del Cliente/Proveedor',
      'Concepto del Asiento',
      'Base Imponible',
      'Porcentaje del I.V.A.',
      'Importe del I.V.A.',
      'Subcuenta del I.V.A.',
      'Porcentaje del Recargo de Equivalencia',
      'Importe del Recargo de Equivalencia',
      'Subcuenta del Recargo de Equivalencia',
      'Total de la Factura',
      'Identificador de Factura Rectificativa',
      'Subcuenta del Gasto/Ingreso',
      'Importe del Gasto/Ingreso',
      'Domicilio del Cliente/Proveedor',
      'Número (4), Escalera (4), Piso (2), Puerta (2) del Cliente/Proveedor',
      'Localidad del Cliente/Proveedor',
      'Provincia del Cliente/Proveedor',
      'Código Postal del Cliente/Proveedor',
      'Flag de Cobro/Pago al Contado',
      'Subcuenta Debe de Cobro/Pago al Contado',
      'Subcuenta Haber de Cobro/Pago al Contado',
      'Importe de Cobro/Pago al Contado',
      'Importe base de Irpf',
      'Porcentaje de Irpf',
      'Importe de Irpf',
      'Subcuenta de Irpf',
      'Criterio de Caja S/N',
      'Tipo de Pago',
      'Medio de Cobro/Pago',
      'Descripción Del Pago',
      'Comunicada',
      'Ticket',
      'Venta a Canarias',
      'Documento',
    ]];

    facturasRecibidasFiltered.forEach(facturaRecibida => {
      this.facturacionService.obtenerProveedor(facturaRecibida.factr_proveedor).subscribe({
        next: (data: any) => {
          const proveedor = data.data as Proveedor;

          this.facturacionService.obtenerFacturaRecibidaProductosYOtrosGastos(facturaRecibida).subscribe({
            next : (data: FacturasRecibidasProductosList) => {

              const productosFactura : FacturasRecibidasProductos[] = data.data;
              let ivaList = productosFactura.map(producto => producto.factrp_iva).filter((x, i, a) => a.indexOf(x) === i);

              //console.log(productosFactura);
              //console.log(ivaList);

              this.facturacionService.obtenerUnidadFiscal(facturaRecibida.factr_unidad_fiscal).subscribe({
                next: (data: any) => {
                  //console.log('unidadFiscal', data.data);

                  const unidadFiscal: UnidadFiscal = data.data;

                  productosFactura.forEach(productoFactura => {

                    ws_data.push([
                      ivaList.length > 1 ? 'CM' : 'C',
                      facturaRecibida.factr_fecha_modificacion != null ? facturaRecibida.factr_fecha_modificacion.toString() : '',
                      facturaRecibida.factr_fecha_creacion.toString(),
                      'NO',
                      facturaRecibida.factr_numero_factura,
                      unidadFiscal.unif_id_unidades_fiscales,
                      proveedor.prov_cif,
                      proveedor.prov_nombre,
                      'TODO CONCEPTO',
                      facturaRecibida.factr_base_imponible.toString() + '€',
                      productoFactura.factrp_iva.toString() + '%',
                      facturaRecibida.factr_total_iva.toString() + '€',
                      'TODO SUBCUENTA DEL IVA',
                      '',
                      '',
                      '',
                      facturaRecibida.factr_total_factura.toString() + '€',
                      '',
                      unidadFiscal.unif_cuenta_gastos ,
                      facturaRecibida.factr_base_imponible.toString() + '€',
                      proveedor.prov_domicilio,
                      '',
                      'Localidad del Cliente/Proveedor',
                      proveedor.prov_provincia.prv_provincia_geografica,
                      proveedor.prov_provincia.prv_codigo_postal,
                      'NO',
                      '',
                      '',
                      '',
                      facturaRecibida.factr_base_imponible.toString() + '€',
                      productoFactura.factrp_irpf.toString() + '%',
                      productoFactura.factrp_total_retencion.toString() + '€',
                      unidadFiscal.unif_cuenta_irpf,
                      '',
                      '',
                      '',
                      '',
                      'NO',
                      'NO',
                      '',
                      '',
                    ])
    
                  });
    
                  contadorFacturas++;
    
                  if(contadorFacturas == this.selection.selected.length){
                    const ws: XLSX.WorkSheet =XLSX.utils.aoa_to_sheet(ws_data);  
        
                    /* generate workbook and add the worksheet */
                    const wb: XLSX.WorkBook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      
                    const contabilidad: Contabilidad = {
                      con_id_contabilidad: undefined,
                      con_fecha_creacion: undefined,
                      con_fecha_modificacion: undefined,
                      con_estado: 'ESTC-001',
                      con_usuario_creador: this.sessionService.getCurrentSession().user.id.toString(),
                    }
    
                    this.facturacionService.crearContabilidadRecibidas(contabilidad, facturasRecibidasFiltered).subscribe({
                      error: (err: any) => {
                        this.snackBar.open("Error en el servidor", undefined, {
                          duration: 3 * 1000,
                        });
                      },
                      complete: () => {
                        /* save to file */  
                        XLSX.writeFile(wb, "Contabilidad.xlsx");
                        this.dialogRef.close({
                          closed: true
                        });
                      }
                    });
    
                  }

                },
                error: (err: any) => {
                  console.log(err);
                }
              })

            },
            error: (err: any) => {
              this.snackBar.open("Error en el servidor", undefined, {
                duration: 3 * 1000,
              });
            }
          })

        },
        error: (err: any) => {
          this.snackBar.open("Error en el servidor", undefined, {
            duration: 3 * 1000,
          });
        }
      });
    });
    
  }

  calculateTotal(){
    this.total = 0;

    this.selection.selected.forEach(facturaRecibida => {
      this.total += facturaRecibida.factr_total_factura;
    })
  }

}

interface DialogDataListaFacturasRecibidasContabilidad {
  facturasRecibidas: FacturasRecibidas[];
  proveedores: Proveedor[];
  estadosFactura: EstadoFacturasRecibidas[];
}
