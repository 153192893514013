import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Medico } from 'src/app/models/medico';
import { User } from 'src/app/models/user';
import { SessionService } from 'src/app/services/session.service';
import { Autorizacion } from '../models/autorizacion';
import { Centro } from '../models/centro';
import { Cita } from '../models/cita';
import { ComentarioFactura } from '../models/comentario-factura';
import { ComentarioIncidencia } from '../models/comentario-incidencia';
import { Contabilidad } from '../models/contabilidad';
import { Entidad } from '../models/entidad';
import { EstadoFacturasRecibidas } from '../models/estado-facturas-recibidas';
import { EstadoRemesas } from '../models/estado-remesas';
import { Expediente } from '../models/expediente';
import { FacturasEmitidas } from '../models/facturas-emitidas';
import { FacturasEmitidasProductos } from '../models/facturas-emitidas-productos';
import { FacturasRecibidas } from '../models/facturas-recibidas';
import { FacturasRecibidasProductos } from '../models/facturas-recibidas-productos';
import { Incidencia } from '../models/incidencia';
import { IncidenciaContabilidad } from '../models/incidencia-contabilidad';
import { IncidenciaNormal } from '../models/incidencia-normal';
import { IncidenciaPago } from '../models/incidencia-pago';
import { LlamadasSeg } from '../models/llamadas-seg';
import { Motivo } from '../models/motivo';
import { Pago } from '../models/pago';
import { PagoFacturas } from '../models/pago-facturas';
import { PlantillaProductos } from '../models/plantilla-productos';
import { Producto } from '../models/producto';
import { Proveedor } from '../models/proveedor';
import { Remesa } from '../models/remesa';
import { Serie } from '../models/serie';
import { UnidadFiscal } from '../models/unidad-fiscal';

@Injectable({
  providedIn: 'root'
})
export class FacturacionServiceService {

  //protected basePath = "https://api-celsus.gestimedica.test/api/";
  getBasePath() {
    if (location.hostname == "192.168.50.51" || location.hostname == "intranet.gestimedica.int") {
      return "http://api-celsus.gestimedica.test/api/auth/";
      //return "http://dev.api-celsus.gestimedica.int/api/auth/";
    }
    else {
      return 'https://api.upsan.net/api/auth/';
    }
  }

  constructor(private http: HttpClient, private sessionService: SessionService) {}
   
  //#region Expedientes alta
  // Se obtiene el listado de expedientes en alta.
  obtenerExpedientesEnAlta(): Observable<ExpedientesAlta> {    
    const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };
    //console.log(this.sessionService.getCurrentToken());
    return this.http.get<ExpedientesAlta>(this.getBasePath() + 'obtenerExpedientesEnAlta', httpOptions);
  }

  //#endregion

  //#region Facturas emitidas
  // Se obtiene el listado de facturas emitidas.
  obtenerFacturasEmitidas(): Observable<FacturasEmitidasList> {    
    const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };
    return this.http.get<FacturasEmitidasList>(this.getBasePath() + 'obtenerFacturasEmitidas', httpOptions);
  }

  // Se obtiene el listado de productos de una factura emitida.
  obtenerFacturaEmitidaProductos(factura: FacturasEmitidas): Observable<FacturasEmitidasProductosList> {    
    let formData = new FormData();
    formData.append('factura', JSON.stringify(factura));

    return this.http.post<FacturasEmitidasProductosList>(this.getBasePath() + 'obtenerFacturaEmitidaProductos', formData);
  }

  //#endregion

  //#region Facturar expediente

  // Se obtiene factura emitida.
  obtenerFacturaEmitida(facte_id_facturas_emitidas: string): Observable<FacturaEmitidaData> {    
    const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };
    return this.http.get<FacturaEmitidaData>(this.getBasePath() + 'obtenerFacturaEmitida/' + facte_id_facturas_emitidas, httpOptions);
  }

  // Se obtiene expediente.
  obtenerExpediente(exp_id_expedientes_up: string): Observable<ExpedienteData> {    
    const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };
    return this.http.get<ExpedienteData>(this.getBasePath() + 'obtenerExpediente/' + exp_id_expedientes_up, httpOptions);
  }

  // Se obtiene el listado de autorizaciones para facturar.
  obtenerAutorizacionesParaFacturarExpediente(exp_id_crm: string): Observable<Autorizaciones> {    
    const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };
    return this.http.get<Autorizaciones>(this.getBasePath() + 'obtenerAutorizacionesParaFacturarExpediente/' + exp_id_crm, httpOptions);
  }

  // Se obtiene el listado de citas para facturar.
  obtenerCitasParaFacturarExpediente(exp_id_crm: string): Observable<Citas> {    
    const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };
    return this.http.get<Citas>(this.getBasePath() + 'obtenerCitasParaFacturarExpediente/' + exp_id_crm, httpOptions);
  }

  // Se obtiene el listado de plantillas de producto para facturar.
  obtenerPlantillasParaFacturarExpediente(exp_id_crm: string): Observable<PlantillasProductos> {    
    const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };
    return this.http.get<PlantillasProductos>(this.getBasePath() + 'obtenerPlantillasParaFacturarExpediente/' + exp_id_crm, httpOptions);
  }

  // Se obtiene el listado de llamadas de seguimiento para facturar.
  obtenerLlamadasSegParaFacturarExpediente(exp_id_crm: string): Observable<LlamadasSegList> {    
    const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };
    return this.http.get<LlamadasSegList>(this.getBasePath() + 'obtenerLlamadasSegParaFacturarExpediente/' + exp_id_crm, httpOptions);
  }

  // Se obtiene el listado de productos.
  obtenerProductosParaFacturarExpediente(): Observable<Productos> {    
    const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };
    return this.http.get<Productos>(this.getBasePath() + 'obtenerProductosParaFacturarExpediente');
  }

  // Se obtiene el listado de series.
  obtenerSeriesFacturacion(): Observable<Series> {    
    const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };
    return this.http.get<Series>(this.getBasePath() + 'obtenerSeriesFacturacion');
  }

  // Crear factura
  crearFacturaEmitida(factura: FacturasEmitidas, listProductos: FacturasEmitidasProductos[], serie: Serie): Observable<any> {   
    let formData = new FormData();
    formData.append('factura', JSON.stringify(factura));
    formData.append('listProductos', JSON.stringify(listProductos));
    formData.append('serie', JSON.stringify(serie));

    return this.http.post<any>(this.getBasePath() + 'crearFacturaEmitida', formData);
  }

   // Actualizar factura
   actualizarFacturaEmitida(factura: FacturasEmitidas, listProductosDelete: FacturasEmitidasProductos[], listProductosAdd: FacturasEmitidasProductos[]): Observable<any> {   
    let formData = new FormData();
    formData.append('factura', JSON.stringify(factura));
    formData.append('listProductosAdd', JSON.stringify(listProductosAdd));
    formData.append('listProductosDelete', JSON.stringify(listProductosDelete));

    return this.http.post<any>(this.getBasePath() + 'actualizarFacturaEmitida', formData);
  }

  // Actualizar estado de las facturas
  actualizarEstadoFacturasEmitidas(facturas: FacturasEmitidas[], estadoFactura: string){
    let formData = new FormData();
    formData.append('facturas', JSON.stringify(facturas));
    formData.append('estadoFactura', estadoFactura);

    return this.http.post<any>(this.getBasePath() + 'actualizarEstadoFacturasEmitidas', formData);
  }

  generarInformeFactura(factura: FacturasEmitidas): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };
    return this.http.get<any>(this.getBasePath() + 'generarInformeFactura/' + factura.facte_id_facturas_emitidas, httpOptions);
  }

  //#endregion

  //#region Proveedores.
  obtenerProveedores(): Observable<Proveedores> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<Proveedores>(this.getBasePath() + 'obtenerProveedores', httpOptions);
  }

  obtenerProveedor(prov_id_crm: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<any>(this.getBasePath() + 'obtenerProveedor/' + prov_id_crm, httpOptions);
  }

  obtenerCentrosAsignados(prov_id_crm: string): Observable<Centros> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
    return this.http.get<Centros>(this.getBasePath() + 'obtenerCentrosAsignados/' + prov_id_crm, httpOptions);
  }

  obtenerMedicosAsignados(prov_id_crm: string): Observable<Medicos> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
    return this.http.get<Medicos>(this.getBasePath() + 'obtenerMedicosAsignados/' + prov_id_crm, httpOptions);
  }

  obtenerFacturasRecibidasAsignadas(prov_id_crm: string): Observable<FacturasRecibidasList> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
    return this.http.get<FacturasRecibidasList>(this.getBasePath() + 'obtenerFacturasRecibidasAsignadas/' + prov_id_crm, httpOptions);
  }

  obtenerPagos(prov_id_crm: string): Observable<Pagos> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
    return this.http.get<Pagos>(this.getBasePath() + 'obtenerPagos/' + prov_id_crm, httpOptions);
  }
  
  //#endregion

  //#region Facturas Recibidas.

  obtenerFacturasRecibidas(): Observable<FacturasRecibidasList> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<FacturasRecibidasList>(this.getBasePath() + 'obtenerFacturasRecibidas', httpOptions);
  }

  obtenerFacturaRecibida(factr_id_facturas_recibidas: string): Observable<FacturaRecibidaData> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<FacturaRecibidaData>(this.getBasePath() + 'obtenerFacturaRecibida/' + factr_id_facturas_recibidas, httpOptions);
  }

  crearFacturaRecibida(factura: FacturasRecibidas): Observable<FacturaRecibidaData> {
    let formData = new FormData();
    formData.append('factura', JSON.stringify(factura));

    return this.http.post<FacturaRecibidaData>(this.getBasePath() + 'crearFacturaRecibida', formData);
  }

  obtenerIncidenciasNormales(prov_id_crm: string): Observable<incidenciasNormales> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
    return this.http.get<incidenciasNormales>(this.getBasePath() + 'obtenerIncidenciasNormales/' + prov_id_crm, httpOptions);
  }
  
  //#endregion

  //#region Incidencia Proveedor

  obtenerMotivos(): Observable<Motivos> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<Motivos>(this.getBasePath() + 'obtenerMotivos', httpOptions);
  }

  crearIncidenciaNormal(incidencia: Incidencia, incidenciaNormal: IncidenciaNormal, comentarios: ComentarioIncidencia[]): Observable<any> {
    let formData = new FormData();
    formData.append('incidencia', JSON.stringify(incidencia));
    formData.append('incidenciaNormal', JSON.stringify(incidenciaNormal));
    formData.append('comentarios', JSON.stringify(comentarios));

    return this.http.post<any>(this.getBasePath() + 'crearIncidenciaNormal', formData);
  }

  actualizarIncidenciaNormal(incidencia: Incidencia, incidenciaNormal: IncidenciaNormal): Observable<any> {
    let formData = new FormData();
    formData.append('incidencia', JSON.stringify(incidencia));
    formData.append('incidenciaNormal', JSON.stringify(incidenciaNormal));

    return this.http.post<any>(this.getBasePath() + 'actualizarIncidenciaNormal', formData);
  }

  obtenerComentariosIncidenciaNormal(incidenciaNormal: IncidenciaNormal): Observable<ComentariosIncidencia> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<ComentariosIncidencia>(this.getBasePath() + 'obtenerComentariosIncidenciaNormal/' + incidenciaNormal.incn_id_incidencias_normales, httpOptions);
  }

  crearComentarioIncidenciaNormal(comentario: ComentarioIncidencia, incidenciaNormal: IncidenciaNormal): Observable<ComentariosIncidencia> {
    let formData = new FormData();
    formData.append('comentario', JSON.stringify(comentario));
    formData.append('incidenciaNormal', JSON.stringify(incidenciaNormal));

    return this.http.post<any>(this.getBasePath() + 'crearComentarioIncidenciaNormal', formData);
  }

  obtenerUsuariosFacturacion(): Observable<Users> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<Users>(this.getBasePath() + 'obtenerUsuariosFacturacion', httpOptions);
  }

  //#endregion

  //#region Incidencia Contabilidad

  obtenerUnidadFiscal(unif_id_unidades_fiscales: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<any>(this.getBasePath() + 'obtenerUnidadFiscal/' + unif_id_unidades_fiscales, httpOptions);
  }

  crearIncidenciaContabilidadRecibida(incidencias: Incidencia[], incidenciasContabilidad: IncidenciaContabilidad[]): Observable<any> {
    let formData = new FormData();
    formData.append('incidencias', JSON.stringify(incidencias));
    formData.append('incidenciasContabilidad', JSON.stringify(incidenciasContabilidad));

    return this.http.post<any>(this.getBasePath() + 'crearIncidenciaContabilidadRecibida', formData);
  }

  crearIncidenciaContabilidadEmitida(incidencias: Incidencia[], incidenciasContabilidad: IncidenciaContabilidad[]): Observable<any> {
    let formData = new FormData();
    formData.append('incidencias', JSON.stringify(incidencias));
    formData.append('incidenciasContabilidad', JSON.stringify(incidenciasContabilidad));

    return this.http.post<any>(this.getBasePath() + 'crearIncidenciaContabilidadEmitida', formData);
  }

  //#endregion

  //#region Generar Remesa

  obtenerEstadosRemesas(): Observable<EstadosRemesas> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<EstadosRemesas>(this.getBasePath() + 'obtenerEstadosRemesas', httpOptions);
  }

  crearRemesa(pagos: Pago[], pagosFactura: PagoFacturas[], remesa: Remesa): Observable<any> {
    let formData = new FormData();
    formData.append('pagos', JSON.stringify(pagos));
    formData.append('pagosFactura', JSON.stringify(pagosFactura));
    formData.append('remesa', JSON.stringify(remesa));

    return this.http.post<any>(this.getBasePath() + 'crearRemesa', formData);
  }

  //#endregion

  //#region Remesas

  obtenerRemesas(): Observable<Remesas> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<Remesas>(this.getBasePath() + 'obtenerRemesas', httpOptions);
  }

  obtenerPagosRemesa(remesa: Remesa): Observable<Pagos> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<Pagos>(this.getBasePath() + 'obtenerPagosRemesa/' + remesa.rem_id_remesas, httpOptions);
  }

  pagarRemesa(pagos: Pago[], remesa: Remesa): Observable<any> {
    let formData = new FormData();
    formData.append('pagos', JSON.stringify(pagos));
    formData.append('remesa', JSON.stringify(remesa));

    return this.http.post<any>(this.getBasePath() + 'pagarRemesa', formData);
  }

  cancelarRemesa(remesa: Remesa): Observable<Remesas> {
    let formData = new FormData();
    formData.append('remesa', JSON.stringify(remesa));

    return this.http.post<Remesas>(this.getBasePath() + 'cancelarRemesa', formData);
  }

  generarXMLRemesa(remesa: Remesa): Observable<any> {
    let formData = new FormData();
    formData.append('remesa', JSON.stringify(remesa));

    return this.http.post<any>(this.getBasePath() + 'generarXMLRemesa', formData);
  }

  generarInformeRemesa(remesa: Remesa): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<any>(this.getBasePath() + 'generarInformeRemesa/' + remesa.rem_id_remesas, httpOptions);
  }

  //#endregion

  //#region Incluir factura.

  obtenerEstadosFacturasRecibidas(): Observable<EstadosFacturasRecibidas> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<EstadosFacturasRecibidas>(this.getBasePath() + 'obtenerEstadosFacturasRecibidas', httpOptions);
  }

  obtenerAutorizacionesParaIncluirFacturaRecibida(proveedor: Proveedor): Observable<Autorizaciones> {    
    const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };
    return this.http.get<Autorizaciones>(this.getBasePath() + 'obtenerAutorizacionesParaIncluirFacturaRecibida/' + proveedor.prov_id_crm, httpOptions);
  }

  obtenerCitasParaIncluirFacturaRecibida(proveedor: Proveedor): Observable<Citas> {    
    const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };
    return this.http.get<Citas>(this.getBasePath() + 'obtenerCitasParaIncluirFacturaRecibida/' + proveedor.prov_id_crm, httpOptions);
  }

  obtenerPlantillasParaIncluirFacturaRecibida(proveedor: Proveedor): Observable<PlantillasProductos> {    
    const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };
    return this.http.get<PlantillasProductos>(this.getBasePath() + 'obtenerPlantillasParaIncluirFacturaRecibida/' + proveedor.prov_id_crm, httpOptions);
  }

  obtenerComentariosFactura(factura: FacturasRecibidas): Observable<ComentariosFactura> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<ComentariosFactura>(this.getBasePath() + 'obtenerComentariosFactura/' + factura.factr_id_facturas_recibidas, httpOptions);
  }

  obtenerFacturaRecibidaProductos(factura: FacturasRecibidas): Observable<FacturasRecibidasProductosList> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<FacturasRecibidasProductosList>(this.getBasePath() + 'obtenerFacturaRecibidaProductos/' + factura.factr_id_facturas_recibidas, httpOptions);
  }

  obtenerFacturaRecibidaProductosYOtrosGastos(factura: FacturasRecibidas): Observable<FacturasRecibidasProductosList> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<FacturasRecibidasProductosList>(this.getBasePath() + 'obtenerFacturaRecibidaProductosYOtrosGastos/' + factura.factr_id_facturas_recibidas, httpOptions);
  }

  obtenerFacturaRecibidaOtrosGastos(factura: FacturasRecibidas): Observable<FacturasRecibidasProductosList> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<FacturasRecibidasProductosList>(this.getBasePath() + 'obtenerFacturaRecibidaOtrosGastos/' + factura.factr_id_facturas_recibidas, httpOptions);
  }

  crearComentarioFactura(comentario: ComentarioFactura): Observable<ComentariosFactura> {
    let formData = new FormData();
    formData.append('comentario', JSON.stringify(comentario));

    return this.http.post<any>(this.getBasePath() + 'crearComentarioFactura', formData);
  }

  actualizarFacturaRecibida(
    factura: FacturasRecibidas,
    productosFacturaAdd: FacturasRecibidasProductos[],
    productosFacturaDelete: FacturasRecibidasProductos[],
    otrosGastosAdd: FacturasRecibidasProductos[],
    otrosGastosDelete: FacturasRecibidasProductos[],
  ): Observable<FacturasRecibidasList> {
    let formData = new FormData();
    formData.append('factura', JSON.stringify(factura));
    formData.append('productosFacturaAdd', JSON.stringify(productosFacturaAdd));
    formData.append('productosFacturaDelete', JSON.stringify(productosFacturaDelete));
    formData.append('otrosGastosAdd', JSON.stringify(otrosGastosAdd));
    formData.append('otrosGastosDelete', JSON.stringify(otrosGastosDelete));

    return this.http.post<FacturasRecibidasList>(this.getBasePath() + 'actualizarFacturaRecibida', formData);
  }

  cancelarFacturaRecibida(factura: FacturasRecibidas): Observable<FacturasRecibidasList> {
    let formData = new FormData();
    formData.append('factura', JSON.stringify(factura));

    return this.http.post<FacturasRecibidasList>(this.getBasePath() + 'cancelarFacturaRecibida', formData);
  }

  obtenerIncidenciasPago(factura: FacturasRecibidas): Observable<IncidenciasPago> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<IncidenciasPago>(this.getBasePath() + 'obtenerIncidenciasPago/' + factura.factr_id_facturas_recibidas, httpOptions);
  }

  crearIncidenciaPago(incidencia: Incidencia, incidenciaPago: IncidenciaPago, comentarios: ComentarioIncidencia[]): Observable<any> {
    let formData = new FormData();
    formData.append('incidencia', JSON.stringify(incidencia));
    formData.append('incidenciaPago', JSON.stringify(incidenciaPago));
    formData.append('comentarios', JSON.stringify(comentarios));

    return this.http.post<any>(this.getBasePath() + 'crearIncidenciaPago', formData);
  }

  actualizarIncidenciaPago(incidencia: Incidencia, incidenciaPago: IncidenciaPago): Observable<any> {
    let formData = new FormData();
    formData.append('incidencia', JSON.stringify(incidencia));
    formData.append('incidenciaPago', JSON.stringify(incidenciaPago));

    return this.http.post<any>(this.getBasePath() + 'actualizarIncidenciaPago', formData);
  }

  obtenerComentariosIncidenciaPago(incidenciaPago: IncidenciaPago): Observable<ComentariosIncidencia> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<ComentariosIncidencia>(this.getBasePath() + 'obtenerComentariosIncidenciaPago/' + incidenciaPago.incp_id_incidencias_pago, httpOptions);
  }

  crearComentarioIncidenciaPago(comentario: ComentarioIncidencia, incidenciaPago: IncidenciaPago): Observable<ComentariosIncidencia> {
    let formData = new FormData();
    formData.append('comentario', JSON.stringify(comentario));
    formData.append('incidenciaPago', JSON.stringify(incidenciaPago));

    return this.http.post<any>(this.getBasePath() + 'crearComentarioIncidenciaPago', formData);
  }

  obtenerTodasIncidenciasNormales(): Observable<incidenciasNormales> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
    return this.http.get<incidenciasNormales>(this.getBasePath() + 'obtenerTodasIncidenciasNormales', httpOptions);
  }

  //#endregion


  //#region Funciones generales.
  /*
    Se obtiene un listado de entidades. Para utilizar en la pestaña de búsqueda y tablas.
  */
  obtenerEntidades(): Observable<Entidades> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<Entidades>(this.getBasePath() + 'obtenerEntidades', httpOptions);
  }

  obtenerEntidad(ent_id_entidades: string): Observable<Entidades> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.sessionService.getCurrentToken() }),
    };

    return this.http.get<Entidades>(this.getBasePath() + 'obtenerEntidades/' + ent_id_entidades, httpOptions);
  }

  obtenerEntidadTabla(ent_id_entidades: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
    };

    return this.http.get<any>(this.getBasePath() + 'obtenerEntidadTabla/' + ent_id_entidades, httpOptions);
  }
  //#endregion

  //#region Contabilidad.
  /*
    Se crea un registro de contabilidad.
  */
  crearContabilidadRecibidas(contabilidad: Contabilidad, facturasRecibidas: FacturasRecibidas[]): Observable<any> {
    let formData = new FormData();
    formData.append('contabilidad', JSON.stringify(contabilidad));
    formData.append('facturasRecibidas', JSON.stringify(facturasRecibidas));

    return this.http.post<any>(this.getBasePath() + 'crearContabilidadRecibidas', formData);
  }

  crearContabilidadEmitidas(contabilidad: Contabilidad, facturasEmitidas: FacturasEmitidas[]): Observable<any> {
    let formData = new FormData();
    formData.append('contabilidad', JSON.stringify(contabilidad));
    formData.append('facturasEmitidas', JSON.stringify(facturasEmitidas));

    return this.http.post<any>(this.getBasePath() + 'crearContabilidadEmitidas', formData);
  }
  //#endregion

  //#region Cambios-Pendientes.
  /*
    Sacamos el listado de las Autorizaciones
  */
    obtenerAutMySQL() {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
  
      return this.http.get<Autorizaciones>(this.getBasePath() + 'obtenerAutMySQL', httpOptions);
    }

  /*
    Sacamos el listado de las Citas
  */
    obtenerCitasMySQL() {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
  
      return this.http.get<Citas>(this.getBasePath() + 'obtenerCitasMySQL', httpOptions);
    }

  /*
    Sacamos el listado de las Consultas
  */
    obtenerConsultasMySQL() {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
  
      return this.http.get<any>(this.getBasePath() + 'obtenerConsultarMySQL', httpOptions);
    }  

  /*
    Sacamos el listado de las Expedientes
  */
    obtenerExpedientesMySQL() {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
  
      return this.http.get<ExpedientesAlta>(this.getBasePath() + 'obtenerExpedienteMySQL', httpOptions);
    }    

  /*
    Sacamos el listado de las HistorialClinico
  */
    obtenerHClinicosMySQL() {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
  
      return this.http.get<any>(this.getBasePath() + 'obtenerHClinicoMySQL', httpOptions);
    } 
    
  /*
    Sacamos el listado de las LlamadasSeg
  */
    obtenerLlamadasSegMySQL() {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
  
      return this.http.get<LlamadasSegList>(this.getBasePath() + 'obtenerLlamadasSegMySQL', httpOptions);
    }   

  /*
    Sacamos el listado de las PlantillasProductos
  */
    obtenerPlantillaProductosMySQL() {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
  
      return this.http.get<PlantillasProductosData>(this.getBasePath() + 'obtenerPlantillasProductosMySQL', httpOptions);
    }  
    
  /*
    Sacamos el listado de las PlantillasProductosTienenFactura
  */
    obtenerPlantillaProductoTienenFacturasMySQL() {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
  
      return this.http.get<any>(this.getBasePath() + 'obtenerPlantillasProductosTienenFacturasMySQL', httpOptions);
    }
    
  /*
    Sacamos la autorización de las Autorizaciones de CRM
  */

    obtenerAutorizacionesCRM(id_autorizacion: string) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
  
      return this.http.get<any>(this.getBasePath() + 'obtenerAutCRM/' + id_autorizacion, httpOptions);
    }

   /*
    Sacamos la cita de las Citas de CRM
  */

    obtenerCitasCRM(id_cita: string) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
  
      return this.http.get<any>(this.getBasePath() + 'obtenerCitasCRM/' + id_cita, httpOptions);
    }

  /*
    Sacamos la consulta de las Consultas de CRM
  */

    obtenerConsultasCRM(id_consulta: string) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
  
      return this.http.get<any>(this.getBasePath() + 'obtenerConsultasCRM/' + id_consulta, httpOptions);
    }
    
  /*
    Sacamos el expediente de los Expedientes de CRM
  */

    obtenerExpedientesCRM(id_expediente: string) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
  
      return this.http.get<any>(this.getBasePath() + 'obtenerExpedientesCRM/' + id_expediente, httpOptions);
    }

  /*
    Sacamos la llamada de seguimiento de las Llamadas de Seg de CRM
  */

    obtenerLlamadasSegCRM(id_llSeg: string) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
  
      return this.http.get<any>(this.getBasePath() + 'obtenerLlamadasSegCRM/' + id_llSeg, httpOptions);
    }  

  /*
    Sacamos la Plantilla Producto de las PProductos de CRM
  */

    obtenerPlantillasProductosCRM(id_plantillaP: string) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
  
      return this.http.get<any>(this.getBasePath() + 'obtenerPlantillasProductosCRM/' + id_plantillaP, httpOptions);
    }  
  
  /*
    Sacamos la PP tienen Factura de las PPTF de CRM
  */

    obtenerPlantillasProductosTienenFacturaCRM(id_plantillaPFactura: string) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' /*+ this.sessionService.getCurrentToken()*/ }),
      };
  
      return this.http.get<any>(this.getBasePath() + 'obtenerPlantillasProductosTienenFacturasCRM/' + id_plantillaPFactura, httpOptions);
    }  
    
}

export interface ExpedientesAlta {
  success : string;
  data: Expediente[];
  message: string;
}

export interface ExpedienteData {
  success : string;
  data: Expediente;
  message: string;
}

export interface FacturasEmitidasList {
  success : string;
  data:     FacturasEmitidas[];
  message:  string;
}

export interface FacturaEmitidaData {
  success : string;
  data:     FacturasEmitidas;
  message:  string;
}

export interface FacturasEmitidasProductosList {
  success : string;
  data:     FacturasEmitidasProductos[];
  message:  string;
}

export interface Entidades {
  success : string;
  data:     Entidad[];
  message:  string;
}

export interface Autorizaciones {
  success : string;
  data:     Autorizacion[];
  message:  string;
}

export interface Citas {
  success : string;
  data:     Cita[];
  message:  string;
}

export interface PlantillasProductos {
  success : string;
  data:     [PlantillaProductos[]];
  message:  string;
}

export interface LlamadasSegList {
  success : string;
  data:     LlamadasSeg[];
  message:  string;
}

export interface Productos {
  success : string;
  data:     Producto[];
  message:  string;
}

export interface Series {
  success : string;
  data:     Serie[];
  message:  string;
}

export interface Proveedores {
  success : string;
  data:     Proveedor[];
  message:  string;
}

export interface FacturasRecibidasList {
  success : string;
  data:     FacturasRecibidas[];
  message:  string;
}

export interface FacturaRecibidaData {
  success : string;
  data:     FacturasRecibidas;
  message:  string;
}

export interface FacturasRecibidasProductosList {
  success : string;
  data:     FacturasRecibidasProductos[];
  message:  string;
}

export interface Centros {
  success : string;
  data:     Centro[];
  message:  string;
}

export interface Medicos {
  success : string;
  data:     Medico[];
  message:  string;
}

export interface Pagos {
  success : string;
  data:     Pago[];
  message:  string;
}

export interface incidenciasNormales {
  success : string;
  data:     [IncidenciaNormal[], Incidencia[]];
  message:  string;
}

export interface IncidenciasPago {
  success : string;
  data:     [IncidenciaPago[], Incidencia[]];
  message:  string;
}

export interface EstadosFacturasRecibidas {
  success : string;
  data:     EstadoFacturasRecibidas[];
  message:  string;
}

export interface EstadosRemesas {
  success : string;
  data:     EstadoRemesas[];
  message:  string;
}

export interface ComentariosFactura {
  success : string;
  data:     ComentarioFactura[];
  message:  string;
}

export interface ComentariosIncidencia {
  success : string;
  data:     ComentarioIncidencia[];
  message:  string;
}

export interface Motivos {
  success : string;
  data:     Motivo[];
  message:  string;
}

export interface Users {
  success : string;
  data:     User[];
  message:  string;
}

export interface Remesas {
  success : string;
  data:     Remesa[];
  message:  string;
}

export interface PlantillasProductosData {
  success : string;
  data:     PlantillaProductos[];
  message:  string;
}