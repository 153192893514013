import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Encargo } from 'src/app/models/encargo';
import { FuncionesPrincipalService } from 'src/app/services/funciones-principal.service';
import { SessionService } from 'src/app/services/session.service';
import { TramitadorPortalService } from 'src/app/services/tramitador-portal.service';

@Component({
  selector: 'app-header-encargo-pericial',
  templateUrl: './header-encargo-pericial.component.html',
  styleUrls: ['./header-encargo-pericial.component.scss']
})
export class HeaderEncargoPericialComponent implements OnInit {

  nombreTramitador: string;
  idTramitador : number;
  countMensajes: number;
  idEncargo: number;
  idTipo: number;
  encargo : Encargo;

  constructor(private sessionService: SessionService, private tramitadorService: TramitadorPortalService, private title : Title,private route: ActivatedRoute,
    private funcionesService : FuncionesPrincipalService 
    ) {
    this.nombreTramitador = this.sessionService.getCurrentSession().tramitador.trm_nombre;
    this.idTramitador     = this.sessionService.getCurrentSession().tramitador.trm_id_user;
    this.route.params.subscribe(params =>{
      this.idEncargo = params['idEncargo'];
      this.idTipo    = params['idTipo'];
      //this.ngOnInit();
    });
  }

  ngOnInit(): void {
    this.funcionesService.getEncargoById(this.idEncargo, this.idTramitador).subscribe(data => {
      if(data){
        this.encargo = data['encargo'];
        this.title.setTitle(this.encargo.enup_lesionado);
      }else{
        this.title.setTitle("CELSUS");
      }
    });
    this.tramitadorService.getCountMensajesSinLeerTramitador().subscribe(
      data => {
        if(data['response'] == "ok"){
          this.countMensajes = data['totalMensajes'];
        }else{

        }
      }
    );
  }


  logout(){
    const confirmed = confirm("Está a punto de cerrar sesión, y eso conlleva a cerrar sesión en TODAS las pestañas de CELSUS.");
    
    if(confirmed) {
      this.sessionService.logout();
    }
  }

}
