import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { EstadoFacturasRecibidas } from 'src/app/facturacion/models/estado-facturas-recibidas';
import { FacturasRecibidas } from 'src/app/facturacion/models/facturas-recibidas';
import { Pago } from 'src/app/facturacion/models/pago';
import { PagoFacturas } from 'src/app/facturacion/models/pago-facturas';
import { Proveedor } from 'src/app/facturacion/models/proveedor';
import { FacturacionServiceService, IncidenciasPago } from 'src/app/facturacion/services/facturacion-service.service';

@Component({
  selector: 'app-lista-facturas-remesa-dialog',
  templateUrl: './lista-facturas-remesa-dialog.component.html',
  styleUrls: ['./lista-facturas-remesa-dialog.component.scss']
})
export class ListaFacturasRemesaDialogComponent implements OnInit {

  facturasRecibidas: FacturasRecibidas[] = [];
  proveedores: Proveedor[] = [];
  estadosFactura: EstadoFacturasRecibidas[] = [];

  total: number = 0;

  //---Tabla Facturas Recibidas---
  @ViewChild('tableFacturasRecibidas')
  facturasRecibidasTable: MatTable<FacturasRecibidas>;
  displayedColumnsFacturasRecibidas: string[] = [
    'checkbox',
    'factr_id_facturas_recibidas',
    'factr_fecha_creacion', 
    'factr_numero_factura',
    'factr_fecha_recepcion', 
    'factr_fecha_pago',
    'factr_base_imponible', 
    'factr_total_iva',
    'factr_total_factura', 
    'factr_estado', 
    'factr_contabilidad',
    'factr_proveedor',
    'factr_estado_factura'
  ];
  dataSourceFacturasRecibidas: MatTableDataSource<FacturasRecibidas> = new MatTableDataSource(this.facturasRecibidas);
  selection = new SelectionModel<FacturasRecibidas>(true, []);

  constructor(
    public facturacionService: FacturacionServiceService, 
    @Inject(MAT_DIALOG_DATA) public data: DialogDataListaFacturasRemesa,
    public dialogRef: MatDialogRef<ListaFacturasRemesaDialogComponent>,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.obtenerProveedoresyFacturas();
    this.calculateTotal();
  }

  obtenerProveedoresyFacturas(){
    this.proveedores = this.data.proveedores;
    this.facturasRecibidas = this.data.facturasRecibidas;
    this.dataSourceFacturasRecibidas.data = this.facturasRecibidas;

    //Seleccionar todo
    this.masterToggle();
  }

  obtenerNombreProveedor(factr_proveedor: string): string{
    return this.proveedores.filter(proveedor => proveedor.prov_id_crm == factr_proveedor)[0].prov_nombre;
  }

  getEstadoFacturaDescripcion(est_id_estados): string{
    if(this.estadosFactura.filter(estadoFactura => estadoFactura.est_id_estados == est_id_estados).length > 0){
      return this.estadosFactura.filter(estadoFactura => estadoFactura.est_id_estados == est_id_estados)[0].est_descripcion;
    }
    else{
      return '--';
    }
  }

  //Métodos selection

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceFacturasRecibidas.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSourceFacturasRecibidas.data);
  }

  checkboxLabel(row?: FacturasRecibidas): string {
    let positionId : number;
    this.facturasRecibidas.forEach((factura, key) => {
      if(factura == row) positionId = key;
    });
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${positionId + 1}`;
  }

  deselectAll(){
    this.selection.clear();
  }

  generarRemesa(){

    let proveedoresFacturas: string[] = [];
    let pagosFacturas: PagoFacturas[] = [];
    let pagos: Pago[] = [];
    let listaErrores: string[] = [];

    let facturasCounterFlag = 0;

    this.selection.selected.forEach(facturaRecibida => {
      proveedoresFacturas.push(facturaRecibida.factr_proveedor);
      this.facturacionService.obtenerIncidenciasPago(facturaRecibida).subscribe({
        next: (data: IncidenciasPago) => {
          facturaRecibida['have_incidencia'] = data.data[1].length > 0;
        },
        error: (err: any) => {
          console.log(err);
          this.snackBar.open("Error en el servidor", undefined, {
            duration: 3 * 1000,
          });
        },
        complete: () => {
          facturasCounterFlag++;
          if(facturasCounterFlag == this.selection.selected.length){
            this.proveedores.filter(proveedor => proveedoresFacturas.includes(proveedor.prov_id_crm) && proveedor.prov_fecha_diligencia == null && proveedor.prov_iban != null).forEach(proveedor => {

              let totalPagado = 0;
              let pagosFacturasProveedor: PagoFacturas[] = [];
        
              this.selection.selected.filter(facturaRecibida => facturaRecibida.factr_proveedor == proveedor.prov_id_crm).forEach((facturaRecibida, key) => {
                //console.log(facturaRecibida);
                if(!facturaRecibida['have_incidencia']){
                  pagosFacturasProveedor.push({
                    pagf_id_pagos: proveedor.prov_id_crm,
                    pagf_id_factura_recibida: facturaRecibida.factr_id_facturas_recibidas,
                  })
                  totalPagado += facturaRecibida.factr_total_factura;
                }
              })
        
              if(pagosFacturasProveedor.length > 0){

                let concepto: string = '';

                pagosFacturasProveedor.forEach(pagoFactura => {
                  pagosFacturas.push(pagoFactura);
                  concepto += pagoFactura.pagf_id_factura_recibida;
                })

                pagos.push({
                  pag_id_pagos: null,
                  pag_fecha_creacion: null,
                  pag_fecha_modificacion: null,
                  pag_estado: null,
                  pag_remesa: null,
                  pag_proveedor: proveedor.prov_id_crm,
                  pag_concepto: concepto,
                  pag_iban_cuenta: proveedor.prov_iban,
                  pag_total_pagado: totalPagado,
                })
              }
              
                    
            });
        
            if(pagos.length > 0){
              if(this.total <= 80000){
                //console.log(pagosFacturas);
                this.dialogRef.close({
                  pagos: pagos,
                  pagosFacturas: pagosFacturas,
                  proveedores: this.proveedores,
                });
              }
              else{
                this.snackBar.open("El total no puede superar los 80000€", undefined, {
                  duration: 3 * 1000,
                });
              }
            }
            else{
              this.snackBar.open("No se han podido generar pagos a partir de las facturas seleccionadas", undefined, {
                duration: 3 * 1000,
              });
            }
            
          }
        }
      })
    });
    
  }

  calculateTotal(){
    this.total = 0;

    this.selection.selected.forEach(facturaRecibida => {
      this.total += facturaRecibida.factr_total_factura;
    })
  }

}

interface DialogDataListaFacturasRemesa {
  facturasRecibidas: FacturasRecibidas[];
  proveedores: Proveedor[];
  estadosFactura: EstadoFacturasRecibidas[];
}