import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Encargo } from '../../models/encargo';
import { Medico } from '../../models/medico';
import { MedicoPortalService } from '../../services/medico-portal.service';
import { SessionService } from '../../services/session.service';
import { TramitadorPortalService } from 'src/app/services/tramitador-portal.service';
import { PageEvent } from '@angular/material/paginator';
import { FuncionesPrincipalService } from 'src/app/services/funciones-principal.service';

@Component({
  selector: 'app-medico-mensajes',
  templateUrl: './medico-mensajes.component.html',
  styleUrls: ['./medico-mensajes.component.scss'],
  //Cambios css en las clases de material angular
  encapsulation: ViewEncapsulation.None
})
export class MedicoMensajesComponent implements OnInit {


  encargosArrayAux : Encargo[];
  encargosFilter    : Encargo[];
  encargosLength : number;
  idMedico: number;
  medico : Medico;
  clickEstado : number;
  encargo : Encargo[];

  estados : any[] = [
    {
      id          : 0,
      description : "Pendientes de revisión",
    },
    {
      id          : 1,
      description : "Revisados",
    },
    {
      id          : 2,
      description : "Finalizados",
    }
  ];

  //PAGINATION
  startIndex = 0;
  length = 100;
  pageSize = 5;
  endIndex = this.pageSize;

  pageSizeOptions: number[] = [5, 10, 20, 50, 100];
  DISPLAY_FOOTER: number = 0;


  opc: string = "mensajesSinLeer";

  mostrarProgressSpinner1 = true;
  mostrarProgressSpinner2 = true;
  mostrarProgressSpinner3 = true;
  mostrarProgressSpinner4 = true;
  mostrarProgressSpinner5 = true;
  loadingPageFlag: boolean = false;


  constructor(private medicoService: MedicoPortalService, private router: Router, private sessionService: SessionService, private tramitadorService: TramitadorPortalService,  private funcionesService: FuncionesPrincipalService) {
    this.idMedico = this.sessionService.getCurrentSession().medico.med_id_user;
    this.medico = this.sessionService.getCurrentSession().medico;
   }

  ngOnInit(): void {
    this.getEncargosPericial(2);
    this.DISPLAY_FOOTER = this.pageSizeOptions[0];
    this.pageSize = this.pageSizeOptions[0];
  }

  setpage(pagedata: PageEvent) {
    this.startIndex = pagedata.pageIndex * pagedata.pageSize;
    this.endIndex = (pagedata.pageIndex + 1) * pagedata.pageSize;
  }

  //Cuando cambiamos de pestaña, la barra de búsqueda se pone en blanco, y el array recibe el valor en función de la
  //pestaña en que se encuentre
    //Cuando cambiamos de pestaña, la barra de búsqueda se pone en blanco, y el array recibe el valor en función de la
  //pestaña en que se encuentre
  onTabChanged($event) {
    let clickedIndex = $event.index;
    switch(clickedIndex) { 
      case 0: { 
          /*this.encargosArrayAux = this.encargosTramitacion;
          this.encargosFilter   = this.encargosTramitacion;*/
          this.loadingPageFlag = false;
          this.clickEstado = 2;
          this.getEncargosPericial(this.clickEstado);
         break; 
      } 
      case 1: { 
        /*this.encargosArrayAux = this.encargosEnviados;
        this.encargosFilter   = this.encargosEnviados;*/
        this.loadingPageFlag = false;
        this.clickEstado = 3;
        this.getEncargosPericial(this.clickEstado);

         break; 
      } 
      case 2: { 
        /*this.encargosArrayAux = this.encargosPendienteRevision;
        this.encargosFilter   = this.encargosPendienteRevision;*/
        this.loadingPageFlag = false;
        this.clickEstado = 4;
        this.getEncargosPericial(this.clickEstado);

         break; 
      } 
    } 
  }

  public onClickEstrella(encargo: Encargo){

    this.encargosArrayAux.find(encargoAux => encargoAux.id == encargo.id).enup_estrella = !encargo.enup_estrella;

    let datos = {
      'encargoIdPlataforma': encargo.id_plataforma
    };

    this.funcionesService.cambiarEstadoEstrella(datos).subscribe(
      data => {
        console.log(data);
      },
      error =>{
        console.log(error);
      }
    );
  }

  getEncargosPericial(clickEstado : number) {
    this.encargosFilter = [];
    this.tramitadorService.getEncargosMedicoMensajes(clickEstado).subscribe(
      data => {
        this.mostrarProgressSpinner5 = false;
        
        if(data['response'] == "ok"){
          //this.encargosPendienteEnviar = data['encargosPendienteEnviar'];
          this.encargosFilter = data['encargosTramitacion'];
          this.encargosConMensajes(this.encargosFilter);
          //this.encargosArrayAux = this.encargosFilter;
          this.encargosLength = this.encargosFilter.length;
          this.loadingPageFlag = true;
        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner5 = false;
        this.loadingPageFlag = false;
      }
    );
  }

 
 
  //Filtra solo los encargos con mensajes
  encargosConMensajes(encargos: Encargo[]){
    let encargosConMensajes: Encargo[] = [];

    encargos.forEach(function (encargo){
      if(encargo.mensajes_count != 0){
        encargosConMensajes.push(encargo);
        encargosConMensajes.sort((a, b) => b.mensajes_count - a.mensajes_count);
      }
    });

    this.encargosArrayAux = encargosConMensajes;
  }

  colorEncargo(fechaLimite: string){
    var color = '';
    var fechaNow = new Date();
    var fechaLimiteTomorrow = new Date(fechaLimite);
    var fechaLimiteFormat = new Date(fechaLimite);

    if(fechaLimite != null){

      fechaLimiteTomorrow.setDate(fechaLimiteFormat.getDate() + 1);

      if(fechaNow<fechaLimiteFormat){
        color = 'color_verde';
      }else if(fechaNow.getFullYear()==fechaLimiteFormat.getFullYear() && fechaNow.getMonth()==fechaLimiteFormat.getMonth() && fechaNow.getDate()==fechaLimiteFormat.getDate() 
      || fechaNow.getFullYear()==fechaLimiteTomorrow.getFullYear() && fechaNow.getMonth()==fechaLimiteTomorrow.getMonth() && fechaNow.getDate()==fechaLimiteTomorrow.getDate()){
        color = 'color_amarillo';
      }else if(fechaNow>fechaLimiteTomorrow){
        color = 'color_rojo';
      }else{
        color = '';
      }
  
      return color;
    }
  }

  colorEncargoFila(fechaLimite: string, prioridad: number){
    var color = '';
    var fechaNow = new Date();
    var fechaLimiteTomorrow = new Date(fechaLimite);
    var fechaLimiteFormat = new Date(fechaLimite);

    if(fechaLimite != null){

      fechaLimiteTomorrow.setDate(fechaLimiteFormat.getDate() + 1);

      if(fechaNow<fechaLimiteFormat){
        color = 'color_verde_fila';
      }else if(fechaNow.getFullYear()==fechaLimiteFormat.getFullYear() && fechaNow.getMonth()==fechaLimiteFormat.getMonth() && fechaNow.getDate()==fechaLimiteFormat.getDate() 
                || fechaNow.getFullYear()==fechaLimiteTomorrow.getFullYear() && fechaNow.getMonth()==fechaLimiteTomorrow.getMonth() && fechaNow.getDate()==fechaLimiteTomorrow.getDate()){
        color = 'color_amarillo_fila';
      }else if(fechaNow>fechaLimiteTomorrow){
        color = 'color_rojo_fila';
      }else{
        color = '';
      }
    }

    if(prioridad != null){
      if(prioridad == 1){
        color = color + ' color_urgente_fila';
      }
    }

    return color;
  }

  abrirEncargo(idEncargo: number){
    this.router.navigate(['encargo', {idEncargo: idEncargo}]);
  }

}
