import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { Entidad } from '../../models/entidad';
import { FacturacionServiceService, Entidades } from '../../services/facturacion-service.service';

@Component({
  selector: 'app-header-facturacion',
  templateUrl: './header-facturacion.component.html',
  styleUrls: ['./header-facturacion.component.scss']
})
export class HeaderFacturacionComponent implements OnInit {
  
  nombreFacturador: string;
  userType: number;

  listadoEntidades: Entidad[];
  
  tablas = ["Autorizaciones","Centros","Compañias","Citas","Consultas","Contabilidad","Expedientes","Facturas emitidas","Facturas emitidas (Productos)",
  "Facturas recibidas","Facturas recibidas (Productos)","Historial Clínico", "Lista de Ventas","Lista de Ventas (Productos)", "Médicos", "Pagos", "Pagos Facturas",
  "Productos", "Proveedores", "Provincias", "Remesas"];

  showFiller = false;

  constructor(
    private sessionService: SessionService,
    private facturacionService: FacturacionServiceService,
    private router: Router,
    ) {
    this.nombreFacturador = this.sessionService.getCurrentSession().user.name;
    this.userType = this.sessionService.getCurrentSession().user.tipo;
   }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.getEntidades();   
  }

  logout(){
    this.sessionService.logout();
  }

  getEntidades() {
    this.facturacionService.obtenerEntidades().subscribe({
      next: (data: Entidades) => {
        this.listadoEntidades = data.data;
        //console.log(data);
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => { }
    });
  }

  navigateToBusqueda(id: string){
    this.router.navigate(['/busqueda'], {
      queryParams: {
          id: id
      },
    });
  }

  navigateToTablas(id: string){
    this.router.navigate(['/tablas'], {
      queryParams: {
          id: id
      },
    });
  }

}