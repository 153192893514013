import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EnviarDialogComponent } from '../../../dialogs/enviar-dialog/enviar-dialog.component';
import { Encargo } from '../../../models/encargo';
import { Expediente } from '../../../models/expediente';
import { FormatoConsulta } from '../../../models/formato_consulta/formato-consulta.model';
import { Medico } from '../../../models/medico';
import { Tipo } from '../../../models/tipo';
import { Tramitador } from '../../../models/tramitador';
import { FuncionesPrincipalService } from '../../../services/funciones-principal.service';
import { NotificacionService } from '../../../services/notificacion.service';
import { SessionService } from '../../../services/session.service';
import { TramitadorPortalService } from '../../../services/tramitador-portal.service';
import { Cia } from 'src/app/facturacion/models/cia';

@Component({
  selector: 'app-nueva-tarea-vdc',
  templateUrl: './nueva-tarea-vdc.component.html',
  styleUrls: ['./nueva-tarea-vdc.component.scss']
})
export class NuevaTareaVdcComponent implements OnInit {
  private idEncargo;
  tramitador: Tramitador;
  encargo: Encargo;
  numExpediente: string;
  msgErrorInputExpediente: string = "";
  expediente: Expediente;
  medicos: Medico[];
  tipos: Tipo[];
  tiposSlice : Tipo[];
  idMedico: number;
  idTipo: number;
  idCia: string;
  mostrarProgressSpinner: boolean = false;
  mostrarCancel: boolean = false;
  isUrgente: boolean;
  comentario: string;
  idEncargoCreado;
  fechaLimite: string = null;
  fechaConsultaVideo: string = null;
  horaConsultaVideo: string = null;
  patoAutomatica: string = null;
  formatoConsulta : FormatoConsulta[];
  idFormatoConsulta: number;
  cias : Cia[];
  excelFision : any[];
  consultasExcelFision : any[];

  constructor(private tramitadorService: TramitadorPortalService, 
    private notificacion: NotificacionService, 
    private route: ActivatedRoute, 
    private router: Router, 
    private funcionesService: FuncionesPrincipalService, 
    private sessionService: SessionService,
    private dialog: MatDialog) {

      this.numExpediente = "UPASIS-";
      this.encargo = new Encargo();
      this.expediente = new Expediente();

      this.tramitador = this.sessionService.getCurrentSession().tramitador;

      this.route.params.subscribe(params =>{
        this.idEncargo = params['idEncargo'];
        this.idTipo    = params['idTipo'];
        this.ngOnInit();
      });
  }

  ngOnInit(): void {
    this.mostrarProgressSpinner = true;
    this.getFormatoConsulta();
    this.getCias();


    //Si es un encargo pendiente de enviar se habilita el boton de cancelar encargo
    if(this.idEncargo){
      this.mostrarCancel = true;
    }

    this.tramitadorService.getDatosNuevaTarea().subscribe(
      data =>{
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          this.medicos = data['medicos'];
          this.tipos = data['tipos'];
        }
        this.tipos.splice(0,10);
        // Obtener la longitud actual del arreglo
        var longitudArreglo = this.tipos.length;

        // Eliminar el último elemento del arreglo
        this.tipos.splice(longitudArreglo - 1, 1);
        
      }, error=>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );

    //Si se ha navegado desde el encargo, los campos se autocompletan con el encargo
    if(this.idEncargo){
      this.funcionesService.getEncargoById(this.idEncargo, this.tramitador.trm_id_user).subscribe(
        data =>{
          this.mostrarProgressSpinner = false;
          if(data['response'] == "ok"){
            this.encargo = data['encargo'];
            this.rellenarCampos(this.encargo);
          }else{
            this.notificacion.notificationFail(data['mensaje']);
          }
        }, error =>{
          this.mostrarProgressSpinner = false;
          this.notificacion.notificationFail("Error en el servidor");
        }
      );
    }

  }

  onBackspace(event: KeyboardEvent) {
    if (this.numExpediente === 'UPASIS-') {
      event.preventDefault(); // Evita que el evento de retroceso (backspace) se propague
    }
  }

  onSpace(event: KeyboardEvent) {
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      event.preventDefault(); // Evita que el evento de espacio (space) se propague
    }
  }

  getFormatoConsulta() {
    this.tramitadorService.getFormatoConsulta().subscribe({
      next: (data: any) => {
        console.log(data);
        this.formatoConsulta = data['data'];
      },
      error: (err: any) => {
        console.log(err);
      },
      
    });
  }

  getDataExcel() {
    this.mostrarProgressSpinner = true;
    this.tramitadorService.ExportExcelToArray(this.numExpediente + '/' + this.numExpediente + '.xlsx').subscribe({
      next: (data: any) => {
        console.log(data);
        this.mostrarProgressSpinner = false;

        this.excelFision = data['data'];
        this.consultasExcelFision = data['consultasAnteriores'];
        this.rellenarCampos(this.excelFision);
      },
      error: (err: any) => {
        this.mostrarProgressSpinner = false;
        console.log(err);
      },
      
    });
  }

  getCias() {
    this.tramitadorService.getCias().subscribe({
      next: (data: any) => {
        this.cias = data['data'];
        this.expediente.idCia = "6f6234e4-2088-e211-8cfd-984be17c1a27";

        
      },
      error: (err: any) => {
        console.log(err);
      },
    })
  }

  rellenarCampos(encargo: any){
    // this.expediente.cia = encargo.enup_cia;
    this.expediente.condicion = encargo['Condición'];
    this.expediente.edadLesionado = encargo['Edad'];
    // this.expediente.telefono = encargo.enup_telefono;
    this.expediente.fechaSiniestro = encargo['Fecha siniestro'];
    this.expediente.formaProduccion = encargo['Causa'];
    this.expediente.lesionado = encargo['Nombre'] + ' ' + encargo['Apellidos'];
    this.expediente.localidad = encargo['Población'];
    // this.expediente.numExpediente = encargo.enup_expediente;
    // this.expediente.idExpediente = encargo.enup_id_expediente;
    // this.numExpediente = encargo.enup_expediente;
    this.expediente.provincia = encargo['Provincia'];
    this.expediente.siniestro = this.numExpediente.replace("UPASIS-", "");
    // this.idMedico = encargo.enup_id_medico;
  }

  getExpediente(){
    console.log(this.numExpediente);
    this.mostrarProgressSpinner = true;
    //Si es vacio mostrar mensaje de error
    if(this.numExpediente.trim()==""){
      this.mostrarProgressSpinner = false;
      this.msgErrorInputExpediente = "El campo está vacio";
    }else{
      this.msgErrorInputExpediente = "";

      this.tramitadorService.getExpediente(this.numExpediente).subscribe(
        data =>{
          this.mostrarProgressSpinner = false;
          if(data['response'] == "ok"){
            //console.log(data['expediente']);
            this.expediente = data['expediente'];
          }else if(data['response'] == "noEncontrado"){
            console.log(data['mensaje']);
          }else{

          }
        }, error =>{
          this.mostrarProgressSpinner = false;
        }
      );
    }
  }

  //Guardar encargo estado pendiente de enviar
  guardarTarea(estado: string){
    this.mostrarProgressSpinner = true;
    var idEstado: number;
    var fuenteDocumental : string = '';

    if(this.idTipo == -1){
      this.idTipo = null;
    }

    if(this.idMedico == -1){
      this.idMedico = null;
    }
    //Dependiendo del boton se le pasa un estado o otro
    if(estado == "tramitacion"){
      idEstado = 1;
    }else if(estado == "enviado"){
      idEstado = 2;
    }

    //Campos requeridos
    if((this.idMedico != null && this.numExpediente != null && idEstado == 2) || (idEstado == 1)){

      if (this.consultasExcelFision) {
        this.consultasExcelFision.forEach(element => {
          if (element['Fecha'] && element['Consulta'] && element['Médico']) {
            fuenteDocumental += element['Fecha'] + ' ' + element['Consulta'] + ' Dr. ' + (element['Médico'] ? element['Médico'].toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : '') + '\n';
          }
        });
      }

      // fuenteDocumental = fuenteDocumental.trim();  // Elimina el último espacio extra

      //console.log(this.expediente);
      var datos = {
        'numExpediente'       : this.numExpediente,
        'idExpediente'        : this.expediente.idExpediente,
        'idMedico'            : this.idMedico,
        'idTramitador'        : this.tramitador.trm_id_user,
        'prioridad'           : this.isUrgente == true ? 1 : null,
        'lesionado'           : this.expediente.lesionado,
        'edadLesionado'       : this.expediente.edadLesionado,
        'telefono'            : this.expediente.telefono,
        'fechaSiniestro'      : this.expediente.fechaSiniestro,
        'refSiniestro'        : this.expediente.siniestro,
        'idEstadoEncargo'     : idEstado,
        'idTipoEncargo'       : 19,
        'condicion'           : this.expediente.condicion,
        'idCia'               : this.expediente.idCia,
        'provincia'           : this.expediente.provincia,
        'localidad'           : this.expediente.localidad,
        'formaProduccion'     : this.expediente.formaProduccion,
        'mecanismoProduccion' : this.excelFision ? this.excelFision['Mecanismo de producción'] : '',
        'antecedentes'        : this.excelFision ? this.excelFision['Antecedentes'] : '',
        'centroUrgencias'     : this.excelFision ? this.excelFision['Centro urgencias'] : '',
        'diagnosticoUrgencias': (this.excelFision ? this.excelFision['Diagnóstico urgencias'] : '') + '\nTratamiento urgencias: ' + ( this.excelFision ? this.excelFision['Tratamiento urgencias'] : 'No refiere'),
        'fechaAltaLaboral'    : this.excelFision ? this.excelFision['Fecha alta laboral'] : '',
        'fechaBajaLaboral'    : this.excelFision ? this.excelFision['Fecha baja laboral'] : '',
        'inicioTratamiento'   : this.excelFision ? this.excelFision['Fecha inicio tto. Rhb.'] : '',
        'centroRehabilitacion': this.excelFision ? this.excelFision['Nombre centro Rhb.'] : '',
        'profesion'           : this.excelFision ? this.excelFision['Profesión'] : '',
        'numConsultas'        : this.excelFision ? this.excelFision['Total número consultas'] : '',
        'numSesiones'         : this.excelFision ? this.excelFision['Total número sesiones'] : '',
        'contingencia'        : (this.excelFision && this.excelFision['In itinere']) === 'SI' ? 'Contingencia laboral' : (this.excelFision && this.excelFision['In itinere']) === 'NO' ? 'Contingencia no laboral' : 'Se desconoce',
        'bajaLaboral'         : this.excelFision ? (isNaN(Date.parse(this.excelFision['Fecha baja laboral'])) ? 'No refiere' : 'Si') : '',
        'peticion'            : this.excelFision ? 'Culpa: ' +  this.excelFision['Culpa'] : '',
        'estadoInicial'       : this.excelFision ? this.consultasExcelFision[0]['Diagnóstico'] : '',
        'estadoFinal'         : this.excelFision ? this.consultasExcelFision[this.consultasExcelFision.length -1]['ExploraciónFisicaAlta'] : '',
        'fuenteDocumental'    : fuenteDocumental,
        'cuandoAcude'         : this.excelFision ? this.excelFision['Fecha 1ª asistencia médica'] : '',
        'medicoTratante'      : this.excelFision ? this.consultasExcelFision[0]['Médico'] : '',
        'pruebasRealizadas'   : this.excelFision ? this.excelFision['Pruebas diagnósticas'] : ''
      };

      if(idEstado == 2){
        //Mostrar dialogo de confirmacion
        const dialogEnviar = this.dialog.open(EnviarDialogComponent,{
          data: {comentario: this.comentario}
        });
        
        dialogEnviar.afterClosed().subscribe(
          result =>{
            //Si pulsa el boton "Si" del dialog
            if(result){
              this.mostrarProgressSpinner = true;

              this.tramitadorService.crearTareaEncargo(datos).subscribe(
                data =>{
                  if(data['response'] == "ok"){
                    this.idEncargoCreado = data['idEncargo'];
                    let datosMensaje = {
                      'idEncargo': data['idEncargo'],
                      'idUsuario': this.tramitador.trm_id_user,
                      'textoMensaje': result
                    };

                    //Si es un encargo urgente se le envia un email al medico
                    if(this.isUrgente){
                      let datosEmail = {
                        'idEncargo': this.idEncargoCreado
                      };
                      this.mostrarProgressSpinner = false;
                      //Enviar email al medico
                      //this.enviarEmailEncargoUrgente(datosEmail);
                    }

                    this.mostrarProgressSpinner = false;
                    this.notificacion.notificationOk(data['mensaje']);
                    
                    
                  }else{
                    this.notificacion.notificationFail(data['mensaje']);
                  }
                }, error =>{
                  this.mostrarProgressSpinner = false;
                  this.notificacion.notificationFail("Error en el servidor");
                }
              );
              
            }else{
              this.mostrarProgressSpinner = false;
            }
          }
        );
      }else if(idEstado == 1){
        this.tramitadorService.crearTareaEncargo(datos).subscribe(
          data =>{
            this.mostrarProgressSpinner = false;
            if(data['response'] == "ok"){
              this.notificacion.notificationOk("Tarea guardada en estado: En tramitación. Se te redireccionará en 3 segundos");
              setTimeout(() => {
                if(this.idTipo == 0) {
                  this.router.navigate(['tramitador-listado/asesorias']);
                }
                this.router.navigate(['encargo-vdc-asistencial']);
              }, 3000);
            }else{
              this.notificacion.notificationFail(data['mensaje']);
            }
          }, error =>{
            this.mostrarProgressSpinner = false;
            this.notificacion.notificationFail("Error en el servidor");
          }
        );
        
      }

    }else{
      //this.mostrarProgressSpinner = false;
      //this.notificacion.notificationFail("El campo 'Medicos' o 'Tipo' no estan rellenos");
    }
    //this.notificacion.notificationOk('Aún no tiene funcionalidad, en desarrollo');

  }

  // //Enviar email encargo urgente
  // enviarEmailEncargoUrgente(datosEmail){
  //   this.tramitadorService.enviarEmailEncargoUrgente(datosEmail).subscribe(
  //     data =>{
  //       this.mostrarProgressSpinner = false;
  //       if(data['response'] == "ok"){

  //       }else{
  //         this.notificacion.notificationFail(data['mensaje']);
  //       }
  //     }, error =>{
  //       this.mostrarProgressSpinner = false;
  //       this.notificacion.notificationFail("Error en el servidor");
  //     }
  //   );
  // }
  
  //Crear autorizacion y enviar mensaje
  crearAutorizacion(datosMensaje){
    this.mostrarProgressSpinner = true;
    let datos = {
      'idMedico': this.idMedico,
      'idEncargo': this.idEncargoCreado,
      'idExpedienteCrm': this.expediente.idExpediente,
      'idTipo' : this.idTipo,
      'idCia': this.expediente.cia
    };
    this.funcionesService.crearAut(datos).subscribe(
      data =>{
        for(let dato in data) {
          let valor = data[dato];
          console.log(`Clave: ${dato}, Valor: ${valor}`);
        }
        this.mostrarProgressSpinner = false;
        if(data['response'] == "ok"){
          this.funcionesService.addMensajeEncargo(datosMensaje).subscribe(
            data => {
              this.mostrarProgressSpinner = false;
              if(data['response'] == "ok"){
                this.notificacion.notificationOk("Tarea guardada en estado: Enviado. Se te redireccionará en 3 segundos");
                setTimeout(() => {
                  if(this.idTipo == 0) {
                    this.router.navigate(['tramitador-listado/asesorias']);
                  }
                  
                }, 3000);
              }else{
                console.log(data['mensaje']);
                this.notificacion.notificationFail(data['mensaje']);
              }
            }, error =>{
              this.mostrarProgressSpinner = false;
              this.notificacion.notificationFail("Error en el servidor");
            }
          );
        }else{
          this.notificacion.notificationFail(data['mensaje']);
        }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor. Autorización no creada");
        
      }
    );
  }

  cancelar(){
    //Eliminar encargo
    this.funcionesService.borrarEncargo(this.idEncargo).subscribe(
      data =>{
        this.mostrarProgressSpinner = false;
          if(data['response'] == "ok"){
            this.mostrarProgressSpinner = true;
            this.notificacion.notificationOk(data['mensaje'] + " Se te redireccionará en 3 segundos.");
            setTimeout(() => {
              this.router.navigate(['tramitador-principal'])
              .then(() => {
                window.location.reload();
              });
            }, 3000);
            
          }else{
            this.notificacion.notificationFail(data['mensaje']);
          }
      }, error =>{
        this.mostrarProgressSpinner = false;
        this.notificacion.notificationFail("Error en el servidor");
      }
    );
  }
    
}
