import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Session } from '../models/session';
import { FuncionesPrincipalService } from '../services/funciones-principal.service';
import { NotificacionService } from '../services/notificacion.service';
import { SessionService } from '../services/session.service';
import { trigger, state, style, animate, transition } from '@angular/animations';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('tutorialAnimation', [
      state('void', style({ opacity: 0, transform: 'translateX(-100%)' })),
      state('*', style({ opacity: 1, transform: 'translateX(0)' })),
      transition(':enter', animate('0.5s ease')),
      transition(':leave', animate('0.5s ease-out'))
    ])
  ] 
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  dataSession: Session;
  tutorialVisible: boolean = false;
  mostrarProgressSpinner = false;


  constructor(private funcionesService: FuncionesPrincipalService, private sessionService: SessionService, private router: Router, private notificacion: NotificacionService) { }
  
  ngOnInit(): void {
    if(localStorage.getItem('currentUser')) {
      this.mostrarProgressSpinner = true;
    }else {
      this.mostrarProgressSpinner = false;
    }
  }

  ngAfterViewInit(): void {
    // this.displayThemeButtons();
  }

  login(){
    if(!localStorage.getItem('currentUser')) { 
      if(this.email && this.password){
        this.funcionesService.login(this.email, this.password).subscribe(
          data =>{
            if(data['message'] == 'Unauthorized'){
              this.notificacion.notificationFail("Los credenciales son incorrectos");
            }else{
              //console.log(data);
              this.dataSession = data;
              this.sessionService.setCurrentSession(this.dataSession);
              
              //Si es medico navega al portal de medicos, sino al de tramitador
              if(data.user.tipo == 0){
                this.router.navigateByUrl('medico-principal/valoracionDocumental');
              }else if(data.user.tipo == 1){
                this.router.navigateByUrl('tramitador-principal');
              }else if(data.user.tipo == 2){
                this.router.navigateByUrl('facturador-principal');
              }
            }
          }, error =>{
            this.notificacion.notificationFail("Error en el servidor");
          }
        );
      }else{
        alert("Rellene usuario y/o contraseña.")
      }
    }else{
      alert("Ya hay una sesión activa, si quiere iniciar sesión debe cerrar la anterior");
    }
    
  }

  themes = [
    {
      background: "#e7f8f2",
      color: "#333",
      primaryColor: "#5AA783"
    },
    {
      background: "#2b5448",
      color: "#FFFFFF",
      primaryColor: "#407f69"
    }
  ];

  setTheme(theme: any): void {
    const root = document.querySelector(":root") as HTMLElement;
    root.style.setProperty("--background", theme.background);
    root.style.setProperty("--color", theme.color);
    root.style.setProperty("--primary-color", theme.primaryColor);
  }

  displayThemeButtons(): void {
  const btnContainer = document.querySelector(".theme-btn-container") as HTMLElement;
  if (btnContainer) {
    this.themes.forEach((theme) => {
      const div = document.createElement("div");
      div.className = "theme-btn";
      div.style.cssText = `background: ${theme.background}; width: 25px; height: 25px; margin: 5px; cursor: pointer;`;
      btnContainer.appendChild(div);
      div.addEventListener("click", () => this.setTheme(theme));
    });
  } else {
    console.error("Container element with class 'theme-btn-container' not found.");
  }
}

toggleTutorial(): void {
  this.tutorialVisible = !this.tutorialVisible;
}

  toggleTutorialOnClick(): void {
    const toggleButton = document.querySelector('#toggleTutorial') as HTMLElement;
    toggleButton.addEventListener('click', () => this.toggleTutorial());
  }
}

