import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FacturasRecibidas } from 'src/app/facturacion/models/facturas-recibidas';
import { Incidencia } from 'src/app/facturacion/models/incidencia';
import { IncidenciaContabilidad } from 'src/app/facturacion/models/incidencia-contabilidad';
import { FacturacionServiceService } from 'src/app/facturacion/services/facturacion-service.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-incidencia-factura-recibida-dialog',
  templateUrl: './incidencia-factura-recibida-dialog.component.html',
  styleUrls: ['./incidencia-factura-recibida-dialog.component.scss']
})
export class IncidenciaFacturaRecibidaDialogComponent implements OnInit {

  facturaRecibida: FacturasRecibidas;
  motivo: string = 'Cambio de estado a pendiente';
  observaciones: string = '';

  constructor(
    public facturacionService: FacturacionServiceService, 
    private sessionService: SessionService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataIncidenciaRecibida,
    public dialogRef: MatDialogRef<IncidenciaFacturaRecibidaDialogComponent>,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.facturaRecibida = this.data.facturaRecibida;
  }

  crearIncidenciaContabilidad(){

    let incidencias: Incidencia[] = [];
    let incidenciasContabilidad: IncidenciaContabilidad[] = [];

    let incidencia: Incidencia = {
      inc_id_incidencias: undefined,
      inc_fecha_creacion: undefined,
      inc_fecha_modificacion: undefined,
      inc_usuario_creacion: this.sessionService.getCurrentSession().user.id.toString(),
      inc_estado: 'Pendiente',
      inc_tipo: 'Recibida',
      inc_usuario_asignado: '15',
      inc_descripcion: undefined,
      inc_fecha_finalizacion: undefined
    }

    let incidenciaContabilidad: IncidenciaContabilidad = {
      incc_id_incidencias_contabilidad: undefined,
      incc_fecha_creacion: undefined,
      incc_fecha_modificacion: undefined,
      incc_id_incidencia: undefined,
      incc_id_contablidad: this.facturaRecibida.factr_contabilidad,
      incc_id_factura: this.facturaRecibida.factr_id_facturas_recibidas,
      incc_comentario: this.observaciones,
      incc_motivo: this.motivo,
    }

    incidencias.push(incidencia);
    incidenciasContabilidad.push(incidenciaContabilidad);

    if(this.facturaRecibida.factr_estado_factura = 'EST-004'){
      let incidenciaPagada: Incidencia = {
        inc_id_incidencias: undefined,
        inc_fecha_creacion: undefined,
        inc_fecha_modificacion: undefined,
        inc_usuario_creacion: this.sessionService.getCurrentSession().user.id.toString(),
        inc_estado: 'Pendiente',
        inc_tipo: 'Pago',
        inc_usuario_asignado: '15',
        inc_descripcion: undefined,
        inc_fecha_finalizacion: undefined
      }
  
      let incidenciaContabilidadPagada: IncidenciaContabilidad = {
        incc_id_incidencias_contabilidad: undefined,
        incc_fecha_creacion: undefined,
        incc_fecha_modificacion: undefined,
        incc_id_incidencia: undefined,
        incc_id_contablidad: this.facturaRecibida.factr_contabilidad,
        incc_id_factura: this.facturaRecibida.factr_id_facturas_recibidas,
        incc_comentario: this.observaciones,
        incc_motivo: this.motivo,
      }

      incidencias.push(incidenciaPagada);
      incidenciasContabilidad.push(incidenciaContabilidadPagada);

    }

    this.facturacionService.crearIncidenciaContabilidadRecibida(incidencias, incidenciasContabilidad).subscribe({
      error: (err: any) => {
        console.log(err);
        this.snackBar.open("Error en el servidor", undefined, {
          duration: 3 * 1000,
        });
      },
      complete: () => {
        this.snackBar.open("Incidencia de contabilidad creada", undefined, {
          duration: 3 * 1000,
        });
        this.dialogRef.close();
      }
    })

  }

}

interface DialogDataIncidenciaRecibida{
  facturaRecibida: FacturasRecibidas
}
