import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appHoraFormat]'
})
export class HoraFormatDirective {

  constructor(public ngControl: NgControl) { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    if (!value) {
      return; // Permitir campo vacío
    }

    const cleanValue = value.replace(/\D/g, ''); // Remover caracteres no numéricos
    let formattedValue = '';

    // Asegurar que los primeros dos caracteres estén limitados a 23 y los siguientes dos a 59
    if (cleanValue.length >= 2) {
      formattedValue += cleanValue.substring(0, 2);
      if (cleanValue.length >= 3) {
        formattedValue += ':' + cleanValue.substring(2, 4);
      }
    }

    this.ngControl.control.setValue(formattedValue, { emitEvent: false });
  }
}
