import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FacturasEmitidas } from '../../models/facturas-emitidas';
import { FacturasEmitidasProductos } from '../../models/facturas-emitidas-productos';
import { FacturacionServiceService, FacturasEmitidasList, FacturasEmitidasProductosList } from '../../services/facturacion-service.service';

import * as XLSX from 'xlsx';
import { IncidenciaFacturaEmitidaDialogComponent } from 'src/app/dialogs/incidencia-factura-emitida-dialog/incidencia-factura-emitida-dialog.component';
import { UnidadFiscal } from '../../models/unidad-fiscal';
import { Contabilidad } from '../../models/contabilidad';
import { SessionService } from 'src/app/services/session.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-facturas-emitidas',
  templateUrl: './facturas-emitidas.component.html',
  styleUrls: ['./facturas-emitidas.component.scss']
})
export class FacturasEmitidasComponent implements OnInit {

  facturasEmitidas : FacturasEmitidas[] = [];
  productos : FacturasEmitidasProductos[] = [];
  totalFacturado = 0;

  yearFilter = new Date().getFullYear();

  filteredValues = {
    nFactura : "",
    expediente : "",
    compania : "",
    lesionado : "",
    refSiniestro : "",
    provincia : "",
    fechaFactura : "",
    totalFacturado : "",
    totalImponible : "",
    totalIva : "",
    estado : "",
  };


  //Flags
  //---Flag mostrar loading cuando se vaya a hacer doble click a la factura---
  loadingPageFlag = false;
    //---Flag mostrar loading cuando se vaya a hacer click a la factura al mostrar sus productos---
  loadingProductos = false;

  //Tablas
  @ViewChild('tableFacturasEmitidas')
  facturasEmitidasTable: MatTable<FacturasEmitidas>;
  displayedColumnsFacturasEmitidas: string[] = ['checkbox', 'nFactura', 'expediente', 'compañia', 'lesionado', 'refSiniestro', 'provincia', 'fechaFactura', 'totalFacturado', 'totalImponible', 'totalIva', 'estado', 'fechaCobro'];
  dataSourceFacturasEmitidas: MatTableDataSource<FacturasEmitidas> = new MatTableDataSource(this.facturasEmitidas);

  @ViewChild('tableProductos')
  productosTable: MatTable<FacturasEmitidasProductos>;
  displayedColumnsProductos: string[] = ['id', 'tipo', 'precio', 'fecha'];
  dataSourceProductos: MatTableDataSource<FacturasEmitidasProductos> = new MatTableDataSource(this.productos);

  //Paginator Facturas
  @ViewChild('facturasEmitidasPaginator')
  paginator: MatPaginator;
  facturasEmitidasLength = 0;
  selection = new SelectionModel<FacturasEmitidas>(true, []);

  constructor(
    private facturacionService : FacturacionServiceService,
    private sessionService: SessionService,
    private snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.obtenerFacturasEmitidas();
  }

  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = 'Mostrando:';
  }

  obtenerFacturasEmitidas(){

    this.loadingPageFlag = true;

    this.facturacionService.obtenerFacturasEmitidas().subscribe({
      next: (data: FacturasEmitidasList) => {
        this.facturasEmitidas = data.data.filter(factura => 
          new Date(factura.facte_fecha_creacion).getFullYear() === this.yearFilter
        );

        this.dataSourceFacturasEmitidas = new MatTableDataSource(this.facturasEmitidas);

        this.calculateTotalFact();

        this.facturasEmitidasLength = this.dataSourceFacturasEmitidas.filteredData.length;

        this.dataSourceFacturasEmitidas.paginator = this.paginator;

        this.productos = [];
        this.deselectAll();

        this.loadingPageFlag = false;
      },

      error: (err: any) => {
        console.log(err);
      }
    });
  }

  obtenerFacturaEmitidaProductos(factura : FacturasEmitidas){

    this.loadingProductos = true;

    this.facturacionService.obtenerFacturaEmitidaProductos(factura).subscribe({
      next: (data: FacturasEmitidasProductosList) => {
        this.productos = data.data;
        //console.log(data.data);
        this.dataSourceProductos = new MatTableDataSource(this.productos);

        this.loadingProductos = false;
      },

      error: (err: any) => {
        console.log(err);

        this.loadingProductos = false;
      }
    });
  }

  updateToDuplicada(){

    if(this.selection.selected.length > 0){
      this.loadingProductos = true;

      this.facturacionService.actualizarEstadoFacturasEmitidas(this.selection.selected, "duplicada").subscribe({
        next: (data: any) => {
          //console.log(data.data);
          this.selection.selected.forEach(factura => {
            factura.facte_estado_factura = "duplicada";
          });

          this.loadingProductos = false;
        },

        error: (err: any) => {
          console.log(err);

          this.loadingProductos = false;
        }
      });
    }
    
  }

  updateToCobrada(){

    if(this.selection.selected.length > 0){
      this.loadingProductos = true;
    
      this.facturacionService.actualizarEstadoFacturasEmitidas(this.selection.selected, "cobrada").subscribe({
        next: (data: any) => {
          //console.log(data.data);
          this.selection.selected.forEach(factura => {
            factura.facte_estado_factura = "cobrada";
          });

          this.loadingProductos = false;
        },

        error: (err: any) => {
          console.log(err);

          this.loadingProductos = false;
        }
      });
    }
    
    
  }

  navigateToFacturacion(factura: FacturasEmitidas){
    this.router.navigate(['/facturar-expediente'], {
      queryParams: {
          factura: factura.facte_id_facturas_emitidas
      },
    });
  }

  createIncidencia(){
    if(this.selection.selected.length == 1){
      if(this.selection.selected[0].facte_contabilidad != null){
        const dialogIncFactEmitida = this.dialog.open(IncidenciaFacturaEmitidaDialogComponent, {
          data: {
            facturaEmitida: this.selection.selected[0],
          },
        });

        dialogIncFactEmitida.afterClosed().subscribe({
          next: (result: any) => {
            this.obtenerFacturasEmitidas();
          }
        })
      }
      else{
        this.snackBar.open("Esta factura no está contabilizada", undefined, {
          duration: 3 * 1000,
        });
      }
    }
    else if(this.selection.selected.length == 0){
      this.snackBar.open("Debe seleccionar al menos una factura", undefined, {
        duration: 3 * 1000,
      });
    }
    else{
      this.snackBar.open("Debe seleccionar solo una factura", undefined, {
        duration: 3 * 1000,
      });
    }
    
  }

  exportarFacturas() {

    if(this.dataSourceFacturasEmitidas.filteredData.length > 0){

      var ws_data = [[
        'Número de factura',
        'Expediente',
        'Compañía',
        'Lesionado',
        'Referencia de siniestro',
        'Provincia',
        'Fecha de factura',
        'Total facturado',
        'Total imponible',
        'Total IVA',
        'Estado'
      ]];

      this.dataSourceFacturasEmitidas.filteredData.forEach(factura => {
        ws_data.push([
          factura.facte_id_facturas_emitidas,
          factura.facte_expediente,
          factura.facte_compania,
          factura.facte_lesionado,
          factura.facte_expediente_ref_siniestro,
          factura.facte_provincia,
          factura.facte_fecha_creacion.toString(),
          factura.facte_total_facturado.toString(),
          factura.facte_total_imponible.toString(),
          factura.facte_total_iva.toString(),
          factura.facte_estado_factura,
        ])
      });

       /* pass here the table id */
      const ws: XLSX.WorkSheet =XLSX.utils.aoa_to_sheet(ws_data);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */  
      XLSX.writeFile(wb, "Facturas emitidas.xlsx");

    }

  }

  searchFactura() {

    this.dataSourceFacturasEmitidas.filterPredicate = this.customFilterPredicate();

    this.dataSourceFacturasEmitidas.filter = JSON.stringify(this.filteredValues);

    this.calculateTotalFact();

  }

  customFilterPredicate() {
    const myFilterPredicate = (data: FacturasEmitidas, filter: string): boolean => {
      let searchString = JSON.parse(filter);
      console.log(searchString);
      console.log(searchString['nFactura']);
      return data.facte_id_facturas_emitidas.toLowerCase().includes(searchString['nFactura'].toLowerCase()) &&
      data.facte_expediente.toLowerCase().includes(searchString['expediente'].toLowerCase()) &&
      data.facte_compania.toLowerCase().includes(searchString['compania'].toLowerCase()) &&
      data.facte_lesionado.toLowerCase().includes(searchString['lesionado'].toLowerCase()) &&
      data.facte_expediente_ref_siniestro.toLowerCase().includes(searchString['refSiniestro'].toLowerCase()) &&
      data.facte_provincia.toLowerCase().includes(searchString['provincia'].toLowerCase()) &&
      data.facte_fecha_creacion.toString().toLowerCase().includes(searchString['fechaFactura'].toLowerCase()) &&
      data.facte_total_facturado.toString().toLowerCase().includes(searchString['totalFacturado'].toLowerCase()) &&
      data.facte_total_imponible.toString().toLowerCase().includes(searchString['totalImponible'].toLowerCase()) &&
      data.facte_total_iva.toString().toLowerCase().includes(searchString['totalIva'].toLowerCase()) &&
      data.facte_estado_factura.toLowerCase().includes(searchString['estado'].toLowerCase()) ;
    }
    return myFilterPredicate;
  }

  generarInformeFactura(){

    if(this.selection.selected.length > 0){

      if(this.selection.selected.length == 1){

        this.loadingPageFlag = true;
      
        this.facturacionService.generarInformeFactura(this.selection.selected[0]).subscribe({
          next: (data: any) => {
            this.snackBar.open("PDF generado", undefined, {
              duration: 3 * 1000,
            });
          },
          error: (err: any) => {
            this.snackBar.open("Error en el servidor", undefined, {
              duration: 3 * 1000,
            });
          },
          complete: () => {
            this.loadingPageFlag = false;
          }
        })
      
      }
      else{
        this.snackBar.open("Debe seleccionar una unica factura", undefined, {
          duration: 3 * 1000,
        });
      }
    
    }
    else{
      this.snackBar.open("Debe seleccionar una factura", undefined, {
        duration: 3 * 1000,
      });
    }

  }

  contabilizarFacturaEmitida(){
    //TODO

    if(this.selection.selected.length > 0){

      let contadorFacturas = 0;
      const facturasEmitidasFiltered: FacturasEmitidas[] = this.selection.selected.filter(factura => ['cobrado', 'facturado'].includes(factura.facte_estado_factura));

      var ws_data = [[
        'Tipo de Factura',
        'Fecha del Asiento',
        'Fecha de la Factura',
        'Tipo de operación',
        'Número de la Factura',
        'Subcuenta del Cliente/Proveedor',
        'NIF del Cliente/Proveedor',
        'Nombre del Cliente/Proveedor',
        'Concepto del Asiento',
        'Base Imponible',
        'Porcentaje del I.V.A.',
        'Importe del I.V.A.',
        'Subcuenta del I.V.A.',
        'Porcentaje del Recargo de Equivalencia',
        'Importe del Recargo de Equivalencia',
        'Subcuenta del Recargo de Equivalencia',
        'Total de la Factura',
        'Identificador de Factura Rectificativa',
        'Subcuenta del Gasto/Ingreso',
        'Importe del Gasto/Ingreso',
        'Domicilio del Cliente/Proveedor',
        'Número (4), Escalera (4), Piso (2), Puerta (2) del Cliente/Proveedor',
        'Localidad del Cliente/Proveedor',
        'Provincia del Cliente/Proveedor',
        'Código Postal del Cliente/Proveedor',
        'Flag de Cobro/Pago al Contado',
        'Subcuenta Debe de Cobro/Pago al Contado',
        'Subcuenta Haber de Cobro/Pago al Contado',
        'Importe de Cobro/Pago al Contado',
        'Importe base de Irpf',
        'Porcentaje de Irpf',
        'Importe de Irpf',
        'Subcuenta de Irpf',
        'Criterio de Caja S/N',
        'Tipo de Pago',
        'Medio de Cobro/Pago',
        'Descripción Del Pago',
        'Comunicada',
        'Ticket',
        'Venta a Canarias',
        'Documento',
      ]];

      facturasEmitidasFiltered.forEach(facturaEmitida => {
        
        this.facturacionService.obtenerFacturaEmitidaProductos(facturaEmitida).subscribe({
          next : (data: FacturasEmitidasProductosList) => {

            const productosFactura : FacturasEmitidasProductos[] = data.data;
            let ivaList = productosFactura.map(producto => producto.factep_iva).filter((x, i, a) => a.indexOf(x) === i);

            console.log(productosFactura);
            console.log(ivaList);

            this.facturacionService.obtenerUnidadFiscal(facturaEmitida.facte_unidad_fiscal).subscribe({
              next: (data: any) => {
                //console.log('unidadFiscal', data.data);

                const unidadFiscal: UnidadFiscal = data.data;

                productosFactura.forEach(productoFactura => {

                  ws_data.push([
                    ivaList.length > 1 ? 'CM' : 'C',
                    facturaEmitida.facte_fecha_validacion != null ? facturaEmitida.facte_fecha_validacion.toString() : '',
                    facturaEmitida.facte_fecha_creacion.toString(),
                    ivaList.filter(iva => iva != 0).length > 0 ? '1' : '2',
                    facturaEmitida.facte_numero_factura,
                    unidadFiscal.unif_sub_cuenta_contable,
                    'ID COMPAÑÍA',
                    'PROVEEDOR NOMBRE',
                    'TODO CONCEPTO',
                    'BASE IMPONIBLE',
                    productoFactura.factep_iva.toString() + '%',
                    facturaEmitida.facte_total_iva.toString() + '€',
                    'TODO SUBCUENTA DEL IVA',
                    '',
                    '',
                    '',
                    facturaEmitida.facte_total_facturado.toString() + '€',
                    '',
                    unidadFiscal.unif_cuenta_ingresos ,
                    'BASE IMPONIBLE',
                    'DOMICILIO',
                    '',
                    'Localidad del Cliente/Proveedor',
                    'PROVINCIA',
                    'CODIGO POSTAL',
                    'NO',
                    '',
                    '',
                    '',
                    'BASE IMPONIBLE',
                    'IRPF',
                    'TOTAL RETENCIóN',
                    unidadFiscal.unif_cuenta_irpf,
                    '',
                    '',
                    '',
                    '',
                    'NO',
                    'NO',
                    '',
                    '',
                  ])

                });

                contadorFacturas++;

                if(contadorFacturas == facturasEmitidasFiltered.length){
                  const ws: XLSX.WorkSheet =XLSX.utils.aoa_to_sheet(ws_data);   
      
                  /* generate workbook and add the worksheet */
                  const wb: XLSX.WorkBook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
                  const contabilidad: Contabilidad = {
                    con_id_contabilidad: undefined,
                    con_fecha_creacion: undefined,
                    con_fecha_modificacion: undefined,
                    con_estado: 'ESTC-001',
                    con_usuario_creador: this.sessionService.getCurrentSession().user.id.toString(),
                  }

                  this.facturacionService.crearContabilidadEmitidas(contabilidad, facturasEmitidasFiltered).subscribe({
                    error: (err: any) => {
                      this.snackBar.open("Error en el servidor", undefined, {
                        duration: 3 * 1000,
                      });
                    },
                    complete: () => {
                      /* save to file */  
                      XLSX.writeFile(wb, "Contabilidad.xlsx");
                      this.snackBar.open("Contabilidad generada", undefined, {
                        duration: 3 * 1000,
                      });
                      this.obtenerFacturasEmitidas();
                    }
                  });

                }

              },
              error: (err: any) => {
                console.log(err);
                this.snackBar.open("Error en el servidor", undefined, {
                  duration: 3 * 1000,
                });
              }
            })

          },
          error: (err: any) => {
            this.snackBar.open("Error en el servidor", undefined, {
              duration: 3 * 1000,
            });
          }
        })
      });

    }
    else{
      this.snackBar.open("Debe seleccionar al menos una factura", undefined, {
        duration: 3 * 1000,
      });
    }

  }

  //Paginator Funciones

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceFacturasEmitidas.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSourceFacturasEmitidas.data);
  }

  checkboxLabel(row?: FacturasEmitidas): string {
    let positionId : number;
    this.facturasEmitidas.forEach((factura, key) => {
      if(factura == row) positionId = key;
    });
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${positionId + 1}`;
  }

  deselectAll(){
    this.selection.clear();
  }

  calculateTotalFact(){

    //Suma del total facturado
    this.totalFacturado = 0;
    this.dataSourceFacturasEmitidas.filteredData.forEach(factura => {
      this.totalFacturado += factura.facte_total_facturado;
    });

  }

}
