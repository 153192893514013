import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Encargo } from '../../models/encargo';
import { Tramitador } from '../../models/tramitador';
import { SessionService } from '../../services/session.service';
import { TramitadorPortalService } from '../../services/tramitador-portal.service';

@Component({
  selector: 'app-tramitador-mensajes',
  templateUrl: './tramitador-mensajes.component.html',
  styleUrls: ['./tramitador-mensajes.component.scss'],
  //Cambios css en las clases de material angular
  encapsulation: ViewEncapsulation.None
})
export class TramitadorMensajesComponent implements OnInit {

  encargosTramitacion : Encargo[];
  encargosEnviados : Encargo[];
  encargosFinalizados : Encargo[];
  encargosPendienteRevision : Encargo[];
  encargosRevisado : Encargo[];
  encargosPendienteEnviar : Encargo[];

  encargosArrayAux : Encargo[];
  busquedaType : string;

  tramitador: Tramitador;
  mostrarProgressSpinner1 = true;
  mostrarProgressSpinner2 = true;
  mostrarProgressSpinner3 = true;
  mostrarProgressSpinner4 = true;
  mostrarProgressSpinner5 = true;
  mostrarProgressSpinner6 = true;

  checkFechaCreacion: boolean = false;
  checkFechaLimite: boolean = false;
  opcMensajesSinLeer: string = "mensajesSinLeer";

  constructor(private tramitadorService: TramitadorPortalService, private router: Router,
    private sessionService: SessionService) {
      this.tramitador = this.sessionService.getCurrentSession().tramitador;
    }

  ngOnInit(): void {
    this.encargos();
  }

  encargos(){
    this.tramitadorService.getEncargosTramitacion(this.opcMensajesSinLeer).subscribe(
      data => {
        this.mostrarProgressSpinner1 = false;
        
        //console.log(data);
        if(data['response'] == "ok"){
          this.encargosTramitacion = this.encargosConMensajes(data['encargosTramitacion']);
          this.encargosArrayAux = this.encargosConMensajes(data['encargosTramitacion']);
        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner1 = false;
      }
    );

    this.tramitadorService.getEncargosEnviados(this.opcMensajesSinLeer).subscribe(
      data => {
        this.mostrarProgressSpinner5 = false;
        
        //console.log(data);
        if(data['response'] == "ok"){
          this.encargosEnviados = this.encargosConMensajes(data['encargosEnviados']);
        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner5 = false;
      }
    );

    this.tramitadorService.getEncargosFinalizados(this.opcMensajesSinLeer).subscribe(
      data => {
        this.mostrarProgressSpinner2 = false;
        
        //console.log(data);
        if(data['response'] == "ok"){
          this.encargosFinalizados = this.encargosConMensajes(data['encargosFinalizados']);
        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner2 = false;
      }
    );

    this.tramitadorService.getEncargosPendienteRevision(this.opcMensajesSinLeer).subscribe(
      data => {
        this.mostrarProgressSpinner3 = false;
        
        //console.log(data);
        if(data['response'] == "ok"){
          this.encargosPendienteRevision = this.encargosConMensajes(data['encargosPendienteRevision']);
        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner3 = false;
      }
    );

    this.tramitadorService.getEncargosRevisado(this.opcMensajesSinLeer).subscribe(
      data => {
        this.mostrarProgressSpinner4 = false;
        
        //console.log(data);
        if(data['response'] == "ok"){
          this.encargosRevisado = this.encargosConMensajes(data['encargosRevisado']);
        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner4 = false;
      }
    );

    this.tramitadorService.getEncargosPendienteEnviar(this.opcMensajesSinLeer).subscribe(
      data => {
        this.mostrarProgressSpinner5 = false;
        
        //console.log(data);
        if(data['response'] == "ok"){
          this.encargosPendienteEnviar = this.encargosConMensajes(data['encargosPendienteEnviar']);
        }else{

        }
      }, error =>{
        this.mostrarProgressSpinner5 = false;
      }
    );
  }

  //Filtra solo los encargos con mensajes
  encargosConMensajes(encargos: Encargo[]){
    let encargosConMensajes: Encargo[] = [];

    encargos.forEach(function (encargo){
      if(encargo.mensajes_count != 0){
        encargosConMensajes.push(encargo);
      }
    });

    return encargosConMensajes;
  }

  colorEncargo(fechaLimite: string){
    var color = '';
    var fechaNow = new Date();

    if(fechaLimite != null){
      var quitarHoraFechaLimite = fechaLimite.split(" ");
      var arrayFechaLimite = quitarHoraFechaLimite[0].split("/");
      var fechaLimiteTomorrow = new Date(parseInt(arrayFechaLimite[2]), parseInt(arrayFechaLimite[1]) - 1, parseInt(arrayFechaLimite[0]));
      var fechaLimiteFormat = new Date(parseInt(arrayFechaLimite[2]), parseInt(arrayFechaLimite[1]) - 1, parseInt(arrayFechaLimite[0]));

      fechaLimiteTomorrow.setDate(fechaLimiteFormat.getDate() + 1);

      if(fechaNow<fechaLimiteFormat){
        color = 'color_verde';
      }else if(fechaNow.getFullYear()==fechaLimiteFormat.getFullYear() && fechaNow.getMonth()==fechaLimiteFormat.getMonth() && fechaNow.getDate()==fechaLimiteFormat.getDate() 
      || fechaNow.getFullYear()==fechaLimiteTomorrow.getFullYear() && fechaNow.getMonth()==fechaLimiteTomorrow.getMonth() && fechaNow.getDate()==fechaLimiteTomorrow.getDate()){
        color = 'color_amarillo';
      }else if(fechaNow>fechaLimiteTomorrow){
        color = 'color_rojo';
      }else{
        color = '';
      }
  
      return color;
    }
  }

  colorEncargoFila(fechaLimite: string, prioridad: number){
    var color = '';
    var fechaNow = new Date();

    if(fechaLimite != null){
      var quitarHoraFechaLimite = fechaLimite.split(" ");
      var arrayFechaLimite = quitarHoraFechaLimite[0].split("/");
      var fechaLimiteTomorrow = new Date(parseInt(arrayFechaLimite[2]), parseInt(arrayFechaLimite[1]) - 1, parseInt(arrayFechaLimite[0]));
      var fechaLimiteFormat = new Date(parseInt(arrayFechaLimite[2]), parseInt(arrayFechaLimite[1]) - 1, parseInt(arrayFechaLimite[0]));

      fechaLimiteTomorrow.setDate(fechaLimiteFormat.getDate() + 1);

      if(fechaNow<fechaLimiteFormat){
        color = 'color_verde_fila';
      }else if(fechaNow.getFullYear()==fechaLimiteFormat.getFullYear() && fechaNow.getMonth()==fechaLimiteFormat.getMonth() && fechaNow.getDate()==fechaLimiteFormat.getDate() 
                || fechaNow.getFullYear()==fechaLimiteTomorrow.getFullYear() && fechaNow.getMonth()==fechaLimiteTomorrow.getMonth() && fechaNow.getDate()==fechaLimiteTomorrow.getDate()){
        color = 'color_amarillo_fila';
      }else if(fechaNow>fechaLimiteTomorrow){
        color = 'color_rojo_fila';
      }else{
        color = '';
      }
      
    }

    if(prioridad != null){
      if(prioridad == 1){
        color = color + ' color_urgente_fila';
      }
    }

    return color;
  }

  abrirEncargo(idEncargo: number){
    this.router.navigate(['encargo-tramitador', {idEncargo: idEncargo}]);
  }

  abrirEncargoTramitacion(idEncargo: number){
    this.router.navigate(['tramitador-principal', {idEncargo: idEncargo}]);
  }

  filtrarPorIdPlataforma(value : string, arrayEncargos : Encargo[]){
    var filtered : Encargo[] = [];
    //Compruebo si el input tiene escrito algo
    if(value){
      for(var i = 0; i < arrayEncargos.length; i++){
        var obj : Encargo = arrayEncargos[i];
        
        //Filtrar por varios parametros
        if(obj.id_plataforma.includes(value.toUpperCase()) || obj.enup_lesionado.includes(value.toUpperCase()) || 
          obj.nombre_tipo_encargo.includes(value.toUpperCase()) || (this.checkFechaCreacion == true ? obj.enup_fecha_tarea.toString().includes(value.toUpperCase()): false) || 
          (obj.enup_fecha_limite ? (this.checkFechaLimite == true ? obj.enup_fecha_limite.includes(value.toUpperCase()) : false) : false) || 
          (obj.nombreMedico ? obj.nombreMedico.toUpperCase().includes(value.toUpperCase()) : false) || 
          (obj.nombreTramitador ? obj.nombreTramitador.toUpperCase().includes(value.toUpperCase()) : false) || (obj.ciaNombre ? obj.ciaNombre.toUpperCase().includes(value.toUpperCase()) : false)){
          
            filtered.push(obj);
        }
      }   

      this.encargosArrayAux = filtered;

    }else{
      this.encargosArrayAux = arrayEncargos;
    }

  }

  //Cuando cambiamos de pestaña, la barra de búsqueda se pone en blanco, y el array recibe el valor en función de la
  //pestaña en que se encuentre
  onTabChanged($event) {
    let clickedIndex = $event.index;
    this.busquedaType = "";
    switch(clickedIndex) { 
      case 0: { 
          this.encargosArrayAux = this.encargosTramitacion;
         break; 
      } 
      case 1: { 
        this.encargosArrayAux = this.encargosEnviados;

         break; 
      } 
      case 2: { 
        this.encargosArrayAux = this.encargosPendienteRevision;

         break; 
      } 
      case 3: { 
        this.encargosArrayAux = this.encargosFinalizados

         break; 
      } 
    } 
  }

}
