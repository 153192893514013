import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cancelar-encargo-dialog',
  templateUrl: './cancelar-encargo-dialog.component.html',
  styleUrls: ['./cancelar-encargo-dialog.component.scss']
})
export class CancelarEncargoDialogComponent implements OnInit {

  comentario: string;

  constructor(public dialogRef: MatDialogRef<CancelarEncargoDialogComponent>) { }

  ngOnInit(): void {
  }

}
