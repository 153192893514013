<app-header-encargo-pericial-medico></app-header-encargo-pericial-medico>

<div class="progress_spinner" *ngIf="mostrarProgressSpinner">
    <mat-spinner></mat-spinner>
</div>

<div class="container-fluid">
    <!-- Encargo elegido -->
    <div class="row fila_encargo">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-2">
                    <div>
                        <i class="bi bi-circle-fill icon_size" [ngClass]="colorEncargo(encargo.enup_fecha_limite)"></i>
                        <h3 class="text_inline_icon_encargo">{{encargo.id_plataforma}}</h3>
                    </div>
                    <div>
                        <i class="bi bi-chat-left-text-fill icon_size"><h3 class="text_inline_icon_mensaje">{{countMensajes}} Mensaje</h3></i>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="row">
                        <div *ngIf="encargo.enup_fecha_consulta_video === null" class="col-md-8">
                            <h5 class="m-0 pt-3 subtitulo_h5">Fecha encargo</h5>
                            <h4 class="subtitulo_h4">{{encargo.enup_fecha_tarea}}</h4>
                        </div>
                        <div *ngIf="encargo.enup_fecha_consulta_video != null" class="col-md-8">
                            <h5 class="m-0 pt-3 subtitulo_h5">Fecha Consulta/Videoconsulta</h5>
                            <h4 class="subtitulo_h4">{{encargo.enup_fecha_consulta_video}}</h4>
                        </div>
                        <div class="col-md-2">
                            <mat-divider [vertical] ="true" class="mt-3" style="height:50px"></mat-divider>
                        </div>
                        <div class="col-md-2">
                           
                        </div>
                    </div>
                </div>
    
                <div class="col-md-2">
                    <h5 class="m-0 pt-3 subtitulo_h5">Estado</h5>
                    <h4 class="subtitulo_h4">{{encargo.enup_estado_encargo}}</h4>
                </div>
    
                <div class="col-md-2">
                    <div class="row">
                        <div class="col-md-8">
                            <h5 class="m-0 pt-3 subtitulo_h5">Tipo de encargo</h5>
                            <h4 class="subtitulo_h4">{{encargo.nombre_tipo_encargo}}</h4>
                        </div>
                        <div class="col-md-2">
                            <mat-divider [vertical] ="true" class="mt-3" style="height:50px"></mat-divider>
                        </div>
                        <div class="col-md-2">
                           
                        </div>
                    </div>
                </div>
    
                <div class="col-md-2">
                    <div class="row">
                        <div class="col-md-8">
                            <h5 class="m-0 pt-3 subtitulo_h5">Fecha Limite</h5>
                            <h4 class="subtitulo_h4">{{encargo.enup_fecha_limite | date: 'dd/MM/yyyy HH:mm:ss'}}</h4>
                        </div>
                        <div class="col-md-2">
                            <mat-divider [vertical] ="true" class="mt-3" style="height:50px"></mat-divider>
                        </div>
                        <div class="col-md-2">
                           
                        </div>
                    </div>
                </div>
    
                <div class="col-md-2">
                    <h5 class="m-0 pt-3 subtitulo_h5">Lesionado</h5>
                    <h4 class="subtitulo_h4">{{encargo.enup_lesionado}}</h4>
                </div>
            </div>
    
        </div>
    </div>

    <!-- Informacion -->
    <div class="cointainer_tab">
        <!-- Tabs -->
        <div class="tab">
            <button class="tablinks active" (click)="onChangeOption('datos')" id="datos">Datos Generales</button>
            <button class="tablinks" (click)="onChangeOption('informe')" id="informe">Informe</button>
            <button class="tablinks" (click)="onChangeOption('documentacion')" id="documentacion">Documentación</button>
            <button class="tablinks" (click)="onChangeOption('mensajes')" id="mensajes">Mensajes<span *ngIf="countMensajes!=0" matBadge="{{countMensajes}}" matBadgeColor="warn" matBadgeOverlap="false"></span></button>
            <button class="tablinks" (click)="onChangeOption('historico')" id="historico">Histórico del siniestro</button>
        </div>

        <!-- Datos Generales -->
        <div class="tabcontent datos" id="datos">

            <div class="container-fluid" style="width: 92%;">
                <div class="row m-0 ms-3 fila_datos">
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Nombre del lesionado</h5>
                        <h4 class="subtitulo_h4">{{encargo.enup_lesionado}}</h4>
                    </div>
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Condición del lesionado</h5>
                        <h4 class="subtitulo_h4">{{encargo.enup_condicion_lesionado}}</h4>
                    </div>
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Provincia</h5>
                        <h4 class="subtitulo_h4">{{encargo.enup_provincias}}</h4>
                    </div>
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Localidad</h5>
                        <h4 class="subtitulo_h4">{{encargo.enup_localidad}}</h4>
                    </div>
                </div>

                <div class="row m-0 ms-3 fila_datos">
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Referencias del Siniestro</h5>
                        <h4 class="subtitulo_h4">{{encargo.enup_referencia_siniestro}}</h4>
                    </div>
                    <div class="col-md-3">
                        <h5 class="m-0 pt-3 subtitulo_h5">Teléfono</h5>
                        <h4 class="subtitulo_h4">{{encargo.enup_telefono}}</h4>
                    </div>
                </div>

                <div class="row m-0 ms-3 fila_datos">
                    <div class="col-md-9">
                        <h5 class="m-0 pt-3 subtitulo_h5">Forma de producción</h5>
                        <h4 class="subtitulo_h4">{{encargo.enup_forma_produccion}}</h4>
                    </div>
                </div>
                

                <div class="row m-0 ms-3 mt-3 fila_datos">
                    <div id="responsabilidad" class="col-md-6">
                        <mat-form-field style="width: 100%;" appearance="outline">
                            <mat-label>Responsabilidad</mat-label>
                            <mat-select [(ngModel)]="responsabilidad" placeholder="Responsabilidad">
                                <mat-option value="Gasto médico">Gasto médico</mat-option>
                                <mat-option value="Reclamación">Reclamación</mat-option>
                                <mat-option value="Defensa">Defensa</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    
                    <div *ngIf="encargo.enup_url_videoconferencia" class="col-md-6" style="text-align: center;">
                        <a mat-stroked-button class="btn_videoconsulta" [href]="rutaSala" target="_blank">Abrir sala videoconsulta<mat-icon class="icon_btn_videoconsulta">videocam</mat-icon>
                        </a>
                    </div>
                </div>

                <div class="row m-0 ms-3 fila_datos">
                    <div id="peticion" class="col-md-12">
                        <mat-form-field style="width: 100%;" appearance="outline">
                            <mat-label>Peticion</mat-label>
                            <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="4" cdkAutosizeMaxRows="20" matInput placeholder="Peticion" [(ngModel)]="peticion" (keyup)="keyupClaseAnterior($event.target.value, 'peticion', encargoAnterior.enup_peticion)"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <!-- Informe -->
        <div class="tabcontent informe noshow" id="informe" >
            <div class="container-fluid" style="width: 92%;">
                <div class="row" style="margin-top: 20px;">
                    <div class="col-md-12">
                        <h1>Informe</h1>
                    </div>
                </div>

                <!-- Campos adicionales de informe REHUSE CAS+VIDEO || VALORACIÓN VIDEO-CONSULTA || VALORACIÓN BASE DOCUMENTAL || ALTA+IAE BASE DOCUMENTAL-->
                <!-- <div *ngIf="idTipo == 21">
                    <div class="row m-0">
                        <h2>Fuente documental</h2>

                        <div id="fuenteDocumental" class="col-md-12" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Fuente documental</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="fuenteDocumental" placeholder="Fuente documental" (keyup)="keyupClaseAnterior($event.target.value, 'fuenteDocumental', encargoAnterior.enup_fuente_documental)"></textarea>
                            </mat-form-field>
                        </div>
                        
                    </div> 
                    
                </div> -->

                <!--<div *ngIf="encargo.nombre_tipo_encargo == 'PRUEBA! ESTUDIO DOCUMENTAL'">
                    <div class="row m-0">
                        <h2>Fuente documental</h2>

                        <div id="fuenteDocumental" class="col-md-12">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Fuente documental</mat-label>
                                <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="fuenteDocumental" placeholder="Fuente documental" (keyup)="keyupClaseAnterior($event.target.value, 'fuenteDocumental', encargoAnterior.enup_fuente_documental)"></textarea>
                            </mat-form-field>
                        </div>
                </div> -->
            
                <div class="row m-0">
                    <h2>Datos generales</h2>
                    <div class="col-md-4">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Número de expediente</mat-label>
                            <input matInput [(ngModel)]="numExpediente" placeholder="Número de expediente">
                        </mat-form-field>
                    </div>
                    <div  class="col-md-4">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Nombre del médico</mat-label>
                            <input matInput disabled [(ngModel)]="nombreMedico" placeholder="Nombre del médico">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Nº de colegiado</mat-label>
                            <input matInput disabled [(ngModel)]="numColegiado" placeholder="Nº de colegiado">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row m-0">
                    <div class="col-md-4">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Nombre lesionado</mat-label>
                            <input matInput [(ngModel)]="nombreLesionado" placeholder="Nombre lesionado">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Edad lesionado</mat-label>
                            <input matInput [(ngModel)]="edadLesionado" placeholder="Edad lesionado">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Provincia lesionado</mat-label>
                            <input matInput [(ngModel)]="provincia" placeholder="Provincia lesionado">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row m-0">
                    <div class="col-md-4">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Localidad lesionado</mat-label>
                            <input matInput [(ngModel)]="localidad" placeholder="Localidad lesionado">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Fecha siniestro</mat-label>
                            <input matInput [(ngModel)]="fechaSiniestro" placeholder="Fecha siniestro">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Referencia siniestro</mat-label>
                            <input matInput [(ngModel)]="refSiniestro" placeholder="Referencia siniestro">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col-md-4">
                        <mat-form-field class="w-100"  appearance="outline">
                            <mat-label>Fecha límite</mat-label>
                            <input matInput [matDatepicker]="pickerFechalimite" [formControl] ="fechaLimite">
                            <mat-datepicker-toggle matSuffix [for]="pickerFechalimite"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFechalimite></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4" *ngIf="idTipo == 21">
                        <mat-form-field class="w-100"  appearance="outline">
                            <mat-label>Fecha consulta/vídeo</mat-label>
                            <input matInput [matDatepicker]="pickerFechaConsulta" [formControl] ="fechaConsultaVideoConsulta">
                            <mat-datepicker-toggle matSuffix [for]="pickerFechaConsulta"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFechaConsulta></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4" *ngIf="idTipo == 21">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Hora consulta/vídeo</mat-label>
                            <input type="time" matInput [(ngModel)]="horaConsultaVideoConsulta">
                        </mat-form-field>
                    </div>
                </div>

                <!-- Mecanismo de produccion -->
                <!-- <div class="row m-0" *ngIf="idTipo == 21">
                    <div id="mecanismoProduccion" class="col-md-12" [ngClass]="{anterior: addClassSecuelasAntiguas}">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Mecanismo de producción</mat-label>
                            <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="mecanismoProduccion" placeholder="Mecanismo de producción" (keyup)="keyupClaseAnterior($event.target.value, 'mecanismoProduccion', encargoAnterior.enup_mecanismo_produccion)"></textarea>
                        </mat-form-field>
                    </div>
                </div> -->

                <div class="row m-0">
                    <div id="observaciones" class="col-md-12">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Observaciones</mat-label>
                            <textarea class="p-0" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20" matInput [(ngModel)]="observaciones" placeholder="Observaciones" (keyup)="keyupClaseAnterior($event.target.value, 'observaciones', encargoAnterior.enup_observaciones)"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>


        <!-- Documentacion -->
        <div class="tabcontent documentacion noshow" id="documentacion" >
            <div>
                <h1>Documentación</h1>
                <app-file-manager-medico [fileElements]="archivos | async" [path]="currentPath" [canNavigateUp]="canNavigateUp"
                    (navigatedDown)="navigateToFolder($event)" (navigatedUp)="navigateUp()"
                    (openModalView)="openModalFile($event)">
                </app-file-manager-medico>
            </div>
        </div>

        <!-- Mensajes -->
        <div class="tabcontent mensajes noshow" id="mensajes" >
            <div class="container-fluid"  style="width: 90%;">
                <div class="row" style="margin-top: 20px;">
                    <div class="col-md-12">
                        <h1>Mensajes</h1>
                    </div>
                </div>

                <!-- Mensajes sin leer -->
                <!-- <div *ngFor="let mensaje of mensajes" class="row fila_mensajes">
                    <div class="col-md-12">
                        <div class="row" style="margin-top: 10px;">
                            <div class="col-md-2">
                                <h4 style="font-weight: 500;">{{mensaje.msj_usuario_creacion}}</h4>
                            </div>
                            <div class="col-md-2">
                                <h5 class="h5_mensajes">{{mensaje.created_at}}</h5>
                            </div>
                            <div class="col-md-7">
                                <h5 class="h5_mensajes">{{mensaje.msj_texto}}
                                </h5>
                            </div>
                            <div class="col-md-1 check_mensaje_container">
                                <mat-checkbox style="margin: auto;" (change)="mensajeCheck($event, mensaje.id)"></mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div *ngIf="mensajes.length != 0" class="row" style="border: 2px solid black; max-height: 400px; overflow-y: scroll;">
                    <div class="col-md-12">
                        <div *ngFor="let mensaje of mensajes" class="row">
                            <div class="col-md-6">
                                <mat-card *ngIf="mensaje.tipoUsuario == 1" style="background-color: #B4DBCD; margin: 10px 0;">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <h4 style="font-weight: 500;">{{mensaje.nombreUsuario}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <h5 class="h5_mensajes">{{mensaje.fechaCreacion}}</h5>
                                        </div>
                                        <div class="col-md-8">
                                            <h5 class="h5_mensajes">{{mensaje.msj_texto}}</h5>
                                        </div>
                                    </div>
                                </mat-card>
                            </div>
                            <div class="col-md-6">
                                <mat-card *ngIf="mensaje.tipoUsuario == 0" style="background-color: grey; margin: 10px 0;">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <h4 style="font-weight: 500;">{{mensaje.nombreUsuario}}</h4>
                                        </div>
                                        <div class="col-md-2">
                                            <h5 class="h5_mensajes">{{mensaje.fechaCreacion}}</h5>
                                        </div>
                                        <div class="col-md-8">
                                            <h5 class="h5_mensajes">{{mensaje.msj_texto}}</h5>
                                        </div>
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <!-- Boton mensajes leidos -->
                <div *ngIf="mensajes.length != 0" class="row" style="margin-top: 20px;">
                    <div class="col-md-2">
                        <button mat-stroked-button color="warn" (click)="mensajesLeidos()">Mensajes Leidos</button>
                    </div>
                </div>

                <div class="row" style="margin-top: 40px;">
                    <mat-divider class="separador_mensajes"></mat-divider>
                </div>

                <!-- Crear nuevo mensaje -->
                <div class="row" style="margin-top: 40px;">
                    <div class="col-md-12">
                        <mat-form-field appearance="outline" class="textarea_mensaje">
                            <mat-label>Crear nuevo mensaje</mat-label>
                            <textarea matInput placeholder="Texto del mensaje" [(ngModel)]="textoMensaje"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 80px;">
                    <div class="col-md-12">
                        <button mat-stroked-button class="boton_enviar_mensaje" (click)="enviarMensajeEncargo()">Enviar</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Histórico del siniestro -->
        <div class="tabcontent historico noshow" id="historico" >
            <div class="container-fluid" style="width: 95%;">
                <div class="row" style="margin-top: 20px;">
                    <div class="col-md-12">
                        <h1>Histórico del siniestro</h1>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="width: 92%;">
                <div *ngFor="let encargoBySiniestro of encargosBySiniestro" class="row fila_encargo_siniestro" (click)="abrirEncargoSiniestro(encargoBySiniestro.id)">
                    <div class="col-md-12">
                        <div class="row" style="margin-top: 10px;margin-bottom: 5px;">
                            <div class="col-md-2">
                                <div>
                                    <i class="bi bi-circle-fill icon_size" [ngClass]="colorEncargo(encargoBySiniestro.enup_fecha_limite)"></i>
                                    <h3 class="text_inline_icon_encargo">{{encargoBySiniestro.id_plataforma}}</h3>
                                </div>
                                <div>
                                    <i class="bi bi-chat-left-text-fill icon_size"><h3 class="text_inline_icon_mensaje">{{encargoBySiniestro.mensajes_count}} Mensaje</h3></i>
                                </div>
                            </div>
            
                            <div class="col-md-2">
                                <div class="row">
                                    <div class="col-md-8">
                                        <h5 class="m-0 pt-3 subtitulo_h5">Fecha encargo</h5>
                                        <h4 class="subtitulo_h4">{{encargoBySiniestro.enup_fecha_tarea | date: 'dd/MM/yyyy HH:mm:ss'}}</h4>
                                    </div>
                                    <div class="col-md-2">
                                        <mat-divider [vertical] ="true" class="mt-3" style="height:50px"></mat-divider>
                                    </div>
                                    <div class="col-md-2">
                                    
                                    </div>
                                </div>
                            </div>
                
                            <div class="col-md-2">
                                <h5 class="m-0 pt-3 subtitulo_h5">Estado</h5>
                                <h4 class="subtitulo_h4">{{encargoBySiniestro.enup_estado_encargo}}</h4>
                            </div>
                
                            <div class="col-md-2">
                                <div class="row">
                                    <div class="col-md-8">
                                        <h5 class="m-0 pt-3 subtitulo_h5">Tipo de encargo</h5>
                                        <h4 class="subtitulo_h4">{{encargoBySiniestro.nombre_tipo_encargo}}</h4>
                                    </div>
                                    <div class="col-md-2">
                                        <mat-divider [vertical] ="true" class="mt-3" style="height:50px"></mat-divider>
                                    </div>
                                    <div class="col-md-2">
                                    
                                    </div>
                                </div>
                            </div>
                
                            <div class="col-md-2">
                                <div class="row">
                                    <div class="col-md-8">
                                        <h5 class="m-0 pt-3 subtitulo_h5">Fecha Limite</h5>
                                        <h4 class="subtitulo_h4">{{encargoBySiniestro.enup_fecha_limite | date: 'dd/MM/yyyy HH:mm:ss'}}</h4>
                                    </div>
                                    <div class="col-md-2">
                                        <mat-divider [vertical] ="true" class="mt-3" style="height:50px"></mat-divider>
                                    </div>
                                    <div class="col-md-2">
                                    
                                    </div>
                                </div>
                            </div>
                
                            <div class="col-md-2">
                                <h5 class="m-0 pt-3 subtitulo_h5">Lesionado</h5>
                                <h4 class="subtitulo_h4">{{encargoBySiniestro.enup_lesionado}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- FAB -->
        <div class="fab_container guardar">
            <button mat-fab class="fab_datos" color="white" title="Guardar" aria-label="Guardar" (click)="guardarDatosEncargo('')">
                <mat-icon style="margin-top: -6px;">save</mat-icon>
            </button>
        </div>

        <div class="fab_container generarInforme">
            <button mat-fab class="fab_datos" color="white" title="Generar informe" aria-label="Generar informe" (click)="guardarDatosEncargo('informe')">
                <mat-icon style="margin-top: -6px;" style="color: #4b8f76;"><i></i>assignment</mat-icon>
            </button>
        </div>
        
        <!-- FAB -->
        <div *ngIf="mostrarRevisado" class="fab_container revisado">
            <button mat-fab class="fab_datos" color="white" title="Revisado" aria-label="Revisado" (click)="guardarDatosEncargo('revisado')">
                <mat-icon style="margin-top: -2px;">send</mat-icon>
            </button>
        </div>

        <!-- FAB -->
        <div class="fab_container subir">
            <button mat-fab class="fab_datos" color="white" title="Subir archivo" aria-label="Subir archivo" (click)="openModal('modalUploadFile', null)">
                <mat-icon style="margin-top: -2px;">add_circle</mat-icon>
            </button>
        </div>

        <div class="fab_container carpeta">
            <button mat-fab class="fab_datos" color="white" title="Descargar carpeta" aria-label="Descargar carpeta" (click)="guardarDatosEncargo('descargarDocMedico')">
                <mat-icon  style="color: #4b8f76;"><i></i>cloud_download</mat-icon>
            </button>
        </div>
        <!-- Generar informe -->
        <div class="fab_container generarInforme">
            <button mat-fab class="fab_datos" color="white" title="Generar informe" aria-label="Generar informe" (click)="guardarDatosEncargo('informe')">
                <mat-icon style="margin-top: -6px;" style="color: #4b8f76;"><i></i>assignment</mat-icon>
            </button>
        </div>

    </div>
</div>

<!-- Modal Image -->
<div id="modalFile" class="modal-wrapper">
    <div class="modal">
        <div class="head">
            <a class="btn-close trigger" (click)="openModal('modalFile', null)">
                <mat-icon aria-hidden="true">close</mat-icon>
            </a>
        </div>
        <div class="content">
            <div class="good-job" *ngIf="imageSelect && tipoArchivo=='pdf'">
                <h5>PDF</h5>
                <ngx-extended-pdf-viewer [base64Src]="imageSelect" backgroundColor="#ffffff" [height]="'500px'"
                    [useBrowserLocale]="true" [handTool]="false" [showHandToolButton]="true">
                </ngx-extended-pdf-viewer>
            </div>
            <div class="good-job" *ngIf="imageSelect && tipoArchivo=='imagen'">
                <h5>Imagen</h5>
                <img height="500px" src="{{'data:image/jpg;base64,' + imageSelect}}">
            </div>
        </div>
    </div>
</div>

<!-- Modal File -->
<div id="modalUploadFile" class="modal-wrapper">
    <div class="modalFile">
        <div class="head">
            <a class="btn-close trigger" (click)="openModal('modalUploadFile', null)">
                <mat-icon aria-hidden="true">close</mat-icon>
            </a>
        </div>
        <div class="content">
            <div class="good-job">
                <h5>Subir archivo</h5>
                <form [formGroup]="formUploadFile" method="POST">
                    <div class="form-group">
                        <input type="file" id="archFile" (change)="onFileChange($event, 'archFile', false)"
                            #fileInput />
                    </div>
                </form>
                <button mat-fab class="fab_datos" color="white" title="Guardar" aria-label="Guardar" (click)="uploadFile()">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>