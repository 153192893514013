import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-enviar-dialog',
  templateUrl: './enviar-dialog.component.html',
  styleUrls: ['./enviar-dialog.component.scss']
})
export class EnviarDialogComponent implements OnInit {

  comentario: string;

  constructor(public dialogRef: MatDialogRef<EnviarDialogComponent>) { }

  ngOnInit(): void {
  }

}
