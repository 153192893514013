<h1 mat-dialog-title>Facturas disponibles para generar remesa:</h1>

<div mat-dialog-content class="border-top">

    <div *ngIf="facturasRecibidas.length == 0" class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="facturasRecibidas.length > 0">
        <table mat-table #tableFacturasRecibidas [dataSource]="dataSourceFacturasRecibidas" class="w-100 ">

            <ng-container matColumnDef="checkbox">
                <th class="p-2" mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null; calculateTotal()"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td class="p-2" mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null; calculateTotal()"
                                    [checked]="selection.isSelected(row)"
                                    [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="factr_id_facturas_recibidas">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Id factura recibida </th>
                <td class="p-2" mat-cell *matCellDef="let facturaRecibida"> {{facturaRecibida.factr_id_facturas_recibidas != null ? facturaRecibida.factr_id_facturas_recibidas : '--'}} </td>
            </ng-container>

            <ng-container matColumnDef="factr_fecha_creacion">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha creación </th>
                <td class="p-2" mat-cell *matCellDef="let facturaRecibida"> {{facturaRecibida.factr_fecha_creacion != null ? facturaRecibida.factr_fecha_creacion : '--'}} </td>
            </ng-container>

            <ng-container matColumnDef="factr_numero_factura">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Nº factura </th>
                <td class="p-2" mat-cell *matCellDef="let facturaRecibida"> {{facturaRecibida.factr_numero_factura != null ? facturaRecibida.factr_numero_factura : '--'}} </td>
            </ng-container>

            <ng-container matColumnDef="factr_fecha_recepcion">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha recepción </th>
                <td class="p-2" mat-cell *matCellDef="let facturaRecibida"> {{facturaRecibida.factr_fecha_recepcion != null ? facturaRecibida.factr_fecha_recepcion : '--'}} </td>
            </ng-container>

            <ng-container matColumnDef="factr_fecha_pago">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha pago </th>
                <td class="p-2" mat-cell *matCellDef="let facturaRecibida"> {{facturaRecibida.factr_fecha_pago != null ? facturaRecibida.factr_fecha_pago : '--'}} </td>
            </ng-container>

            <ng-container matColumnDef="factr_base_imponible">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Base imponible </th>
                <td class="p-2" mat-cell *matCellDef="let facturaRecibida"> {{facturaRecibida.factr_base_imponible != null ? (facturaRecibida.factr_base_imponible | number:'1.2-2') : '--'}}€ </td>
            </ng-container>

            <ng-container matColumnDef="factr_total_iva">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Total IVA </th>
                <td class="p-2" mat-cell *matCellDef="let facturaRecibida"> {{facturaRecibida.factr_total_iva != null ? (facturaRecibida.factr_total_iva | number:'1.2-2') : '--'}}€ </td>
            </ng-container>

            <ng-container matColumnDef="factr_total_factura">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Total factura </th>
                <td class="p-2" mat-cell *matCellDef="let facturaRecibida"> {{facturaRecibida.factr_total_factura != null ? (facturaRecibida.factr_total_factura | number:'1.2-2') : '--'}}€ </td>
            </ng-container>

            <ng-container matColumnDef="factr_estado">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td class="p-2" mat-cell *matCellDef="let facturaRecibida"> {{facturaRecibida.factr_estado != null ? facturaRecibida.factr_estado : '--'}} </td>
            </ng-container>

            <ng-container matColumnDef="factr_contabilidad">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Contabilidad </th>
                <td class="p-2" mat-cell *matCellDef="let facturaRecibida"> {{facturaRecibida.factr_contabilidad != null ? facturaRecibida.factr_contabilidad : '--'}} </td>
            </ng-container>

            <ng-container matColumnDef="factr_proveedor">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Proveedor </th>
                <td class="p-2" mat-cell *matCellDef="let facturaRecibida"> {{obtenerNombreProveedor(facturaRecibida.factr_proveedor)}} </td>
            </ng-container>

            <ng-container matColumnDef="factr_estado_factura">
                <th class="p-2" mat-header-cell *matHeaderCellDef mat-sort-header> Estado factura </th>
                <td class="p-2" mat-cell *matCellDef="let facturaRecibida"> {{getEstadoFacturaDescripcion(facturaRecibida.factr_estado_factura)}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsFacturasRecibidas" class="sticky-top"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsFacturasRecibidas;" class="custom-row" (click)="selection.toggle(row); calculateTotal()"></tr>
        </table>
    </div>

</div>
<div mat-dialog-actions class="justify-content-between">
    <button mat-flat-button color="primary" (click)="generarRemesa()">Generar remesa</button>
    <h5><strong>Total facturas: {{total | number:'1.2-2'}}€</strong></h5>
</div>