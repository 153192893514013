<nav class="navbar navbar_primary">
    <span class="navbar-text navbar_primary_text">
        <h1 class="navbar_h" style="font-weight: 600;">Portal Tramitador</h1>
        <h2 class="navbar_h" style="padding-top: 6px;">{{nombreTramitador}}</h2>
    </span>
    <span>
        <img src="../../assets/img/logoUpsan.jpg" alt="" style="width: 260px;">
    </span>
</nav>
<nav class="navbar navbar_secondary">
    <ul>
        <li class="pestanias">
            <a [routerLink]="['/tramitador-principal']"><i class="bi bi-house-fill navbar_i"></i></a>
        </li>
        <li class="pestanias" [routerLinkActive]="['nabvar_li_active']">
            <a [routerLink]="['/tramitador-principal']">Nueva Tarea</a>
        </li>
        <li class="pestanias" [routerLinkActive]="['nabvar_li_active']">
            <a [routerLink]="['/tramitador-listado', 'valoracionDocumental']">Listado Valoración Documental</a>
        </li>
        <li class="pestanias" [routerLinkActive]="['nabvar_li_active']">
            <a [routerLink]="['/tramitador-listado', 'consultaVideoConsulta']">Listado Consulta/Videoconsulta</a>
        </li>
        <li class="pestanias" [routerLinkActive]="['nabvar_li_active']">
            <a [routerLink]="['/tramitador-listado', 'asesorias']">Listado Asesorías</a>
        </li>
        <li class="pestanias" [routerLinkActive]="['nabvar_li_active']">
            <a [routerLink]="['/encargo-pericial']">Listado Periciales</a>
        </li>
        <li class="pestanias" [routerLinkActive]="['nabvar_li_active']">
            <a [routerLink]="['/tramitador-mensajes']">MENSAJES<span *ngIf="countMensajes!=0" matBadge="{{countMensajes}}" matBadgeColor="warn" matBadgeOverlap="false"></span></a>
        </li>
        <li class="pestanias" [routerLinkActive]="['nabvar_li_active']" >
            <a [routerLink]="['/nueva-tarea-vdc']">Nueva Tarea Asistencial</a>
        </li>
        <li class="pestanias" [routerLinkActive]="['nabvar_li_active']" >
            <a [routerLink]="['/encargo-vdc-asistencial']">VDC Asistencial</a>
        </li>
        <li class="navbar_li_exit pestanias">
            <a (click)="logout()"><i class="bi bi-box-arrow-left navbar_i"></i></a>
        </li>
    </ul>
</nav> 